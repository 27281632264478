import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  header: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '29px',
    textAlign: 'center',
    color: '#5568AE',
    display: 'flex',
    paddingTop: '20px',
    margin: 'auto',
  },
  friendsContainer: {
    padding: '13px',
    // borderBottom: '1px solid #5568AE',
    height: '100%',
    alignItems: 'center',
    '& p': {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      // fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      textAlign: 'center',
      color: '#5568AE',
      display: 'flex',
      margin: 'auto',
    },
  },
  friendItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '15px',
    cursor: 'pointer',
    transition: '0.1s ease-out',
    maxWidth: '75px',
    minWidth: '65px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginRight: '20px',
      maxWidth: '95px',
      minWidth: '75px',
    },
    '&:last-child': {
      marginRight: '0px',
    },
    '& > img': {
      width: '75px',
      height: '75px',
      borderRadius: '4px',
      marginBottom: '3px',
      [theme.breakpoints.up('sm')]: {
        width: '95px',
        height: '95px',
      },
    },
    '& > p': {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '17px',

      alignItems: 'center',
      textAlign: 'center',
      color: '#1B2036',
      width: '75px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
    '&:hover': {
      transform: 'scale(1.02)',
    },
  },
}));

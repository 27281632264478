import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  depositContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '20px',
    '& p': {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '18px',
      textAlign: 'center',
      color: '#5568AE',
      display: 'flex',
    },
    '& img': {
      width: '22px',
      height: '20px',
      marginRight: '7px',
    },
    '& > .or': {
      margin: '0px 0px 12px',
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '17px',
      lineHeight: '20px',
      textAlign: 'center',
      color: '#5568AE',
    },
  },
  btnContainer: {
    color: 'inherit',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

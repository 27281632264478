import { styles } from './styles';
import Button from '@components/Button';
import { useAppSelector } from '@app/hooks';
import { useEffect, useState } from 'react';
import { isPlayerReadyToQuit, selectIsFirstPlayer } from '@store/room/roomSlice';
import { selectIsEnLocale } from '@store/reducers/session.reducer';

interface IProps {
  isTopPlayers: boolean;
  isSpectator: boolean;
  onClick: () => void;
  isActivePlayer?: boolean;
}

const NextButton: React.FC<IProps> = ({ isTopPlayers, isSpectator, onClick, isActivePlayer }) => {
  const classes = styles();

  const isQuitClickedAfterReload = useAppSelector(isPlayerReadyToQuit);
  const [isButtonClicked, setButtonClicked] = useState(false);
  const isRoomOwner = useAppSelector(selectIsFirstPlayer);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const buttonTextRu = isTopPlayers ? 'Далее' : 'Завершить';
  const buttonTextEn = isTopPlayers ? 'Next' : 'Finish party';

  const waitingText = isEnLocale
    ? `Room owner must tap ${buttonTextEn} to proceed`
    : `Создатель комнаты должен нажать ${buttonTextRu}, чтобы продолжить`;

  const isButtonEnabled = !isButtonClicked && !isQuitClickedAfterReload && !isSpectator;

  const onButtonClick = () => {
    setButtonClicked(true);
    onClick();
  };

  useEffect(() => {
    setButtonClicked(false);
  }, [isTopPlayers]);

  return (
    <div className={classes.buttonWrapper}>
      {(isRoomOwner && isButtonEnabled) || isActivePlayer ? (
        <Button type="primary-blue-new" text={isEnLocale ? buttonTextEn : buttonTextRu} onClick={onButtonClick} />
      ) : (
        <span className="mobile-outline">{waitingText}</span>
      )}
    </div>
  );
};

export { NextButton };

import { ObjectType } from '@shared/generics';
import WSClient from '@src/sockets';
import { CLIENT_EVENTS } from '@shared/socketEvents/eventTypes';
import { analytics } from '@services/amplitude';
import { IdType } from '@src/shared/generics';
import { GameTypes } from '@src/common/constants/common';
import { GameRewardClientEvent } from '@src/shared/socketEvents/socketEvents';

export const startGameEvent = (
  roomId: IdType,
  playerId: IdType,
  partyId: IdType | undefined,
  gameId: IdType | undefined,
  gameType: GameTypes,
  params?: ObjectType
) => {
  WSClient.emit(CLIENT_EVENTS.PLAYER_STARTED_GAME, {
    roomId,
    playerId,
    partyId,
    gameId,
    gameType,
    params,
  });
  analytics.logEvent(analytics.EVENTS.PARTY_STARTED, { roomId, partyId, gameId, gameType, ...params });
};

export const endGameEvent = (roomId: IdType, playerId: IdType, gameType: GameTypes) => {
  WSClient.emit(CLIENT_EVENTS.PLAYER_READY_TO_QUIT_GAME, {
    roomId,
    playerId,
  });
  analytics.logEvent(analytics.EVENTS.PARTY_ENDED, { roomId, playerId, gameType });
};

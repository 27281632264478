import { makeStyles } from '@material-ui/core';
import Logo from '@art/images/one-question/logo.svg';
import ArrowBack from '@art/images/one-question/back-arrow.svg';

export const styles = makeStyles((theme) => ({
  startScreen: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    flexGrow: 1,
    position: 'relative',
    textAlign: 'center',
  },

  homepageButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: '138px',
    height: '34px',
    backgroundColor: 'rgba(0,0,0, 0.2)',
    borderRadius: '4px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '13px center',
    backgroundImage: `url(${ArrowBack})`,
    transition: 'all .23s',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      top: '90px',
      left: '30px',
    },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0, 0.5)',
    },
    '& > span': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '17px',
      lineHeight: '17px',
      color: 'white',
      paddingLeft: '25px',
      letterSpacing: '-0.5px',
    },
  },

  title: {
    fontFamily: 'Barbieri-Regular',
    textAlign: 'center',
    color: 'white',
    fontSize: '48px',
    lineHeight: '46px',
    maxWidth: '200px',
    textShadow: '-2px 2px 3px black',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '260px',
      fontSize: '72px',
      lineHeight: '68px',
      textShadow: '-4px 4px 3px black',
    },
  },

  imageTitle: {
    width: '200px',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '260px',
    },
  },

  buttonsContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },

  sendButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '275px',
    height: '44px',
    border: '1px solid #000',
    backgroundColor: '#8950FF',
    boxShadow: 'inset 0px -5px 0px #5E33B8, inset 0px 17px 0px #AB82FF',
    borderRadius: '70px',
    color: 'white',
    fontSize: '24px',
    lineHeight: '28px',
    textShadow: '0px 2px 0px #000',
    cursor: 'pointer',
    transition: 'all .23s',
    marginBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '300px',
      height: '55px',
      fontSize: '28px',
      lineHeight: '28px',
    },
    '&.ru': {
      minWidth: '280px',
      fontSize: '20px',
      lineHeight: '24px',
    },
    '&:hover': {
      backgroundColor: '#5E33B8',
      boxShadow: 'none',
    },
  },

  acceptButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '215px',
    height: '44px',
    border: '1px solid #000',
    backgroundColor: '#008AFF',
    boxShadow: 'inset 0px -5px 0px #0243A5, inset 0px 17px 0px #00ABFF',
    borderRadius: '70px',
    color: 'white',
    fontSize: '24px',
    lineHeight: '28px',
    textShadow: '0px 2px 0px #000',
    cursor: 'pointer',
    transition: 'all .23s',
    [theme.breakpoints.up('sm')]: {
      minWidth: '230px',
      height: '55px',
      fontSize: '28px',
      lineHeight: '28px',
    },
    '&.ru': {
      minWidth: '210px',
      fontSize: '20px',
      lineHeight: '24px',
    },
    '&:hover': {
      backgroundColor: '#0243A5',
      boxShadow: 'none',
    },
  },
}));

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#98B92E',
    overflow: 'hidden',
    position: 'relative',
  },
  background: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    position: 'relative',
    justifyContent: 'center',
  },
  loading: {
    background: '#FFFFFF',
    textStroke: '2px #061537',
    boxSizing: 'border-box',
    boxShadow: 'inset 0px -9px 0px #E2E2E2',
    borderRadius: '52px',
    height: '20px',
    padding: '15px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid #061537',
    bottom: '20%',
    position: 'absolute',
    '&.fill': {
      background: '#3760C2',
      boxShadow: 'inset 0px -7px 0px #1B3A80',
      position: 'absolute',
      left: '-2px',
      top: '-2px',
      overflow: 'hidden',
    },
  },
  input: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 10px',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#212529',
    backgroundColor: '#fff',
    border: '1px solid #bdbdbd',
    borderRadius: '10px',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
  },

  logoUnity: {
    width: '275px',
    height: '126px',
    flexShrink: 0,
    marginTop: '110px',
    [theme.breakpoints.up('sm')]: {
      width: '540px',
      height: '248px',
      marginTop: '100px',
    },
    [theme.breakpoints.up('md')]: {
      width: '675px',
      height: '310px',
    },
  },
}));

import { useGameProvider } from './data.service';
import { CLIENT_EVENTS } from '@src/shared/socketEvents/eventTypes';
import WSClient from '@src/sockets';
import { IQuestion } from '@src/shared/gameInterfaces/loveBottle.socket';
import { MATCH_STATE } from './game.types';
import { GameTypes } from '@src/common/constants/common';

const useGameHook = (roomId: string) => {
  const [state, game, playerId, storeActions, isSpectator, currentPlayer] = useGameProvider(roomId);
  const activeQuestion = game.questions[game.activeTicketIndex] || ([] as IQuestion[]);
  const isNextDisabled =
    (playerId === game.pairPlayerId && game.pairPlayerReady) ||
    (playerId === game.activePlayerId && game.activePlayerReady);
  const heart = state.SHOWING_RESULTS
    ? game.questions[game.activeTicketIndex].options.some((option) => option.votes && option.votes.length === 2)
      ? MATCH_STATE.RED
      : MATCH_STATE.BROKEN
    : MATCH_STATE.GREY;

  const activeGameId = game.activeGameId;
  const socketData = {
    roomId,
    activeGameId,
    playerId,
  };

  return {
    state,
    game,
    isSpectator,
    currentPlayer,
    heart,
    isNextDisabled,
    activeQuestion,
    actions: {
      onSpin: () => {
        WSClient.emit(CLIENT_EVENTS.ROUND_STARTED, {
          roomId,
          activeGameId,
          gameType: GameTypes.LOVE_BOTTLE,
        });
      },

      onVote: (index: any) => {
        storeActions.setVote(index);
        WSClient.emit(CLIENT_EVENTS.PLAYER_VOTED, {
          ...socketData,
          value: index,
        });
      },

      onNext: () => {
        storeActions.setReady();
        WSClient.emit(CLIENT_EVENTS.PLAYER_READY, socketData);
      },

      onQuit: () => {
        WSClient.emit(CLIENT_EVENTS.PLAYER_READY_TO_QUIT_GAME, {
          roomId,
          activeGameId: game.activeGameId,
          playerId,
        });
      },
    },
  };
};

export { useGameHook };

import { makeStyles } from '@material-ui/core';
import background_alert from '@art/images/alias/background_alert.png';

export const styles = makeStyles((theme) => ({
  timeIsUp: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 5,
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: '#F83E67',
    backgroundImage: `url(${background_alert})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    margin: '-15px',
    fontFamily: 'SF Pro Rounded Black',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '29px',
    textAlign: 'center',
    color: '#FFFFFF',
    textStroke: '2px #1B2036',
    padding: '20px',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
    [theme.breakpoints.up('sm')]: {
      margin: '-30px',
    },
    [theme.breakpoints.up('md')]: {
      margin: '-15px',
      borderRadius: '20px 20px 0px 0px',
      fontSize: '28px',
    },
  },
}));

import { formatEther, formatUnits } from '@ethersproject/units';
import { useAppSelector } from '@src/app/hooks';
import { IdType } from '@src/shared/generics';
import { selectBsc } from '@src/store/reducers/common';
import { useEtherBalance, useTokenBalance, useEthers } from '@usedapp/core';

export const useBscData = () => {
  const bscData = useAppSelector(selectBsc);
  const { account, chainId } = useEthers();
  // const userBalance = useEtherBalance(account);
  const tokenBalance = useTokenBalance(bscData.token, account) || 0;

  return {
    walletReady: true,
    walletConnected: Boolean(account),
    publicKey: account,
    tokenBalance: parseFloat(formatUnits(tokenBalance)),
    requestBalance: () => {},
    isRightChain: bscData.chainId === chainId,
    sendTokens: (amount: number) => Promise.resolve(''),
  };
};

import React, { useEffect, forwardRef } from 'react';
import Typography from '@material-ui/core/Typography';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { selectRoomPlayers } from '@store/room/roomSlice';
import { setVideoState } from '@store/reducers/common';
import matchedHeart from '@art/icons/LoveBottle/matchHeart.svg';
import brokenHeart from '@art/icons/LoveBottle/brokenHeart.svg';
import containerHeart from '@art/icons/LoveBottle/containerHeart.svg';
import HeartList from '../HeartList';
import { IGameState } from '../../game.types';
import { IQuestion } from '@src/shared/gameInterfaces/loveBottle.socket';
import { playerContainerStyles } from '../../game.styles';

const DELAY = 3000;

interface IProps {
  heart: string;
  activeTicketIndex: number;
  game: IGameState;
  isShowResults: boolean;
}

interface IPlayerCardProps extends Omit<IProps, 'game'> {
  playerId: string;
  activeQuestion: IQuestion;
}

const PlayerCard = forwardRef<HTMLDivElement, IPlayerCardProps>(function Card(
  { playerId, activeQuestion, heart, activeTicketIndex, isShowResults },
  ref
) {
  const dispatch = useAppDispatch();
  const containerRef = React.useRef<HTMLDivElement>(null);

  const userAnswer =
    (activeQuestion && activeQuestion.options.find((option) => option.votes && option.votes.includes(playerId))) ||
    ({} as IQuestion);
  const classes = playerContainerStyles();

  const [isVisible, setVisible] = React.useState(false);

  const videoContainer = document.getElementById(playerId);
  const videoContainerOrigin = document.getElementById(`${playerId}_container`);

  useEffect(() => {
    if (isShowResults) {
      setVisible(true);
      setTimeout(() => setVisible(false), DELAY);
    } else {
      setVisible(false);
    }
  }, [isShowResults]);

  useEffect(() => {
    if (videoContainerOrigin) {
      //@ts-ignore
      videoContainerOrigin.firstChild.insertAdjacentHTML(
        'afterBegin',
        `<img src=${containerHeart} class=${classes.containerHeart} />`
      );
      //@ts-ignore
      videoContainerOrigin.firstChild.style.background = 'rgba(255, 226, 236, 1)';
    }

    if (videoContainer && containerRef.current) {
      containerRef.current.append(videoContainer);
      videoContainer.classList.add(`${classes.pairContainer}`);
      videoContainer.classList.remove(`${classes.normalContainer}`);
      //@ts-ignore
      videoContainer.style.zIndex = '4';
    }

    return () => {
      if (videoContainer) {
        videoContainer.classList.add(`${classes.normalContainer}`);
        videoContainer.classList.remove(`${classes.pairContainer}`);
        //@ts-ignore
        videoContainer.style.zIndex = '0';
      }

      dispatch(setVideoState({ playerId, data: { shouldRestart: {} } }));

      if (videoContainerOrigin) {
        videoContainerOrigin.firstChild!.firstChild!.remove();
        //@ts-ignore
        videoContainerOrigin.firstChild!.insertBefore(videoContainer!, videoContainerOrigin.firstChild!.firstChild);
      }
    };
  }, [videoContainerOrigin, videoContainer]);

  let imgSrc;
  const heartChoice = (heart: string) => {
    switch (heart) {
      case 'RED':
        imgSrc = matchedHeart;
        break;
      case 'BROKEN':
        imgSrc = brokenHeart;
        break;
      default:
        imgSrc = null;
    }

    return imgSrc;
  };

  if (activeTicketIndex === 3 || heart === 'BROKEN') {
    heartChoice(heart);
  }

  const players = useAppSelector(selectRoomPlayers);
  const isRoomOwner = players[0].playerId === playerId; // first player is always owner

  return (
    <div className={classes.personContainer} ref={containerRef}>
      {isRoomOwner && <div className={classes.crownIcon} />}
      {imgSrc != null && !isVisible && <img src={imgSrc} className={classes.bigHeart} />}

      {isVisible && (
        <div className={classes.userAnswer}>
          <Typography className={classes.userAnswerText}>{userAnswer.title}</Typography>
        </div>
      )}
    </div>
  );
});

const PlayerContainer: React.FC<IProps> = ({ game, heart, activeTicketIndex, isShowResults }) => {
  const pairContainerRef = React.useRef<HTMLDivElement>(null);
  const activeContainerRef = React.useRef<HTMLDivElement>(null);
  const { activePlayerId, pairPlayerId } = game;
  const classes = playerContainerStyles();

  return (
    <div className={classes.playersWhiteContainer}>
      <PlayerCard
        isShowResults={isShowResults}
        playerId={activePlayerId}
        activeQuestion={game.questions[activeTicketIndex]}
        ref={activeContainerRef}
        heart={heart}
        activeTicketIndex={activeTicketIndex}
      />

      <HeartList heart={heart} activeTicketIndex={activeTicketIndex} />

      <PlayerCard
        isShowResults={isShowResults}
        playerId={pairPlayerId!}
        activeQuestion={game.questions[activeTicketIndex]}
        ref={pairContainerRef}
        heart={heart}
        activeTicketIndex={activeTicketIndex}
      />
    </div>
  );
};

export { PlayerContainer };

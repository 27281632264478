const FakeArtistTextContentEn: React.FC = () => {
  return (
    <>
      <li>
        If Artists won, then Artists get <b>10 points</b> each, FAKE get <b>0 points</b>
      </li>
      <li>
        If FAKE won, then Artists get <b>0 points</b> each, FAKE get <b>50 points</b>
      </li>
    </>
  );
};

const FakeArtistTextContentRu: React.FC = () => {
  return (
    <>
      <li>
        Если побеждают Художники, то Художники получают по <b>10 очков</b>, ФЭЙК - <b>0 очков</b>
      </li>
      <li>
        Если побеждает ФЭЙК, то Художники получают <b>0 очков</b>, ФЭЙК - <b>50 очков</b>
      </li>
    </>
  );
};

export { FakeArtistTextContentEn, FakeArtistTextContentRu };

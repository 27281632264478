import { AppDispatch } from '@src/app/store';
import { IQuestion, LOVE_BOTTLE_STATE } from '@src/shared/gameInterfaces/loveBottle.socket';
import { updateGameState } from '@store/reducers/game.reducer';
import { IGameState } from './game.types';

enum LABELS {
  ACTIVE = 'Active',
  DATING = 'Dating',
  VOTED = 'Voted',
  READY = 'Ready',
}

const updateLabelsThunk = (game: IGameState, activeQuestion: IQuestion) => (dispatch: AppDispatch) => {
  const state = game.gameState;
  const votedPlayers = activeQuestion.options && activeQuestion.options.map((option) => option.votes).flat();
  const readyPlayers = game.readyPlayers;

  const activeStates = state === LOVE_BOTTLE_STATE.READY_START_ROUND || state === LOVE_BOTTLE_STATE.SPIN_BOTTLE;
  const datingStates = state === LOVE_BOTTLE_STATE.ROUND_IN_PROGRESS || state === LOVE_BOTTLE_STATE.SHOWING_RESULTS;
  const voteStates = state === LOVE_BOTTLE_STATE.ROUND_IN_PROGRESS;
  const readyStates = state === LOVE_BOTTLE_STATE.SHOWING_RESULTS;
  const allReadyStates = state === LOVE_BOTTLE_STATE.TOP_COUPLES;

  const labels = new Map<string, string>();

  if (activeStates) {
    labels.set(game.activePlayerId, LABELS.ACTIVE);
  }

  if (datingStates) {
    labels.set(game.activePlayerId, LABELS.DATING);
    labels.set(game.pairPlayerId!, LABELS.DATING);
  }

  if (voteStates && votedPlayers) {
    votedPlayers!.forEach((playerId) => labels.set(playerId, LABELS.VOTED));
  }

  if (readyStates) {
    game.activePlayerReady ? labels.set(game.activePlayerId, LABELS.READY) : undefined;
    game.pairPlayerReady ? labels.set(game.pairPlayerId!, LABELS.READY) : undefined;
  }

  if (allReadyStates) {
    readyPlayers!.forEach((playerId) => labels.set(playerId, LABELS.READY));
  }

  dispatch(updateGameState({ playerLabels: [...labels.entries()] }));
};

export { updateLabelsThunk };

import { styles } from './styles';

interface IProps {
  onStartClick: () => void;
}

const Start: React.FC<IProps> = ({ onStartClick }) => {
  const classes = styles();

  return (
    <div className={classes.start}>
      {/* logoContainer */}
      <div className={classes.logoContainer} />

      {/* infoLabel */}
      <div className={classes.infoLabel} />

      {/* buttonsContainer */}
      <div className={classes.buttonsContainer}>
        <div className={classes.buttonStart} onClick={onStartClick} />

        {/*<div className={classes.buttonInvite} onClick={onStartClick} />*/}
      </div>
    </div>
  );
};

export default Start;

import { ILeaderboard, IReward } from '../common';
import { IdType } from '../generics';
import { BaseGameState } from '../socketEvents/socketEvents';

export enum LOVE_BOTTLE_STATE {
  READY_START_ROUND = 'READY_START_ROUND',
  SPIN_BOTTLE = 'SPIN_BOTTLE',
  ROUND_IN_PROGRESS = 'ROUND_IN_PROGRESS',
  SHOWING_RESULTS = 'SHOWING_RESULTS',
  SHOW_GIF = 'SHOW_GIF',
  TOP_COUPLES = 'TOP_COUPLES',
  LEADERBOARD = 'LEADERBOARD',
}

export interface IQuestion {
  ticketId: IdType;
  title: string;
  options: {
    title: string;
    answer: IdType;
    votes: IdType[];
  }[];
}

export interface LoveBottleState extends BaseGameState {
  gameState: LOVE_BOTTLE_STATE;
  roundNumber: number;
  totalRounds: number;
  activePlayerId: IdType;
  pairPlayerId: IdType;
  activePlayerReady?: boolean;
  pairPlayerReady?: boolean;
  activeTicketIndex: number;
  questions: IQuestion[];
  gifId: string;
  readyPlayers: IdType[];
  topCouples?: {
    playerName: string;
    matchName?: string;
    value?: number;
  }[];
  leaderboard?: ILeaderboard;
  reward?: IReward;
}

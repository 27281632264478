import React, { useState } from 'react';
import SpinnerRun from '@art/images/loveBottle/spinnerRun.png';
import SpinButton from '@art/images/loveBottle/spinButton.png';
import { IGameState } from '../game.types';
import { Typography } from '@material-ui/core';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { useAppSelector } from '@app/hooks';
import { LoveBottleState } from '@src/shared/gameInterfaces/loveBottle.socket';
import { loveBottleSpinStyles } from '../game.styles';

interface IProps {
  game: LoveBottleState;
  onSpin: () => void;
  isSpin: boolean;
  currUserId: string;
}

const SPIN_DURATION_STR = '1.5s';

const Spin: React.FC<IProps> = ({ onSpin, game, isSpin, currUserId }) => {
  const { roundNumber, totalRounds } = game;
  const classes = loveBottleSpinStyles();
  const [isVisible, setVisible] = useState(true);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const handleClickSpin = () => {
    onSpin();
    setVisible(false);
  };

  const showTitleEn = roundNumber === totalRounds ? 'Final round' : `Date ${roundNumber} of ${totalRounds}`;
  const showTitleRu = roundNumber === totalRounds ? 'Финальный раунд' : `Раунд ${roundNumber} из ${totalRounds}`;

  return (
    <div className={classes.spinContainer}>
      <Typography className={`${classes.spinText} mobile-outline`}>{isEnLocale ? showTitleEn : showTitleRu}</Typography>

      <div className={classes.spinnerWrapper}>
        <img
          src={SpinnerRun}
          className={classes.spinRunning}
          style={{ animation: isSpin ? `spinning ${SPIN_DURATION_STR} linear` : '' }}
          alt={'spinner'}
        />

        {game.activePlayerId === currUserId && isVisible && (
          <img src={SpinButton} className={classes.spinButton} onClick={handleClickSpin} alt={'Spin button'} />
        )}
      </div>
    </div>
  );
};

export { Spin };

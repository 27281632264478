const QuizTextContentEn: React.FC = () => {
  return (
    <>
      <li>
        Player gets <b>2 points</b> for correct answer
      </li>
      <li>
        Ghost gets <b>1 point</b> for correct answer
      </li>
      <li>0 points for incorrect answer</li>
    </>
  );
};

const QuizTextContentRu: React.FC = () => {
  return (
    <>
      <li>
        Игрок получает <b>2 очка</b> за правильный ответ
      </li>
      <li>
        Приведение получает <b>1 очко</b> за правильный ответ
      </li>
      <li>
        За неправильный ответ - <b>0 очков</b>
      </li>
    </>
  );
};

export { QuizTextContentEn, QuizTextContentRu };

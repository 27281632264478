const palleteColors = [
  '#1E1E1E',
  '#666666',
  '#AAAAAA',
  '#FFFFFF',
  '#1E52C6',
  '#60C7FA',
  '#31712D',
  '#51AC4C',
  '#8C1A11',
  '#EA332A',
  '#8C4620',
  '#EE7F40',
  '#A77230',
  '#F6C24D',
  '#8C1C4D',
  '#EA368D',
  '#BD605B',
  '#F3B2AB',
];

const brushSizes = [8, 12, 16, 20, 24, 28];

export { palleteColors, brushSizes };

import { GuessMeState, GUESS_ME_STATE } from '@shared/gameInterfaces/guessMe.socket';

export const initialMapState = {
  READY_START_ROUND: false,
  SPIN: false,
  QUESTION_ACTIVE: false,
  QUESTION_PASSIVE: false,
  RESULTS: false,
  TOP_PLAYERS: false,
  LEADERBOARD: false,
};

export type IGameStatusMatcher = typeof initialMapState;

export const defaultGameState: GuessMeState = {
  gameState: GUESS_ME_STATE.READY_START_ROUND,
  activeGameId: '',
  activePlayerId: '',
  roundNumber: 0,
  totalRounds: 0,
  readyPlayers: [],
};

export interface IGameState extends GuessMeState {
  state: IGameStatusMatcher;
}

export interface IPlayer {
  playerId: string;
  name: string;
}

import { Dispatch, SetStateAction } from 'react';
import { useAppSelector } from '@app/hooks';
import { selectRoomPlayers } from '@store/room/roomSlice';
import CommonModal from '@components/Modals/CommonModal';
import { styles } from './styles';
import { selectIsEnLocale } from '@store/reducers/session.reducer';

interface Props {
  isOpen: boolean;
  activePlayer: any;
  onVote: (value: string | undefined) => void;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const ChooseModal: React.FC<Props> = ({ isOpen, activePlayer, onVote, setModalOpen }) => {
  const classes = styles();
  const players = useAppSelector(selectRoomPlayers);
  const passivePlayers = players.filter((player) => player.playerId !== activePlayer.playerId);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <CommonModal isOpen={isOpen} handleClose={() => setModalOpen(false)} backgroundColor={'white'}>
      <h2 style={{ textAlign: 'center', marginTop: 0 }}>{isEnLocale ? 'Who guessed correctly?' : 'Кто отгадал?'}</h2>

      <div className={classes.btnContainer}>
        {passivePlayers.map((player, index) => {
          return (
            <div
              key={index}
              onClick={() => onVote(player.playerId)}
              className={classes.voteBtn}
              style={{ width: `calc(100% / ${passivePlayers.length + 1}` }}
            >
              <span>{player.name}</span>
            </div>
          );
        })}

        <div
          onClick={() => onVote(undefined)}
          className={`${classes.voteBtn} no-one`}
          style={{ width: `calc(100% / ${passivePlayers.length + 1}` }}
        >
          <span>{isEnLocale ? 'No one' : 'Никто'}</span>
        </div>
      </div>
    </CommonModal>
  );
};

export { ChooseModal };

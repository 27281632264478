import React, { useState } from 'react';
import { getRoomId, selectIsFullRoom, selectRoomPlayers } from '@store/room/roomSlice';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import Button from '@components/Button';
import { BuyCoinModal, CommonModal } from '@components/Modals';
import { styles } from './styles';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectBalance, selectPlayerId, setBalance } from '@src/store/reducers/player.reducer';
import helloCoin from '@art/icons/coin_flat.svg';
import { Host } from '@src/graphql/queries';
import { startGameEvent } from '@src/common/sendEvents';
import { selectGameParams } from '@src/store/reducers/playerData.reducer';
import { getAllGames, selectFriendlyBonus } from '@src/store/reducers/common';
import CloseCross from '@src/components/CloseCross/CloseCross';
import { roundNumbers } from '@src/common/misc';
import { GameTypes } from '@src/common/constants/common';
import { hasBalanceGame, hasFriendBonusGame } from '@src/common/constants/common';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  party: Host;
}

const PartyStartModal: React.FC<Props> = ({ isOpen, handleClose, party }) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const playerId = useAppSelector(selectPlayerId);
  const roomId = useAppSelector(getRoomId);
  const params = useAppSelector(selectGameParams);
  const balance = useAppSelector(selectBalance);
  const isFullRoom = useAppSelector(selectIsFullRoom);
  const games = useAppSelector(getAllGames);
  const players = useAppSelector(selectRoomPlayers);
  const friendBonusBase = useAppSelector(selectFriendlyBonus);
  const [isBuyCoinModalOpen, setBuyCoinModalOpen] = useState(false);
  const currentGame = games.find((game) => game.id === party.game.id);
  const friendsBonusPercent = (players.length - 1) * friendBonusBase * 100;
  const roundedTreasury = roundNumbers(currentGame!.balance.dailyBalance);
  const showBalance = hasBalanceGame(currentGame!.type);
  const hasFriendBonus = hasFriendBonusGame(currentGame!.type);

  const onClick = () => {
    if (balance >= party.price) {
      dispatch(setBalance(balance - party.price));
      startGameEvent(roomId, playerId, party.id, undefined, party.game.type, params);
    } else {
      setBuyCoinModalOpen(true);
    }
  };

  return (
    <>
      <CommonModal isOpen={isOpen} handleClose={handleClose}>
        <div className={classes.wrapper}>
          <CloseCross onClick={handleClose} />
          <h3 id="transition-modal-title">{party.title}</h3>
          {showBalance && (
            <div style={{ flexDirection: 'column', width: '100%' }}>
              <div className={classes.row}>
                <p className={'title'}>{isEnLocale ? 'Game Daily Treasury' : 'Сокровищница'}</p>
                <div className={'dots'} />
                <p className={'value'}>{roundedTreasury}</p>
                <img className={'coin'} src={helloCoin} alt="coin" />
              </div>

              {hasFriendBonus && (
                <>
                  <div className={classes.row}>
                    <p className={'title'}>{isEnLocale ? 'Total Friends Bonus' : 'Бонус за друзей'}</p>
                    <div className={'dots'} />
                    <p className={'value'}>{friendsBonusPercent ? `+${friendsBonusPercent.toFixed()}` : 0}%</p>
                  </div>

                  {!isFullRoom && (
                    <p className={classes.subtitle}>
                      {isEnLocale ? 'Invite Friends to increase bonus' : 'Приглашай друзей, чтобы увеличить бонус'}
                    </p>
                  )}
                </>
              )}
            </div>
          )}
          <div className={classes.btnContainer}>
            <Button
              onClick={onClick}
              // disabled={ }
              text={isEnLocale ? 'Start for' : 'Начать'}
              type={'primary-blue-new'}
              className="send"
            >
              <div>
                {party.price > 0 ? (
                  <p className="coin">{party.price}</p>
                ) : (
                  <p className="free">{isEnLocale ? 'free' : 'бесплатно'}</p>
                )}
                {party.price > 0 && <img src={helloCoin} alt="coin" />}
              </div>
            </Button>
            <p>
              {isEnLocale
                ? `You will be charged ${party.price || '0'} tokens`
                : `С баланса будет списано ${party.price || '0'} HITs`}
            </p>
          </div>
        </div>
      </CommonModal>

      <BuyCoinModal isOpen={isBuyCoinModalOpen} handleClose={() => setBuyCoinModalOpen(false)} />
    </>
  );
};

export default PartyStartModal;

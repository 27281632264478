import React from 'react';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { INotification } from '@src/store/room/notifications';
import { useStyles } from './styles';
import { selectIsRoomPublic } from '@src/store/room/roomSlice';
import { LockOn, LockOff } from '@components/icons';

interface Props {
  notification: INotification;
}

const getPublicText = (isEnLocale: boolean) =>
  isEnLocale
    ? 'New players can join your room without invite'
    : 'Новые игроки могут войти в вашу комнату без приглашения';

const getPrivateText = (isEnLocale: boolean) =>
  isEnLocale ? 'Only invited players can join your room' : 'Только приглашённые игроки могут войти в вашу комнату';

const PublicityNotification: React.FC<Props> = ({ notification }) => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const isPublic = useAppSelector(selectIsRoomPublic);
  const styles = useStyles();
  const text = isPublic ? getPublicText(isEnLocale) : getPrivateText(isEnLocale);

  return (
    <div className={styles.publicity}>
      {isPublic ? <LockOff fill={'#5F6372'} /> : <LockOn />}
      {text}
    </div>
  );
};

export { PublicityNotification };

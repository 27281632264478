import React, { useState, useCallback, useEffect, CSSProperties } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createRoomThunk } from '@store/thunk/room';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { selectIsInRoom, getRoomId, selectRoomPlayers, selectIsFirstPlayer } from '@store/room/roomSlice';
import { GameTypes } from '@src/common/constants/common';
import { IdType } from '@shared/generics';
import { MixProps } from '@src/common/generics';
import { startGameEvent } from '@common/sendEvents';
import { shouldShowPlayButton, showInviteThunk } from '@common/misc';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { LoginModal, CreateRoomModal, RenameModal } from '@components/Modals';
import CrownIcon from '@art/icons/crown.svg';
import Button from '@components/Button';
import { useStyles } from './styles';
import { analytics } from '@services/amplitude';
import { FROM_LOCATIONS } from '@src/common/constants/system';
import { selectPlayerId, selectPlayerRoomId, selectIsAuthenticated } from '@src/store/reducers/player.reducer';

interface PropsGreenButton {
  onClick?: () => void;
  text?: string;
}

interface PropsRoomButton {
  subpath?: string;
  automatic?: boolean;
  from?: FROM_LOCATIONS;
}

interface PropsRoomArea extends PropsRoomButton {
  children: (onClick: () => void) => React.ReactNode;
  onClick?: () => void;
  roomId?: string;
  hash: string;
}

// GreenButton
const GreenButton: React.FC<MixProps<PropsGreenButton>> = (props) => {
  return (
    <Button
      onClick={props.onClick}
      text={props.text}
      type={'primary-green-new'}
      className={props.className}
      style={props.style}
    >
      {props.children}
    </Button>
  );
};

// Button
const SimpleButton: React.FC<MixProps<PropsGreenButton>> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperButton}>
      <button className={props.className} onClick={props.onClick} style={props.style}>
        {props.children}
      </button>
    </div>
  );
};

const shouldOpenRoomArea = (props: PropsRoomArea, location: ReturnType<typeof useLocation>) => {
  const subpath = props.subpath ? `?${props.subpath}` : '';
  const hash = `#${props.hash}`;
  return hash === location.hash && subpath === location.search;
};

const getTargetLocation = (location: ReturnType<typeof useLocation>, hash: string, search?: string) => ({
  ...location,
  hash: `#${hash}`,
  search: search ? `?${search}` : undefined,
});

// RoomArea
const RoomArea: React.FC<MixProps<PropsRoomArea>> = (props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const playerRoomId = useAppSelector(selectPlayerRoomId);
  const roomId = useAppSelector(getRoomId);
  const isInRoom = useAppSelector(selectIsInRoom);
  const [showCreate, setShowCreate] = useState(false);
  const targetRoomId = props.roomId || roomId || playerRoomId;

  const onRoomClick = (roomId: string) => {
    history.push(`/room/${roomId}${props.subpath || ''}`, { from: isInRoom ? '' : props.from || '' });
  };

  const onRoomAutocreate = () => {
    dispatch(createRoomThunk('', (roomId) => onRoomClick(roomId)));
  };

  const onRename = useCallback(() => {
    targetRoomId ? onRoomClick(targetRoomId) : props.automatic ? onRoomAutocreate() : setShowCreate(true);
  }, [targetRoomId]);

  const onClick = useCallback(() => {
    props.onClick?.apply(null);
    !isAuthenticated ? login() : onRename();
  }, [isAuthenticated, onRename]);

  const login = () => {
    history.push(
      { pathname: '/login' },
      { target: getTargetLocation(location, props.hash, props.subpath), autologin: false }
    );
  };

  useEffect(() => {
    if (shouldOpenRoomArea(props, location)) {
      history.replace(location.pathname);
      isAuthenticated ? onRename() : undefined;
    }
  }, [isAuthenticated, onRename]);

  return (
    <>
      {showCreate && !props.automatic && (
        <CreateRoomModal isOpen={showCreate} handleClose={() => setShowCreate(false)} onSubmit={onRoomClick} />
      )}
      {props.children(onClick)}
    </>
  );
};

// RoomButton
const RoomButton: React.FC<MixProps<PropsRoomButton>> = (props) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const playerRoomId = useAppSelector(selectPlayerRoomId);
  const [buttonText, setButtonText] = useState('');
  const from = props.from || FROM_LOCATIONS.CREATE_ROOM;
  const isEnLocale = useAppSelector(selectIsEnLocale);

  useEffect(() => {
    setButtonText(
      playerRoomId
        ? isEnLocale
          ? 'Enter Your Room'
          : 'Войти в комнату'
        : isEnLocale
        ? 'Create Your Room'
        : 'Создать комнату'
    );
  }, [playerRoomId, isEnLocale]);

  const onClick = () => {
    const event = playerRoomId ? analytics.EVENTS.ENTER_ROOM_CLICKED : analytics.EVENTS.CREATE_ROOM_CLICKED;
    analytics.logEvent(event);
  };

  if (!isAuthenticated) return <></>;

  return (
    <>
      <RoomArea {...props} onClick={onClick} from={from} hash={'button'}>
        {(onClick: () => void) => (
          <GreenButton onClick={onClick} className={props.className} style={props.style} text={buttonText} />
        )}
      </RoomArea>
    </>
  );
};

interface PropsStartGameButton {
  gameType: GameTypes;
  partyId: IdType;
  single?: boolean;
  onClick?: () => void;
}

interface PropsStartGameArea {
  gameType: GameTypes;
  children: (onClick: () => void) => React.ReactNode;
  onClick?: () => void;
}

// StartGameArea
const StartGameArea: React.FC<MixProps<PropsStartGameArea>> = (props) => {
  const dispatch = useAppDispatch();
  const showPlayButton = shouldShowPlayButton(props.gameType);

  const onClick = () => {
    showPlayButton ? props.onClick?.apply(null) : dispatch(showInviteThunk('BANNER'));
  };

  return <>{props.children(onClick)}</>;
};

// StartGameButton
const StartGameButton: React.FC<MixProps<PropsStartGameButton>> = ({ gameType, partyId, onClick, single = false }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const playerId = useAppSelector(selectPlayerId);
  const roomId = useAppSelector(getRoomId);
  const showPlayButton = shouldShowPlayButton(gameType);
  const isFirstPlayer = useAppSelector(selectIsFirstPlayer);
  const players = useAppSelector(selectRoomPlayers);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const startClick = () => {
    startGameEvent(roomId, playerId, partyId, undefined, gameType);
  };

  const onInviteClick = () => {
    onClick?.apply(null);
    dispatch(showInviteThunk('TOOLTIP'));
  };

  if (!isFirstPlayer) {
    return (
      <div className={classes.ownerText}>
        <span>{isEnLocale ? 'Room owner' : 'Владелец комнаты'}</span>
        <img src={CrownIcon} alt="crown.svg" />
        <span>{isEnLocale ? 'should start the party' : 'должен начать игру'}</span>
      </div>
    );
  }

  return (
    <>
      {single && players.length === 1 && (
        <SimpleButton onClick={startClick} className={`${classes.violetteButton} mb-15`}>
          {isEnLocale ? 'Start Singleplayer' : 'Начать одиночную игру'}
        </SimpleButton>
      )}

      {showPlayButton ? (
        <SimpleButton onClick={startClick} className={classes.violetteButton}>
          {isEnLocale ? 'Start the party' : 'Начать игру'}
        </SimpleButton>
      ) : (
        // onInviteClick
        <SimpleButton onClick={onInviteClick} className={classes.blueButton}>
          {isEnLocale ? 'Invite' : 'Пригласить'}
        </SimpleButton>
      )}
    </>
  );
};

export { GreenButton, RoomButton, RoomArea, StartGameButton, StartGameArea };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  clearAllButton: {
    position: 'absolute',
    zIndex: 5,
    bottom: '10px',
    right: '11px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '92px',
    height: '34px',
    backgroundColor: '#F3F4F5',
    border: '1px solid #E8E9EB',
    borderRadius: '4px',
    boxShadow: '0 0 5px 0 rgba(0,0,0,0.1)',
    cursor: 'pointer',
    '&.up': {
      bottom: '50px',
    },
    '&.desktop': {
      position: 'static',
      height: '32px',
      width: '100%',
      boxShadow: 'none',
      backgroundColor: '#E8E9EB',
      borderRadius: '8px',
      marginBottom: '15px',
    },
    '& > span': {
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      color: '#5F6372',
    },
  },
}));

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  wordShowContainer: {
    justifyContent: 'center',
  },

  wordShowItem: {
    flexDirection: 'column',
    minHeight: '275px',
    flexBasis: '207px',
    alignItems: 'center',
    margin: '0 5px',
    backgroundColor: 'white',
    borderRadius: '10px',
    paddingTop: '5px',
    paddingRight: '5px',
    paddingLeft: '5px',
    backgroundImage: 'linear-gradient(180deg, #FFD957 0, #CAC6FA 80%, #CAC6FA 100%)',
    transition: 'all .1s',
    [theme.breakpoints.up('sm')]: {
      minHeight: '425px',
      flexBasis: '317px',
      margin: '0 15px',
      borderRadius: '20px',
      paddingTop: '10px',
      paddingRight: '10px',
      paddingLeft: '10px',
    },
  },

  wordShowTop: {
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '5px',
    minHeight: '221px',
    [theme.breakpoints.up('sm')]: {
      minHeight: '330px',
    },

    '& > p': {
      textAlign: 'center',
      fontSize: '20px',
      lineHeight: '24px',
      color: '#1B2036',
      fontWeight: 500,
      maxWidth: '170px',
      margin: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
        lineHeight: '31px',
        maxWidth: '200px',
      },
      '& > span': {
        color: '#2C74F3',
        overflow: 'hidden',
        display: 'block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },

  pencilImage: {
    height: '62px',
    width: 'auto',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      height: '85px',
    },
  },

  wordShowBottom: {
    backgroundColor: '#CAC6FA',
    borderRadius: '0 0 10px 10px',
    alignSelf: 'stretch',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    '& > b': {
      flexBasis: '50px',
      fontFamily: 'Nunito',
      color: '#161F43',
      fontSize: '36px',
      lineHeight: '49px',
      fontWeight: 800,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        flexBasis: '80px',
        fontSize: '50px',
        lineHeight: '75px',
      },
    },
    '& > div': {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      marginTop: '5px',
      borderRadius: '0 0 5px 0',
      marginBottom: '5px',
      paddingLeft: '2px',
      paddingRight: '2px',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '10px',
        paddingRight: '5px',
      },
      '& > p': {
        margin: 0,
        color: '#1B2036',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '14px',
        letterSpacing: '-0.8px',
        paddingLeft: '12px',
        paddingRight: '5px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '16px',
          lineHeight: '19px',
        },
      },
    },
  },
}));

import React from 'react';
import { styles } from './styles';
import User from '@art/icons/StatusBar/user.svg';
import { IPlayerCard } from '@src/store/reducers/common';

interface IProps {
  player: IPlayerCard;
  onClick: (playerId: IPlayerCard) => void;
}

const PlayerButton: React.FC<IProps> = ({ player, onClick }) => {
  const classes = styles();
  return (
    <div className={classes.friendItem} onClick={() => onClick(player)}>
      <img src={player.avatar?.image || User} style={{ backgroundColor: `#${player.avatar?.background || 'fff'}` }} />
      <p>{player.name}</p>
    </div>
  );
};

export { PlayerButton };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  fakeArtistHeader: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 10px',
    height: '70px',
    width: '100%',
    backgroundColor: '#305088',
    color: 'rgba(255,255,255, 0.7)',
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      height: '105px',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    [theme.breakpoints.up('md')]: {
      position: 'static',
      zIndex: 1,
      backgroundColor: 'transparent',
      color: '#1B2036',
      padding: 0,
      height: 'auto',
    },
    '& > p': {
      margin: '0 5px',
      color: 'white',
      fontWeight: 600,
      fontSize: '17px',
      lineHeight: '20px',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
        lineHeight: '24px',
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  },

  buttonWrapper: {
    flexShrink: 0,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    minHeight: '46px',
    minWidth: '85px',
    [theme.breakpoints.up('sm')]: {
      flexBasis: 'auto',
      minWidth: '170px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 'auto',
      minHeight: '75px',
    },
  },

  movesLeftInfo: {
    color: 'inherit',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '19px',
    },
  },

  headerTimer: {
    color: 'inherit',
    display: 'inline-flex',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '19px',
    },
  },

  voteButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '75px',
    height: '32px',
    border: '1px solid #000',
    backgroundColor: '#F83E67',
    boxShadow: 'inset 0px -3px 0px #D9264D, inset 0px 15px 0px #FE5479',
    borderRadius: '70px',
    color: 'white',
    fontSize: '20px',
    lineHeight: '28px',
    textShadow: '0 2px 0 #000',
    cursor: 'pointer',
    transition: 'all .23s',
    '&:hover': {
      backgroundColor: '#D9264D',
      boxShadow: 'none',
    },
    '&.ru': {
      width: '100px',
      fontSize: '16px',
      lineHeight: '20px',
      [theme.breakpoints.up('sm')]: {
        width: '140px',
        height: '45px',
        fontSize: '24px',
        lineHeight: '26px',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: '110px',
      height: '55px',
      fontSize: '28px',
      lineHeight: '28px',
    },
  },

  nextButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '75px',
    height: '32px',
    border: '1px solid #000',
    backgroundColor: '#008AFF',
    boxShadow: 'inset 0px -3px 0px #0243A5, inset 0px 15px 0px #00ABFF',
    borderRadius: '70px',
    color: 'white',
    fontSize: '20px',
    lineHeight: '28px',
    textShadow: '0px 2px 0px #000',
    cursor: 'pointer',
    transition: 'all .23s',
    '&.hover': {
      '&:hover': {
        backgroundColor: '#0243A5',
        boxShadow: 'none',
      },
    },
    '&.ru': {
      width: '70px',
      fontSize: '16px',
      lineHeight: '20px',
      [theme.breakpoints.up('sm')]: {
        width: '90px',
        height: '45px',
        fontSize: '24px',
        lineHeight: '26px',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: '110px',
      height: '55px',
      fontSize: '28px',
      lineHeight: '28px',
    },
  },

  whoDrawing: {
    width: '70px',
    minHeight: '32px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '15px',
    lineHeight: '14px',
    fontWeight: 600,
    marginBottom: 'auto',
    textAlign: 'center',
    color: 'white',
    [theme.breakpoints.up('sm')]: {
      width: '110px',
      minHeight: '55px',
      fontSize: '24px',
      lineHeight: '20px',
    },
    [theme.breakpoints.up('md')]: {
      color: '#1B2036',
    },
  },

  whoDrawingName: {
    width: '70px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.up('sm')]: {
      width: '110px',
    },
  },

  whoDrawingPhrase: {
    color: 'inherit',
  },
}));

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  gameContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    minHeight: '100%',
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    // [theme.breakpoints.up('sm')]: {
    //   justifyContent: 'space-between',
    // },
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
  },
}));

import { useGameHook } from './quizClassic.hook';
import { GameTypes } from '@src/common/constants/common';
import GameWrapper from '@components/GameWrapper';
import { StartRound, Question } from './parts';
import { Leaderboard } from '@src/games/common/Leaderboard/Leaderboard';
import { VoteList } from './parts/VoteList/VoteList';
import PartyFinishModal from '../common/PartyFinishModal';

interface IProps {
  roomId: string;
}

const QuizClassic: React.FC<IProps> = ({ roomId }) => {
  const { state, game, isActivePlayer, playerId, isSpectator, actions } = useGameHook(roomId);
  const { onPlayerSpin, onPlayerVoted, onPlayerReady, onGameQuit } = actions;

  const onClick = () => {
    state.TOP_PLAYERS ? onPlayerReady() : onGameQuit();
  };

  if (!game.activeGameId) return <></>;

  return (
    <GameWrapper state={state} withScrollButton={true}>
      {(state.READY_START_ROUND || state.SPIN) && (
        <StartRound game={game} isSpin={state.SPIN} onSpin={onPlayerSpin} isActivePlayer={isActivePlayer} />
      )}

      {(state.ROUND_IN_PROGRESS || state.RESULTS) && (
        <Question
          game={game}
          playerId={playerId}
          onVote={onPlayerVoted}
          onReady={onPlayerReady}
          isSpectator={isSpectator}
          isRightAnswerStatus={state.RESULTS}
        />
      )}

      {state.COMMON_RESULTS && (
        <VoteList game={game} onNext={onPlayerReady} isSpectator={isSpectator} playerId={playerId} />
      )}

      {(state.TOP_PLAYERS || state.LEADERBOARD) && (
        <Leaderboard
          game={game}
          onClick={onClick}
          isSpectator={isSpectator}
          isTopPlayers={state.TOP_PLAYERS}
          gameType={GameTypes.QUIZ_CLASSIC}
          roomId={roomId}
        />
      )}

      {game.reward && <PartyFinishModal isOpen={true} game={game} />}
    </GameWrapper>
  );
};

export { QuizClassic };

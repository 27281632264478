import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { selectIsProfilePage } from '@store/reducers/popups';
import { CommonModal } from '@components/Modals';
import CloseCross from '@src/components/CloseCross/CloseCross';
import { ProfileTab } from './parts/ProfileTab/index';
import { Header } from '../profile/parts/Header/Header';
import { styles } from './styles';
import { useBlockchainData } from '@src/features/blockchain';
import { requestRewards } from '@src/graphql/player.queries';
import { selectOwnAvatar, selectPlayerName } from '@src/store/reducers/player.reducer';
import { IdType } from '@src/shared/generics';

export enum TABS {
  PROFILE,
  ROOM,
}

interface IProps {
  handleClose: () => void;
}

export const ProfilePage: React.FC<IProps> = ({ handleClose }) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const { walletConnected, tokenBalance } = useBlockchainData();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const [activeTab, setActiveTab] = useState(TABS.PROFILE);
  const isProfilePage = useAppSelector(selectIsProfilePage);
  const playerName = useAppSelector(selectPlayerName);
  const playerAvatar = useAppSelector(selectOwnAvatar);
  const [avatarId, setAvatarId] = useState<IdType>(playerAvatar?.id || '');
  const [name, setName] = useState<string>(playerName);

  useEffect(() => {
    if (isProfilePage) dispatch(requestRewards);
  }, [isProfilePage]);

  const coinTabs = [
    { type: TABS.PROFILE, title: isEnLocale ? 'Profile' : 'Профиль' },
    // { type: TABS.ROOM, title: isEnLocale ? 'Room' : 'Комната' },
  ];

  return (
    <CommonModal isOpen={isProfilePage} handleClose={handleClose} className="profile" padding={false}>
      <div className={classes.profileWrapper}>
        <CloseCross onClick={handleClose} style={{ top: '-45px', right: '5px', background: '#F5F3DF' }} />
        <Header tokenBalance={tokenBalance} walletConnected={walletConnected} avatarId={avatarId} name={name} />

        {/* <div className={classes.profileTabs}>
          {coinTabs.map((tab) => (
            <p
              key={tab.type}
              className={`
                  ${activeTab === tab.type ? 'active' : ''}
                `}
              onClick={() => setActiveTab(tab.type)}
            >
              {tab.title}
            </p>
          ))}
        </div> */}

        {activeTab === TABS.PROFILE && <ProfileTab setAvatarId={setAvatarId} setName={setName} />}

        {/* {activeTab === TABS.ROOM && <DepositTab />} */}
      </div>
    </CommonModal>
  );
};

import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  timer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  timerSmall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: '45px',
    height: '45px',
    transition: 'all .23s',
    color: 'white',
    position: 'absolute',
    fontWeight: 'bold',
    fontSize: '25px',
    lineHeight: '14px',
    bottom: 12,
    zIndex: 5,
    right: 5,
    [theme.breakpoints.up('sm')]: {
      width: '100px',
      height: '100px',
      bottom: 22,
    },
    [theme.breakpoints.up('sm')]: {
      width: '55px',
      height: '55px',
      bottom: 12,
    },
  },

  timerBig: {
    position: 'absolute',
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '120px',
    lineHeight: '143px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    // color: '#2C74F3',
    textShadow: '-1px 0 5px white, 0 1px 5px white, 1px 0 5px white, 0 -1px 5px white',
    zIndex: 20,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    top: '60%',
    // border: '6px solid #FFFFFF',
  },
}));

import { makeStyles } from '@material-ui/core';
import closeImage from '@art/images/one-question/close.svg';

export const styles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',
    },
  },

  topContent: {
    width: '100%',
    justifyContent: 'space-between',
    // paddingTop: '10px',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      marginBottom: '35px',
    },
  },

  bottomContent: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: '48px',
    padding: '20px 15px 30px',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '20px 20px 0 0',
    animationDuration: '1s',
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      padding: '30px 15px 30px',
      width: '480px',
      minHeight: '260px',
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: '20px',
    },
    [theme.breakpoints.up('md')]: {
      width: '640px',
    },
  },

  close: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    width: '18px',
    height: '18px',
    cursor: 'pointer',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${closeImage})`,
    backgroundSize: 'cover',
    [theme.breakpoints.up('sm')]: {
      top: '20px',
      right: '20px',
      width: '20px',
      height: '20px',
    },
  },

  title: {
    fontFamily: 'Barbieri-Regular',
    textAlign: 'center',
    color: 'white',
    fontSize: '48px',
    lineHeight: '46px',
    maxWidth: '180px',
    marginTop: '10px',
    textShadow: '-2px 2px 3px black',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '260px',
      fontSize: '72px',
      lineHeight: '68px',
      textShadow: '-4px 4px 3px black',
      marginTop: '40px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));

import { createStyles } from '@material-ui/core/styles';
import { makeStyles, Theme } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export interface StylesProps {
  top: number;
  bottom: number;
  left?: number;
  right: number;
}

export const styles = makeStyles<Theme, StylesProps>((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      zIndex: 11,
      left: 0,
      right: 0,
      top: 0,
      color: 'white',
      height: '100%',
      fontWeight: 500,
      lineHeight: '18px',
      '--animate-duration': '230ms',
      '--animate-delay': '230ms',
    },

    popup: {
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      width: '100%',
      minHeight: '78px',
      padding: '0 15px 0 3px',
      top: '10%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      '--animate-duration': '230ms',
      '--animate-delay': '230ms',
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        padding: '0 80px 0 30px',
        width: '672px',
        minHeight: '138px',
        borderRadius: '20px',
        top: '13%',
      },
      [theme.breakpoints.up('md')]: {
        top: '15%',
      },
    },

    text: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '29px',
      alignItems: 'center',
      color: 'white',
      maxWidth: '450px',
      textOverflow: 'ellipsis',
      wordWrap: 'break-word',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px',
        lineHeight: '43px',
      },
    },

    shout: {
      marginRight: 5,
      [theme.breakpoints.up('sm')]: {
        marginRight: '30px',
      },
    },

    backdrop: {
      height: '100%',
      zIndex: 11,
      background: 'rgba(0, 0, 0, 0.2)',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      top: (props) => `${props.top}px`,
      right: (props) => `${props.right}px !important`,
      bottom: (props) => `${props.bottom}px !important`,
    },
  })
);

import { analytics } from '@services/amplitude';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Greeting, Question, Results } from './parts';
import { styles } from './styles';

const questionText =
  'You make $ 10,000 a month, but ...\n\
Your boss periodically sends his homemade porn to you, asks you to rate his technique in 10 and to give a constructive feedback\n\
\n\
Will you quit this job?';
// "Would you rather spend a year without sex or a year without internet?"

const answers = ['I quit', 'I stay'];
//{['No sex', 'No internet']}

const LandingPage1: React.FC = () => {
  const classes = styles();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [screen, setScreen] = useState('greeting');
  const [isGreetingClicked, setGreetingClick] = useState(false);
  const [isQuestionClicked, setQuestionClick] = useState(false);
  const [isResultsClicked, setResultsClick] = useState(false);
  const version = 1;

  const TIME_OUT = 1000;

  const greetingClick = () => {
    setGreetingClick(true);
    analytics.logEvent(analytics.EVENTS.INTRO_GO, { version });
    setTimeout(() => {
      setScreen('question');
    }, TIME_OUT);
  };

  const questionClick = (value: number) => {
    setQuestionClick(true);
    setValue(value);
    analytics.logEvent(analytics.EVENTS.INTRO_ANSWER_CLICKED, { answer: value, version });
    setTimeout(() => {
      setScreen('results');
    }, TIME_OUT);
  };

  const resultsClick = (value: string) => {
    setResultsClick(true);
    analytics.logEvent(analytics.EVENTS.INTRO_RESULT_CLICKED, { button: value, version });
    setTimeout(() => {
      history.push('/');
    }, TIME_OUT);
  };

  useEffect(() => {
    analytics.logEvent(analytics.EVENTS.INTRO_SHOWN, { version });
  }, []);

  return (
    <div className={classes.landingPage1}>
      <div className={classes.landingPage1Wrapper}>
        {/* greeting */}
        {screen === 'greeting' && <Greeting greetingClick={greetingClick} isGreetingClicked={isGreetingClicked} />}

        {/* question */}
        {screen === 'question' && (
          <Question
            value={value}
            questionClick={questionClick}
            isQuestionClicked={isQuestionClicked}
            answers={answers}
            questionText={questionText}
          />
        )}

        {/* results */}
        {screen === 'results' && (
          <Results resultsClick={resultsClick} isResultsClicked={isResultsClicked} questionText={questionText} />
        )}
      </div>
    </div>
  );
};

export default LandingPage1;

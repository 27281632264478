import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  resultsContainer: {
    flexDirection: 'column',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 35,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '590px',
      flexGrow: 1,
      justifyContent: 'space-around',
    },
    [theme.breakpoints.up('md')]: {
      width: '90%',
    },
  },

  resultsBarsContainer: {
    justifyContent: 'center',
    borderBottom: '1px solid #F3F4F5',
    marginBottom: '10px',
    '&.more-2': {
      justifyContent: 'space-evenly',
      '& > .resultsBarsItem': {
        width: '61px',
        maxWidth: 'none',
        marginLeft: '1%',
        marginRight: '1%',
        paddingLeft: 0,
        paddingRight: 0,
        '&:first-child': {
          marginLeft: '1%',
          marginRight: '1%',
        },
        [theme.breakpoints.up('sm')]: {
          width: '110px',
        },
      },
    },
  },

  resultsWhiteContainer: {
    width: '100%',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '25px 15px 20px',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      padding: '20px 15px 15px 15px',
      marginBottom: '40px',
    },
    [theme.breakpoints.up('md')]: {
      padding: 0,
      backgroundColor: 'transparent',
    },
  },

  resultsBarsItem: {
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '45%',
    maxWidth: '280px',
    paddingLeft: '10%',
    paddingRight: '10%',
    flexShrink: 0,
    flexGrow: 0,
    transition: 'all .23s',
    marginLeft: '15px',
    alignItems: 'center',
    // '&:first-child': {
    //   marginLeft: 0,
    //   marginRight: '15px',
    // },
  },

  resultsNameItem: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    lineHeight: '12px',
    height: '30px',
    width: '80px',
    fontWeight: 400,
    padding: '4px 5px',
    textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:first-child': {
      borderRadius: '4px 4px 0px 0px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
      lineHeight: '21px',
      height: '50px',
      width: '115px',
      display: 'flex',
      alignItems: 'center',
    },
  },

  resultsVotesTitlesContainer: {
    justifyContent: 'center',
    '&.more-2': {
      justifyContent: 'space-evenly',
      '& > .resultsVoteTitlesItem': {
        width: '61px',
        maxWidth: 'none',
        marginLeft: '1%',
        marginRight: '1%',
        lineClamp: 2,
        display: '-webkit-box',
        boxOrient: 'vertical',
        overflow: 'hidden',
        '&:first-child': {
          marginLeft: '1%',
          marginRight: '1%',
        },
        [theme.breakpoints.up('sm')]: {
          width: '110px',
        },
      },
    },
  },

  resultsVoteTitlesItem: {
    position: 'relative',
    width: '45%',
    maxWidth: '280px',
    minHeight: '12px',
    flexShrink: 0,
    flexGrow: 0,
    display: 'block',
    maxHeight: '25px',
    textAlign: 'center',
    marginLeft: '15px',
    wordBreak: 'break-word',
    color: '#1B2036',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '12px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
      lineHeight: '21px',
      minHeight: '21px',
      maxHeight: '40px',
    },
    // '&:first-child': {
    //   marginLeft: 0,
    //   marginRight: '15px',
    // },
  },
  buttonWrapper: {
    position: 'relative',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '56px',
    [theme.breakpoints.up('sm')]: {
      position: 'static',
      minHeight: '60px',
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      height: '50px',
    },

    // button
    '& > button': {
      border: '10px solid white',
      height: '56px',
      [theme.breakpoints.up('sm')]: {
        height: '60px',
      },
      [theme.breakpoints.up('md')]: {
        border: 'none',
        height: '40px',
      },
    },

    '& > span': {
      fontFamily,
      color: 'white',
      fontWeight: 800,
      fontSize: '15px',
      lineHeight: '18px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
      [theme.breakpoints.up('md')]: {
        color: '#5F6372',
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
  },
  crown: {
    width: 55,
    height: 33,
    marginBottom: 7,
    [theme.breakpoints.up('sm')]: {
      width: 75,
      height: 45,
      marginBottom: 10,
    },
  },
}));

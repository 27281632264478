import { styles } from './styles';

type Props = {
  isActivePlayer: boolean;
  timeIsUp?: boolean;
  children: any;
};

const CanvasWrapper: React.FC<Props> = ({ isActivePlayer, timeIsUp, children }) => {
  const classes = styles();

  return (
    <div
      className={classes.canvasWrapper}
      // blocked canvas for passive players or when time is up
      style={{ pointerEvents: !isActivePlayer || timeIsUp ? 'none' : 'auto' }}
    >
      {children}
    </div>
  );
};

export { CanvasWrapper };

import { styles } from './styles';

interface IProps {
  questionText: string;
  buttons: { title: string; value: number }[];
  onResultClick: (value: string) => void;
}

const Results: React.FC<IProps> = ({ questionText, buttons, onResultClick }) => {
  const classes = styles();
  const LARGE_TRIGGER = 100;
  const questionLength = questionText.length;

  return (
    <div className={`${classes.results} animate__animated  animate__fadeIn`}>
      <div className={classes.resultsWrapper}>
        {/* question text */}
        <div
          className={`
          ${classes.questionText}
          ${questionLength >= LARGE_TRIGGER ? 'large' : ''}
        `}
        >
          <p>{questionText}</p>
        </div>

        {/* bars */}
        <div className={classes.barsContainer}>
          <div className={classes.barsWrapper}>
            {buttons.map((button, index: number) => {
              return (
                <div key={index}>
                  <span>{`${button.value}%`}</span>
                  <b>{button.title}</b>
                </div>
              );
            })}
          </div>
        </div>

        {/* buttons */}
        <div className={classes.buttonsContainer}>
          <div className={classes.purple} onClick={() => onResultClick('friends')}>
            <span>See your Friends answers</span>
          </div>

          <div className={classes.blue} onClick={() => onResultClick('homepage')}>
            <span>Next Question</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;

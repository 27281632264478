const DrawAndGuessTextContentEn: React.FC = () => {
  return (
    <>
      <li>An active player and a player who guessed the word get the same amount of points.</li>
      <li>
        Easy word reward - <b>1 point</b>
      </li>
      <li>
        Difficult word reward - <b>2 point</b>
      </li>
      <li>
        If no one guessed correctly, <b>no one</b> gets points
      </li>
    </>
  );
};

const DrawAndGuessTextContentRu: React.FC = () => {
  return (
    <>
      <li>Активный игрок и игрок, угадавший слово, получают одинаковое количество очков</li>
      <li>
        Награда за лёгкое слово - <b>1 очко</b>
      </li>
      <li>
        Награда за сложное слово - <b>2 очка</b>
      </li>
      <li>
        Если никто не отгадал слово, то очки не начисляются <b>никому</b>
      </li>
    </>
  );
};

export { DrawAndGuessTextContentEn, DrawAndGuessTextContentRu };

import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { setWrapperOffsets } from '@store/reducers/session.reducer';
import { MenuBar } from '@components/HomepageBar/MenuBar';
import { RoomButton } from '@components/CreateRoomButton';
import { useStyles } from './styles';
import { useDeviceSize } from '@src/common/screenSizes';
import { getIsHomePageLoading } from '@src/store/reducers/loadActions';
import ConnectToServerLoader from '../ConnectToServerLoader';
import { LoginModal } from '../Modals';
import { LoginButton, MixedLoginButton } from '../LoginButtons';

const MetaWrapper = <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  function MetaComponent({ ...props }) {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const { isMobile, isTablet } = useDeviceSize();
    const topBar = useRef<HTMLDivElement>(null);
    const bottomBar = useRef<HTMLDivElement>(null);
    const IsHomePageLoading = useAppSelector(getIsHomePageLoading);

    useEffect(() => {
      const top = topBar.current?.offsetHeight || 0;
      const bottom = bottomBar.current?.offsetHeight || 0;
      dispatch(setWrapperOffsets({ top, bottom }));
    }, [isMobile, isTablet]);

    return (
      <>
        {/*<LoginModal
          isOpen={true}
          handleClose={() => {}}
          onSubmit={() => {}}
          ignoreOnError={true}
          shouldAutologin={false}
    />*/}
        {IsHomePageLoading ? (
          <ConnectToServerLoader />
        ) : (
          <div className={classes.roomWrapper}>
            <div className={classes.columnWrapper}>
              <div className={classes.header} ref={topBar}>
                {!isMobile && <MenuBar />}
                {!isMobile && (
                  <RoomButton
                    style={{ marginRight: '10px', flexShrink: 0, height: '40px', marginLeft: 'auto' }}
                    className={classes.roomButton}
                  />
                )}
                {!isMobile && <LoginButton style={{ marginRight: '10px' }} />}
              </div>

              <div className={classes.contentContainer}>
                <Component {...props} />
              </div>

              {isMobile && (
                <div className={classes.whiteBackground}>
                  <RoomButton className={classes.roomButton} style={{ marginRight: '10px' }} />
                  <LoginButton />
                </div>
              )}
              {isMobile && <MenuBar ref={bottomBar} />}
            </div>
          </div>
        )}
      </>
    );
  };

export { MetaWrapper };

import { TouchEvent, MouseEvent } from 'react';

/*export type Point = {
  x: number;
  y: number;
};*/

export type Brush = {
  brushColor: string;
  brushRadius: number;
};

export type CanvasSize = {
  canvasWidth: number;
  canvasHeight: number;
};

export type Line = {
  x: number[];
  y: number[];
} & Brush &
  CanvasSize;

export type TouchOrClick<T = Element> = TouchEvent<T> | MouseEvent<T>;
export type DrawEventHandler<T = Element> = (ev: TouchOrClick<T>) => void;
export function isTouchEvent(ev: TouchOrClick): ev is TouchEvent {
  if ((ev as TouchEvent).touches) {
    return true;
  } else {
    return false;
  }
}
export const isTouchEndEvent = (ev: TouchOrClick) => ev.type === 'touchend' || ev.type === 'touchcancel';

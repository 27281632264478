import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  metaMaskPopup: {
    fontFamily,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',

    '& > h2': {
      marginTop: 0,
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '20px',
      textAlign: 'center',
      color: '#1B2036',
      paddingLeft: '10%',
      paddingRight: '10%',
      marginBottom: '15px',
    },
  },

  currentBlockchain: {
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    backgroundColor: '#FDDBE2',
    border: '1px solid #F83E67',
    borderRadius: '8px',
    marginBottom: '15px',
    padding: '5px 15px',
    '& > span': {
      color: '#5F6372',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '21px',
    },

    '& > b': {
      color: '#1B2036',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '21px',
    },
  },
}));

import React from 'react';
import { CommonModal } from '@components/Modals';
import Button from '@components/Button';
import { styles } from './styles';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { useAppSelector } from '@app/hooks';
import CloseCross from '@src/components/CloseCross/CloseCross';
import thanksCoin from '@art/images/modals/donate/thanksCoin.svg';

interface Props {
  isOpen: boolean | undefined;
  handleClose: () => void;
}

const ThanksModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      backgroundColor={'linear-gradient(180deg, #E8FDFF 0%, #BCE1FC 100%)'}
      className={classes.modalWrapper}
    >
      <div className={classes.wrapper}>
        <CloseCross onClick={handleClose} />
        <img className="thanks" src={thanksCoin} alt="" />
        <h3 id="transition-modal-title" style={{ maxWidth: '195px', marginBottom: '0px' }}>
          {isEnLocale ? 'Thank you ' : 'Спасибо '}
        </h3>
        <h3 id="transition-modal-title">{isEnLocale ? 'for your donation!' : 'за твой донат!'}</h3>
        <Button
          type="primary-blue-new"
          text={isEnLocale ? 'Ok' : 'Хорошо'}
          onClick={handleClose}
          style={{ marginTop: '10px' }}
        />
      </div>
    </CommonModal>
  );
};

export { ThanksModal };

import React, { MouseEventHandler } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';
import { MixProps } from '@src/common/generics';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';

interface Props {
  onClick: MouseEventHandler;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  viewAllButton: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#2C74F3',
    border: 0,
    background: 'none',
    outline: 'none',
    cursor: 'pointer',
    flexShrink: 0,
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
    },
  },
}));

const ViewAll: React.FC<MixProps<Props>> = (props) => {
  const classes = useStyles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <button className={classes.viewAllButton} onClick={props.onClick} style={props.style}>
      {isEnLocale ? 'View All' : 'Посмотреть всё'}
    </button>
  );
};

export { ViewAll };

import { useAppDispatch } from '@app/hooks';
import { showInviteThunk } from '@common/misc';
import helloCoin from '@art/icons/coin_flat.svg';
import { styles } from './styles';

const InviteItem: React.FC = () => {
  const classes = styles();
  const dispatch = useAppDispatch();

  const onInviteClick = () => {
    dispatch(showInviteThunk('VIDEO'));
  };

  return (
    <div className={classes.inviteContainer}>
      <div className={classes.inviteWrapper}>
        <div className={classes.inviteButton} onClick={onInviteClick}>
          <span>Invite</span>
          <img src={helloCoin} className={classes.helloCoin} alt="helloCoin" />
        </div>
      </div>
    </div>
  );
};

export default InviteItem;

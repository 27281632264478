import { useEffect, useState } from 'react';
import moment from 'moment';
import { ITimer } from '@src/shared/common';
import { useAppSelector } from '@src/app/hooks';
import { selectServerDiff } from '@src/store/reducers/session.reducer';

const SECOND = 1000;

export const useTimer = (timer: ITimer) => {
  const serverDiff = useAppSelector(selectServerDiff);
  const end = moment(timer.end);
  const duration = Math.round(timer.duration / SECOND);
  const getLeftTime = () => Math.floor((end.diff(moment(new Date())) + serverDiff) / SECOND);
  const leftTime = getLeftTime();
  const [countDown, setCountDown] = useState(leftTime > 0 ? leftTime : 0);
  const timeLeftInPercents = (countDown / duration) * 100;
  const timeIsUp = timer.timeIsUp;

  useEffect(() => {
    const timeout = setInterval(() => {
      if (getLeftTime() > 0) {
        setCountDown(getLeftTime());
      } else {
        setCountDown(0);
        clearInterval(timeout);
      }
    }, SECOND);
    return () => clearInterval(timeout);
  }, [end]);

  return {
    countDown,
    duration,
    timeLeftInPercents,
    timeIsUp,
  };
};

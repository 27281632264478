import GameWrapper from '@components/GameWrapper';
import { GameTypes } from '@src/common/constants/common';
import { Leaderboard } from '@src/games/common/Leaderboard/Leaderboard';
import PartyFinishModal from '../common/PartyFinishModal';
import { useGameHook } from './drawAndGuess.hook';
import { StartRound, ChooseWord, ShowWord, Draw, RightAnswer } from './parts';

interface Props {
  roomId: string;
}

const DrawAndGuess: React.FC<Props> = ({ roomId }) => {
  const { game, isActivePlayer, activePlayer, actions, eventObserver, isSpectator } = useGameHook(roomId);

  const { state } = game;
  const { onSpin, onChoose, onVote, onDrawUpdate, onLiveUpdate, onReady, onQuit, onGameStateRequest } = actions;
  const { READY_START_ROUND, SPIN, CHOOSE_WORD, SHOW_WORD, DRAW, RESULTS, TOP_PLAYERS, LEADERBOARD } = state;
  const padding = !TOP_PLAYERS && !LEADERBOARD ? 0 : '';

  const onClick = () => {
    TOP_PLAYERS ? onReady() : onQuit();
  };

  return (
    <GameWrapper state={state} style={{ padding: `${padding}` }} hasDrawCanvas={DRAW}>
      {(READY_START_ROUND || SPIN) && (
        <StartRound game={game} isSpin={state.SPIN} onSpin={onSpin} isActivePlayer={isActivePlayer} />
      )}

      {CHOOSE_WORD && (
        <ChooseWord game={game} activePlayer={activePlayer} isActivePlayer={isActivePlayer} onChoose={onChoose} />
      )}

      {SHOW_WORD && <ShowWord game={game} activePlayer={activePlayer} isActivePlayer={isActivePlayer} />}

      {DRAW && (
        <Draw
          game={game}
          onVote={onVote}
          onGameStateRequest={onGameStateRequest}
          activePlayer={activePlayer}
          eventObserver={eventObserver}
          isActivePlayer={isActivePlayer}
          onDrawUpdate={onDrawUpdate}
          onLiveUpdate={onLiveUpdate}
        />
      )}

      {RESULTS && <RightAnswer game={game} />}

      {(TOP_PLAYERS || LEADERBOARD) && (
        <Leaderboard
          game={game}
          onClick={onClick}
          isSpectator={isSpectator}
          isTopPlayers={TOP_PLAYERS}
          gameType={GameTypes.DRAW_AND_GUESS}
          roomId={roomId}
        />
      )}

      {game.reward && <PartyFinishModal isOpen={true} game={game} />}
    </GameWrapper>
  );
};

export { DrawAndGuess };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  resultsWrapper: {
    minHeight: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',

    '& > h2': {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '28px',
      color: 'white',
      marginTop: 0,
      marginBottom: '10px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
        marginBottom: '20px',
      },
      [theme.breakpoints.up('md')]: {
        color: '#1B2036',
        marginBottom: '18px',
      },
    },
  },

  leaderboardTable: {
    flexShrink: 0,
    minWidth: '245px',
    maxWidth: '90vw',
    flexDirection: 'column',
    // border: '1px solid #E8E9EB',
    // borderRadius: '15px',
    marginBottom: '25px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '260px',
      marginBottom: '30px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '55px',
    },
  },

  leaderboardTableRow: {
    position: 'relative',
    minHeight: '45px',
    padding: '14px 25px',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#1B2036',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '15px center',
    backgroundSize: '25px auto',
    '&:first-child': {
      borderRadius: '15px 15px 0 0',
    },
    '&:last-child': {
      borderRadius: '0 0 15px 15px',
    },
    '&:nth-child(odd)': {
      backgroundColor: '#FFFFFF',
    },
    '&:nth-child(even)': {
      backgroundColor: 'rgb(249,249,249)',
    },
    [theme.breakpoints.up('sm')]: {
      backgroundSize: '30px auto',
      minHeight: '56px',
      fontSize: '16px',
      lineHeight: '19px',
    },
  },

  leaderboardTableRowIndex: {
    minWidth: '25px',
    marginRight: '15px',
    fontWeight: 700,
  },

  leaderboardTableRowName: {
    marginRight: 'auto',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 1,
    wordWrap: 'break-word',
  },

  leaderboardTableRowPoints: {
    marginLeft: '15px',
    fontWeight: 700,
  },

  resultsMedalImage: {
    position: 'absolute',
    width: '25px',
    height: '40px',
    left: '25px',
    top: 0,
  },

  chestImage: {
    position: 'absolute',
    right: '-19.5px',
    top: '50%',
    marginTop: '-16.5px',
    width: '39px',
    height: '33px',
    '--animate-duration': '230ms',
  },

  smallChestImage: {
    position: 'absolute',
    right: '-15px',
    top: '50%',
    marginTop: '-11.5px',
    width: '30px',
    height: '23px',
    '--animate-duration': '230ms',
  },

  buttonWrapper: {
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    '& > button': {
      width: 'init !important',
    },
    '& > .questionButton': {
      position: 'absolute',
      top: '10px',
      right: '10px',
    },
  },
}));

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Host } from '@graphql/queries';
import { MixProps } from '@src/common/generics';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { showInviteThunk, shouldShowPlayButton } from '@common/misc';
import { selectRoomPlayers } from '@store/room/roomSlice';
import { setChosenGame } from '@store/reducers/common';
import Card from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles/partyCard';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { useLocaleString } from '@store/thunk/session.thunk';
import helloCoin from '@art/icons/coin_flat.svg';
import Button from '../Button';
import { PartyStartModal } from '../Modals';
import { selectIsPartyPlayed } from '@src/store/reducers/player.reducer';

interface Props {
  party: Host;
  isSingle?: boolean;
  onlyOnePlayer?: boolean;
}

enum PARTY_TAGS {
  FAMILY = 'Family',
  FRIENDS = 'Friends',
  ROMANCE = 'Romance',
}

const getPartyTagColor = (tag: string) => {
  switch (tag) {
    case PARTY_TAGS.FAMILY:
      return 'linear-gradient(168.91deg, #BFFFCD 0%, #93EFDD 101.99%)';
    case PARTY_TAGS.FRIENDS:
      return 'linear-gradient(169.21deg, #C6EDFF 0%, #A3C6FF 102.45%)';
    case PARTY_TAGS.ROMANCE:
      return 'linear-gradient(168.99deg, #FFCCF3 0%, #FFB7BA 102.11%)';
    default:
      return 'yellow';
  }
};

const getPartyTagTextEn = (tag: string) => {
  switch (tag) {
    case PARTY_TAGS.FAMILY:
      return 'Family';
    case PARTY_TAGS.FRIENDS:
      return 'Friends';
    case PARTY_TAGS.ROMANCE:
      return 'Romance';
    default:
      return 'New';
  }
};

const getPartyTagTextRu = (tag: string) => {
  switch (tag) {
    case PARTY_TAGS.FAMILY:
      return 'Семья';
    case PARTY_TAGS.FRIENDS:
      return 'Друзья';
    case PARTY_TAGS.ROMANCE:
      return 'Романтика';
    default:
      return 'Новая';
  }
};

const PartyCard: React.FC<MixProps<Props>> = ({ party, className, isSingle, onlyOnePlayer = false }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isPartyPlayed = useAppSelector(selectIsPartyPlayed(party.id));
  const tag = party.tags[0] || 'Play me';
  const gameColor = getPartyTagColor(tag);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const title = party.title || useLocaleString(party.lTitle);
  const showPlayButton = party.game.type && shouldShowPlayButton(party.game.type);
  const canPlay = isSingle || showPlayButton;
  const players = useSelector(selectRoomPlayers);
  const forOnlyOnePlayer = onlyOnePlayer && players.length > 1;
  const [isPartyStartModalOpen, setPartyStartModalOpen] = useState(false);

  const startClick = () => {
    if (forOnlyOnePlayer) return;
    dispatch(setChosenGame({ gameId: party.game.id }));
    canPlay ? setPartyStartModalOpen(true) : dispatch(showInviteThunk('PARTY_CARD'));
  };

  const partyRating = party.rate || 5;
  const integerPartOfRating = Math.trunc(partyRating);
  const fractionalPartOfRating = partyRating - integerPartOfRating;

  const getWidth = (index: number) => {
    if (index + 1 <= integerPartOfRating) {
      return '100%';
    } else if (index === integerPartOfRating && fractionalPartOfRating) {
      return '50%';
    } else '0%';
  };

  const renderStars = (numberOfStars = 5) => {
    return Array.from({ length: numberOfStars }, (item, index) => (
      <div className={classes.star} key={index}>
        <div className={`${classes.star} ${classes.active}`} style={{ width: getWidth(index) }} />
      </div>
    ));
  };

  return (
    <>
      <Card className={`${classes.root} ${className}`} onClick={startClick}>
        <div className={classes.text}>
          <div className={classes.firstLine}>
            <Typography className={classes.headline}>{title}</Typography>
            <div>{renderStars()}</div>
          </div>

          <div style={{ justifyContent: 'space-between', alignItems: 'center', marginTop: 'auto' }}>
            <div style={{ display: 'inline-flex' }}>
              {/* game tag */}
              {tag && (
                <div className={classes.gameType} style={{ background: `${gameColor}` }}>
                  {isEnLocale ? getPartyTagTextEn(tag) : getPartyTagTextRu(tag)}
                </div>
              )}

              {/* played tag */}
              {isPartyPlayed && (
                <div
                  className={classes.gameType}
                  style={{ background: 'linear-gradient(167.61deg, #F4F6F9 0%, #C5D0E2 100%)' }}
                >
                  {isEnLocale ? 'Played' : 'Сыграно'}
                </div>
              )}
            </div>

            {/* play-button */}
            {forOnlyOnePlayer ? (
              <div className={classes.start} style={{ fontFamily: isEnLocale ? 'Barbieri-Regular' : 'Impact' }}>
                <div>{isEnLocale ? 'Only one player' : 'Только один игрок'}</div>
              </div>
            ) : canPlay ? (
              <Button
                onClick={startClick}
                text={isEnLocale ? 'Play' : 'Игра'}
                type={'primary-green-new'}
                size={'small'}
              >
                {party.price > 0 && (
                  <div className="coin">
                    {party.price}
                    <img src={helloCoin} alt="coin" />
                  </div>
                )}
              </Button>
            ) : (
              <Button
                onClick={startClick}
                text={isEnLocale ? 'Invite' : 'Пригласить'}
                type={'primary-blue-new'}
                size={'small'}
              />
            )}
          </div>
        </div>
      </Card>

      <PartyStartModal isOpen={isPartyStartModalOpen} handleClose={() => setPartyStartModalOpen(false)} party={party} />
    </>
  );
};

export { PartyCard };

import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  containerViewAll: {
    display: 'block',
    padding: '30px',
    boxSizing: 'border-box',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    justifyItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '40px 30px',
      maxWidth: '720px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '1410px',
    },
  },

  gridWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(85px, 95px))',
    gridGap: '15px',
    gridAutoRows: 'auto',
    gridAutoColumns: 'auto',
    // justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(105px, 150px))',
      gridColumnGap: '20px',
    },
  },
}));

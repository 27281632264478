import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectIsEnergyPage } from '@store/reducers/popups';
import { CommonModal } from '@components/Modals';
import { EnergyHeader } from './parts/EnergyHeader/EnergyHeader';
import { styles } from './styles';
import CloseCross from '@src/components/CloseCross/CloseCross';
import { Quests } from './parts/Quests/index';
import { selectQuests } from '@src/store/reducers/player.reducer';
import { requestEnergy } from '@src/graphql/player.queries';
import { useEffect } from 'react';

interface IProps {
  handleClose: () => void;
}

const EnergyPage: React.FC<IProps> = ({ handleClose }) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const isEnergyPage = useAppSelector(selectIsEnergyPage);
  const quests = useAppSelector(selectQuests);

  useEffect(() => {
    if (isEnergyPage) dispatch(requestEnergy);
  }, [isEnergyPage]);

  return (
    <CommonModal isOpen={isEnergyPage} handleClose={handleClose} className="profile" padding={false}>
      <div className={classes.energyWrapper}>
        <CloseCross onClick={handleClose} style={{ top: '-45px', right: '5px', background: '#F5F3DF' }} />
        <EnergyHeader />
        <Quests quests={quests} />
      </div>
    </CommonModal>
  );
};

export { EnergyPage };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  personName: {
    width: '100%',
    padding: '0 2px',
    position: 'absolute',
    // zIndex: 1,
    left: 0,
    right: 0,
    bottom: '-18px',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      bottom: '-22px',
      padding: '0 5px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
      bottom: 0,
      justifyContent: 'center',
      alignItems: 'center',
      height: '26px',
      fontWeight: 400,
      color: '#FFF',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      borderRadius: '0px 0px 4px 4px',
    },
  },

  personNameWrapper: {
    position: 'relative',
    // zIndex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '13px',
    lineHeight: '12px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '19px',
    },

    [theme.breakpoints.up('md')]: {
      fontWeight: 400,
      color: '#FFF',
    },

    // when mute
    // '&.muteAudio': {
    //   paddingLeft: '12px',

    //   [theme.breakpoints.up('sm')]: {
    //     paddingLeft: '16px',
    //   },
    //   [theme.breakpoints.up('md')]: {
    //     paddingLeft: '18px',
    //   },
    // },

    '& .mute': {
      display: 'contents',
      '& img': {
        marginRight: '5px',
        width: '10px',
        height: '10px',
      },
    },

    // mute-audio icon
    '& > svg': {
      position: 'absolute',
      // zIndex: 2,
      width: '10px',
      height: '10px',
      left: 0,
      top: '50%',
      marginTop: '-5px',
      [theme.breakpoints.up('sm')]: {
        width: '16px',
        height: '16px',
        marginTop: '-8px',
      },
    },
  },
}));

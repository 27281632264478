import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  switcherContainer: {
    fontFamily: fontFamily,
    height: '15px',
    maxWidth: '55px',
    boxSizing: 'border-box',
    alignItems: 'center',
    marginLeft: 'auto',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '0px',
    },
  },

  switcherBar: {
    position: 'relative',
    height: '15px',
    width: '30px',
    minWidth: '30px',
    flexShrink: 0,
    borderRadius: '10px',
    backgroundColor: '#F3F4F5',
    border: '1px solid #E8E9EB',
    marginRight: '6px',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },

  switcherThumb: {
    position: 'absolute',
    top: '-1px',
    height: '15px',
    left: '-1px',
    width: '15px',
    backgroundColor: '#2C74F3',
    borderRadius: '50%',
    transitionDuration: '0.23s',
    transitionProperty: 'left',
    '&.switch-right': {
      left: '14px',
    },
  },

  switcherLang: {
    color: '#5F6372',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '15px',
  },

  tooltip: {
    background: '#FFCC00',
    borderRadius: '20px',
    padding: 10,
    zIndex: 2,
    top: '-105px',
    left: '-145px',
    position: 'absolute',
    transform: 'rotate(-180deg)',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      transform: 'rotate(0deg)',
      top: '30px',
      left: '-36px',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      left: '30px',
      top: '-34px',
      border: '20px solid transparent',
      borderBottom: '15px solid #FFCC00',
    },
  },

  tooltipContent: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '24px',
    display: 'black',
    alignItems: 'center',
    textAlign: 'center',
    color: '#1B2036',
    flexShrink: 0,
    flexDirection: 'column',
    transform: 'rotate(-180deg)',
    [theme.breakpoints.up('sm')]: {
      transform: 'rotate(0deg)',
    },
  },

  switcherTooltipBar: {
    height: '40px',
    width: '190px',
    flexShrink: 0,
    borderRadius: '30px',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    marginTop: '5px',
    marginBottom: 0,
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 15,
    lineHeight: '18px',
    textAlign: 'center',
    color: '#2C74F3',
    padding: '11px 20px',
    cursor: 'pointer',
    '&.switch-right': {
      justifyContent: 'flex-start',
    },
    '&.switch-left': {
      justifyContent: 'flex-end',
    },
  },

  switcherTooltipThumb: {
    position: 'absolute',
    background: '#2C74F3',
    borderRadius: '30px',
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 15,
    lineHeight: '18px',
    textAlign: 'center',
    color: '#FFFFFF',
    textShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
    padding: '9px 20px',
    left: '2px',
    right: 'auto',
    transition: 'ease-out 0.23s',
    // transitionDuration: '0.23s',
    // transitionProperty: 'left',
    [theme.breakpoints.up('sm')]: {
      top: '2px',
    },
    '&.switch-right': {
      left: '87px',
    },
  },
}));

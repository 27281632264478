import { FiveSecondsState, FIVE_SECONDS_STATE } from '@src/shared/gameInterfaces/fiveSeconds.socket';
// import { TypeInfo } from 'graphql';

export const initialMapState = {
  READY_START_ROUND: false,
  SPIN: false,
  SHOW_WORD: false,
  PREPARE: false,
  GAME: false,
  VOTE: false,
  VOTE_RESULTS: false,
  // RESULTS: false,
  TOP_PLAYERS: false,
  LEADERBOARD: false,
};

export const defaultGameState: FiveSecondsState = {
  gameState: FIVE_SECONDS_STATE.READY_START_ROUND,
  activeGameId: '',
  activePlayerId: '',
  roundNumber: 1,
  totalRounds: 20,
};

export type MapState = typeof initialMapState;

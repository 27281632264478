import GameWrapper from '@components/GameWrapper';
import { useGameHook } from './RoastBattle.hook';
import { GameTypes } from '@src/common/constants/common';
import { StartRound, PlayerContainer, Question, Answers, Bars } from './parts';
import { Leaderboard } from '@src/games/common/Leaderboard/Leaderboard';
import { styles } from './styles';
import PartyFinishModal from '../common/PartyFinishModal';

interface Props {
  roomId: string;
}

const RoastBattle: React.FC<Props> = ({ roomId }) => {
  const classes = styles();
  const { state, game, actions, isActivePlayer, isPairPlayer, isRoundPlayers, playerId, isSpectator } = useGameHook(
    roomId
  );
  const { onSpin, onVote, onReady, onQuit } = actions;

  const onClick = () => {
    state.TOP_PLAYERS ? onReady() : onQuit();
  };

  return (
    <GameWrapper state={state}>
      <div className={classes.gameContainer}>
        {(state.READY_START_ROUND || state.SPIN) && (
          <StartRound game={game} isSpin={state.SPIN} onSpin={onSpin} isActivePlayer={isActivePlayer} />
        )}

        {(state.BATTLE || state.VOTE || state.RESULTS) && (
          <Question game={game} state={state} isRoundPlayers={isRoundPlayers} />
        )}

        {state.BATTLE && (
          <PlayerContainer
            game={game}
            state={state}
            isRoundPlayers={isRoundPlayers}
            isActivePlayer={isActivePlayer}
            isPairPlayer={isPairPlayer}
          />
        )}

        {state.VOTE && (
          <Answers
            game={game}
            onVote={onVote}
            isRoundPlayers={isRoundPlayers}
            playerId={playerId}
            isSpectator={isSpectator}
          />
        )}

        {state.RESULTS && (
          <Bars game={game} onReady={onReady} isRoundPlayers={isRoundPlayers} isSpectator={isSpectator} />
        )}

        {(state.TOP_PLAYERS || state.LEADERBOARD) && (
          <Leaderboard
            game={game}
            onClick={onClick}
            isSpectator={isSpectator}
            isTopPlayers={state.TOP_PLAYERS}
            gameType={GameTypes.ROAST_BATTLE}
            roomId={roomId}
          />
        )}

        {game.reward && <PartyFinishModal isOpen={true} game={game} />}
      </div>
    </GameWrapper>
  );
};

export { RoastBattle };

import { ChestsState, CHESTS_STATE } from '@shared/gameInterfaces/chests.socket';

export const initialMapState = {
  READY_START_ROUND: false,
  ROUND_IN_PROGRESS: false,
  LEADERBOARD: false,
};

export const defaultGameState: ChestsState = {
  gameState: CHESTS_STATE.READY_START_ROUND,
  roundNumber: 1,
  totalRounds: 10,
  stakeAmount: 10,
  stakedPlayers: [],
  skippedPlayers: [],
  activeGameId: '',
};

export type MapState = typeof initialMapState;

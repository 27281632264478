import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  wordChoose: {
    width: '100%',
    minHeight: '100%',
    flexGrow: 1,
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
    justifyContent: 'center',
    color: '#1B2036',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
    [theme.breakpoints.up('md')]: {
      padding: '30px',
    },

    '& > .timerContainer': {
      position: 'absolute',
      top: '15px',
      right: '15px',
      opacity: '0.75',

      // iPhone 5
      ['@media (max-width:365px)']: {
        top: '5px',
        right: '5px',
      },
    },
  },
}));

import { helpStyles } from './styles';
import { HelpQuestion, HelpWallets } from './helpPart/helpQuestion';
import coin from '@art/icons/coin.svg';
import { useState } from 'react';
import { WhatIsHelloToken, HowWithdraw, Security, Answer } from './helpPart/answers';

const HelpContentEn: React.FC = () => {
  const classes = helpStyles();
  const [question, setAnswer] = useState(0);

  const onBackClick = () => {
    setAnswer(0);
  };

  const renderAnswer = (question: number) => {
    switch (question) {
      case 1:
        return (
          <Answer onClick={onBackClick}>
            <WhatIsHelloToken />
          </Answer>
        );
      case 2:
        return;
      case 3:
        return (
          <Answer onClick={onBackClick}>
            <HowWithdraw />
          </Answer>
        );
      case 4:
        return (
          <Answer onClick={onBackClick}>
            <Security />
          </Answer>
        );
    }
  };

  return (
    <>
      {question !== 0 ? (
        renderAnswer(question)
      ) : (
        <div className={classes.container}>
          <div className={classes.helpContainer}>
            <p className={classes.title}>HELP </p>
            <p className={classes.subtitle}>
              A crypto wallet is an application or hardware device that allows individuals to store and retrieve digital
              items
            </p>
          </div>
          <HelpQuestion src={coin} text={'What is a Hello Token?'} onClick={() => setAnswer(1)} />
          {/* <HelpWallets text={'Available wallets'} onClick={() => setAnswer(2)}/> */}
          <HelpQuestion text={'How do I withdraw my funds from Hello?'} onClick={() => setAnswer(3)} />
          <HelpQuestion text={'Safety and Privacy policy'} onClick={() => setAnswer(4)} />
        </div>
      )}
    </>
  );
};

const HelpContentRu: React.FC = () => {
  const classes = helpStyles();
  const [question, setAnswer] = useState(0);

  const onBackClick = () => {
    setAnswer(0);
  };

  const renderAnswer = (question: number) => {
    switch (question) {
      case 1:
        return (
          <Answer onClick={onBackClick}>
            <WhatIsHelloToken />
          </Answer>
        );
      case 2:
        return;
      case 3:
        return (
          <Answer onClick={onBackClick}>
            <HowWithdraw />
          </Answer>
        );
      case 4:
        return (
          <Answer onClick={onBackClick}>
            <Security />
          </Answer>
        );
    }
  };

  return (
    <>
      {question !== 0 ? (
        renderAnswer(question)
      ) : (
        <div className={classes.container}>
          <div className={classes.helpContainer}>
            <p className={classes.title}>Помощь </p>
            <p className={classes.subtitle}>
              A crypto wallet is an application or hardware device that allows individuals to store and retrieve digital
              items
            </p>
          </div>
          <HelpQuestion src={coin} text={'What is a Hello Token?'} onClick={() => setAnswer(1)} />
          {/* <HelpWallets text={'Available wallets'} onClick={() => setAnswer(2)}/> */}
          <HelpQuestion text={'How do I withdraw my funds from Hello?'} onClick={() => setAnswer(3)} />
          <HelpQuestion text={'Safety and Privacy policy'} onClick={() => setAnswer(4)} />
        </div>
      )}
    </>
  );
};

export { HelpContentEn, HelpContentRu };

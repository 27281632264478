import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  resultsWrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
  },

  resultsInner: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '540px',
      flexGrow: 0.5,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '320px',
      flexGrow: 1,
    },

    '& > img': {
      width: '85px',
      height: '85px',
      borderRadius: '50%',
      marginBottom: '15px',
      [theme.breakpoints.up('sm')]: {
        width: '100px',
        height: '100px',
      },
    },
    '& > p': {
      margin: 0,
      marginBottom: '50px',
      fontSize: '20px',
      lineHeight: '28px',
      borderRadius: '50%',
      color: '#1B2036',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '100px',
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: '30px',
      },
      '& > b': {
        fontWeight: 700,
      },
    },
  },

  resultsCount: {
    fontFamily: '"Bebas Neue", cursive',
    fontSize: '180px',
    lineHeight: '180px',
    fontWeight: 'normal',
    [theme.breakpoints.up('sm')]: {
      fontSize: '280px',
      lineHeight: '280px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '220px',
      lineHeight: '220px',
    },
  },

  buttonWrapper: {
    minHeight: '40px',
    alignSelf: 'center',
    textAlign: 'center',
  },
}));

import { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { styles } from './styles';

import CoffeButtonImg from '@art/images/guess-me/coffee-button.svg';

// mobile images
import CoffeCupImgMobile from '@art/images/guess-me/coffee-cup-mobile.png';
import CoffePhraseImgMobile from '@art/images/guess-me/coffee-phrase-mobile.png';

// tablet images
import CoffeCupImgTablet from '@art/images/guess-me/coffee-cup-tablet.png';
import CoffePhraseImgTablet from '@art/images/guess-me/coffee-phrase-tablet.png';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { useAppSelector } from '@app/hooks';
import { GuessMeState } from '@src/shared/gameInterfaces/guessMe.socket';

interface IProps {
  game: GuessMeState;
  isActivePlayer: boolean;
  onSpin: () => void;
  isSpin: boolean;
}

const StartRound: React.FC<IProps> = ({ game, isActivePlayer, onSpin, isSpin }) => {
  const classes = styles();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { roundNumber, totalRounds } = game;
  const [isButtonClicked, setButtonClicked] = useState(false);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const spinClick = () => {
    setButtonClicked(true);
    onSpin();
  };

  const showTitleEn = roundNumber === totalRounds ? 'Final round' : `Question ${roundNumber} of ${totalRounds}`;
  const showTitleRu = roundNumber === totalRounds ? 'Финальный раунд' : `Вопрос ${roundNumber} из ${totalRounds}`;

  return (
    <div className={`${classes.startWrapper} animate__animated  animate__fadeInDown`}>
      <h2 className={`${classes.title} mobile-outline`}>{isEnLocale ? showTitleEn : showTitleRu}</h2>

      <div className={classes.cupWrapper}>
        {/* coffee-cup */}
        <img src={isMobile ? CoffeCupImgMobile : CoffeCupImgTablet} className={classes.coffeeCup} alt="coffee cup" />

        {/* guess-me phrase */}
        <img
          src={isMobile ? CoffePhraseImgMobile : CoffePhraseImgTablet}
          className={classes.cupPhrase}
          alt="guess-me phrase"
          style={{ animationName: isSpin ? 'rotating-540' : '' }}
        />

        {/* button */}
        {isActivePlayer && (
          <img
            onClick={spinClick}
            src={CoffeButtonImg}
            className={`${classes.cupButton} ${isButtonClicked ? 'clicked' : ''}`}
          />
        )}
      </div>
    </div>
  );
};

export { StartRound };

import React, { useEffect, useState } from 'react';
import { FROM_LOCATIONS } from '@src/common/constants/system';
import { useHistory, useLocation } from 'react-router-dom';
import { LoginRenameModal } from '@components/Modals';
import { useAppSelector } from '@src/app/hooks';
import { selectIsAuthenticated } from '@src/store/reducers/player.reducer';
import { getIsPlayerLoading } from '@src/store/reducers/loadActions';
import ConnectToServerLoader from '@src/components/ConnectToServerLoader';
import { useAuth } from 'oidc-react';
import { LOCATION_PATH_PARAM } from '@src/features/auth/auth.config';

const getStoredLocation = () => {
  const storedString = localStorage.getItem(LOCATION_PATH_PARAM);
  return storedString ? JSON.parse(storedString) : undefined;
};

const getTargetLocation = (location: unknown): { target: any; autologin?: boolean } => {
  return location || getStoredLocation() || { target: { pathname: '/' } };
};

const Login: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const isLoading = useAppSelector(getIsPlayerLoading);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [initialized, setIsInitialized] = useState(false);
  const auth = useAuth();
  const showLoading = !initialized || isAuthenticated || isLoading;
  const targetLocation = getTargetLocation(location.state);

  const onLogin = () => {
    localStorage.removeItem(LOCATION_PATH_PARAM);
    // There's a warning that from has already state sometimes.
    // May be history.replace?
    history.push(targetLocation.target, { from: FROM_LOCATIONS.LOGIN });
  };

  useEffect(() => {
    if (!auth.isLoading && !auth.userData) {
      localStorage.setItem(LOCATION_PATH_PARAM, JSON.stringify(targetLocation));
      auth.signIn();
    }

    if (auth.userData) {
      setIsInitialized(true);
    }
  }, [auth]);

  useEffect(() => {
    if (isAuthenticated) onLogin();
  }, [isAuthenticated]);

  return showLoading ? <ConnectToServerLoader /> : <LoginRenameModal onSubmit={() => {}} handleClose={() => {}} />;
};

export default Login;

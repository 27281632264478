import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  personsContainer: {
    fontFamily,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'flex-start',
    width: '100%',
    paddingBottom: '20px',
    backgroundColor: 'white',
    borderBottom: '1px solid #E8E9EB',
    boxSizing: 'border-box',

    '& *': {
      boxSizing: 'border-box',
    },

    // 600px
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '30px',
    },

    // 960px
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      paddingBottom: '0px',
      height: '100%',

      // mute-video svg icon
      '& > svg': {
        position: 'absolute',
        width: '13px',
        height: '9px',
        left: '50%',
        top: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        [theme.breakpoints.up('sm')]: {
          width: '25px',
          height: '19px',
        },
      },

      // person avatar when video is mute
      '& > img': {
        width: '14vw',
        height: '14vw',
        objectFit: 'cover',
        borderRadius: '50%',
        // filter: 'brightness(55%)',
        [theme.breakpoints.up('sm')]: {
          width: '16vw',
          height: '16vw',
        },
        [theme.breakpoints.up('md')]: {
          width: '10vh', // 12vh - after new header
          height: '10vh', // 12vh - after new header
        },
      },

      // fix for Safari (black bg overflow)
      '& + div': {
        backgroundColor: 'transparent !important',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '24px',
      },
    },

    personVideo: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '8px',
      backgroundColor: '#F3F4F5', // invite bg
      overflow: 'hidden',
      position: 'relative',
      // zIndex: 2,
      '& video': {
        borderRadius: '8px',
      },
      // when video off
      '&.muteVideo video': {
        display: 'none',
      },
      '&.muteVideo .muteVideoOverlay': {
        display: 'flex',
      },
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },

    personStatus: {
      fontFamily,
      position: 'absolute',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      bottom: 0,
      right: 0,
      paddingBottom: '5px',
      paddingLeft: 0,
      paddingRight: 0,
      borderBottom: 'none',
      borderLeft: '1px solid #E8E9EB',
    },
  },
}));

import { makeStyles } from '@material-ui/core';

import Pen from '@art/images/fake-artist/pen.svg';
import PenPattern from '@art/images/fake-artist/pen-pattern.svg';

export const styles = makeStyles((theme) => ({
  ActivePlayerAlert: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    height: '70px',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#3ab957',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${Pen}), url(${PenPattern})`,
    backgroundPosition: 'left center, 0 0',
    animationDuration: '1000ms',
    [theme.breakpoints.up('sm')]: {
      height: '85px',
      backgroundSize: '70px auto, cover',
      backgroundPosition: '15px center, 0 0',
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '0 20px 0 0',
      backgroundImage: `url(${PenPattern})`,
      backgroundPosition: 'auto',
      backgroundSize: 'cover',
    },
    ['@media (min-width:1350px)']: {
      backgroundImage: `url(${Pen}), url(${PenPattern})`,
      backgroundPosition: '15px center, 0 0',
      backgroundSize: '70px auto, cover',
      paddingLeft: '80px',
    },
  },

  ActivePlayerAlertText: {
    flexDirection: 'column',
    paddingLeft: '50px',
    paddingRight: '50px',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '90px',
      paddingRight: '90px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },

    '& > p': {
      fontFamily: 'SF Pro Rounded',
      fontSize: '20px',
      lineHeight: 1.2,
      fontWeight: 700,
      color: 'white',
      margin: 0,
      marginBottom: '5px',
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('sm')]: {
        marginBottom: 0,
        fontSize: '36px',
        lineHeight: '43px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '22px',
      },
      ['@media (min-width:1180px)']: {
        fontSize: '36px',
      },
    },

    '& > span': {
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#1B2036',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
  },
}));

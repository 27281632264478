import React, { MouseEventHandler } from 'react';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import Button from '@components/Button';
import { CommonModal } from '@components/Modals';
import { styles } from './styles';
import { useAppSelector } from '@app/hooks';

interface Props {
  isOpen: boolean;
  handleClose: MouseEventHandler;
}

const LogoModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const text = isEnLocale
    ? 'You need to finish the game first to go to the Homepage. Сlick on the ... in the upper right corner for exit.'
    : 'Для перехода на Главную следует сначала завершить игру. Нажми на ... в верхнем правом углу для выхода';
  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} backgroundColor={'white'}>
      <div className={classes.wrapper}>
        <h3 id="transition-modal-title">{isEnLocale ? 'Finish the game first' : 'Сначала закончи игру'}</h3>
        <p className={classes.text}>{text}</p>
        {/* <div className={classes.btnContainer}> */}
        <Button text={isEnLocale ? 'Okay' : 'Понятно'} onClick={handleClose} type="primary-blue" />
        {/* </div> */}
      </div>
    </CommonModal>
  );
};

export default LogoModal;

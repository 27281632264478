import { makeStyles } from '@material-ui/core';
import { fontFamily } from '../../../../art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  questionCardContainer: {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    // flexGrow: 1,
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flexGrow: 0,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '620px',
    },
  },

  timerWrapper: {
    position: 'relative',
    height: '40px',
    width: '100%',
    marginTop: '-15px',
    marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '-30px',
      marginBottom: '10px',
      height: '50px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '15px',
      marginBottom: 0,
    },

    // fix timer on mobile
    '& > div': {
      position: 'fixed',
      zIndex: 10,
      width: 'calc(100% - 30px)', // minus 15 + 15 paddings
      [theme.breakpoints.up('sm')]: {
        width: 'calc(100% - 60px)', // minus 30 + 30 paddings
      },
      [theme.breakpoints.up('md')]: {
        position: 'static',
        width: '100%',
      },
    },
  },

  questionCardText: {
    fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#1B2036',
    marginBottom: '13px',
    width: '100%',
    borderRadius: '20px',
    backgroundColor: 'white',
    padding: '8px 15px',
    // marginTop: 'auto',
    [theme.breakpoints.up('sm')]: {
      padding: '10px 15px',
      fontSize: '28px',
      lineHeight: '33px',
    },
    [theme.breakpoints.up('md')]: {
      padding: 0,
      borderRadius: 0,
      backgroundColor: 'transparent',
      marginTop: '7px',
    },
  },

  questionCardAnswers: {
    fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    boxShadow: 'none',
    opacity: 1,
    wordBreak: 'break-word',
    width: '48%',
    '&:first-child': {
      marginRight: '15px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      lineHeight: '17px',
      paddingLeft: '10px',
      paddingRight: '10px',
      '&:first-child': {
        marginRight: '20px',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '17px',
      paddingLeft: '10px',
      paddingRight: '10px',
      marginTop: '12px',
      maxWidth: '420px',
      '&:first-child': {
        marginRight: '35px',
      },
    },
    '&:disabled': {
      backgroundColor: '#F3F4F5',
      color: '#5F6372',
      border: '1px solid #E8E9EB',
    },
  },

  questionCardGifContainer: {
    maxWidth: '100%',
    flexDirection: 'column',
    paddingLeft: '15px',
    paddingRight: '15px',
  },

  gameTimerWrapper: {
    // position: 'absolute',
    // zIndex: 2,
    // top: 0,
    // left: 15,
    // right: 15,
    [theme.breakpoints.up('md')]: {
      // position: 'absolute',
      // top: 'auto',
      // bottom: 0,
      // left: 30,
      // right: 30,
      overflowY: 'hidden',
      overflowX: 'visible',
    },
  },

  questionButtons: {
    flexDirection: 'column',
    // marginTop: 'auto',
    width: '100%',
  },

  questionButtonsWrapper: {
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '15px',
    [theme.breakpoints.up('md')]: {
      padding: 0,
      backgroundColor: 'transparent',
      borderRadius: 0,
    },
  },
}));

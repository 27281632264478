import { useEffect } from 'react';
import { useGameHook } from './game.hooks';
import { updateLabelsThunk } from './game.labels';
import { useAppDispatch } from '@src/app/hooks';
import { GameTypes } from '@src/common/constants/common';
import GameWrapper from '@components/GameWrapper';
import { Spin, GifCard, RoundResults, QuestionState } from './parts';
import { Leaderboard } from '../common/Leaderboard/Leaderboard';
import { gameStyles } from './game.styles';
import { sendRoundStarted } from '@src/services/analyticsThunk';
import PartyFinishModal from '../common/PartyFinishModal';

interface IProps {
  roomId: string;
}

const LoveBottle: React.FC<IProps> = ({ roomId }) => {
  const dispatch = useAppDispatch();
  const classes = gameStyles();

  const { state, game, isSpectator, currentPlayer, heart, isNextDisabled, activeQuestion, actions } = useGameHook(
    roomId
  );

  const { onSpin, onNext, onQuit, onVote } = actions;

  useEffect(() => {
    dispatch(updateLabelsThunk(game, activeQuestion));
  }, [game]);

  useEffect(() => {
    if (state.READY_START_ROUND) {
      dispatch(sendRoundStarted(game.roundNumber.toString()));
    }
  }, [state.READY_START_ROUND]);

  return (
    <GameWrapper state={state}>
      <div className={classes.gameContainer}>
        {(state.READY_START_ROUND || state.SPIN_BOTTLE) && (
          <Spin game={game} onSpin={onSpin} isSpin={state.SPIN_BOTTLE} currUserId={currentPlayer.playerId} />
        )}

        {(state.ROUND_IN_PROGRESS || state.SHOWING_RESULTS) && (
          <QuestionState
            game={game}
            heart={heart}
            activeTicketIndex={game.activeTicketIndex}
            currUserId={currentPlayer.playerId}
            isNextDisabled={isNextDisabled}
            onVote={onVote}
            onNext={onNext}
            isShowResults={state.SHOWING_RESULTS}
            activeQuestion={activeQuestion}
          />
        )}

        {state.SHOW_GIF && game?.gifId && <GifCard game={game} activeTicketIndex={game.activeTicketIndex} />}

        {state.TOP_COUPLES && <RoundResults game={game} onNext={onNext} isSpectator={isSpectator} />}

        {state.LEADERBOARD && (
          <Leaderboard
            game={game}
            onClick={onQuit}
            isSpectator={isSpectator}
            isTopPlayers={false}
            gameType={GameTypes.LOVE_BOTTLE}
            roomId={roomId}
          />
        )}

        {game.reward && <PartyFinishModal isOpen={true} game={game} />}
      </div>
    </GameWrapper>
  );
};

export { LoveBottle };

import React, { useEffect } from 'react';
import { Gif } from '@giphy/react-components';
import { IGif } from '@giphy/js-types';
import { giphyFetch } from '@src/common/constants/system';
import { LoveBottleState } from '@src/shared/gameInterfaces/loveBottle.socket';
import { gifStyles } from '../game.styles';

interface IProps {
  game: LoveBottleState;
  activeTicketIndex: number;
}

const GifCard: React.FC<IProps> = ({ game, activeTicketIndex }) => {
  const [prevGifId, setPrevGifId] = React.useState('');
  const [gifUrl, setGif] = React.useState<IGif | undefined>(undefined);
  const [isGifLoading, setIsGifLoading] = React.useState(false);
  // const isMatch = activeQuestion && activeQuestion.options.some((option) => option.votes && option.votes.length === 2);

  const classes = gifStyles();

  useEffect(() => {
    if (game.gifId && game.gifId !== prevGifId && !isGifLoading) {
      setPrevGifId(game.gifId);
      setIsGifLoading(true);
      giphyFetch
        .gif(game.gifId)
        .then((value) => {
          setGif(value?.data);
          setIsGifLoading(false);
        })
        .catch(() => setGif(undefined));
    }
  }, []);

  if (!gifUrl) {
    return null;
  }

  // const titleEn = isMatch ? 'You are the perfect couple' : 'You are not the perfect couple';
  // const titleRu = isMatch ? 'Вы прекрасная пара!' : 'Вы неподходящая пара';

  // {isEnLocale ? titleEn : titleRu}
  return (
    <div className={classes.gifWrapper}>
      <div className={classes.gifContainer}>
        {/* @ts-ignore */}
        <Gif gif={gifUrl} width="100%" height="auto" />
      </div>
    </div>
  );
};

export { GifCard };

import React from 'react';

interface IProps {
  fill?: string;
}

const LockOn: React.FC<IProps> = ({ fill = '#5F6372' }) => (
  <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.50008 0C3.60173 0 1.24367 2.36137 1.24367 5.26398V8.27038C1.24367 8.30454 1.20157 8.36211 1.1685 8.37312C0.884251 8.46804 0.653401 8.5503 0.442079 8.63188C0.190072 8.72918 0 9.00886 0 9.28248V16.1903C0 16.4621 0.188844 16.742 0.439255 16.8414C2.37673 17.6102 4.41588 18 6.50005 18C8.58421 18 10.6234 17.6101 12.5609 16.8413C12.8112 16.7419 13 16.4621 13 16.1903V9.28248C13 9.00886 12.81 8.72918 12.5578 8.63179C12.3464 8.55021 12.1156 8.46795 11.8316 8.37303C11.7986 8.36202 11.7565 8.30442 11.7565 8.27026V5.26389C11.7563 2.36137 9.39842 0 6.50008 0ZM5.06836 11.3864C5.06836 10.594 5.70936 9.95272 6.49998 9.95272C7.29055 9.95272 7.93155 10.5939 7.93155 11.3864C7.93155 11.9114 7.63587 12.351 7.21575 12.6003V14.9705C7.21575 15.3668 6.89561 15.6874 6.49995 15.6874C6.10427 15.6874 5.78416 15.3668 5.78416 14.9705V12.6003C5.36406 12.351 5.06836 11.9114 5.06836 11.3864ZM9.94905 5.26389V7.85786C8.81801 7.61413 7.65765 7.4907 6.50054 7.49061C5.34395 7.49061 4.18383 7.61407 3.05105 7.85758V5.26389C3.05105 3.35935 4.59822 1.80998 6.49998 1.80998C8.40172 1.80995 9.94905 3.35935 9.94905 5.26389Z"
      fill={fill}
    />
  </svg>
);

export default LockOn;

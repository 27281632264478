import React, { useCallback } from 'react';
import { RoomCard } from '@components/entities/RoomCard';
import { useStyles } from './defaultStyles';
import { Headline2 } from '../Headline2/headline';
import { useDeviceSize } from '@common/screenSizes';
import { IActiveRoom } from '@src/shared/misc/room.fragment';
import { useHistory } from 'react-router';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { useAppSelector } from '@src/app/hooks';

interface Props {
  setId?: string;
  rooms: IActiveRoom[];
  showNumber: number;
  headline?: string;
  canShowAll?: boolean;
}

const RoomsContainer: React.FC<Props> = ({ rooms, showNumber, headline = '' }) => {
  const history = useHistory();
  const { isMobile } = useDeviceSize();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = useStyles();
  const roomsArray = rooms.slice(0, showNumber);

  const renderRooms = () => {
    return roomsArray.map((room, index) => <RoomCard key={index} roomData={room} />);
  };

  const onViewAll = useCallback(() => {
    history.push(`/rooms`);
  }, []);

  return (
    <div className={classes.containerBlock}>
      <div className={classes.innerContainer}>
        <Headline2 headline={headline} />
        <div
          className={classes.elementsContainerRoom}
          style={!isMobile ? { maxHeight: '450px', overflow: 'hidden' } : {}}
        >
          {renderRooms()}
          {rooms.length > showNumber && (
            <div className={classes.viewAllContainer}>
              <div className={classes.viewAll} onClick={onViewAll}>
                {isEnLocale ? 'View More' : 'Показать все'}
              </div>
              <p />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { RoomsContainer };

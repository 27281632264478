import { IRoomPlayer, PLAYER_STATE } from '@store/room/roomSlice';
import { useAppSelector } from '@app/hooks';
import { defaultGameState, initialMapState } from './guessMe.types';
import { PLAYER_STATUS } from '@src/common/constants/system';
import { selectRoomPlayers } from '@store/room/roomSlice';
import { selectPlayerId } from '@store/reducers/player.reducer';
import { useState, useEffect } from 'react';
import { GuessMeState } from '@src/shared/gameInterfaces/guessMe.socket';
import { simpleMerge } from '@src/common/generics';
import WSClient from '@src/sockets';
import { CLIENT_EVENTS, SERVER_EVENTS } from '@src/shared/socketEvents/eventTypes';

interface GuessMeData {
  data: {
    guessMe: GuessMeState;
  };
}

export const useGuessMeProvider = (roomId: string) => {
  const [state, setState] = useState<GuessMeState>(defaultGameState);
  const players = useAppSelector(selectRoomPlayers);
  const playerId = useAppSelector(selectPlayerId);
  const isActivePlayer = playerId === state.activePlayerId;
  const currentPlayer = players.find((player) => player.playerId === playerId) || ({} as IRoomPlayer);
  const isSpectator = currentPlayer ? currentPlayer.state !== PLAYER_STATE.GAME : true;
  const stateMatcher = { ...initialMapState, [state.gameState]: true };

  useEffect(() => {
    const updateState = ({ data }: GuessMeData) => setState(simpleMerge(state, data.guessMe));

    WSClient.on(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    WSClient.on(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);

    WSClient.emit(CLIENT_EVENTS.GAME_STATE_REQUESTED, { roomId });

    return () => {
      WSClient.off(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);
      WSClient.off(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    };
  }, []);

  const setVote = (answerIndex: number) => setState(simpleMerge(state, {}));
  const setReady = () => setState(simpleMerge(state, {}));
  const setPreVote = (answerIndex: number) => setState(simpleMerge(state, {}));

  return [
    stateMatcher,
    { ...state, state: stateMatcher },
    playerId,
    players,
    { setVote, setReady, setPreVote },
    isActivePlayer,
    isSpectator,
  ] as const;
};

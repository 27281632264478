import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';
import bgMobile from '@art/images/landing/landing-bg-mobile.jpg';
import bgTablet from '@art/images/landing/landing-bg-tablet.jpg';
import bgDesktop from '@art/images/landing/landing-bg-desktop.jpg';

export const styles = makeStyles((theme) => ({
  landingPage2: {
    fontFamily,
    height: '100%',
    display: 'block',
    minHeight: '100%',
    minWidth: '100%',
    boxSizing: 'border-box',
    // overflowY: 'scroll',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    '&::-webkit-scrollbar': {
      display: 'none',
      background: 'transparent',
      width: 0,
    },
    '& *': { boxSizing: 'border-box' },
  },

  overlay: {
    display: 'block',
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0, 0.2)',
  },

  landingPage2Wrapper: {
    height: '100%',
    width: '100%',
    minHeight: '100%',
    minWidth: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${bgMobile})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${bgTablet})`,
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${bgDesktop})`,
    },
  },
}));

import eraserIcon from '@art/icons/eraser.svg';
import penIcon from '@art/icons/pen.svg';

import { styles } from './styles';

interface Props {
  menuItem: string;
  brushColor: string;
  setMenuItem: (item: string) => void;
  setBrushColor: (color: string) => void;
}

const MobileMenu: React.FC<Props> = ({ menuItem, brushColor, setMenuItem }) => {
  const classes = styles();

  return (
    <ul className={classes.canvasMenu}>
      <li
        className={`${menuItem === 'pen' && 'active'}`}
        style={{ backgroundImage: `url(${penIcon})` }}
        onClick={() => setMenuItem('pen')}
      />

      <li
        className={`${menuItem === 'erase' && 'active'}`}
        style={{ backgroundImage: `url(${eraserIcon})` }}
        onClick={() => setMenuItem('erase')}
      />

      <li className={`${menuItem === 'colors' && 'active'}`}>
        <div
          style={{
            backgroundColor: `${brushColor}`,
            border: `${brushColor === '#FFFFFF' ? '1px solid #E8E9EB' : ''}`,
          }}
          onClick={() => setMenuItem('colors')}
        />
      </li>
    </ul>
  );
};

export { MobileMenu };

import React from 'react';
import { useAppSelector } from '@app/hooks';
import { selectBalance } from '@store/reducers/player.reducer';
import arrowDown from '@art/icons/arrow-down.svg';
import HelloCoin from '@art/icons/hello-coin.svg';
import { styles } from './styles';
import { BlockchainConnectButton } from '@src/features/blockchain';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';

type Props = {
  tokenBalance: number;
  walletConnected: boolean;
};

const CoinHeader: React.FC<Props> = ({ tokenBalance, walletConnected }) => {
  const classes = styles();
  const balance = useAppSelector(selectBalance);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const roundBalance = () => {
    if (balance.toString().length >= 8) {
      return `${balance.toFixed(0)}`;
    } else if (balance.toString().length > 5) {
      return `${balance.toFixed(0)} HIT`;
    } else return `${balance.toFixed(2)} HIT`;
  };

  return (
    <header className={classes.coinHeader}>
      <img className={'coin__icon'} src={HelloCoin} alt="coin" />

      <div className={'coin__info'}>
        <p className={'coin__info-title'}>{isEnLocale ? 'My Balance' : 'Баланс'}</p>
        <p className={'coin__info-value'}>{roundBalance()}</p>
      </div>

      <div className={'coin__button'}>
        {walletConnected && (
          <p className={'coin__info-title'}>
            {isEnLocale ? 'Wallet Connected' : 'Кошелек'}
            <img className={'coin__info-arrow'} src={arrowDown} alt="drop" />
          </p>
        )}
        <BlockchainConnectButton
          walletConnected={walletConnected}
          className={` ${classes.connectYourWalletButton} ${walletConnected ? 'connected' : ''}`}
        >
          {!walletConnected ? 'Connect wallet' : ''}
        </BlockchainConnectButton>

        {walletConnected && <div className={'coin__button-info'}>{tokenBalance.toFixed(2)} HIT</div>}
      </div>

      {/* disconnect button */}
      {/* {walletConnected && (
          <BlockchainDisconnectButton className={classes.disconnectYourWalletButton}>
            <img src={''} alt={''} />{' '}
          </BlockchainDisconnectButton>
        )} */}
    </header>
  );
};

export default CoinHeader;

import { isSafari } from 'react-device-detect';
import { makeStyles, Theme } from '@material-ui/core';
import { ITimer } from '@src/shared/common';

export const useStyles = makeStyles<Theme, ITimer>((theme: Theme) => ({
  // line timer styles
  lineTimer: {
    width: '100%',
    height: '40px',
    position: 'relative',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '50px',
    },
  },

  lineTimerInner: {
    display: 'flex',
    position: 'relative',
    zIndex: 1,
    height: '40px',
    alignItems: 'center',
    width: 'calc(100% - 35px)', // minus output width + boxShadow
    animationDuration: '0.2s',
    animationIterationCount: '1',
    animationFillMode: 'both',
    '&.showTimer': { animationName: 'appearFromTop' },
    '&.hideTimer': { animationName: 'hideToTop' },
    [theme.breakpoints.up('sm')]: {
      height: '50px',
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 30px)', // minus output width without boxShadow
      top: 'auto',
      bottom: '-200px',
      '&.showTimer': {
        animationName: 'appearFromBottom',
      },
      '&.hideTimer': {
        animationName: 'hideToBottom',
      },
    },
  },

  lineTimerOutput: {
    position: 'absolute',
    zIndex: 3,
    top: '9px',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px',
    width: '30px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: 'white',
    borderRadius: '10px',
    backgroundColor: '#CEEDD5',
    boxShadow: '0 0 0 5px white',

    // fix Safari transition bug
    transition: isSafari ? 'all 985ms linear 1ms' : 'all 1s linear',
    '&::selection': {
      background: 'transparent',
    },
    [theme.breakpoints.up('sm')]: {
      top: '14px',
    },
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
    },
  },

  lineTimerRange: {
    position: 'absolute',
    zIndex: 2,
    top: '15px',
    left: 0,
    right: 0,
    height: '8px',
    width: '100%',
    borderRadius: '10px 0 0 10px',
    backgroundColor: '#CEEDD5',
    cursor: 'default',
    boxShadow: '0 0 0 5px white',

    // fix Safari transition bug
    transition: isSafari ? 'all 985ms linear 1ms' : 'all 1s linear',
    [theme.breakpoints.up('sm')]: {
      top: '20px',
    },
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
    },
  },

  timerAlert: {
    opacity: 0,
    position: 'absolute',
    height: '40px',
    backgroundColor: '#F83E67',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    color: 'white',
    animationDuration: '2s',
    animationDelay: '0.1s',
    animationIterationCount: '1',
    animationFillMode: 'both',
    animationTimingFunction: 'ease',
    borderRadius: '0px 0px 10px 10px',
    [theme.breakpoints.up('sm')]: {
      width: '360px',
      height: '50px',
      fontSize: '16px',
      lineHeight: '19px',
      borderRadius: '0px 0px 20px 20px',
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '20px 20px 0px 0px',
    },
    '&.showAlert': {
      animationName: 'showAlertMobile',
      [theme.breakpoints.up('sm')]: {
        animationName: 'showAlertTablet',
      },
      [theme.breakpoints.up('md')]: {
        animationName: 'showAlertDesktop',
      },
    },

    '&.circleTimerAlertPosition': {
      top: '-65px',
      left: 0,
      right: 0,
      [theme.breakpoints.up('sm')]: {
        left: '50%',
        right: 'auto',
        marginLeft: '-180px',
        marginRight: '-180px',
      },
      [theme.breakpoints.up('md')]: {
        top: '389px',
        margin: 0,
      },
    },

    '&.lineTimerAlertPosition': {
      top: '-65px',
      left: 0,
      right: 0,
      [theme.breakpoints.up('sm')]: {
        left: '50%',
        right: 'auto',
        marginLeft: '-180px',
        marginRight: '-180px',
      },
      [theme.breakpoints.up('md')]: {
        top: '16px',
      },
    },
  },

  // circle timer styles
  circleTimerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2C74F3',
    borderRadius: '50%',
    width: '28px',
    height: '28px',
    transition: 'all .23s',
    color: 'white',
    boxShadow: '0 0 0 5px white',
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
    },
    '&.warning': {
      backgroundColor: '#FFCC01',
      color: '#1E1E1E',
    },
    '&.danger': {
      backgroundColor: '#F83E67',
      color: 'white',
    },
    '&.timeIsUp': {
      backgroundColor: '#F83E67',
      color: 'white',
      animation: 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
      transform: 'translate3d(0, 0, 0)',
      backfaceVisibility: 'hidden',
      perspective: '1000px',
    },
  },

  circleTimer: {
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '14px',
    color: 'inherit',
    '&::selection': {
      backgroundColor: 'transparent',
    },
  },

  textTimerContainer: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    color: 'inherit',
  },

  //sectionTimer
  sectionTimerContainer: {
    width: '55px',
    height: '55px',
    background: 'rgba(0, 0, 0, 0.45)',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  timer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '41px',
    height: '41px',
    overflow: 'hidden',
    borderRadius: '50%',
  },
  leftPart: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '50%',
    height: '100%',
    background: 'white',
    zIndex: 3,
  },
  rightPart: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '50%',
    height: '100%',
    background: '#776f6f',
    zIndex: 3,
  },
  timerLine: {
    width: '100%',
    height: '100%',
    background: 'white',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    overflow: 'hidden',
    animation: (props) => `timerRotation ${props.duration / 1000}s linear forwards`,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '50%',
      height: '100%',
      background: '#776f6f',
      // zIndex: 3,
    },
  },
  timerBody: {
    width: '26px',
    height: '26px',
    background: '#776f6f',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 4,
    borderRadius: '50%',
  },
  timerNumber: {
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '18px',
    color: 'white',
  },
}));

import { fontFamily } from '@src/art/theme/textStyles';
import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: '360px',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    '& > h3': {
      fontFamily: fontFamily,
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '24px',
      marginTop: 0,
      textAlign: 'center',
      color: '#1B2036',
      marginBottom: '12px',
      maxWidth: '83%',
    },
    '& > img': {
      width: '18px',
      height: '18px',
      position: 'absolute',
      right: 0,
      top: 0,
      cursor: 'pointer',
    },
  },

  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    '& > .send': {
      width: 'auto',
      padding: '0px 15px',
      minWidth: '165px',
      [theme.breakpoints.up('md')]: {
        minWidth: '200px',
      },
    },
    '& > p': {
      margin: 0,
      marginTop: '5px',
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '17px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: '#5568AE',
      justifyContent: 'center',
    },
    '& .free': {
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '24px',
      lineHeight: '24px',
      display: 'flex',
      color: '#fff',
      textStroke: '0.5px #000000',
      textShadow: '0px 1px 0px #000000',
      marginLeft: '5px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '28px',
      },
    },
    '& .coin': {
      fontFamily: 'SF Pro Rounded Black',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '23px',
      lineHeight: '23px',
      color: '#FFE250',
      textAlign: 'right',
      textStroke: '1px #985E02',
      marginLeft: '5px',
      marginRight: '5px',
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    alignItems: 'baseline',
    marginBottom: '17px',
    '& .title': {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '17px',
      lineHeight: '17px',
      color: '#1B2036',
    },
    '& .dots': {
      color: 'inherit',
      flexGrow: 1,
      marginLeft: '10px',
      marginRight: '10px',
      borderBottom: '1px dashed #5568AE',
    },
    '& .coin': {
      flexShrink: 0,
      width: '22px',
      height: '22px',
      marginLeft: 3,
      paddingTop: 3,
    },
    '& .value': {
      fontFamily: 'SF Pro Rounded Black',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '23px',
      lineHeight: '17px',
      color: '#FFE250',
      textAlign: 'right',
      textStroke: '1px #985E02',
    },
  },
  subtitle: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    color: '#5568AE',
    marginTop: '-12px',
    marginBottom: '17px',
    width: '100%',
    alignText: 'start',
  },
}));

import { useAppSelector } from '@app/hooks';
import { styles } from './styles';
import React, { useEffect, useState } from 'react';
import energyIcon from '@src/art/icons/StatusBar/energy.svg';
import { selectEnergy, selectIsCompletedQuests } from '@src/store/reducers/player.reducer';
import { getLeftTimeCeil } from '@src/common/misc';
import { MS_IN_MINUTE } from '@src/common/constants/system';

interface IProps {
  onClick: () => void;
}

const Energy: React.FC<IProps> = ({ onClick }) => {
  const classes = styles();
  const energy = useAppSelector(selectEnergy);
  const [energyString, setEnergyString] = useState('0');
  const isCompletedQuests = useAppSelector(selectIsCompletedQuests);

  useEffect(() => {
    if (energy.amount > 0) {
      setEnergyString(`${energy.amount}`);
    } else {
      const timerUpdate = () => {
        const leftMinutes = Math.max(getLeftTimeCeil(energy.end), 0);
        console.log(leftMinutes);
        setEnergyString(`${leftMinutes}m`);
      };

      timerUpdate();
      const intervalId = setInterval(() => timerUpdate(), MS_IN_MINUTE);
      return () => clearInterval(intervalId);
    }
  }, [energy.end, energy.amount]);

  return (
    <div className={`${classes.energyContainer} ${isCompletedQuests ? 'hasNotifications' : ''}`} onClick={onClick}>
      <img className={classes.energy} src={energyIcon} alt="energy" />
      {energyString}
    </div>
  );
};

export { Energy };

import React from 'react';
import { styles } from './styles';

interface Props {
  itemsArrayLength: number;
  roundResults: boolean;
  className?: string;
}

// @ts-ignore
const AddEmptyRows: React.FC<Props> = ({ itemsArrayLength, roundResults, className }) => {
  const classes = styles();
  const maxItems = 5;
  const emptyItemsToAdd = maxItems - itemsArrayLength;

  if (itemsArrayLength < maxItems) {
    return [1, 2, 3, 4, 5].slice(-emptyItemsToAdd).map((item: any) => (
      <div className={className || classes.leaderboardTableRow} key={item}>
        <b className={classes.leaderboardTableRowIndex} style={{ color: roundResults ? 'transparent' : '#1B2036' }}>
          {item}
        </b>
        <span className={classes.leaderboardTableRowName}>-</span>
        <b className={classes.leaderboardTableRowPoints}>-</b>
      </div>
    ));
  }

  return null;
};

export { AddEmptyRows };

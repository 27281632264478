import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CommonModal } from '@components/Modals';
import { TextInput } from '@components/Inputs';
import Button from '@components/Button';
import { styles } from './styles';
import { selectPlayer, updatePlayerName } from '@store/reducers/player.reducer';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { mutateGraphql } from '@library/fetch';
import { updatePlayerNameMutation } from '@graphql/debugQueries';
import { useAppSelector } from '@app/hooks';
import { getRoomId } from '@src/store/room/roomSlice';
import WSClient from '@src/sockets';
import { CLIENT_EVENTS } from '@src/shared/socketEvents/eventTypes';
import { IPlayer } from '@src/graphql/player.fragment';

interface Props {
  isGraphqlRename: boolean;
  isOpen: boolean | undefined;
  handleClose: () => void;
  handleNext?: () => void;
}

export const getCurrentPlayerName = (player: IPlayer) => (player.autocreate ? '' : player.name || '');

const RenameModal: React.FC<Props> = ({ isGraphqlRename, isOpen, handleClose, handleNext }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const player = useAppSelector(selectPlayer);
  const currentPlayerName = getCurrentPlayerName(player);
  const roomId = useAppSelector(getRoomId);
  const [playerName, setPlayerName] = useState(currentPlayerName);
  const [isProcessing, setIsProcessing] = useState(false);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const handleChange = (e: any) => {
    setPlayerName(e.target.value);
  };

  const onRenameGraphql = () => {
    setIsProcessing(true);
    mutateGraphql(updatePlayerNameMutation, { playerId: player.publicId, name: playerName }).then((data) => {
      setIsProcessing(false);
      if (data) {
        dispatch(updatePlayerName(playerName));
        handleNext?.apply(null);
      }
    });
  };

  const onRenameInRoom = () => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_CHANGED_NAME, {
      playerId: player.publicId,
      roomId,
      newName: playerName,
    });
    dispatch(updatePlayerName(playerName));
    handleClose();
  };

  const onSubmit = () => (isGraphqlRename ? onRenameGraphql() : onRenameInRoom());

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} backgroundColor={'white'}>
      <div className={classes.wrapper}>
        <h3 id="transition-modal-title">{isEnLocale ? 'Create username' : 'Введите свое имя'}</h3>

        <div className={classes.formInner}>
          <TextInput
            onChange={handleChange}
            value={playerName}
            id="userName"
            name="userName"
            label={isEnLocale ? 'User name' : 'Имя пользователя'}
            variant="outlined"
            type="text"
          />
        </div>

        <div className={classes.btnContainer}>
          <Button
            text={isEnLocale ? 'Cancel' : 'Закрыть'}
            onClick={handleClose}
            type="primary-red-new"
            style={{ marginRight: '15px' }}
          />

          <Button
            type="primary-blue-new"
            text={isEnLocale ? 'Save' : 'Сохранить'}
            onClick={onSubmit}
            disabled={!playerName || playerName === currentPlayerName || isProcessing}
          />
        </div>
      </div>
    </CommonModal>
  );
};

export default RenameModal;

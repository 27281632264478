import { Dispatch, SetStateAction } from 'react';
import Button from '@components/Button';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';

interface Props {
  game: any;
  isModalOpen: boolean;
  isActivePlayer: boolean;
  activePlayerName: string;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const DesktopMenuContent: React.FC<Props> = ({ game, isModalOpen, isActivePlayer, activePlayerName, setModalOpen }) => {
  const classes = styles();
  const { timer, chosenWord } = game;
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <>
      {/* top-container */}
      <div className={classes.desktopMenuWord}>
        <div className={classes.wordWrapper}>
          {isActivePlayer ? (
            <b>{chosenWord.word}</b>
          ) : (
            <div className={classes.passivePlayerInfo}>
              <span>{isEnLocale ? 'Guess what' : 'Угадай, что'}</span>
              <span className={`${classes.activePlayerName} text-overflow`}>{activePlayerName}</span>
              <span>{isEnLocale ? 'is drawing' : 'рисует'}</span>
            </div>
          )}
        </div>
      </div>

      {/* middle-container */}
      {isActivePlayer && (
        <div className={classes.desktopMenuButton}>
          <Button
            type="primary-green"
            text={isModalOpen ? (isEnLocale ? 'Cancel' : 'Закрыть') : isEnLocale ? 'Correct' : 'Отгадано'}
            className={isModalOpen ? 'isModalOpen' : ''}
            disabled={timer.timeIsUp}
            onClick={() => setModalOpen(true)}
          />
        </div>
      )}
    </>
  );
};

export { DesktopMenuContent };

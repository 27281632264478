import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  energyContainer: {
    boxSizing: 'border-box',
    borderRadius: '22px',
    padding: '3px 11px 3px 22px',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '17px',
    lineHeight: '17px',
    position: 'relative',
    color: '#FFFFFF',
    textStroke: '1px #1E1E1E',
    fontFamily: 'SF Pro Rounded Black',
    background: '#FFDDF6',
    border: '1px solid #B800C0',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      marginRight: 20,
    },
    '&.hasNotifications': {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '-4px',
        right: '-3px',
        width: '11px',
        height: '11px',
        borderRadius: '50%',
        backgroundColor: '#F83E67',
      },
    },
  },

  energy: {
    width: '36px',
    height: '35px',
    position: 'absolute',
    left: '-14px',
    top: '-6px',
  },
}));

const RoastBattleTextContextEn: React.FC = () => {
  return (
    <>
      <li>
        You get <b>1 point</b> if more people voted for your answer option
      </li>
    </>
  );
};

const RoastBattleTextContextRu: React.FC = () => {
  return (
    <>
      <li>
        Ты получаешь <b>1 очко</b>, если за твой вариант ответа проголосовало больше людей
      </li>
    </>
  );
};

export { RoastBattleTextContextEn, RoastBattleTextContextRu };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  statusBarDropdown: {
    display: 'inline-flex',
    fontSize: '15px',
    lineHeight: '15px',
    fontWeight: 500,
    border: '1px solid #E8E9EB',
    borderRadius: '4px',
    padding: '5px 10px',
    marginRight: '15px',
    cursor: 'default',
    '&::selection': {
      background: 'transparent',
    },
    ['@media (max-width:374px)']: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: '20px',
    },
  },
}));

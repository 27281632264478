import { useState } from 'react';
import { IPlayer } from '../../guessMe.types';
import { RangeCustom } from '@components/Inputs';
import Button from '@components/Button';
import SubText from './SubText/SubText';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { GuessMeState } from '@src/shared/gameInterfaces/guessMe.socket';
import { LineTimer } from '@src/games/common';
import { styles } from './styles';
import { useDeviceSize } from '@src/common/screenSizes';

interface IProps {
  game: GuessMeState;
  isActivePlayer: boolean;
  isSpectator: boolean;
  players: IPlayer[];
  onPreVote: (value: number) => void;
  onVote: (value: number) => void;
  playerId: string;
  isActivePlayerVote: boolean;
}

const Question: React.FC<IProps> = ({
  game,
  players,
  playerId,
  isActivePlayer,
  isSpectator,
  onPreVote,
  onVote,
  isActivePlayerVote,
}) => {
  const classes = styles();
  const { activePlayerId, ticket, timer } = game;
  const activePlayer = players.find((player) => player.playerId === activePlayerId);
  const hasPlayerVoted = game.votedPlayers && game.votedPlayers.includes(playerId);
  const isVoteActiveClick = hasPlayerVoted;
  const isVotePassiveClick = hasPlayerVoted;
  const isEnLocale = useAppSelector(selectIsEnLocale);

  // active value
  const [valueActive, setValueActive] = useState<number | any>(NaN);
  const [hasActiveValue, setHasActiveValue] = useState(false);
  const onRangeChangeActive = (event: any, newValue: number | number[]) => {
    setValueActive(newValue);
    setHasActiveValue(true); // has value when onChange
  };
  const onVoteActive = () => {
    onVote(valueActive);
  };

  // passive value
  const [valuePassive, setValuePassive] = useState<number | any>(NaN);
  const [hasPassiveValue, setHasPassiveValue] = useState(false);
  const { isDesktop } = useDeviceSize();

  const onRangeChangePassive = (event: any, newValue: number | number[]) => {
    setValuePassive(newValue);
    setHasPassiveValue(true); // has value when onChange
  };
  const onVotePassive = () => {
    onVote(valuePassive);
  };

  return (
    <div className={`${classes.questionWrapper} animate__animated  animate__fadeIn`}>
      {/* mobile timer */}
      {!isDesktop && (
        <div className={classes.timerWrapper}>
          {timer?.duration && <LineTimer timer={timer!} isVote={hasPlayerVoted!} />}
        </div>
      )}

      <div className={classes.questionInner}>
        {/* question text */}
        <p className={classes.questionText}>{ticket!.title}</p>

        <div className={classes.questionRangeWhiteContainer}>
          {/* question sub-text */}
          <SubText
            isActivePlayer={isActivePlayer}
            activePlayer={activePlayer}
            activePlayerVote={isActivePlayerVote}
            passivePlayerVote={!isActivePlayerVote}
          />

          {/* question range */}
          <div className={classes.questionRangeWrapper}>
            <div className={classes.questionRange}>
              {isActivePlayer && (
                <RangeCustom
                  disabled={timer?.timeIsUp}
                  isVote={isVoteActiveClick || !isActivePlayerVote}
                  value={valueActive}
                  activePlayer={isActivePlayer}
                  onChange={onRangeChangeActive}
                  onChangeCommitted={() => onPreVote(valueActive)}
                />
              )}

              {!isActivePlayer && (
                <RangeCustom
                  disabled={timer?.timeIsUp || isActivePlayerVote || isSpectator}
                  isVote={isVotePassiveClick}
                  value={valuePassive}
                  activePlayer={isActivePlayer}
                  onChange={onRangeChangePassive}
                  onChangeCommitted={() => onPreVote(valuePassive)}
                />
              )}
            </div>

            <div className={classes.questionGrades}>
              <span>{ticket!.lowQuestionGrade}</span>
              <span>{ticket!.highQuestionGrade}</span>
            </div>
          </div>
        </div>

        <div className={classes.buttonWrapper}>
          {
            // !isNaN(valueActive) - hide button when !isNaN(valueActive)
            // !isVoteActiveClick - hide button when clicked
            // !timeIsUp - when we have time to answer
            isActivePlayerVote && isActivePlayer && !isNaN(valueActive) && !isVoteActiveClick && !timer?.timeIsUp && (
              <Button text={isEnLocale ? 'Vote' : 'Проголосовать'} type="primary-blue" onClick={onVoteActive} />
            )
          }
          {!isActivePlayerVote &&
            !isActivePlayer &&
            !isNaN(valuePassive) &&
            !timer?.timeIsUp &&
            !isSpectator &&
            (!isVotePassiveClick ? (
              <Button text={isEnLocale ? 'Vote' : 'Проголосовать'} type="primary-blue" onClick={onVotePassive} />
            ) : (
              <span className="mobile-outline">
                {isEnLocale ? 'All players must Vote to proceed' : 'Все игроки должны проголосовать, чтобы продолжить'}
              </span>
            ))}
        </div>
      </div>

      {/* desktop timer */}
      {isDesktop && (
        <div className={classes.timerWrapper}>
          {timer?.duration && <LineTimer timer={timer!} isVote={hasPlayerVoted!} />}
        </div>
      )}
    </div>
  );
};

export { Question };

import { useAppDispatch, useAppSelector } from '@app/hooks';
import { styles } from './styles';
import { ClickAwayListener } from '@material-ui/core';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { LOCALE } from '@src/shared/common';
import { setLocaleThunk } from '@src/store/thunk/session.thunk';

interface Props {
  barPosition: string;
  handleClose: () => void;
}

const TooltipLanguageSwitcher: React.FC<Props> = ({ handleClose, barPosition }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const dispatch = useAppDispatch();

  const onSwitchLocale = () => {
    dispatch(setLocaleThunk(isEnLocale ? LOCALE.RU : LOCALE.EN));
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classes.tooltip}>
        <div className={classes.tooltipContent}>
          {isEnLocale ? 'Your language' : 'Ваш язык'}
          <div className={`${classes.switcherTooltipBar} ${barPosition}`} onClick={handleClose}>
            <p onClick={onSwitchLocale}>{isEnLocale ? 'Русский' : 'English'}</p>
            <div className={`${classes.switcherTooltipThumb} ${barPosition}`}>
              {!isEnLocale ? 'Русский' : 'English'}
            </div>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export { TooltipLanguageSwitcher };

import { fontFamily } from '@src/art/theme/textStyles';
import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  voteList: {
    minHeight: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& > h2': {
      marginTop: 0,
      marginBottom: '20px',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '28px',
      color: '#1B2036',
      maxWidth: '320px',
      borderRadius: '20px',
      backgroundColor: 'white',
      padding: '8px 15px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
        marginBottom: '30px',
        maxWidth: '450px',
      },

      [theme.breakpoints.up('md')]: {
        maxWidth: '550px',
        padding: 0,
        borderRadius: 0,
        backgroundColor: 'transparent',
      },
    },
  },

  voteListTable: {
    minWidth: '215px',
    maxWidth: '90vw',
    flexShrink: 0,
    marginBottom: '25px',
    flexDirection: 'column',
    borderRadius: '20px',
    backgroundColor: 'white',
    padding: '8px 15px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '40px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '50px',
      padding: 0,
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
  },

  voteTableRow: {
    position: 'relative',
    justifyContent: 'space-between',
    padding: '8px 50px 8px 15px',
    borderBottom: '1px solid #E8E9EB',
    [theme.breakpoints.up('sm')]: {
      padding: '13px 50px 13px 15px',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
    '& > p': {
      fontSize: '15px',
      lineHeight: '18px',
      maxWidth: '200px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 1,
      wordWrap: 'break-word',
      margin: 0,
      marginRight: '15px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
    '& > b': {
      fontSize: '15px',
      lineHeight: '18px',
      fontWeight: 700,
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
    '& > svg': {
      position: 'absolute',
      top: '5px',
      right: '15px',
      [theme.breakpoints.up('sm')]: {
        top: '10px',
      },
    },
  },

  buttonWrapper: {
    minHeight: '56px',
    position: 'relative',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: '60px',
    },
    [theme.breakpoints.up('md')]: {
      height: '50px',
    },

    // button
    '& > button': {
      border: '10px solid white',
      height: '56px',
      [theme.breakpoints.up('sm')]: {
        height: '60px',
      },
      [theme.breakpoints.up('md')]: {
        border: 'none',
        height: '40px',
      },
    },

    '& > span': {
      fontFamily,
      color: 'white',
      fontWeight: 800,
      fontSize: '15px',
      lineHeight: '18px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
      [theme.breakpoints.up('md')]: {
        color: '#5F6372',
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
  },
}));

import { GameTypes } from '@common/constants/common';

import {
  DrawAndGuessRules,
  DrinkRouletteRules,
  GuessMeRules,
  LoveBottleRules,
  QuizClassicRules,
  RoastBattleRules,
  RoastRules,
  TwentyQuestionsRules,
  // QuizBattleRules,
  ChestsRules,
  FakeArtistRules,
  OneQuestionRules,
  HillbillyRules,
  AliasRules,
  FiveSecondsRules,
  GuessUpRules,
} from '../../Rules';
// import { GameRules } from './gameRules';

export const getRules = (gameType: GameTypes | undefined) => {
  // const useServerRules = gameType && ![GameTypes.DRINK_ROULETTE, GameTypes.LOVE_BOTTLE].includes(gameType);
  // useServerRules && rulesUrl && <GameRules rulesUrl={rulesUrl} />

  switch (gameType) {
    case GameTypes.DRINK_ROULETTE:
      return <DrinkRouletteRules />;
    case GameTypes.LOVE_BOTTLE:
      return <LoveBottleRules />;
    case GameTypes.ROAST_BATTLE:
      return <RoastBattleRules />;
    case GameTypes.TWENTY_QUESTIONS:
      return <TwentyQuestionsRules />;
    case GameTypes.ROAST:
      return <RoastRules />;
    case GameTypes.QUIZ_CLASSIC:
    case GameTypes.QUIZ_BATTLE:
      return <QuizClassicRules />;
    case GameTypes.GUESS_ME:
      return <GuessMeRules />;
    case GameTypes.DRAW_AND_GUESS:
      return <DrawAndGuessRules />;
    case GameTypes.CHESTS:
      return <ChestsRules />;
    case GameTypes.FAKE_ARTIST:
      return <FakeArtistRules />;
    case GameTypes.QUESTION_CHALLENGE:
      return <OneQuestionRules />;
    case GameTypes.HILLBILLY:
      return <HillbillyRules />;
    case GameTypes.ALIAS:
      return <AliasRules />;
    case GameTypes.GUESS_UP:
      return <GuessUpRules />;
    case GameTypes.FIVE_SECONDS:
      return <FiveSecondsRules />;
    default:
      return 'Will be soon';
  }
};

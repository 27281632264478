import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { styles } from './styles';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { selectPlayerId } from '@src/store/reducers/player.reducer';
import { copyLinkToClipboard } from '@src/common/misc';
import { NOTIFICATION_TYPE } from '@src/store/room/notifications';
import { pushNotification } from '@src/store/room/roomFeaturesData.slice';
import { version } from '../../../../../package.json';

const Info: React.FC = () => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const currentPlayerId = useAppSelector(selectPlayerId);
  const shortPlayerId = currentPlayerId.slice(0, 4) + '...' + currentPlayerId.slice(-4);

  const handleCopy = () => {
    dispatch(copyLinkToClipboard(currentPlayerId, 'POPUP'));
    dispatch(pushNotification({ type: NOTIFICATION_TYPE.COPY_ID }));
  };

  return (
    <>
      <div className={classes.infoContainer}>
        <div className={'row'}>
          <p className={'title'}>{isEnLocale ? 'Player ID' : 'ID игрока'}</p>
          <span className={'title'} style={{ color: '#2C74F3', cursor: 'pointer' }} onClick={handleCopy}>
            {': ' + shortPlayerId}
          </span>
        </div>
        <div className={'row'}>
          <p className={'title'}>{isEnLocale ? 'App version ' : 'Версия приложения '}</p>
          <span className={'title'}>{': ' + version}</span>
        </div>
      </div>
    </>
  );
};

export { Info };

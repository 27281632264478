import { useAppSelector } from '@app/hooks';
import { selectRoomPlayers } from '@store/room/roomSlice';
import GrinningFace from '@art/images/grinning-face-with-sweat.png';
import { styles } from './styles';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { AliasState } from '@src/shared/gameInterfaces/alias.socket';

interface Props {
  game: AliasState;
}

const RightAnswer: React.FC<Props> = ({ game }) => {
  const classes = styles();
  const { winner, word } = game;
  const players = useAppSelector(selectRoomPlayers);
  const winnerPlayer = players.find((player) => player.playerId === winner);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <div className={classes.rightAnswerWrapper}>
      <div
        className={`
        ${classes.rightAnswer} animate__animated  animate__fadeIn
        ${winner ? 'winner' : 'no-one'}
      `}
      >
        {!winner && <img className={classes.smile} src={GrinningFace} alt="WinnerImg" />}

        <h2 className="text-overflow">{winner ? winnerPlayer?.name : isEnLocale ? 'No one' : 'Никто'}</h2>

        <p>{isEnLocale ? 'Guessed Correctly' : winnerPlayer?.name ? 'Отгадал/а' : 'не отгадал'}</p>

        <div className={classes.word}>{word} </div>
      </div>
    </div>
  );
};

export { RightAnswer };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  chestWrapper: {
    position: 'relative',
    zIndex: 3,
    justifyContent: 'center',
    width: '185px',
    height: '185px',
    '--animate-duration': '500ms',
    '--animate-delay': '500ms',
    [theme.breakpoints.up('sm')]: {
      width: '302px',
      height: '302px',
    },
  },

  chestClose: {
    cursor: 'pointer',
    width: '175px',
    height: '147px',
    alignSelf: 'flex-end',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      width: '287px',
      height: '240px',
    },
    [theme.breakpoints.up('md')]: {
      width: '350px',
      height: '294px',
    },
  },

  chestOpen: {
    zIndex: 2,
    width: '185px',
    height: '175px',
    alignSelf: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      width: '302px',
      height: '286px',
    },
    [theme.breakpoints.up('md')]: {
      width: '370px',
      height: '350px',
    },
  },

  chestGlow: {
    position: 'absolute',
    zIndex: 1,
    width: '300px',
    height: '300px',
    left: '50%',
    top: '50%',
    marginTop: '-150px',
    marginLeft: '-150px',
    [theme.breakpoints.up('sm')]: {
      width: '462px',
      height: '462px',
      marginTop: '-236px',
      marginLeft: '-236px',
    },
    [theme.breakpoints.up('md')]: {
      width: '520px',
      height: '520px',
      marginTop: '-265px',
      marginLeft: '-260px',
    },
  },
}));

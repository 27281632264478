import { Active, Passive } from './parts';
import { DrawAndGuessState } from '@src/shared/gameInterfaces/drawAndGuess.socket';
import { IRoomPlayer } from '@src/store/room/roomSlice';
import { CircleTimer } from '@src/games/common';
import { styles } from './styles';

interface Props {
  game: DrawAndGuessState;
  activePlayer: IRoomPlayer | undefined;
  isActivePlayer: boolean;
  onChoose: (index: number) => void;
}

const ChooseWord: React.FC<Props> = ({ game, isActivePlayer, activePlayer, onChoose }) => {
  const classes = styles();

  return (
    <div className={`${classes.wordChoose} animate__animated  animate__fadeInDown`}>
      <CircleTimer timer={game.timer!} shouldShowAlert={false} />

      {isActivePlayer ? <Active game={game} onChoose={onChoose} /> : <Passive activePlayer={activePlayer} />}
    </div>
  );
};

export { ChooseWord };

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PLAYER_STATUS } from '@src/common/constants/system';
import {
  IRoomPlayer,
  PLAYER_STATE,
  selectCurrentGame,
  selectCurrentRoomPlayer,
  selectIsInGame,
} from '@store/room/roomSlice';
import { selectCurrentPlayerVideoState } from '@store/reducers/common';
import { ThreeDots } from '@components/icons/ThreeDots';
import {
  EditRoomModal,
  LeaveModal,
  PauseModal,
  RulesModal,
  RenameModal,
  ChangeBackground,
  RoomPreferences,
} from '@components/Modals';

// parts
import { Menu, Dropdown, RoomTitle, HeaderSkeleton, AudioVideoControls } from './parts';
import { HomepageBarStyles } from '../styles';
import { Action } from 'history';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { getIsJoinRoomLoading } from '@store/reducers/loadActions';
import { MUTE_TYPE, setAudioAccess, setVideoAccess } from '@store/reducers/playerData.reducer';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';

interface Props {
  hasVideoChat: boolean;
}

function isPlayerInLobby(player?: IRoomPlayer): boolean {
  if (!player) return false;
  return [PLAYER_STATE.LOBBY, PLAYER_STATE.JOIN_READY].includes(player.state);
}

const StatusBar: React.FC<Props> = ({ hasVideoChat }) => {
  const classes = HomepageBarStyles();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getIsJoinRoomLoading);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  // useSelector
  const currUserPlayerInfo = useSelector(selectCurrentPlayerVideoState);
  const selectedGame = useAppSelector(selectCurrentGame);
  const gameType = selectedGame?.type;

  // useState
  // const [isSnackShow, setIsShowSnack] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalType, setModalType] = useState('');

  // states
  const history = useHistory();
  const isPrivateLobby = history.location.pathname.search('/room') !== -1;
  const currentRoomPlayer = useAppSelector(selectCurrentRoomPlayer);
  const playerInLobby = isPlayerInLobby(currentRoomPlayer);
  const isGame = useAppSelector(selectIsInGame);

  // MENU ACTIONS
  const isMenuOpen = Boolean(anchorEl);
  const id = isMenuOpen ? 'simple-popover' : undefined;

  const muteAudio = () => {
    dispatch(setAudioAccess(MUTE_TYPE.OWN, !currUserPlayerInfo.isAudioEnabled));
    // dispatch(setVideoStateRequest({ shouldEnableAudio: !currUserPlayerInfo.isAudioEnabled }));
  };

  const muteVideo = () => {
    dispatch(setVideoAccess(MUTE_TYPE.OWN, !currUserPlayerInfo.isVideoEnabled));
    // dispatch(setVideoStateRequest({ shouldEnableVideo: !currUserPlayerInfo.isVideoEnabled }));
  };

  // open-close -MENU-
  const handleOpenMenu = (event: { currentTarget: HTMLElement | any }) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // open-close -MODALS-
  const handleCloseModal = () => {
    setAnchorEl(null);
    setModalType('');
  };

  // Disable back button.
  useEffect(() => {
    const exitRoom = (pathname: string) => pathname.search('/room') === -1;
    const exitGame = (pathname: string) => isGame && pathname.search('/game') === -1;
    const shouldShowLeaveModal = (pathname: string) => exitRoom(pathname) || exitGame(pathname);

    // @ts-ignore
    const blocker = history.block((location, action) => {
      if (action === Action.Pop && shouldShowLeaveModal(location.pathname)) {
        setModalType('leave');
        return false;
      }
    });

    return () => blocker();
  }, [isGame]);

  // skeleton pre-loader
  if (isLoading) {
    return (
      <div className={classes.statusBarContainer}>
        <HeaderSkeleton />
      </div>
    );
  }

  // const rulesUrl = isEnLocale ? selectedHost?.game.rules?.EN : selectedHost?.game.rules?.RU;

  return (
    <div className={classes.statusBarContainer}>
      <RoomTitle />

      <div className={classes.statusBarActions}>
        {/* video-audio controls */}
        {/* {hasVideoChat && (
          <AudioVideoControls muteAudio={muteAudio} muteVideo={muteVideo} currUserPlayerInfo={currUserPlayerInfo} />
        )} */}

        {/* kind of rooms */}
        {/* <Dropdown /> */}

        {/* open menu */}
        <div onClick={handleOpenMenu} style={{ width: '50px', height: '50px', justifyContent: 'end' }}>
          <ThreeDots aria-describedby={id} onClick={handleOpenMenu} />
        </div>

        <Menu
          id={id}
          isMenuOpen={isMenuOpen}
          anchorEl={anchorEl}
          playerInLobby={playerInLobby}
          setAnchorEl={setAnchorEl}
          setModalType={setModalType}
          handleCloseMenu={handleCloseMenu}
        />
      </div>

      {/* MODALS */}
      <RulesModal
        isOpen={modalType === 'rules'}
        onNext={handleCloseModal}
        handleClose={handleCloseModal}
        gameType={gameType}
        // rulesUrl={rulesUrl}
        playerInLobby={playerInLobby}
        isPrivateLobby={isPrivateLobby}
      />
      <PauseModal isOpen={modalType === 'pause'} handleClose={handleCloseModal} />
      <LeaveModal isOpen={modalType === 'leave'} handleClose={handleCloseModal} />
      <EditRoomModal isOpen={modalType === 'edit'} handleClose={handleCloseModal} />
      <RenameModal isOpen={modalType === 'rename'} handleClose={handleCloseModal} isGraphqlRename={false} />
      {/*<SnackbarAfterCopy
        open={modalType === 'copy'}
        onClose={handleCloseModal}
        text={
          isEnLocale
            ? 'Room link copied to the clipboard. Send it to your friends!'
            : 'Ссылка на комнату скопирована в буфер обмена. Отправь её своим друзьям!'
        }
      />*}
      {/*<SnackbarAfterCopy open={modalType === 'copy'} onClose={handleCloseModal} />*/}
      <ChangeBackground isOpen={modalType === 'background'} handleClose={handleCloseModal} />
      <RoomPreferences isOpen={modalType === 'roomPreferences'} handleClose={handleCloseModal} />
    </div>
  );
};

export default StatusBar;

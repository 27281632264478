import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { setShouldUpdateOffsets } from '@store/reducers/session.reducer';
import { selectIsInRoom } from '@store/room/roomSlice';
import { reconnectInRoomThunk } from '@store/thunk/room';
import WSClient from '@src/sockets';
import PersonsContainer from '@components/PersonsContainer/PersonsContainer';
import { styles as useStyles } from './styles';
import { visibilitySubscribeActionThunk, touchListenerThunk, clickListenerThunk } from './parts/visibilityChange';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useAgora } from './parts/agora/useAgora';
import { AgoraMedia } from './parts/agora/agoraMedia';
import { selectPlayerId } from '@src/store/reducers/player.reducer';

interface Props {
  isLoading: boolean;
}

const VideoChat = React.forwardRef<HTMLDivElement, Props>(function VideoChat(props, ref) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const playerId = useAppSelector(selectPlayerId);
  const isInRoom = useAppSelector(selectIsInRoom);
  const { roomId } = useParams<{ roomId: string }>();
  const agora = useAgora(true, roomId);
  const [playStream, setPlayStream] = useState(true);

  useEffect(() => {
    dispatch(setShouldUpdateOffsets({}));

    return () => {
      console.log('leaving videoChat');
      // destroyClient();
    };
  }, [props.isLoading]);

  useEffect(
    visibilitySubscribeActionThunk(
      function () {
        WSClient.close();
        setPlayStream(false);
        agora.stopTracks();
      },
      function () {
        agora.resumeTracks();
        setPlayStream(true);
        dispatch(reconnectInRoomThunk(roomId));
      }
    ),
    [agora.stopTracks, agora.resumeTracks]
  );

  // ToDo: Make callbacks reassignable after corresponding agora events.
  useEffect(
    touchListenerThunk(() => {}), //unmuteRemoteStreams()),
    []
  );

  useEffect(
    clickListenerThunk(() => {}), //unmuteRemoteStreams()),
    []
  );

  const renderAgoraMedia = () => {
    return (
      <>
        <PersonsContainer isLoading={props.isLoading} />
        {playStream && isInRoom && (
          <>
            <AgoraMedia videoTrack={agora.localVideoTrack} audioTrack={undefined} id={playerId} />
            {agora.remoteUsers.map((user, index) => (
              <AgoraMedia
                videoTrack={user.videoTrack}
                audioTrack={user.audioTrack}
                id={user.uid as string}
                key={index}
              />
            ))}
          </>
        )}
      </>
    );
  };

  return (
    <div className={classes.root} ref={ref}>
      {renderAgoraMedia()}
    </div>
  );
});

export default VideoChat;

import { isTouchEvent, TouchOrClick } from './types';

/*export const calcMidPoint: (p1: Point, p2: Point) => Point = (p1: Point, p2: Point) => {
  return {
    x: p1.x + (p2.x - p1.x) * 0.5,
    y: p1.y + (p2.y - p1.y) * 0.5,
  };
};*/

export const calcMidPoint = (x1: number, y1: number, x2: number, y2: number) => {
  return {
    x: x1 + (x2 - x1) * 0.5,
    y: y1 + (y2 - y1) * 0.5,
  };
};

type getPointerPositionFn = (canvas: HTMLCanvasElement, ev: TouchOrClick) => { x: number; y: number; out: boolean };

const getEventPosition = (ev: TouchOrClick) => {
  if (isTouchEvent(ev)) {
    return {
      clientX: ev.touches[0].clientX,
      clientY: ev.touches[0].clientY,
    };
  } else {
    return {
      clientX: ev.clientX,
      clientY: ev.clientY,
    };
  }
};

export const getPointerPosition: getPointerPositionFn = (canvas, ev) => {
  const rect = canvas.getBoundingClientRect();

  const { clientX, clientY } = getEventPosition(ev);
  const x = clientX - rect.left;
  const y = clientY - rect.top;
  const out = x < 0 || y < 0 || x >= rect.width || y >= rect.height;

  return { x, y, out };
};

import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  timer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },

  timerBig: {
    position: 'absolute',
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '80px',
    lineHeight: '143px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#3AB957',
    textShadow: '-1px 0 5px white, 0 1px 5px white, 1px 0 5px white, 0 -1px 5px white',
    zIndex: 20,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    // border: '6px solid #FFFFFF',
    [theme.breakpoints.up('sm')]: {
      fontSize: '120px',
    },
  },
}));

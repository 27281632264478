const FiveSecondsTextContentEn: React.FC = () => {
  return (
    <>
      <li>Players vote whether the question answered correctly by Active player</li>
      <li>If the question was answered correctly, Active player gets 1 point</li>
      <li>If at least one player votes No, Active player gets 0 points</li>
    </>
  );
};

const FiveSecondsTextContentRu: React.FC = () => {
  return (
    <>
      <li>Игроки голосуют, правильно ли активный игрок ответил на вопрос</li>
      <li>Если ответ на вопрос правильный, активный игрок получает 1 балл</li>
      <li>Ели хотя бы один игрок проголосовал Нет, активный игрок получает 0 баллов</li>
    </>
  );
};

export { FiveSecondsTextContentEn, FiveSecondsTextContentRu };

import { fontFamily } from '@src/art/theme/textStyles';
import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  energyHeader: {
    display: 'flex',
    padding: '16px 35px 12px 8px',
    width: '100%',
    height: '85px',
    background: '#F5F3DF',
    borderRadius: '20px 20px 0px 0px',
    alignItems: 'flex-start',
    borderBottom: '1px solid #AE5D02',
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {},
    '& .energy': {
      '&__icon': {
        width: '45px',
        height: '45px',
        [theme.breakpoints.up('sm')]: {
          width: '55px',
          height: '51px',
        },
      },
      '&__info': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 'auto',
        alignItems: 'baseline',
        '&-title': {
          fontFamily: fontFamily,
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '12px',
          color: '#1B2036',
          [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
            lineHeight: '17px',
          },
        },
        '&-value': {
          fontFamily: 'SF Pro Rounded Black',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: '24px',
          lineHeight: '29px',
          color: '#B800C0',
          [theme.breakpoints.up('sm')]: {
            fontSize: '32px',
            lineHeight: '37px',
          },
        },
        '&-timer': {
          fontFamily: 'SF Pro Rounded Black',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: '24px',
          lineHeight: '29px',
          textAlign: 'center',
          color: '#FFFFFF',
          textStroke: '1px #1E1E1E',
          marginTop: '5px',
        },
      },
    },
  },
}));

import { useMemo } from 'react';
import { IdType } from '@shared/generics';
import { useAppSelector } from '@app/hooks';
import { selectAvatars } from '@store/room/roomFeaturesData.slice';
import { Image } from '@components/entities/Image';
import PersonVideoOff from '@art/images/person-video-off.png';
import { styles } from './styles';

interface IProps {
  playerId: string;
  avatarId: IdType;
  muteVideoCondition: boolean;
}

const PersonVideo: React.FC<IProps> = ({ playerId, avatarId, muteVideoCondition, children }) => {
  const classes = styles();
  const avatars = useAppSelector(selectAvatars);
  const avatar = useMemo(() => avatars.find((avatar) => avatar.id === avatarId), [avatars, avatarId]);

  return (
    <div id={playerId} className={`${classes.personVideo} ${muteVideoCondition ? 'muteVideo' : ''}`}>
      <div className={classes.video} id={`${playerId}-video`} />
      {/* mute-video image / avatar image */}
      <div className={`${classes.muteVideoOverlay} muteVideoOverlay`} style={{ background: `#${avatar?.background}` }}>
        {/* <Image src={PersonVideoOff} srcSet={avatar?.image?.srcSet} alt={`PersonVideoOff-${playerId}`} /> */}
        <img src={avatar?.image} alt={`PersonVideoOff-${playerId}`} />
      </div>

      {/* stickers */}
      {/*<div className={classes.childrenOverlay}>{children}</div>*/}
      {children}
    </div>
  );
};

export default PersonVideo;

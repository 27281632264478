import { ROAST_BATTLE_STEP } from '@shared/gameInterfaces/roastBattle.socket';
import { RoastBattleState } from '@shared/gameInterfaces/roastBattle.socket';
import { TimeAlert } from '../TimeAlert/TimeAlert';
import { ITimer } from '@src/shared/common';
import { useTimer } from '@src/games/common/Timer/timer.hook';
import { styles } from './styles';

interface Props {
  timer: ITimer;
  game: RoastBattleState;
  isActivePlayer?: boolean;
  isPairPlayer?: boolean;
  alertThreshold?: number;
  showAlert?: boolean;
}

const getColorTimer = (game: RoastBattleState) => {
  switch (game.battleStep) {
    case ROAST_BATTLE_STEP.PREPARE:
      return '#3AB957';
    case ROAST_BATTLE_STEP.COUNTDOWN_FIRST:
    case ROAST_BATTLE_STEP.SPEECH_FIRST:
      return '#3A9CF7';
    case ROAST_BATTLE_STEP.COUNTDOWN_SECOND:
    case ROAST_BATTLE_STEP.SPEECH_SECOND:
      return '#FF2400';
  }
};

const TimerBig: React.FC<Props> = ({ timer, game, isActivePlayer, showAlert, alertThreshold }) => {
  const classes = styles();
  const { countDown } = useTimer(timer);
  const timerColor = getColorTimer(game);

  const getTextTimer = (game: RoastBattleState) => {
    switch (game.battleStep) {
      case ROAST_BATTLE_STEP.PREPARE:
        return countDown;
      case ROAST_BATTLE_STEP.COUNTDOWN_FIRST:
      case ROAST_BATTLE_STEP.COUNTDOWN_SECOND:
        return countDown > 1 ? `${countDown - 1}` : 'GO';
      case ROAST_BATTLE_STEP.SPEECH_FIRST:
      case ROAST_BATTLE_STEP.SPEECH_SECOND:
        return countDown > 1 ? `${countDown - 1}` : 'STOP';
    }
  };

  const getPopupTimer = (game: RoastBattleState) => {
    switch (game.battleStep) {
      case ROAST_BATTLE_STEP.COUNTDOWN_FIRST:
      case ROAST_BATTLE_STEP.COUNTDOWN_SECOND:
        return true;
      case ROAST_BATTLE_STEP.SPEECH_FIRST:
      case ROAST_BATTLE_STEP.SPEECH_SECOND:
        return false;
    }
  };

  const timerText = getTextTimer(game);
  const isStartPopup = getPopupTimer(game)!;
  const shouldTimerShow = !alertThreshold || countDown < alertThreshold;
  const shouldShowAlert = showAlert && shouldTimerShow;

  return (
    <>
      {<TimeAlert isActivePlayer={isActivePlayer} game={game} isStartPopup={isStartPopup} open={shouldShowAlert!} />}
      {shouldTimerShow && (
        <span className={classes.timerBig} style={{ color: `${timerColor}` }}>
          {timerText}
        </span>
      )}
    </>
  );
};

const TimerSmall: React.FC<Props> = ({ timer, alertThreshold, game }) => {
  const classes = styles();
  const { countDown } = useTimer(timer);
  const timerColor = getColorTimer(game);
  const shouldTimerShow = countDown > alertThreshold!;

  return (
    <>
      {shouldTimerShow && (
        <span className={classes.timerSmall} style={{ backgroundColor: timerColor }}>
          {countDown - 1}
        </span>
      )}
    </>
  );
};

export { TimerBig, TimerSmall };

import rootReducer from './rootReducer';
import { AnyAction, configureStore, getDefaultMiddleware, ThunkDispatch } from '@reduxjs/toolkit';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const finalCreateStore = composeEnhancers(applyMiddleware(thunk))(createStore);

const middleware = [...getDefaultMiddleware()];

export const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
});

export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
export type AppDispatch = ThunkDispatch<any, null, AnyAction>;
export type AppGetState = typeof store.getState;

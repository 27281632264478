import { AppDispatch } from '@src/app/store';
import { updateGameState } from '@store/reducers/game.reducer';
import { AliasState, ALIAS_STATE } from '@src/shared/gameInterfaces/alias.socket';

enum LABELS {
  READY = 'Ready',
  ACTIVE = 'Active',
}

const updateAliasLabelsThunk = (game: AliasState) => (dispatch: AppDispatch) => {
  const { gameState, readyPlayers, activePlayerId } = game;

  // ACTIVE
  const activeStates = [
    ALIAS_STATE.READY_START_ROUND,
    ALIAS_STATE.SPIN,
    ALIAS_STATE.SHOW_WORD,
    ALIAS_STATE.ALIAS,
    ALIAS_STATE.RESULTS,
  ].includes(gameState);

  // READY
  const readyStates = [ALIAS_STATE.TOP_PLAYERS, ALIAS_STATE.LEADERBOARD].includes(gameState);

  const labels = new Map<string, string>();

  // ACTIVE
  if (activeStates) {
    labels.set(activePlayerId, LABELS.ACTIVE);
  }

  // READY
  if (readyStates) {
    readyPlayers!.forEach((playerId) => labels.set(playerId, LABELS.READY));
  }

  dispatch(updateGameState({ playerLabels: [...labels.entries()] }));
};

export { updateAliasLabelsThunk };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  chooseModal: {
    padding: '20px 15px',
    [theme.breakpoints.up('sm')]: {
      width: '520px',
      height: '322px',
      padding: '27px 40px',
      maxWidth: '520px',
      justifyContent: 'center',
    },
    '& h2': {
      textAlign: 'center',
      marginTop: 0,
      marginBottom: '10px',
      [theme.breakpoints.up('sm')]: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '38px',
        textAlign: 'center',
        color: '#1B2036',
        marginBottom: '22px',
      },
    },
  },

  btnContainerGrid: {
    boxSizing: 'border-box',
    width: '100%',
    marginBottom: '15px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '5px',
    [theme.breakpoints.up('sm')]: {
      gap: '10px',
      marginBottom: '20px',
    },
  },

  btnContainerFlex: {
    boxSizing: 'border-box',
    width: '100%',
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '20px',
    },
  },

  voteBtn: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    height: '44px',
    transitionProperty: 'all',
    transitionDuration: '.23s',
    cursor: 'pointer',
    marginRight: '5px',
    marginTop: '5px',
    background: '#0A97FF',
    border: '0.5px solid #000000',
    boxShadow: 'inset 0px -2px 0px rgba(0, 0, 0, 0.3)',
    borderRadius: '5px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '24px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#FFFFFF',
    textStroke: '0.5px #000000',
    textShadow: '0px 1px 0px #000000',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '7px',
    [theme.breakpoints.up('sm')]: {
      height: '55px',
      minHeight: '44px',
      fontSize: '28px',
      lineHeight: '28px',
      borderRadius: '10px',
      padding: '11px',
    },

    '&:hover': {
      backgroundColor: '#356FD6',
    },
    '&:active': {
      backgroundColor: '#3662AE',
    },
    '&:not(nth-child(2n))': {
      marginRight: 0,
    },
  },

  none: {
    width: '100% !important',
  },
}));

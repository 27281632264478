import { useTimer } from './timer.hook';
import { ITimer } from '@src/shared/common';
import { useStyles } from './styles';
import { MixProps } from '@src/common/generics';

interface Props {
  timer: ITimer;
  subroundsLeft?: number;
}

const TextTimer: React.FC<MixProps<Props>> = ({ timer, className }) => {
  const classes = useStyles(timer);
  const { countDown, timeLeftInPercents } = useTimer(timer);

  return (
    <span
      className={`
        timerContainer
        ${className}
        ${classes.textTimerContainer}
        ${timeLeftInPercents < 40 ? 'warning' : ''}
        ${timeLeftInPercents < 15 ? 'danger' : ''}
        ${timer.timeIsUp ? 'timeIsUp' : ''}
      `}
    >
      {countDown}
    </span>
  );
};

export { TextTimer };

import { ILeaderboard, IReward, ITimer } from '../common';
import { IdType } from '../generics';
import { BaseGameState } from '../socketEvents/socketEvents';

export enum GUESS_ME_STATE {
  READY_START_ROUND = 'READY_START_ROUND',
  SPIN = 'SPIN',
  QUESTION_ACTIVE = 'QUESTION_ACTIVE',
  QUESTION_PASSIVE = 'QUESTION_PASSIVE',
  RESULTS = 'RESULTS',
  TOP_PLAYERS = 'TOP_PLAYERS',
  LEADERBOARD = 'LEADERBOARD',
}

export enum RESULTS_MATCH_TYPE {
  TARGET = 'TARGET',
  MATCH = 'MATCH',
  NONE = 'NONE',
}

export interface GuessMeState extends BaseGameState {
  gameState: GUESS_ME_STATE;
  roundNumber: number;
  totalRounds: number;
  activePlayerId: IdType;
  votedPlayers?: IdType[];
  readyPlayers?: IdType[];
  ticket?: {
    title: string;
    highQuestionGrade: string;
    lowQuestionGrade: string;
  };
  voteResults?: {
    name: string;
    value?: number;
    match: RESULTS_MATCH_TYPE;
  }[];
  leaderboard?: ILeaderboard;
  reward?: IReward;
  timerDuration?: number;
  timeIsUp?: boolean;
  timer?: ITimer;
}

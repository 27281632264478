import { useState } from 'react';
import { DrinkRouletteState } from '@shared/gameInterfaces/roulette.socket';

import { styles } from './styles';
import { useAppSelector } from '@src/app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';

interface IProps {
  playerId: string;
  gameState: DrinkRouletteState;
  isSpectator: boolean;
  onVote: (index: number) => void;
}

const SingleQuestion: React.FC<IProps> = ({ gameState, playerId, isSpectator, onVote }) => {
  const classes = styles();
  const { ticket, votedPlayers, roundNumber, totalRounds } = gameState;
  const DEFAULT_ANSWERED_INDEX = -1;

  // current state answer index
  const [stateAnswerValue, setStateAnswerValue] = useState(DEFAULT_ANSWERED_INDEX);

  // check already answered value
  const currentPlayerAnswerObj = votedPlayers?.find((answer) => answer.playerId === playerId);

  const alreadyAnswered =
    // @ts-ignore
    stateAnswerValue > DEFAULT_ANSWERED_INDEX || currentPlayerAnswerObj?.value > DEFAULT_ANSWERED_INDEX;

  const onVoteClick = (index: number) => {
    setStateAnswerValue(index); // save voteId to current state
    onVote(index); // vote action
  };

  const isEnLocale = useAppSelector(selectIsEnLocale);
  const showTitleEn = roundNumber === totalRounds ? 'Final round' : `Question ${roundNumber} of ${totalRounds}`;
  const showTitleRu = roundNumber === totalRounds ? 'Финальный раунд' : `Раунд ${roundNumber} из ${totalRounds}`;

  return (
    <div className={`${classes.singleQuestion} animate__animated  animate__fadeIn`}>
      {/* timerWrapper */}
      <div className={classes.timerWrapper} />

      {/* topSpacer */}
      <div className={classes.topSpacer} />

      {/* questionText */}
      <div className={classes.questionText}>
        <span>{isEnLocale ? showTitleEn : showTitleRu}</span>
        <p>{ticket?.question}</p>
      </div>

      {/* bottomSpacer */}
      <div className={classes.bottomSpacer} />

      {/* btnContainer */}
      <div
        className={`
        ${classes.btnContainer}
        ${alreadyAnswered || isSpectator ? 'clicked' : ''}
      `}
      >
        {ticket?.answers?.length
          ? ticket?.answers.map((answer, index) => {
              // check stateAnswerValue and outStateAnswer to match with index
              const checkAnswer = stateAnswerValue === index || currentPlayerAnswerObj?.value === index;

              return (
                <div
                  key={index}
                  className={`
                  ${classes.voteBtn}
                  ${checkAnswer ? 'clicked-btn' : 'not-clicked-btn'}
                `}
                  onClick={() => onVoteClick(index)}
                  style={{ width: `calc(100% / ${ticket?.answers?.length}` }}
                >
                  <span>{answer}</span>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export { SingleQuestion };

import { AppDispatch } from '@src/app/store';
import { DRAW_AND_GUESS_STATE, DrawAndGuessState } from '@shared/gameInterfaces/drawAndGuess.socket';
import { updateGameState } from '@store/reducers/game.reducer';

enum LABELS {
  READY = 'Ready',
  ACTIVE = 'Active',
}

const updateDrawAndGuessLabelsThunk = (game: DrawAndGuessState) => (dispatch: AppDispatch) => {
  const { gameState, readyPlayers } = game;

  // ACTIVE
  const activeStates = [
    DRAW_AND_GUESS_STATE.READY_START_ROUND,
    DRAW_AND_GUESS_STATE.SPIN,
    DRAW_AND_GUESS_STATE.CHOOSE_WORD,
    DRAW_AND_GUESS_STATE.SHOW_WORD,
    DRAW_AND_GUESS_STATE.DRAW,
    DRAW_AND_GUESS_STATE.RESULTS,
  ].includes(gameState);

  // READY
  const readyStates = [DRAW_AND_GUESS_STATE.TOP_PLAYERS, DRAW_AND_GUESS_STATE.LEADERBOARD].includes(gameState);

  const labels = new Map<string, string>();

  // ACTIVE
  if (activeStates) {
    labels.set(game.activePlayerId, LABELS.ACTIVE);
  }

  // READY
  if (readyStates) {
    readyPlayers!.forEach((playerId) => labels.set(playerId, LABELS.READY));
  }

  dispatch(updateGameState({ playerLabels: [...labels.entries()] }));
};

export { updateDrawAndGuessLabelsThunk };

import { useMediaQuery } from '@material-ui/core';

import { GameTypes } from '@src/common/constants/common';

// DRINK_ROULETTE
import RouletteLogoMobile from '@art/images/roulette/single-player-logo/logo-mobile.png';
import RouletteLogoTablet from '@art/images/roulette/single-player-logo/logo-tablet.png';
import RouletteBgMobile from '@art/images/roulette/single-player-bg/roulette-bg-mobile.png';
import RouletteBgTablet from '@art/images/roulette/single-player-bg/roulette-bg-tablet.png';

// TWENTY_QUESTIONS
import TwentyQuestionsLogoMobile from '@art/images/twenty-questions/logo-mobile.png';
import TwentyQuestionsLogoTablet from '@art/images/twenty-questions/logo-tablet.png';
import TwentyQuestionsBgMobile from '@art/images/twenty-questions/bg-mobile.jpg';
import TwentyQuestionsBgTablet from '@art/images/twenty-questions/bg-tablet.jpg';

// ROAST
import RoastLogoMobile from '@art/images/roast/logo-mobile.png';
import RoastLogoTablet from '@art/images/roast/logo-tablet.png';
import RoastBgMobile from '@art/images/roast/bg-mobile.jpg';
import RoastBgTablet from '@art/images/roast/bg-tablet.jpg';

// info-label
import InfoLabelMobile from '@art/images/landing/roast/infoLabel/info-label-mobile.png';
import InfoLabelTablet from '@art/images/landing/roast/infoLabel/info-label-tablet.png';

const getImages = (gameType: string) => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  switch (gameType) {
    // DRINK_ROULETTE
    case GameTypes.DRINK_ROULETTE:
      return {
        logo: {
          src: isMobile ? RouletteLogoMobile : RouletteLogoTablet,
          className: 'roulette-logo',
          animationName: '',
        },
        background: {
          src: isMobile ? RouletteBgMobile : RouletteBgTablet,
        },
      };

    // TWENTY_QUESTIONS
    case GameTypes.TWENTY_QUESTIONS:
      return {
        logo: {
          src: isMobile ? TwentyQuestionsLogoMobile : TwentyQuestionsLogoTablet,
          className: 'twenty-questions-logo',
          animationName: '',
        },
        background: {
          src: isMobile ? TwentyQuestionsBgMobile : TwentyQuestionsBgTablet,
        },
      };

    // ROAST
    case GameTypes.ROAST:
    case GameTypes.SEND_QUESTION:
      return {
        logo: {
          src: isMobile ? RoastLogoMobile : RoastLogoTablet,
          className: 'roast-logo',
          animationName: '',
        },
        background: {
          src: isMobile ? RoastBgMobile : RoastBgTablet,
        },
        infoLabel: {
          src: isMobile ? InfoLabelMobile : InfoLabelTablet,
        },
      };
  }
};

export default getImages;

import React, { useEffect } from 'react';
import { useStyles } from './styles';
import { useAppSelector } from '@src/app/hooks';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { Avatars } from './Avatars/Avatars';
import { Info } from '../Info/Info';
import { selectPlayerId, selectPlayerName, updatePlayerName } from '@src/store/reducers/player.reducer';
import { useDispatch } from 'react-redux';
import WSClient from '@src/sockets';
import { CLIENT_EVENTS } from '@src/shared/socketEvents/eventTypes';
import { getRoomId, selectIsInRoom } from '@src/store/room/roomSlice';
import { mutateGraphql } from '@src/library/fetch';
import { updatePlayerNameMutation } from '@src/graphql/debugQueries';
import { IdType } from '@src/shared/generics';

interface IProps {
  setAvatarId: (avatarId: IdType) => void;
  setName: (name: string) => void;
}

const ProfileTab: React.FC<IProps> = ({ setAvatarId, setName }) => {
  const classes = useStyles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const dispatch = useDispatch();
  const playerId = useAppSelector(selectPlayerId);
  const playerName = useAppSelector(selectPlayerName);
  const roomId = useAppSelector(getRoomId);
  const [oldName, setOldName] = React.useState<string>(playerName);
  const isInRoom = useAppSelector(selectIsInRoom);
  const [state, setState] = React.useState<{ name: string }>({ name: playerName });

  const renameInRoom = () => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_CHANGED_NAME, {
      playerId,
      roomId,
      newName: state.name || playerName,
    });
    dispatch(updatePlayerName(state.name || playerName));
  };

  useEffect(() => {
    return () => {
      if (oldName === state.name) return;

      setName(state.name || playerName);
      isInRoom
        ? renameInRoom()
        : mutateGraphql(updatePlayerNameMutation, { playerId: playerId, name: state.name || playerName }).then(
            (data) => {
              if (data) {
                dispatch(updatePlayerName(state.name || playerName));
              }
            }
          );
    };
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    state.name = event.target.value || '';
    setName(event.target.value);
  };

  return (
    <div className={classes.container}>
      <p className={classes.inputTitle}>{isEnLocale ? `Change Name` : `Изменить имя`}</p>
      <div className={classes.formInner}>
        <div className={classes.customInput}>
          <input
            onChange={handleChange}
            value={state.name}
            id="value"
            name="value"
            type="text"
            // style={{ border: `1px solid ${value > balance ? '#F83E67' : 'transparent'}` }}
            autoComplete="off"
            maxLength={20}
            placeholder={playerName}
          />
        </div>
      </div>
      <Avatars setAvatar={setAvatarId} />
      <div className={classes.border}></div>
      <Info />
    </div>
  );
};

export { ProfileTab };

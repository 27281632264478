import React, { MouseEventHandler } from 'react';
import CommonModal from '@components/Modals/CommonModal';
import Button from '@components/Button';
import { GameTypes } from '@src/common/constants/common';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';
import { defaultImages } from '@src/art/resources/loadResources';
import { selectCurrentGame } from '@store/room/roomSlice';
import QuestionButton from '@src/components/QuestionButton';

interface Props {
  isOpen: boolean | undefined;
  playerInLobby?: Boolean;
  isPrivateLobby?: Boolean;
  next?: Boolean;
  gameType?: GameTypes;
  rulesUrl?: string | null;
  handleClose: MouseEventHandler;
  onJoinGame: MouseEventHandler;
  isJoiningPopup?: boolean;
  selectedGame?: string;
}

const InProgressModal: React.FC<Props> = ({ isOpen, handleClose, onJoinGame }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const selectedGame = useAppSelector(selectCurrentGame);
  const banner = selectedGame?.banner || defaultImages.banner;

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} isFullscreen={true} backgroundColor={'white'}>
      <h3 id="transition-modal-title">{isEnLocale ? 'Game in progress' : 'Игра уже началась'}</h3>
      <div className={classes.container}>
        <div style={{ position: 'relative' }}>
          <img className={classes.img} alt="experience" src={banner} />
          <QuestionButton
            gameType={selectedGame!.type}
            style={{
              position: 'absolute',
              bottom: 10,
              right: 10,
              boxShadow: '0 0 0 4px rgba(255,255,255, 1)',
            }}
            isShowResults={true}
            isLeaderboardHelp={false}
          />
        </div>
      </div>
      <Button
        text={isEnLocale ? 'Join Now' : 'Присоединиться'}
        onClick={onJoinGame}
        type="primary-blue-new"
        className={classes.button}
      />
    </CommonModal>
  );
};

export default InProgressModal;

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  skeletonWrapper: {
    position: 'relative',
    display: 'flex',
    width: 'inherit',
    justifyContent: 'center',
  },

  welcomeBannerSkeleton: {
    display: 'block',
    maxWidth: '1350px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'sticky',
    top: 0,
    height: '285px',
    backgroundColor: '#E8E9EB',
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      top: '60px',
      position: 'initial',
      height: '345px',
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '0px 0px 8px 8px',
    },
  },
}));

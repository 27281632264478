import { useEffect } from 'react';
import { useGameProvider } from './fakeArtist.provider';
import { updateFakeArtistLabelsThunk } from './fakeArtist.labels';
import { useAppDispatch } from '@src/app/hooks';
import { sendRoundStarted } from '@src/services/analyticsThunk';

const useGameHook = (roomId: string) => {
  const dispatch = useAppDispatch();

  const [
    // 1 - state
    status,

    // 2 - game
    game,

    // 3 - isActivePlayer
    isActivePlayer,

    // 4 - activePlayer
    activePlayer,

    // 5 - isRoomOwner
    isRoomOwner,

    // 6 - isFakeArtist
    isFakeArtist,

    // 7 - fakeArtistName
    fakeArtistName,

    // 8 - actions
    actions,

    // 9 - eventObserver
    eventObserver,

    // 10 - isSpectator
    isSpectator,
  ] = useGameProvider(roomId);

  useEffect(() => {
    dispatch(updateFakeArtistLabelsThunk(game, isSpectator));
  }, [game]);

  useEffect(() => {
    if (game.state.READY_START_ROUND) {
      dispatch(sendRoundStarted(game.roundNumber.toString()));
    }
  }, [game.state.READY_START_ROUND]);

  return {
    // 1 - status
    status,

    // 2 - game
    game,

    // 3 - isActivePlayer
    isActivePlayer,

    // 4 - activePlayer
    activePlayer,

    // 5 - isRoomOwner
    isRoomOwner,

    // 6 - isFakeArtist
    isFakeArtist,

    // 7 - fakeArtistName
    fakeArtistName,

    // 8 - actions
    actions,

    // 9 - eventObserver
    eventObserver,

    // 10 - isSpectator
    isSpectator,
  };
};

export { useGameHook };

import { ILeaderboard, ITimer } from '../common';
import { IdType } from '../generics';
import { BaseGameState } from '../socketEvents/socketEvents';

export enum ALIAS_STATE {
  READY_START_ROUND = 'READY_START_ROUND',
  SPIN = 'SPIN',
  SHOW_WORD = 'SHOW_WORD',
  ALIAS = 'ALIAS',
  RESULTS = 'RESULTS',
  TOP_PLAYERS = 'TOP_PLAYERS',
  LEADERBOARD = 'LEADERBOARD',
}

export interface AliasState extends BaseGameState {
  gameState: ALIAS_STATE;
  roundNumber: number;
  totalRounds: number;
  activePlayerId: IdType;
  word?: string;
  winner?: IdType;
  readyPlayers?: IdType[];
  leaderboard?: ILeaderboard;
  timer?: ITimer;
}

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  chooseChestWrapper: {
    width: '100%',
    minHeight: '100%',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '43px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '53px',
    },
  },

  chooseChest: {
    width: '275px',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    marginBottom: '-10px',
    [theme.breakpoints.up('sm')]: {
      width: '565px',
      marginBottom: '-20px',
    },
  },

  chestItem: {
    display: 'flex',
    flexBasis: '78px',
    width: '78px',
    height: '78px',
    minWidth: '78px',
    maxWidth: '78px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    flexGrow: 0,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    marginBottom: '10px',
    transitionDuration: '230ms',
    transitionProperty: 'background-size',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '169px',
      width: '160px',
      height: '160px',
      minWidth: '160px',
      maxWidth: '160px',
      marginBottom: '20px',
    },
  },

  rewardBomb: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },

  bombBoom: {
    position: 'absolute',
    zIndex: 2,
    width: '225px',
    height: '225px',
    [theme.breakpoints.up('sm')]: {
      width: '300px',
      height: '300px',
    },
  },

  boomVideo: {
    position: 'absolute',
    zIndex: 2,
    width: '450px',
    height: '450px',
    backgroundColor: 'transparent',
    '& > source': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('sm')]: {
      width: '600px',
      height: '600px',
    },
  },

  boomTrack: {
    position: 'absolute',
    zIndex: 1,
    width: '158px',
    height: '88px',
    animationDuration: '50ms',
    animationDelay: '1600ms',
    [theme.breakpoints.up('sm')]: {
      width: '295px',
      height: '164px',
    },
  },

  rewardMoney: {
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      width: '38px',
      height: '38px',
      [theme.breakpoints.up('sm')]: {
        width: '76px',
        height: '76px',
      },
    },
    '& > span': {
      position: 'absolute',
      bottom: '-18px',
      fontWeight: 800,
      fontSize: '15px',
      lineHeight: '18px',
      color: '#FFDE17',
      textShadow: '1px 1px 0 #E87B02',
      [theme.breakpoints.up('sm')]: {
        fontSize: '30px',
        lineHeight: '36px',
        bottom: '-35px',
      },
    },
  },

  rewardTreasure: {
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      width: '75px',
      height: '61px',
      [theme.breakpoints.up('sm')]: {
        width: '138px',
        height: '112px',
      },
    },
  },

  rewardNft: {
    flexDirection: 'column',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '12px',
  },
}));

import React, { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { ROOM_STATE, selectIsInRoom, selectRoomState } from '@store/room/roomSlice';
import Logo from '@art/icons/StatusBar/logo.svg';
import User from '@art/icons/StatusBar/user.svg';
import { LanguageSwitcher } from './LanguageSwitcher';
import { Balance } from './Balance';
import { Energy } from './Energy';
import { HomepageBarStyles } from './styles';
import { MENU_PAGE, selectIsCoinPage, selectIsEnergyPage, selectIsProfilePage, setPage } from '@store/reducers/popups';
import { selectOwnAvatar, selectPlayerId } from '@store/reducers/player.reducer';
import { useGoBack } from '../BackButton/BackButton';
import LogoModal from '../Modals/LogoModal';
import { useDeviceSize } from '@src/common/screenSizes';
import { pushNotification } from '@src/store/room/roomFeaturesData.slice';
import { IQuestNotification, IResultNotification, NOTIFICATION_TYPE } from '@src/store/room/notifications';

const MenuBar = React.forwardRef<HTMLDivElement>(function MenuBar(props, ref) {
  const classes = HomepageBarStyles();
  const dispatch = useAppDispatch();
  const isInRoom = useAppSelector(selectIsInRoom);
  const isProfilePage = useAppSelector(selectIsProfilePage);
  const isCoinPage = useAppSelector(selectIsCoinPage);
  const isEnergyPage = useAppSelector(selectIsEnergyPage);
  const { goBack } = useGoBack();
  const [isOpen, setIsOpen] = useState(false);
  const roomState = useAppSelector(selectRoomState);
  const avatar = useAppSelector(selectOwnAvatar);
  const { isMobile } = useDeviceSize();

  const onProfileClick = () => {
    if (isProfilePage) {
      dispatch(setPage(MENU_PAGE.MAIN));
    } else {
      dispatch(setPage(MENU_PAGE.PROFILE));
    }
  };

  const onCoinClick = () => {
    if (isCoinPage) {
      dispatch(setPage(MENU_PAGE.MAIN));
    } else {
      dispatch(setPage(MENU_PAGE.COIN));
    }
  };

  const onEnergyClick = () => {
    if (isEnergyPage) {
      dispatch(setPage(MENU_PAGE.MAIN));
    } else {
      dispatch(setPage(MENU_PAGE.ENERGY));
    }
  };

  const onLogoClick = () => {
    dispatch(setPage(MENU_PAGE.MAIN));
    roomState === ROOM_STATE.GAME ? setIsOpen(true) : goBack();
  };

  return (
    <div className={classes.menuBarContainer} ref={ref}>
      {(!isInRoom || (isInRoom && !isMobile)) && <img className={classes.logo} src={Logo} onClick={onLogoClick} />}
      <LogoModal isOpen={isOpen} handleClose={() => setIsOpen(false)} />

      {isInRoom && (
        <div onClick={onProfileClick} className={classes.iconWrapper}>
          <img
            className={classes.icon}
            src={avatar ? avatar?.image : User}
            style={{ backgroundColor: `#${avatar?.background}`, borderRadius: '50%' }}
          />
        </div>
      )}
      {isInRoom && <Balance onClick={onCoinClick} />}
      {isInRoom && <Energy onClick={onEnergyClick} />}
      <LanguageSwitcher />
    </div>
  );
});

export { MenuBar };

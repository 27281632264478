import React from 'react';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';
import { useAppSelector } from '@app/hooks';
import { ILeaderboard, ILeaderboardItem, IReward, ITimer, RewardState } from '@src/shared/common';
import { TextTimer } from '../Timer/TextTimer';
import { CommonModal } from '@src/components/Modals';

interface Props {
  game: {
    timer?: ITimer;
  };
}

const WaitModal: React.FC<Props> = ({ game }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <CommonModal isOpen={true} handleClose={() => {}}>
      <div className={classes.wrapper}>
        <div className={classes.timer}>
          <TextTimer timer={game.timer!} />
        </div>
        <h3 id="transition-modal-title">{isEnLocale ? 'Round complete' : 'Раунд завершен'}</h3>
        <h4 id="transition-modal-title">{isEnLocale ? 'Please wait' : 'Пожалуйста, подождите'}</h4>
      </div>
    </CommonModal>
  );
};

export { WaitModal };

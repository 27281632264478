import React from 'react';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { IDepositNotification } from '@src/store/room/notifications';
import { useStyles } from './styles';
import HelloCoin from '@art/icons/hello-coin.svg';

interface Props {
  notification: IDepositNotification;
}

const DepositNotification: React.FC<Props> = ({ notification }) => {
  const classes = useStyles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const text = notification.result
    ? isEnLocale
      ? `Deposit complete`
      : `Зачисление выполнено`
    : isEnLocale
    ? `Deposit failed. Please try again`
    : `Зачисление не выполнено. Пожалуйста, попробуйте снова`;

  return (
    <div
      className={classes.deposit}
      style={{
        background: notification.result
          ? 'linear-gradient(180deg, #BDEBE1 0%, #A4E1DA 100%)'
          : 'linear-gradient(180deg, #FF0844 0%, #FFB199 150%)',
        color: notification.result ? '#1B2036' : 'white',
      }}
    >
      <div> {text}</div>
      {notification.result && (
        <div>
          <p className={'amount'}>{notification.amount}</p>
          <img className={'coin'} src={HelloCoin} alt="coin" />
        </div>
      )}
    </div>
  );
};

export { DepositNotification };

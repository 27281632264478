import WSClient from '@src/sockets';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGameProvider } from './RoastBattleProvider';
import { CLIENT_EVENTS } from '@shared/socketEvents/eventTypes';
import { updateRoastBattleLabelsThunk } from './RoastBattle.labels';
import { sendRoundStarted } from '@services/analyticsThunk';
import { useAppDispatch } from '@src/app/hooks';
import { GameTypes } from '@src/common/constants/common';
// import { ROAST_BATTLE_STATE, RoastBattleState } from '@shared/gameInterfaces/roastBattle.socket';

const useGameHook = (roomId: string) => {
  const dispatch = useAppDispatch();

  const [state, game, playerId, isActivePlayer, isPairPlayer, isSpectator, isRoundPlayers] = useGameProvider(roomId);

  const activeGameId = game.activeGameId;
  const socketData = {
    roomId,
    activeGameId,
    playerId,
    gameType: GameTypes.ROAST_BATTLE,
  };

  useEffect(() => {
    dispatch(updateRoastBattleLabelsThunk(game));
  }, [state]);

  useEffect(() => {
    if (state.READY_START_ROUND) {
      dispatch(sendRoundStarted(game.roundNumber.toString()));
    }
  }, [state.READY_START_ROUND]);

  return {
    state,
    game,
    playerId,
    isActivePlayer,
    isPairPlayer,
    actions: {
      onSpin: () => {
        WSClient.emit(CLIENT_EVENTS.ROUND_STARTED, socketData);
      },

      onVote: (value: string | undefined) => {
        WSClient.emit(CLIENT_EVENTS.PLAYER_VOTED, {
          roomId,
          activeGameId: game.activeGameId,
          playerId,
          value,
        });
      },

      onReady: () => {
        WSClient.emit(CLIENT_EVENTS.PLAYER_READY, {
          roomId,
          activeGameId: game.activeGameId,
          playerId,
        });
      },

      onQuit: () => {
        WSClient.emit(CLIENT_EVENTS.PLAYER_READY_TO_QUIT_GAME, {
          roomId,
          activeGameId: game.activeGameId,
          playerId,
        });
      },
    },
    isSpectator,
    isRoundPlayers,
  };
};

type HookType = ReturnType<typeof useGameHook>;
type ActionsType = HookType['actions'];

export type { ActionsType };

export { useGameHook };

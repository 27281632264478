import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';

type Props = {
  showAlert?: boolean;
};

const TimeAlert: React.FC<Props> = ({ showAlert }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <div
      className={`
      ${classes.TimeAlert}
      ${showAlert ? 'animate__fadeInDown' : 'animate__fadeOutUp'} animate__animated`}
    >
      <div className={`${classes.TimeAlertText} mobile-outline`}>{isEnLocale ? 'Time is up!' : 'Время вышло'}</div>
    </div>
  );
};

export { TimeAlert };

import React, { useState } from 'react';
import { CommonModal } from '@components/Modals';
import { styles } from './styles';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import CloseCross from '@src/components/CloseCross/CloseCross';
import {
  selectCamera,
  selectCameras,
  selectIsAgoraLoading,
  selectMicrophone,
  selectMicrophones,
  setCamera,
  setMicrophone,
  setResetAgora,
} from '@src/store/reducers/playerData.reducer';

interface Props {
  isOpen: boolean | undefined;
  handleClose: () => void;
}

const SettingsModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const dispatch = useAppDispatch();
  const cameras = useAppSelector(selectCameras);
  const microphones = useAppSelector(selectMicrophones);
  const camera = useAppSelector(selectCamera);
  const microphone = useAppSelector(selectMicrophone);
  const isAgoraLoading = useAppSelector(selectIsAgoraLoading);

  const onCameraChange = (deviceId: string) => {
    const currentCamera = cameras.find((camera) => camera.deviceId === deviceId);
    dispatch(setCamera(currentCamera));
    dispatch(setResetAgora({}));
  };

  const onMicrophoneChange = (deviceId: string) => {
    const currentMicrophone = microphones.find((microphone) => microphone.deviceId === deviceId);
    dispatch(setMicrophone(currentMicrophone));
    dispatch(setResetAgora({}));
  };

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      backgroundColor={'linear-gradient(180deg, #E8FDFF 0%, #BCE1FC 100%)'}
    >
      <div className={classes.wrapper}>
        <CloseCross onClick={handleClose} />
        <h3 id="transition-modal-title">{isEnLocale ? 'Settings' : 'Настройки'}</h3>
        <div className={classes.content}>
          <div className={classes.item}>
            <label htmlFor="camera" className="title">
              {isEnLocale ? 'Camera' : 'Камера'}
            </label>
            <select
              name="camera"
              value={camera?.deviceId}
              onChange={(event) => onCameraChange(event.currentTarget.value)}
              className="menu"
              disabled={isAgoraLoading || cameras.length < 1}
            >
              {cameras.length > 0 ? (
                cameras.map((camera, index) => (
                  <option value={camera.deviceId} key={index}>
                    {camera.label}
                  </option>
                ))
              ) : (
                <option value={''}>
                  {isEnLocale ? 'You do not have any camera' : 'У тебя нет подключенной камеры'}
                </option>
              )}
            </select>
            <div className="arrow" />
          </div>
          <div className={classes.item}>
            <label htmlFor="microphone" className="title">
              {isEnLocale ? 'Microphone' : 'Микрофон'}
            </label>
            <select
              name="microphone"
              value={microphone?.deviceId}
              onChange={(event) => onMicrophoneChange(event.currentTarget.value)}
              className="menu"
              disabled={isAgoraLoading || cameras.length < 1}
            >
              {microphones.length > 0 ? (
                microphones.map((micro, index) => (
                  <option value={micro.deviceId} key={index}>
                    {micro.label}
                  </option>
                ))
              ) : (
                <option value={''}>
                  {isEnLocale ? 'You do not have any microphone' : 'У тебя нет подключенного микрофона'}
                </option>
              )}
            </select>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default SettingsModal;

import React from 'react';
import { styles } from './styles';
import { useAppSelector } from '@src/app/hooks';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { Quest } from './quest';
import { IPlayerQuest, QUEST_STATE } from '@src/shared/quests';
import { useResetTimer } from '@src/common/misc';
import { selectResetData } from '@src/store/reducers/common';

interface IProps {
  quests: IPlayerQuest[];
}

const Quests: React.FC<IProps> = ({ quests }) => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();
  const completedQuests = quests.filter((quest) => [QUEST_STATE.DONE, QUEST_STATE.CLAIMED].includes(quest.state));
  const dailyQuests = quests.filter((quest) => quest.state === QUEST_STATE.DAILY);
  const achievements = quests.filter((quest) => quest.state === QUEST_STATE.ONCE);
  const resetData = useAppSelector(selectResetData);
  const claimDate = useResetTimer(resetData);

  // console.log(quests);

  const renderQuests = (quests: IPlayerQuest[]) => {
    return quests.map((quest, index) => <Quest key={index} questData={quest} />);
  };

  return (
    <div className={classes.questsContainer}>
      <p className={classes.title}>{isEnLocale ? 'Daily Quests' : 'Задания'}</p>
      {dailyQuests.length > 0 ? (
        renderQuests(dailyQuests)
      ) : (
        <div
          style={{
            flexDirection: 'column',
            borderBottom: '1px solid rgba(15, 94, 213, 0.1)',
            paddingBottom: '10px',
            alignItems: 'center',
          }}
        >
          <p className={classes.subtitle}>
            {isEnLocale ? 'Come back tomorrow and check new tasks' : 'Возвращайся завтра и проверь новые задания'}
          </p>
          <p className={classes.timer}>{claimDate}</p>
        </div>
      )}
      {completedQuests.length > 0 && (
        <>
          <p className={classes.title}>{isEnLocale ? 'Completed Quests' : 'Выполненные'}</p>
          {renderQuests(completedQuests)}
        </>
      )}
      {achievements.length > 0 && (
        <>
          <p className={classes.title}>{isEnLocale ? 'Achievements' : 'Достижения'}</p>
          {renderQuests(achievements)}
        </>
      )}
    </div>
  );
};

export { Quests };

import React, { useEffect, useState } from 'react';
import { useStyles } from './defaultStyles';
import { Headline2 } from '../Headline2/headline';
import { GameCard } from '../entities/GameCard';
import { Game } from '@graphql/queries';
import _ from 'lodash';

// You might also use ResizeObserver

interface Props {
  setId?: string;
  games: Game[];
  headline?: string;
  canShowAll?: boolean;
}

const GamesContainer: React.FC<Props> = ({ games, headline = '' }) => {
  const classes = useStyles();
  const [sortedGames, setSortedGames] = useState<Game[]>([]);

  useEffect(() => {
    setSortedGames(_.orderBy(games, ['balance.dailyBalance'], ['desc']));
  }, [games]);

  const renderGames = () => {
    return sortedGames.map((game, index) => <GameCard key={index} game={game} />);
  };

  return (
    <div className={classes.containerBlock}>
      <Headline2 headline={headline} />
      <div className={classes.elementsContainer}>{renderGames()}</div>
    </div>
  );
};
export { GamesContainer };

import { useGameHook } from './guessMe.hook';
import GameWrapper from '@components/GameWrapper';
import { Leaderboard } from '@src/games/common/Leaderboard/Leaderboard';
import { GameTypes } from '@src/common/constants/common';
import { StartRound, Question, VoteList } from './parts';
import PartyFinishModal from '../common/PartyFinishModal';

interface IProps {
  roomId: string;
}

const GuessMe: React.FC<IProps> = ({ roomId }) => {
  const { state, game, playerId, actions, players, isActivePlayer, isSpectator } = useGameHook(roomId);
  const { onSpin, onPreVote, onVote, onNext, onGameQuit } = actions;

  const onClick = () => {
    state.TOP_PLAYERS ? onNext() : onGameQuit();
  };

  return (
    <GameWrapper state={state}>
      {(state.READY_START_ROUND || state.SPIN) && (
        <StartRound game={game} isActivePlayer={isActivePlayer} onSpin={onSpin} isSpin={state.SPIN} />
      )}

      {(state.QUESTION_ACTIVE || state.QUESTION_PASSIVE) && (
        <Question
          game={game}
          isActivePlayer={isActivePlayer}
          isSpectator={isSpectator}
          players={players}
          onPreVote={onPreVote}
          onVote={onVote}
          playerId={playerId}
          isActivePlayerVote={state.QUESTION_ACTIVE}
        />
      )}

      {state.RESULTS && <VoteList game={game} isSpectator={isSpectator} onNext={onNext} playerId={playerId} />}

      {(state.TOP_PLAYERS || state.LEADERBOARD) && (
        <Leaderboard
          game={game}
          onClick={onClick}
          isSpectator={isSpectator}
          isTopPlayers={state.TOP_PLAYERS}
          gameType={GameTypes.GUESS_ME}
          roomId={roomId}
        />
      )}

      {game.reward && <PartyFinishModal isOpen={true} game={game} />}
    </GameWrapper>
  );
};

export { GuessMe };

import { makeStyles, Theme } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  copy: {
    fontFamily: fontFamily,
    boxShadow: 'none',
    padding: '10px',
    background: 'linear-gradient(180deg, #BDEBE1 0%, #A4E1DA 100%)',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    color: '#1B2036',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '21px',
    height: '55px',
    width: '100%',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  result: {
    width: '100%',
    fontFamily: fontFamily,
    boxShadow: 'none',
    // padding: '0px 10px',
    background: 'linear-gradient(180deg, #BDEBE1 0%, #A4E1DA 100%)',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    color: '#1B2036',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    height: '100%',
    minHeight: '55px',

    '&.unsuccessful': {
      background: 'linear-gradient(180deg, #FF0844 0%, #FFB199 150%)',
    },
  },
  recieved: {
    fontFamily: fontFamily,
    margin: 'auto',
    boxShadow: 'none',
    background: 'linear-gradient(180deg, #FCF6DE 0%, #FBECAB 100%)',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    color: '#1B2036',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    minHeight: '55px',
    padding: '10px',
    '& img': {
      width: '35px',
      height: '35px',
      marginRight: '10px',
      borderRadius: '2px',
    },
    '& .coin': {
      width: '20px',
      height: '20px',
      marginLeft: '2px',
    },
    '& .amount': {
      fontFamily: 'SF Pro Rounded Black',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '17px',
      lineHeight: '17px',
      textAlign: 'right',
      color: '#FFE250',
      textStroke: '1px #985E02',
    },
  },
  publicity: {
    boxSizing: 'border-box',
    margin: 'auto',
    fontFamily: fontFamily,
    maxWidth: '385px',
    height: '100%',
    boxShadow: 'none',
    padding: '15px 15px 15px 15px',
    flexShrink: 0,
    background: 'linear-gradient(92.08deg, #69FF97 2.25%, #00E4FF 100.96%)',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '20px',
    color: '#1B2036',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '21px',
    width: '100%',

    '& svg': {
      marginRight: '7px',
      flexShrink: 0,
    },
  },
  quest: {
    fontFamily: fontFamily,
    boxShadow: 'none',
    padding: '15px',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#1B2036',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '21px',
    height: '100%',
    background: 'linear-gradient(180deg, #D4E3F6 0%, #CFDAF6 100%)',
    borderRadius: '8px',
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
    '& .questTitle': {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '17px',
      color: '#1B2036',
      marginBottom: '15px',
      textAlign: 'left',
      maxWidth: '255px',
      width: '100%',
    },
    '& .rewardPart': {
      flexDirection: 'row',
      // justifyContent: 'initial !important',
      width: '100%',
      display: 'flex',
      '& > button': {
        height: '32px',
        display: 'flex',
      },
    },
  },
  deposit: {
    width: '100%',
    fontFamily: fontFamily,
    boxShadow: 'none',
    background: 'linear-gradient(180deg, #BDEBE1 0%, #A4E1DA 100%)',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    height: '100%',
    minHeight: '55px',
    flexDirection: 'column',
    '& .amount': {
      fontFamily: 'SF Pro Rounded Black',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '17px',
      lineHeight: '17px',
      textAlign: 'right',
      color: '#FFE250',
      textStroke: '1px #985E02',
    },
    '& .coin': {
      width: '20px',
      height: '20px',
      marginLeft: '2px',
    },
  },
}));

import React, { useState } from 'react';
import { useEthers } from '@usedapp/core';
import { useAppSelector } from '@app/hooks';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import MetamaskPopup from './MetamaskPopup/MetamaskPopup';
import ChangeBlockChainPopup from './ChangeBlockChainPopup';

import { styles } from './styles';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';

type BscConnectButtonProps = {
  className?: string;
  walletConnected?: boolean;
};

export const BscConnectButton: React.FC<BscConnectButtonProps> = ({ className, walletConnected }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const [isMetamaskPopupOpen, setMetamaskPopupOpen] = useState(false);
  const [isChangeBlockChainPopupOpen, setChangeBlockChainPopupOpen] = useState(false);
  const { activateBrowserWallet, account } = useEthers();

  // onConnect click
  const onConnect = () => {
    if (typeof window.ethereum !== 'undefined') {
      activateBrowserWallet();
    } else {
      setMetamaskPopupOpen(true);
    }
  };

  // onAdressClick
  const onAdressClick = () => {
    navigator.clipboard.writeText(`${account}`);
    setSnackbarOpen(true);
  };

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  if (walletConnected && account) {
    const accountSliced = `${account.slice(0, 4)}...${account.slice(-4)}`;

    return (
      <>
        <b title="Click to copy" className={classes.walletAddresLink} onClick={onAdressClick}>
          {accountSliced}
        </b>

        <Snackbar
          open={isSnackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          autoHideDuration={2500}
          className={classes.snackBar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message={isEnLocale ? 'Address copied' : 'Адрес скопирован'}
        />
      </>
    );
  } else {
    return (
      <>
        <button className={className} onClick={onConnect}>
          {isEnLocale ? 'Connect wallet' : 'Подключить кошелёк'}
        </button>

        <MetamaskPopup isOpen={isMetamaskPopupOpen} handleClose={() => setMetamaskPopupOpen(true)} />

        <ChangeBlockChainPopup
          isOpen={isChangeBlockChainPopupOpen}
          handleClose={() => setChangeBlockChainPopupOpen(true)}
        />
      </>
    );
  }
};

export const BscDisconnectButton: React.FC<BscConnectButtonProps> = ({ className }) => {
  const { deactivate, account } = useEthers();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return account ? (
    <button className={className} onClick={deactivate}>
      {isEnLocale ? 'Disconnect' : 'Отсоединить'}
    </button>
  ) : null;
};

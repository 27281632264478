import { useMediaQuery } from '@material-ui/core';

import { GameTypes } from '@src/common/constants/common';

// DRINK_ROULETTE
import RouletteImgMobile from '@art/images/roulette/roulette-wheel/roulette-mobile.png';
import RouletteNumbersImgMobile from '@art/images/roulette/numbers/numbers-mobile.png';
import RouletteButtonImgMobile from '@art/images/roulette/spin-button/button-mobile.png';
import RouletteImgTablet from '@art/images/roulette/roulette-wheel/roulette-tablet.png';
import RouletteNumbersImgTablet from '@art/images/roulette/numbers/numbers-tablet.png';
import RouletteButtonImgTablet from '@art/images/roulette/spin-button/button-tablet.png';

// TWENTY_QUESTIONS
import TwentyQuestionsImgMobile from '@art/images/twenty-questions/orange-mobile.png';
import TwentyQuestionsButtonImgMobile from '@art/images/twenty-questions/button-mobile.png';
import TwentyQuestionsTablet from '@art/images/twenty-questions/orange-tablet.png';
import TwentyQuestionsButtonImgTablet from '@art/images/twenty-questions/button-tablet.png';

// ROAST
import RoastImgMobile from '@art/images/roast/fire-circle-mobile.png';
import RoastButtonImgMobile from '@art/images/roast/button-mobile.png';
import RoastImgTablet from '@art/images/roast/fire-circle-tablet.png';
import RoastButtonImgTablet from '@art/images/roast/button-tablet.png';

// @ts-ignore
const getSpinnerImages = (gameType: string) => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  switch (gameType) {
    // DRINK_ROULETTE
    case GameTypes.DRINK_ROULETTE:
      return {
        button: {
          src: isMobile ? RouletteButtonImgMobile : RouletteButtonImgTablet,
          animationName: 'disappearing',
        },
        spinnerImage: {
          src: isMobile ? RouletteImgMobile : RouletteImgTablet,
          animationName: '',
        },
        spinnerImage2: {
          src: isMobile ? RouletteNumbersImgMobile : RouletteNumbersImgTablet,
          animationName: 'rotating-360',
        },
      };

    // TWENTY_QUESTIONS
    case GameTypes.TWENTY_QUESTIONS:
      return {
        button: {
          src: isMobile ? TwentyQuestionsButtonImgMobile : TwentyQuestionsButtonImgTablet,
          animationName: 'disappearing',
        },
        spinnerImage: {
          src: isMobile ? TwentyQuestionsImgMobile : TwentyQuestionsTablet,
          animationName: 'rotating-360',
        },
      };

    // ROAST
    case GameTypes.ROAST:
    case GameTypes.SEND_QUESTION:
      return {
        button: {
          src: isMobile ? RoastButtonImgMobile : RoastButtonImgTablet,
          animationName: 'disappearing',
        },
        spinnerImage: {
          src: isMobile ? RoastImgMobile : RoastImgTablet,
          animationName: 'rotating-360',
        },
      };
  }
};

export default getSpinnerImages;

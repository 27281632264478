import { makeStyles } from '@material-ui/core';

import {
  BgMobile,
  BgTablet,
  BgDesktop,
  LogoMobile,
  LogoTablet,
  LogoDesktop,
  InfoLabelMobile,
  InfoLabelTablet,
  InfoLabelDesktop,
  StartButtonMobile,
  StartButtonTablet,
  StartButtonDesktop,
  InviteButtonMobile,
  InviteButtonTablet,
  InviteButtonDesktop,
} from './importImages';

export const styles = makeStyles((theme) => ({
  start: {
    position: 'relative',
    zIndex: 1,
    flexGrow: 1,
    paddingTop: '20px',
    width: '100%',
    flexDirection: 'column',
    padding: '15px',
    alignItems: 'center',
    minHeight: '350px',
    backgroundImage: `url(${BgMobile})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    [theme.breakpoints.up('sm')]: {
      minHeight: '700px',
      backgroundImage: `url(${BgTablet})`,
    },
    [theme.breakpoints.up('md')]: {
      padding: '30px',
      backgroundImage: `url(${BgDesktop})`,
    },
  },

  logoContainer: {
    flexGrow: 1,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    backgroundPosition: 'center',
    width: '265px',
    backgroundImage: `url(${LogoMobile})`,
    [theme.breakpoints.up('sm')]: {
      width: '550px',
      backgroundImage: `url(${LogoTablet})`,
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${LogoDesktop})`,
    },
  },

  buttonsContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    color: 'inherit',
    '& > div': {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },

  buttonStart: {
    width: '175px',
    height: '55px',
    marginBottom: '10px',
    backgroundImage: `url(${StartButtonMobile})`,
    [theme.breakpoints.up('sm')]: {
      width: '322px',
      height: '100px',
      marginBottom: '20px',
      backgroundImage: `url(${StartButtonTablet})`,
    },
    [theme.breakpoints.up('md')]: {
      width: '330px',
      height: '103px',
      backgroundImage: `url(${StartButtonDesktop})`,
    },
  },

  buttonInvite: {
    width: '115px',
    height: '37px',
    backgroundImage: `url(${InviteButtonMobile})`,
    [theme.breakpoints.up('sm')]: {
      width: '228px',
      height: '74px',
      backgroundImage: `url(${InviteButtonTablet})`,
    },
    [theme.breakpoints.up('md')]: {
      width: '236px',
      height: '77px',
      backgroundImage: `url(${InviteButtonDesktop})`,
    },
  },

  infoLabel: {
    position: 'absolute',
    left: '15px',
    bottom: '15px',
    zIndex: 2,
    width: '59px',
    height: '67px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${InfoLabelMobile})`,
    [theme.breakpoints.up('sm')]: {
      width: '120px',
      height: '137px',
      backgroundImage: `url(${InfoLabelTablet})`,
    },
    [theme.breakpoints.up('md')]: {
      width: '125px',
      height: '143px',
      backgroundImage: `url(${InfoLabelDesktop})`,
    },
  },
}));

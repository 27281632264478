import React, { useEffect, useState } from 'react';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import Button from '@components/Button';
import { CommonModal } from '@components/Modals';
import { styles } from './styles';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import helloCoin from '@art/icons/coin_flat.svg';
import { Game } from '@src/graphql/queries';
import { setGameBalance } from '@src/store/reducers/common';
import CloseCross from '@src/components/CloseCross/CloseCross';
import energyIcon from '@src/art/icons/StatusBar/energy.svg';
import { useGameModal } from '@src/games/UnityGame/UnityGame.hooks';
import { EnergyEmpty } from '../EmptyNotification/EnergyEmpty';
import { GameTreasuryEmpty } from '../EmptyNotification/GameTreasuryEmpty';
import { ENERGY_COST } from '@src/common/constants/system';
import { IdType } from '@src/shared/generics';
import { CLIENT_EVENTS, SERVER_EVENTS } from '@src/shared/socketEvents/eventTypes';
import WSClient from '@src/sockets';
import { UnityFinishServerEvent } from '@src/shared/socketEvents/socketEvents';
import { UNITY_POPUP_STATE } from '../unityModals.types';
import { analytics } from '@src/services/amplitude';

interface Props {
  isOpen: boolean;
  game: Game;
  activeGameId: IdType;
  result: number;
  handleClose: () => void;
  finishGame: () => void;
}

const UnityFinishModal: React.FC<Props> = ({ isOpen, game, activeGameId, result, handleClose, finishGame }) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const { friendBonus, friendsBonusPercent, popupState } = useGameModal(game, isOpen, result);
  const gameReward = result * game.balance!.multiplier;
  const friendsBonusCoins = gameReward * friendBonus;
  const [isRequesting, setIsRequesting] = useState(false);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const [showPopup, setShowPopup] = useState(false);
  const showFullPopup = popupState === UNITY_POPUP_STATE.FULL || isRequesting;

  const onQuit = (claimed: boolean) => {
    analytics.logEvent(analytics.EVENTS.UNITY_ENDED, { gameId: game.id, state: popupState, claimed });
    finishGame();
  };

  const onClose = () => {
    if (popupState === UNITY_POPUP_STATE.FULL) {
      analytics.logEvent(analytics.EVENTS.CLAIM_CLOSED, { result, toClaim: gameReward + friendsBonusCoins });
    }
    onQuit(false);
  };

  const onClick = () => {
    setIsRequesting(true);
    WSClient.emit(CLIENT_EVENTS.PLAYER_UNITY_FINISH, { activeGameId: activeGameId, result });
    analytics.logEvent(analytics.EVENTS.CLAIM_CLAIMED, { result, toClaim: gameReward + friendsBonusCoins });
  };

  useEffect(() => {
    const onGameFinish = ({ data }: { data: UnityFinishServerEvent }) => {
      if (data.result || data.dailyBalance === undefined) {
        onQuit(true);
      } else {
        dispatch(setGameBalance({ gameId: game.id, dailyBalance: data.dailyBalance }));
      }
      setIsRequesting(false);
    };

    WSClient.on(SERVER_EVENTS.PLAYER_UNITY_FINISH, onGameFinish);
    return () => {
      WSClient.off(SERVER_EVENTS.PLAYER_UNITY_FINISH, onGameFinish);
    };
  }, []);

  useEffect(() => {
    setShowPopup(isOpen && popupState !== UNITY_POPUP_STATE.NO_POPUP);

    if (isOpen) {
      analytics.logEvent(analytics.EVENTS.CLAIM_SHOWN, { hasReward: popupState === UNITY_POPUP_STATE.FULL });
    }

    if (isOpen && popupState === UNITY_POPUP_STATE.NO_POPUP) {
      onQuit(false);
    }
  }, [isOpen, popupState]);

  return (
    <CommonModal isOpen={showPopup} handleClose={() => onClose()}>
      <div className={classes.wrapper}>
        <h3 id="transition-modal-title">{isEnLocale ? 'Round Complete' : 'Раунд завершен'}</h3>

        <CloseCross onClick={() => onClose()} />
        {showFullPopup && (
          <div style={{ flexDirection: 'column', width: '100%' }}>
            <div className={classes.row}>
              <p className={'title'}>{isEnLocale ? 'Your Results' : 'Твой результат'}</p>
              <div className={'dots'} />
              <p className={'value'} style={{ color: '#1B2036' }}>
                {result}
              </p>
            </div>

            <div className={classes.row}>
              <p className={'title'}>{isEnLocale ? 'Total Friends Bonus' : 'Бонус за друзей'}</p>
              <div className={'dots'} />
              <p className={'value'}>{friendsBonusPercent ? `+${friendsBonusPercent.toFixed()}` : 0}%</p>
            </div>

            <div className={classes.row}>
              <p className={'title'}>{isEnLocale ? 'Total Rewards' : 'Общая награда'}</p>
              <div className={'dots'} />
              <p className={'value'}>
                {friendsBonusCoins > 0
                  ? `${gameReward.toFixed(2)} + ${friendsBonusCoins.toFixed(2)}`
                  : `${gameReward.toFixed(2)}`}
              </p>
              <img className={'coin'} src={helloCoin} alt="coin" />
            </div>
          </div>
        )}

        {popupState === UNITY_POPUP_STATE.NO_ENERGY && <EnergyEmpty />}

        {popupState === UNITY_POPUP_STATE.NO_TREASURY && <GameTreasuryEmpty />}

        <div className={classes.btnContainer}>
          {showFullPopup ? (
            <Button
              onClick={onClick}
              disabled={isRequesting || result <= 0}
              text={isEnLocale ? `Claim for ${ENERGY_COST}` : `Забрать за ${ENERGY_COST}`}
              type={'primary-blue-new'}
              className="send"
            >
              <img src={energyIcon} alt="" />
            </Button>
          ) : (
            <Button
              onClick={() => onQuit(false)}
              // disabled={}
              text={isEnLocale ? 'Next' : 'Далее'}
              type={'primary-blue-new'}
              className="send"
            />
          )}
        </div>
      </div>
    </CommonModal>
  );
};

export default UnityFinishModal;

import React, { useEffect } from 'react';
import { RoomCard } from '@components/entities/RoomCard';
import { IActiveRoom } from '@src/shared/misc/room.fragment';
import { getIsHomePageLoading } from '@store/reducers/loadActions';
import { useStyles } from './styles';
import { useAppSelector } from '@src/app/hooks';
import { selectRoomSetName, selectRoomsNotOwned } from '@src/store/reducers/common';
import { Headline2 } from '@src/components/Headline2/headline';

const RoomsPage: React.FC = () => {
  const rooms = useAppSelector(selectRoomsNotOwned);
  const isHomePageLoading = useAppSelector(getIsHomePageLoading);
  const headline = useAppSelector(selectRoomSetName);
  const classes = useStyles();

  const renderRooms = (rooms: IActiveRoom[]) => {
    return rooms.map((room, index) => <RoomCard key={index} roomData={room} />);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.containerViewAll}>
        <Headline2 headline={headline || 'PUBLIC ROOMS'} />
        <div className={classes.gridWrapper}>{!isHomePageLoading && renderRooms(rooms)}</div>
      </div>
    </div>
  );
};

export default RoomsPage;

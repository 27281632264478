export enum SET_TYPES {
  GAME_SET = 'GameSet',
  ROOM_SET = 'RoomSet',
  NEW_PARTY_SET = 'NewPartySet',
}

export enum BANNER_TYPE {
  WELCOME = 'WELCOME',
  DONATION = 'DONATION',
  GAME = 'GAME',
}

export enum BANNER_CONDITION {
  ROOM_OWNER = 'ROOM_OWNER',
  ROOM_GUEST = 'ROOM_GUEST',
  ROOM_OUTSIDE = 'ROOM_OUTSIDE',
}

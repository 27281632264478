import { makeStyles } from '@material-ui/core';
import HelloCoin from '@art/images/one-question/coin.svg';

export const styles = makeStyles((theme) => ({
  modalTitle: {
    fontFamily: 'Nunito Sans',
    textTransform: 'uppercase',
    color: '#1B2036',
    fontWeight: 900,
    fontSize: '32px',
    lineHeight: '32px',
    marginTop: '5px',
    marginBottom: '10px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '10px',
      marginBottom: '12px',
    },
  },

  reward: {
    marginBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '25px',
    },
  },

  rewardValue: {
    fontFamily: 'SF Pro Rounded',
    fontWeight: 700,
    fontSize: '54px',
    lineHeight: '64px',
    color: '#FFDE17',
    textShadow: '2px 2px 1px #AE5D02',
    marginRight: '12px',
  },

  helloCoin: {
    width: '65px',
    height: '65px',
    backgroundImage: `url(${HelloCoin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },

  claimButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '105px',
    height: '44px',
    border: '1px solid #000',
    backgroundColor: '#008AFF',
    boxShadow: 'inset 0px -5px 0px #0243A5, inset 0px 17px 0px #00ABFF',
    borderRadius: '70px',
    color: 'white',
    fontSize: '24px',
    lineHeight: '28px',
    textShadow: '0px 2px 0px #000',
    cursor: 'pointer',
    transition: 'all .23s',
    [theme.breakpoints.up('sm')]: {
      minWidth: '120px',
      height: '55px',
      fontSize: '28px',
      lineHeight: '28px',
    },
    '&.ru': {
      minWidth: '120px',
      fontSize: '20px',
      lineHeight: '24px',
      [theme.breakpoints.up('sm')]: {
        minWidth: '150px',
        height: '55px',
        fontSize: '28px',
        lineHeight: '28px',
      },
    },
    '&:hover': {
      backgroundColor: '#0243A5',
      boxShadow: 'none',
    },
  },
}));

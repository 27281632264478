import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  showWord: {
    width: '100%',
    minHeight: '100%',
    flexGrow: 1,
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
    justifyContent: 'center',
    color: '#1B2036',
    animationDuration: '230ms',
    animationDelay: '230ms',
    [theme.breakpoints.up('md')]: {
      padding: '30px',
    },
  },

  showWordCard: {
    minWidth: '207px',
    maxWidth: '90%',
    minHeight: '275px',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '10px solid #F8CA61',
    padding: '28px 5px 23px 5px',
    backgroundColor: 'white',
    borderRadius: '20px',
    color: '#1B2036',
    animationDuration: '230ms',
    animationDelay: '230ms',
    [theme.breakpoints.up('sm')]: {
      minWidth: '320px',
      minHeight: '425px',
      padding: '43px 5px 35px 5px',
      border: '15px solid #F8CA61',
      borderRadius: '30px',
    },
  },

  showWordCardTop: {
    flexDirection: 'column',
    marginBottom: '12px',
    '& > span': {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 600,
      [theme.breakpoints.up('sm')]: {
        fontSize: '30px',
        lineHeight: '36px',
      },
    },

    '& > b': {
      lineHeight: 1.2,
      fontWeight: 900,
      textTransform: 'uppercase',
      paddingTop: '5px',
      paddingBottom: '5px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '48px',
        lineHeight: '55px',
      },
    },
  },

  showWordCardLine: {
    width: '90%',
    height: '10px',
    backgroundColor: '#F8CA61',
    borderRadius: '20px',
    [theme.breakpoints.up('sm')]: {
      height: '15px',
    },
  },

  showWordCardBottom: {
    '& > p': {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '30px',
        lineHeight: '36px',
      },
    },
  },
}));

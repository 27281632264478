import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  walletAddresLink: {
    display: 'block',
    maxWidth: '70px',
    marginRight: '5px',
    // textOverflow: 'ellipsis',
    // overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#2C74F3',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 900,
    lineHeight: '14px',
    flexShrink: 1,
  },

  snackBar: {
    boxSizing: 'border-box',
    maxWidth: '356px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '360px',
      transform: 'translateX(-50%)',
    },
    '&.MuiSnackbar-anchorOriginTopCenter': {
      top: '25px',
      [theme.breakpoints.up('md')]: {
        top: '75px',
      },
    },
    '&.MuiSnackbar-root': {
      left: '50%',
    },

    '& > div': {
      fontFamily: fontFamily,
      boxShadow: 'none',
      padding: '0 60px',
      background: 'linear-gradient(92.08deg, #69FF97 2.25%, #00E4FF 100.96%)',
      justifyContent: 'center',
      textAlign: 'center',
      borderRadius: '8px',
      color: '#1B2036',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '21px',
      height: '100%',
    },
  },

  metaMaskPopup: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',

    '& > h2': {
      marginTop: 0,
    },
  },

  metamaskLogo: {
    marginBottom: '30px',
  },
}));

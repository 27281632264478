import { makeStyles, createStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';
import { Theme } from '@material-ui/core';
import starIcon from '@art/icons/partyStar.svg';
import starIconFull from '@art/icons/partyStarFull.svg';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid #E8E9EB',
      borderRadius: 8,
      display: 'flex',
      // height: '100%',
      backgroundColor: theme.palette.common.white,
      boxShadow: 'none',
      overflow: 'initial',
      flexShrink: 0,
      width: '100%',
      boxSizing: 'border-box',
      background: 'red',
      cursor: 'pointer',
      padding: '15px 20px 15px 15px',
      alignItems: 'center',
      position: 'relative',
      height: 100,
      minWidth: 290,
      [theme.breakpoints.up('sm')]: {
        height: 120,
        padding: '18px 25px 18px 20px',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 385,
      },
    },
    text: {
      flexDirection: 'column',
      display: 'flex',
      width: '100%',
      height: '100%',
    },

    firstLine: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      paddingRight: '2px',
    },

    headline: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 17,
      lineHeight: '17px',
      whiteSpace: 'break-spaces',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      boxSizing: 'border-box',
      lineClamp: 2,
      boxOrient: 'vertical',
      display: '-webkit-box',
      wordWrap: 'break-word',
      marginRight: '5px',
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
        lineHeight: '20px',
      },
    },
    gameType: {
      fontWeight: 500,
      display: 'block',
      fontSize: 12,
      lineHeight: '24px',
      color: '#1B2036',
      fontFamily: fontFamily,
      padding: '0px 10px',
      background: 'linear-gradient(168.91deg, #BFFFCD 0%, #93EFDD 101.99%)',
      borderRadius: '4px',
      width: 'fit-content',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 1,
      wordWrap: 'break-word',
      height: 24,
      marginRight: '10px',
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
        lineHeight: '21px',
      },
    },
    lock: {
      width: 24,
      height: 30,
    },

    start: {
      fontFamily: 'Barbieri-Regular',
      fontSize: '24px',
      color: '#1B2036',
      width: 'auto',
      alignItems: 'center',
      '& .play': {
        width: 22,
        height: 25,
        marginRight: '5px',
        marginBottom: '3px',
      },
      '& .invite': {
        width: 29,
        height: 29,
        marginRight: '5px',
        marginBottom: '3px',
      },
    },

    star: {
      width: '12px',
      height: '11px',
      marginRight: '1px',
      position: 'relative',
      backgroundImage: `url(${starIcon})`,
      backgroundSize: 'contain',
      [theme.breakpoints.up('sm')]: {
        width: '16px',
        height: '15px',
      },
    },

    active: {
      position: 'absolute',
      display: 'block',
      top: 0,
      left: 0,
      bottom: 0,
      overflow: 'hidden',
      width: 0,
      backgroundImage: `url(${starIconFull})`,
      backgroundSize: 'cover',
      [theme.breakpoints.up('sm')]: {
        backgroundSize: 'auto',
      },
    },

    buttonText: {
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '20px',
      lineHeight: '28px',
      textAlign: 'center',
      color: '#FFFFFF',
      textStroke: '0.5px #000000',
      textShadow: '0px 1px 0px #000000',
      marginLeft: '4px',
    },
  })
);

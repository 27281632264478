import { useParams } from 'react-router-dom';
import React, { UIEvent, useEffect, useRef, useState } from 'react';
import { partition } from 'lodash';
import { getRoomId, selectRoomPlayers } from '@store/room/roomSlice';
import { getAllGames, getGamePartiesSorted } from '@store/reducers/common';
import { PartyCard } from '@components/entities/PartyCard';
import { Host } from '@graphql/queries';
import { useStyles } from './styles';
import { analytics } from '@services/amplitude';
import { selectIsEnLocale, selectWrapperOffsets } from '@store/reducers/session.reducer';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import BackButton from '@src/components/BackButton/BackButton';
import { GameTypes, hasBalanceGame, isSingleGame } from '@src/common/constants/common';
import { useImageHook } from '@src/components/entities/Image';
import { ReactComponent as UpArrow } from '@art/icons/up.svg';
import { ReactComponent as HotIcon } from '@art/icons/18.svg';
import { selectPlayerId } from '@src/store/reducers/player.reducer';
import { startGameEvent } from '@src/common/sendEvents';
import helloCoin from '@art/icons/coin_flat.svg';
import { SuperstarModeSwitcher } from '@src/components/HomepageBar/SuperstarModeSwitcher';
import { useDeviceSize } from '@src/common/screenSizes';
import { roundNumbers } from '@src/common/misc';

const MARKER_APPEAR_DIFF = 150;

const getHeader = (
  isSingle: boolean,
  canPlaySingle: boolean,
  hasGames: boolean,
  isFirstPlayer: boolean,
  isEnLocale: boolean
) => {
  if (!hasGames) return isEnLocale ? 'We will add games soon' : 'Скоро добавим игры, надо немного подождать';

  if (isSingle) {
    return canPlaySingle
      ? isEnLocale
        ? 'Choose question category'
        : 'Выбери категорию вопроса'
      : isEnLocale
      ? 'This is not a party game'
      : 'Эта игра не для пати';
  }

  return isFirstPlayer
    ? isEnLocale
      ? 'Choose party to play with friends'
      : 'Выбери игру'
    : isEnLocale
    ? 'Room owner should start the party'
    : 'Владелец комнаты должен начать игру';
};

const getSubHeader = (isEnLocale: boolean) => {
  return isEnLocale ? 'You can play it only when you are alone in the room' : 'Вы можете играть только в одиночку';
};

const GameIntroPage: React.FC = () => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const { gameId } = useParams<{ gameId: string }>();
  const games = useAppSelector(getAllGames);
  const gameSelected = games.find((game) => game.id === gameId);
  const { isMobile } = useDeviceSize();
  const classes = useStyles();
  const parties = useAppSelector(getGamePartiesSorted(gameId));
  const imageSrc = useImageHook(gameSelected?.background);
  const players = useAppSelector(selectRoomPlayers);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const [isVisible, setVisible] = useState(false);
  const playerId = useAppSelector(selectPlayerId);
  const roomId = useAppSelector(getRoomId);
  const isRoomOwner = players[0].playerId === playerId;
  const unityLink = gameSelected?.unityData?.link || '';
  const unityButtonPlay = `${unityLink}/button.png`;
  const roundedTreasury = roundNumbers(gameSelected?.balance.dailyBalance);
  const shouldShowHotIcon =
    gameSelected && (gameSelected.type === GameTypes.ROAST || gameSelected.type === GameTypes.ROAST_BATTLE);

  useEffect(() => {
    analytics.logEvent(analytics.EVENTS.GAME_INTRO_SHOWN, { gameId: gameSelected?.id, gameType: gameSelected?.type });
  }, [gameSelected]);

  if (!gameSelected) {
    return <></>;
  }

  const showBalance = hasBalanceGame(gameSelected!.type) && gameSelected!.balance.dailyBalance > 0;
  const isSingle = isSingleGame(gameSelected.type);
  const canPlaySingle = isSingle && players.length === 1;
  const multiTitle = getHeader(isSingle, canPlaySingle, parties.length > 0, isRoomOwner, isEnLocale);
  const subHeader = getSubHeader(isEnLocale);

  const renderParties = (parties: Host[]) =>
    parties.map((party, index) => {
      return (
        <PartyCard
          key={index}
          party={party}
          isSingle={party.single}
          onlyOnePlayer={party.game.type === GameTypes.QUESTION_CHALLENGE}
        />
      );
    });

  const onUpClick = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const onScroll = (event: UIEvent<HTMLDivElement>) => {
    const scrolled = event.currentTarget.scrollTop;
    const screenHeight = event.currentTarget.clientHeight - MARKER_APPEAR_DIFF;
    setVisible(scrolled > screenHeight);
  };

  return (
    <div className={classes.root} onScroll={onScroll} style={{ backgroundImage: imageSrc }} ref={scrollContainer}>
      <div className={classes.containerViewAll}>
        <div className={classes.headerContainer}>
          <BackButton>{isEnLocale ? 'Homepage' : 'Главная'}</BackButton>
        </div>

        {gameSelected && <img src={gameSelected.logo} className={classes.logo} />}

        {gameSelected && gameSelected.type === GameTypes.LOVE_BOTTLE && <SuperstarModeSwitcher />}

        {gameSelected.type !== GameTypes.UNITY_GAME && (
          <div className={classes.bestParties}>
            <p className={classes.title}>{multiTitle}</p>
            {isSingle && !canPlaySingle && <p className={classes.subtitle}>{subHeader}</p>}
            {(canPlaySingle || !isSingle) && <div className={classes.gridWidgetWrapper}>{renderParties(parties)}</div>}
          </div>
        )}

        {gameSelected.type === GameTypes.UNITY_GAME && (
          <button
            className={classes.buttonPlay}
            style={{ backgroundImage: `url(${unityButtonPlay})` }}
            onClick={() => startGameEvent(roomId, playerId, undefined, gameId, gameSelected.type)}
          >
            <p className={classes.buttonText}>{isEnLocale ? 'Play' : 'Играть'}</p>
          </button>
        )}

        {isVisible && <UpArrow className={classes.arrowUp} onClick={onUpClick} />}
        <HotIcon className={classes.hotIcon} style={{ visibility: shouldShowHotIcon ? 'visible' : 'hidden' }} />

        {showBalance && (
          <div className={classes.treasuryContainer}>
            {!isMobile && <p>{isEnLocale ? 'Daily Treasury' : 'Сокровищница'}</p>}
            <div>
              <img src={helloCoin} alt="coin" />
              <p className={`${roundedTreasury === '∞' ? 'infinity' : ''}`}>{roundedTreasury} </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GameIntroPage;

import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import matchedHeart from '@art/icons/LoveBottle/matchHeart.svg';
import brokenHeart from '@art/icons/LoveBottle/brokenHeart.svg';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { IQuestion, LoveBottleState } from '@src/shared/gameInterfaces/loveBottle.socket';
import { matchStatusStyles } from '../../game.styles';

const DELAY_NEXT = 3000;

interface IProps {
  onNext: () => void;
  currUserId: string;
  game: LoveBottleState;
  isNextDisabled: boolean | undefined;
  activeQuestion: IQuestion;
}

const MatchStatus: React.FC<IProps> = ({ game, activeQuestion, currUserId, onNext, isNextDisabled }) => {
  const isMatch = activeQuestion && activeQuestion.options.some((option) => option.votes && option.votes.length === 2);
  const isRoundPlayer = currUserId === game.pairPlayerId || currUserId === game.activePlayerId;
  const classes = matchStatusStyles();
  const [isVisible, setVisible] = React.useState(false);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  useEffect(() => {
    setTimeout(() => setVisible(true), DELAY_NEXT);
  }, [isVisible]);

  const [isButtonClicked, setButtonClicked] = useState(false);

  const onNextClick = () => {
    setButtonClicked(true);
    onNext();
  };

  const matchStatusEn = isMatch ? 'It’s a match!' : 'Does not match!';
  const matchStatusRu = isMatch ? 'Вы совпали!' : 'Вы не совпали';

  return (
    <div className={classes.container}>
      {!isVisible && (
        <div className={classes.matchWhiteContainer}>
          <img src={isMatch ? matchedHeart : brokenHeart} alt={'heart'} className={classes.imgStyle} />
          <Typography className={classes.matchStatus}>{isEnLocale ? matchStatusEn : matchStatusRu}</Typography>
        </div>
      )}

      {isVisible && (
        <div className={classes.buttonContainer}>
          {isRoundPlayer ? (
            !isButtonClicked ? (
              <button
                className={classes.button}
                onClick={onNextClick}
                style={{
                  backgroundColor: 'rgba(44, 116, 243, 1)',
                  color: 'white',
                }}
                disabled={isNextDisabled}
              >
                {isEnLocale ? 'Next' : 'Далее'}
              </button>
            ) : (
              <p className={`${classes.textWaiting} mobile-outline`}>
                {isEnLocale
                  ? 'Your partner have to tap `Next` to proceed'
                  : 'Твой партнер должен нажать `Далее`, чтобы продолжить'}
              </p>
            )
          ) : (
            <p className={`${classes.textWaiting} mobile-outline`}>
              {isEnLocale ? 'Dating players have to tap `Next`' : 'Игроки на свидании должны нажать `Далее`'}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export { MatchStatus };

import React, { MouseEventHandler } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { MixProps } from '@src/common/generics';

const styles = makeStyles((theme) => ({
  threeDots: {
    width: '18px',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    flexShrink: 0,

    '& div': {
      width: '4px',
      height: '4px',
      borderRadius: '50%',
      backgroundColor: '#1B2036',
    },
  },
  white: {
    backgroundColor: 'white !important',
  },
}));

interface Props {
  ariaDescribedby?: string;
  onClick?: MouseEventHandler;
  style?: any;
}

const ThreeDots: React.FC<MixProps<Props>> = ({ style, ariaDescribedby, onClick }) => {
  const classes = styles();

  return (
    <div className={classes.threeDots} aria-describedby={ariaDescribedby} onClick={onClick} style={style}>
      <div />
      <div />
      <div />
    </div>
  );
};

const ThreeDotsWhite: React.FC<MixProps<Props>> = ({ style, ariaDescribedby, onClick }) => {
  const classes = styles();

  return (
    <div className={classes.threeDots} aria-describedby={ariaDescribedby} onClick={onClick} style={style}>
      <div className={classes.white} />
      <div className={classes.white} />
      <div className={classes.white} />
    </div>
  );
};

export { ThreeDots, ThreeDotsWhite };

import { GameTypes } from '@src/common/constants/common';
import WSClient from '@src/sockets';
import { useEffect } from 'react';
import { useGuessMeProvider } from './guessMeProvider';
// import { setVotedFor, setReadyNextRound } from '@store/reducers/guessMe';
import { useDispatch } from 'react-redux';
import { CLIENT_EVENTS } from '@shared/socketEvents/eventTypes';
import { PlayerStatus, PLAYER_STATUS } from '@src/common/constants/system';
import { updatePlayerState } from '@store/room/roomSlice';
import { updateGuessMeLabelsThunk } from './guessMe.labels';
import { sendRoundStarted } from '@services/analyticsThunk';
import { endGameEvent } from '@src/common/sendEvents';
import { useAppDispatch } from '@src/app/hooks';

const useGameHook = (roomId: string) => {
  const dispatch = useAppDispatch();
  const [state, game, playerId, players, storeActions, isActivePlayer, isSpectator] = useGuessMeProvider(roomId);

  useEffect(() => {
    dispatch(updateGuessMeLabelsThunk(game));
  }, [game]);

  useEffect(() => {
    if (state.READY_START_ROUND) {
      dispatch(sendRoundStarted(game.roundNumber.toString()));
    }
  }, [state.READY_START_ROUND]);

  return {
    state,
    game,
    playerId,
    players,
    isActivePlayer,
    isSpectator,
    actions: {
      onSpin: () => {
        WSClient.emit(CLIENT_EVENTS.ROUND_STARTED, {
          roomId,
          activeGameId: game.activeGameId,
          gameType: GameTypes.GUESS_ME,
        });
      },

      onPreVote: (value: number) => {
        storeActions.setPreVote(value);
        WSClient.emit(CLIENT_EVENTS.PLAYER_PRE_VOTED, {
          roomId,
          activeGameId: game.activeGameId,
          playerId,
          value,
        });
      },

      onVote: (value: number) => {
        storeActions.setVote(value);
        WSClient.emit(CLIENT_EVENTS.PLAYER_VOTED, {
          roomId,
          activeGameId: game.activeGameId,
          playerId,
          value,
        });
      },

      onNext: () => {
        storeActions.setReady();
        WSClient.emit(CLIENT_EVENTS.PLAYER_READY, {
          roomId,
          activeGameId: game.activeGameId,
          playerId,
        });
      },

      onGameQuit: () => {
        // dispatch(updatePlayerState(playerId, PLAYER_STATUS.READY_TO_QUIT_GAME as PlayerStatus));
        endGameEvent(roomId, playerId, GameTypes.GUESS_ME);
      },
    },
  };
};

export { useGameHook };

import { fontFamily } from '@src/art/theme/textStyles';
import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  buttonWrapper: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '250px',
    minHeight: '56px',
    [theme.breakpoints.up('sm')]: {
      minHeight: '60px',
    },
    [theme.breakpoints.up('md')]: {
      height: '50px',
    },

    // button
    '& > button': {
      // width: 'auto !important',
      padding: '0px 15px',
      whiteSpace: 'nowrap',
    },

    // after click text
    '& > span': {
      fontFamily,
      color: 'white',
      fontWeight: 800,
      fontSize: '15px',
      lineHeight: '18px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
      [theme.breakpoints.up('md')]: {
        color: '#5F6372',
        fontSize: '16px',
        lineHeight: '19px',
      },
    },

    // questionButton
    '& > .questionButton': {
      position: 'absolute',
      right: 0,
    },
  },
}));

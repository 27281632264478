import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

const FiveSecondsRules: React.FC = () => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();

  return (
    <>
      {isEnLocale ? (
        <ul className={classes.list}>
          <li>Active player answer a question within 5 seconds</li>
          <li>When time is up players vote whether the Active player answered correctly</li>
          <li>If the question answered correctly, Active player gets 1 point</li>
        </ul>
      ) : (
        <ul className={classes.list}>
          <li>Активный игрок отвечает на вопрос в течение 5 секунд</li>
          <li>Когда время заканчивается, игроки голосуют, ответил ли активный игрок верно</li>
          <li>Если активный игрок верно ответил на вопрос, он получает 1 балл</li>
        </ul>
      )}
    </>
  );
};

export { FiveSecondsRules };

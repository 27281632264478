import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import WSClient from '@src/sockets';
import { sendRoundStarted } from '@services/analyticsThunk';
import { updateChestsLabelsThunk } from './chests.labels';
import { CLIENT_EVENTS } from '@shared/socketEvents/eventTypes';
import { useGameProvider } from './chests.provider';

const useGameHook = (roomId: string) => {
  const dispatch = useDispatch();
  const [status, game, playerId, players, isActivePlayer, storeActions, isSpectator, balance] = useGameProvider(roomId);

  const activeGameId = game.activeGameId;
  const socketData = {
    roomId,
    activeGameId,
    playerId,
  };

  useEffect(() => {
    dispatch(updateChestsLabelsThunk(game));
  }, [status]);

  useEffect(() => {
    if (status.READY_START_ROUND) {
      dispatch(sendRoundStarted(game.roundNumber.toString()));
    }
  }, [status.READY_START_ROUND]);

  return {
    status,
    game,
    playerId,
    players,
    isActivePlayer,
    storeActions,
    isSpectator,
    balance,
    actions: {
      onStart: (value: boolean) => {
        WSClient.emit(CLIENT_EVENTS.PLAYER_STAKED, {
          activeGameId,
          roomId,
          playerId,
          value: value,
        });
      },

      onVote: (index: number) => {
        storeActions.setVote(index);
        WSClient.emit(CLIENT_EVENTS.PLAYER_VOTED, {
          ...socketData,
          value: index,
        });
      },

      onQuit: () => {
        WSClient.emit(CLIENT_EVENTS.PLAYER_READY_TO_QUIT_GAME, {
          roomId,
          activeGameId: game.activeGameId,
          playerId,
        });
      },
    },
  };
};

export { useGameHook };

import React from 'react';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';
import { useAppSelector } from '@app/hooks';

const EnergyEmpty: React.FC = () => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  return (
    <div className={classes.column}>
      <p className={'title'}>
        {isEnLocale ? 'You are out of Energy.' : 'Энергия закончилась,'}
        <br />
        {isEnLocale ? 'You still can play and have fun' : 'но игра продолжается!'}
      </p>
    </div>
  );
};

export { EnergyEmpty };

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isSafari } from 'react-device-detect';
import { ChestsState, Reward, REWARD_TYPE } from '@shared/gameInterfaces/chests.socket';
import { styles } from './styles';
import { getChestImages } from '../../helpers/chests.helpers';
import { BombReward, MoneyReward, NftReward, TreasureReward } from './Rewards';
import { useDeviceSize } from '@src/common/screenSizes';
import { requestRewards } from '@src/graphql/player.queries';

type Props = {
  game: ChestsState;
  playerId: string;
  isActivePlayer: boolean;
  onVote: (chestIndex: number) => void;
};

const ChooseChest: React.FC<Props> = ({ game, playerId, isActivePlayer, onVote }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { chests } = game;
  const { isMobile } = useDeviceSize();
  const [chestIndex, setChestIndex] = useState(-1);
  const isChestClicked = chestIndex !== -1;

  useEffect(() => {
    if (game.activePlayerId === playerId) {
      dispatch(requestRewards);
    }
  }, [chests]);

  const onChestClick = (index: number) => {
    setChestIndex(index);
    onVote(index);
  };

  const showReward = (reward: Reward) => {
    switch (reward.rewardType) {
      case REWARD_TYPE.BOMB:
        return <BombReward className={classes.rewardBomb} />;
      case REWARD_TYPE.MONEY:
        return <MoneyReward className={classes.rewardMoney} value={reward.value} />;
      case REWARD_TYPE.TREASURE:
        return <TreasureReward className={classes.rewardTreasure} />;
      case REWARD_TYPE.NFT:
        return <NftReward className={classes.rewardNft} />;
    }
  };

  const chestImages = getChestImages(game.stakeAmount);

  return (
    <div className={classes.chooseChestWrapper}>
      <div
        className={`${classes.chooseChest} animate__animated animate__zoomIn`}
        style={{ opacity: !isSafari ? (isActivePlayer ? 1 : 0.75) : 1 }}
      >
        {chests!.map((chest, index) => {
          const hasReward = Boolean(chest.reward);
          return (
            <div
              key={index}
              style={{
                pointerEvents: isChestClicked || !isActivePlayer || hasReward ? 'none' : 'auto',
                backgroundImage: `url(${chestIndex === index || hasReward ? chestImages.open : chestImages.close})`,
                backgroundSize: `${!hasReward ? `${isMobile ? '78px' : '160px'} auto` : '0 auto'}`,
              }}
              className={classes.chestItem}
              onClick={() => onChestClick(index)}
            >
              {chest.reward && showReward(chest.reward)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { ChooseChest };

import React, { useMemo } from 'react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import * as WALLETS from '@solana/wallet-adapter-wallets';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import '@solana/wallet-adapter-react-ui/styles.css';
// import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { WalletBalanceProvider } from './use-wallet-balance';
import { Cluster, clusterApiUrl } from '@solana/web3.js';
import { MintPage } from './mintPage';
import { useAppSelector } from '@src/app/hooks';
import { selectSolanaNetwork } from '@src/store/reducers/common';

const SolanaWrapper = <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  function SolanaComponent({ ...props }) {
    const solanaNetwork = useAppSelector(selectSolanaNetwork);
    const network = clusterApiUrl(solanaNetwork as Cluster);
    const untypedNetwork = network as any;

    console.log(solanaNetwork); // !!! - devnet - solana dev server

    const wallets = useMemo(
      () => [
        WALLETS.getPhantomWallet(),
        WALLETS.getSolflareWallet(),
        WALLETS.getSolletWallet({ network: untypedNetwork }),
        WALLETS.getLedgerWallet(),
        WALLETS.getSlopeWallet(),
        WALLETS.getSolletExtensionWallet({ network: untypedNetwork }),
      ],
      []
    );

    return (
      <ConnectionProvider endpoint={network}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          {/* <WalletModalProvider> */}
          <WalletBalanceProvider>
            <Component {...props} />
          </WalletBalanceProvider>
          {/* </WalletModalProvider> */}
        </WalletProvider>
      </ConnectionProvider>
    );
  };

export { SolanaWrapper };

/*
<GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined
      }}
    >
  </GoogleReCaptchaProvider>
    */

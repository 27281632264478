import { useAppDispatch, useAppSelector } from '@app/hooks';
import { MixProps } from '@src/common/generics';
import { LOCALE } from '@shared/common';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { setLocaleThunk } from '@store/thunk/session.thunk';
import { styles } from './styles';
import { useEffect, useState } from 'react';
import { selectShouldShowLanguagePopup, setShouldShowLanguagePopup } from '@src/store/reducers/popups';
import { TooltipLanguageSwitcher } from './tooltipLanguageSwitcher';

const LanguageSwitcher: React.FC<MixProps> = () => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const shouldShowLanguagePopup = useAppSelector(selectShouldShowLanguagePopup);
  const [isOpen, setOpen] = useState(shouldShowLanguagePopup);
  const barPosition = isEnLocale ? 'switch-left' : 'switch-right';

  const onSwitchLocale = () => {
    dispatch(setLocaleThunk(isEnLocale ? LOCALE.RU : LOCALE.EN));
    handleClose();
  };

  const handleClose = () => {
    dispatch(setShouldShowLanguagePopup(false));
    setOpen(false);
  };

  useEffect(() => {
    if (shouldShowLanguagePopup) {
      setOpen(true);
    }
  }, [shouldShowLanguagePopup]);

  return (
    <div className={classes.switcherContainer}>
      <div className={classes.switcherBar} onClick={onSwitchLocale}>
        <div className={`${classes.switcherThumb} ${barPosition}`} />
      </div>
      <div className={classes.switcherLang}>{isEnLocale ? 'EN' : 'RU'}</div>
      {isOpen && <TooltipLanguageSwitcher handleClose={handleClose} barPosition={barPosition} />}
    </div>
  );
};

export { LanguageSwitcher };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  question: {
    flexGrow: 1,
    minHeight: '100%',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },

  questionWrapper: {
    flexGrow: 1,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '644px',
    },
  },

  timerWrapper: {
    position: 'relative',
    height: '40px',
    width: '100%',
    marginTop: '-15px',
    marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '-30px',
      marginBottom: '10px',
      height: '50px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '15px',
      marginBottom: 0,
    },

    // fix timer on mobile
    '& > div': {
      position: 'fixed',
      zIndex: 10,
      width: 'calc(100% - 30px)', // minus 15 + 15 paddings
      [theme.breakpoints.up('sm')]: {
        width: 'calc(100% - 60px)', // minus 30 + 30 paddings
      },
      [theme.breakpoints.up('md')]: {
        position: 'static',
        width: '100%',
      },
    },
  },

  questionInner: {
    flexGrow: 1,
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '550px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '50px',
    },

    '& > p': {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '28px',
      color: '#1B2036',
      wordBreak: 'break-word',
      margin: 0,
      marginBottom: '10px',
      backgroundColor: 'white',
      borderRadius: '20px',
      padding: '8px 15px',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        padding: '10px 15px',
        fontSize: '28px',
        lineHeight: '33px',
      },
      [theme.breakpoints.up('md')]: {
        padding: 0,
        borderRadius: 0,
        backgroundColor: 'transparent',
      },
    },
  },

  buttonsContainer: {
    width: '100%',
    color: 'inherit',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '15px',
    backgroundColor: 'white',
    borderRadius: '20px',
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {
      padding: 0,
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
    '&.clicked > div': {
      pointerEvents: 'none',
      cursor: 'default',
    },
    '&.isSpectator > div': {
      pointerEvents: 'none',
      cursor: 'default',
      backgroundColor: '#D7E4FD !important',
    },
  },

  answerButton: {
    position: 'relative',
    padding: '0 15px',
    flexBasis: 'calc(50% - 7.5px)',
    flexShrink: 1,
    minHeight: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    transitionProperty: 'background-color',
    transitionDuration: '.23s',
    cursor: 'pointer',
    boxSizing: 'border-box',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 0',
    [theme.breakpoints.up('sm')]: {
      minHeight: '80px',
    },
    '&:nth-child(-n+2)': {
      marginBottom: '15px',
    },
    '&:hover': {
      backgroundColor: '#356FD6',
    },
    '&:active': {
      backgroundColor: '#3662AE',
    },
    '&.is-voted': {
      backgroundColor: '#EAF1FE',
      color: '#BBBCC3',
    },

    '& > img': {
      position: 'absolute',
      top: '-12px',
      left: '-12px',
    },

    '& > span': {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
      textAlign: 'center',
      display: 'inline-flex',
      maxHeight: '40px',
      overflow: 'hidden',
      wordBreak: 'break-word',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
  },
}));

import { ILeaderboard, ITimer } from '../common';
import { IdType } from '../generics';
import { BaseGameState } from '../socketEvents/socketEvents';

export enum GUESS_UP_STATE {
  READY_START_ROUND = 'READY_START_ROUND',
  SPIN = 'SPIN',
  SHOW_WORD = 'SHOW_WORD',
  GAME = 'GAME',
  VOTE = 'VOTE',
  VOTE_RESULTS = 'VOTE_RESULTS',
  RESULTS = 'RESULTS',
  TOP_PLAYERS = 'TOP_PLAYERS',
  LEADERBOARD = 'LEADERBOARD',
}

export interface GuessUpVote {
  playerId: IdType;
  value: boolean;
}

export interface GuessUpState extends BaseGameState {
  gameState: GUESS_UP_STATE;
  roundNumber: number;
  totalRounds: number;
  activePlayerId: IdType;
  word?: string;
  hasWon?: boolean;
  votedPlayers?: IdType[];
  votes?: GuessUpVote[];
  readyPlayers?: IdType[];
  leaderboard?: ILeaderboard;
  timer?: ITimer;
}

import { useAppSelector } from '@src/app/hooks';
import { selectBalance, selectPlayerId } from '@src/store/reducers/player.reducer';
import React from 'react';
import { styles } from './styles';
import attention from '@art/icons/attention.svg';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import Button from '@src/components/Button';
import { useBlockchainData } from '@src/features/blockchain';

interface Iprops {
  setIsOpenWithdrawModal: (isOpen: boolean) => void;
  maxClaim: number;
  minClaim: number;
}

const Withdraw: React.FC<Iprops> = ({ setIsOpenWithdrawModal, maxClaim, minClaim }) => {
  const classes = styles();
  const currentPlayerId = useAppSelector(selectPlayerId);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const balance = useAppSelector(selectBalance);
  const { walletConnected } = useBlockchainData();

  return (
    <div className={classes.withdrawalContainer}>
      {!walletConnected ? (
        <div>
          <img src={attention} alt="" />
          <p>{isEnLocale ? 'Connect your wallet to withdraw HIT' : 'Подключи кошелек, чтобы вывести HIT'}</p>
        </div>
      ) : (
        <div style={{ flexDirection: 'column', width: '100%' }}>
          <Button
            type="primary-blue-new"
            text={isEnLocale ? 'Withdraw' : 'Вывести'}
            onClick={() => setIsOpenWithdrawModal(true)}
            disabled={balance < minClaim || balance === 0}
          />
          <div style={{ justifyContent: 'center' }}>
            {balance >= minClaim ? (
              <p>
                {isEnLocale
                  ? `Maximum withdraw amount: ${maxClaim}HIT`
                  : `Максимальное количество для вывода - ${maxClaim}HIT`}
              </p>
            ) : (
              <p>
                {isEnLocale
                  ? `Minimum withdraw amount: ${minClaim}HIT`
                  : `Минимальное количество для вывода - ${minClaim}HIT`}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Withdraw;

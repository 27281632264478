import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  pointsHelperBlock: {
    flexDirection: 'column',
    '& > div': {
      width: '100%',
      fontSize: '15px',
      lineHeight: '18px',
      marginBottom: '10px',
      justifyContent: 'flex-end',

      '& > b': {
        fontWeight: 700,
        color: '#5F6372',
        maxWidth: '220px',
      },

      '& > span': {
        color: '#1B2036',
        flexShrink: 0,
        alignSelf: 'flex-end',
      },
    },
  },

  dots: {
    color: 'inherit',
    flexGrow: 1,
    marginLeft: '5px',
    marginRight: '5px',
    borderBottom: '1px dashed #BBBCC3',
  },

  list: {
    flexDirection: 'column',
    paddingLeft: 0,
    marginTop: 0,
    overflowY: 'auto',
    maxHeight: '58vh',
    '& li': {
      position: 'relative',
      fontSize: '15px',
      lineHeight: '21px',
      marginBottom: '12px',
      listStyle: 'none',
      paddingLeft: '15px',
      '&:last-child': {
        marginBottom: 0,
      },
      [theme.breakpoints.up('md')]: {
        paddingRight: '15px',
        fontSize: '14px',
        lineHeight: '21px',
      },
      '&::before': {
        content: '""',
        display: 'block',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: '#E8E9EB',
        position: 'absolute',
        top: '8px',
        left: 0,
      },
    },
    '& b': {
      fontWeight: 700,
    },
  },
}));

export const helpStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  helpContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '26px 15px 15px',
    background: 'white',
    borderRadius: '20px',
  },
  title: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '32px',
    lineHeight: '32px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#1B2036',
    margin: 0,
  },
  subtitle: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#1B2036',
    margin: '7px 0px 0px',
  },
}));

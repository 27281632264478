import { makeStyles } from '@material-ui/core';
import { ButtonBlueBackground, ButtonGrayBackground } from '../../../../helpers/chests.images';

export const styles = makeStyles((theme) => ({
  imageWrapper: {
    position: 'relative',
    zIndex: 1,
    color: 'inherit',
    width: '272px',
    height: '222px',
    marginBottom: '14px',
    // outline: '1px solid red',
    // overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: '412px',
      height: '336px',
    },
  },

  potPart1: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: '50%',
    marginLeft: '-110px',
    width: '216px',
    height: '40px',
    [theme.breakpoints.up('sm')]: {
      width: '329px',
      height: '61px',
      marginLeft: '-168px',
    },
  },

  potPart2: {
    position: 'absolute',
    zIndex: 4,
    width: '272px',
    height: '222px',
    [theme.breakpoints.up('sm')]: {
      width: '412px',
      height: '336px',
    },
  },

  coin: {
    position: 'absolute',
    top: '-350px',
    left: '50%',
    zIndex: 3,
    width: '116px',
    height: '116px',
    marginLeft: '-58px',
    animationName: 'coinsFalling',
    animationDuration: '230ms',
    animationIterationCount: '1',
    animationFillMode: 'both',
  },

  gold: {
    position: 'absolute',
    top: '-18px',
    left: '50%',
    zIndex: 5,
    width: '200px',
    height: '114px',
    marginLeft: '-110px',
    animationDuration: '230ms',
    [theme.breakpoints.up('sm')]: {
      width: '301px',
      height: '174px',
      top: '-27px',
      marginLeft: '-165px',
    },
  },

  notEnoughtButton: {
    width: '250px',
    marginLeft: '-125px',
    position: 'absolute',
    zIndex: 6,
    left: '50%',
    bottom: '60px',
    minHeight: '64px',
    justifyContent: 'center',
    alignItems: 'center',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '26px',
    lineHeight: '32px',
    color: '#fff',
    textShadow: '1px 4px 2px #000',
    backgroundImage: `url(${ButtonGrayBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    borderRadius: '50px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      width: '470px',
      marginLeft: '-235px',
      minHeight: '97px',
      bottom: '100px',
      fontSize: '50px',
      lineHeight: '60px',
    },
  },

  addTokensButton: {
    width: '245px',
    marginLeft: '-122.5px',
    position: 'absolute',
    zIndex: 6,
    left: '50%',
    bottom: '60px',
    minHeight: '55px',
    textAlign: 'center',
    cursor: 'pointer',
    animationDuration: '100ms',
    justifyContent: 'center',
    alignItems: 'center',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '26px',
    lineHeight: '32px',
    color: '#fff',
    textShadow: '1px 4px 2px #000',
    backgroundImage: `url(${ButtonBlueBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    borderRadius: '50px',
    [theme.breakpoints.up('sm')]: {
      width: '470px',
      marginLeft: '-235px',
      minHeight: '97px',
      bottom: '100px',
      fontSize: '50px',
      lineHeight: '60px',
    },
  },

  skipButton: {
    position: 'absolute',
    zIndex: 6,
    left: '50%',
    bottom: '-40px',
    marginLeft: '-110px',
    width: '220px',
    height: '28px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '26px',
    lineHeight: '32px',
    color: '#fff',
    textShadow: '1px 4px 2px #000',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      height: '68px',
      bottom: '-80px',
      fontSize: '50px',
      lineHeight: '60px',
    },
  },

  allPlayers: {
    width: '250px',
    marginLeft: '-125px',
    position: 'absolute',
    zIndex: 6,
    bottom: '45px',
    left: '50%',
    height: '96px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '24px',
    lineHeight: '30px',
    color: '#fff',
    textShadow: '1px 4px 2px #000',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      marginLeft: '-225px',
      height: '160px',
      bottom: '55px',
      fontSize: '42px',
      lineHeight: '52px',
    },
  },
}));

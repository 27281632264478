import { Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import { styles } from './styles';
import { ReactComponent as Loading } from '@src/art/icons/loading.svg';
import { useAppSelector } from '@src/app/hooks';
import { selectWrapperOffsets } from '@store/reducers/session.reducer';

const TransparentConnectToServerLoader: React.FC = () => {
  const classes = styles();
  const wrapperOffsets = useAppSelector(selectWrapperOffsets);
  const height = window.innerHeight - wrapperOffsets.top - wrapperOffsets.bottom;
  const width = window.innerWidth - wrapperOffsets.right;

  return (
    <Backdrop
      className={classes.backdrop}
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', height: height, width: width, position: 'absolute' }}
      open={true}
    >
      <Loading />
      {/* <Typography>Please wait, we’re loading...</Typography> */}
    </Backdrop>
  );
};

export default TransparentConnectToServerLoader;

import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { analytics } from '@services/amplitude';
import { Greeting, Question } from '../LandingPage1/parts';
import Results from './Results/Results';
import { styles } from './styles';

export type IAnswers = {
  text: string;
  percents: number;
  right: boolean;
};

const LandingPage2: React.FC = () => {
  const classes = styles();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [screen, setScreen] = useState('question');
  const [isGreetingClicked, setGreetingClick] = useState(false);
  const [isQuestionClicked, setQuestionClick] = useState(false);
  const [isResultsClicked, setResultsClick] = useState(false);
  const [isRight, setRight] = useState(false);
  const version = 2;

  const TIME_OUT: number = 1000;

  const isResultsSreens = screen === 'results' || screen === 'final';

  const answers: IAnswers[] = [
    { text: 'Libya', percents: 27, right: false },
    { text: 'Nepal', percents: 39, right: true },
    { text: 'Argentina', percents: 12, right: false },
    { text: 'Cambodia', percents: 22, right: false },
  ];

  // greetingClick
  const greetingClick = () => {
    setGreetingClick(true);
    analytics.logEvent(analytics.EVENTS.INTRO_GO, { version });
    setTimeout(() => {
      setScreen('question');
    }, TIME_OUT);
  };

  // questionClick
  const questionClick = (value: number) => {
    setQuestionClick(true);
    setValue(value);
    analytics.logEvent(analytics.EVENTS.INTRO_ANSWER_CLICKED, { answer: value, version });

    // check the answer
    if (answers[value - 1].right) {
      setRight(true);
    }

    setTimeout(() => {
      setScreen('results');
      setTimeout(() => {
        setScreen('final');
      }, TIME_OUT * 2);
    }, TIME_OUT);
  };

  // resultsClick
  const resultsClick = () => {
    setResultsClick(true);
    analytics.logEvent(analytics.EVENTS.INTRO_RESULT_CLICKED, { button: value, version });
    setTimeout(() => {
      history.push('/');
    }, TIME_OUT);
  };

  useEffect(() => {
    analytics.logEvent(analytics.EVENTS.INTRO_SHOWN, { version });
  }, []);

  return (
    <div className={classes.landingPage2}>
      {/* overlay */}
      {isResultsSreens && <div className={`${classes.overlay} animate__animated animate__fadeIn`} />}

      <div className={classes.landingPage2Wrapper}>
        {/* greeting */}
        {screen === 'greeting' && <Greeting greetingClick={greetingClick} isGreetingClicked={isGreetingClicked} />}

        {/* question */}
        {screen === 'question' && (
          <Question
            value={value}
            questionClick={questionClick}
            isQuestionClicked={isQuestionClicked}
            answers={['Libya', 'Nepal', 'Argentina', 'Cambodia']}
            questionText="It is the only country to spot a non-rectangular flag?"
          />
        )}

        {/* results and final */}
        {isResultsSreens && (
          <Results
            screen={screen}
            value={value}
            answers={answers}
            isRight={isRight}
            questionText="It is the only country to spot a non-rectangular flag?"
            resultsClick={resultsClick}
          />
        )}
      </div>
    </div>
  );
};

export default LandingPage2;

import { Observable } from '@apollo/client';
import { DrawAndGuessState, DRAW_AND_GUESS_STATE } from '@shared/gameInterfaces/drawAndGuess.socket';
import { DrawEvent, DrawLines } from '@src/shared/drawData';

export const initialMapState = {
  READY_START_ROUND: false,
  SPIN: false,
  CHOOSE_WORD: false,
  SHOW_WORD: false,
  DRAW: false,
  RESULTS: false,
  TOP_PLAYERS: false,
  LEADERBOARD: false,
};

export enum DrawType {
  PEN = 'Pen',
  ERASER = 'Erase',
}

export enum DrawColor {
  RED = '#f00',
  GREEN = '#0f0',
  BLUE = '#00f',
  YELLOW = '#FFFF00',
  BLACK = '#000',
}

export enum DrawSize {
  SMALL,
  MEDIUM,
  LARGE,
}

export const defaultGameState: DrawAndGuessState = {
  // @ts-ignore
  gameState: DRAW_AND_GUESS_STATE,
  activeGameId: '',
  activePlayerId: '',
};

export type EngineProps = {
  handleOnChange?: (event: DrawEvent) => void;
  initialDrawing?: DrawLines;
  eventObserver: Observable<DrawEvent> | undefined;
  isActivePlayer: boolean;
  timeIsUp: boolean;
};

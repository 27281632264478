import { useState, useRef, useEffect } from 'react';
import { useAppSelector } from '@app/hooks';
import { selectBackgrounds } from '@store/room/roomFeaturesData.slice';
import { SCROLL_EPSILON } from '@common/constants/system';
import { getIsJoinRoomLoading } from '@src/store/reducers/loadActions';
import ConnectToServerLoader from '../ConnectToServerLoader';
import ScrollButton from './ScrollButton/ScrollButton';
import { useImageHook } from '@src/components/entities/Image';
import { styles } from './styles';
import { useDeviceSize } from '@src/common/screenSizes';
import { selectBackground } from '@src/store/room/roomSlice';

interface Props {
  state?: any;
  style?: any;
  transparent?: boolean;
  drawAndGuess?: boolean;
  withScrollButton?: boolean;
  hasDrawCanvas?: boolean;
}

const GameWrapper: React.FC<Props> = ({
  state,
  children,
  style,
  transparent = false,
  withScrollButton = false,
  hasDrawCanvas = false,
}) => {
  const classes = styles();
  const { isMobile } = useDeviceSize();
  const backgroundsList = useAppSelector(selectBackgrounds);
  const selectedBackground = useAppSelector(selectBackground) || backgroundsList[0];
  const imageSrc = useImageHook(selectedBackground?.image);
  const isLoading = useAppSelector(getIsJoinRoomLoading);

  const isGrayWindow =
    state?.CHOOSE_WORD ||
    state?.SHOW_WORD ||
    state?.DRAW ||
    state?.TOP_PLAYERS ||
    state?.LEADERBOARD ||
    state?.ROUND_RESULTS ||
    state?.LOOP_RESULTS ||
    state?.TOP_COUPLES ||
    state?.VOTES;

  const addBackgroundStyles = () => {
    // gradient & image +++
    if (selectedBackground?.gradient && selectedBackground?.image?.srcSet?.mobile) {
      return {
        backgroundImage: `${imageSrc}, ${selectedBackground?.gradient}`,
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundColor: 'transparent',
        backgroundPosition: 'center bottom',
        backgroundSize: '100% auto',
      };

      // gradient & pattern +++
    } else if (selectedBackground?.gradient && selectedBackground?.image?.src) {
      return {
        backgroundImage: `${imageSrc}, ${selectedBackground?.gradient}`,
        backgroundRepeat: 'repeat',
        backgroundColor: 'transparent',
      };

      // image +++
    } else if (!selectedBackground?.gradient && selectedBackground?.image?.srcSet?.mobile) {
      return {
        backgroundImage: imageSrc,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'transparent',
        backgroundPosition: 'center bottom',
      };

      // gradient +++
    } else if (
      selectedBackground?.gradient &&
      !selectedBackground?.image?.srcSet?.mobile &&
      !selectedBackground?.image?.src
    ) {
      return {
        backgroundImage: selectedBackground?.gradient,
        backgroundColor: 'transparent',
      };

      // color +++
    } else if (selectedBackground?.backgroundColor) {
      return { backgroundColor: selectedBackground?.backgroundColor };

      // default background
    } else
      return {
        backgroundImage: 'linear-gradient(180deg, #EBF6FF 0%, #ACD8FD 98.44%)',
        backgroundColor: 'transparent',
      };
  };

  const scrollContainer = useRef<HTMLDivElement>(null);
  const [showScrollButton, setScrollButton] = useState(false);
  const [isScrollButtonClicked, setScrollButtonClicked] = useState(false);
  const PADDING_SIZE = 25;

  // check scrollContainer has scroll when page is loaded
  useEffect(() => {
    if (scrollContainer.current) {
      const scrollContainerHeight = scrollContainer.current.clientHeight;
      const contentHeight = scrollContainer.current.scrollHeight;
      const scrollDifference = contentHeight - scrollContainerHeight;

      // if current scroll not on the bottom
      if (
        Math.abs(scrollContainer.current.scrollTop - scrollDifference) > SCROLL_EPSILON
        // Math.ceil(scrollContainer.current.scrollTop - scrollDifference) &&
        // Math.floor(scrollContainer.current.scrollTop - scrollDifference)
      ) {
        setScrollButtonClicked(false);

        // show scroll button if content height is more than container height
        setScrollButton(contentHeight > scrollContainerHeight + PADDING_SIZE + 10);
      }
    }
    // update when childen are rerendering
  }, [children]);

  // when content is scrolled to bottom -> hide scroll button
  const onScrollContainer = () => {
    if (scrollContainer.current) {
      const scrollTopValue = scrollContainer.current.scrollTop;
      const scrollDifference = scrollContainer.current.scrollHeight - scrollContainer.current.clientHeight;

      setScrollButton(scrollTopValue < scrollDifference - PADDING_SIZE);
    }
  };

  // scroll content to bottom when clicked -> and hide scroll button after
  const onScrollButtonClick = () => {
    setScrollButtonClicked(true);
    if (scrollContainer.current) {
      // scroll action
      scrollContainer.current.scrollTo({ top: scrollContainer.current.scrollHeight, behavior: 'smooth' });
    }
  };

  const showScrollButtonCondition = isMobile && !isScrollButtonClicked && showScrollButton;

  if (isLoading) {
    return <ConnectToServerLoader />;
  }

  return (
    <section
      style={addBackgroundStyles()}
      className={`
        ${classes.game}
        ${transparent ? 'transparent' : ''}
        ${isGrayWindow ? 'isGrayWindow' : ''}
        ${hasDrawCanvas ? 'hasDrawCanvas' : ''}
      `}
    >
      <div
        style={style}
        ref={scrollContainer}
        onScroll={onScrollContainer}
        className={`${classes.gameWrapper} gameWrapper`}
      >
        <div className={`${classes.desktopCentered} desktopCentered`}>{children}</div>
      </div>

      {/* scrollToBottom button */}
      {withScrollButton && showScrollButtonCondition && <ScrollButton onClick={onScrollButtonClick} />}
    </section>
  );
};

export default GameWrapper;

import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import CommonModal from '@components/Modals/CommonModal';
import { styles } from './styles';
import { useDispatch } from 'react-redux';
import { selectRoomIcons } from '@src/store/room/roomFeaturesData.slice';
import check from '@art/icons/check.svg';
import Button from '@src/components/Button';
import CloseCross from '@src/components/CloseCross/CloseCross';
import { requestRoomIconChange } from '@src/graphql/debugQueries';
import { selectRoomIcon } from '@src/store/reducers/player.reducer';

interface Props {
  isOpen: boolean | undefined;
  handleClose: () => void;
}

const RoomAvatarsModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const avatars = useAppSelector(selectRoomIcons);
  const currentAvatar = useAppSelector(selectRoomIcon)!;
  const currentAvatarIndex = avatars.indexOf(currentAvatar);
  const [iconIndex, setIconIndex] = useState(currentAvatarIndex);
  const [oldAvatarIndex, setOldAvatarIndex] = useState(currentAvatarIndex);

  useEffect(() => {
    setOldAvatarIndex(iconIndex);
  }, [currentAvatar]);

  const onButtonClick = () => {
    dispatch(requestRoomIconChange(avatars[iconIndex]));
    handleClose();
  };

  const renderAvatars = () =>
    avatars.map((avatar, index) => {
      return (
        <div style={{ position: 'relative' }} key={index} onClick={() => setIconIndex(index)}>
          <img src={avatar} className={classes.avatar} />
          {iconIndex === index && <img className={classes.check} src={check} alt="✓" />}
          {iconIndex === index && <div className={classes.background} />}
        </div>
      );
    });

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} padding={false} className={classes.optionsWrapper}>
      <CloseCross onClick={handleClose} style={{ top: '-40px', right: '5px' }} />
      <p className={classes.title}>{isEnLocale ? 'Room avatar' : 'Aватар комнаты'}</p>
      <div className={classes.border} style={{ margin: '0px', background: '#5569AE' }}></div>
      <div className={classes.block}>
        <div className={classes.itemsContainer}>{renderAvatars()}</div>
      </div>
      {iconIndex !== oldAvatarIndex && (
        <Button
          type="primary-blue-new"
          text={isEnLocale ? 'Select' : 'Выбрать'}
          onClick={onButtonClick}
          className={classes.optionsButton}
        />
      )}
      {/* </div> */}
    </CommonModal>
  );
};

export { RoomAvatarsModal };

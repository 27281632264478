import { MobileHeader } from '..';
import { styles } from './styles';

interface Props {
  game: any;
  isEnLocale: boolean;
  isActivePlayer: boolean;
  isSpectator: boolean;
  isFakeArtist: boolean;
  activePlayerName: string;
  isVotingStatuses: boolean;
  onNext: () => void;
  onVotingStart: () => void;
}

const DesktopMenuContent: React.FC<Props> = ({
  game,
  isEnLocale,
  isActivePlayer,
  isSpectator,
  isFakeArtist,
  activePlayerName,
  isVotingStatuses,
  onNext,
  onVotingStart,
}) => {
  const classes = styles();
  const { chosenWord } = game;

  const returnFontSize = (chosenWord: string) => {
    if (chosenWord.length < 8) {
      return { fontSize: '48px' };
    } else {
      return { fontSize: '36px' };
    }
  };

  return (
    <>
      {/* top-container */}
      <div className={classes.desktopMenuHeader}>
        {isFakeArtist ? (
          <div className={classes.showFakeArtistInfo}>
            <p>{isEnLocale ? 'GUESS the word being drawn' : 'УГАДАЙ рисуемое слово'}</p>
          </div>
        ) : (
          <div className={classes.choosenWord}>
            <b style={returnFontSize(chosenWord)}>{game.chosenWord}</b>
          </div>
        )}

        <div className={classes.buttonsContainer}>
          <MobileHeader
            game={game}
            onNext={onNext}
            isSpectator={isSpectator}
            isFakeArtist={isFakeArtist}
            onVotingStart={onVotingStart}
            isActivePlayer={isActivePlayer}
            activePlayerName={activePlayerName}
            isSkipVoting={Boolean(game.voteState)}
            isVotingStatuses={isVotingStatuses}
          />
        </div>
      </div>

      {/* middle-container */}
      <div className={classes.desktopMenuSpacer} />
    </>
  );
};

export { DesktopMenuContent };

import { brushSizes } from '@src/games/common/Drawing/palleteColors';
import eraserIcon from '@art/icons/eraser.svg';
import penIcon from '@art/icons/pen.svg';
import { styles } from './styles';

type Props = {
  menuItem: string;
  penWidth: number;
  setMenuItem: (item: string) => void;
  onChangePen: (penWidth: number) => void;
};

const DrawControls: React.FC<Props> = ({ menuItem, penWidth, setMenuItem, onChangePen }) => {
  const classes = styles();

  return (
    <div className={classes.modeMenuAndBrushSizes}>
      <ul className={classes.modeMenuList}>
        <li className={`${menuItem === 'pen' && 'active'}`} onClick={() => setMenuItem('pen')}>
          <img src={penIcon} alt="pen" />
        </li>

        <li className={`${menuItem === 'erase' && 'active'}`} onClick={() => setMenuItem('erase')}>
          <img src={eraserIcon} alt="erase" />
        </li>
      </ul>

      {/* brushSizes */}
      <section className={classes.brushSizes}>
        <ul className={classes.brushSizesList}>
          {brushSizes.map((size: number, index: number) => {
            return (
              <li
                key={index}
                style={{ height: `${size}px`, width: `${size}px` }}
                className={`${size === penWidth * 2 ? 'active' : ''}`}
                onClick={() => onChangePen(size / 2)}
              />
            );
          })}
        </ul>
      </section>
    </div>
  );
};

export { DrawControls };

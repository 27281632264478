import { fontFamily } from '@src/art/theme/textStyles';
import { makeStyles } from '@material-ui/core';
import InvitePlusButton from '@art/images/buttons/invite/invite-plus.svg';

export const styles = makeStyles((theme) => ({
  inviteContainer: {
    fontFamily,
    paddingTop: '2px',
    paddingLeft: '1px',
    paddingRight: '1px',
    flexBasis: '20%',
    flexShrink: 0, // check it
    flexGrow: 0, // check it
    height: '26vw',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      height: '27vw',
      paddingLeft: '2px',
      paddingRight: '2px',
    },

    [theme.breakpoints.up('md')]: {
      height: 'auto',
      overflow: 'hidden',
      paddingLeft: '5px',
      paddingRight: '5px',
    },
  },

  inviteWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F3F4F5',
    borderRadius: '4px',
  },

  inviteButton: {
    transition: 'all .23s',
    borderRadius: '50%',
    position: 'relative',
    zIndex: 1,
    width: '45px',
    height: '45px',
    marginBottom: '15%',
    cursor: 'pointer',
    backgroundImage: `url(${InvitePlusButton})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',

    [theme.breakpoints.up('sm')]: {
      width: '13vw',
      height: '13vw',
      marginBottom: '30px',
      maxHeight: '100px',
      maxWidth: '100px',
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: '25px',
      width: '11vh',
      height: '11vh',

      // custom media query
      ['@media (max-height:630px)']: {
        border: '2px solid #BBBCC3',
        '& div': {
          transform: 'scale(0.5)',
        },
        '& div:nth-child(2)': {
          transform: 'scale(0.5) rotate(90deg)',
        },
      },

      ['@media (max-height:500px)']: {
        marginBottom: 0,
      },

      '&:hover': {
        borderColor: '#2C74F3',

        '& div': {
          background: '#2C74F3',
        },
      },
    },

    '& > span': {
      display: 'block',
      position: 'absolute',
      color: '#008AFF',
      fontWeight: 600,
      left: '50%',
      fontSize: '12px',
      lineHeight: '14px',
      width: '34px',
      height: '14px',
      marginLeft: '-17px',
      textAlign: 'center',
      marginTop: '-7px',
      bottom: '-20px',
      cursor: 'pointer',
      [theme.breakpoints.up('sm')]: {
        marginLeft: '-28px',
        marginTop: '-12px',
        width: '56px',
        height: '24px',
        bottom: '-30px',
        fontSize: '20px',
        lineHeight: '24px',
      },
      [theme.breakpoints.up('md')]: {
        ['@media (max-height:500px)']: {
          display: 'none',
        },
      },
    },
  },

  helloCoin: {
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    display: 'block',
    width: '20px',
    height: '20px',
    zIndex: 2,
    [theme.breakpoints.up('sm')]: {
      width: '44px',
      height: '44px',
      top: '-12px',
      right: '-12px',
    },
    [theme.breakpoints.up('md')]: {
      width: '5vh',
      height: '5vh',
      maxHeight: '44px',
      maxWidth: '44px',
      ['@media (max-height:500px)']: {
        display: 'none',
      },
    },
  },
}));

import { DrinkRouletteState } from '@shared/gameInterfaces/roulette.socket';

import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { showInviteThunk } from '@common/misc';
import { useMediaQuery } from '@material-ui/core';

import { setInviteLink } from '@src/store/reducers/popups';
import { selectDeeplink } from '@src/store/room/roomSlice';
import { selectIsEnLocale } from '@store/reducers/session.reducer';

import { styles } from './styles';
import { analytics } from '@src/services/amplitude';

interface IProps {
  gameState: DrinkRouletteState;
  onQuit: () => void;
  onCheers: () => void;
  isActivePlayer: boolean;
  isSpectator: boolean;
}

const SingleResults: React.FC<IProps> = ({ gameState, onQuit, onCheers, isActivePlayer, isSpectator }) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const { ticket, roundNumber, totalRounds } = gameState;
  const deeplink = useAppSelector(selectDeeplink);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const Kheight = isMobile ? 1 : 1.6;
  const maxVotes = ticket?.commonAnswers.reduce((prev: number, next: number) => Math.max(prev, next));
  const isFinalRound = roundNumber === totalRounds;
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const onInviteClick = () => {
    dispatch(setInviteLink(deeplink));
    dispatch(showInviteThunk('SINGLE'));
    // analytics.logEvent(analytics.EVENTS.INVITE_CLICKED, { from });
  };

  return (
    <div className={`${classes.singleResults} animate__animated  animate__fadeIn`}>
      {/* questionText */}
      <div className={classes.questionText}>
        <p>{ticket?.question}</p>
      </div>

      {/* spacer */}
      <div className={classes.spacer} />

      {/* bars */}
      <div className={classes.barsContainer}>
        <div className={classes.barsWrapper}>
          {ticket?.answers.map((answer, index: number) => {
            return (
              <div
                key={index}
                style={{
                  backgroundColor: ticket?.commonAnswers[index] === maxVotes ? '#2C74F3' : '#D7E4FD',
                  color: ticket?.commonAnswers[index] === maxVotes ? 'white' : '#5F6372',
                  height: `${Kheight * ticket?.commonAnswers[index]}px`,
                }}
              >
                <span>{`${ticket?.commonAnswers[index]} %`}</span>
                <b>{answer}</b>
              </div>
            );
          })}
        </div>
      </div>

      {/* spacer */}
      <div className={classes.spacer} />

      {/* buttonsContainer */}
      <div className={classes.buttonsContainer}>
        {isActivePlayer ? (
          <>
            {!isFinalRound && (
              <div className={classes.purple} onClick={onInviteClick}>
                <span>{isEnLocale ? 'Send question to Friends' : 'Отправь вопрос друзьям'}</span>
              </div>
            )}

            <div className={classes.blue} onClick={() => (isFinalRound ? onQuit() : onCheers())}>
              <span>
                {isFinalRound
                  ? isEnLocale
                    ? 'Finish party'
                    : 'Закончить игру'
                  : isEnLocale
                  ? 'Next Question'
                  : 'Следующий вопрос'}
              </span>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export { SingleResults };

import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const mainCardStyle = makeStyles((theme) => ({
  root: {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    display: 'flex',
    alignItems: 'center',
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
      display: 'none',
    },
  },
  rootContent: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '30px 30px 0 0',
    display: 'block',
    maxWidth: '1440px',
    backgroundColor: 'rgba(243, 244, 245, 1)',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 45,
      paddingRight: 45,
      maxWidth: '750px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '1440px',
    },
  },
  gamesContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    flexShrink: 0,
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
      display: 'none',
    },
  },
  partiesContainer: {
    flexDirection: 'column',
    width: '100%',
    display: 'block',
  },
  utilityContainer: {
    paddingTop: 1,
    paddingBottom: 3,
    flexShrink: 0,
  },
  topHostsLabel: {
    fontFamily: fontFamily,
    fontSize: 17,
    fontWeight: 500,
    lineHeight: '17px',
    color: theme.palette.common.black,
  },
  expandArrow: {
    position: 'relative',
    top: 2,
    paddingLeft: 4,
  },
  favouritesIcon: {
    paddingRight: 15,
    width: 16,
    height: 15,
  },

  greenButton: {
    background: ' linear-gradient(180deg, rgba(243, 244, 245, 0) 0%, #F3F4F5 100%);',
    margin: '0 !important',
    paddingBottom: 20,
    marginBottom: -20,
    width: 'auto !important',
    padding: '17px 27px',
    [theme.breakpoints.up('sm')]: {
      display: 'none !important',
    },
    '@media (min-width: 168px)': {
      left: 0,
      bottom: 'unset !important',
    },
  },

  welcomeBanner: {
    maxWidth: 1335,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '-60px',
    position: 'sticky',
    zIndex: -1,
    top: 0,
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      top: '60px',
      position: 'static',
    },
  },
  roomContent: {
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxWidth: '1440px',
    alignItems: 'center',
    overflow: 'scroll',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
      display: 'none',
    },
  },
}));

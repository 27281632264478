import _, { assign } from 'lodash';
import React, { useContext, useEffect, useRef, useCallback, useState } from 'react';
import { useResizeObserver } from '../hooks';
import { Brush, Line } from '../types';
import { CanvasDrawConfig } from './CanvasDrawApi';
import { styles } from './styles';

type Point = { x: number; y: number };

type PointsUpdateHandler = (params: { point: Point } & Brush) => void;
type PointsUpdateListener = (params: Line) => void;
type TriggerFinishLineHandler = (params: Brush) => void;
type LinesUpdateListener = (line: Line) => void;

export type CanvasDrawContextInternalProps = {
  resizeObserverCallback: ResizeObserverCallback;
  subscribeToResize: (element: HTMLCanvasElement, afterResize: (width: number, height: number) => void) => void;
  unsubscribeToResize: (element: HTMLCanvasElement) => void;
  triggerPointsUpdate: PointsUpdateHandler;
  addPointsUpdateListener: (callback: PointsUpdateListener) => void;
  unsubscribePointsUpdate: (callback: PointsUpdateListener) => void;
  triggerFinishLine: TriggerFinishLineHandler;
  addLinesUpdateListener: (callback: LinesUpdateListener) => void;
  unsubscribeLinesUpdate: (callback: LinesUpdateListener) => void;
};

export const CanvasDrawContext = React.createContext<CanvasDrawContextInternalProps | undefined>(undefined);
export const useCanvasDrawContext = (): CanvasDrawContextInternalProps => {
  const context = useContext<CanvasDrawContextInternalProps | undefined>(CanvasDrawContext);

  if (!context) {
    throw new Error('Attempt to use CanvasDrawContext out of CanvasDrawContextProvider');
  }

  return context;
};

interface ResizeListener {
  canvas: HTMLCanvasElement;
  afterResize: (width: number, height: number) => void;
}

interface CanvasCallbacks {
  onLineFinish?: (line: Line) => void;
  onLiveDraw?: (line: Line) => void;
  liveDrawFrequency?: number;
  maxLinePoints?: number;
}

export type CanvasDrawContextProviderProps = CanvasDrawConfig & CanvasCallbacks;

const createContext = (callbacks: CanvasCallbacks, containerRef: React.RefObject<HTMLDivElement>) => {
  const resizeListeners: ResizeListener[] = [];
  const resizeObserverCallback: ResizeObserverCallback = (entries: ResizeObserverEntry[], observer: ResizeObserver) => {
    // save data before update
    for (const entry of entries) {
      const newSize = entry.contentRect;
      for (const listener of resizeListeners) {
        listener.canvas.width = newSize.width;
        listener.canvas.height = newSize.height;
        listener.canvas.style.width = `${newSize.width}px`;
        listener.canvas.style.height = `${newSize.height}px`;
        listener.afterResize(newSize.width, newSize.height);
      }
    }
  };
  const subscribeToResize = (canvas: HTMLCanvasElement, afterResize: (width: number, height: number) => void) => {
    resizeListeners.push({
      canvas: canvas,
      afterResize: afterResize,
    });
  };

  const unsubscribeToResize = (canvas: HTMLCanvasElement) => {
    _.remove(resizeListeners, (listener) => listener.canvas === canvas);
  };

  const x: number[] = [];
  const y: number[] = [];
  let liveDrawCounter = 0;
  const pointsUpdateListener: PointsUpdateListener[] = [];

  const buildLine: (x: number[], y: number[], brush: Brush) => Line = (x, y, brush) => {
    const rect = containerRef.current!.getBoundingClientRect();
    return {
      x: [...x],
      y: [...y],
      ...brush,
      canvasWidth: rect.width,
      canvasHeight: rect.height,
    };
  };

  const assignPoint = (point: Point) => {
    x.push(Math.round(point.x));
    y.push(Math.round(point.y));
  };

  const triggerPointsUpdate: PointsUpdateHandler = ({ point, ...brush }) => {
    // might here be some issues if we scale screen?
    assignPoint(point);

    // split the line;
    if (x.length >= callbacks.maxLinePoints!) {
      triggerFinishLine(brush);
      assignPoint(point);
      return;
    }

    if (liveDrawCounter === 0) {
      const line: Line = buildLine(x, y, brush);
      for (const listener of pointsUpdateListener) {
        listener(line);
      }
      if (callbacks.onLiveDraw) {
        callbacks.onLiveDraw(line);
      }
    }

    if (++liveDrawCounter >= callbacks.liveDrawFrequency!) {
      liveDrawCounter = 0;
    }
  };
  const addPointsUpdateListener = (callback: PointsUpdateListener) => {
    pointsUpdateListener.push(callback);
  };
  const unsubscribePointsUpdate = (callback: PointsUpdateListener) => {
    _.pull(pointsUpdateListener, callback);
  };
  //endregion
  //region Lines Draw
  const lines: Line[] = [];
  const linesUpdateListeners: LinesUpdateListener[] = [];
  const triggerFinishLine: TriggerFinishLineHandler = (brush) => {
    const line: Line = buildLine(x, y, brush);
    console.log('Finish line ', x.length);
    x.length = 0;
    y.length = 0;
    liveDrawCounter = 0;
    lines.push(line);

    for (const listener of linesUpdateListeners) {
      listener(line);
    }

    if (callbacks.onLineFinish) {
      callbacks.onLineFinish(line);
    }
  };
  const addLinesUpdateListener = (callback: LinesUpdateListener) => {
    linesUpdateListeners.push(callback);
  };
  const unsubscribeLinesUpdate = (callback: LinesUpdateListener) => {
    _.pull(linesUpdateListeners, callback);
  };

  return {
    resizeObserverCallback,
    subscribeToResize,
    unsubscribeToResize,
    triggerPointsUpdate,
    addPointsUpdateListener,
    unsubscribePointsUpdate,
    triggerFinishLine,
    addLinesUpdateListener,
    unsubscribeLinesUpdate,
  };
};

export const CanvasDrawContextProvider: React.FC<CanvasDrawContextProviderProps> = ({
  canvasWidth = '100%',
  canvasHeight = '100%',
  onLineFinish,
  onLiveDraw,
  children,
  liveDrawFrequency = 1,
  maxLinePoints = 50,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const classes = styles();
  const [context, setContext] = useState<CanvasDrawContextInternalProps>(() =>
    createContext({ onLineFinish, onLiveDraw, liveDrawFrequency, maxLinePoints }, containerRef)
  );
  const resizeObserver = useResizeObserver(context.resizeObserverCallback);

  useEffect(() => {
    if (containerRef.current) {
      resizeObserver?.observe(containerRef.current);
    }

    return () => resizeObserver?.disconnect();
  }, [resizeObserver, containerRef.current]);

  return (
    <CanvasDrawContext.Provider value={context}>
      <div ref={containerRef} className={classes.container} style={{ width: canvasWidth, height: canvasHeight }}>
        {children}
      </div>
    </CanvasDrawContext.Provider>
  );
};

import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import WSClient from '@src/sockets';
import { PLAYER_STATUS } from '@src/common/constants/system';
import { simpleMerge } from '@src/common/generics';
import { PLAYER_STATE, selectRoomPlayers } from '@store/room/roomSlice';
import { selectPlayerId } from '@store/reducers/player.reducer';
import { DrinkRouletteState } from '@shared/gameInterfaces/roulette.socket';
import { CLIENT_EVENTS, SERVER_EVENTS } from '@shared/socketEvents/eventTypes';

import { defaultGameState, initialMapState } from './roulette.types';

interface RouletteData {
  data: {
    roulette: DrinkRouletteState;
  };
}

export const useGameProvider = (roomId: string) => {
  const [gameState, setGameState] = useState<DrinkRouletteState>(defaultGameState);
  const players = useSelector(selectRoomPlayers);
  const playerId = useSelector(selectPlayerId);
  const isActivePlayer = playerId === gameState.activePlayerId;
  const currentPlayer = players.find((player: any) => player.playerId === playerId);
  const isSpectator = currentPlayer ? currentPlayer.state !== PLAYER_STATE.GAME : true;
  const stateMatcher = { ...initialMapState, [gameState.gameState]: true };

  useEffect(() => {
    const updateState = ({ data }: RouletteData) => setGameState(simpleMerge(gameState, data.roulette));

    WSClient.on(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    WSClient.on(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);

    WSClient.emit(CLIENT_EVENTS.GAME_STATE_REQUESTED, { roomId });

    return () => {
      WSClient.off(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);
      WSClient.off(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    };
  }, []);

  const setVote = (answerIndex: number) => setGameState(simpleMerge(gameState, {})); // vote: answerIndex }));
  const setReady = () => setGameState(simpleMerge(gameState, {})); // readyPlayers: playerId }));

  // return from provider
  return [
    // 1 - status
    stateMatcher,

    // 2 - gameState
    { ...gameState, status: stateMatcher },

    // 3 - playerId
    playerId,

    // 4 - players
    players,

    // 5 - isActivePlayer
    isActivePlayer,

    // 6 - storeActions
    { setVote, setReady },

    // 7 - isSpectator
    isSpectator,
  ] as const;
};

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  palleteList: {
    display: 'flex',
    width: '100%',
    minHeight: '50px',
    backgroundColor: '#F3F4F5',
    alignItems: 'center',
  },

  palleteListColors: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    listStyle: 'none',
    margin: 0,
    padding: '10px',
    backgroundColor: '#E8E9EB',
    borderRadius: '8px',
    width: '100%',
    maxWidth: '360px',
    flexWrap: 'wrap',
    '& > li': {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '32px',
      height: '32px',
      borderRadius: '4px',
      '& > div': {
        borderRadius: '50%',
        width: '20px',
        height: '20px',
      },
      '&.active': {
        backgroundColor: 'white',
        border: '1px solid #2C74F3',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
}));

import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';

const OneQuestionRules: React.FC = () => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();

  return (
    <>
      {isEnLocale ? (
        <ul className={classes.list}>
          <li>OneQuestionRules EN</li>
        </ul>
      ) : (
        <ul className={classes.list}>
          <li>OneQuestionRules RU</li>
        </ul>
      )}
    </>
  );
};

export { OneQuestionRules };

import React from 'react';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { IRecievedNotification, NOTIFICATION_TYPE } from '@src/store/room/notifications';
import { useStyles } from './styles';
import { selectRoomPlayer } from '@src/store/room/roomSlice';
import { selectAvatar } from '@src/store/reducers/player.reducer';
import HelloCoin from '@art/icons/hello-coin.svg';
import UserImg from '@art/icons/StatusBar/user.svg';

interface Props {
  notification: IRecievedNotification;
}

const RecievedNotification: React.FC<Props> = ({ notification }) => {
  const classes = useStyles();
  const player = useAppSelector(selectRoomPlayer(notification.playerId));
  const avatar = useAppSelector(selectAvatar(player?.avatarId));
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <div className={classes.recieved}>
      <img src={avatar?.image || UserImg} alt="icon" style={{ backgroundColor: `#${avatar?.background || 'fff'}` }} />
      <span style={{ fontWeight: 800 }}>{notification.name || player?.name}&nbsp;</span>
      <p> {isEnLocale ? ` sent you` : ` отправил вам`}</p>
      <p className={'amount'}>&nbsp;{notification.amount}</p>
      <img className={'coin'} src={HelloCoin} alt="coin" />
    </div>
  );
};

export { RecievedNotification };

import WSClient from '@src/sockets';
import { useDispatch, useSelector } from 'react-redux';
import { useMatchMeProvider } from './matchMe.provider';
import { CLIENT_EVENTS } from '@shared/socketEvents/eventTypes';
import { selectRoomPlayers } from '@store/room/roomSlice';
import { endGameEvent } from '@src/common/sendEvents';
import { GameTypes } from '@src/common/constants/common';

const useMatchMeHook = (roomId: string) => {
  const roomPlayers = useSelector(selectRoomPlayers);
  const [state, game, playerId, storeActions] = useMatchMeProvider(roomId);
  const activeGameId = game.activeGameId;

  return {
    state,
    game,
    isActivePlayer: roomPlayers[0].playerId === playerId,
    actions: {
      onPlayerVoted: (index: number) => {
        storeActions.setVote(index);
        WSClient.emit(CLIENT_EVENTS.PLAYER_VOTED, {
          roomId,
          activeGameId,
          playerId,
          value: index,
        });
      },

      onGameQuit: () => {
        endGameEvent(roomId, playerId!, GameTypes.MATCH_ME);
      },
    },
  };
};

type HookType = ReturnType<typeof useMatchMeHook>;
type ActionsType = HookType['actions'];

export { useMatchMeHook };
export type { ActionsType };

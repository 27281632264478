import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  draw: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    position: 'relative',
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    '& .clearAllButton': {
      display: 'none',
    },
  },
}));

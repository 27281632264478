import { useState } from 'react';
import RightIcon from '@art/icons/right.svg';
import WrongIcon from '@art/icons/wrong.svg';
import { QuizClassicState } from '@shared/gameInterfaces/quizClassic.socket';
import { LineTimer } from '@src/games/common';
import { styles } from './styles';
import { useDeviceSize } from '@src/common/screenSizes';

interface IProps {
  game: QuizClassicState;
  playerId: string;
  isSpectator: boolean;
  isRightAnswerStatus: boolean;
  onVote: (index: number) => void;
  onReady: () => void;
}

const Question: React.FC<IProps> = ({ game, playerId, isRightAnswerStatus, isSpectator, onVote, onReady }) => {
  const classes = styles();
  const [isVote, setVote] = useState(false);
  const [voteIndex, setVoteIndex] = useState(null);
  const { ticket, votedPlayers, rightAnswer } = game;
  const { isDesktop } = useDeviceSize();
  const isVotedAfterReload = votedPlayers && votedPlayers.includes(playerId);
  const clickedCondition = isVote || isVotedAfterReload;

  const onVoteClick = (index: number) => {
    //@ts-ignore
    setVoteIndex(index);
    setVote(true);
    onVote(index);
  };

  const showQuestionColors = (index: number) => {
    if (isVote) {
      return {
        color: voteIndex === index ? 'white' : '#BBBCC3',
        backgroundColor: voteIndex === index ? '#2C74F3' : '#EAF1FE',
      };
    } else if (isVotedAfterReload) {
      return {
        color: '#BBBCC3',
        backgroundColor: '#EAF1FE',
      };
    } else {
      return {
        color: 'white',
        backgroundColor: '#2C74F3',
      };
    }
  };

  const showAnswerColors = (index: number) => {
    // right answer
    if (index == rightAnswer) {
      return {
        color: 'white',
        backgroundColor: '#3AB957',
        pointerEvents: 'none',
      };
    } else if (index != rightAnswer) {
      // wrong user answer
      if (index == voteIndex) {
        return {
          color: 'white',
          backgroundColor: '#F83E67',
          pointerEvents: 'none',
        };
        // empty choices
      } else {
        return {
          color: 'white',
          backgroundColor: '#FDDBE2',
          pointerEvents: 'none',
        };
      }
    }
  };

  const showAnswerIcon = (index: number) => {
    if (index === voteIndex) {
      if (voteIndex == rightAnswer) {
        return <img src={RightIcon} alt="right-icon.svg" />;
      } else return <img src={WrongIcon} alt="wrong-icon.svg" />;
    }
  };

  return (
    <div className={classes.question}>
      {!isDesktop && (
        <div className={classes.timerWrapper}>
          {game.timer?.duration && !isRightAnswerStatus && <LineTimer timer={game.timer!} isVote={isVote} />}
        </div>
      )}

      <div className={`${classes.questionWrapper} animate__animated  animate__fadeIn`}>
        <div className={classes.questionInner}>
          <p>{ticket?.question}</p>
        </div>

        <div
          className={`
        ${classes.buttonsContainer}
        ${clickedCondition ? 'clicked' : ''}
        ${isSpectator ? 'isSpectator' : ''}
      `}
        >
          {ticket?.answers.map((answer: string, index: number) => {
            return (
              <div
                key={index}
                className={classes.answerButton}
                onClick={() => onVoteClick(index)}
                style={!isRightAnswerStatus ? showQuestionColors(index) : showAnswerColors(index)}
              >
                {isRightAnswerStatus && showAnswerIcon(index)}
                <span>{answer}</span>
              </div>
            );
          })}
        </div>

        {isDesktop && (
          <div className={classes.timerWrapper}>
            {game.timer?.duration && !isRightAnswerStatus && <LineTimer timer={game.timer!} isVote={isVote!} />}
          </div>
        )}
      </div>
    </div>
  );
};

export { Question };

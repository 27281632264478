import React, { useEffect } from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import { NextParty } from '@pages/PrivateLobby/nextParty';
import GameIntroPage from '@src/Pages/gameIntro';
import roomHPContent from '@pages/Main/roomHPContent';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectIsAlonePlayer } from '@store/room/roomSlice';
import { showInviteThunk } from '@common/misc';
import { RateScreen } from './RateScreen/rateScreen';
import { requestGameBalances } from '@src/graphql/loadHomePageData';
import { selectIsInviteShownOnce } from '@src/store/reducers/popups';

interface RouterProps {
  roomId: string;
}

interface Props extends RouteComponentProps<RouterProps> {}

const RoomLobby: React.FC<Props> = ({ match }) => {
  const currentPath = match.path;
  const dispatch = useAppDispatch();
  const isAlonePlayer = useAppSelector(selectIsAlonePlayer);
  const isInviteShownOnce = useAppSelector(selectIsInviteShownOnce);

  const lobbyRoutes: RouteProps[] = [
    { path: `${currentPath}`, component: roomHPContent, exact: true },
    { path: `${currentPath}/game/:gameId`, component: GameIntroPage },
    { path: `${currentPath}/experience/:gameId`, component: GameIntroPage },
    { path: `${currentPath}/rateScreen/:partyId`, component: RateScreen },
    { path: `${currentPath}/nextParty/:partyId`, component: NextParty },
  ];

  useEffect(() => {
    if (isAlonePlayer && !isInviteShownOnce) {
      dispatch(showInviteThunk('AUTO'));
    }

    dispatch(requestGameBalances);
  }, []);

  return (
    <>
      {lobbyRoutes.map((props, idx) => (
        <Route key={idx} {...props} />
      ))}
    </>
  );
};

export { RoomLobby };

import CountUp from 'react-countup';
import { styles } from './styles';

interface IProps {
  resultsClick: (value: string) => void;
  isResultsClicked: boolean;
  questionText: string;
}

const Results: React.FC<IProps> = ({ resultsClick, isResultsClicked, questionText }) => {
  const classes = styles();

  return (
    <div className={classes.results}>
      {/* questionText */}
      <div
        className={`
        ${classes.questionText}
        animate__animated animate__fadeInDown
        ${isResultsClicked ? 'animate__fadeOutUp' : ''}
      `}
      >
        {questionText}
      </div>

      <div className={classes.spacer} />

      {/* resultsBarsContainer */}
      <div
        className={`
        ${classes.barsContainer}
        animate__animated animate__fadeIn
        ${isResultsClicked ? 'animate__fadeOut' : ''}
      `}
      >
        <div className={classes.barsWrapper}>
          <div>
            <span>
              <CountUp end={75} suffix="%" />
            </span>
            <b>No sex</b>
          </div>

          <div>
            <span>
              <CountUp end={25} suffix="%" />
            </span>
            <b>No internet</b>
          </div>
        </div>
      </div>

      <div className={classes.spacer} />

      {/* buttonsContainer */}
      <div
        className={`
        ${classes.buttonsContainer}
        animate__animated animate__fadeInUp
        ${isResultsClicked ? 'animate__fadeOutDown' : ''}
      `}
      >
        <div className={classes.button} onClick={() => resultsClick('friends')}>
          <span>Send question to Friends</span>
        </div>

        <div className={classes.button} onClick={() => resultsClick('homepage')}>
          <span>Next Question</span>
        </div>
      </div>
    </div>
  );
};

export default Results;

import { useEffect } from 'react';
import { useGameProvider } from './drawAndGuessProvider';
import { updateDrawAndGuessLabelsThunk } from './drawAndGuess.labels';
import { useAppDispatch } from '@src/app/hooks';
import { sendRoundStarted } from '@src/services/analyticsThunk';

const useGameHook = (roomId: string) => {
  const dispatch = useAppDispatch();

  const [game, isActivePlayer, activePlayer, actions, eventObserver, playerId, isSpectator] = useGameProvider(roomId);

  useEffect(() => {
    dispatch(updateDrawAndGuessLabelsThunk(game));
  }, [game]);

  useEffect(() => {
    if (game.state.READY_START_ROUND) {
      dispatch(sendRoundStarted(game.roundNumber.toString()));
    }
  }, [game.state.READY_START_ROUND]);

  return {
    game,
    isActivePlayer,
    activePlayer,
    actions,
    eventObserver,
    playerId,
    isSpectator,
  };
};

type HookType = ReturnType<typeof useGameHook>;
type ActionsType = HookType['actions'];

export type { ActionsType };

export { useGameHook };

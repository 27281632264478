import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  desktopMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '350px',
    padding: '20px',
  },

  desktopMenuInterface: {
    width: '100%',
    color: 'inherit',
    flexDirection: 'column',
    alignItems: 'center',
  },

  modeMenuAndBrushSizes: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },

  modeMenuList: {
    backgroundColor: '#E8E9EB',
    height: '50px',
    flexBasis: '81px',
    margin: 0,
    padding: 0,
    marginRight: '14px',
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    '& > li': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '17px 12px',
      cursor: 'pointer',
      '&:first-child': {
        position: 'relative',
        borderRadius: '8px 0 0 8px',
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: '1px',
          height: '32px',
          top: '50%',
          right: 0,
          backgroundColor: '#fff',
          marginTop: '-16px',
        },
      },
      '&:last-child': {
        borderRadius: '0 8px 8px 0',
      },
      '& > img': {
        width: '16px',
        height: 'auto',
        opacity: 0.3,
      },
      '&.active': {
        '& > img': {
          opacity: 1,
        },
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },

  brushSizes: {
    display: 'flex',
    flexBasis: '195px',
    backgroundColor: '#E8E9EB',
    height: '50px',
    padding: '10px 12px',
    borderRadius: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    '& > div': {
      // marginLeft: '20px',
    },
  },

  brushSizesList: {
    display: 'flex',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    flexBasis: '150px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > li': {
      border: '1px solid #BBBCC3',
      borderRadius: '50%',
      backgroundColor: 'white',
      '&:last-child': {
        marginRight: 0,
      },
      '&:hover': {
        cursor: 'pointer',
      },
      '&.active': {
        backgroundColor: '#2C74F3',
        border: 0,
      },
    },
  },

  palleteList: {
    display: 'flex',
    width: '100%',
    minHeight: '50px',
    backgroundColor: '#F3F4F5',
    alignItems: 'center',
  },

  palleteListColors: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    listStyle: 'none',
    margin: 0,
    padding: '10px',
    backgroundColor: '#E8E9EB',
    borderRadius: '8px',
    width: '100%',
    maxWidth: '360px',
    flexWrap: 'wrap',
    '& > li': {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '32px',
      height: '32px',
      borderRadius: '4px',
      '& > div': {
        borderRadius: '50%',
        width: '20px',
        height: '20px',
      },
      '&.active': {
        backgroundColor: 'white',
        border: '1px solid #2C74F3',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
}));

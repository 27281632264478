import { IRoomPlayer } from '@store/room/roomSlice';
import { useAppSelector } from '@src/app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { Martini } from '@components/icons';
import { styles } from './styles';

interface IProps {
  drinkPlayers: string[] | undefined;
  players: IRoomPlayer[];
}

const WhoDrinks: React.FC<IProps> = ({ drinkPlayers, players }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  // [{playerId, name, gender, isReady, playerStatus} - all players] --> [{playerId, name, gender, isReady, playerStatus} - return who drinks players]
  const whoDrinksArray = players.filter((player: { playerId: string }) => drinkPlayers?.includes(player.playerId));

  // [{playerId, name, gender, isReady, playerStatus} - who drinks array] --> <div name[0],="" name[1],="" ..=".."></div>
  const whoDrinksNames = whoDrinksArray.map((player: { name: string }) => player.name).join(', ');

  const showWhoDrinks =
    players.length === drinkPlayers?.length ? (isEnLocale ? 'All players drink' : 'Все игроки пьют') : whoDrinksNames;

  return (
    <div className={classes.whoDrinksBlock}>
      <b>{isEnLocale ? 'Who drinks?' : 'Кто пьет?'}</b>
      <Martini />
      <p>{showWhoDrinks}</p>
    </div>
  );
};

export { WhoDrinks };

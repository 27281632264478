import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  controlsContainer: {
    marginLeft: '10px',
    marginRight: '5px',
    boxSizing: 'border-box',
    '& *': {
      boxSizing: 'border-box',
    },

    '& > div': {
      height: '28px',
      width: '28px',
      cursor: 'pointer',
    },
  },

  audio: {
    marginRight: '5px',
  },

  video: {
    marginRight: '10px',
  },
}));

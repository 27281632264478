import React, { useEffect, useRef, useState } from 'react';
import { mainCardStyle } from './styles';
import { HomePageContent } from './content';
import { RoomButton } from '@components/CreateRoomButton';
import { HeroBanner } from '@components/HeroBanner';
import { useAppSelector } from '@app/hooks';
import { getHomepageBanners } from '@store/reducers/common';
import { MetaWrapper } from '@components/AppWrappers/MetaWrapper';
import { LoginModal } from '@src/components/Modals';
import ConnectToServerLoader from '@src/components/ConnectToServerLoader';
import { getIsHomePageLoading } from '@src/store/reducers/loadActions';

const Main: React.FC = () => {
  const classes = mainCardStyle();
  const bannerInfos = useAppSelector(getHomepageBanners);
  const rootRef = useRef<HTMLDivElement>(null);
  const IsHomePageLoading = useAppSelector(getIsHomePageLoading);

  return (
    <>
      {!IsHomePageLoading && (
        <div className={classes.root} ref={rootRef}>
          <HeroBanner bannerInfos={bannerInfos} />
          <HomePageContent />
        </div>
      )}
    </>
  );
};

export default MetaWrapper(Main);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '@app/store';
import { IRoomPlayer } from '@store/room/roomSlice';
import { NUMBER_OF_PERSONS, PERSONS_ARRAY } from '@src/common/constants/system';
import { PersonItem, InviteItem } from './parts';
import { styles } from './styles';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { popStickerAction, selectStickerAction, StickerAction } from '@store/room/roomFeaturesData.slice';

type Props = {
  players: IRoomPlayer[];
  isLoading: boolean;
};

const PersonsContainer: React.FC<Props> = ({ players, isLoading }: Props) => {
  const dispatch = useAppDispatch();
  const stickerAction = useAppSelector(selectStickerAction);
  const [nextStickerAction, setStickerAction] = useState<StickerAction | undefined>(undefined);
  const classes = styles();

  useEffect(() => {
    if (!stickerAction) return;
    setStickerAction(stickerAction);
    dispatch(popStickerAction());
  }, [stickerAction]);

  const renderPlayers: Function = () => {
    return players.slice(0, NUMBER_OF_PERSONS).map((item, index) => {
      const player = players[index];
      const stickerAction = player.playerId === nextStickerAction?.targetId ? nextStickerAction : undefined;

      return (
        <PersonItem
          key={player.playerId || index}
          user={players[index]}
          isLoading={isLoading}
          stickerAction={stickerAction}
        />
      );
    });
  };

  const renderInvites: Function = () => {
    if (players.length) {
      return PERSONS_ARRAY.slice(0, NUMBER_OF_PERSONS - players.length).map((item, index) => {
        return <InviteItem key={index} />;
      });
    } else {
      return null;
    }
  };

  if (!isLoading) {
    return (
      <section className={classes.personsContainer}>
        {renderPlayers()}
        {renderInvites()}
      </section>
    );
  } else {
    return null;
  }
};

export default connect((state: RootState) => ({
  players: state.room.players,
}))(PersonsContainer);

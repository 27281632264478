import { useAppSelector } from '@app/hooks';
import Pencil from '@art/images/draw-and-guess/pencil.svg';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

interface Props {
  chosenWord: {
    word: string;
    value: number;
  };
}

const Active: React.FC<Props> = ({ chosenWord }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const wordValueText = isEnLocale
    ? chosenWord.value === 1
      ? 'Point to you and player who guessed correctly'
      : 'Points to you and player who guessed correctly'
    : chosenWord.value === 1
    ? 'очко тебе и игроку, который угадает'
    : 'очка тебе и игроку, который угадает';

  return (
    <div className={classes.wordShowContainer}>
      <div className={classes.wordShowItem}>
        <div className={classes.wordShowTop}>
          <div className={classes.wordShowTitle}>{isEnLocale ? 'Draw' : 'Нарисуй'}</div>

          <img src={Pencil} className={classes.pencilImage} alt="pencil.svg" />

          <b>{chosenWord.word}</b>
        </div>

        <div className={classes.wordShowBottom}>
          <b>{chosenWord.value}</b>
          <div>
            <p>{wordValueText}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Active };

import { AliasState, ALIAS_STATE } from '@shared/gameInterfaces/alias.socket';
// import { TypeInfo } from 'graphql';

export const initialMapState = {
  READY_START_ROUND: false,
  SPIN: false,
  SHOW_WORD: false,
  ALIAS: false,
  RESULTS: false,
  TOP_PLAYERS: false,
  LEADERBOARD: false,
};

export const defaultGameState: AliasState = {
  gameState: ALIAS_STATE.READY_START_ROUND,
  activeGameId: '',
  activePlayerId: '',
  roundNumber: 1,
  totalRounds: 20,
};

export type MapState = typeof initialMapState;

import React, { useState } from 'react';
import { copyLinkToClipboard } from '@common/misc';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { getRoomLink, selectPartyId, selectQuestionNumber } from '@store/room/roomSlice';
import { selectInviteLink, selectIsInviteShown, setInviteLink, setInviteShown } from '@store/reducers/popups';
import Button from '@components/Button';
import CommonModal from '@components/Modals/CommonModal';
import CloseCross from '@components/CloseCross/CloseCross';
import { TelegramShareButton, FacebookShareButton, WhatsappShareButton, TwitterShareButton } from 'react-share';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import HelloCoin from '@art/icons/hello-coin.svg';
import { styles } from './styles';
import { pushNotification } from '@src/store/room/roomFeaturesData.slice';
import { NOTIFICATION_TYPE } from '@src/store/room/notifications';
import { analytics } from '@src/services/amplitude';
import { requestProgressInviteQuest } from '@src/graphql/player.queries';

const getSendLinkText = (isEnLocale: boolean) => (isEnLocale ? 'Send to Friends' : 'Отправь друзьям');
const getInviteText = (isEnLocale: boolean) => (isEnLocale ? 'Invite friends' : 'Пригласи друзей');

const InviteModal: React.FC = () => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const inviteLink = useAppSelector(selectInviteLink);
  const roomLink = useAppSelector(getRoomLink);
  const isInviteShown = useAppSelector(selectIsInviteShown);
  const [showInviteSnack, setShowInviteSnack] = useState(false);
  const link = inviteLink || roomLink;
  const isInviteLink = inviteLink === '';
  const partyId = useAppSelector(selectPartyId);
  const questionNumber = useAppSelector(selectQuestionNumber);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const text = isInviteLink ? getInviteText(isEnLocale) : getSendLinkText(isEnLocale);

  const handleClose = () => {
    dispatch(setInviteLink(''));
    dispatch(setInviteShown(false));
  };

  const sendSingleQuestionAnalytics = () => {
    if (!isInviteLink) {
      analytics.logEvent(analytics.EVENTS.SHARE_QUESTION, { partyId, question: questionNumber });
    }
  };

  const handleCopy = () => {
    dispatch(setInviteLink(''));
    dispatch(setInviteShown(false));
    setShowInviteSnack(true);
    dispatch(copyLinkToClipboard(link, 'POPUP'));
    dispatch(pushNotification({ type: NOTIFICATION_TYPE.COPY_LINK }));
    sendSingleQuestionAnalytics();
  };

  const onShareClick = () => {
    dispatch(requestProgressInviteQuest);
    sendSingleQuestionAnalytics();
  };

  return (
    <>
      {/*<SnackbarAfterCopy
        open={showInviteSnack}
        onClose={() => setShowInviteSnack(false)}
        text={
          isEnLocale
            ? 'Room link copied to the clipboard. Send it to your friends!'
            : 'Ссылка на комнату скопирована в буфер обмена. Отправь её своим друзьям!'
        }
      />*/}
      {/*<SnackbarAfterCopy open={showInviteSnack} onClose={() => setShowInviteSnack(false)} />*/}

      <CommonModal isOpen={isInviteShown} handleClose={handleClose} backgroundColor={'white'}>
        <div className={classes.inviteWrapper}>
          {/* <div className={classes.closeContainer}> */}
          <div style={{ position: 'absolute', right: '0px', top: '0' }}>
            <CloseCross onClick={handleClose} />
          </div>

          <div className={classes.inviteTitle}>
            {isInviteLink && <img src={HelloCoin} alt="HelloCoin" />}
            <span>{text}</span>
          </div>

          {isInviteLink && (
            <div className={classes.inviteSubTitle}>
              {isEnLocale ? 'to increase friends bonus' : 'увеличь им бонус'}
            </div>
          )}

          {/* shareList */}
          <ul className={classes.shareList}>
            <li>
              <TelegramShareButton url={link} onClick={onShareClick}>
                <div className={`${classes.shareItem} telegram`} />
              </TelegramShareButton>
            </li>

            <li>
              <WhatsappShareButton url={link} onClick={onShareClick}>
                <div className={`${classes.shareItem} whatsapp`} />
              </WhatsappShareButton>
            </li>

            <li>
              <FacebookShareButton url={link} onClick={onShareClick}>
                <div className={`${classes.shareItem} facebook`} />
              </FacebookShareButton>
            </li>

            <li>
              <TwitterShareButton url={link} onClick={onShareClick}>
                <div className={`${classes.shareItem} twitter`} />
              </TwitterShareButton>
            </li>
          </ul>

          {/* <div className={classes.invitePicture} /> */}
          {/* <p>Send this link bellow to your friends:</p> */}

          <div className={classes.linkContainer}>
            <span>{link}</span>
            {/* copy button */}
            <Button
              type="primary-blue-new"
              text={isEnLocale ? 'Copy room link' : 'Скопировать'}
              onClick={handleCopy}
              className={classes.button}
            />
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default InviteModal;

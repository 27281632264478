import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';
import { selectRoomPlayers } from '@src/store/room/roomSlice';
import tick from '@art/images/guess-up/tick.svg';
import cross from '@art/images/guess-up/cross.svg';
import { NextButton } from '@src/games/common';
import { FiveSecondsState, VoteType } from '@src/shared/gameInterfaces/fiveSeconds.socket';

interface IProps {
  game: FiveSecondsState;
  isSpectator: boolean;
  isActivePlayer: boolean;
  onReady: () => void;
  activePlayer: { name: string };
}

const VotesTable: React.FC<IProps> = ({ game, isSpectator, isActivePlayer, onReady, activePlayer }) => {
  const classes = styles();
  const { votes, hasWon } = game;
  console.log(activePlayer.name);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const players = useAppSelector(selectRoomPlayers);
  const text = hasWon ? (isEnLocale ? 'passed' : 'выиграл') : isEnLocale ? 'failed' : 'проиграл';
  return (
    <div className={`${classes.resultsWrapper} animate__animated  animate__fadeIn`}>
      <h2 className="mobile-outline">{activePlayer.name + ' ' + text}</h2>

      <div className={`${classes.leaderboardTable} animate__animated  animate__fadeIn`}>
        {votes!.map((vote: VoteType, index: number) => {
          const playerName = players.find((player) => player.playerId === vote.playerId)?.name;

          return (
            <div key={index} className={classes.leaderboardTableRow}>
              <span className={classes.leaderboardTableRowName}>{playerName}</span>
              <img className={classes.leaderboardTableRowSign} src={vote.value ? tick : cross} />
            </div>
          );
        })}
      </div>

      {isActivePlayer ? (
        <div className={classes.buttonWrapper}>
          <NextButton isSpectator={isSpectator} onClick={onReady} isTopPlayers={true} isActivePlayer={true} />
        </div>
      ) : (
        <div className={classes.badge}>
          {isEnLocale
            ? 'Active player must tap Next to proceed'
            : 'Активный игрок должен нажать Далее, чтобы продолжить'}
        </div>
      )}
    </div>
  );
};

export { VotesTable };

import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  image: {
    borderRadius: '10px',
    width: '100%',
    height: 'auto',
    marginBottom: '15px',
  },

  list: {
    fontFamily: fontFamily,
    flexDirection: 'column',
    paddingLeft: 0,
    marginTop: 0,
    overflowY: 'auto',
    maxHeight: '58vh',
    '& li': {
      position: 'relative',
      fontSize: '15px',
      lineHeight: '21px',
      marginBottom: '12px',
      listStyle: 'none',
      paddingLeft: '15px',
      '&:last-child': {
        marginBottom: 0,
      },
      [theme.breakpoints.up('md')]: {
        paddingRight: '15px',
        fontSize: '14px',
        lineHeight: '21px',
      },
      '&::before': {
        content: '""',
        display: 'block',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: '#E8E9EB',
        position: 'absolute',
        top: '8px',
        left: 0,
      },
    },
    '& b': {
      fontWeight: 700,
    },
  },
}));

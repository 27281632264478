import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  timerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#5568AE',
    padding: '9px',
  },
  timer: {
    fontFamily: 'SF Pro Rounded Black',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    textStroke: '1px #1E1E1E',
  },
}));

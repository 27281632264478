import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  showWord: {
    width: '100%',
    minHeight: '100%',
    flexGrow: 1,
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
    justifyContent: 'center',
    color: '#1B2036',
    animationDuration: '230ms',
    animationDelay: '230ms',
    [theme.breakpoints.up('md')]: {
      padding: '30px',
    },
  },

  showFakeCard: {
    width: '207px',
    minHeight: '275px',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '10px solid #305088',
    padding: '15px 15px 20px',
    backgroundColor: 'white',
    borderRadius: '20px',
    color: '#1B2036',
    animationDuration: '230ms',
    animationDelay: '230ms',
    [theme.breakpoints.up('sm')]: {
      width: '320px',
      minHeight: '425px',
      padding: '25px ​15px 35px',
      border: '15px solid #305088',
      borderRadius: '30px',
    },
  },

  showFakeCardTop: {
    flexDirection: 'column',
    '& > p': {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 600,
      [theme.breakpoints.up('sm')]: {
        fontSize: '30px',
        lineHeight: '36px',
      },
    },
  },

  showFakeCardLine: {
    width: '100%',
    height: '10px',
    backgroundColor: '#305088',
    borderRadius: '20px',
    [theme.breakpoints.up('sm')]: {
      height: '15px',
    },
  },

  showFakeCardBottom: {
    '& > p': {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '30px',
        lineHeight: '36px',
      },
    },
  },
}));

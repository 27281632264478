import { makeStyles } from '@material-ui/core';

import GoldGlowImg from '@art/images/reward/glow2.png';

export const styles = makeStyles((theme) => ({
  coinsWrapper: {
    position: 'relative',
    zIndex: 3,
    width: '100%',
    justifyContent: 'center',
    marginTop: '25px',
    '--animate-duration': '500ms',
    '--animate-delay': '500ms',
    [theme.breakpoints.up('sm')]: { marginTop: '10px' },
    [theme.breakpoints.up('md')]: { marginTop: 0 },
  },

  recieverContainer: {
    position: 'relative',
    zIndex: 4,
    flexShrink: 1,
    flexGrow: 0,
    minHeight: '137px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${GoldGlowImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '137px auto',
    [theme.breakpoints.up('sm')]: {
      minHeight: '233px',
      backgroundSize: '233px auto',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '278px',
      backgroundSize: '278px auto',
    },
  },

  coinContainer: {
    position: 'relative',
    zIndex: 4,
    minWidth: '100px',
    flexBasis: '100px',
    flexShrink: 0,
    minHeight: '137px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${GoldGlowImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '137px auto',
    [theme.breakpoints.up('sm')]: {
      minWidth: '200px',
      flexBasis: '200px',
      minHeight: '233px',
      backgroundSize: '233px auto',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '240px',
      flexBasis: '240px',
      minHeight: '278px',
      backgroundSize: '278px auto',
    },
  },

  coin: {
    width: '65px',
    height: '65px',
    [theme.breakpoints.up('sm')]: {
      width: '104px',
      height: '104px',
    },
    [theme.breakpoints.up('md')]: {
      width: '124px',
      height: '124px',
    },
  },

  coinValue: {
    fontFamily: 'SF Pro Rounded',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: '8px',
    zIndex: 5,
    color: 'white',
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 800,
    textAlign: 'center',
    textShadow: '0 0 3px #000',
    letterSpacing: '1px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '32px',
      lineHeight: '38px',
      bottom: '18px',
    },
  },

  reciever: {
    width: '125px',
    height: '125px',
    [theme.breakpoints.up('sm')]: {
      width: '233px',
      height: '233px',
    },
    [theme.breakpoints.up('md')]: {
      width: '278px',
      height: '278px',
    },
  },
}));

import { forwardRef, Ref } from 'react';
import { IImage, IImageSourceSet } from '@shared/misc/image.types';
import { useDeviceSize } from '@src/common/screenSizes';

interface IProps {
  src?: string;
  srcSet?: IImageSourceSet;
  alt?: string;
  className?: string;
  onLoad?: () => void;
}

export const Image = forwardRef<HTMLImageElement, IProps>(function Image(
  { srcSet, src, alt, className, onLoad },
  ref: Ref<HTMLImageElement>
) {
  // Check id srcSet is undefined or it's every field are null
  // const useSrcSet = srcSet && Object.values(srcSet).some((value) => value !== null);
  const useSrcSet = srcSet?.mobile;

  const retina2xSource = `${srcSet?.x2 ? ',' + srcSet?.x2 + ' 2x' : ''}`;

  return (
    <picture>
      {useSrcSet && (
        <>
          <source media="(min-width: 0) and (max-width: 600px)" srcSet={`${srcSet?.mobile} 1x${retina2xSource}`} />
          <source media="(min-width: 601px) and (max-width: 960px)" srcSet={`${srcSet?.tablet} 1x${retina2xSource}`} />
          <source media="(min-width: 961px)" srcSet={`${srcSet?.desktop} 1x${retina2xSource}`} />
        </>
      )}
      <img className={className} src={src} alt={alt} ref={ref} onLoad={onLoad} />
    </picture>
  );
});

const useImageHook = (image: IImage | undefined) => {
  const { isMobile, isTablet } = useDeviceSize();

  if (!image) return '';

  const useSrcSet = image.srcSet?.mobile;
  const retina2xSource = image.srcSet?.x2 ? `, url(${image.srcSet?.x2}) 2x` : '';

  const imageSrc = useSrcSet
    ? isMobile
      ? `-webkit-image-set(url(${image.srcSet?.mobile}) 1x${retina2xSource})`
      : isTablet
      ? `-webkit-image-set(url(${image.srcSet?.tablet}) 1x${retina2xSource})`
      : `-webkit-image-set(url(${image.srcSet?.desktop}) 1x${retina2xSource})`
    : `url(${image.src})`;

  return imageSrc;
};

export { useImageHook };

import { useAppSelector } from '@src/app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

interface Props {
  questionType: string;
}

const RouletteDrinkContent: React.FC<Props> = ({ questionType }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const drink = isEnLocale ? '1 drink' : '1 глоток';

  return (
    <div style={{ flexDirection: 'column' }}>
      <h3 id="transition-modal-title">{isEnLocale ? 'Who drinks?' : 'Кто пьет?'}</h3>

      {questionType === 'playersList' && (
        <div className={classes.pointsHelperBlock}>
          <div>
            <b>{isEnLocale ? 'Top voted player(s)' : 'Игрок/и, за которых больше голосов'}</b>
            <div className={classes.dots} />
            <span>{drink}</span>
          </div>
          <div>
            <b>{isEnLocale ? 'Active player' : 'Активный игрок'}</b>
            <div className={classes.dots} />
            <span>{drink}</span>
          </div>
        </div>
      )}

      {questionType === 'static' && (
        <div className={classes.pointsHelperBlock}>
          <div>
            <b>
              {isEnLocale
                ? 'Player(s) who made the same choice as the active player'
                : 'Игрок/и, которые сделали такой же выбор, как и активный игрок'}
            </b>
            <div className={classes.dots} />
            <span>{drink}</span>
          </div>
          <div>
            <b>{isEnLocale ? 'Active player' : 'Активный игрок'}</b>
            <div className={classes.dots} />
            <span>{drink}</span>
          </div>
        </div>
      )}

      {questionType === 'youDrink' && (
        <div className={classes.pointsHelperBlock}>
          <div>
            <b>{isEnLocale ? 'All players' : 'Все игроки'}</b>
            <div className={classes.dots} />
            <span>{drink}</span>
          </div>
        </div>
      )}
    </div>
  );
};

const RouletteTextContentEn: React.FC = () => {
  return (
    <>
      You get <b>1 point</b> every time when:
      <br />
      <br />
      <li>you are an active player</li>
      <li>you answer the same way as an active player</li>
      <li>you are the top voted player</li>
    </>
  );
};

const RouletteTextContentRu: React.FC = () => {
  return (
    <>
      Ты получаешь 1 очко каждый раз, когда:
      <br />
      <br />
      <li>ты активный игрок</li>
      <li>ты отвечаешь так же, как активный игрок</li>
      <li>за тебя проголосовало большее количество игроков</li>
    </>
  );
};

export { RouletteTextContentEn, RouletteTextContentRu, RouletteDrinkContent };

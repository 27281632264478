import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';
import stars from '@art/images/stars.svg';

export const styles = makeStyles((theme) => ({
  switcherContainer: {
    fontFamily: fontFamily,
    boxSizing: 'border-box',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '40px',
    },
  },

  switcherLang: {
    color: '#1B2036',
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '17px',
    textShadow: '0px 4px 12px rgba(3, 24, 55, 0.1)',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
  },

  switcherBar: {
    flexShrink: 0,
    borderRadius: '38px',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    marginBottom: 10,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '11px 11px',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 14,
      padding: '11px 20px',
    },
    '& .mode': {
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: 20,
      lineHeight: '28px',
      color: '#5F6372',
      padding: '5px 15px',
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('sm')]: {
        fontSize: 28,
        padding: '13px 30px',
      },
    },

    '& .regular': {
      background: 'linear-gradient(180deg, #9FEE3A 0%, #43AD17 100%)',
      color: 'white',
      border: '0.5px solid #000000',
      boxShadow: 'inset 0px -2px 0px rgba(0, 0, 0, 0.3)',
      borderRadius: '70px',
    },

    '& .superstar': {
      background: 'linear-gradient(180deg, #F7F709 0%, #FAA42C 100%)',
      color: 'white',
      border: '0.5px solid #000000',
      boxShadow: 'inset 0px -2px 0px rgba(0, 0, 0, 0.3)',
      borderRadius: '70px',
      '& .stars': {
        content: "''",
        backgroundImage: `url(${stars})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        width: '65px',
        height: '40px',
        top: '-19px',
        [theme.breakpoints.up('sm')]: {
          width: '90px',
          height: '60px',
          top: '-30px',
        },
        '&.starsEN': {
          right: '76px',
          [theme.breakpoints.up('sm')]: {
            right: '100px',
          },
        },
        '&.starsRU': {
          right: '57px',
          [theme.breakpoints.up('sm')]: {
            right: '78px',
          },
        },
      },
    },
  },
}));

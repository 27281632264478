import React, { useEffect, forwardRef } from 'react';
import Typography from '@material-ui/core/Typography';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectRoomPlayers } from '@store/room/roomSlice';
import { blueVolume, redVolume, blueVolumeDsk, redVolumeDsk } from './icons';
import { RoastBattleState, ROAST_BATTLE_STEP } from '@shared/gameInterfaces/roastBattle.socket';
import { TimerBig, TimerSmall } from '../Timer/Timer';
import { MUTE_TYPE, setAudioAccess } from '@store/reducers/playerData.reducer';
import { MapState } from 'games/RoastBattle/RoastBattle.types';
import { setVideoState } from '@store/reducers/common';
import { styles } from './styles';
import { useDeviceSize } from '@src/common/screenSizes';

// const DELAY = 3000;

interface IProps {
  game: RoastBattleState;
  state: MapState;
  isRoundPlayers: boolean;
  isActivePlayer: boolean;
  isPairPlayer: boolean;
}

interface IPlayerCardProps extends Omit<IProps, 'isActivePlayer' | 'isPairPlayer'> {
  answer: string;
  ref: any;
  playerId: string;
}

const PlayerContainer: React.FC<IProps> = ({ game, state, isRoundPlayers, isActivePlayer, isPairPlayer }) => {
  const classes = styles();
  const pairContainerRef = React.useRef<HTMLDivElement>(null);
  const activeContainerRef = React.useRef<HTMLDivElement>(null);
  const { activePlayerId, pairPlayerId, ticket } = game;
  const dispatch = useAppDispatch();
  const isNeedMute =
    (game.battleStep === ROAST_BATTLE_STEP.SPEECH_FIRST && isPairPlayer) ||
    (game.battleStep === ROAST_BATTLE_STEP.SPEECH_SECOND && isActivePlayer);

  const shouldEnable = isNeedMute ? false : undefined;

  useEffect(() => {
    dispatch(setAudioAccess(MUTE_TYPE.GAME, shouldEnable));

    return () => {
      dispatch(setAudioAccess(MUTE_TYPE.GAME, undefined));
    };
  }, [shouldEnable]);

  return (
    <div className={classes.containerStyles}>
      <PlayerCard
        playerId={activePlayerId}
        answer={ticket!.answers[0]}
        ref={activeContainerRef}
        isRoundPlayers={isRoundPlayers}
        state={state}
        game={game}
      />

      {game.battleStep == ROAST_BATTLE_STEP.PREPARE && (
        <TimerBig isActivePlayer={isActivePlayer} timer={game.timer!} game={game} />
      )}
      {game.battleStep == ROAST_BATTLE_STEP.COUNTDOWN_FIRST && (
        <TimerBig
          isActivePlayer={isActivePlayer}
          isPairPlayer={isPairPlayer}
          timer={game.timer!}
          game={game}
          showAlert={true}
        />
      )}
      {game.battleStep == ROAST_BATTLE_STEP.COUNTDOWN_SECOND && (
        <TimerBig
          isActivePlayer={isActivePlayer}
          isPairPlayer={isPairPlayer}
          timer={game.timer!}
          game={game}
          showAlert={true}
        />
      )}
      {game.battleStep == ROAST_BATTLE_STEP.SPEECH_FIRST && (
        <TimerBig
          isActivePlayer={isActivePlayer}
          isPairPlayer={isPairPlayer}
          timer={game.timer!}
          game={game}
          showAlert={true}
          alertThreshold={5}
        />
      )}
      {game.battleStep == ROAST_BATTLE_STEP.SPEECH_SECOND && state.BATTLE && (
        <TimerBig
          isActivePlayer={isActivePlayer}
          isPairPlayer={isPairPlayer}
          timer={game.timer!}
          game={game}
          showAlert={true}
          alertThreshold={5}
        />
      )}

      <PlayerCard
        playerId={pairPlayerId!}
        answer={ticket!.answers[1]}
        ref={pairContainerRef}
        isRoundPlayers={isRoundPlayers}
        state={state}
        game={game}
      />
    </div>
  );
};

const PlayerCard: React.FC<IPlayerCardProps> = forwardRef(function Card({ playerId, answer, state, game }, ref) {
  const dispatch = useAppDispatch();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const classes = styles();
  const { isDesktop } = useDeviceSize();
  const players = useAppSelector(selectRoomPlayers);
  const isRoomOwner = players[0].playerId === playerId;
  const videoContainer = document.getElementById(playerId!);
  const videoContainerOrigin = document.getElementById(`${playerId}_container`);
  const isActivePlayer = playerId === game.activePlayerId;
  const isPairPlayer = playerId === game.pairPlayerId;
  const volume = isActivePlayer ? blueVolume : redVolume;
  const volumeDsk = isActivePlayer ? blueVolumeDsk : redVolumeDsk;

  const isTimerShow =
    (game.battleStep === ROAST_BATTLE_STEP.SPEECH_FIRST && isActivePlayer) ||
    (game.battleStep === ROAST_BATTLE_STEP.SPEECH_SECOND && isPairPlayer)
      ? true
      : false;

  const isMuteiconShow =
    (game.battleStep === ROAST_BATTLE_STEP.SPEECH_FIRST && isPairPlayer) ||
    (game.battleStep === ROAST_BATTLE_STEP.SPEECH_SECOND && isActivePlayer)
      ? true
      : false;

  useEffect(() => {
    if (videoContainerOrigin) {
      //@ts-ignore
      videoContainerOrigin.firstChild.insertAdjacentHTML(
        'afterBegin',
        `<img src=${isDesktop ? volumeDsk : volume} class=${classes.containerVolume} />`
      );
    }

    if (videoContainer && containerRef.current) {
      containerRef.current.append(videoContainer);
      videoContainer.classList.add(`${classes.pairContainer}`);
      videoContainer.classList.remove(`${classes.normalContainer}`);
      //@ts-ignore
      videoContainer.style.zIndex = '4';
    }

    return () => {
      if (videoContainer) {
        videoContainer.classList.add(`${classes.normalContainer}`);
        videoContainer.classList.remove(`${classes.pairContainer}`);
        //@ts-ignore
        videoContainer.style.zIndex = '0';
      }

      if (videoContainerOrigin) {
        videoContainerOrigin.firstChild!.firstChild!.remove();
        //@ts-ignore
        //videoContainerOrigin.firstChild!.append(videoContainer);
        videoContainerOrigin.firstChild!.insertBefore(videoContainer, videoContainerOrigin.firstChild!.firstChild);

        dispatch(setVideoState({ playerId, data: { shouldRestart: {} } }));
      }
    };
  }, [videoContainerOrigin, videoContainer, isDesktop]);

  return (
    <div className={classes.personContainer} ref={containerRef}>
      {isRoomOwner && <div className={classes.crownIcon} />}

      {isTimerShow && <TimerSmall timer={game.timer!} alertThreshold={4} game={game} />}

      {state.BATTLE && isMuteiconShow && <div className={classes.muteIcon} />}

      <div className={classes.userAnswer}>
        <Typography className={classes.userAnswerText}>{answer}</Typography>
      </div>
    </div>
  );
});

export { PlayerContainer };

import { useGameHook } from './helpers/chests.hook';
import { GameTypes } from '@src/common/constants/common';
import GameWrapper from '@components/GameWrapper';
import { Leaderboard } from '@src/games/common';
import { ChestsHeader, StartRound, ChooseChest } from './parts';
import { styles } from './parts/styles';

type Props = {
  roomId: string;
};

const Chests: React.FC<Props> = ({ roomId }) => {
  const classes = styles();
  const { status, game, actions, playerId, players, isActivePlayer, isSpectator, balance } = useGameHook(roomId);
  const { onStart, onVote, onQuit } = actions;

  return (
    <div className={classes.chestsWrapper}>
      <ChestsHeader
        players={players}
        game={game}
        balance={balance}
        isActivePlayer={isActivePlayer}
        roundInProgress={status.ROUND_IN_PROGRESS}
      />

      <GameWrapper state={status} transparent={true}>
        {status.READY_START_ROUND && (
          <StartRound players={players} playerId={playerId} onStart={onStart} game={game} balance={balance} />
        )}

        {status.ROUND_IN_PROGRESS && (
          <ChooseChest playerId={playerId} isActivePlayer={isActivePlayer} game={game} onVote={onVote} />
        )}

        {status.LEADERBOARD && (
          <Leaderboard
            roomId={roomId}
            game={game}
            onClick={onQuit}
            isTopPlayers={false}
            isSpectator={isSpectator}
            gameType={GameTypes.CHESTS}
          />
        )}
      </GameWrapper>
    </div>
  );
};

export { Chests };

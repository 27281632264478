import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  resultsWrapper: {
    minHeight: '100%',
    width: '100%',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
      paddingBottom: 0,
    },
  },

  resultsWhiteContainer: {
    width: '100%',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '25px 15px 20px',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      padding: '20px 15px 15px 15px',
      marginBottom: '40px',
    },
    [theme.breakpoints.up('md')]: {
      padding: 0,
      backgroundColor: 'transparent',
    },
  },

  questionText: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '22px',
    color: '#1B2036',
    marginTop: 0,
    marginBottom: '40px',
    whiteSpace: 'pre-wrap',
    borderRadius: '20px',
    backgroundColor: 'white',
    padding: '8px 15px',
    wordBreak: 'break-word',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '32px',
      padding: '10px 15px',
    },
    [theme.breakpoints.up('md')]: {
      padding: 0,
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
  },

  questionButton: {
    position: 'fixed',
    right: '20px',
    bottom: '75px',
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
      bottom: '45px',
      right: '34px',
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      right: '30px',
      bottom: '45px',
    },
  },
}));

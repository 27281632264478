import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import CommonModal from '@components/Modals/CommonModal';
import MetamaskLogo from '@art/images/metamask.svg';
import Button from '@components/Button';
import { styles } from './styles';

type MetamaskPopupProps = {
  isOpen: boolean;
  handleClose: () => void;
};

const MetamaskPopup: React.FC<MetamaskPopupProps> = ({ isOpen, handleClose }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} backgroundColor={'white'}>
      {/* desktop browsers */}
      {!isMobile && (
        <div className={classes.metaMaskPopup}>
          <h2>{isEnLocale ? 'Install plugin' : 'Установите плагин'}</h2>

          <a className={classes.metamaskLogo} href="https://metamask.io/download/" target="_blank" rel="noreferrer">
            <img src={MetamaskLogo} alt="MetamaskLogo" />
          </a>

          <Button
            href="https://metamask.io/download/"
            text={isEnLocale ? 'Download plugin' : 'Скачать плагин'}
            onClick={handleClose}
          />
        </div>
      )}

      {/* android */}
      {isAndroid && (
        <div className={classes.metaMaskPopup}>
          <h2>{isEnLocale ? 'Install app' : 'Установите приложение'}</h2>

          <a
            className={classes.metamaskLogo}
            href="https://play.google.com/store/apps/details?id=io.metamask"
            target="_blank"
            rel="noreferrer"
          >
            <img src={MetamaskLogo} alt="MetamaskLogo" />
          </a>

          <Button
            href="https://play.google.com/store/apps/details?id=io.metamask"
            text={isEnLocale ? 'Install from Google Play' : 'Установить через Google Play'}
            onClick={handleClose}
          />
        </div>
      )}

      {/* ios */}
      {isIOS && (
        <div className={classes.metaMaskPopup}>
          <h2>{isEnLocale ? 'Install app' : 'Установите приложение'}</h2>

          <a
            className={classes.metamaskLogo}
            href="https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202"
            target="_blank"
            rel="noreferrer"
          >
            <img src={MetamaskLogo} alt="MetamaskLogo" />
          </a>

          <Button
            href="https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202"
            text={isEnLocale ? 'Install from App Store' : 'Установить через App Store'}
            onClick={handleClose}
          />
        </div>
      )}
    </CommonModal>
  );
};

export default MetamaskPopup;

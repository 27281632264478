import React, { useState } from 'react';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import CommonModal from '@components/Modals/CommonModal';
import { GameTypes } from '@src/common/constants/common';

// import texts
import {
  RouletteDrinkContent,
  RouletteTextContentRu,
  RouletteTextContentEn,
  RoastTextContentRu,
  RoastTextContentEn,
  TwentyQuestionsTextContentRu,
  TwentyQuestionsTextContentEn,
  QuizTextContentRu,
  QuizTextContentEn,
  QuizClassicTextContentRu,
  QuizClassicTextContentEn,
  GuessMeTextContentRu,
  GuessMeTextContentEn,
  LoveBottleTextContentRu,
  LoveBottleTextContentEn,
  DrawAndGuessTextContentRu,
  DrawAndGuessTextContentEn,
  RoastBattleTextContextRu,
  RoastBattleTextContextEn,
  ChestsTextContentRu,
  ChestsTextContentEn,
  FakeArtistTextContentRu,
  FakeArtistTextContentEn,
  HillbillyTextContentEn,
  HillbillyTextContentRu,
  HelpContentEn,
  HelpContentRu,
  AliasTextContentRu,
  AliasTextContentEn,
  GuessUpTextContentEn,
  GuessUpTextContentRu,
  FiveSecondsTextContentRu,
  FiveSecondsTextContentEn,
} from './textContent';

import { styles } from './styles';
import { MixProps } from '@src/common/generics';
import { getRules } from '../Modals/RulesModal/chooseRules';

interface Props {
  gray?: boolean;
  gameType: GameTypes | undefined;
  isShowResults?: boolean;
  questionType?: string;
  padding?: boolean;
  isLeaderboardHelp?: boolean;
}

const QuestionButton: React.FC<MixProps<Props>> = ({
  gray = false,
  isShowResults = false,
  questionType = '',
  padding = true,
  style,
  gameType,
  isLeaderboardHelp = true,
}) => {
  const classes = styles();
  const [isOpenQuestionModal, setQuestionModalIsOpen] = useState(false);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const getHelpRu = () => {
    switch (gameType) {
      case GameTypes.QUIZ_BATTLE:
        return <QuizTextContentRu />;
      case GameTypes.GUESS_ME:
        return <GuessMeTextContentRu />;
      case GameTypes.LOVE_BOTTLE:
        return <LoveBottleTextContentRu />;
      case GameTypes.ROAST_BATTLE:
        return <RoastBattleTextContextRu />;
      case GameTypes.DRAW_AND_GUESS:
        return <DrawAndGuessTextContentRu />;
      case GameTypes.ROAST:
        return <RoastTextContentRu />;
      case GameTypes.TWENTY_QUESTIONS:
        return <TwentyQuestionsTextContentRu />;
      case GameTypes.QUIZ_CLASSIC:
        return <QuizClassicTextContentRu />;
      case GameTypes.DRINK_ROULETTE:
        return isShowResults ? <RouletteDrinkContent questionType={questionType} /> : <RouletteTextContentRu />;
      case GameTypes.FAKE_ARTIST:
        return <FakeArtistTextContentRu />;
      case GameTypes.CHESTS:
        return <ChestsTextContentRu />;
      case GameTypes.HILLBILLY:
        return <HillbillyTextContentRu />;
      case GameTypes.HELP_SCREEN:
        return <HelpContentRu />;
      case GameTypes.ALIAS:
        return <AliasTextContentRu />;
      case GameTypes.GUESS_UP:
        return <GuessUpTextContentRu />;
      case GameTypes.FIVE_SECONDS:
        return <FiveSecondsTextContentRu />;
    }
  };

  const getHelpEn = () => {
    switch (gameType) {
      case GameTypes.QUIZ_BATTLE:
        return <QuizTextContentEn />;
      case GameTypes.GUESS_ME:
        return <GuessMeTextContentEn />;
      case GameTypes.LOVE_BOTTLE:
        return <LoveBottleTextContentEn />;
      case GameTypes.ROAST_BATTLE:
        return <RoastBattleTextContextEn />;
      case GameTypes.DRAW_AND_GUESS:
        return <DrawAndGuessTextContentEn />;
      case GameTypes.ROAST:
        return <RoastTextContentEn />;
      case GameTypes.TWENTY_QUESTIONS:
        return <TwentyQuestionsTextContentEn />;
      case GameTypes.QUIZ_CLASSIC:
        return <QuizClassicTextContentEn />;
      case GameTypes.DRINK_ROULETTE:
        return isShowResults ? <RouletteDrinkContent questionType={questionType} /> : <RouletteTextContentEn />;
      case GameTypes.FAKE_ARTIST:
        return <FakeArtistTextContentEn />;
      case GameTypes.CHESTS:
        return <ChestsTextContentEn />;
      case GameTypes.HILLBILLY:
        return <HillbillyTextContentEn />;
      case GameTypes.HELP_SCREEN:
        return <HelpContentEn />;
      case GameTypes.ALIAS:
        return <AliasTextContentEn />;
      case GameTypes.GUESS_UP:
        return <GuessUpTextContentEn />;
      case GameTypes.FIVE_SECONDS:
        return <FiveSecondsTextContentEn />;
    }
  };

  const getHelp = isEnLocale ? getHelpEn() : getHelpRu();
  const getRule = getRules(gameType);

  return (
    <>
      <div
        className={`${classes.questionButton} questionButton`}
        style={style}
        onClick={() => setQuestionModalIsOpen(true)}
      />

      {/* modal */}
      <CommonModal
        isOpen={isOpenQuestionModal}
        handleClose={() => setQuestionModalIsOpen(false)}
        // gray={gray}
        padding={padding}
        backgroundColor={gray ? '#F3F4F5' : ''}
      >
        <div className={classes.modalWrapper}>
          {!isShowResults && (
            <h3 id="transition-modal-title">{isEnLocale ? 'Leaderboard help' : 'Как начисляются очки'}</h3>
          )}
          {isLeaderboardHelp ? (
            <ul className={classes.list}>{getHelp}</ul>
          ) : (
            <ul className={classes.list}>{getRule}</ul>
          )}
        </div>
      </CommonModal>
    </>
  );
};

export default QuestionButton;

import { styles } from './styles';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import React, { ReactEventHandler, useState } from 'react';
import helloCoin from '@art/icons/coin_flat.svg';
import energyIcon from '@src/art/icons/StatusBar/energy.svg';
import Button from '@src/components/Button';
import { CostQuest, IPlayerQuest, LinkQuest, PlayQuest, QUEST_STATE, QUEST_TYPE } from '@src/shared/quests';
import { requestClaimQuestReward, requestProgressFrontendQuest } from '@src/graphql/player.queries';
import { useTitle } from './helpers';
import { selectPlayerId } from '@src/store/reducers/player.reducer';
import { MixProps } from '@src/common/generics';

interface IProps {
  questData: IPlayerQuest | PlayQuest | LinkQuest | CostQuest;
}

interface IPropsRewardPart extends IProps {
  setIsClaimed: (isClaimed: boolean) => void;
  isClaimed: boolean;
  isNotification?: boolean;
}

const Quest: React.FC<IProps> = ({ questData }) => {
  const dispatch = useAppDispatch();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();
  const type = questData.type;
  const [isClaimed, setIsClaimed] = useState(questData.state === QUEST_STATE.CLAIMED);
  const { getTitle } = useTitle(type, questData);

  return (
    <div className={classes.questWrapper}>
      {isClaimed && <div className={classes.receivedQuest} />}
      <div className={classes.wrapper}>
        <p className={'questTitle'}>{getTitle()}</p>
        <ProgressPartQuest questData={questData} />
      </div>
      <RewardPartQuest questData={questData} setIsClaimed={setIsClaimed} isClaimed={isClaimed} />
    </div>
  );
};

const ProgressPartQuest: React.FC<IProps> = ({ questData }) => {
  const classes = styles();
  const target = questData.target;
  const value = questData.value;

  return (
    <div className={classes.wrapper}>
      <div className={'progress'}>
        <div className={'fill'} style={{ width: `${(100 / target) * value}%` }} />
        <div className={'value'}>
          {value >= target ? Math.floor(target) : value} / {target}
        </div>
      </div>
    </div>
  );
};

const RewardPartQuest: React.FC<MixProps<IPropsRewardPart>> = ({
  questData,
  className,
  setIsClaimed,
  isClaimed,
  isNotification = false,
}) => {
  const dispatch = useAppDispatch();
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const playerId = useAppSelector(selectPlayerId);
  const type = questData.type;
  const { buttonText, link } = useTitle(type, questData);
  const showButton = [QUEST_STATE.DONE, QUEST_STATE.CLAIMED].includes(questData.state) || type === QUEST_TYPE.LINK;

  const onLinkClick = () => {
    requestProgressFrontendQuest(playerId, type, questData.id);
  };

  const onClaimClick = () => {
    dispatch(requestClaimQuestReward(questData));
    setIsClaimed(true);
  };

  const onClaim: ReactEventHandler = (e) => {
    type === QUEST_TYPE.LINK && questData.state !== QUEST_STATE.DONE ? onLinkClick() : onClaimClick();
    e.stopPropagation();
  };

  return (
    <div
      className={`${classes.wrapper} ${className}`}
      style={{ justifyContent: 'space-between', width: 'auto', display: 'flex' }}
    >
      <div className={`${classes.rewards} ${isNotification ? 'notification' : ''}`}>
        {(!questData.reward.coins || !questData.reward.energy) && <p>{isEnLocale ? 'Reward' : 'Награда'}</p>}
        {questData.reward.coins && (
          <div className={'coins'}>
            <img src={helloCoin} alt="coin" />
            <p>{questData.reward.coins}</p>
          </div>
        )}
        {questData.reward.energy && (
          <div className={'energy'}>
            <img src={energyIcon} alt="energy" />
            <p>{questData.reward.energy}</p>
          </div>
        )}
      </div>
      {showButton && (
        <Button
          type="primary-green-new"
          size="small"
          className={classes.button}
          text={isClaimed ? (isEnLocale ? 'Received' : 'Получено') : buttonText}
          onClick={onClaim}
          disabled={isClaimed}
          href={link ? `https://${link}` : ''}
        />
      )}
    </div>
  );
};

export { Quest, ProgressPartQuest, RewardPartQuest };

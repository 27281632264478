import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';

const FakeArtistRules: React.FC = () => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();

  return (
    <>
      {isEnLocale ? (
        <ul className={classes.list}>
          <li>All players except one are given a task for drawing - a secret word</li>
          <li>The one who was not issued is the Fake Artist</li>
          <li>The player draws one line per subround</li>
          <li>The task of the Artists is to determine who is the Fake Artist</li>
          <li>Fake&apos;s task is not to be discovered</li>
          <li>At any time in the game, players can vote for Fake Artist</li>
          <li>The result of the vote is determined by a simple majority</li>
          <li>If the Artists voted correctly, then they won</li>
          <li>If the artists did not find Fake, then Fake won</li>
        </ul>
      ) : (
        <ul className={classes.list}>
          <li>Всем игрокам, кроме одного, выдается задание для рисунка - секретное слово</li>
          <li>Тот, кому не выдали, тот Фэйк</li>
          <li>За ход игрок рисует одну линию</li>
          <li>Задача Художников - определить кто Фэйк</li>
          <li>Задача Фэйка - не выдать себя</li>
          <li>В любой момент игры игроки могут проголосовать за Фэйка</li>
          <li>Результат голосования определяется простым большинством</li>
          <li>Если художники проголосовали правильно - то они победили</li>
          <li>Если художники не нашли ФЭЙКА - то ФЭЙК победил</li>
        </ul>
      )}
    </>
  );
};

export { FakeArtistRules };

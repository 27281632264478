import { FC, ChangeEvent } from 'react';

import { styles } from './styles';

interface Props {
  id: string;
  value: any;
  checked: boolean;
  labelText: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const RadioCustom: FC<Props> = ({ id, value, checked, labelText, onChange }) => {
  const classes = styles();

  return (
    <div className={classes.radioCustom}>
      <input id={id} type="radio" value={value} checked={checked} onChange={onChange} hidden />
      <label className={classes.radioCustomLabel} htmlFor={id}>
        {labelText}
      </label>
    </div>
  );
};

export default RadioCustom;

import { Wallet } from '@project-serum/anchor';
import { Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { WalletContextState } from '@solana/wallet-adapter-react';
import {
  Connection,
  Keypair,
  PublicKey,
  sendAndConfirmTransaction,
  Signer,
  Transaction,
  TransactionInstruction,
} from '@solana/web3.js';
import { getToken, getTokenAccountAddress } from '@src/features/solana/engine/misc';
import { HIT_STRING_KEY, SOULS_IN_HIT } from './constants';

export const sendTokens = async (
  connection: Connection,
  token: string,
  target: string,
  amount: number,
  wallet: WalletContextState
) => {
  if (!wallet.publicKey) return '';

  const mintAddress = new PublicKey(token);
  const targetAddress = new PublicKey(target);

  const associatedSourceTokenAddress = await getTokenAccountAddress(mintAddress, wallet.publicKey);

  // Get the derived address of the destination wallet which will hold the custom token
  const associatedDestinationTokenAddr = await getTokenAccountAddress(mintAddress, targetAddress);
  const receiverAccount = await connection.getAccountInfo(associatedDestinationTokenAddr);

  const instructions: TransactionInstruction[] = [];

  if (receiverAccount === null || associatedSourceTokenAddress.equals(PublicKey.default)) {
    return '';
    /*instructions.push(
      Token.createAssociatedTokenAccountInstruction(
        mintToken.associatedProgramId,
        mintToken.programId,
        mintAddress,
        associatedDestinationTokenAddr,
        targetAddress,
        payer.publicKey
      )
    );*/
  }

  instructions.push(
    Token.createTransferInstruction(
      TOKEN_PROGRAM_ID,
      // wallet.publicKey,
      associatedSourceTokenAddress,
      associatedDestinationTokenAddr,
      wallet.publicKey,
      [],
      amount * SOULS_IN_HIT
    )
  );

  const transaction = new Transaction().add(...instructions);
  transaction.feePayer = wallet.publicKey;

  /*return sendAndConfirmTransaction(connection, transaction, [payer], {
    skipPreflight: true,
    commitment: 'singleGossip',
  });*/

  const transactionId = await wallet.sendTransaction(transaction, connection, {
    skipPreflight: true,
    preflightCommitment: 'singleGossip',
  });
  // wallet.signTransaction()

  return transactionId;
};

export const checkTransaction = async (connection: Connection, transactionId: string, attempt = 0) => {
  const transaction = await connection.getParsedTransaction(transactionId, 'finalized');

  console.log(transaction);
  if (transaction) {
    // @ts-ignore
    console.log(transaction.meta?.err, transaction.meta?.status?.Ok === null);
    return;
  } else {
    const NUMBER_OF_ATTEMPTS = 4;
    const RETRY_TIMEOUT = 5000;
    if (attempt < NUMBER_OF_ATTEMPTS) {
      setTimeout(() => checkTransaction(connection, transactionId, attempt + 1), RETRY_TIMEOUT);
    }
  }
};

/*const transaction = new Transaction().add(...instructions);
      transaction.feePayer = payer.publicKey;
      transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
      transaction.partialSign(payer);
      
      const transactionSignature = await connection.sendRawTransaction(
        transaction.serialize(),
        { skipPreflight: true }
      );
    
      await connection.confirmTransaction(transactionSignature, 'singleGossip');*/

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { loginThunk } from '@src/store/thunk/login.thunk';
import { CommonModal } from '@components/Modals';
import { TextInput } from '@components/Inputs';
import Button from '@components/Button';
import { styles } from './styles';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectLocalPlayerName, selectPlayerWeak } from '@src/store/reducers/player.reducer';
import { AUTH_TYPE } from '@src/shared/common';
import { getIsPlayerLoading } from '@src/store/reducers/loadActions';

interface Props {
  isOpen?: boolean;
  handleClose: () => void;
  onSubmit: () => void;
}

const getFormikData = (name: string | undefined, login: (name: string) => void) => ({
  initialValues: {
    userName: name || '',
  },
  onSubmit: (values: { userName: string }) => {
    login(values.userName);
  },
});

const LoginRenameModal: React.FC<Props> = ({ isOpen = true, handleClose, onSubmit }) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  //const [logging, setLogging] = useState(true);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const name = useAppSelector(selectLocalPlayerName);
  const isLogging = useAppSelector(getIsPlayerLoading);

  const login = (name?: string) => dispatch(loginThunk(name || '', onSubmit, () => {}, true, AUTH_TYPE.AUTH));
  const formik = useFormik(getFormikData(name, (name) => login(name)));

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} isFullscreen={true}>
      <div className={classes.wrapper}>
        <h3 id="transition-modal-title">{isEnLocale ? 'Log In' : 'Войдите'}</h3>
        <form onSubmit={formik.handleSubmit} id="enter-name-id" noValidate autoComplete="off">
          <div className={classes.formInner}>
            <TextInput
              onChange={formik.handleChange}
              value={formik.values.userName}
              id="userName"
              name="userName"
              label={isEnLocale ? 'User name' : 'Имя пользователя'}
              variant="outlined"
              type="text"
            />

            <Button
              text={isEnLocale ? 'Next' : 'Далее'}
              buttonType="submit"
              type="primary-blue-new"
              disabled={isLogging || !formik.values.userName}
            />
          </div>
        </form>
      </div>
    </CommonModal>
  );
};

export { LoginRenameModal };

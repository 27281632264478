import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  canvasMenu: {
    position: 'absolute',
    zIndex: 3,
    top: '50%',
    margin: 0,
    right: '12px',
    padding: 0,
    listStyle: 'none',
    marginTop: '-47px',
    width: '28px',
    minHeight: '94px',
    flexDirection: 'column',
    alignContent: 'flex-end',
    '& > li': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '28px',
      height: '28px',
      border: '1px solid #E8E9EB',
      borderRadius: '4px',
      marginBottom: '5px',
      cursor: 'pointer !important',
      backgroundColor: 'white',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      '& > div': {
        width: '14px',
        height: '14px',
        borderRadius: '50%',
      },
      '&.active': {
        border: '1px solid #2C74F3',
      },
      '&:hover': {
        cursor: 'pointer',
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
}));

import { IdType } from '../generics';
import { ITimer } from '../common';
import { BaseGameState } from '../socketEvents/socketEvents';

export enum QUESTION_CHALLENGE_STATE {
  QUESTION = 'QUESTION',
}

export interface QuestionChallengeState extends BaseGameState {
  gameState: QUESTION_CHALLENGE_STATE;
  question: string;
}

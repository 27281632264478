import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Game } from '@src/graphql/queries';
import { selectFriendlyBonus } from '@src/store/reducers/common';
import { selectRoomPlayers } from '@src/store/room/roomSlice';
import { UNITY_POPUP_STATE } from '@src/components/Modals/UnityModals/unityModals.types';
import { ENERGY_COST } from '@src/common/constants/system';
import { selectEnergyAmount } from '@src/store/reducers/player.reducer';

const useGameModal = (game: Game, shouldShow: boolean, reward?: number) => {
  const players = useAppSelector(selectRoomPlayers);
  const energyAmount = useAppSelector(selectEnergyAmount);
  const gameBalance = game.balance!;
  const friendBonusBase = useAppSelector(selectFriendlyBonus);
  const friendBonus = (players.length - 1) * friendBonusBase;
  const minBalance =
    reward !== undefined ? reward * gameBalance.multiplier * (1.0 + friendBonus) : gameBalance.minDailyBalance;
  const gameTreasury = gameBalance.dailyBalance;
  const hasGameTreasury = minBalance <= gameTreasury;
  const hasEnergy = energyAmount >= ENERGY_COST;
  const friendsBonusPercent = friendBonus * 100;
  const [lastPopupState, setLastPopupState] = useState(UNITY_POPUP_STATE.FULL);
  const [popupState, setPopupState] = useState(UNITY_POPUP_STATE.FULL);

  useEffect(() => {
    if (!shouldShow) return;

    const getCurrentPopupState = () => {
      if (!hasEnergy) return UNITY_POPUP_STATE.NO_ENERGY;
      if (!hasGameTreasury) return UNITY_POPUP_STATE.NO_TREASURY;
      return UNITY_POPUP_STATE.FULL;
    };

    const currentState = getCurrentPopupState();
    const lastState = lastPopupState;

    setLastPopupState(currentState);

    const showState =
      currentState === UNITY_POPUP_STATE.FULL || currentState !== lastState ? currentState : UNITY_POPUP_STATE.NO_POPUP;
    setPopupState(showState);
  }, [shouldShow, hasEnergy, hasGameTreasury]);

  return {
    gameTreasury,
    friendBonus,
    friendsBonusPercent,
    popupState,
  };
};

export { useGameModal };

import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import CommonModal from '@components/Modals/CommonModal';
import { styles } from './styles';
import { useDispatch } from 'react-redux';
import { selectBackgrounds } from '@src/store/room/roomFeaturesData.slice';
import check from '@art/icons/check.svg';
import { getRoomId, selectBackground } from '@src/store/room/roomSlice';
import Button from '@src/components/Button';
import CloseCross from '@src/components/CloseCross/CloseCross';
import { CLIENT_EVENTS } from '@src/shared/socketEvents/eventTypes';
import { selectPlayerId } from '@src/store/reducers/player.reducer';
import WSClient from '@src/sockets';

interface Props {
  isOpen: boolean | undefined;
  handleClose: () => void;
}

const RoomBackgroundModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const backgroundsList = useAppSelector(selectBackgrounds);
  const currentBackground = useAppSelector(selectBackground) || backgroundsList[0];
  const roomId = useAppSelector(getRoomId);
  const playerId = useAppSelector(selectPlayerId);
  const currentBackgroundIndex = backgroundsList.indexOf(currentBackground);
  const [backgroundIndex, setBackgroundIndex] = useState(currentBackgroundIndex);
  const [oldBackgroundIndex, setOldBackgroundIndex] = useState(currentBackgroundIndex);

  useEffect(() => {
    setOldBackgroundIndex(backgroundIndex);
  }, [currentBackground]);

  const renderBackgrounds = () =>
    backgroundsList.map((background, index) => {
      const { id, preview } = background; // backgroundColor, gradient, image,

      const addStyles = () => {
        // gradient
        if (preview?.includes('gradient')) {
          return { backgroundImage: preview };

          // image
        } else if (preview?.includes('http')) {
          return { backgroundImage: `url(${preview})` };

          // color
        } else if (preview?.includes('#')) {
          return { backgroundColor: preview, border: '1px solid #BBBCC3' };
        }
      };

      return (
        <div style={{ position: 'relative' }} key={index} onClick={() => setBackgroundIndex(index)}>
          <div
            key={id}
            style={addStyles()}
            className={`${classes.backgroundItem} ${backgroundIndex === index ? 'active' : ''}`}
            // onClick={() => setBackgroundIndex(index)}
          >
            {
              // background.price === 0 ?
              // (
              <div className="free">free</div>
              // )
              // : (
              // <div>
              //   {sticker.price}
              //   <img src={helloCoin} alt="coin" />
              // </div>
              // )
            }
          </div>
        </div>
      );
    });

  const onSaveBackgroundClick = () => {
    if (backgroundIndex) {
      WSClient.emit(CLIENT_EVENTS.PLAYER_UPDATED_ROOM, {
        backgroundId: backgroundsList[backgroundIndex].id,
      });
    }
    handleClose();
  };

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} padding={false} className={classes.optionsWrapper}>
      <CloseCross onClick={handleClose} style={{ top: '-40px', right: '5px' }} />
      <p className={classes.title}>{isEnLocale ? 'Game background' : 'Фон комнаты'}</p>
      <div className={classes.border} style={{ margin: '0px', background: '#5569AE' }}></div>
      <div className={classes.itemsContainer}>
        {backgroundsList.length ? renderBackgrounds() : isEnLocale ? 'No game backgrounds' : 'Нет фонов для игры'}
      </div>
      {backgroundIndex !== oldBackgroundIndex && (
        <Button
          type="primary-blue-new"
          text={isEnLocale ? 'Select' : 'Выбрать'}
          onClick={onSaveBackgroundClick}
          className={classes.optionsButton}
        />
      )}
    </CommonModal>
  );
};

export { RoomBackgroundModal };

import { AppDispatch } from '@src/app/store';
import { ROAST_BATTLE_STATE, RoastBattleState } from '@shared/gameInterfaces/roastBattle.socket';
import { updateGameState } from '@store/reducers/game.reducer';

enum LABELS {
  READY = 'Ready',
  ACTIVE = 'Active',
  VOTED = 'Voted',
  BATTLE = 'In battle',
}

const updateRoastBattleLabelsThunk = (game: RoastBattleState) => (dispatch: AppDispatch) => {
  const { gameState, readyPlayers, activePlayerId, pairPlayerId } = game;

  // ACTIVE
  const activeStates = [
    ROAST_BATTLE_STATE.READY_START_ROUND,
    ROAST_BATTLE_STATE.SPIN,
    // ROAST_BATTLE_STATE.BATTLE,
    ROAST_BATTLE_STATE.VOTE,
    ROAST_BATTLE_STATE.RESULTS,
  ].includes(gameState);

  // READY
  const readyStates = [ROAST_BATTLE_STATE.TOP_PLAYERS, ROAST_BATTLE_STATE.LEADERBOARD].includes(gameState);

  const labels = new Map<string, string>();

  // ACTIVE
  if (activeStates) {
    labels.set(activePlayerId, LABELS.ACTIVE);
  }

  // READY
  if (readyStates) {
    readyPlayers!.forEach((playerId) => labels.set(playerId, LABELS.READY));
  }

  // IN BATTLE
  const battleStates = ROAST_BATTLE_STATE.BATTLE.includes(gameState);

  if (battleStates) {
    labels.set(activePlayerId, LABELS.BATTLE);
    labels.set(pairPlayerId!, LABELS.BATTLE);
  }

  dispatch(updateGameState({ playerLabels: [...labels.entries()] }));
};

export { updateRoastBattleLabelsThunk };

import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { selectIsCoinPage } from '@store/reducers/popups';
import { CommonModal } from '@components/Modals';
import { AddFakePoints, CoinHeader } from './parts';
import { styles } from './styles';
import { useBlockchainData } from '@src/features/blockchain';
import CloseCross from '@src/components/CloseCross/CloseCross';
import { SendTab } from './parts/SendTab/index';
import { DepositTab } from './parts/DepositTab/index';
import { requestEnergy, requestRewards } from '@src/graphql/player.queries';
import { IS_DEBUG } from '@src/common/constants/system';
import { mutateGraphql } from '@src/library/fetch';
import { addEnergyDebug, addRewardDebug } from '@src/graphql/debugQueries';
import { selectPlayerId } from '@src/store/reducers/player.reducer';

export enum TABS {
  SEND,
  DEPOSIT,
}

interface IProps {
  handleClose: () => void;
}

export const CoinsPage: React.FC<IProps> = ({ handleClose }) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const { walletConnected, tokenBalance } = useBlockchainData();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const [activeTab, setActiveTab] = useState(TABS.SEND);
  const isCoinPage = useAppSelector(selectIsCoinPage);
  const playerId = useAppSelector(selectPlayerId);

  useEffect(() => {
    if (isCoinPage) dispatch(requestRewards);
  }, [isCoinPage]);

  const coinTabs = [
    { type: TABS.SEND, title: isEnLocale ? 'Send' : 'Отправить' },
    { type: TABS.DEPOSIT, title: isEnLocale ? 'Deposit' : 'Депозит' },
  ];

  const onAddRewardDebug = () => {
    mutateGraphql(addRewardDebug, { playerId, amount: 10 }).then((data) => {
      dispatch(requestRewards);
    });
  };

  const onAddEnergyDebug = () => {
    mutateGraphql(addEnergyDebug, { playerId, amount: 1, isBonus: true }).then((data) => {
      dispatch(requestEnergy);
    });
  };

  const onClaimEnergyDebug = () => {
    mutateGraphql(addEnergyDebug, { playerId, amount: -1, isBonus: true }).then((data) => {
      dispatch(requestEnergy);
    });
  };

  return (
    <CommonModal isOpen={isCoinPage} handleClose={handleClose} className="profile" padding={false}>
      <div className={classes.coinWrapper}>
        {IS_DEBUG && (
          <>
            <AddFakePoints show={true} onClick={onAddRewardDebug} style={{ top: '-70px' }}>
              + 10 tokens
            </AddFakePoints>

            <AddFakePoints show={true} onClick={onAddEnergyDebug} style={{ top: '-50px' }}>
              + 1 energy
            </AddFakePoints>

            <AddFakePoints show={true} onClick={onClaimEnergyDebug} style={{ top: '-30px' }}>
              - 1 energy
            </AddFakePoints>
          </>
        )}

        <CloseCross onClick={handleClose} style={{ top: '-45px', right: '5px', background: '#F5F3DF' }} />
        <CoinHeader tokenBalance={tokenBalance} walletConnected={walletConnected} />

        <div className={classes.coinTabs}>
          {coinTabs.map((tab) => (
            <p
              key={tab.type}
              className={`
                  ${activeTab === tab.type ? 'active' : ''}
                `}
              onClick={() => setActiveTab(tab.type)}
            >
              {tab.title}
            </p>
          ))}
        </div>

        {activeTab === TABS.SEND && <SendTab />}

        {activeTab === TABS.DEPOSIT && <DepositTab walletConnected={walletConnected} />}
      </div>
    </CommonModal>
  );
};

// export const CoinsPage = BlockchainWrapper(Coins);

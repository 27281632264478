import WSClient from '@src/sockets';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGameProvider } from './quizClassic.provider';
import { CLIENT_EVENTS } from '@shared/socketEvents/eventTypes';
import { PLAYER_STATE, selectRoomPlayers, updatePlayerState } from '@store/room/roomSlice';
import { PlayerStatus, PLAYER_STATUS } from '@src/common/constants/system';
import { updateQuizBattleLabelsThunk } from './quizClassic.labels';
import { sendRoundStarted } from '@services/analyticsThunk';
import { endGameEvent } from '@src/common/sendEvents';
import { useAppDispatch } from '@src/app/hooks';
import { GameTypes } from '@src/common/constants/common';

const useGameHook = (roomId: string) => {
  const dispatch = useAppDispatch();
  const roomPlayers = useSelector(selectRoomPlayers);
  const [state, game, playerId, storeActions, players, currentPlayer, isSpectator] = useGameProvider(roomId);
  const activeGameId = game.activeGameId;

  const socketData = {
    roomId,
    activeGameId,
    playerId,
    gameType: GameTypes.QUIZ_CLASSIC,
  };

  useEffect(() => {
    dispatch(updateQuizBattleLabelsThunk(game));
  }, [game]);

  useEffect(() => {
    if (state.READY_START_ROUND) {
      dispatch(sendRoundStarted(game.roundNumber.toString()));
    }
  }, [state.READY_START_ROUND]);

  return {
    state,
    game,
    playerId,
    players,
    currentPlayer,
    isSpectator,
    isActivePlayer: roomPlayers[0].playerId === playerId,
    actions: {
      onPlayerSpin: () => {
        WSClient.emit(CLIENT_EVENTS.ROUND_STARTED, socketData);
      },

      onPlayerVoted: (index: number) => {
        storeActions.setVote(index);
        WSClient.emit(CLIENT_EVENTS.PLAYER_VOTED, {
          ...socketData,
          value: index,
        });
      },

      onPlayerReady: () => {
        storeActions.setReady();
        WSClient.emit(CLIENT_EVENTS.PLAYER_READY, socketData);
      },

      onGameQuit: () => {
        dispatch(updatePlayerState(playerId, PLAYER_STATE.QUIT_READY));
        endGameEvent(roomId, playerId, GameTypes.QUIZ_CLASSIC);
      },
    },
  };
};

type HookType = ReturnType<typeof useGameHook>;
type ActionsType = HookType['actions'];

export { useGameHook };
export type { ActionsType };

import { answerStyles } from './styles';
import back from '@art/icons/backArrowBlack.svg';
import coin from '@art/icons/coin.svg';
import withdraw from '@art/images/withdraw.png';

interface Props {
  onClick?: () => void;
}

const Answer: React.FC<Props> = ({ onClick, children }) => {
  const classes = answerStyles();

  return (
    <div className={classes.helpContainer}>
      <Back onClick={onClick} />
      <div className={classes.content}>{children}</div>
    </div>
  );
};

const Back: React.FC<Props> = ({ onClick }) => {
  const classes = answerStyles();

  return (
    <div className={classes.back} onClick={onClick}>
      <img src={back} alt="" />
      Back to help
    </div>
  );
};

const WhatIsHelloToken: React.FC = () => {
  const classes = answerStyles();

  return (
    <>
      <div className={classes.question}>
        <img className={classes.image} src={coin} alt="" />
        What is a Hello Token?
      </div>
      <p className={classes.text}>
        <b> Lorem ipsum dolor sit amet</b>Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Purus faucibus ornare suspendisse sed. Mattis enim ut tellus elementum sagittis vitae et leo. Ultrices
        in iaculis nunc sed augue. Leo in vitae turpis massa sed. Ipsum dolor sit amet consectetur adipiscing elit
        pellentesque habitant morbi. Non odio euismod lacinia at quis.
      </p>
    </>
  );
};

const HowWithdraw: React.FC = () => {
  const classes = answerStyles();

  return (
    <>
      <div className={classes.question}>How do I withdraw my funds from Hello?</div>
      <p className={classes.text} style={{ marginBottom: '18px' }}>
        <b> Lorem ipsum dolor sit amet</b>Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Purus faucibus ornare suspendisse sed. Mattis enim ut tellus elementum sagittis vitae et leo. Ultrices
        in iaculis nunc sed augue. Leo in vitae turpis massa sed. Ipsum dolor sit amet consectetur adipiscing elit
        pellentesque habitant morbi. Non odio euismod lacinia at quis.
      </p>
      <p className={classes.text} style={{ marginBottom: '10px' }}>
        <b> Lorem ipsum dolor sit amet</b>Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Purus faucibus ornare suspendisse sed. Mattis enim ut tellus elementum sagittis vitae et leo. Ultrices
        in iaculis nunc sed augue. Leo in vitae turpis massa sed. Ipsum dolor sit amet consectetur adipiscing elit
        pellentesque habitant morbi. Non odio euismod lacinia at quis.
      </p>
      <img src={withdraw} alt="" />
    </>
  );
};

const Security: React.FC = () => {
  const classes = answerStyles();

  return (
    <>
      <div className={classes.question}>Safety and Privacy policy</div>
      <p className={classes.text} style={{ marginBottom: '18px' }}>
        <b> Lorem ipsum dolor sit amet</b>Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Purus faucibus ornare suspendisse sed. Mattis enim ut tellus elementum sagittis vitae et leo. Ultrices
        in iaculis nunc sed augue. Leo in vitae turpis massa sed. Ipsum dolor sit amet consectetur adipiscing elit
        pellentesque habitant morbi. Non odio euismod lacinia at quis.
      </p>
    </>
  );
};

export { WhatIsHelloToken, HowWithdraw, Security, Answer };

import React, { MouseEventHandler } from 'react';
import { CommonModal } from '@components/Modals';
import Button from '@components/Button';
import { styles } from './styles';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';

interface Props {
  isOpen: boolean | undefined;
  handleClose: MouseEventHandler;
}

const PauseModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} backgroundColor={'white'}>
      <div className={classes.wrapper}>
        <h3 id="transition-modal-title">{isEnLocale ? 'Pause' : 'Пауза'}</h3>
        <p>
          {isEnLocale ? 'The game is paused and you skip this round.' : 'Игра приостановлена, вы пропустите этот раунд'}
          <br />
          {isEnLocale ? 'All other players keep playing.' : 'Все остальные игроки продолжают играть.'}
        </p>

        <Button
          className={classes.button}
          text={isEnLocale ? 'Back to Game' : 'Вернуться к игре'}
          onClick={handleClose}
          type="primary-blue-new"
        />
      </div>
    </CommonModal>
  );
};

export default PauseModal;

import { AppDispatch, AppGetState, RootState } from '@src/app/store';
import {
  MUTE_TYPE,
  selectIsAudioRestricted,
  selectIsVideoRestricted,
  setAudioAccess,
  setVideoAccess,
} from '@store/reducers/playerData.reducer';

export const requestAudioAccess = (shouldEnable: boolean) => (dispatch: AppDispatch, getState: AppGetState) => {
  const isAudioRestricted = selectIsAudioRestricted(getState());
  if (!isAudioRestricted) {
    dispatch(setAudioAccess(MUTE_TYPE.OWN, shouldEnable));
  }
};

export const requestVideoAccess = (shouldEnable: boolean) => (dispatch: AppDispatch, getState: AppGetState) => {
  const isVideoRestricted = selectIsVideoRestricted(getState());
  if (!isVideoRestricted) {
    dispatch(setVideoAccess(MUTE_TYPE.OWN, shouldEnable));
  }
};

export const muteAll = () => (dispatch: AppDispatch, getState: AppGetState) => {
  dispatch(setAudioAccess(MUTE_TYPE.OWN, false));
  dispatch(setVideoAccess(MUTE_TYPE.OWN, false));
};

import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  metaMaskPopup: {
    fontFamily,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',

    '& > h2': {
      marginTop: 0,
    },
  },

  metamaskLogo: {
    marginBottom: '30px',
  },
}));

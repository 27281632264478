import { fontFamily } from '@src/art/theme/textStyles';
import { makeStyles } from '@material-ui/core';

export const helpStyles = makeStyles((theme) => ({
  helpQuestionContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    background: '#F3F4F5',
    width: '100%',
    '&:not(last-child)': {
      borderBottom: '1px solid #BBBCC3',
    },
  },
  image: {
    width: '40px',
    height: '40px',
    marginRight: '10px',
  },
  question: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#1B2036',
    margin: 0,
  },
  back: {
    marginLeft: 'auto',
    width: '8px',
    height: '14px',
    cursor: 'pointer',
  },
  viewAllButton: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#2C74F3',
    border: 0,
    background: 'none',
    outline: 'none',
    cursor: 'pointer',
    flexShrink: 0,
    padding: 0,
    display: 'contents',
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
    '&:hover': {
      background: 'none !important',
    },
  },
}));

export const answerStyles = makeStyles((theme) => ({
  helpContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRadius: '20px',
    background: '#F3F4F5',
  },

  back: {
    display: 'flex',
    padding: '17px 15px',
    borderRadius: '20px 20px 0px 0px',
    fontFamily: fontFamily,
    justifyContent: 'start',
    textAlign: 'center',
    color: 'black',
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '17px',
    width: '100%',
    alignItems: 'center',
    borderBottom: '1px solid #E8E9EB',
    background: 'white',
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      padding: '17px 25px',
    },
    '& > img': {
      marginRight: '15px',
      width: '18px',
      height: '14px',
    },
  },
  image: {
    width: '30px',
    height: '30px',
    marginRight: '5px',
  },
  question: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#1B2036',
    margin: 0,
    width: '100%',
    marginBottom: '15px',
    textAlign: 'center',
  },
  content: {
    padding: '73px 15px 15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '20px',
    [theme.breakpoints.up('sm')]: {
      padding: '73px 25px 20px',
    },
  },
  text: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '21px',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#1B2036',
    margin: 0,
    width: '100%',
    '& > b': {
      marginBottom: '5px',
      fontWeight: 600,
      display: 'block',
    },
  },
}));

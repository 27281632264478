import { MS_IN_MINUTE } from './constants/system';
import { useState, useEffect } from 'react';
import { selectPlayerId } from '@store/reducers/player.reducer';
import { GameTypes, getMinimumPlayers } from '@src/common/constants/common';
import { analytics } from '@services/amplitude';
import { getRoomId, ROOM_STATE, selectRoom, setRoomIsPublic } from '@store/room/roomSlice';
import { AppDispatch, AppGetState } from '@app/store';
import { useAppSelector, useAppDispatch } from '@src/app/hooks';
import { setInviteShown, setInviteShownOnce } from '@store/reducers/popups';
import { LOCALE } from '@shared/common';
import { FACEBOOK_EVENT, sendFacebookEvent } from '@services/facebookPixel';
import { selectPlayerIdWeak } from '@src/store/reducers/player.reducer';
import WSClient from '@src/sockets';
import { CLIENT_EVENTS } from '@src/shared/socketEvents/eventTypes';
import { pushNotification } from '@src/store/room/roomFeaturesData.slice';
import { NOTIFICATION_TYPE } from '@src/store/room/notifications';
import { QUEST_TYPE } from '@src/shared/quests';
import { requestProgressInviteQuest } from '@src/graphql/player.queries';

export const getHomepageRoute = () => {
  const roomId = useAppSelector(getRoomId);
  return roomId ? `/room/${roomId}/lobby` : '';
};

export const shouldShowPlayButton = (gameType: GameTypes) => {
  const roomState = useAppSelector(selectRoom);
  const playerId = useAppSelector(selectPlayerIdWeak);

  if (roomState.state !== ROOM_STATE.LOBBY) {
    return false;
  }

  const MINIMUM_PLAYERS = getMinimumPlayers(gameType);

  if (roomState.players.length < MINIMUM_PLAYERS) {
    return false;
  }

  return roomState.players[0].playerId === playerId;
};

export const copyLinkToClipboard = (link: string, from: string) => (dispatch: AppDispatch, getState: AppGetState) => {
  navigator.clipboard.writeText(link);
  dispatch(requestProgressInviteQuest);
  analytics.logEvent(analytics.EVENTS.ROOM_LINK_COPIED, { from });
  sendFacebookEvent(FACEBOOK_EVENT.ROOM_LINK_COPIED);
};

export const showInviteThunk = (from: string) => (dispatch: AppDispatch) => {
  dispatch(setInviteShown(true));
  dispatch(setInviteShownOnce(true));
  analytics.logEvent(analytics.EVENTS.INVITE_CLICKED, { from });
};

export const getStoredValue = <T>(valueName: string) => localStorage.getItem(valueName) as T | null;

export const getDefaultStoredLocale = () => getStoredValue<LOCALE>('funnchat_locale');
export const getDefaultLocale = () =>
  getDefaultStoredLocale() || (navigator.languages.includes('ru-RU' || 'ru') ? LOCALE.RU : LOCALE.EN);

export const roundNumbers = (number: number | undefined) => {
  if (number === undefined) {
    return '-';
  } else if (number > 999499999) {
    return `∞`;
  } else if (number > 999499) {
    return `${String(Math.round(number / 1000000))}M`;
  } else if (number > 9999) {
    return `${String(Math.round(number / 1000))}K`;
  } else return Math.round(number);
};

export const useResetTimer = (lastClaimDate: number) => {
  const [claimDate, setClaimDate] = useState('');

  useEffect(() => {
    const timerUpdate = () => {
      const leftMinutes = Math.max(getLeftTimeCeil(lastClaimDate), 0);
      if (leftMinutes > 60) {
        const hour = Math.trunc(leftMinutes / 60);
        const minutes = leftMinutes - hour * 60;
        setClaimDate(`${hour}h ${minutes}m`);
      } else {
        setClaimDate(`${leftMinutes}m`);
      }
    };

    timerUpdate();
    const intervalId = setInterval(() => timerUpdate(), MS_IN_MINUTE);
    return () => clearInterval(intervalId);
  }, [lastClaimDate]);

  return claimDate;
};

export const useChangeRoomPublicity = (isPublic: boolean) => {
  const dispatch = useAppDispatch();
  const roomId = useAppSelector(getRoomId);
  const playerId = useAppSelector(selectPlayerId);

  const onChangeRoomPublicity = () => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_CHANGED_ROOM_PUBLICITY, { roomId, playerId: playerId, value: !isPublic });
    dispatch(setRoomIsPublic(!isPublic));
    dispatch(pushNotification({ type: NOTIFICATION_TYPE.PUBLICITY }));
  };

  return { onChangeRoomPublicity };
};

export const getLeftTimeCeil = (end: number) => Math.ceil((end - Date.now()) / MS_IN_MINUTE);

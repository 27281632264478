import { gameListStart, gameListFinish, roomsLoadStart, roomsLoadFinish } from '@store/reducers/loadActions';
import { hostsLoadStart, hostsLoadFinish } from '@store/reducers/loadActions';
import { homePageLoadFinish, homePageLoadStart } from '@store/reducers/loadActions';
import {
  setGamesArray,
  setHosts,
  setRooms,
  setBanners,
  setTopSetConfig,
  setBlockchainConfig,
  setDrawConfig,
  setGameBalance,
  setRewardsConfig,
  setGameBalances,
  setResetDataConfig,
  setStreamers,
  IPlayerCard,
} from '@store/reducers/common';
import {
  gamesQuery,
  hostsQuery,
  roomsQuery,
  bannersQuery,
  topSetConfigQuery,
  GamesQueryParams,
  GamesQueryResponse,
  RoomsQueryParams,
  RoomsQueryResponse,
  HostsQueryParams,
  HostsQueryResponse,
  BannersQueryParams,
  BannersQueryResponse,
  TopSetConfigQueryParams,
  TopSetConfigQueryResponse,
  ConfigurationQueryParams,
  ConfigurationQueryResponse,
  configurationQuery,
  StickersQueryParams,
  StickersQueryResponse,
  stickersQuery,
  BackgroundsQueryParams,
  BackgroundsQueryResponse,
  backgroundsQuery,
  AvatarsQueryParams,
  AvatarsQueryResponse,
  avatarsQuery,
  GameBalanceQueryParams,
  GameBalanceQueryResponse,
  gameBalanceQuery,
  GameBalancesQueryParams,
  GameBalancesQueryResponse,
  gameBalancesQuery,
  IStreamer,
} from '@graphql/queries';
import { setLocale } from '@store/reducers/session.reducer';
import { AppDispatch, AppGetState } from '@app/store';
import { getDefaultStoredLocale } from '@common/misc';
import { setAvatars, setBackgrounds, setStickers, setRoomIcons } from '@store/room/roomFeaturesData.slice';
import { fetchGraphql } from '@src/library/fetch';
import { selectGameId } from '@src/store/room/roomSlice';

export const loadGameBalance = (dispatch: AppDispatch, getState: AppGetState) => {
  const gameId = selectGameId(getState());

  return fetchGraphql<GameBalanceQueryParams, GameBalanceQueryResponse>(gameBalanceQuery, { gameId }, true).then(
    (data) => {
      dispatch(setGameBalance({ gameId, dailyBalance: data.game.balance.dailyBalance }));
    }
  );
};

export const requestGameBalances = (dispatch: AppDispatch, getState: AppGetState) => {
  return fetchGraphql<GameBalancesQueryParams, GameBalancesQueryResponse>(gameBalancesQuery, {}, true).then((data) => {
    dispatch(setGameBalances(data.games));
  });
};

const convertStreamer = (streamer: IStreamer): IPlayerCard => ({
  playerId: streamer.playerId,
  name: streamer.name,
  avatar: { image: streamer.avatar },
  isStreamer: true,
});

const loadHomePageData = (dispatch: AppDispatch, getState: AppGetState) => {
  dispatch(homePageLoadStart());

  dispatch(roomsLoadStart());
  dispatch(gameListStart());
  dispatch(hostsLoadStart());

  const queries = [
    fetchGraphql<GamesQueryParams, GamesQueryResponse>(gamesQuery, {}, true).then((data) => {
      dispatch(setGamesArray(data.games));
      dispatch(gameListFinish());
    }),

    fetchGraphql<HostsQueryParams, HostsQueryResponse>(hostsQuery, {}).then((data) => {
      dispatch(setHosts(data.hosts));
      dispatch(hostsLoadFinish());
    }),

    /*fetchGraphql<CreatorsQueryParams, CreatorsQueryResponse>(creatorsQuery, {}).then((data) => {
      dispatch(setCreators(data.creators));
    }),*/

    fetchGraphql<RoomsQueryParams, RoomsQueryResponse>(roomsQuery, {}, true).then((data) => {
      dispatch(setRooms(data.activeRooms));
      dispatch(roomsLoadFinish());
    }),

    fetchGraphql<BannersQueryParams, BannersQueryResponse>(bannersQuery, {})
      .then((data) => {
        dispatch(setBanners(data.banners));
      })
      .catch((reason) => {
        console.log(reason);
      }),

    fetchGraphql<StickersQueryParams, StickersQueryResponse>(stickersQuery, {}).then((data) => {
      dispatch(setStickers(data.stickers || []));
    }),

    fetchGraphql<AvatarsQueryParams, AvatarsQueryResponse>(avatarsQuery, {}).then((data) => {
      dispatch(setAvatars(data.avatars || []));
    }),

    fetchGraphql<BackgroundsQueryParams, BackgroundsQueryResponse>(backgroundsQuery, {}).then((data) => {
      dispatch(setBackgrounds(data.backgrounds || []));
    }),

    fetchGraphql<TopSetConfigQueryParams, TopSetConfigQueryResponse>(topSetConfigQuery, {})
      .then((data) => {
        dispatch(setTopSetConfig(data.topSetConfig));
      })
      .catch((reason) => {
        console.log(reason);
      }),

    fetchGraphql<ConfigurationQueryParams, ConfigurationQueryResponse>(configurationQuery, {}).then((data) => {
      if (data.configuration) {
        if (!getDefaultStoredLocale()) {
          dispatch(setLocale(data.configuration.defaultLocale));
        }
        dispatch(setBlockchainConfig(data.configuration.blockchain));
        dispatch(setDrawConfig(data.configuration.draw));
        dispatch(setRoomIcons(data.configuration.roomIcons || []));
        dispatch(setRewardsConfig(data.configuration.rewards));
        dispatch(setStreamers(data.configuration.streamers.map((streamer) => convertStreamer(streamer))));
        dispatch(setResetDataConfig(data.configuration.resetDate));
      }
    }),
  ];

  Promise.all(queries).then(() => dispatch(homePageLoadFinish()));
};

export { loadHomePageData };

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import WSClient from '@src/sockets';
import { simpleMerge } from '@src/common/generics';
import { useAppSelector } from '@app/hooks';
import { selectPlayerId } from '@store/reducers/player.reducer';
import { IRoomPlayer, PLAYER_STATE, selectRoomPlayers } from '@store/room/roomSlice';
import { defaultGameState, initialMapState } from './DrawAndGuess.types';
import { DrawAndGuessState } from '@shared/gameInterfaces/drawAndGuess.socket';
import { CLIENT_EVENTS, SERVER_EVENTS } from '@shared/socketEvents/eventTypes';
import { PLAYER_STATUS } from '@src/common/constants/system';
import { Observable } from '@apollo/client';
import { sendDataToServer } from '@components/VideoChat/parts/helperMethods';
import { endGameEvent } from '@src/common/sendEvents';
import { GameTypes } from '@src/common/constants/common';
import { DrawEvent } from '@src/shared/drawData';

export const useGameProvider = (roomId: string) => {
  const playerId = useAppSelector(selectPlayerId);
  const players = useSelector(selectRoomPlayers);
  const [state, setState] = useState<DrawAndGuessState>(defaultGameState);
  const stateMatcher = { ...initialMapState, [state.gameState]: true };
  const [eventObserver, setEventObserver] = useState<Observable<DrawEvent>>();
  const isActivePlayer = state.activePlayerId === playerId;
  const activePlayer = players.find((player) => player.playerId === state.activePlayerId) || ({} as IRoomPlayer);
  const currentPlayer = players.find((player) => player.playerId === playerId) || ({} as IRoomPlayer);
  const isSpectator = currentPlayer ? currentPlayer.state !== PLAYER_STATE.GAME : true;
  const activeGameId = state.activeGameId;
  const socketData = {
    roomId,
    activeGameId,
    playerId,
    gameType: GameTypes.DRAW_AND_GUESS,
  };

  useEffect(() => {
    const observable = new Observable<DrawEvent>((observer) => {
      // magic
      const handleWebsocketUpdate = ({ data }: { data: DrawEvent }) => {
        // avoid networks update on current draw if player active
        // console.log('WS update', data);
        observer.next(data);
      };
      WSClient.on(SERVER_EVENTS.GAME_STATE_UPDATED_DEFAULT, handleWebsocketUpdate);
      WSClient.on(SERVER_EVENTS.GAME_DRAW, handleWebsocketUpdate);

      return () => {
        WSClient.off(SERVER_EVENTS.GAME_DRAW, handleWebsocketUpdate);
        WSClient.off(SERVER_EVENTS.GAME_STATE_UPDATED_DEFAULT, handleWebsocketUpdate);
      };
    });

    setEventObserver(observable);

    const updateState = ({ data }: any) => setState(simpleMerge(defaultGameState, data.drawAndGuess));

    WSClient.on(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    WSClient.on(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);

    WSClient.emit(CLIENT_EVENTS.GAME_STATE_REQUESTED, { roomId });

    return () => {
      // remove unused events!!!!
      WSClient.off(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);
      WSClient.off(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    };
  }, []);

  const onSpin = () => {
    WSClient.emit(CLIENT_EVENTS.ROUND_STARTED, socketData);
  };

  const onChoose = (index: number) => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_VOTED, {
      roomId,
      activeGameId: state.activeGameId,
      playerId,
      value: index,
    });
  };

  const onVote = (value: string | undefined) => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_VOTED, {
      roomId,
      activeGameId: state.activeGameId,
      playerId,
      value,
    });
  };

  const onReady = () => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_READY, {
      roomId,
      activeGameId: state.activeGameId,
      playerId,
    });
  };

  const onQuit = () => {
    endGameEvent(roomId, playerId, GameTypes.DRAW_AND_GUESS);
  };

  const onDrawUpdate = (data: DrawEvent) => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_DREW, {
      state: data,
      roomId,
      activeGameId: state.activeGameId,
      playerId,
    });
  };

  const onLiveUpdate = (data: DrawEvent) => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_UPDATED_GAME_STATE, {
      state: data,
      roomId,
      activeGameId: state.activeGameId,
      playerId,
    });
  };

  const onGameStateRequest = (totalLines: number) => {
    WSClient.emit(CLIENT_EVENTS.GAME_STATE_REQUESTED, { roomId });
    sendDataToServer(playerId, `Wrong lines number: ${totalLines}`);
  };

  return [
    // state
    { ...state, state: stateMatcher },

    isActivePlayer,
    activePlayer,

    // actions
    {
      onSpin,
      onChoose,
      onVote,
      onReady,
      onQuit,
      onDrawUpdate,
      onLiveUpdate,
      onGameStateRequest,
    },
    eventObserver,

    // from redux
    playerId,
    isSpectator,
  ] as const;
};

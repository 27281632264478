import React, { useEffect } from 'react';
import { BannerInfo } from '@shared/misc/bannerInfo';
import { useBannerInfo } from '@src/features/banner.logics';
import { SimpleBanner } from './parts';
import { styles } from './styles';

interface HeroBannerProps {
  bannerInfos: BannerInfo[];
  isRoomGameIntro?: boolean;
  onLoaded?: (height: number) => void;
  onClick?: () => void;
}

const HeroBanner: React.FC<HeroBannerProps> = (props) => {
  const classes = styles();
  const bannerInfo = useBannerInfo(props.bannerInfos);

  useEffect(() => {
    if (!bannerInfo) {
      props.onLoaded?.apply(null, [-1]);
    }
  }, [bannerInfo]);

  if (!bannerInfo) return <></>;

  return (
    <div className={classes.heroFlexer}>
      <SimpleBanner
        bannerInfo={bannerInfo}
        onLoaded={props.onLoaded}
        isRoomGameIntro={props.isRoomGameIntro}
        onClick={props.onClick}
      />
    </div>
  );
};

export { HeroBanner, SimpleBanner };

import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

const DrinkRouletteRules: React.FC = () => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();

  return (
    <>
      {isEnLocale ? (
        <ul className={classes.list}>
          <li>
            You <b>need a drink</b> to play the game
          </li>
          <li>An active player spins the roulette and reads the question out loud</li>
          <li>Everyone answers the question by voting</li>
          <li>See voting results and discuss who answered how and why</li>
          <li>Players listed in “Who drinks” section should have one drink</li>
        </ul>
      ) : (
        <ul className={classes.list}>
          <li>Для этой игры нужна выпивка</li>
          <li>Активный игрок крутит рулетку и читает вопрос вслух</li>
          <li>Все отвечают на вопрос голосованием</li>
          <li>Посмотрите результаты голосования и обсудите, кто как ответил и почему</li>
          <li>Игроки, перечисленные в разделе «Кто пьёт», должны выпить один раз</li>
        </ul>
      )}
    </>
  );
};

export { DrinkRouletteRules };

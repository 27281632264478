import React from 'react';
import BlueButton from '@components/MaterialRewrite/BlueButton';
import { RoastBattleState, ROAST_BATTLE_STATE } from '@shared/gameInterfaces/roastBattle.socket';
import { LineTimer } from '@src/games/common';
import { styles } from './styles';
import { useDeviceSize } from '@src/common/screenSizes';

interface IProps {
  game: RoastBattleState;
  onVote: any;
  isRoundPlayers: boolean;
  playerId: string;
  isSpectator: boolean;
}

const Answers: React.FC<IProps> = ({ onVote, game, isRoundPlayers, playerId, isSpectator }) => {
  const classes = styles();
  const { ticket, activePlayerId, pairPlayerId, votes } = game;

  const voteIndex = votes!.findIndex((vote) => vote.players.includes(playerId));
  const isAnswered = voteIndex != -1;
  const isDisabled = isAnswered || isRoundPlayers || isSpectator;
  const { isDesktop } = useDeviceSize();

  const lightBackgroundColor = '#D7E4FD';
  const transparentBackgroundColor = 'rgba(215, 228, 253, 0.1)';
  const darkTextColor = '#1B2036';
  const lightTextColor = '#5F6372';

  return (
    <div className={classes.questionButtons}>
      <div className={classes.questionButtonsWrapper}>
        {ticket?.answers.map((answer, index) => {
          const isChosen = index === voteIndex;
          const backgroundColor = isChosen ? lightBackgroundColor : transparentBackgroundColor;
          const color = isChosen ? darkTextColor : lightTextColor;
          const votedPlayerId = index === 0 ? activePlayerId : pairPlayerId;

          return (
            <BlueButton
              className={classes.questionCardAnswers}
              key={index}
              style={{
                backgroundColor: isAnswered ? backgroundColor : 'none',
                color: isAnswered ? color : 'none',
              }}
              onClick={() => onVote(votedPlayerId)}
              disabled={isDisabled}
            >
              {answer}
            </BlueButton>
          );
        })}
      </div>

      {game.gameState === ROAST_BATTLE_STATE.VOTE && !isRoundPlayers && game.timer?.duration && isDesktop && (
        <div className={classes.timerWrapper}>
          <LineTimer timer={game.timer!} isVote={isAnswered} />
        </div>
      )}
    </div>
  );
};

export { Answers };

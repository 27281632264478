import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  singleQuestion: {
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: '15px',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
    [theme.breakpoints.up('md')]: {
      // padding: '30px',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignContent: 'baseline',
    },
  },

  timerWrapper: {
    order: 1,
    width: '100%',
    minHeight: '30px',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      order: 5,
      maxWidth: '620px',
      marginBottom: 0,
      marginTop: '10px',
    },
  },

  questionText: {
    order: 2,
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: '10px',
    backgroundColor: '#fff',
    borderRadius: '20px',
    padding: '15px',
    wordBreak: 'break-word',
    // flexGrow: 1,
    '& > span': {
      color: 'white',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      marginBottom: '7px',
      padding: '4px 10px',
      borderRadius: '20px',
      backgroundImage: 'linear-gradient(77.61deg, #7B61FF 0%, #9146FF 51.56%, #7B61FF 100%)',
      alignSelf: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '24px',
        marginBottom: '14px',
        padding: '6px 15px',
      },
      [theme.breakpoints.up('md')]: {
        color: '#5F6372',
        padding: 0,
        backgroundImage: 'none',
        borderRadius: 0,
      },
    },

    '& > p': {
      whiteSpace: 'pre-wrap',
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 500,
      color: '#1B2036',
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
      },
    },
  },

  topSpacer: {
    order: 3,
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },

  bottomSpacer: {
    display: 'none',
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },

  btnContainer: {
    backgroundColor: 'white',
    padding: '15px',
    borderRadius: '20px',
    order: 4,
    boxSizing: 'border-box',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '620px',
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transpanent',
      padding: 0,
      borderRadius: 'none',
    },
    '& > div': {
      flexShrink: 1,
    },
    '&.clicked > div': {
      pointerEvents: 'none',
      cursor: 'default',
      boxSizing: 'border-box',
      '&.not-clicked-btn': {
        backgroundColor: 'transparent',
        color: '#BBBCC3',
        '&:hover': {
          backgroundColor: 'inherit',
        },
        '&:active': {
          backgroundColor: 'inherit',
        },
      },
    },
  },

  voteBtn: {
    display: 'inline-flex',
    padding: '0 15px',
    flexGrow: 0,
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '48px',
    backgroundColor: '#2C74F3',
    color: 'white',
    borderRadius: '4px',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    marginRight: '5px',
    transitionProperty: 'background-color',
    transitionDuration: '.23s',
    cursor: 'pointer',

    '& > span': {
      textAlign: 'center',
      display: 'inline-flex',
      maxHeight: '40px',
      overflow: 'hidden',
      wordBreak: 'break-word',
    },

    '&.clicked-btn': {
      backgroundColor: '#D7E4FD',
      color: '#1B2036',
      '&:hover': {
        backgroundColor: 'inherit',
      },
      '&:active': {
        backgroundColor: 'inherit',
      },
    },
    '&:hover': {
      backgroundColor: '#356FD6',
    },
    '&:active': {
      backgroundColor: '#3662AE',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

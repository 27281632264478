import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { fontFamily } from '@src/art/theme/textStyles';

import CrownIcon from '@art/icons/crown.svg';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    personContainer: {
      paddingTop: '2px',
      paddingLeft: '1px',
      paddingRight: '1px',
      fontFamily,
      flexBasis: '20%',
      flexShrink: 0, // check it
      flexGrow: 0, // check it
      height: '26vw',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        height: '27vw',
        paddingLeft: '2px',
        paddingRight: '2px',
      },

      [theme.breakpoints.up('md')]: {
        height: 'auto',
        overflow: 'hidden',
        paddingTop: 0,
        paddingLeft: '5px',
        paddingRight: '5px',
        transition: 'all .1s',
        padding: '2px 4px',

        '&:hover': {
          // transform: 'scale(1.02)',

          '& .personControls': {
            display: 'flex',
          },

          '& .personStatus': {
            display: 'none',
          },

          '& .personName': {
            display: 'flex',
          },
        },
      },
    },

    personControls: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: '13px',
      height: '3px',
      zIndex: 1,
      top: '7px',
      right: '4px',
      [theme.breakpoints.up('sm')]: {
        top: '10px',
        right: '10px',
      },

      [theme.breakpoints.up('md')]: {
        display: 'none',
        top: '15px',
        right: '10px',
        width: '25px',
        height: '25px',
        borderRadius: '4px',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        padding: '11px 6px',
        cursor: 'pointer',
      },

      '& > div': {
        display: 'block',
        width: '3px',
        height: '3px',
        backgroundColor: 'white',
        borderRadius: '50%',
        transition: 'all .23s',
      },

      '&:hover > div': {
        cursor: 'pointer',
        backgroundColor: '#2C74F3',
      },
    },

    personWrapper: {
      width: '100%',
      position: 'relative',
      // zIndex: 1,
      borderRadius: '4px',
      // alignItems: 'center',
      justifyContent: 'center',
      // [theme.breakpoints.up('md')]: {
      //   paddingTop: '5px',
      // },

      '&.invite': {
        width: '100%',
      },
      '&.isShowSticker': {
        pointerEvents: 'none',
      },
    },

    muteVideoOverlay: {
      display: 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F3F4F5',

      // mute-video svg icon
      '& > svg': {
        position: 'absolute',
        width: '13px',
        height: '9px',
        left: '50%',
        top: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        [theme.breakpoints.up('sm')]: {
          width: '25px',
          height: '19px',
        },
      },

      // person avatar when video is mute
      '& > img': {
        width: '14vw',
        height: '14vw',
        objectFit: 'cover',
        borderRadius: '50%',
        // filter: 'brightness(55%)',
        [theme.breakpoints.up('sm')]: {
          width: '16vw',
          height: '16vw',
        },
        [theme.breakpoints.up('md')]: {
          width: '10vh', // 12vh - after new header
          height: '10vh', // 12vh - after new header
        },
      },

      // fix for Safari (black bg overflow)
      '& + div': {
        backgroundColor: 'transparent !important',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '24px',
      },
    },

    personVideo: {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
      borderRadius: '4px',
      backgroundColor: '#F3F4F5', // invite bg
      overflow: 'hidden',
      position: 'relative',
      // zIndex: 2,
      '& video': {
        borderRadius: '4px',
      },
      // when video off
      '&.muteVideo video': {
        display: 'none',
      },
      '&.muteVideo .muteVideoOverlay': {
        display: 'flex',
      },
      [theme.breakpoints.up('md')]: {
        width: '310px',
      },
    },

    crownIcon: {
      position: 'absolute',
      // zIndex: 2,
      top: 0,
      left: 0,
      width: '18px',
      height: '18px',
      backgroundColor: 'rgba(0,0,0, 0.45)',
      backgroundSize: '12px auto',
      backgroundImage: `url(${CrownIcon})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '4px 0px',
      [theme.breakpoints.up('sm')]: {
        width: '32px',
        height: '32px',
        backgroundSize: '21px auto',
      },
    },

    stickerContainer: {
      position: 'absolute',
      // zIndex: 2,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    sticker: {
      position: 'absolute',
      zIndex: 3,
      border: '2px solid orange',
      borderRadius: '4px',
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
      width: 'auto',
      objectFit: 'fill',
      [theme.breakpoints.up('md')]: {
        width: '100%',
        objectFit: 'contain',
      },
    },

    stickerIcon: {
      position: 'absolute',
      top: 2,
      right: 2,
      width: '22px',
      height: '22px',
      cursor: 'pointer',
      [theme.breakpoints.up('sm')]: {
        top: 5,
        right: 5,
        width: '30px',
        height: '30px',
      },
    },

    mute: {
      display: 'flex',
      position: 'absolute',
      bottom: '10px',
      left: '10px',
      '& img': {
        width: '25px',
        height: '25px',
        marginRight: '5px',
      },
    },

    bigMute: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: 2,
      background: 'rgba(0, 0, 0, 0.45)',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      cursor: 'pointer',
      '& img': {
        width: '30px',
        height: '40px',
        alignSelf: 'center',
        [theme.breakpoints.up('sm')]: {
          width: '60px',
          height: '80px',
        },
      },
    },
  })
);

import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

interface IProps {
  isActivePlayer: boolean;
  activePlayerVote: boolean;
  passivePlayerVote: boolean;
  activePlayer:
    | {
        playerId: string;
        name: string;
      }
    | undefined;
}

const SubText: React.FC<IProps> = ({ isActivePlayer, activePlayerVote, passivePlayerVote, activePlayer }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <p className={classes.questionSubText}>
      {activePlayerVote &&
        (isActivePlayer ? (
          isEnLocale ? (
            <>
              How do <span>You</span> think?
            </>
          ) : (
            <>
              Как <span>ты</span> думаешь?
            </>
          )
        ) : isEnLocale ? (
          <>
            Wait until <span>{activePlayer?.name || 'Active player'}</span> makes his&nbsp;choice...
          </>
        ) : (
          <>
            Подожди, пока <span>{activePlayer?.name || 'Активный игрок'}</span> сделает свой&nbsp;выбор...
          </>
        ))}

      {passivePlayerVote &&
        (isActivePlayer ? (
          isEnLocale ? (
            <p>
              Wait until players are trying to&nbsp;guess <br />
              <span>Your</span>&nbsp;choice...
            </p>
          ) : (
            <p>
              Подожди, пока игроки стараются&nbsp;угадать <br />
              <span>твой</span>&nbsp;выбор...
            </p>
          )
        ) : isEnLocale ? (
          <p>
            Guess what <span>{activePlayer?.name || 'Active player'}</span> thinks?
          </p>
        ) : (
          <p>
            Отгадай, что <span>{activePlayer?.name || 'Active player'}</span> думает?
          </p>
        ))}
    </p>
  );
};

export default SubText;

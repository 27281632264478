import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: 'none !important',
    [theme.breakpoints.up('md')]: {
      flexGrow: 0,
      width: '320px',
    },
  },

  mainStatusBarContainer: {
    zIndex: 5,
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      left: 0,
      top: 0,
      right: '320px',
      width: 'auto',
    },
  },
}));

export const videoContainerStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      flexGrow: 1,
      backgroundColor: '#fff',
    },
  },
}));

export const videoCardStyle = makeStyles((theme) => ({
  cardContainer: {
    boxSizing: 'border-box',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
      // border: '2px dashed #b7b7b9',
      position: 'relative',
      justifyContent: 'center',
      '&:hover $bigScreenBadgetContainer': {
        display: 'flex',
      },
      '&:hover $badgetContainer': {
        display: 'none',
      },
    },
  },

  videoPlaceholder: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    top: 0,
    left: 0,
  },

  badgetContainer: {
    backgroundColor: '#00000099',
    height: '16px',
    position: 'absolute',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '2px',
    bottom: 0,
    zIndex: 2,
    [theme.breakpoints.up('md')]: {
      height: '30px',
    },
  },

  badgetContainerText: {
    color: '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      fontWeight: 900,
      fontSize: '16px',
      lineHeight: '25px',
    },
  },

  userNameText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.up('md')]: {
      fontWeight: 900,
      fontSize: '16px',
      lineHeight: '25px',
      color: '#FFF',
    },
  },

  bigScreenBadgetContainer: {
    bottom: -20,
    height: '16px',
    position: 'absolute',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '2px',
    zIndex: 2,
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
      backgroundColor: '#00000099',
      bottom: 0,
      height: '30px',
    },
  },
}));

export const modalStyle = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  container: {
    paddingLeft: '15px',
    paddingRight: '15px',
    position: 'fixed',
    boxSizing: 'border-box',
    outline: 'none !important',
    width: '100%',
    maxWidth: '528px',
  },

  content: {
    backgroundColor: '#fff',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '25px',
    paddingBottom: '15px',
    flexGrow: 1,
    borderRadius: '20px',
  },

  textStyle: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },

  btnComomn: {
    marginTop: '10px',
    textTransform: 'none',
  },
}));

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PLAYER_STATE, selectRoomPlayers } from '@store/room/roomSlice';
import { selectPlayerId } from '@store/reducers/player.reducer';
import { PLAYER_STATUS } from '@src/common/constants/system';
import WSClient from '@src/sockets';
import { simpleMerge } from '@src/common/generics';
import { defaultGameState, initialMapState } from './quizClassic.types';
import { CLIENT_EVENTS, SERVER_EVENTS } from '@shared/socketEvents/eventTypes';
import { QuizClassicState } from '@shared/gameInterfaces/quizClassic.socket';

export const useGameProvider = (roomId: string) => {
  const playerId = useSelector(selectPlayerId);
  const players = useSelector(selectRoomPlayers);
  const [state, setState] = useState<QuizClassicState>(defaultGameState);
  const stateMatcher = { ...initialMapState, [state.gameState]: true };
  const currentPlayer = players.find((player: any) => player.playerId === playerId);
  const isSpectator = currentPlayer ? currentPlayer.state !== PLAYER_STATE.GAME : true;

  useEffect(() => {
    const updateState = ({ data }: any) => setState(simpleMerge(state, data.quizClassic));

    WSClient.on(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    WSClient.on(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);

    WSClient.emit(CLIENT_EVENTS.GAME_STATE_REQUESTED, { roomId });

    return () => {
      WSClient.off(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);
      WSClient.off(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    };
  }, []);

  const setVote = (answerIndex: number) => setState(simpleMerge(state, {})); // vote: answerIndex }));
  const setReady = () => setState(simpleMerge(state, {})); // readyPlayers: playerId }));

  return [
    stateMatcher,
    { ...state, state: stateMatcher },
    playerId,
    { setVote, setReady },
    players,
    currentPlayer,
    isSpectator,
  ] as const;
};

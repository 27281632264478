import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  personVideo: {
    width: '100%',
    height: '100%',
    // height: 'auto',
    objectFit: 'cover',
    borderRadius: '4px',
    backgroundColor: '#F3F4F5', // invite bg
    overflow: 'hidden',
    position: 'relative',
    // zIndex: 2,
    '& video': {
      borderRadius: '4px',
    },
    // when video off
    '&.muteVideo video': {
      display: 'none',
    },
    '&.muteVideo .muteVideoOverlay': {
      display: 'flex',
    },
    [theme.breakpoints.up('md')]: {
      // width: '310px',
    },
  },

  video: {
    width: '100%',
    height: '100%',
  },

  muteVideoOverlay: {
    display: 'none',
    // zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F3F4F5',

    // mute-video svg icon
    '& > svg': {
      position: 'absolute',
      width: '13px',
      height: '9px',
      left: '50%',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      [theme.breakpoints.up('sm')]: {
        width: '25px',
        height: '19px',
      },
    },

    '& > picture': {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        height: '100%',
        // width: 'auto',
        width: '100%',
        // objectFit: 'cover',
      },
    },

    // person avatar when video is mute
    '& > img': {
      width: 'auto',
      height: '100%',
      objectFit: 'cover',
      // [theme.breakpoints.up('md')]: {
      //   width: '10vw',
      //   height: '100%',
      // },
    },

    // fix for Safari (black bg overflow)
    '& + div': {
      backgroundColor: 'transparent !important',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '24px',
    },
  },

  childrenOverlay: {
    width: '100%',
    height: '100%',
    display: 'block',
    position: 'absolute',
    // zIndex: 2,
  },
}));

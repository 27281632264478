import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

const GuessUpRules: React.FC = () => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();

  return (
    <>
      {isEnLocale ? (
        <ul className={classes.list}>
          <li>Active player must guess the Secret Phrase by asking the other players questions</li>
          <li>Players should answer active player questions but should not directly say the Secret Phrase</li>
          <li>When time is up players vote whether the Active player guessed correctly</li>
          <li>If Secret Phrase guessed correctly, Active player gets 1 point</li>
        </ul>
      ) : (
        <ul className={classes.list}>
          <li>Активный игрок должен отгадать секретную фразу, задавая вопросы другим игрокам</li>
          <li>Игроки должны ответить на вопрос, не называя секретную фразу</li>
          <li>Когда время заканчивается, игроки голосуют, отгадал ли активный игрок секретную фразу</li>
          <li>Если фраза отгадана верно, активный игрок получает один балл</li>
        </ul>
      )}
    </>
  );
};

export { GuessUpRules };

import { useState } from 'react';
import { DrinkRouletteState, ROULETTE_TICKET_TYPE } from '@shared/gameInterfaces/roulette.socket';
import { IRoomPlayer } from '@src/store/room/roomSlice';
import { LineTimer } from '@src/games/common';
import { styles } from './styles';
import { useDeviceSize } from '@src/common/screenSizes';

interface IProps {
  playerId: string;
  gameState: DrinkRouletteState;
  isSpectator: boolean;
  onVote: (index: number) => void;
  players: IRoomPlayer[];
}

const MultiQuestion: React.FC<IProps> = ({ gameState, isSpectator, playerId, onVote, players }) => {
  const classes = styles();
  const { ticket, votedPlayers, timer } = gameState;
  const { isDesktop } = useDeviceSize();
  const [isVote, setVote] = useState(false);

  const DEFAULT_ANSWERED_INDEX = -1;

  // current state answer index
  const [stateAnswerValue, setStateAnswerValue] = useState(DEFAULT_ANSWERED_INDEX);

  // check already answered value
  const currentPlayerAnswerObj = votedPlayers?.find((answer) => answer.playerId === playerId);

  const alreadyAnswered =
    // @ts-ignore
    stateAnswerValue > DEFAULT_ANSWERED_INDEX || currentPlayerAnswerObj?.value > DEFAULT_ANSWERED_INDEX;

  const onVoteClick = (index: number) => {
    setStateAnswerValue(index); // save voteId to current state
    onVote(index); // vote action
    setVote(true);
  };

  return (
    <div className={`${classes.questionWrapper} animate__animated  animate__fadeIn`}>
      {!isDesktop && (
        <div className={classes.timerWrapper}>
          <LineTimer timer={timer!} isVote={isVote!} />
        </div>
      )}

      <div className={classes.questionInner}>
        <div className={classes.questionText}>
          <p>{ticket?.question}</p>
        </div>

        <div
          className={`
          ${classes.btnContainer}
          ${alreadyAnswered || isSpectator ? 'clicked' : ''}
        `}
        >
          {ticket?.answers.length && !timer?.timeIsUp
            ? ticket?.answers.map((answer, index) => {
                // check stateAnswerValue and outStateAnswer to match with index
                const checkAnswer = stateAnswerValue === index || currentPlayerAnswerObj?.value === index;
                const getOptionPlayerName = () => players.find((player) => player.playerId === answer)?.name || '@left';
                const option =
                  ticket?.ticketType === ROULETTE_TICKET_TYPE.PLAYERS_LIST ? getOptionPlayerName() : answer;

                return (
                  <div
                    key={index}
                    className={`
                    ${classes.voteBtn}
                    ${checkAnswer ? 'clicked-btn' : 'not-clicked-btn'}
                  `}
                    onClick={() => onVoteClick(index)}
                    style={{ width: `calc(100% / ${ticket?.answers.length}` }}
                  >
                    <span>{option}</span>
                  </div>
                );
              })
            : null}
        </div>
      </div>

      {isDesktop && (
        <div className={classes.timerWrapper}>
          <LineTimer timer={timer!} isVote={isVote!} />
        </div>
      )}
    </div>
  );
};

export { MultiQuestion };

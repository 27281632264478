import { useEffect, useState, useRef } from 'react';
import {
  getSelectedPartyInfo,
  PLAYER_STATE,
  selectCurrentGame,
  selectCurrentPlayerState,
  selectCurrentRoomPlayer,
} from '@store/room/roomSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { getIsGameListLoading, getIsJoinRoomLoading } from '@store/reducers/loadActions';
import { setWrapperOffsets, selectShouldUpdateOffsets } from '@store/reducers/session.reducer';
import { GameTypes } from '@common/constants/common';
import { IS_AGORA_ENABLED, PLAYER_STATUS } from '@src/common/constants/system';
import VideoChat from '@components/VideoChat';
import StatusBar from '@components/HomepageBar/StatusBar';
import { MenuBar } from '@components/HomepageBar/MenuBar';
import ConnectToServerLoader from '@components/ConnectToServerLoader';
import { useStyles } from './styles';
import { useDeviceSize } from '@src/common/screenSizes';
import { SNACKBAR_TYPE, selectNotificationType } from '@src/store/reducers/popups';

interface DeviceProps {
  isLoading: boolean;
  showVideoChat: boolean;
}

/*interface CommonDeviceProps extends DeviceProps {
  deviceInfo: DeviceInfoType;
}*/

interface RoomWrapperProps {
  showVideoChat: boolean;
}

// Desktop
const RoomWrapperDesktop: React.FC<DeviceProps> = ({ showVideoChat, isLoading, children }) => {
  const classes = useStyles();
  const topBar = useRef<HTMLDivElement>(null);
  const rightBar = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const shouldUpdateOffsets = useAppSelector(selectShouldUpdateOffsets);

  useEffect(() => {
    if (topBar.current && rightBar.current) {
      const top = topBar.current.offsetHeight;
      const right = rightBar.current.offsetWidth;
      dispatch(setWrapperOffsets({ top, right }));
    }
  }, [shouldUpdateOffsets]);

  return (
    <div className={classes.desktopWrapper}>
      <div className={classes.columnWrapper}>
        <div className={classes.header} ref={topBar}>
          <MenuBar />
          <StatusBar hasVideoChat={showVideoChat} />
        </div>
        <div className={classes.contentContainer}>{children}</div>
      </div>

      {showVideoChat && <VideoChat isLoading={isLoading} ref={rightBar} />}
    </div>
  );
};

// Tablet
const RoomWrapperTablet: React.FC<DeviceProps> = ({ showVideoChat, isLoading, children }) => {
  const classes = useStyles();
  const topBar = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  // TODO: Should be ResizeObserver instead.
  const shouldUpdateOffsets = useAppSelector(selectShouldUpdateOffsets);

  useEffect(() => {
    if (topBar.current) {
      const top = topBar.current.offsetHeight;
      dispatch(setWrapperOffsets({ top }));
    }
  }, [shouldUpdateOffsets]);

  return (
    <div className={classes.columnWrapper}>
      <div className={classes.refWrapper} ref={topBar}>
        <div className={classes.header}>
          <MenuBar />
          <StatusBar hasVideoChat={showVideoChat} />
        </div>

        {showVideoChat && <VideoChat isLoading={isLoading} />}
      </div>

      <div className={classes.contentContainer}>{children}</div>
    </div>
  );
};

// Mobile
const RoomWrapperMobile: React.FC<DeviceProps> = ({ showVideoChat, isLoading, children }) => {
  const classes = useStyles();
  const topBar = useRef<HTMLDivElement>(null);
  const bottomBar = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const shouldUpdateOffsets = useAppSelector(selectShouldUpdateOffsets);

  useEffect(() => {
    if (topBar.current && bottomBar.current) {
      const top = topBar.current.offsetHeight;
      const bottom = bottomBar.current.offsetHeight;
      dispatch(setWrapperOffsets({ top, bottom }));
    }
  }, [shouldUpdateOffsets]);

  return (
    <div className={classes.columnWrapper}>
      <div className={classes.refWrapper} ref={topBar}>
        <StatusBar hasVideoChat={showVideoChat} />

        {showVideoChat && <VideoChat isLoading={isLoading} />}
      </div>

      <div className={classes.contentContainer}>{children}</div>
      <MenuBar ref={bottomBar} />
    </div>
  );
};

interface Props {
  showVideoChat: boolean;
}

const RoomWrapper: React.FC<Props> = ({ showVideoChat, children }) => {
  const classes = useStyles();
  const isLoading = useAppSelector(getIsJoinRoomLoading);
  const isGameListLoading = useAppSelector(getIsGameListLoading);
  const playerState = useAppSelector(selectCurrentPlayerState);
  const selectedGame = useAppSelector(selectCurrentGame);
  const gameType = selectedGame?.type;
  const [isShowVideoChat, setShowVieoChat] = useState(true);
  const shouldShowVideoChat = IS_AGORA_ENABLED && showVideoChat && isShowVideoChat;
  const { isMobile, isTablet, isDesktop } = useDeviceSize();
  const type = useAppSelector(selectNotificationType);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(type === SNACKBAR_TYPE.RECEIVE);
  }, [type]);

  useEffect(() => {
    if (gameType === GameTypes.QUESTION_CHALLENGE && playerState === PLAYER_STATE.GAME) {
      setShowVieoChat(false);
    } else {
      setShowVieoChat(true);
    }
  }, [gameType, playerState]);

  return (
    <>
      {isLoading || isGameListLoading ? (
        <ConnectToServerLoader />
      ) : (
        <div className={classes.roomWrapper}>
          {isDesktop && (
            <RoomWrapperDesktop children={children} isLoading={isLoading} showVideoChat={shouldShowVideoChat} />
          )}

          {isTablet && (
            <RoomWrapperTablet children={children} isLoading={isLoading} showVideoChat={shouldShowVideoChat} />
          )}

          {isMobile && (
            <RoomWrapperMobile children={children} isLoading={isLoading} showVideoChat={shouldShowVideoChat} />
          )}
        </div>
      )}
    </>
  );
};

export { RoomWrapper };

import { useAppDispatch, useAppSelector } from '@app/hooks';
import { styles } from './styles';
import React, { useState } from 'react';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { selectGameParams, setGameParams } from '@src/store/reducers/playerData.reducer';

interface Props {}

const SuperstarModeSwitcher: React.FC<Props> = () => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const { isSuperstarMode } = useAppSelector(selectGameParams);
  const [barPosition, setBarPosition] = useState(isSuperstarMode ? 'superstar' : 'regular');
  const text = isSuperstarMode
    ? isEnLocale
      ? 'Room owner always goes on date'
      : 'Владелец в комнате всегда на свидании'
    : isEnLocale
    ? 'Players go on date in turn'
    : 'Игроки идут на свидание по очереди';

  const onRegularModeClick = () => {
    dispatch(setGameParams({ isSuperstarMode: false }));
    setBarPosition('regular');
  };

  const onSuperstarModeClick = () => {
    dispatch(setGameParams({ isSuperstarMode: true }));
    setBarPosition('superstar');
  };

  return (
    <div className={classes.switcherContainer}>
      <div className={`${classes.switcherBar}`}>
        <div className={`mode ${isSuperstarMode ? '' : barPosition}`} onClick={onRegularModeClick}>
          {isEnLocale ? 'Regular' : 'Обычный'}
        </div>
        <div
          className={`mode ${isSuperstarMode ? barPosition : ''} ${isEnLocale ? 'starsEN' : 'starsRU'}`}
          onClick={onSuperstarModeClick}
        >
          {isEnLocale ? 'Superstar Mode' : 'Суперстар'}
          <div className={` stars ${isEnLocale ? 'starsEN' : 'starsRU'}`}></div>
        </div>
      </div>

      <div className={classes.switcherLang}>{text}</div>
    </div>
  );
};

export { SuperstarModeSwitcher };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  singleStartRound: {
    position: 'relative',
    zIndex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: '15px',
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
    flexGrow: 1,
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
    boxSizing: 'border-box',
    '& *': { boxSizing: 'border-box' },
    // [theme.breakpoints.up('md')]: {
    //   padding: '30px',
    // },
    '& + section': {
      display: 'none',
    },
  },

  background: {
    position: 'absolute',
    zIndex: 2,
    width: '500px',
    height: 'auto',
  },

  content: {
    position: 'relative',
    flexGrow: 1,
    height: '100%',
    zIndex: 3,
    flexDirection: 'column',
    alignItems: 'center',
  },

  spacer: {
    flexGrow: 1,
  },

  logo: {
    height: 'auto',
    marginBottom: '10px',

    // roulette
    '&.roulette-logo': {
      width: '195px',
      marginLeft: '-20px',
      [theme.breakpoints.up('sm')]: {
        width: '373px',
        marginLeft: '-40px',
      },
      [theme.breakpoints.up('md')]: {
        width: '470px',
        marginLeft: '-50px',
      },
    },

    // roast
    '&.roast-logo': {
      width: '265px',
      [theme.breakpoints.up('sm')]: {
        // width: '550px',
        width: '410px',
      },
      // [theme.breakpoints.up('md')]: {
      //   width: '410px',
      // },
    },

    // twenty-questions
    '&.twenty-questions-logo': {
      width: '185px',
      [theme.breakpoints.up('sm')]: {
        width: '376px',
      },
      [theme.breakpoints.up('md')]: {
        width: '280px',
      },
    },
  },

  buttonsContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },

  buttonStart: {
    width: '175px',
    height: '55px',
    marginBottom: '10px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    transition: 'all 0.23s',
    '&:hover': {
      cursor: 'pointer',
      filter: 'brightness(120%)',
    },
    [theme.breakpoints.up('sm')]: {
      width: '322px',
      height: '100px',
      marginBottom: '20px',
    },
    [theme.breakpoints.up('md')]: {
      width: '330px',
      height: '103px',
    },
  },

  buttonInvite: {
    width: '115px',
    height: '37px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    transition: 'all 0.23s',
    '&:hover': {
      cursor: 'pointer',
      filter: 'brightness(120%)',
    },
    [theme.breakpoints.up('sm')]: {
      width: '228px',
      height: '74px',
    },
    [theme.breakpoints.up('md')]: {
      width: '236px',
      height: '77px',
    },
  },

  infoLabel: {
    position: 'absolute',
    left: '15px',
    bottom: '15px',
    zIndex: 2,
    width: '59px',
    height: '67px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('sm')]: {
      width: '120px',
      height: '137px',
    },
    [theme.breakpoints.up('md')]: {
      width: '125px',
      height: '143px',
    },
  },
}));

import { makeStyles } from '@material-ui/core';
import warningImage from '@art/images/one-question/warning.svg';

export const styles = makeStyles((theme) => ({
  noCameraWarning: {
    width: '60px',
    height: '55px',
    backgroundImage: `url(${warningImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginBottom: '14px',
    [theme.breakpoints.up('sm')]: {
      width: '120px',
      height: '109px',
      marginBottom: '25px',
    },
  },

  noCameraWarningTitle: {
    maxWidth: '100px',
    color: '#1B2036',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '32px',
      lineHeight: '32px',
      maxWidth: '150px',
    },
  },
}));

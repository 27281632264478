import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { BLOCKCHAIN_TYPE, selectBlockchainType } from '@src/store/reducers/common';
import { SolanaWrapper } from '@src/features/solana/solanaWrapper';
import { BscWrapper } from '@src/features/bsc/bscWrapper';
import { useSolanaData } from '@src/features/solana/useSolanaData';
import { useBscData } from '@src/features/bsc/useBscData';
import { WalletDisconnectButton, WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { BscConnectButton, BscDisconnectButton } from '@src/features/bsc/bscButtons';
import { requestProgressConnectQuest } from '@src/graphql/player.queries';

type BlockchainButtonsProps = {
  className?: string;
  walletConnected?: boolean;
};

export const BlockchainWrapper = <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  function BlockchainComponent({ ...props }) {
    const blockchainType = useAppSelector(selectBlockchainType);
    const wrapper = blockchainType === BLOCKCHAIN_TYPE.BSC ? BscWrapper(Component) : SolanaWrapper(Component);

    return React.createElement(wrapper, props);
  };

export const useBlockchainData = () => {
  const dispatch = useAppDispatch();
  const blockchainType = useAppSelector(selectBlockchainType);
  const solanaData = useSolanaData();
  const bscData = useBscData();
  const blockchainData = blockchainType === BLOCKCHAIN_TYPE.BSC ? bscData : solanaData;
  const walletConnected = blockchainData.walletConnected;

  useEffect(() => {
    if (walletConnected) {
      dispatch(requestProgressConnectQuest);
    }
  }, [walletConnected]);

  return blockchainData;
};

export const BlockchainConnectButton: React.FC<BlockchainButtonsProps> = ({ className, walletConnected, children }) => {
  const blockchainType = useAppSelector(selectBlockchainType);
  return blockchainType === BLOCKCHAIN_TYPE.BSC ? (
    <BscConnectButton className={className} walletConnected={walletConnected}>
      {children}
    </BscConnectButton>
  ) : (
    <WalletModalProvider>
      <WalletMultiButton className={className}>{children}</WalletMultiButton>
    </WalletModalProvider>
  );
};

export const BlockchainDisconnectButton: React.FC<BlockchainButtonsProps> = (props) => {
  const blockchainType = useAppSelector(selectBlockchainType);
  return blockchainType === BLOCKCHAIN_TYPE.BSC ? (
    <BscDisconnectButton className={props.className} />
  ) : (
    <WalletDisconnectButton className={props.className} />
  );
};

const AliasTextContentEn: React.FC = () => {
  return (
    <>
      <li>
        An active player and a player who guessed the word gets <b>1 point</b>
      </li>
      <li>
        If no one guessed correctly, <b>no one</b> gets points
      </li>
    </>
  );
};

const AliasTextContentRu: React.FC = () => {
  return (
    <>
      <li>
        Активный игрок и игрок, угадавший слово, получают по <b>1 очку</b>
      </li>
      <li>
        Если никто не отгадал слово, то очки не начисляются <b>никому</b>
      </li>
    </>
  );
};

export { AliasTextContentEn, AliasTextContentRu };

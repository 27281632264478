import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    height: 'auto',
    width: 'inherit',
    maxWidth: 1350,
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
  welcomeBanner: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'initial', //'sticky',
    top: 0,
    height: '100%',
    backgroundColor: '#E8E9EB',
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      top: '60px',
      position: 'initial',
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '0px 0px 8px 8px',
    },
  },
  welcomeBannerSkeleton: {
    display: 'block',
    maxWidth: 1350,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'sticky',
    top: 0,
    height: '285px',
    backgroundColor: '#E8E9EB',
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      top: '60px',
      position: 'initial',
      height: '345px',
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '0px 0px 8px 8px',
    },
  },

  buttonStart: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
    bottom: 20,
    width: '125px !important',
    [theme.breakpoints.up('sm')]: {
      bottom: '25px',
      width: '180px !important',
    },
  },
}));

import { useAppSelector } from '@src/app/hooks';
import { IRoomPlayer } from '@src/store/room/roomSlice';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { Chest, ChestsState } from '@shared/gameInterfaces/chests.socket';
import { PotCoinSmall } from '../../helpers/chests.images';
import { styles } from './styles';
import { CircleTimer } from '@src/games/common';
import { useEffect, useRef, useState } from 'react';

type Props = {
  players: IRoomPlayer[];
  game: ChestsState;
  balance: number;
  roundInProgress: boolean;
  isActivePlayer: boolean;
};

const ChestsHeader: React.FC<Props> = ({ players, game, balance, roundInProgress, isActivePlayer }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const { roundNumber, totalRounds, timer, chests } = game;
  const showTitleEn = roundNumber === totalRounds ? 'Final round' : `Round ${roundNumber} of ${totalRounds}`;
  const showTitleRu = roundNumber === totalRounds ? 'Финальный раунд' : `Раунд ${roundNumber} из ${totalRounds}`;
  const playerObj = players.find((player) => player.playerId === game.activePlayerId);

  // const isRewardState = chests?.some((chest) => chest.reward !== null);
  const timerCondition = roundInProgress && isActivePlayer && timer!.duration > 5000;

  return (
    <header className={classes.chestsHeader}>
      <div className={classes.balance}>
        <span>{isEnLocale ? 'Balance' : 'Баланс'}</span>
        <div>
          <img src={PotCoinSmall} alt="PotCoinSmall" />
          <b>{balance}</b>
        </div>
      </div>

      <h2 className={classes.roundTitle}>
        {roundInProgress ? (
          isActivePlayer ? (
            <>
              {isEnLocale ? (
                <>
                  <span>Choose</span> a Chest
                </>
              ) : (
                <>
                  <span>Выберите</span> сундучок
                </>
              )}
            </>
          ) : (
            <>
              {isEnLocale ? (
                <>
                  <span>{playerObj?.name}</span> chooses a Chest
                </>
              ) : (
                <>
                  <span>{playerObj?.name}</span> выбирает сундучок
                </>
              )}
            </>
          )
        ) : (
          <>{isEnLocale ? showTitleEn : showTitleRu}</>
        )}
      </h2>

      <div className={classes.timerContainer}>{timerCondition && <CircleTimer timer={timer!} />}</div>
    </header>
  );
};

export { ChestsHeader };

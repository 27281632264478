import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  results: {
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',
    minHeight: '100%',
    [theme.breakpoints.up('md')]: {
      paddingTop: '30px',
    },
  },

  resultsCard: {
    animationDuration: '230ms',
    animationDelay: '230ms',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '15px 25px',
    width: '345px',
    minHeight: '313px',
    paddingTop: '140px',
    backgroundRepeat: 'no-repeat, no-repeat',
    backgroundSize: '200px auto, 295px auto',
    backgroundPosition: 'center 12px, center 45px',
    [theme.breakpoints.up('sm')]: {
      width: '468px',
      minHeight: '440px',
      paddingTop: '200px',
      padding: '15px 30px',
      backgroundPosition: 'center 10px, center 60px',
      backgroundSize: '285px auto, 430px auto',
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '160px',
      backgroundColor: 'transparent',
      backgroundPosition: 'center -15px, center 35px',
    },
  },

  resultWrapper: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '10px',
    borderWidth: '5px',
    borderStyle: 'solid',
    padding: '5px 15px 10px',
    marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      borderWidth: '10px',
      borderStyle: 'solid',
      borderRadius: '20px',
      padding: '10px 15px 20px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '14px',
    },

    '& > h3': {
      fontWeight: 900,
      fontSize: '28px',
      lineHeight: '38px',
      color: '#1B2036',
      margin: 0,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '40px',
        lineHeight: '55px',
      },
    },
  },

  resultWinner: {
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '20px',
    margin: 0,
    marginBottom: '18px',
    color: '#5F6372',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '25px',
      fontSize: '20px',
      lineHeight: '24px',
    },
  },

  resultName: {
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    margin: 0,
    color: '#5F6372',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '22px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '28px',
    },
    '& > div': {
      width: '250px',
      whiteSpace: 'nowrap',
      display: 'block',
      height: '22px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontWeight: 700,
      [theme.breakpoints.up('sm')]: {
        width: '330px',
        height: '30px',
      },
    },
  },

  resultWord: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: '15px',
    paddingRight: '15px',
    '& > span': {
      fontSize: '15px',
      lineHeight: '20px',
      color: '#5F6372',
      fontWeight: 500,
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
    '& > b': {
      color: '#1B2036',
      fontSize: '24px',
      lineHeight: '26px',
      textTransform: 'uppercase',
      [theme.breakpoints.up('sm')]: {
        fontSize: '32px',
        lineHeight: '32px',
      },
    },
  },
}));

import { useAppSelector } from '@src/app/hooks';
import { useDeviceSize } from '@src/common/screenSizes';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';

interface Props {
  activePlayer: { name: string };
}

const Rule: React.FC<Props> = ({ activePlayer }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const { isMobile } = useDeviceSize();

  const text = isEnLocale
    ? 'will have to answer the question within 5 seconds'
    : 'должен ответить на вопрос в течение 5-ти секунд';

  return (
    <div className={`animate__animated  ${isMobile ? `animate__fadeInDown` : `animate__zoomIn`}`}>
      <div className={classes.ruleContainer}>
        <div className={classes.name}>{activePlayer?.name}</div>
        <div className={classes.description}>{text}</div>
      </div>
    </div>
  );
};

export { Rule };

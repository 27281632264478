import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  rewardTitle: {
    width: '325px',
    zIndex: 3,
    '--animate-duration': '500ms',
    '--animate-delay': '500ms',
    [theme.breakpoints.up('sm')]: {
      width: '568px',
      marginBottom: '30px',
    },
    [theme.breakpoints.up('md')]: {
      width: '650px',
      marginBottom: '90px',
    },
    '& > img': {
      width: '100%',
      height: 'auto',
    },
  },
}));

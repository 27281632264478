import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  results: {
    display: 'flex',
    minHeight: '100%',
    minWidth: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundImage: 'linear-gradient(180deg, #EBF6FF 0%, #ACD8FD 98.44%)',
    boxSizing: 'border-box',
    '& *': { boxSizing: 'border-box' },
    [theme.breakpoints.up('md')]: {
      overflowY: 'initial',
      padding: '30px',
    },
  },

  resultsWrapper: {
    flexGrow: 1,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    padding: '15px',
    [theme.breakpoints.up('md')]: {
      padding: '30px',
      flexGrow: 0,
      maxWidth: '975px',
      // flexBasis: '500px',
      borderRadius: '20px',
      backgroundColor: 'white',
    },
  },

  questionText: {
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    maxWidth: '315px',
    marginBottom: 0,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '600px',
    },
    '& > p': {
      whiteSpace: 'pre-wrap',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 500,
      color: '#1B2036',
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
      },
    },

    '&.large': {
      maxWidth: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '100%',
      },
      '& > p': {
        fontSize: '16px',
        lineHeight: '21px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '21px',
          lineHeight: '27px',
        },
      },
    },
  },

  barsContainer: {
    width: '345px',
    height: '160px',
    alignItems: 'flex-end',
    justifyContent: 'center',
    background: '#FFF',
    borderRadius: '20px',
    marginTop: '50px',
    marginBottom: '60px',
    paddingBottom: '30px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '40px',
      width: '528px',
      height: '230px',
      paddingBottom: '40px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '60px',
      height: 'auto',
      paddingBottom: 0,
    },
  },

  barsWrapper: {
    borderBottom: '1px solid #F3F4F5',
    // outline: '1px solid red',
    width: '90%',
    height: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'flex-end',
    justifyContent: 'space-evenly',

    '& > div': {
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: '75px',
      borderRadius: '4px 4px 0px 0px',
      '&:first-child': {
        height: '75px',
        backgroundColor: '#2C74F3',
        [theme.breakpoints.up('sm')]: {
          height: '120px',
        },
        '& > span': {
          color: 'white',
        },
      },
      '&:last-child': {
        height: '25px',
        backgroundColor: '#D7E4FD',
        [theme.breakpoints.up('sm')]: {
          height: '40px',
        },
        '& > span': {
          color: '#5F6372',
        },
      },
      [theme.breakpoints.up('sm')]: {
        width: '115px',
      },
      '& > span': {
        fontWeight: 'bold',
        fontSize: '15px',
        lineHeight: '18px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '24px',
          lineHeight: '29px',
        },
      },
      '& > b': {
        position: 'absolute',
        bottom: '-16px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '12px',
        color: '#1B2036',
        [theme.breakpoints.up('sm')]: {
          fontSize: '15px',
          lineHeight: '21px',
          bottom: '-30px',
        },
      },
    },
  },

  buttonsContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '450px',
    },
  },

  purple: {
    height: '36px',
    width: '216px',
    backgroundImage: 'linear-gradient(77.61deg, #8E78FF 0%, #FC7D7B 100%)',
    borderRadius: '30px',
    marginBottom: '15px',
    cursor: 'pointer',
    transition: 'all .23s',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '248px',
      marginBottom: '20px',
    },
    '&:hover, &:focus': {
      boxShadow: '0 0 10px 1px white',
    },
    '&:active': {
      boxShadow: '0 0 10px 1px white',
    },
    '& > a': {
      width: '100%',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& span': {
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '15px',
      color: '#fff',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '16px',
      },
    },
  },

  blue: {
    height: '36px',
    width: '165px',
    backgroundColor: '#2C74F3',
    borderRadius: '30px',
    cursor: 'pointer',
    transition: 'all .23s',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '148px',
    },
    '&:hover, &:focus': {
      backgroundColor: '#356FD6',
    },
    '&:active': {
      backgroundColor: '#3662AE',
    },
    '& > a': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
    },
    '& span': {
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '15px',
      color: '#fff',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '16px',
      },
    },
  },
}));

import React from 'react';

const Star: React.FC = () => {
  return (
    <svg width="25" height="23" viewBox="0 0 25 23" fill="none">
      <path
        d="M11.5006 0.59822C11.9094 -0.199407 13.0906 -0.199407 13.4994 0.598221L16.4987 6.45016C16.6611 6.7669 16.9749 6.98644 17.3379 7.03723L24.0446 7.97563C24.9587 8.10354 25.3237 9.18526 24.6622 9.80613L19.8092 14.3612C19.5465 14.6078 19.4267 14.963 19.4887 15.3111L20.6343 21.743C20.7905 22.6197 19.8349 23.2883 19.0173 22.8743L13.0186 19.8376C12.6939 19.6732 12.3061 19.6732 11.9814 19.8376L5.98274 22.8743C5.16512 23.2883 4.20952 22.6197 4.36567 21.743L5.51131 15.3111C5.57332 14.963 5.45345 14.6078 5.19078 14.3612L0.337781 9.80613C-0.323689 9.18526 0.0413191 8.10354 0.955449 7.97563L7.66213 7.03723C8.02513 6.98644 8.33893 6.7669 8.50127 6.45016L11.5006 0.59822Z"
        fill="#FFCC01"
      />
    </svg>
  );
};

export default Star;

import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '4px 0px 20px',
  },
  img: {
    width: '315px',
    height: '175px',
    display: 'block',
    borderRadius: '8px',
  },
  button: {
    width: 'auto !important',
    padding: '0px 15px',
  },
}));

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  results: {
    position: 'relative',
    zIndex: 1,
    flexGrow: 1,
    padding: '35px 15px',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: '30px',
    },
  },

  spacer: {
    flexGrow: 1,
  },

  questionText: {
    whiteSpace: 'pre-wrap',
    color: '#1B2036',
    padding: '20px',
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '24px',
    backgroundColor: 'white',
    textAlign: 'center',
    borderRadius: '20px',
    maxWidth: '528px',
    marginBottom: '15px',
    animationDuration: '0.5s',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '33px',
      letterSpacing: '-0.7px',
    },
  },

  barsContainer: {
    width: '345px',
    height: '160px',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFF',
    borderRadius: '20px',
    animationDuration: '0.5s',
    [theme.breakpoints.up('sm')]: {
      width: '528px',
      height: '250px',
      alignItems: 'flex-end',
    },
  },

  barsWrapper: {
    borderBottom: '1px solid #F3F4F5',
    width: '90%',
    height: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'flex-end',
    justifyContent: 'space-evenly',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '45px',
    },

    '& > div': {
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: '75px',
      borderRadius: '4px 4px 0px 0px',
      '&:first-child': {
        height: '75px',
        backgroundColor: '#2C74F3',
        [theme.breakpoints.up('sm')]: {
          height: '120px',
        },
        '& > span': {
          color: 'white',
        },
      },
      '&:last-child': {
        height: '25px',
        backgroundColor: '#D7E4FD',
        [theme.breakpoints.up('sm')]: {
          height: '40px',
        },
        '& > span': {
          marginTop: '5px',
          color: '#2C74F3',
        },
      },
      [theme.breakpoints.up('sm')]: {
        width: '115px',
      },
      '& > span': {
        fontFamily: 'FinkHeavy',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '24px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '36px',
          lineHeight: '36px',
        },
      },
      '& > b': {
        position: 'absolute',
        bottom: '-20px',
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#1B2036',
        [theme.breakpoints.up('sm')]: {
          fontSize: '18px',
          lineHeight: '21px',
          bottom: '-30px',
        },
      },
    },
  },

  buttonsContainer: {
    marginTop: '30px',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    animationDuration: '0.5s',
    '& > div:first-child': {
      marginBottom: '15px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '30px',
      },
      '& > span': {
        color: '#7B61FF',
      },
    },
  },

  button: {
    display: 'inline-flex',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    height: '64px',
    minWidth: '165px',
    borderRadius: '20px',
    paddingLeft: '30px',
    paddingRight: '30px',
    cursor: 'pointer',
    transition: 'all .23s',
    [theme.breakpoints.up('sm')]: {
      height: '104px',
      paddingLeft: '50px',
      paddingRight: '50px',
    },
    '&:hover': {
      '& > span': {
        color: '#7B61FF',
      },
    },
    '& > span': {
      fontFamily: 'FinkHeavy',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '23px',
      color: '#2C74F3',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px',
        lineHeight: '40px',
      },
    },
  },
}));

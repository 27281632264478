import { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import SpinButtonImg from '@art/images/quiz/quiz-spin-button.svg';
import RaptorImgMobile from '@art/images/quiz/raptor-mobile.png';
import RaptorImgTablet from '@art/images/quiz/raptor-tablet.png';
import { styles } from './styles';
import { QuizClassicState } from '@shared/gameInterfaces/quizClassic.socket';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { useAppSelector } from '@app/hooks';

interface Props {
  game: QuizClassicState;
  isSpin: boolean;
  isActivePlayer: boolean;
  onSpin: () => void;
}

const StartRound: React.FC<Props> = ({ game, isSpin, isActivePlayer, onSpin }) => {
  const classes = styles();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { roundNumber, totalRounds } = game;
  const [isButtonClicked, setButtonClicked] = useState(false);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const onSpinClick = () => {
    setButtonClicked(true);
    onSpin();
  };

  const showTitleEn = roundNumber === totalRounds ? 'Final round' : `Round ${roundNumber} of ${totalRounds}`;
  const showTitleRu = roundNumber === totalRounds ? 'Финальный раунд' : `Раунд ${roundNumber} из ${totalRounds}`;
  return (
    <div className={`${classes.startWrapper} animate__animated  animate__fadeInDown`}>
      <h2 className={`${classes.startTitle} mobile-outline`}>{isEnLocale ? showTitleEn : showTitleRu}</h2>

      <div className={classes.spinWrapper}>
        {/* raptor */}
        <img
          className={classes.raptorImg}
          src={isMobile ? RaptorImgMobile : RaptorImgTablet}
          style={{ animationName: isSpin ? 'rotating-360' : '' }}
          alt="raptor.png"
        />

        {/* spin button */}
        {isActivePlayer && (
          <img
            src={SpinButtonImg}
            onClick={onSpinClick}
            className={`${classes.spinButton} ${isButtonClicked ? 'clicked' : ''}`}
          />
        )}
      </div>
    </div>
  );
};

export { StartRound };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  whoDrinksBlock: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#1B2036',
    marginTop: '20px',
    marginBottom: '30px',
    width: '100%',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      marginTop: '40px',
    },

    '& > b': {
      fontSize: '15px',
      lineHeight: '18px',
      fontWeight: 700,
      flexShrink: 0,
    },

    '& > svg': {
      marginBottom: '10px',
      marginRight: 8,
    },

    '& > p': {
      margin: 0,
      marginLeft: '5px',
      fontWeight: 500,
      fontSize: '17px',
      lineHeight: '17px',
      overflow: 'hidden',
      display: 'block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
        lineHeight: '29px',
      },
    },
  },
}));

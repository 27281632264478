import { getIsPlayerLoading } from '@store/reducers/loadActions';
import { AppDispatch, AppGetState } from '@src/app/store';
import { analytics } from '@services/amplitude';
import { ILocalPlayer, selectLocalPlayer } from '@store/reducers/player.reducer';
import { AUTH_TYPE } from '@src/shared/common';
import { requestPlayer } from '@src/graphql/player.queries';

const getPublicId = (localPlayer: ILocalPlayer, authType: AUTH_TYPE, autocreate: boolean) => {
  return authType === AUTH_TYPE.AUTH ||
    (authType !== AUTH_TYPE.UID && localPlayer.authType !== AUTH_TYPE.UID && !autocreate)
    ? ''
    : localPlayer.publicId;
};

export const loginThunk = (
  name: string | undefined,
  onLogin: () => void,
  onReject: () => void,
  autocreate: boolean,
  authType: AUTH_TYPE
) => (dispatch: AppDispatch, getState: AppGetState) => {
  // check if already loading state.loader.login

  const isLogging = getIsPlayerLoading(getState());
  if (isLogging) {
    console.log('Already logging');
    return;
  }

  const onLoginInternal = (playerId: string, isNewPlayer: boolean) => {
    analytics.startSession(playerId, isNewPlayer);
    onLogin();
  };

  const localPlayer = selectLocalPlayer(getState());
  // const publicId = getPublicId(localPlayer!, authType, autocreate);
  const publicId = localPlayer?.publicId || '';
  const variables = {
    publicId,
    playerData: { name },
    autocreate,
  };

  dispatch(requestPlayer(variables, onLoginInternal, onReject));
};

export const authLoginThunk = (shouldAutologin = false) =>
  loginThunk(
    '',
    () => {},
    () => {},
    shouldAutologin,
    AUTH_TYPE.AUTH
  );
export const oldLoginThunk = () =>
  loginThunk(
    '',
    () => {},
    () => {},
    false,
    AUTH_TYPE.UID
  );

import { hasResizeObserver } from '@common/compatibility';
import { CanvasDrawApi } from '@src/features/canvas-draw/components/CanvasDrawApi';
import { useMediaQuery } from '@material-ui/core';
import { Line } from '@src/features/canvas-draw/types';
import { styles } from './styles';
import { useDeviceSize } from '@src/common/screenSizes';
import { useAppSelector } from '@src/app/hooks';
import { selectDrawConfig } from '@src/store/reducers/common';
import { useProtobuf } from '@src/services/globalContextProvider';

type Props = {
  isActivePlayer: boolean;
  canvasApi: any;
  menuItem: string;
  brushColor: string;
  brushRadius: number;
  onLiveUpdate: (line: Line) => void;
  onLineFinish: (line: ArrayBufferLike) => void;
};

const CanvasDraw: React.FC<Props> = ({
  isActivePlayer,
  canvasApi,
  menuItem,
  brushColor,
  brushRadius,
  onLiveUpdate,
  onLineFinish,
}) => {
  const classes = styles();
  const protobufRoot = useProtobuf();
  const isSupportResize = hasResizeObserver();
  const drawConfig = useAppSelector(selectDrawConfig);
  const { isMobile, isTablet, isDesktop } = useDeviceSize();
  const isDesktop1100 = useMediaQuery('(min-width: 1100px)');
  const isDesktop1200 = useMediaQuery('(min-width: 1200px)');
  const isDesktop1300 = useMediaQuery('(min-width: 1300px)');

  // canvas wrapper proportions: 1,25(w) x 1,00(h)
  const addWidth = () => {
    if (isDesktop) {
      if (isDesktop1300) return '625px';
      if (isDesktop1200) return '500px';
      if (isDesktop1100) return '375px';
      return '325px';

      // return isActivePlayer ? '31vw' : '40vw';
    } else return '100vw';
  };

  const addHeight = () => {
    if (isDesktop) {
      if (isDesktop1300) return '500px';
      if (isDesktop1200) return '400px';
      if (isDesktop1100) return '300px';
      return '260px';

      // return isActivePlayer ? '25vw' : '32vw';
    } else return '100%';
  };

  // fix Safari resizing (no VW only PX)
  const addWidthOldSafari = () => {
    if (isMobile) return '375px';
    else if (isTablet) return '768px';
    else if (isDesktop) return '625px';
  };

  const addHeightOldSafari = () => {
    if (isMobile) return '300px';
    else if (isTablet) return '605px';
    else if (isDesktop) return '500px';
  };

  /* canvas wrapper proportions: 1,25(w) x 1,00(h) */

  return (
    <div
      className={`
        ${classes.canvasDrawApiWrapper}
        ${isActivePlayer ? 'isActivePlayer' : ''}
      `}
      style={{
        width: isSupportResize ? addWidth() : addWidthOldSafari(),
        height: isSupportResize ? addHeight() : addHeightOldSafari(),
      }}
    >
      {/* canvas */}
      <CanvasDrawApi
        ref={canvasApi}
        protobufRoot={protobufRoot}
        hideGrid={true}
        canvasWidth="100%"
        canvasHeight="100%"
        lazyBrushRadius={0}
        brushColor={menuItem === 'erase' ? '#FFFFFF' : brushColor}
        brushRadius={brushRadius}
        onLiveUpdate={onLiveUpdate}
        onLineFinish={onLineFinish}
        hideInterface={!isActivePlayer}
        name={`CanvasDrawApi for ${isActivePlayer}`}
        {...drawConfig}
      />
    </div>
  );
};

export { CanvasDraw };

import { Artist, FakeArtist } from './parts';

interface Props {
  isFakeArtist: boolean;
}

const ShowRole: React.FC<Props> = ({ isFakeArtist }) => {
  if (isFakeArtist) {
    return <FakeArtist />;
  } else {
    return <Artist />;
  }
};

export { ShowRole };

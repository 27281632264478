// import { GAME_STATUS } from '@common/constants/games';
import { IdType } from '../generics';
import { ILeaderboard, IReward, ITimer } from '../common';
import { BaseGameState } from '../socketEvents/socketEvents';

export enum QUIZ_CLASSIC_STATE {
  READY_START_ROUND = 'READY_START_ROUND',
  SPIN = 'SPIN',
  ROUND_IN_PROGRESS = 'ROUND_IN_PROGRESS',
  RESULTS = 'RESULTS',
  COMMON_RESULTS = 'COMMON_RESULTS',
  TOP_PLAYERS = 'TOP_PLAYERS',
  LEADERBOARD = 'LEADERBOARD',
}

export interface QuizClassicState extends BaseGameState {
  gameState: QUIZ_CLASSIC_STATE;
  roundNumber: number;
  totalRounds: number;
  activePlayerId: IdType;
  ticket?: {
    question: string;
    answers: string[];
  };
  rightAnswer?: number;
  votedPlayers?: IdType[];
  readyPlayers?: IdType[];
  leaderboard?: ILeaderboard;
  reward?: IReward;
  timer?: ITimer;
  playerAnswers?: {
    playerId: IdType;
    answer: number;
  }[];
}

import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  addFakeBalance: {
    position: 'absolute',
    // left: '20px',
    right: '50px',
    // top: '30px',
    color: '#2C74F3',
    fontSize: '14px',
    cursor: 'pointer',
    textShadow: '1px 1px 2px white',
  },
}));

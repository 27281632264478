import { IdType, ObjectType } from './generics';
import { IString } from './misc/string.types';

export enum QUEST_TYPE {
  PLAY = 'PLAY',
  FIRST = 'FIRST',
  SEND_STICKER = 'SEND_STICKER',
  SEND_MONEY = 'SEND_MONEY',
  RECIEVE_MONEY = 'RECIEVE_MONEY',
  SPEND_MONEY = 'SPEND_MONEY',
  CLAIM_MONEY = 'CLAIM_MONEY',
  WITHDRAW_MONEY = 'WITHDRAW_MONEY',
  LINK = 'LINK',
  RATE = 'RATE',
  CHANGE_NAME = 'CHANGE_NAME',
  CHANGE_AVATAR = 'CHANGE_AVATAR',
  CONNECT_WALLET = 'CONNECT_WALLET',
  INVITE = 'INVITE',
  COMPLETE_ALL = 'COMPLETE_ALL',
  DEBUG = 'DEBUG',
}

export enum QUEST_STATE {
  ONCE = 'ONCE',
  DAILY = 'DAILY',
  DONE = 'DONE',
  CLAIMED = 'CLAIMED',
}

export enum QUEST_COST {
  FREE = 'FREE',
  PAID = 'PAID',
}

export enum PLAY_QUEST_FAMILY {
  PARTY = 'PARTY',
  UNITY = 'UNITY',
}

export enum PLAY_QUEST_OWNER {
  OWNER = 'OWNER',
  GUEST = 'GUEST',
}

export enum PLAY_QUEST_PUBLICITY {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export interface IQuestReward {
  coins?: number;
  energy?: number;
}

export interface IPlayerQuest {
  id: IdType;
  type: QUEST_TYPE;
  state: QUEST_STATE;
  value: number;
  target: number;
  reward: IQuestReward;
  data: ObjectType;
}

export type PlayQuestData = {
  gameId: IdType;
  gameName: string;
  minFriends: number;
  family: PLAY_QUEST_FAMILY;
  owner: PLAY_QUEST_OWNER;
  publicity: PLAY_QUEST_PUBLICITY;
};

export interface PlayQuest extends IPlayerQuest {
  data: PlayQuestData;
}

export interface LinkQuest extends IPlayerQuest {
  data: {
    text: IString;
    link: string;
  };
}

export interface CostQuest extends IPlayerQuest {
  data: {
    cost: QUEST_COST;
  };
}

import { AppDispatch } from '@src/app/store';
import { FAKE_ARTIST_STATE, FakeArtistState } from '@shared/gameInterfaces/fakeArtist.socket';
import { updateGameState } from '@store/reducers/game.reducer';

enum LABELS {
  READY = 'Ready',
  ACTIVE = 'Active',
  VOTED = 'Voted',
  SPECTATOR = 'Spectator',
}

const updateFakeArtistLabelsThunk = (game: FakeArtistState, isSpectator: boolean) => (dispatch: AppDispatch) => {
  const { gameState, readyPlayers, votedPlayers } = game;

  // ACTIVE
  const activeState = [FAKE_ARTIST_STATE.DRAW].includes(gameState);

  // VOTE
  const voteState = [FAKE_ARTIST_STATE.VOTE].includes(gameState);

  // READY
  const readyState = [FAKE_ARTIST_STATE.TOP_PLAYERS, FAKE_ARTIST_STATE.LEADERBOARD].includes(gameState);

  const labels = new Map<string, string>();

  // SPECTATOR
  if (isSpectator) {
    labels.set(game.activePlayerId!, LABELS.SPECTATOR);
  }

  // ACTIVE
  if (activeState) {
    labels.set(game.activePlayerId!, LABELS.ACTIVE);
  }

  // VOTE
  if (voteState) {
    votedPlayers?.forEach((playerId) => labels.set(playerId, LABELS.VOTED));
  }

  // READY
  if (readyState) {
    readyPlayers!.forEach((playerId) => labels.set(playerId, LABELS.READY));
  }

  dispatch(updateGameState({ playerLabels: [...labels.entries()] }));
};

export { updateFakeArtistLabelsThunk };

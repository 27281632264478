import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  btnContainer: {
    boxSizing: 'border-box',
    width: '100%',
    '& > div': {
      flexShrink: 1,
    },
  },

  voteBtn: {
    display: 'inline-flex',
    padding: '0 10px',
    flexGrow: 0,
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '48px',
    backgroundColor: '#2C74F3',
    color: 'white',
    borderRadius: '4px',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    marginRight: '5px',
    transitionProperty: 'all',
    transitionDuration: '.23s',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      minHeight: '44px',
      fontSize: '14px',
      lineHeight: '19px',
    },
    '&.no-one': {
      backgroundColor: 'transparent',
      '& > span': {
        color: '#D80001',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: '0 0 0 1px #D80001',
        '& > span': {
          color: 'D80001',
        },
      },
      '&:active': {
        backgroundColor: 'transparent',
        boxShadow: '0 0 0 1px #D80001',
        '& > span': {
          color: 'D80001',
        },
      },
    },

    '& > span': {
      textAlign: 'center',
      display: 'inline-flex',
      maxHeight: '40px',
      overflow: 'hidden',
      wordBreak: 'break-word',
    },

    '&:hover': {
      backgroundColor: '#356FD6',
    },
    '&:active': {
      backgroundColor: '#3662AE',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

import { makeStyles } from '@material-ui/core';

import GraySkull from '@art/images/fake-artist/gray-skull.svg';
import RedSkull from '@art/images/fake-artist/red-skull.svg';
import SkipCheck from '@art/images/fake-artist/skip-check.png';
import PenPattern from '@art/images/fake-artist/pen-pattern.svg';

export const styles = makeStyles((theme) => ({
  voteModalContent: {
    width: '100%',
    padding: '15px',
    position: 'relative',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '480px',
      padding: '25px 25px 20px',
    },

    '& > h2': {
      marginTop: '0px',
      marginBottom: '15px',
      padding: '0 30px',
      fontSize: '24px',
      lineHeight: '29px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '25px',
        padding: '0 50px',
      },
    },

    '& > p': {
      textAlign: 'center',
      color: '#5F6372',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '19px',
      margin: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '24px',
      },
    },
  },

  voteModalButtons: {
    flexDirection: 'column',

    '& > button': {
      width: '100% !important',
      marginBottom: '10px',
    },
  },

  voteForPlayerButtons: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  voteForPlayerButton: {
    position: 'relative',
    textAlign: 'left',
    flexBasis: '49%',
    minHeight: '54px',
    backgroundColor: '#F7F7F7',
    border: '1px solid #000',
    boxShadow: 'inset 0px 2px 0px rgba(255, 255, 255, 0.4), inset 0px -4px 0px rgba(248, 159, 38, 0.8)',
    borderRadius: '10px',
    marginBottom: '10px',
    padding: '8px 12px',
    transition: 'all .23s',
    '&:hover': {
      backgroundColor: '#EAF1FE',
    },

    '&.isButtonClicked': {
      backgroundColor: '#EAF1FE',
      cursor: 'default',
      pointerEvents: 'none',
    },

    '& > span': {
      display: 'block',
      height: '36px',
      width: '140px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: '#1B2036',
      fontWeight: 700,
      fontSize: '15px',
      lineHeight: '18px',
      [theme.breakpoints.up('sm')]: {
        width: '180px',
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
  },

  voteModalTimerContainer: {
    position: 'absolute',
    top: '17px',
    right: '15px',
    [theme.breakpoints.up('sm')]: {
      top: '25px',
      right: '25px',
    },
  },

  skipButton: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    padding: '0 16px',
    alignItems: 'center',
    backgroundColor: '#008AFF',
    color: 'white',
    width: '100%',
    height: '54px',
    borderRadius: '10px',
    cursor: 'pointer',
    transition: 'all 0.23s',
    marginBottom: '10px',
    boxShadow: 'inset 0px -5px 0px #0243A5, inset 0px 25px 0px #00ABFF',
    border: '1px solid #000',
    fontSize: '32px',
    lineHeight: '32px',
    textShadow: '0px 2px 0px #000',
    '&.isButtonClicked': {
      cursor: 'default',
      pointerEvents: 'none',
    },
    '&:hover, &:focus': {
      backgroundColor: '#0243A5',
      boxShadow: 'none',
    },
  },

  skipCount: {
    color: 'inherit',
  },

  skipCheck: {
    width: '24px',
    height: '20px',
    backgroundImage: `url(${SkipCheck})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },

  skulls: {
    position: 'absolute',
    minWidth: '20px',
    height: '21px',
    right: '5px',
    bottom: '6px',
  },

  graySkull: {
    width: '20px',
    height: '21px',
    backgroundImage: `url(${GraySkull})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },

  redSkull: {
    width: '20px',
    height: '21px',
    backgroundImage: `url(${RedSkull})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    marginLeft: '5px',
  },

  votingSkiped: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    height: '70px',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#2c74f3',
    animationDuration: '1000ms',
    backgroundImage: `url(${PenPattern})`,
    backgroundSize: 'cover',
    borderRadius: '20px',
    [theme.breakpoints.up('sm')]: {
      height: '85px',
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '20px 20px 0 0',
    },
    '& > b': {
      fontFamily: 'SF Pro Rounded',
      fontSize: '24px',
      lineHeight: '29px',
      fontWeight: 700,
      color: 'white',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px',
        lineHeight: '43px',
      },
    },
  },
}));

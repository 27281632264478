import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  chestsHeader: {
    fontFamily,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '14px',
    paddingRight: '14px',
    position: 'absolute',
    zIndex: 8,
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '42px',
    backgroundColor: '#FFF',
    borderBottom: '1px solid #E8E9EB',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      height: '53px',
    },
  },

  balance: {
    flexDirection: 'column',
    '& > span': {
      color: '#5F6372',
      fontWeight: 'bold',
      fontSize: '10px',
      lineHeight: '12px',
      textTransform: 'uppercase',
      [theme.breakpoints.up('sm')]: {
        fontSize: '12px',
        lineHeight: '14px',
      },
    },

    '& > div': {
      marginTop: '2px',
      alignItems: 'center',
      '& > img': {
        width: '15px',
        height: '15px',
        marginRight: '3px',
        [theme.breakpoints.up('sm')]: {
          width: '18px',
          height: '18px',
        },
      },
      '& > b': {
        color: '#1B2036',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '12px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '16px',
          lineHeight: '16px',
        },
      },
    },
  },

  roundTitle: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '17px',
    color: '#1B2036',
    margin: 0,
    marginRight: '17px',
    '&::selection': {
      background: 'transparent',
    },
    '& > span': {
      color: '#2C74F3',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '29px',
      marginRight: '26px',
    },
  },

  timerContainer: {
    width: '28px',
    height: '28px',
  },
}));

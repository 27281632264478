import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  chestsWrapper: {
    position: 'relative',
    width: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

import { ITimer } from '../common';
import { IdType } from '../generics';
import { BaseGameState } from '../socketEvents/socketEvents';

export enum CHESTS_STATE {
  READY_START_ROUND = 'READY_START_ROUND',
  ROUND_IN_PROGRESS = 'ROUND_IN_PROGRESS',
  LEADERBOARD = 'LEADERBOARD',
}

export enum CHEST_TYPE {
  TINY,
  SMALL,
  MEDIUM,
  LARGE,
}

export enum REWARD_TYPE {
  BOMB,
  MONEY,
  TREASURE,
  NFT,
}

export interface Reward {
  rewardType: REWARD_TYPE;
  value: number | string;
}

export interface Chest {
  chestType: CHEST_TYPE;
  reward?: Reward;
}

export interface ChestsState extends BaseGameState {
  gameState: CHESTS_STATE;
  roundNumber: number;
  totalRounds: number;
  stakeAmount: number;
  stakedPlayers: IdType[];
  skippedPlayers: IdType[];
  activePlayerId?: IdType;
  chests?: Chest[];
  leaderboard?: {
    name: string;
    value: number;
    place: number;
  }[];
  timer?: ITimer;
}

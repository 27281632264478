import React, { useEffect, useRef, useState } from 'react';
import Unity, { UnityContext } from 'react-unity-webgl';
import { useResizeObserver } from '@features/canvas-draw/hooks';
import { isMobile, isSafari, withOrientationChange } from 'react-device-detect';
import { useStyles } from './styles';
import { useAppSelector, useAppDispatch } from '@src/app/hooks';
import { selectWrapperOffsets } from '@store/reducers/session.reducer';
import { Game } from '@src/graphql/queries';
import UnityStartModal from '../../components/Modals/UnityModals/StartModal';
import UnityFinishModal from '../../components/Modals/UnityModals/FinishModal';
import TransparentConnectToServerLoader from '../../components/ConnectToServerLoader/transparentLoader';
import { loadGameBalance } from '@src/graphql/loadHomePageData';
import { IdType } from '@src/shared/generics';
import { useGameProvider } from './UnityGame.provider';
import { GAME_LAYOUT } from '@src/common/constants/common';
import { useImageHook } from '@src/components/entities/Image';
import { analytics } from '@src/services/amplitude';
import WSClient from '@src/sockets';
import { CLIENT_EVENTS } from '@src/shared/socketEvents/eventTypes';
import { selectPlayerId } from '@src/store/reducers/player.reducer';

interface Props {
  isLandscape?: boolean;
  game: Game;
  roomId: IdType;
}

const createContext = (link: string | undefined) =>
  new UnityContext({
    loaderUrl: `${link}/WebGL.loader.js`,
    dataUrl: `${link}/WebGL.data`,
    frameworkUrl: `${link}/WebGL.framework.js`,
    codeUrl: `${link}/WebGL.wasm`,
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

const UnityPageInternal: React.FC<Props> = ({ isLandscape = false, game, roomId }) => {
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const wrapperOffsets = useAppSelector(selectWrapperOffsets);
  const playerId = useAppSelector(selectPlayerId);
  const [gameState] = useGameProvider(roomId);
  const unityData = game.unityData!;

  const [progression, setProgression] = useState(0);
  const [isContextLoaded, setIsContextLoaded] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [windowInnerWidth, setWindowInnerWidth] = useState(null);
  const [width, setWidth] = useState('100%'); // Default for GAME_LAYOUT.STRETCH
  const [height, setHeight] = useState('100%'); // Default for GAME_LAYOUT.STRETCH
  const [showInput, setShowInput] = useState(false);

  const [state] = useState({ gameObjectName: '', result: 0, activeGameId: '' });
  const [name, setName] = useState('');
  const [isStartModalOpen, setIsStartModalOpen] = useState(false);
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);

  const resizeObserver = useResizeObserver(() => onSizeChange());
  const styles = useStyles();

  const ASPECT_RATIO = 9.0 / 16.0;
  const MAX_ASPECT_RATIO = 3.0 / 4.0;
  const [unityContext, _] = useState(createContext(unityData.link));
  const background = useImageHook(game?.background);

  const onSizeChange = () => {
    //@ts-ignore
    setWindowInnerWidth(window.innerWidth);

    if (window && unityData.layout !== GAME_LAYOUT.STRETCH) {
      const placeHorizontal = () => {
        const width = window.innerWidth - wrapperOffsets.right;
        const height =
          window.innerHeight - wrapperOffsets.top - wrapperOffsets.bottom / width > MAX_ASPECT_RATIO
            ? width * ASPECT_RATIO
            : window.innerHeight - wrapperOffsets.top - wrapperOffsets.bottom;

        setWidth(`${width}px`);
        setHeight(`${height.toFixed()}px`);
      };

      const placeVertical = () => {
        const verticalHeight = window.innerHeight - wrapperOffsets.top - wrapperOffsets.bottom;
        const verticalWidth =
          verticalHeight / window.innerWidth - wrapperOffsets.right > 1 / MAX_ASPECT_RATIO
            ? window.innerWidth - wrapperOffsets.right
            : verticalHeight * ASPECT_RATIO;

        setWidth(`${verticalWidth.toFixed()}px`);
        setHeight(`${verticalHeight}px`);
      };

      const isVertical = unityData.layout === GAME_LAYOUT.VERTICAL;
      isVertical ? placeVertical() : placeHorizontal();
    }
  };

  const requestGameBalance = () => {
    setIsDataLoading(true);
    return dispatch(loadGameBalance).then(() => setIsDataLoading(false));
  };

  const startGame = () => {
    setIsStartModalOpen(false);
    unityContext.send(state.gameObjectName, 'startGame');
  };

  const finishGame = () => {
    setIsFinishModalOpen(false);
    unityContext.send(state.gameObjectName, 'finishGame');
  };

  useEffect(() => {
    unityContext.on('progress', (progression) => setProgression(progression));
    unityContext.on('loaded', () => {
      console.log('ON LOADED');
      setIsContextLoaded(true);
    });

    unityContext.on('canvas', (canvas) => {
      onSizeChange();
    });

    unityContext.on('SettingsLoadedEvent', (gameObjectName: string) => {
      unityContext.send(gameObjectName, 'setParticleSystemEnabled', (!isSafari).toString());
    });

    unityContext.on('MobileTextInputEvent', (gameObjectName: string, currentText: string) => {
      if (isMobile) {
        setName(currentText);
        setShowInput(true);
        state.gameObjectName = gameObjectName;
      } else {
        unityContext.send(gameObjectName, 'closeTextInputInstantly');
      }
    });

    unityContext.on('GameStartEvent', (gameObjectName: string) => {
      state.gameObjectName = gameObjectName;
      requestGameBalance().then(() => setIsStartModalOpen(true));
    });

    unityContext.on('GameFinishEvent', (gameObjectName: string, result: number) => {
      state.gameObjectName = gameObjectName;
      state.result = result;
      requestGameBalance().then(() => setIsFinishModalOpen(true));

      WSClient.emit(CLIENT_EVENTS.PLAYER_FINISHED, {
        activeGameId: state.activeGameId,
        playerId,
        result,
      });
      analytics.logEvent(analytics.EVENTS.PLAYER_FINISHED_GAME, { gameType: game.type, gameId: game.id });
    });

    return () => {
      unityContext.removeAllEventListeners();
      unityContext.quitUnityInstance();
    };
  }, [wrapperOffsets]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click();
    }
  }, [showInput]);

  useEffect(() => {
    if (containerRef.current) {
      resizeObserver?.observe(containerRef.current);
      return () => resizeObserver?.disconnect();
    }
  }, [containerRef.current]);

  useEffect(() => {
    if (!resizeObserver) {
      onSizeChange();
    }
  }, [isLandscape, resizeObserver]);

  useEffect(() => {
    state.activeGameId = gameState.activeGameId;
  }, [gameState.activeGameId]);

  const DEFAULT_LOADING_WIDTH = 350;
  const loadingWidth = Math.min(0.67 * windowInnerWidth!, DEFAULT_LOADING_WIDTH);
  const loadingWidthFill = loadingWidth * progression;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setName(event.target.value);
    unityContext.send(state.gameObjectName, 'updateTextInput', name);
  };

  const onCloseInput = () => {
    unityContext.send(state.gameObjectName, 'applyTextInput', name);
    setShowInput(false);
  };

  const onKeyUpInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    console.log(e);
    if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.key === 'Enter') {
      onCloseInput();
    }
  };

  return (
    <div
      className={styles.root}
      ref={containerRef}
      style={{
        height: window.innerHeight - wrapperOffsets.top - wrapperOffsets.bottom,
        width: window.innerWidth - wrapperOffsets.right,
      }}
    >
      {!isContextLoaded && (
        <div className={styles.background} style={{ backgroundImage: background }}>
          <img src={game.logo} className={styles.logoUnity} />
          {loadingWidth && (
            <div className={styles.loading} style={{ width: `${loadingWidth}px` }}>
              <div className={`${styles.loading} fill`} style={{ width: `${loadingWidthFill}px` }} />
            </div>
          )}
        </div>
      )}

      <Unity
        unityContext={unityContext}
        style={{ width: width, height: height, display: !isContextLoaded ? 'none' : 'flex' }}
      />

      {showInput && (
        <input
          ref={inputRef}
          className={styles.input}
          type="text"
          placeholder="your name"
          value={name}
          onChange={onChange}
          onKeyUp={onKeyUpInput}
          autoFocus
          onBlur={onCloseInput}
        />
      )}

      {isDataLoading && <TransparentConnectToServerLoader />}

      <UnityStartModal
        isOpen={isStartModalOpen}
        handleClose={() => setIsStartModalOpen(false)}
        game={game}
        startGame={startGame}
      />
      <UnityFinishModal
        isOpen={isFinishModalOpen}
        handleClose={() => setIsFinishModalOpen(false)}
        game={game}
        activeGameId={gameState.activeGameId}
        result={state.result}
        finishGame={finishGame}
      />
    </div>
  );
};

const UnityPage = withOrientationChange(UnityPageInternal);

export { UnityPage };

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { analytics } from '@services/amplitude';
import { Start, Question, Results } from './parts';
import { styles } from './styles';
import { mutateGraphql } from '@library/fetch';
import { postTap } from '@graphql/debugQueries';

const LandingPage3: React.FC = () => {
  const history = useHistory();
  const classes = styles();
  const [screen, setScreen] = useState('game-start');
  const version = 3;
  const questionText =
    'You make $ 10,000 a month, but ...\n\
    Your boss periodically sends his homemade porn to you, asks you to rate his technique in 10 and to give a constructive feedback\n\
    \n\
    Will you quit this job?';

  const buttons = [
    { title: 'Quit', value: 75 },
    { title: 'Stay', value: 25 },
  ];

  useEffect(() => {
    analytics.logEvent(analytics.EVENTS.INTRO_SHOWN, { version });
  }, []);

  useEffect(() => {
    if (screen !== 'game-start') return;

    const onAnyTap = (x: number, y: number) => {
      const tap = { type: 3, x: Number(x.toFixed()), y: Number(y.toFixed()) };
      analytics.logEvent(analytics.EVENTS.TAP, { ...tap });
      return mutateGraphql(postTap, { ...tap });
    };

    const touchCallback = (event: TouchEvent) => {
      const x = (event.touches[0].clientX / document.body.offsetWidth) * 100;
      const y = (event.touches[0].clientY / document.body.offsetHeight) * 100;
      onAnyTap(x, y);
    };

    const clickCallback = (event: MouseEvent) => {
      const x = (event.clientX / document.body.offsetWidth) * 100;
      const y = (event.clientY / document.body.clientHeight) * 100;
      onAnyTap(x, y);
    };

    document.body.addEventListener('touchstart', touchCallback);
    document.body.addEventListener('mousedown', clickCallback);

    return () => {
      document.body.removeEventListener('touchstart', touchCallback);
      document.body.removeEventListener('mousedown', clickCallback);
    };
  }, [screen]);

  const onStartClick = () => {
    analytics.logEvent(analytics.EVENTS.INTRO_GO, { version });
    setScreen('question');
  };

  const onAnswerClick = (index: number) => {
    analytics.logEvent(analytics.EVENTS.INTRO_ANSWER_CLICKED, { answer: index, version });
    setScreen('results');
  };

  const onResultClick = (buttonType: string) => {
    analytics.logEvent(analytics.EVENTS.INTRO_RESULT_CLICKED, { button: buttonType, version });
    history.push('/');
  };

  return (
    <div className={classes.landingPage3}>
      <div className={classes.landingPage3Wrapper}>
        {/* game-start */}
        {screen === 'game-start' && <Start onStartClick={onStartClick} />}

        {/* Question screen */}
        {screen === 'question' && (
          <Question questionText={questionText} onAnswerClick={onAnswerClick} buttons={buttons} />
        )}

        {/* Results screen */}
        {screen === 'results' && (
          <Results questionText={questionText} onResultClick={onResultClick} buttons={buttons} />
        )}
      </div>
    </div>
  );
};

export default LandingPage3;

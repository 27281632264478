import { QuizClassicState, QUIZ_CLASSIC_STATE } from '@shared/gameInterfaces/quizClassic.socket';

export const initialMapState = {
  READY_START_ROUND: false,
  SPIN: false,
  ROUND_IN_PROGRESS: false,
  COMMON_RESULTS: false,
  RESULTS: false,
  TOP_PLAYERS: false,
  LEADERBOARD: false,
};

// export interface ITicketData {
//   ticketData?: {
//     answerType: string;
//     answers: string[];
//     gifTags: any;
//     options: {
//       title: string;
//     }[];
//     question: string;
//     rightAnswer: number;
//     title: string;
//     totalRounds: number;
//     timer: undefined;
//   };
// }

// export interface IQuestionResults {
//   questionResults: {
//     place: number;
//     name: string;
//     points: number;
//     type: string;
//   }[];
// }

export const defaultGameState: QuizClassicState = {
  gameState: QUIZ_CLASSIC_STATE.READY_START_ROUND,
  activePlayerId: '',
  activeGameId: '',
  roundNumber: 1,
  totalRounds: 5,
};

export type MapState = typeof initialMapState;

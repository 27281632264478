import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  canvasWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      minHeight: '100%',
      borderRadius: '0 20px 20px 0',
      overflow: 'hidden',
    },
  },
}));

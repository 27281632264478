import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  voteList: {
    minHeight: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& > h2': {
      marginTop: 0,
      marginBottom: '20px',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '28px',
      color: '#1B2036',
      maxWidth: '320px',
      borderRadius: '20px',
      backgroundColor: 'white',
      padding: '8px 15px',
      [theme.breakpoints.up('sm')]: {
        padding: '10px 15px',
        fontSize: '28px',
        lineHeight: '33px',
        marginBottom: '30px',
        maxWidth: '450px',
      },

      [theme.breakpoints.up('md')]: {
        maxWidth: '550px',
        padding: 0,
        borderRadius: 0,
        backgroundColor: 'transparent',
      },
    },
  },

  voteListTable: {
    maxWidth: '245px',
    width: '100%',
    flexShrink: 0,
    marginBottom: '25px',
    flexDirection: 'column',
    backgroundColor: 'white',
    border: '1px solid #E8E9EB',
    borderRadius: '15px',
    padding: '15px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '320px',
      marginBottom: '16px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '50px',
      border: 'none',
    },
  },

  voteTableRow: {
    position: 'relative',
    justifyContent: 'space-between',
    padding: '15px 0px 15px 10px',
    borderBottom: '1px solid #E8E9EB',
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0,
    },
    '&:first-child': {
      paddingTop: 0,
    },
    '& > p': {
      fontFamily: fontFamily,
      fontSize: '15px',
      lineHeight: '21px',
      margin: '0px',
      fontWeight: 400,
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '27px',
      },
    },
    '& > div': {
      width: '80px',
      padding: '0px 8px',
      borderRadius: '4px',
      overflow: 'hidden',
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('sm')]: {
        width: '100px',
      },
      '& > span': {
        fontSize: '12px',
        lineHeight: '23px',
        fontWeight: 500,
        color: 'white',
        textOverflow: 'ellipsis',
        width: '100%',
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
          fontSize: '14px',
          lineHeight: '27px',
        },
      },
    },
  },
  buttonWrapper: {
    position: 'relative',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '56px',
    [theme.breakpoints.up('sm')]: {
      minHeight: '60px',
    },
    [theme.breakpoints.up('md')]: {
      height: '50px',
    },

    // button
    '& > button': {
      border: '10px solid white',
      height: '56px',
      [theme.breakpoints.up('sm')]: {
        height: '60px',
      },
      [theme.breakpoints.up('md')]: {
        border: 'none',
        height: '40px',
      },
    },

    // after click text
    '& > span': {
      fontFamily,
      color: 'white',
      fontWeight: 800,
      fontSize: '15px',
      lineHeight: '18px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
      [theme.breakpoints.up('md')]: {
        color: '#5F6372',
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
  },
}));

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  desktopMenuWord: {
    position: 'relative',
    width: '100%',
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '185px',
    borderRadius: '10px',
    backgroundImage: 'linear-gradient(180deg, #FFD957 0, #CAC6FA 80%, #CAC6FA 100%)',
  },

  wordWrapper: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',

    // for active
    '& > b': {
      fontFamily: 'Nunito',
      textTransform: 'uppercase',
      color: '#ABA8DC',
      fontWeight: 900,
      fontSize: '32px',
      lineHeight: '44px',
      textAlign: 'center',
      wordBreak: 'break-word',
      '&::selection': {
        backgroundColor: 'transparent',
      },
    },
  },

  // for passive
  passivePlayerInfo: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#1B2036',
  },

  activePlayerName: {
    color: '#2C74F3',
    width: '250px',
  },

  desktopMenuButton: {
    position: 'relative',
    flexGrow: 1,
    marginTop: '5%',
    marginBottom: '5%',
    '& > button': {
      width: '120px',
      alignSelf: 'flex-start',
      '&.isModalOpen': {
        backgroundColor: 'transparent !important',
        '& > span': {
          color: '#F83E67',
          fontWeight: 600,
        },
      },
    },
  },
}));

import { fontFamily } from '@src/art/theme/textStyles';
import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  profileWrapper: {
    position: 'relative',
    flexDirection: 'column',
    background: 'linear-gradient(180deg, #E8FDFF 0%, #BCE1FC 100%)',
    borderRadius: '20px 20px 0px 0px',
    maxHeight: '400px',
    [theme.breakpoints.up('sm')]: {
      borderRadius: '20px',
      // height: '100%',
    },
  },

  profileTabs: {
    background: '#F5F3DF',
    paddingBottom: '8px',
    paddingLeft: '15px',
    paddingRight: '15px',
    borderBottom: '1px solid #AE5D02',
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {},
    '& > p': {
      fontFamily: 'Nunito Sans',
      fontWeight: 900,
      fontSize: '17px',
      lineHeight: '23px',
      marginRight: '20px',
      color: '#D9B787',
      textTransform: 'uppercase',
      cursor: 'pointer',
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '27px',
        marginRight: '27px',
      },
      '&.active': {
        color: '#1B2036',
        cursor: 'default',
        pointerEvents: 'none',
      },
      '&:last-child': { marginRight: 0 },
    },
  },
}));

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  questionWrapper: {
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '644px',
    },
  },

  questionInner: {
    flexGrow: 1,
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '300px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '550px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '50px',
    },

    '& > span': {
      display: 'inline-flex',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      color: '#5F6372',
      marginBottom: '10px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '14px',
        fontSize: '20px',
        lineHeight: '24px',
      },
    },

    '& > p': {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '28px',
      color: '#1B2036',
      wordBreak: 'break-word',
      margin: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
      },
    },
  },

  buttonsContainer: {
    width: '100%',
    color: 'inherit',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '&.clicked > div': {
      pointerEvents: 'none',
      cursor: 'default',
    },
    '&.isSpectator > div': {
      pointerEvents: 'none',
      cursor: 'default',
      backgroundColor: '#D7E4FD !important',
    },
  },

  answerButton: {
    position: 'relative',
    padding: '0 15px',
    flexBasis: 'calc(50% - 7.5px)',
    flexShrink: 1,
    minHeight: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    transitionProperty: 'background-color',
    transitionDuration: '.23s',
    cursor: 'pointer',
    boxSizing: 'border-box',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 0',
    [theme.breakpoints.up('sm')]: {
      minHeight: '80px',
    },
    '&:nth-child(1)': {
      marginBottom: '15px',
    },
    '&:nth-child(2)': {
      marginBottom: '15px',
    },
    '&:hover': {
      backgroundColor: '#356FD6',
    },
    '&:active': {
      backgroundColor: '#3662AE',
    },
    '&.is-voted': {
      backgroundColor: '#EAF1FE',
      color: '#BBBCC3',
    },

    '& > img': {
      position: 'absolute',
      top: '-12px',
      left: '-12px',
    },

    '& > span': {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
      textAlign: 'center',
      display: 'inline-flex',
      maxHeight: '40px',
      overflow: 'hidden',
      wordBreak: 'break-word',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
  },
}));

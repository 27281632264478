import { CSSProperties } from '@material-ui/styles';
import { MixProps } from '@src/common/generics';
import React from 'react';
import { styles } from './styles';

type Props = {
  show: boolean;
  onClick: () => void;
};

const AddFakePoints: React.FC<MixProps<Props>> = ({ show, onClick, children, style }) => {
  const classes = styles();

  if (show) {
    return (
      <span onClick={onClick} className={classes.addFakeBalance} style={style}>
        {children}
      </span>
    );
  } else return null;
};

export default AddFakePoints;

import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  singlePage: {
    fontFamily,
    display: 'flex',
    minHeight: '100%',
    minWidth: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundImage: 'linear-gradient(180deg, #EBF6FF 0%, #ACD8FD 98.44%)',
    boxSizing: 'border-box',
    '& *': { boxSizing: 'border-box' },
    [theme.breakpoints.up('md')]: {
      overflowY: 'initial',
      padding: '15px',
    },
  },

  singlePageWrapper: {
    flexGrow: 1,
    width: '100%',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    [theme.breakpoints.up('md')]: {
      flexGrow: 0,
      maxWidth: '975px',
      // flexBasis: '500px',
      borderRadius: '20px',
      backgroundColor: 'white',
    },
  },

  questionWrapper: {
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '620px',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      padding: '30px',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignContent: 'baseline',
    },
  },

  questionText: {
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: '10px',
    maxWidth: '315px',
    flexGrow: 1,
    flexBasis: '100%',
    [theme.breakpoints.up('sm')]: {
      flexGrow: 'auto',
      marginBottom: '55px',
      maxWidth: '600px',
    },
    '& > p': {
      whiteSpace: 'pre-wrap',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 500,
      color: '#1B2036',
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
      },
    },

    '&.large': {
      maxWidth: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '100%',
      },
      '& > p': {
        fontSize: '16px',
        lineHeight: '21px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '21px',
          lineHeight: '27px',
        },
      },
    },
  },

  questionTextResults: {
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: '10px',
    maxWidth: '315px',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '600px',
    },
    '& > p': {
      whiteSpace: 'pre-wrap',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 500,
      color: '#1B2036',
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
      },
    },

    '&.large': {
      maxWidth: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '100%',
      },
      '& > p': {
        fontSize: '16px',
        lineHeight: '21px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '21px',
          lineHeight: '27px',
        },
      },
    },
  },

  btnContainer: {
    boxSizing: 'border-box',
    width: '100%',
    '& > div': {
      flexShrink: 1,
    },
    '&.clicked > div': {
      pointerEvents: 'none',
      cursor: 'default',
      boxSizing: 'border-box',
      '&.not-clicked-btn': {
        backgroundColor: 'transparent',
        color: '#BBBCC3',
        '&:hover': {
          backgroundColor: 'inherit',
        },
        '&:active': {
          backgroundColor: 'inherit',
        },
      },
    },
  },

  voteBtn: {
    display: 'inline-flex',
    padding: '0 15px',
    flexGrow: 0,
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '48px',
    backgroundColor: '#2C74F3',
    color: 'white',
    borderRadius: '4px',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '22px',
    marginRight: '5px',
    transitionProperty: 'background-color',
    transitionDuration: '.23s',
    cursor: 'pointer',

    '& > span': {
      textAlign: 'center',
      display: 'inline-flex',
      maxHeight: '40px',
      overflow: 'hidden',
      wordBreak: 'break-word',
    },

    '&:hover': {
      backgroundColor: '#356FD6',
    },
    '&:active': {
      backgroundColor: '#3662AE',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },

  resultsWrapper: {
    position: 'relative',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      padding: '30px',
    },
  },

  spacer: {
    flexGrow: 1,
  },

  barsContainer: {
    width: '345px',
    height: '160px',
    alignItems: 'flex-end',
    justifyContent: 'center',
    background: '#FFF',
    borderRadius: '20px',
    marginTop: '15px',
    marginBottom: '15px',
    paddingBottom: '30px',
    [theme.breakpoints.up('sm')]: {
      width: '528px',
      height: '230px',
      paddingBottom: '40px',
    },
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      marginTop: '55px',
      marginBottom: '25px',
    },
  },

  barsWrapper: {
    borderBottom: '1px solid #F3F4F5',
    // outline: '1px solid red',
    width: '90%',
    height: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'flex-end',
    justifyContent: 'space-evenly',

    '& > div': {
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: '75px',
      borderRadius: '4px 4px 0px 0px',
      '&:first-child': {
        height: '75px',
        backgroundColor: '#2C74F3',
        [theme.breakpoints.up('sm')]: {
          height: '120px',
        },
        '& > span': {
          color: 'white',
        },
      },
      '&:last-child': {
        height: '25px',
        backgroundColor: '#D7E4FD',
        [theme.breakpoints.up('sm')]: {
          height: '40px',
        },
        '& > span': {
          color: '#5F6372',
        },
      },
      [theme.breakpoints.up('sm')]: {
        width: '115px',
      },
      '& > span': {
        fontWeight: 'bold',
        fontSize: '15px',
        lineHeight: '18px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '24px',
          lineHeight: '29px',
        },
      },
      '& > b': {
        position: 'absolute',
        bottom: '-16px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '12px',
        color: '#1B2036',
        [theme.breakpoints.up('sm')]: {
          fontSize: '15px',
          lineHeight: '21px',
          bottom: '-30px',
        },
      },
    },
  },

  buttonsContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },

  purple: {
    height: '36px',
    width: '216px',
    backgroundImage: 'linear-gradient(77.61deg, #8E78FF 0%, #FC7D7B 100%)',
    borderRadius: '30px',
    marginBottom: '15px',
    cursor: 'pointer',
    transition: 'all .23s',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '248px',
      marginBottom: '20px',
    },
    '&:hover, &:focus': {
      boxShadow: '0 0 10px 1px white',
    },
    '&:active': {
      boxShadow: '0 0 10px 1px white',
    },
    '& > a': {
      width: '100%',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& span': {
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      color: '#fff',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
  },

  blue: {
    height: '36px',
    width: '165px',
    backgroundColor: '#2C74F3',
    borderRadius: '30px',
    cursor: 'pointer',
    transition: 'all .23s',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '148px',
    },
    '&:hover, &:focus': {
      backgroundColor: '#356FD6',
    },
    '&:active': {
      backgroundColor: '#3662AE',
    },
    '& > a': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
    },
    '& span': {
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      color: '#fff',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
  },
}));

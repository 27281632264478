import { makeStyles } from '@material-ui/core';

import Pen from '@art/images/fake-artist/pen.svg';
import PenPattern from '@art/images/fake-artist/pen-pattern.svg';

export const styles = makeStyles((theme) => ({
  TimeAlert: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    height: '70px',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F83E67',
    backgroundImage: `url(${PenPattern})`,
    animationDuration: '1000ms',
    [theme.breakpoints.up('sm')]: {
      height: '85px',
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '0 20px 0 0',
    },
  },

  TimeAlertText: {
    textAlign: 'center',
    paddingLeft: '15px',
    paddingRight: '15px',
    fontFamily: 'SF Pro Rounded',
    fontSize: '24px',
    lineHeight: '29px',
    fontWeight: 700,
    color: 'white',
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
      lineHeight: '43px',
    },
  },
}));

import { ILeaderboard, IReward, ITimer } from '../common';
import { IdType } from '../generics';
import { BaseGameState } from '../socketEvents/socketEvents';
// import { ITimer } from '../common';

export enum ROULETTE_TICKET_TYPE {
  STATIC = 'static',
  YOU_DRINK = 'youDrink',
  PLAYERS_LIST = 'playersList',
}

export enum DRINK_ROULETTE_STATE {
  UNKNOWN = 'UNKNOWN', // новая кнопка старт
  GAME_START = 'GAME_START', // новая кнопка старт
  READY_START_ROUND = 'READY_START_ROUND',
  SPIN = 'SPIN',
  ROUND_IN_PROGRESS = 'ROUND_IN_PROGRESS',
  SKIP_RESULTS = 'SKIP_RESULTS',
  SHOW_RESULTS = 'SHOW_RESULTS',
  COMMON_RESULTS = 'COMMON_RESULTS', // %-ты результат голосования
  TOP_PLAYERS = 'TOP_PLAYERS',
  LEADERBOARD = 'LEADERBOARD',
}

// GAME_START --> SPIN --> ROUND_IN_PROGRESS --> COMMON_RESULTS (Финишный экран пока неясно) Single player Cofluence

export interface RouletteVotedPlayer {
  playerId: IdType;
  value: number;
}

export interface DrinkRouletteState extends BaseGameState {
  gameState: DRINK_ROULETTE_STATE;
  roundNumber: number;
  totalRounds: number;
  activePlayerId: IdType;
  isSinglePlayer?: boolean;
  ticket?: {
    question: string;
    answers: string[]; // may be players ids for multiplayers
    commonAnswers: number[];
    ticketType: ROULETTE_TICKET_TYPE;
  };
  results?: IdType[][]; // [0] - кто проголовал за 1-й вариант, [1] - за 2-ой
  votedPlayers?: RouletteVotedPlayer[]; //
  readyPlayers?: IdType[];
  drinkPlayers?: IdType[];
  leaderboard?: ILeaderboard;
  reward?: IReward;
  timer?: ITimer;
}

import { IRoomPlayer } from '@store/room/roomSlice';
import { DrinkRouletteState } from '@shared/gameInterfaces/roulette.socket';
import { Bars, WhoDrinks, Buttons } from './parts';
import { styles } from './styles';

interface IProps {
  playerId: string;
  gameState: DrinkRouletteState;
  players: IRoomPlayer[];
  shouldDrink: boolean;
  isSpectator: boolean;
  isShowResults: boolean;
  onCheers: () => void;
}

const MultiResults: React.FC<IProps> = ({
  gameState,
  playerId,
  players,
  shouldDrink,
  isSpectator,
  isShowResults,
  onCheers,
}) => {
  const classes = styles();
  const { ticket, drinkPlayers, results, readyPlayers } = gameState;
  // const { maxVotes, playersNamesByVotesArray, voteData, showWhoDrinks } = useTemplateResults();

  return (
    <div className={`${classes.resultsWrapper} animate__animated  animate__fadeIn`}>
      <p className={classes.questionText}>{ticket?.question}</p>

      <div className={classes.resultsWhiteContainer}>
        {isShowResults && <Bars gameState={gameState} results={results} players={players} />}

        {shouldDrink && <WhoDrinks drinkPlayers={drinkPlayers} players={players} />}
      </div>

      <Buttons
        playerId={playerId}
        onCheers={onCheers}
        shouldDrink={shouldDrink}
        isSpectator={isSpectator}
        readyPlayers={readyPlayers}
        isShowResults={isShowResults}
        questionType={ticket?.ticketType}
      />
    </div>
  );
};

export { MultiResults };

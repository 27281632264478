import React, { useEffect, useState } from 'react';
import { MixProps } from '@src/common/generics';
import { PotCoinMobile, PotWithGoldMobile, BombBoomGif, BombBoomTrack } from '../../helpers/chests.images';
import { styles } from './styles';

interface BombProps {
  src: string;
}

const Bomb: React.FC<MixProps<BombProps>> = (props) => {
  const [data, setData] = useState('');
  const [id] = useState(Math.random());

  useEffect(() => {
    fetch(props.src, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
    })
      .then((res) => res.blob())
      .then(async (text) => {
        const reader = new FileReader();
        reader.onload = (ev) => {
          const result = ev.target?.result?.toString() || '';
          setData(result.replace('data:image/gif;base64', ''));
        };
        reader.readAsDataURL(text);
      });
  }, []);

  if (!data) return <></>;

  return <img src={`data:image/gif;gif-id=${id};base64` + data} className={props.className} />;
};

export const BombReward: React.FC<MixProps> = ({ className }) => {
  const classes = styles();
  return (
    <div className={className}>
      <img
        src={BombBoomTrack}
        className={`${classes.boomTrack} animate__animated animate__zoomIn`}
        alt="BombBoomTrack"
      />

      <Bomb src={BombBoomGif} className={classes.bombBoom} />
    </div>
  );
};

export const MoneyReward: React.FC<MixProps<{ value: number | string }>> = ({ value, className }) => {
  return (
    <div className={`${className} animate__animated animate__zoomIn`}>
      <img src={PotCoinMobile} alt="PotCoinMobile" />
      <span>{value}</span>
    </div>
  );
};

export const TreasureReward: React.FC<MixProps> = ({ className }) => {
  return (
    <div className={`${className} animate__animated animate__zoomIn`}>
      <img src={PotWithGoldMobile} alt="PotWithGoldMobile" />
    </div>
  );
};

export const NftReward: React.FC<MixProps> = ({ className }) => {
  return <div className={`${className} animate__animated animate__zoomIn`}>NFT</div>;
};

import muteVideoOnIcon from '@art/icons/PersonControls/mute-video-on.svg';
import muteAudioOnIcon from '@art/icons/PersonControls/mute-audio-on.svg';
import { styles } from './styles';
import { useDeviceSize } from '@src/common/screenSizes';

interface IProps {
  playerName: string;
  muteAudioCondition: boolean;
  muteVideoCondition: boolean;
}

const PersonName: React.FC<IProps> = ({ playerName, muteAudioCondition, muteVideoCondition }) => {
  const classes = styles();
  const { isMobile } = useDeviceSize();

  return (
    <span className={`${classes.personName} personName`}>
      <span className={`${classes.personNameWrapper} ${muteAudioCondition ? 'muteAudio' : ''}`}>
        {isMobile && (
          <div className={'mute'}>
            {muteVideoCondition && !muteAudioCondition && <img src={muteVideoOnIcon} />}
            {muteAudioCondition && <img src={muteAudioOnIcon} />}
          </div>
        )}
        {playerName}
      </span>
    </span>
  );
};

export default PersonName;

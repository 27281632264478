const halfDivider = 2;
const fifthDivider = 5;

const showBackgroundColor = (timerCountDown: number, countDown: number) => {
  const timerDiv = timerCountDown / countDown;

  // green (50-100%)
  if (timerDiv < halfDivider) {
    return '#3AB957';

    // red (0)
  } else if (timerDiv === Infinity) {
    return '#F83E67';

    // red (1-20%)
  } else if (timerDiv >= fifthDivider) {
    return '#F83E67';

    // yellow (20-50%)
  } else if (timerDiv >= halfDivider) {
    return '#FFCC01';
  }
};

const showTextColor = (timerCountDown: number, countDown: number) => {
  const timerDiv = timerCountDown / countDown;

  // green (50-100%)
  if (timerDiv < halfDivider) {
    return '#5F6372';

    // gray (0)
  } else if (timerDiv === Infinity) {
    return 'white';

    // red (1-20%)
  } else if (timerDiv >= fifthDivider) {
    return 'white';

    // yellow (20-50%)
  } else if (timerDiv >= halfDivider) {
    return '#5F6372';
  }
};

export { showBackgroundColor, showTextColor };

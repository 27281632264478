import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  gameContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
  },
}));

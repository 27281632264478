import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { MouseEventHandler } from 'react';
import { styles } from './styles';

interface Props {
  className?: string;
  onClick: MouseEventHandler;
}

const ClearAllButton: React.FC<Props> = ({ className, onClick }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <button className={`${classes.clearAllButton} ${className} clearAllButton`} onClick={onClick}>
      <span>{isEnLocale ? 'Clear All' : 'Очистить'}</span>
    </button>
  );
};

export { ClearAllButton };

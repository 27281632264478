import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  statusBarTitle: {
    overflow: 'hidden',
    cursor: 'pointer',
    '& svg': {
      marginRight: '10px',
      flexShrink: 0,
    },

    '& span': {
      display: 'block',
      marginTop: '2px',
      fontSize: '17px',
      lineHeight: '17px',
      fontWeight: 500,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
}));

import { isSafari } from 'react-device-detect';
import React, { useEffect, useMemo, useState } from 'react';
import { CommonModal } from '@components/Modals';
import Button from '@components/Button';
import { styles } from './styles';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { useAppSelector } from '@app/hooks';
import CloseCross from '@src/components/CloseCross/CloseCross';
import { coffee, kebab, pizza, vacation } from '@art/images/modals/donate';
import WSClient from '@src/sockets';
import { SERVER_EVENTS } from '@src/shared/socketEvents/eventTypes';
import { PaymentServerEvent } from '@src/shared/socketEvents/socketEvents';
import { requestDonationPayment } from '@src/graphql/queries';
import { IS_DEBUG } from '@src/common/constants/system';
import { ThanksModal } from './thanksModal';
import { useCallback } from 'react';

interface Props {
  isOpen: boolean | undefined;
  handleClose: () => void;
}

const SMALL_SUM = 1;
const MIDDLE_SUM = 5;
const BIG_SUM = 10;

const chosenImage = (donation: number | string) => {
  switch (donation) {
    case SMALL_SUM:
      return coffee;
    case MIDDLE_SUM:
      return kebab;
    case BIG_SUM:
      return pizza;
    default:
      return vacation;
  }
};

const debugLink = `https://sandbox-secure.xsolla.com/paystation3/?access_token=`;
const prodLink = `https://secure.xsolla.com/paystation3/?access_token=`;

const getButtonText = (isEnLocale: boolean, isPayButton: boolean) => {
  const donateText = isEnLocale ? 'Donate' : 'Донат';
  const requestText = isEnLocale ? 'Next' : 'Далее';
  return isPayButton ? donateText : requestText;
};

const DonateModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const [donation, setDonation] = useState<number | string>(SMALL_SUM);
  const [value, setValue] = React.useState<number | string>('');
  const [isThanksModalOpen, setIsThanksModalOpen] = React.useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isAnimationStarted, setIsAnimationStarted] = useState(true);
  const [token, setToken] = useState<string | undefined>(undefined);
  const buttonText = getButtonText(isEnLocale, !isSafari || Boolean(token));
  const imageSrc = chosenImage(donation);

  useEffect(() => {
    const subscription = ({ data }: { data: PaymentServerEvent }) => {
      if (data.success) {
        setIsThanksModalOpen(true);
      }
    };
    WSClient.on(SERVER_EVENTS.PAYMENT, subscription);
    return () => WSClient.off(SERVER_EVENTS.PAYMENT, subscription);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setToken(undefined);
    }
  }, [isOpen]);

  const goToDonation = (token: string | undefined) => {
    if (token) {
      window.open((IS_DEBUG ? debugLink : prodLink) + token);
      //setIsDisabled(false);
      handleClose();
    }
  };

  const onClick = () => {
    setIsDisabled(true);
    requestDonationPayment(Number(donation)).then((token) => {
      isSafari ? setToken(token) : goToDonation(token);
      setIsDisabled(false);
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(event.target.value) || 0);
    setDonation(parseInt(event.target.value) || 0);
  };

  const onDonationClick = (element: any) => {
    const sum = element.target.innerText.replace(/[^0-9]/g, '');
    setDonation(Number(sum));
    setValue('');
    setIsAnimationStarted(true);
  };

  const onHandlerClick = () => {
    setDonation(value);
    setIsAnimationStarted(true);
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        handleClose={handleClose}
        backgroundColor={'linear-gradient(180deg, #E8FDFF 0%, #BCE1FC 100%)'}
        className={classes.modalWrapper}
      >
        <div className={classes.wrapper}>
          <CloseCross onClick={handleClose} />
          <h3 id="transition-modal-title">{isEnLocale ? 'Donate' : 'Донат'}</h3>
          {/* {image} */}
          <img
            className={isAnimationStarted ? 'animation' : ''}
            src={imageSrc}
            alt=""
            onAnimationEnd={() => setIsAnimationStarted(false)}
          />
          <div className={classes.handler}>
            <div
              className={donation === SMALL_SUM ? 'active' : ''}
              onClick={(element) => onDonationClick(element)}
              style={{ pointerEvents: token ? 'none' : 'auto' }}
            >{`$ ${SMALL_SUM}`}</div>
            <div
              className={donation === MIDDLE_SUM ? 'active' : ''}
              onClick={(element) => onDonationClick(element)}
              style={{ pointerEvents: token ? 'none' : 'auto' }}
            >{`$ ${MIDDLE_SUM}`}</div>
            <div
              className={donation === BIG_SUM ? 'active' : ''}
              onClick={(element) => onDonationClick(element)}
              style={{ pointerEvents: token ? 'none' : 'auto' }}
            >{`$ ${BIG_SUM}`}</div>
          </div>
          <div className={classes.customInput}>
            <input
              onChange={handleChange}
              value={value.toString()}
              id="value"
              name="value"
              type="number"
              autoComplete="off"
              maxLength={10}
              inputMode="numeric"
              placeholder={isEnLocale ? 'your own amount' : 'своя сумма'}
              onClick={onHandlerClick}
              disabled={Boolean(token)}
            />
            <p className={'currency'}>$</p>
          </div>
          <Button
            type="primary-green-new"
            text={buttonText}
            onClick={token ? () => goToDonation(token) : onClick}
            disabled={isDisabled}
          />
        </div>
      </CommonModal>

      <ThanksModal isOpen={isThanksModalOpen} handleClose={() => setIsThanksModalOpen(false)} />
    </>
  );
};

export default DonateModal;

import { QuizBattleRoyaleState, QUIZ_BATTLE_ROYALE_STATE } from '@shared/gameInterfaces/battleRoyale.socket';

export const initialMapState = {
  READY_START_ROUND: false,
  SPIN: false,
  ROUND_IN_PROGRESS: false,
  SHOWING_RIGHT_ANSWER: false,
  QUESTION_RESULTS: false,
  ROUND_RESULTS: false,
  LEADERBOARD: false,
  LOOP_RESULTS: false,
};

export type IGameStateMatcher = typeof initialMapState;

export interface ITicketData {
  ticketData?: {
    answerType: string;
    answers: string[];
    gifTags: any;
    options: {
      title: string;
    }[];
    question: string;
    rightAnswer: number;
    title: string;
    totalRounds: number;
    timer: undefined;
  };
}

export interface IQuestionResults {
  questionResults: {
    place: number;
    name: string;
    points: number;
    type: string;
  }[];
}

export const defaultGameState: QuizBattleRoyaleState = {
  gameState: QUIZ_BATTLE_ROYALE_STATE.READY_START_ROUND,
  activeGameId: '',
  activePlayerId: '',
  roundNumber: 0,
  totalRounds: 0,
  activeQuestion: 0,
  countQuestions: 0,
  loopCurrent: 0,
  ticketData: undefined,
  votedPlayers: [],
  readyPlayers: [],
  timer: undefined,
  questionResults: undefined,
  roundResults: undefined,
  finalLeaderboard: undefined,
};

export interface IGameState extends QuizBattleRoyaleState {
  state: IGameStateMatcher;
}

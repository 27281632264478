import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  question: {
    position: 'relative',
    zIndex: 1,
    flexGrow: 1,
    paddingTop: '20px',
    width: '100%',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      paddingTop: '30px',
    },
  },

  spacer: {
    flexGrow: 1,
  },

  questionTextContainer: {
    flexShrink: 0,
    position: 'relative',
    zIndex: 1,
    alignSelf: 'center',
    width: '100%',
    paddingLeft: '15px',
    paddingRight: '15px',
    textAlign: 'center',
    animationDelay: '0.5s',
    animationDuration: '0.5s',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '558px',
    },
    '& > p': {
      whiteSpace: 'pre-wrap',
      width: '100%',
      margin: 0,
      backgroundColor: 'white',
      padding: '15px',
      borderRadius: '20px',
      fontWeight: 800,
      fontSize: '20px',
      lineHeight: '24px',
      [theme.breakpoints.up('sm')]: {
        padding: '30px',
        fontSize: '28px',
        lineHeight: '33px',
      },
    },
  },

  name: {
    position: 'absolute',
    zIndex: 2,
    left: '35px',
    top: '-15px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'linear-gradient(77.61deg, #7B61FF 0%, #9146FF 51.56%, #7B61FF 100%)',
    color: 'white',
    fontWeight: 800,
    fontSize: '15px',
    lineHeight: '18px',
    paddingLeft: '15px',
    paddingRight: '15px',
    minHeight: '28px',
    borderRadius: '20px',
    [theme.breakpoints.up('sm')]: {
      left: '45px',
      fontSize: '20px',
      lineHeight: '24px',
      minHeight: '36px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },

  triangle: {
    position: 'absolute',
    zIndex: 2,
    right: '75px',
    bottom: '-40px',
    borderWidth: '20px',
    borderStyle: 'solid',
    borderColor: 'white transparent transparent transparent',
  },

  dogContainer: {
    display: 'block',
    position: 'relative',
    zIndex: 1,
    alignSelf: 'flex-end',
    flexGrow: 0,
    flexShrink: 1,
    marginTop: '-20px',
    animationDelay: '0.25s',
    animationDuration: '0.5s',
    [theme.breakpoints.up('sm')]: {
      marginTop: '-110px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '-90px',
      width: '100%',
      justifyContent: 'center',
    },
  },

  dog: {
    width: '310px',
    height: 'auto',
    marginBottom: '-5px',
    [theme.breakpoints.up('sm')]: {
      width: '525px',
    },
    [theme.breakpoints.up('md')]: {
      width: '820px',
      marginLeft: '34%',
      // marginLeft: '500px',
    },
  },

  buttonsContainer: {
    position: 'absolute',
    zIndex: 3,
    bottom: '30px',
    width: '100%',
    justifyContent: 'center',
    animationDelay: '0.5s',
    animationDuration: '0.5s',
    '&.isQuestionClicked': {
      '& > div': {
        pointerEvents: 'none',
        cursor: 'default',
        '& > span': {
          color: '#D7E4FD',
        },
      },
    },
    '&.buttons-2': {
      '& > div:first-child': {
        borderRadius: '20px 0 0 20px',
        marginRight: '-1px ',
      },
      '& > div:last-child': {
        borderRadius: '0 20px 20px 0',
      },
    },
    '&.buttons-4': {
      flexWrap: 'wrap',
      width: '346px',
      left: '50%',
      marginLeft: '-173px',
      [theme.breakpoints.up('sm')]: {
        width: '528px',
        marginLeft: '-264px',
      },
      '& > div': {
        flexBasis: '50%',
        '&:nth-child(1)': { borderRadius: '20px 0 0 0' },
        '&:nth-child(2)': { borderRadius: '0 20px 0 0' },
        '&:nth-child(3)': { borderRadius: '0 0 0 20px' },
        '&:nth-child(4)': { borderRadius: '0 0 20px 0' },
      },
    },
    [theme.breakpoints.up('sm')]: {
      bottom: '50px',
    },
    [theme.breakpoints.up('md')]: {
      bottom: '60px',
    },
  },

  button: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    height: '64px',
    minWidth: '165px',
    borderRadius: '20px',
    border: 0,
    outline: 0,
    paddingLeft: '15px',
    paddingRight: '15px',
    cursor: 'pointer',
    transition: 'all .23s',
    margin: '-1px',
    '&.active': {
      '& > span': {
        color: '#2C74F3 !important',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: '268px',
      height: '104px',
    },
    '&:hover': {
      '& > span': {
        color: '#7B61FF',
      },
    },
    '& > span': {
      fontFamily: 'FinkHeavy',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '23px',
      color: '#2C74F3',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px',
        lineHeight: '40px',
      },
    },
  },
}));

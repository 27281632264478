import { BombMobile } from '../../../../helpers/chests.images';

import { styles } from './styles';

const Bombs = () => {
  const classes = styles();

  return (
    <div className={classes.bombs}>
      {[1, 2, 3].map((bomb, index) => {
        return (
          <img
            key={index}
            src={BombMobile}
            alt="BombMobile"
            style={{ animationDelay: `${(index + 1) * 250}ms` }}
            className={`
                ${classes.bombImage}
                animate__animated
                animate__zoomIn
              `}
          />
        );
      })}
    </div>
  );
};

export { Bombs };

import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

const DrawAndGuessRules: React.FC = () => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();

  return (
    <>
      {isEnLocale ? (
        <ul className={classes.list}>
          <li>An active player gets a secret word to explain</li>
          <li>An active player should explain the secret word to the other players by drawing it</li>
          <li>All other players should guess the secret word</li>
          <li>An active player and the one who guessed the word get points</li>
          <li>If no one guessed correctly, no one gets points</li>
        </ul>
      ) : (
        <ul className={classes.list}>
          <li>Активный игрок получает секретное слово для объяснения</li>
          <li>Активный игрок должен объяснить секретное слово другим игрокам, нарисовав его</li>
          <li>Все остальные игроки должны отгадать секретное слово</li>
          <li>Активный игрок и игрок, угадавший слово, получает очки</li>
          <li>Если никто не угадал слово, то очки не начисляются никому</li>
        </ul>
      )}
    </>
  );
};

export { DrawAndGuessRules };

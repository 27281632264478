import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  timeIsUp: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 3,
    left: 0,
    right: 0,
    top: 0,
    color: 'white',
    height: '40px',
    backgroundColor: '#F83E67',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
    [theme.breakpoints.up('md')]: {
      width: '250px',
      marginLeft: '-115px',
      left: '50%',
      right: 'auto',
    },
  },
}));

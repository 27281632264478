import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GamesContainer } from '@components/entityContainers/games';
import { RoomsContainer } from '@components/entityContainers/rooms';
import { selectIsInRoom } from '@store/room/roomSlice';
import { SET_TYPES } from '@shared/homepage/configSet';
import { getHosts, getAllGames, getTopSets, selectRoomsNotOwned } from '@store/reducers/common';
import { useMediaQuery } from '@material-ui/core';
import { filterByIds } from '@common/types';
import { DEVICE_SIZE } from '@common/screenSizes';
import { mainCardStyle } from './styles';
import { analytics } from '@services/amplitude';
import { IString } from '@shared/misc/string.types';
import { useAppSelector } from '@app/hooks';
import { selectDeviceSize, selectLocale } from '@store/reducers/session.reducer';

interface IShowNumber {
  mobile?: number;
  tablet?: number;
  desktop?: number;
}

interface ITopSetFlatten {
  id: string;
  type: string;
  name: IString;
  showNumber?: IShowNumber;
  items: string[];
  [key: string]: unknown;
}

const getItemNumber = (deviceSize: DEVICE_SIZE, showNumber?: IShowNumber) => {
  if (!showNumber) {
    return undefined;
  }

  switch (deviceSize) {
    case DEVICE_SIZE.MOBILE:
      return showNumber.mobile;
    case DEVICE_SIZE.TABLET:
      return showNumber.tablet;
    case DEVICE_SIZE.DESKTOP:
      return showNumber.desktop;
  }
};

const HomePageContent: React.FC = () => {
  const classes = mainCardStyle();
  const topSet = useAppSelector(getTopSets);
  const rooms = useAppSelector(selectRoomsNotOwned);
  const parties = useAppSelector(getHosts);
  const games = useAppSelector(getAllGames);
  const locale = useAppSelector(selectLocale);
  const deviceSize = useAppSelector(selectDeviceSize);
  const isInRoom = useAppSelector(selectIsInRoom);
  const isMobile = useMediaQuery('(max-width: 599px)');
  const dispatch = useDispatch();

  useEffect(() => {
    analytics.logEvent(analytics.EVENTS.HOMEPAGE_SHOWN);
  }, []);

  const typeSetMap: any = new Map([
    [
      SET_TYPES.ROOM_SET,
      (id: string, items: any[], showNumber: number, name: string = '') => {
        const hasRooms = rooms.length > 0;
        return (
          !isInRoom &&
          hasRooms && <RoomsContainer setId={id} headline={name} rooms={rooms} showNumber={showNumber} key={'rooms'} />
        );
      },
    ],
    [
      SET_TYPES.GAME_SET,
      (id: string, items: any[], showNumber: number, name: string = 'Top Experiences') => {
        const gamesFiltered = filterByIds(items, games);
        return <GamesContainer setId={id} headline={name} games={gamesFiltered} key={id} />;
      },
    ],
  ]);

  const getTupleElement = (set: ITopSetFlatten) => {
    if (!typeSetMap.has(set.type)) {
      return;
    }

    const showNumber = getItemNumber(deviceSize, set.showNumber);
    const items = set.items.slice(0, showNumber);
    return typeSetMap.get(set.type)(set.id, items, showNumber, set.name[locale]);
  };

  const defaultFragment = (
    <>
      <GamesContainer games={games} headline="Games" canShowAll />
      {!isInRoom && <RoomsContainer rooms={rooms} showNumber={5} canShowAll />}
    </>
  );
  const componentTuple =
    topSet?.length > 0 ? topSet.map((set: ITopSetFlatten) => getTupleElement(set)) : defaultFragment;

  return (
    <div
      className={classes.rootContent}
      style={{
        marginTop: isInRoom ? 0 : undefined,
        borderRadius: isInRoom ? 0 : undefined,
        paddingTop: isMobile ? '25px' : isInRoom ? '85px' : '70px',
      }}
    >
      {componentTuple}
    </div>
  );
};

export { HomePageContent };

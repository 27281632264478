import { RoastBattleState, ROAST_BATTLE_STATE } from '@shared/gameInterfaces/roastBattle.socket';
// import { TypeInfo } from 'graphql';

export const initialMapState = {
  READY_START_ROUND: false,
  SPIN: false,
  BATTLE: false,
  VOTE: false,
  RESULTS: false,
  TOP_PLAYERS: false,
  LEADERBOARD: false,
};

export const defaultGameState: RoastBattleState = {
  gameState: ROAST_BATTLE_STATE.READY_START_ROUND,
  activeGameId: '',
  activePlayerId: '',
  roundNumber: 1,
  totalRounds: 10,
};

export type MapState = typeof initialMapState;

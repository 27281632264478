import { ShowFakeArtistInfo, ShowWordToArtists } from './parts';

interface Props {
  chosenWord?: string;
  isFakeArtist: boolean;
}

const ShowWord: React.FC<Props> = ({ chosenWord, isFakeArtist }) => {
  if (isFakeArtist) {
    return <ShowFakeArtistInfo />;
  } else {
    return <ShowWordToArtists chosenWord={chosenWord} />;
  }
};

export { ShowWord };

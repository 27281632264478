import React from 'react';
import protobuf from 'protobufjs';
import { Line } from '../types';
import { CanvasDrawContextProvider } from './CanvasDrawContext';
import CanvasDrawGrid from './CanvasDrawGrid';
import CanvasDraw from './CanvasDraw';
import CanvasDrawInterface from './CanvasDrawInterface';
import { LazyBrush } from 'lazy-brush';
// import { sendDataToServer } from '@components/VideoChat/parts/helperMethods';

type CanvasDrawApiState = {
  lines: Line[];
  liveDraw?: Line;
};

export type CanvasDrawConfig = {
  canvasWidth?: number | string;
  canvasHeight?: number | string;
  lazyBrushRadius?: number;
  brushColor?: string;
  brushRadius?: number;
};

export type CanvasDrawApiProps = CanvasDrawConfig & {
  name: string;
  hideInterface: boolean;
  hideGrid: boolean;
  gridColor?: string;
  gridSize?: number;

  onLineFinish?: (line: ArrayBufferLike) => void;
  onLiveUpdate?: (line: Line) => void;

  frameFrequency?: number;
  liveDrawFrequency?: number;
  sendFrequency?: number;
  maxLinePoints?: number;

  protobufRoot: protobuf.Root;
};

export class CanvasDrawApi extends React.Component<CanvasDrawApiProps, CanvasDrawApiState> {
  static defaultProps = {
    lazyBrushRadius: 30,
  };

  private readonly lazyBrush: LazyBrush;
  private readonly sendFrequency: number;
  private sendCounter: number;
  private protobufLine: protobuf.Type;

  constructor(props: Readonly<CanvasDrawApiProps> | CanvasDrawApiProps) {
    super(props);

    const lazyBrushRadius =
      props.lazyBrushRadius !== undefined ? props.lazyBrushRadius : CanvasDrawApi.defaultProps.lazyBrushRadius;

    this.lazyBrush = new LazyBrush({
      radius: lazyBrushRadius * window.devicePixelRatio,
    });

    this.state = {
      lines: [],
      liveDraw: undefined,
    };

    this.sendCounter = 0;
    this.sendFrequency = props.sendFrequency || 1;
    this.protobufLine = props.protobufRoot.lookupType('drawdata.Line');
  }

  componentDidMount() {
    this.setState({
      lines: [],
    });
  }

  // componentWillUnmount() {
  //   console.log('', 'Destroy canvas Draw Api');
  // }

  getLines: () => Line[] = () => {
    return this.state.lines;
  };

  addLineLocal: (line: Line) => void = (line) => {
    this.state.lines.push(line);
  };

  addLine: (line: Line) => Line[] = (line) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        lines: [...prevState.lines, line],
        liveDraw: undefined,
      };
    });
    return this.state.lines;
  };

  private decodeLine: (protoLine: ArrayBufferLike) => Line = (protoLine) => {
    const decodedLine = this.protobufLine.decode(new Uint8Array(protoLine));
    return this.protobufLine.toObject(decodedLine) as Line;
  };

  addProtobufLine: (protoLine: ArrayBufferLike) => Line[] = (protoLine) => {
    const line = this.decodeLine(protoLine);
    return this.addLine(line);
  };

  setLines: (lines: Line[]) => void = (lines) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        lines: [...lines],
        liveDraw: undefined,
      };
    });
  };

  setProtobufLines: (protoLines: ArrayBufferLike[]) => void = (protoLines) => {
    const lines = protoLines.map((line) => this.decodeLine(line));
    return this.setLines(lines);
  };

  clear = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        lines: [],
        liveDraw: undefined,
      };
    });
  };

  // Used only for outside events
  updateLiveDraw: (line: Line) => void = (line) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        liveDraw: line,
      };
    });
  };

  finishLiveDraw: () => void = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        liveDraw: undefined,
      };
    });
  };

  render() {
    return (
      <>
        <CanvasDrawContextProvider
          {...this.props}
          onLineFinish={(line) => {
            this.addLineLocal(line);
            this.sendCounter = 0;

            const protobufLine = this.protobufLine.encode(line).finish();

            if (this.props.onLineFinish) {
              this.props.onLineFinish(protobufLine.slice().buffer);
            }
          }}
          onLiveDraw={(line) => {
            if (this.props.onLiveUpdate && this.sendCounter === 0) {
              this.props.onLiveUpdate(line);
            }
            if (++this.sendCounter >= this.sendFrequency) {
              this.sendCounter = 0;
            }
          }}
        >
          <CanvasDrawGrid
            gridColor={this.props.gridColor}
            gridSize={this.props.gridSize}
            hideGrid={this.props.hideGrid}
          />

          <CanvasDraw isTemp={false} lines={this.state.lines} />

          <CanvasDraw isTemp={true} liveDrawLine={this.state.liveDraw} />

          <CanvasDrawInterface
            hideInterface={this.props.hideInterface}
            lazyBrush={this.lazyBrush}
            brushColor={this.props.brushColor}
            brushRadius={this.props.brushRadius}
            frameFrequency={this.props.frameFrequency}
          />
        </CanvasDrawContextProvider>
      </>
    );
  }
}

import React, { MouseEventHandler, useEffect } from 'react';
import { useAppSelector } from '@app/hooks';
import { selectIsRoomPublic, selectRoomName, setRoomName } from '@store/room/roomSlice';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import CommonModal from '@components/Modals/CommonModal';
import { styles } from './styles';
import { Input } from '@src/components/Inputs';
import { useDispatch } from 'react-redux';
import { useChangeRoomPublicity } from '@src/common/misc';
import { MenuPoint } from './menuPoint';
import { RoomAvatarsModal } from './roomAvatars';
import CloseCross from '@src/components/CloseCross/CloseCross';
import { RoomBackgroundModal } from './roomBackground';
import { CLIENT_EVENTS } from '@src/shared/socketEvents/eventTypes';
import WSClient from '@src/sockets';

interface Props {
  isOpen: boolean | undefined;
  handleClose: () => void;
}

const RoomPreferences: React.FC<Props> = ({ isOpen, handleClose }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const roomName = useAppSelector(selectRoomName);
  const [value, setValue] = React.useState<string>('');
  const isPublic = useAppSelector(selectIsRoomPublic);
  const { onChangeRoomPublicity } = useChangeRoomPublicity(isPublic);
  const [isAvatarsOpen, setIsAvatarsOpen] = React.useState<boolean>(false);
  const [isBackgroundsOpen, setIsBackgroundsOpen] = React.useState<boolean>(false);
  const [oldRoomName, setOldRoomName] = React.useState<string>(roomName);
  const [state, setState] = React.useState<{ roomName: string }>({ roomName: roomName });

  const onModalClose = () => {
    handleClose();
    if (oldRoomName === state.roomName) return;
    setValue(state.roomName || roomName);
    dispatch(setRoomName(state.roomName || roomName));
    WSClient.emit(CLIENT_EVENTS.PLAYER_UPDATED_ROOM, {
      title: value,
    });
  };

  const text = isPublic
    ? isEnLocale
      ? 'New players can join your room without invite'
      : 'Новые игроки могут присоединиться к твоей комнате без приглашения'
    : isEnLocale
    ? 'Only invited players can join your room'
    : 'Только приглашенные игроки могут присоединиться к комнате';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    state.roomName = event.target.value || '';
    setValue(state.roomName || roomName);
    dispatch(setRoomName(state.roomName || roomName));
  };

  const onAvatarsClick = () => {
    setIsAvatarsOpen(true);
    handleClose();
  };

  const onChangeRoomBackground = () => {
    setIsBackgroundsOpen(true);
    handleClose();
  };

  return (
    <>
      <CommonModal isOpen={isOpen} handleClose={onModalClose} padding={false} className={classes.wrapper}>
        <CloseCross onClick={onModalClose} style={{ top: '-40px', right: '5px' }} />

        <p className={classes.title}>{isEnLocale ? 'Room preferences' : 'Параметры комнаты'}</p>
        <div className={classes.container}>
          <p className={classes.inputTitle}>{isEnLocale ? `Change Room Name` : `Изменить название комнаты`}</p>
          <Input onChange={handleChange} value={value?.toString()} type="text" maxLength={80} placeholder={roomName} />

          <div className={classes.switcherContainer}>
            <div className={`${classes.switcherBar}`}>
              <div className={`mode ${isPublic ? 'active' : ''}`} onClick={onChangeRoomPublicity}>
                {isEnLocale ? 'Public' : 'Публичная'}
              </div>
              <div className={`mode ${isPublic ? '' : 'active'}`} onClick={onChangeRoomPublicity}>
                {isEnLocale ? 'Private' : 'Частная'}
              </div>
            </div>

            <div className={classes.switcherLang}>{text}</div>
          </div>
          <div className={classes.border}></div>
          <MenuPoint text={isEnLocale ? 'Change Room Avatar' : 'Изменить аватар комнаты'} onClick={onAvatarsClick} />
          <div className={classes.border}></div>
          <MenuPoint
            text={isEnLocale ? 'Change Game Background' : 'Изменить фон игры'}
            onClick={onChangeRoomBackground}
          />
        </div>
      </CommonModal>

      <RoomAvatarsModal isOpen={isAvatarsOpen} handleClose={() => setIsAvatarsOpen(false)} />
      <RoomBackgroundModal isOpen={isBackgroundsOpen} handleClose={() => setIsBackgroundsOpen(false)} />
    </>
  );
};

export default RoomPreferences;

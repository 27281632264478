import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useAppSelector } from '@src/app/hooks';
import { IdType } from '@src/shared/generics';
import { sendTokens } from '@src/shared/solana/sendTokens';
import { selectSolanaTarget, selectSolanaToken } from '@src/store/reducers/common';
import useWalletBalance from './use-wallet-balance';

export const useSolanaData = () => {
  const { tokenBalance, requestBalance } = useWalletBalance();
  const connectionContext = useConnection();
  const wallet = useWallet();
  const token = useAppSelector(selectSolanaToken);
  const target = useAppSelector(selectSolanaTarget);

  return {
    walletReady: wallet.ready,
    walletConnected: wallet.connected,
    publicKey: wallet.publicKey?.toString(),
    tokenBalance,
    requestBalance,
    isRightChain: true,
    sendTokens: (amount: number) => sendTokens(connectionContext.connection, token, target, amount, wallet),
  };
};

import { GuessUpState, GUESS_UP_STATE } from './../../shared/gameInterfaces/guessUp.socket';
// import { TypeInfo } from 'graphql';

export const initialMapState = {
  READY_START_ROUND: false,
  SPIN: false,
  SHOW_WORD: false,
  VOTE: false,
  VOTE_RESULTS: false,
  RESULTS: false,
  GAME: false,
  TOP_PLAYERS: false,
  LEADERBOARD: false,
};

export const defaultGameState: GuessUpState = {
  gameState: GUESS_UP_STATE.READY_START_ROUND,
  activeGameId: '',
  activePlayerId: '',
  roundNumber: 1,
  totalRounds: 20,
};

export type MapState = typeof initialMapState;

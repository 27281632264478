import { useAppSelector } from '@app/hooks';
import { PLAYER_STATUS } from '@src/common/constants/system';
import { PLAYER_STATE, selectRoomPlayer } from '@store/room/roomSlice';
import { selectPlayerLabels } from '@store/reducers/game.reducer';
import { styles } from './styles';

interface Props {
  playerId: string;
  // isLoading?
}

enum PLAYER_STATE_LABEL {
  OFFLINE = 'Offline',
  READY = 'Ready',
  ABSENT = 'Absent',
}

const PlayerStateLabel: React.FC<Props> = ({ playerId }) => {
  const classes = styles();
  const playerState = useAppSelector(selectRoomPlayer(playerId));
  const playerLabels = useAppSelector(selectPlayerLabels);
  const gamePlayerState = playerLabels.get(playerId);

  const getLabel = () => {
    if (!playerState) return undefined;

    if (playerState.state === PLAYER_STATE.ABSENT) return PLAYER_STATE_LABEL.ABSENT;

    if (!playerState.isOnline) return PLAYER_STATE_LABEL.OFFLINE;

    if (playerState.state === PLAYER_STATE.QUIT_READY) return PLAYER_STATE_LABEL.READY;

    return playerState.state === PLAYER_STATE.GAME ? gamePlayerState : '';
  };

  const label = getLabel();

  return label ? <span className={`${classes.personStatus} personStatus`}>{label}</span> : null;
};

export { PlayerStateLabel };

import React from 'react';
import { Card, CardActionArea, ClickAwayListener } from '@material-ui/core';
import { defaultImages } from '@src/art/resources/loadResources';
import { useStyles, gameCardSettings } from './styles/gameCard.style';
import { Game } from '@graphql/queries';
import { GameTypes, hasBalanceGame } from '@src/common/constants/common';
import { analytics } from '@services/amplitude';
import { RoomArea } from '../CreateRoomButton';
import { FROM_LOCATIONS } from '@src/common/constants/system';
import jackpot from '@art/icons/GameIntro/jackpot.png';
import HelloCoin from '@art/icons/hello-coin.svg';
import { roundNumbers } from '@src/common/misc';

interface Props {
  style?: React.CSSProperties;
  game: Game;
}

const GameCard: React.FC<Props> = ({ game }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const subpath = `/lobby/experience/${game.id}`;
  const banner = game.banner || defaultImages.banner;
  const gameTreasury = game.balance.dailyBalance;
  const roundedTreasury = roundNumbers(gameTreasury);
  const showBalance = hasBalanceGame(game.type) && gameTreasury > 0;

  const handleClickAway = () => setOpen(false);

  const onAnalyticsClick = () => {
    analytics.logEvent(analytics.EVENTS.GAME_CLICKED, { gameId: game.id, gameType: game.type });
  };

  const renderGameCard = (onClick: () => void) => {
    const onCardClick = () => {
      game.type !== GameTypes.COMING_SOON ? onClick() : setOpen(true);
    };

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Card className={classes.root} onClick={onCardClick}>
          {open && <div className={classes.tooltip}>The game will be added soon</div>}
          {open && <div className={classes.triangle}></div>}

          {showBalance && (
            <div className={classes.jackpot} style={{ backgroundImage: `url(${jackpot})` }}>
              <img alt="coin" src={HelloCoin} />
              <p className={`${roundedTreasury === '∞' ? 'infinity' : ''}`}>{roundedTreasury}</p>
            </div>
          )}
          <CardActionArea>
            <img className={classes.img} alt="experience" src={banner} />
          </CardActionArea>
        </Card>
      </ClickAwayListener>
    );
  };

  return (
    <RoomArea
      subpath={subpath}
      automatic={false}
      onClick={onAnalyticsClick}
      from={FROM_LOCATIONS.GAME_CARD}
      hash={'gamecard'}
    >
      {renderGameCard}
    </RoomArea>
  );
};

export { GameCard, gameCardSettings };

import { FakeArtistState, FAKE_ARTIST_STATE } from '@shared/gameInterfaces/fakeArtist.socket';

export const initialMapState = {
  READY_START_ROUND: false,
  SPIN: false,
  SHOW_ROLE: false,
  SHOW_WORD: false,
  DRAW: false,
  VOTE: false,
  VOTE_RESULTS: false,
  RESULTS: false,
  TOP_PLAYERS: false,
  LEADERBOARD: false,
};

export const defaultGameState: FakeArtistState = {
  gameState: FAKE_ARTIST_STATE.READY_START_ROUND,
  roundNumber: 1,
  totalRounds: 5,
  subroundNumber: 1,
  totalSubrounds: 5,
  fakeArtistId: '',
  chosenWord: '',
  activePlayerId: '',
  readyPlayers: [],
  votedPlayers: [],
  voteList: [],
  results: [],
  voteState: undefined,
  resultState: undefined,
  leaderboard: [],
  activeGameId: '',
  timer: {
    end: new Date(),
    left: 0,
    duration: 0,
    timeIsUp: false,
  },
};

export type MapState = typeof initialMapState;

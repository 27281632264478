import { useAppDispatch } from '@app/hooks';
import { MENU_PAGE, setPage } from '@store/reducers/popups';
import { BlockchainWrapper } from '@src/features/blockchain';
import { CoinsPage } from '@src/Pages/coins/coins';
import { EnergyPage } from '@src/Pages/energy/energy';
import { ProfilePage } from '@src/Pages/profile/profile';

export const PageWrapper: React.FC = () => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setPage(MENU_PAGE.MAIN));
  };

  return (
    <>
      <CoinsPage handleClose={handleClose} />
      <EnergyPage handleClose={handleClose} />
      <ProfilePage handleClose={handleClose} />
    </>
  );
};

export const MenuPages = BlockchainWrapper(PageWrapper);

import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  popOver: {
    fontFamily,
    color: 'inherit',
    boxSizing: 'border-box',
    '& *': {
      boxSizing: 'border-box',
    },
  },

  popOverContainer: {
    fontFamily,
    backgroundColor: '#fff',
    minWidth: '160px',
    flexDirection: 'column',
    borderRadius: '4px',
    border: '1px solid #E8E9EB',
    paddingTop: '12px',
    paddingBottom: '12px',
  },

  popOverItem: {
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '12px',
    '&.disabled': {
      pointerEvents: 'none',
      cursor: 'default',
      '& > div': {
        color: '#E8E9EB',
      },
    },
    '&.borderTop': {
      borderTop: '1px solid #E8E9EB',
      '& > div': {
        marginTop: '10px',
      },
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },

  popOverText: {
    width: '100%',
    color: '#5F6372',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '5px',
    paddingBottom: '5px',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
  },
}));

import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { PLAYER_STATE, selectRoomPlayers } from '@store/room/roomSlice';
import { selectPlayerId } from '@store/reducers/player.reducer';

import { PLAYER_STATUS } from '@src/common/constants/system';

import WSClient from '@src/sockets';
import { simpleMerge } from '@src/common/generics';
import { defaultGameState, initialMapState } from './RoastBattle.types';
import { CLIENT_EVENTS, SERVER_EVENTS } from '@shared/socketEvents/eventTypes';
import { RoastBattleState } from '@shared/gameInterfaces/roastBattle.socket';

interface RoastBattleData {
  data: {
    roastBattle: RoastBattleState;
  };
}

export const useGameProvider = (roomId: string) => {
  const [game, setGame] = useState<RoastBattleState>(defaultGameState);
  const players = useSelector(selectRoomPlayers);
  const playerId = useSelector(selectPlayerId);
  const isActivePlayer = playerId === game.activePlayerId;
  const isPairPlayer = playerId === game.pairPlayerId;
  const currentPlayer = players.find((player: any) => player.playerId === playerId);
  const isRoundPlayers = playerId === game.activePlayerId || playerId === game.pairPlayerId;
  const isSpectator = currentPlayer ? currentPlayer.state !== PLAYER_STATE.GAME : true;

  const stateMatcher = { ...initialMapState, [game.gameState]: true };

  useEffect(() => {
    const updateState = ({ data }: RoastBattleData) => setGame(simpleMerge(game, data.roastBattle));

    WSClient.on(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    WSClient.on(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);

    WSClient.emit(CLIENT_EVENTS.GAME_STATE_REQUESTED, { roomId });

    return () => {
      WSClient.off(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);
      WSClient.off(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    };
  }, []);

  const setVote = (answerIndex: number) => setGame(simpleMerge(game, {})); // vote: answerIndex }));
  const setReady = () => setGame(simpleMerge(game, {})); // readyPlayers: playerId }));

  return [
    stateMatcher,
    { ...game, state: stateMatcher },
    playerId,
    // players,
    isActivePlayer,
    isPairPlayer,
    isSpectator,
    isRoundPlayers,
  ] as const;
};

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: '350px',
    flexDirection: 'column',
    alignSelf: 'center',
    '& > h3': {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '20px',
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.up('md')]: {
        marginTop: '5px',
      },
    },
  },

  text: {
    fontSize: '13px',
    lineHeight: '21px',
    textAlign: 'center',
    marginTop: 9,
    fontWeight: 400,
    marginBottom: '9px',
  },

  btnContainer: {
    color: 'inherit',
  },
}));

import { Theme } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';
import { makeStyles, createStyles } from '@material-ui/core/styles';

export interface StylesProps {
  width?: string;
  gap?: string;
}

export const useStyles = makeStyles((theme) => ({
  containerBlock: {
    // width: '100%', //!
    boxSizing: 'border-box',
    padding: '0 0 32px 0',
    position: 'relative',
    flexDirection: 'column',
    width: 'fit-content',
    // margin: '0 auto',
    '&:last-child': {
      padding: '0 0 72px 0',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 0 45px 0',
      width: '100%',
    },
  },
  headline2: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '17px',
    marginBottom: '13px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  innerContainer: {
    flexDirection: 'column',
    flexShrink: 1,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  elementsContainer: {
    display: 'grid',
    gap: '13px',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    gridTemplateColumns: 'minmax(135px, 315px) minmax(135px, 315px)',
    alignSelf: 'center',
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      gap: '30px',
      width: '100%',
      gridTemplateColumns: 'repeat(auto-fill, 315px)',
    },
  },

  elementsContainerRoom: {
    display: 'grid',
    gap: '15px',
    gridTemplateColumns: 'repeat(3, minmax(85px, 105px))',
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      gap: '20px',
      width: '100%',
      gridTemplateColumns: 'repeat(4, minmax(105px, 150px))',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(8, minmax(85px, 150px))',
    },
  },

  viewAllContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      width: '100%',
      height: '42px',
      flexShrink: 0,
      [theme.breakpoints.up('sm')]: {
        height: '53px',
      },
    },
  },

  viewAll: {
    borderRadius: '50%',
    background: '#EAF1FE',
    border: '3px dashed #2C74F3',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '20px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#2C74F3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '85px',
    maxWidth: '105px',
    minHeight: '85px',
    maxHeight: '105px',
    width: '100%',
    height: '100%',
    padding: '15px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      minWidth: '105px',
      maxWidth: '150px',
      minHeight: '105px',
      maxHeight: '150px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '85px',
      minHeight: '85px',
    },
  },
}));

import { AppDispatch } from '@src/app/store';
import { updateGameState } from '@store/reducers/game.reducer';
import { FiveSecondsState, FIVE_SECONDS_STATE } from '@src/shared/gameInterfaces/fiveSeconds.socket';

enum LABELS {
  READY = 'Ready',
  ACTIVE = 'Active',
  VOTED = 'Voted',
}

const updateFiveSecondsLabelsThunk = (game: FiveSecondsState) => (dispatch: AppDispatch) => {
  const { gameState, votedPlayers, readyPlayers, activePlayerId } = game;

  // ACTIVE
  const activeStates = [
    FIVE_SECONDS_STATE.READY_START_ROUND,
    FIVE_SECONDS_STATE.SPIN,
    FIVE_SECONDS_STATE.SHOW_WORD,
    FIVE_SECONDS_STATE.GAME,
    FIVE_SECONDS_STATE.VOTE_RESULTS,
  ].includes(gameState);

  // READY
  const readyStates = [FIVE_SECONDS_STATE.TOP_PLAYERS, FIVE_SECONDS_STATE.LEADERBOARD].includes(gameState);
  const votedStates = [FIVE_SECONDS_STATE.GAME, FIVE_SECONDS_STATE.VOTE].includes(gameState);

  const labels = new Map<string, string>();

  // ACTIVE
  if (activeStates) {
    labels.set(activePlayerId, LABELS.ACTIVE);
  }

  // READY
  if (readyStates && readyPlayers) {
    readyPlayers.forEach((playerId) => labels.set(playerId, LABELS.READY));
  }

  // VOTED
  if (votedStates && votedPlayers) {
    votedPlayers.forEach((playerId) => labels.set(playerId, LABELS.VOTED));
  }
  dispatch(updateGameState({ playerLabels: [...labels.entries()] }));
};

export { updateFiveSecondsLabelsThunk };

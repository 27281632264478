import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';
import { WrapperOffsets } from '@src/store/reducers/session.reducer';

export const styles = makeStyles<Theme, WrapperOffsets>((theme: Theme) =>
  createStyles({
    snackBar: {
      transform: 'none',
      left: 'auto',
      right: 'auto',
      boxSizing: 'border-box',
      maxWidth: '356px',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '360px',
      },
      '&.MuiSnackbar-anchorOriginTopCenter': {
        top: '10px',
        [theme.breakpoints.up('md')]: {
          top: '75px',
        },
      },
    },
  })
);

import { makeStyles } from '@material-ui/core';

import closeIcon from '@art/icons/close.svg';

export const styles = makeStyles((theme) => ({
  brushSizes: {
    display: 'flex',
    backgroundColor: '#F3F4F5',
    position: 'absolute',
    zIndex: 3,
    bottom: 0,
    left: 0,
    right: 0,
    paddingLeft: '15px',
    paddingRight: '25px',
    height: '40px',
    // borderBottom: '1px solid #E8E9EB',
    borderTop: '1px solid #E8E9EB',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > div': {
      marginLeft: '20px',
    },
  },

  brushSizesList: {
    display: 'flex',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    alignItems: 'center',
    '& > li': {
      border: '1px solid #BBBCC3',
      borderRadius: '50%',
      backgroundColor: 'white',
      marginRight: '20px',
      '&:hover': {
        cursor: 'pointer',
      },
      '&.active': {
        backgroundColor: '#2C74F3',
      },
    },
  },

  palleteList: {
    display: 'flex',
    position: 'absolute',
    zIndex: 5,
    bottom: 0,
    left: 0,
    right: 0,
    height: '76px',
    borderTop: '1px solid #E8E9EB',
    paddingLeft: '12px',
    paddingRight: '12px',
    backgroundColor: '#F3F4F5',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '40px',
    },
  },

  palleteListColors: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    width: '276px',
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
      width: 'auto',
    },
    '& > li': {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '28px',
      height: '28px',
      borderRadius: '4px',
      '& > div': {
        borderRadius: '50%',
        width: '14px',
        height: '14px',
      },
      '&.active': {
        backgroundColor: 'white',
        border: '1px solid #2C74F3',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },

  closeButton: {
    width: '12px',
    height: '12px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${closeIcon})`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

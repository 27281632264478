import { useAppSelector } from '@src/app/hooks';
import { IRoomPlayer, selectFriends } from '@src/store/room/roomSlice';
import React from 'react';
import { styles } from './styles';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { selectAvatars } from '@src/store/room/roomFeaturesData.slice';
import { IdType } from '@src/shared/generics';
import { Avatar } from '@src/shared/misc/roomFeatures.types';
import { PlayerButton } from './PlayerButton';
import { IPlayerCard, selectAllStreamers, selectStreamers } from '@src/store/reducers/common';

interface IProps {
  onPlayerClick: (player: IPlayerCard) => void;
}

const convertPlayers = (player: IRoomPlayer, avatars: Avatar[]) => ({
  playerId: player.playerId,
  avatar: avatars.find((avatar) => avatar.id === player.avatarId),
  name: player.name,
  isStreamer: false,
});

//? 'There are no friends in the room. You only can send tokens to the other players in the room.'
//: 'В комнате нет людей. Ты можешь отправлять токены только людям из комнаты'}{' '}

const Friends: React.FC<IProps> = ({ onPlayerClick }) => {
  const classes = styles();
  const friends = useAppSelector(selectFriends);
  const avatars = useAppSelector(selectAvatars);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const players = friends.map((friend) => convertPlayers(friend, avatars));

  const renderFriendsCards = () =>
    players.map((player, index) => {
      return <PlayerButton key={index} player={player} onClick={onPlayerClick} />;
    });

  return (
    <>
      <div className={classes.header}>{isEnLocale ? 'Send to Friends' : 'Отправить друзьям'}</div>
      <div className={classes.friendsContainer}>
        {players.length > 0 ? (
          renderFriendsCards()
        ) : (
          <p>
            {isEnLocale
              ? 'You can send tokens to the other players in the room'
              : 'Вы можете отправлять токены людям в комнате'}{' '}
          </p>
        )}
      </div>
    </>
  );
};

const Streamers: React.FC<IProps> = ({ onPlayerClick }) => {
  const classes = styles();
  const players = useAppSelector(selectAllStreamers);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const renderStreamerCards = () =>
    players.map((player, index) => {
      return <PlayerButton key={index} player={player} onClick={onPlayerClick} />;
    });

  if (players.length === 0) return <></>;

  return (
    <>
      <div className={classes.header} style={{ paddingTop: '10px' }}>
        {isEnLocale ? 'Donate to Superstars' : 'Донаты'}
      </div>
      <div className={classes.friendsContainer}>{renderStreamerCards()}</div>
    </>
  );
};

export { Friends, Streamers };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  draw: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    position: 'relative',
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },

    // timerContainer
    '& > .timerContainer': {
      position: 'absolute',
      right: '10px',
      top: '10px',
      zIndex: 1,
      [theme.breakpoints.up('sm')]: {
        top: '13px',
      },
      [theme.breakpoints.up('md')]: {
        left: '280px',
        top: '40px',
      },
    },
  },
}));

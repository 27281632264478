import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  drawPictureMobileHeader: {
    height: '54px',
    padding: '3px 15px',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #ABA8DC',
    textAlign: 'center',
    '& > button': {
      position: 'absolute',
      left: '10px',
      width: '75px',
      height: '28px',
      [theme.breakpoints.up('sm')]: {
        width: '95px',
        height: '32px',
      },
      '& > span': {
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '14px',
          lineHeight: '17px',
        },
      },
      '&.isModalOpen': {
        backgroundColor: 'transparent !important',
        '& > span': {
          color: '#F83E67',
          fontWeight: 600,
        },
      },
    },
    '& > b': {
      fontFamily: 'Nunito',
      display: 'inline-flex',
      textTransform: 'uppercase',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '15px',
      lineHeight: '20px',
      color: '#ABA8DC',
      maxWidth: '200px',
      wordBreak: 'break-word',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '350px',
        fontSize: '20px',
        lineHeight: '26px',
      },
      // iPhone 5
      ['@media (max-width:365px)']: {
        fontSize: '13px',
        letterSpacing: '-0.5px',
      },
    },
  },

  whoDraw: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    margin: '0 15px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      lineHeight: '21px',
    },
    '& > span': {
      color: '#1B2036',
    },
    '& > div': {
      maxWidth: '140px',
      paddingLeft: '5px',
      paddingRight: '5px',
      color: '#2C74F3',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '350px',
      },
    },
  },
}));

import { useEffect } from 'react';

import WSClient from '@src/sockets';
import { sendRoundStarted } from '@services/analyticsThunk';
import { updateRouletteLabelsThunk } from './roulette.labels';
import { CLIENT_EVENTS } from '@shared/socketEvents/eventTypes';

import { useGameProvider } from './roulette.provider';
import { useAppDispatch } from '@src/app/hooks';
import { GameTypes } from '@src/common/constants/common';
import { setQuestionNumber } from '@src/store/room/roomSlice';

const useGameHook = (roomId: string, shouldDrink: boolean) => {
  const dispatch = useAppDispatch();

  // import from provider
  const [
    // 1 - status
    status,

    // 2 - gameState
    gameState,

    // 3 - playerId
    playerId,

    // 4 - players
    players,

    // 5 - isActivePlayer
    isActivePlayer,

    // 6 - storeActions
    storeActions,

    // 7 - isSpectator
    isSpectator,
  ] = useGameProvider(roomId);

  const activeGameId = gameState.activeGameId;
  const socketData = {
    roomId,
    activeGameId,
    playerId,
  };

  useEffect(() => {
    dispatch(updateRouletteLabelsThunk(gameState, shouldDrink));
  }, [status]);

  useEffect(() => {
    dispatch(setQuestionNumber(gameState.roundNumber));
  }, [gameState.roundNumber]);

  useEffect(() => {
    if (status.READY_START_ROUND) {
      dispatch(sendRoundStarted(gameState.roundNumber.toString()));
    }
  }, [status.READY_START_ROUND]);

  // return from hook
  return {
    status,
    gameState,
    playerId,
    players,
    isActivePlayer,
    storeActions,
    isSpectator,
    actions: {
      // onSpin
      onSpin: () => {
        WSClient.emit(CLIENT_EVENTS.ROUND_STARTED, {
          roomId,
          activeGameId,
          gameType: GameTypes.DRINK_ROULETTE,
        });
      },

      // onVote
      onVote: (index: number) => {
        storeActions.setVote(index);
        WSClient.emit(CLIENT_EVENTS.PLAYER_VOTED, {
          ...socketData,
          value: index,
        });
      },

      // onCheers
      onCheers: () => {
        storeActions.setReady();
        WSClient.emit(CLIENT_EVENTS.PLAYER_READY, socketData);
      },

      // onNext
      onNext: () => {
        storeActions.setReady();
        WSClient.emit(CLIENT_EVENTS.PLAYER_READY, socketData);
      },

      // onQuit
      onQuit: () => {
        WSClient.emit(CLIENT_EVENTS.PLAYER_READY_TO_QUIT_GAME, {
          roomId,
          activeGameId: gameState.activeGameId,
          playerId,
        });
      },
    },
  };
};

export { useGameHook };

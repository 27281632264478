import React, { useEffect, useRef, useState } from 'react';
import { LazyBrush } from 'lazy-brush';
import { DrawEventHandler, isTouchEndEvent, isTouchEvent } from '../types';
import { getPointerPosition } from '../helpers';
import { useCatenary } from '../hooks';
import { Catenary } from 'catenary-curve';
import { useCanvasDrawContext } from './CanvasDrawContext';

import { styles } from './styles';

export type CanvasDrawInterfaceProps = {
  hideInterface: boolean;
  lazyBrush: LazyBrush;

  brushColor?: string;
  brushRadius?: number;

  catenaryColor?: string;
  frameFrequency?: number;
};

const drawInterface = (
  ctx: CanvasRenderingContext2D,
  lazyBrush: LazyBrush,
  brushColor: string,
  brushRadius: number,
  catenary: Catenary,
  catenaryColor: string
) => {
  const pointer = lazyBrush.getPointerCoordinates();
  const brush = lazyBrush.getBrushCoordinates();

  ctx.beginPath();
  ctx.fillStyle = brushColor;
  ctx.arc(brush.x, brush.y, brushRadius, 0, Math.PI * 2, true);
  ctx.fill();

  ctx.beginPath();
  ctx.fillStyle = catenaryColor;
  ctx.arc(pointer.x, pointer.y, 4, 0, Math.PI * 2, true);
  ctx.fill();

  if (lazyBrush.isEnabled()) {
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.lineCap = 'round';
    ctx.setLineDash([2, 4]);
    ctx.strokeStyle = catenaryColor;

    catenary.drawToCanvas(ctx, brush, pointer, lazyBrush.radius);
    ctx.stroke();
  }
};

const CanvasDrawInterface: React.FC<CanvasDrawInterfaceProps> = ({
  lazyBrush,
  brushColor = '#444',
  brushRadius = 6,
  catenaryColor = brushColor,
  hideInterface,
  frameFrequency = 40,
}) => {
  const classes = styles();
  const context = useCanvasDrawContext();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  // rename to redraw?
  const catenary = useCatenary();
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [state, _] = useState({
    frameCounter: 0,
    shouldDraw: false,
    interval: undefined as NodeJS.Timeout | undefined,
  });

  const renderLoop = () => {
    const ctx = canvasRef.current?.getContext('2d');

    if (ctx) {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      if (!hideInterface) {
        drawInterface(ctx, lazyBrush, brushColor, brushRadius, catenary, catenaryColor);
      }
    } else {
      // ignore or log error
    }
  };

  function requestAnimationFrameLoop() {
    window.requestAnimationFrame(renderLoop);
  }

  useEffect(() => {
    if (canvasRef.current) {
      context.subscribeToResize(canvasRef.current, () => {
        requestAnimationFrameLoop();
      });
      return () => context.unsubscribeToResize(canvasRef.current!);
    }
  }, [context, canvasRef.current]);

  useEffect(() => {
    requestAnimationFrameLoop();
  }, [hideInterface]);

  const handlePointerMove = (x: number, y: number, forced = false) => {
    lazyBrush.update({ x, y });
    if (isDrawing || forced) {
      context.triggerPointsUpdate({ point: lazyBrush.brush.toObject(), brushColor, brushRadius });
    }

    requestAnimationFrameLoop();
  };

  /*const checkFrameFrequency = () => {
    const handleFrame = state.frameCounter === 0;

    if (++state.frameCounter >= frameFrequency) {
      state.frameCounter = 0;
    }

    return handleFrame;
  };*/

  const handleDrawStart: DrawEventHandler<HTMLCanvasElement> = (e) => {
    //e.preventDefault();
    setIsDrawing(true);
    state.interval = setInterval(() => (state.shouldDraw = true), frameFrequency);
    state.shouldDraw = false;
    // state.frameCounter = frameFrequency > 1 ? 1 : 0;

    if (canvasRef.current) {
      const { x, y } = getPointerPosition(canvasRef.current, e);
      if (isTouchEvent(e)) {
        lazyBrush.update({ x, y }, { both: true });
      }
      handlePointerMove(x, y, true);
    }
  };

  const handleDrawMove: DrawEventHandler<HTMLCanvasElement> = (e) => {
    //e.preventDefault();
    // checkFrameFrequency()
    if (canvasRef.current && isDrawing && state.shouldDraw) {
      state.shouldDraw = false;
      const { x, y, out } = getPointerPosition(canvasRef.current, e);
      if (out) {
        context.triggerFinishLine({ brushRadius, brushColor });
        setIsDrawing(false);
        clearInterval(state.interval!);
      } else {
        handlePointerMove(x, y);
      }
    }
  };

  const handleDrawEnd: DrawEventHandler<HTMLCanvasElement> = (e) => {
    if (isDrawing) {
      context.triggerFinishLine({ brushRadius, brushColor });
      setIsDrawing(false);
      clearInterval(state.interval!);
    }
  };

  return (
    <canvas
      ref={canvasRef}
      className={classes.canvas}
      onMouseDown={handleDrawStart}
      onMouseMove={handleDrawMove}
      onMouseUp={handleDrawEnd}
      onMouseLeave={handleDrawEnd}
      onTouchStart={handleDrawStart}
      onTouchMove={handleDrawMove}
      onTouchEnd={handleDrawEnd}
      onTouchCancel={handleDrawEnd}
    />
  );
};

export default CanvasDrawInterface;

import { DrinkRouletteState, DRINK_ROULETTE_STATE } from '@shared/gameInterfaces/roulette.socket';

export const initialMapState = {
  UNKNOWN: false,
  GAME_START: false,
  READY_START_ROUND: false,
  SPIN: false,
  ROUND_IN_PROGRESS: false,
  SKIP_RESULTS: false,
  SHOW_RESULTS: false,
  COMMON_RESULTS: false,
  TOP_PLAYERS: false,
  LEADERBOARD: false,
};

// add more fields
export const defaultGameState: DrinkRouletteState = {
  gameState: DRINK_ROULETTE_STATE.UNKNOWN,
  activeGameId: '',
  activePlayerId: '',
  roundNumber: 1,
  totalRounds: 10,
};

export type MapState = typeof initialMapState;

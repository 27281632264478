import { OperationVariables } from '@apollo/client';

export type ObjectType = Record<string, unknown>;
export type ParamsType = Record<string, string | undefined>;

export const defaultObject: ObjectType = {};

export type MixProps<P = {}> = P & {
  className?: string;
  style?: React.CSSProperties;
};

export const simpleMerge = <T>(state: T, payload: Partial<T>): T => ({
  ...state,
  ...payload,
});

import React from 'react';
import { IQuestion } from '@src/shared/gameInterfaces/loveBottle.socket';
import { IGameState } from '../../game.types';
import { MatchStatus } from './MatchStatus';
import { PlayerContainer } from './PlayerContainer';
import { Question } from './Question';
import { gameStyles } from '../../game.styles';

interface IProps {
  heart: string;
  activeTicketIndex: number;
  game: IGameState;
  isShowResults: boolean;
  isNextDisabled?: boolean;
  currUserId: string;
  activeQuestion: IQuestion;
  onVote: (index: any) => void;
  onNext: () => void;
}

const QuestionState: React.FC<IProps> = ({
  game,
  currUserId,
  isShowResults,
  activeTicketIndex,
  heart,
  isNextDisabled,
  activeQuestion,
  onVote,
  onNext,
}) => {
  const classes = gameStyles();

  return (
    <>
      <PlayerContainer game={game} heart={heart} activeTicketIndex={activeTicketIndex} isShowResults={isShowResults} />

      {!isShowResults && (
        <Question game={game} onVote={onVote} currUserId={currUserId} activeQuestion={activeQuestion} />
      )}

      {isShowResults && (
        <MatchStatus
          game={game}
          onNext={onNext}
          currUserId={currUserId}
          isNextDisabled={isNextDisabled}
          activeQuestion={activeQuestion}
        />
      )}
    </>
  );
};

export { QuestionState };

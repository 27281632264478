import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { PLAYER_STATE, selectCurrentGame, selectCurrentRoomPlayer } from '@store/room/roomSlice';
import WSClient from '@src/sockets';
import { GameTypes } from '@src/common/constants/common';
import { CLIENT_EVENTS } from '@shared/socketEvents/eventTypes';
import { InProgressModal } from '@components/Modals';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  Roulette,
  LoveBottle,
  MatchMe,
  GuessMe,
  DrawAndGuess,
  RoastBattle,
  QuizClassic,
  Chests,
  FakeArtist,
  OneQuestion,
  UnityPage,
  Alias,
  GuessUp,
} from '@src/games';
import { analytics } from '@services/amplitude';
import { FACEBOOK_EVENT, sendFacebookEvent } from '@services/facebookPixel';
import { playParty } from '@src/store/reducers/player.reducer';
import { FiveSeconds } from '@src/games/FiveSeconds/FiveSeconds';

interface RouterProps {
  roomId: string;
}

interface Props extends RouteComponentProps<RouterProps> {}

const RoomGame: React.FC<Props> = ({ match }) => {
  const dispatch = useAppDispatch();
  const player = useAppSelector(selectCurrentRoomPlayer)!;
  const shouldJoinGame = player.state === PLAYER_STATE.LOBBY;
  const isPlayerInGame = player.state === PLAYER_STATE.GAME;
  const selectedGame = useAppSelector(selectCurrentGame);
  const gameType = selectedGame?.type;
  const roomId = match.params.roomId;

  useEffect(() => {
    if (isPlayerInGame) {
      analytics.logEvent(analytics.EVENTS.PLAYER_JOINED_GAME, {
        roomId: match.params.roomId,
        gameType: gameType,
        gameId: selectedGame?.id,
      });
      sendFacebookEvent(FACEBOOK_EVENT.GAME_ENTERED);
      dispatch(playParty);
    }
  }, [isPlayerInGame]);

  const renderGame = () => {
    switch (gameType) {
      case GameTypes.DRINK_ROULETTE:
      case GameTypes.TWENTY_QUESTIONS:
      case GameTypes.ROAST:
      case GameTypes.SEND_QUESTION:
        return <Roulette roomId={roomId} shouldDrink={gameType === GameTypes.DRINK_ROULETTE} gameType={gameType} />;
      case GameTypes.LOVE_BOTTLE:
        return <LoveBottle roomId={roomId} />;
      case GameTypes.MATCH_ME:
        return <MatchMe roomId={roomId} />;
      case GameTypes.GUESS_ME:
        return <GuessMe roomId={roomId} />;
      case GameTypes.QUIZ_BATTLE:
      case GameTypes.QUIZ_CLASSIC:
        return <QuizClassic roomId={roomId} />;
      case GameTypes.DRAW_AND_GUESS:
        return <DrawAndGuess roomId={roomId} />;
      case GameTypes.ROAST_BATTLE:
        return <RoastBattle roomId={roomId} />;
      case GameTypes.CHESTS:
        return <Chests roomId={roomId} />;
      case GameTypes.FAKE_ARTIST:
        return <FakeArtist roomId={roomId} />;
      case GameTypes.UNITY_GAME:
        return <UnityPage game={selectedGame!} roomId={roomId} />;
      case GameTypes.QUESTION_CHALLENGE:
        return null;
      // return <OneQuestion roomId={roomId} />;
      case GameTypes.ALIAS:
        return <Alias roomId={roomId} />;
      case GameTypes.GUESS_UP:
        return <GuessUp roomId={roomId} />;
      case GameTypes.FIVE_SECONDS:
        return <FiveSeconds roomId={roomId} />;
      default:
        return null;
    }
  };

  const onJoinGame = () => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_READY_TO_JOIN_GAME, {
      roomId,
      playerId: player.playerId,
    });
    // save choice?
  };

  return (
    <>
      {shouldJoinGame && (
        <InProgressModal isOpen={true} handleClose={() => {}} onJoinGame={onJoinGame} gameType={gameType} />
      )}

      {!shouldJoinGame && renderGame()}
    </>
  );
};

export { RoomGame };

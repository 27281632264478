import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  heroFlexer: {
    flexShrink: 0,
    width: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
}));

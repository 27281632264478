import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  results: {
    position: 'relative',
    zIndex: 3,
    flexGrow: 1,
    padding: '30px 15px',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: '30px',
    },
  },

  questionText: {
    color: '#1B2036',
    padding: '20px',
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '24px',
    backgroundColor: 'white',
    textAlign: 'center',
    borderRadius: '20px',
    maxWidth: '528px',
    marginBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '33px',
      letterSpacing: '-0.7px',
    },
  },

  spacer: {
    flexGrow: 1,
    transitionDuration: '1000ms',
    transitionProperty: 'all',
  },

  imageContainer: {
    display: 'block',
    width: '100%',
    transitionDuration: '1000ms',
    transitionProperty: 'height',
    '& > img': {
      display: 'block',
      width: '325px',
      height: '237px',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up('sm')]: {
        width: '569px',
        height: '414px',
      },
    },
  },

  answersContainer: {
    width: '100%',
    justifyContent: 'center',
    marginTop: '15px',
    '&.isQuestionClicked': {
      '& > div': {
        pointerEvents: 'none',
        cursor: 'default',

        '& > span': { color: '#D7E4FD' },
      },
    },
    '&.answers-2': {
      '& > div:first-child': {
        borderRadius: '20px 0 0 20px',
      },
      '& > div:last-child': {
        borderRadius: '0 20px 20px 0',
      },
    },
    '&.answers-4': {
      flexWrap: 'wrap',
      width: '346px',
      [theme.breakpoints.up('sm')]: {
        width: '528px',
      },
      '& > div': {
        flexBasis: '50%',
        '&:nth-child(1)': { borderRadius: '20px 0 0 0' },
        '&:nth-child(2)': { borderRadius: '0 20px 0 0' },
        '&:nth-child(3)': { borderRadius: '0 0 0 20px' },
        '&:nth-child(4)': { borderRadius: '0 0 20px 0' },
      },
    },
  },

  answer: {
    position: 'relative',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    height: '64px',
    minWidth: '165px',
    borderRadius: '20px',
    border: 0,
    outline: 0,
    paddingLeft: '15px',
    paddingRight: '15px',
    transition: 'all .23s',
    margin: '-1px',
    '&.right': {
      '& > span': {
        color: '#3AB957',
      },
      '& > b': {
        backgroundColor: '#3AB957',
      },
    },
    '&.wrong': {
      '& > span': {
        color: '#FCB2C2',
      },
      '& > b': {
        backgroundColor: 'red',
      },
    },
    '&.active': {
      '& > span': {
        color: '#2C74F3',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: '268px',
      height: '104px',
    },
    '& > b': {
      position: 'absolute',
      top: '-10px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F83E67',
      color: 'white',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '29px',
      width: '82px',
      borderRadius: '10px',
    },
    '& > span': {
      fontFamily: 'FinkHeavy',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '23px',
      color: '#D7E4FD',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px',
        lineHeight: '40px',
      },
    },
  },

  buttonsContainer: {
    marginTop: '30px',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    animationDuration: '0.5s',
    '& > div:first-child': {
      marginBottom: '15px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '30px',
      },
      '& > span': {
        color: '#7B61FF',
      },
    },
  },

  button: {
    display: 'inline-flex',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    height: '64px',
    minWidth: '165px',
    borderRadius: '20px',
    paddingLeft: '30px',
    paddingRight: '30px',
    cursor: 'pointer',
    transition: 'all .23s',
    [theme.breakpoints.up('sm')]: {
      height: '104px',
      paddingLeft: '50px',
      paddingRight: '50px',
    },
    '&:hover': {
      '& > span': {
        color: '#7B61FF',
      },
    },
    '& > span': {
      fontFamily: 'FinkHeavy',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '23px',
      color: '#2C74F3',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px',
        lineHeight: '40px',
      },
    },
  },
}));

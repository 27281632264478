import { MouseEventHandler, useState } from 'react';
import WSClient from '@src/sockets';
import { useAppSelector } from '@app/hooks';
import { CLIENT_EVENTS } from '@shared/socketEvents/eventTypes';
import { getRoomId } from '@store/room/roomSlice';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { selectBackgrounds } from '@store/room/roomFeaturesData.slice';
import Button from '@components/Button';
import CommonModal from '@components/Modals/CommonModal';
import { styles } from './styles';
import { selectPlayerId } from '@src/store/reducers/player.reducer';

interface Props {
  isOpen: boolean | undefined;
  handleClose: MouseEventHandler;
}

const ChangeBackground: React.FC<Props> = ({ isOpen, handleClose }) => {
  const classes = styles();

  // redux
  const roomId = useAppSelector(getRoomId);
  const playerId = useAppSelector(selectPlayerId);
  const backgroundsList = useAppSelector(selectBackgrounds);

  const isEnLocale = useAppSelector(selectIsEnLocale);

  // state
  const [checkedBackground, setCheckedBackground] = useState('');

  const onBackgroundClick = (backgroundId: string) => {
    setCheckedBackground(backgroundId);
  };

  const onSaveBackgroundClick = () => {
    if (checkedBackground) {
      WSClient.emit(CLIENT_EVENTS.PLAYER_UPDATED_ROOM, {
        // roomId,
        // playerId,
        backgroundId: checkedBackground,
      });
    }
    // @ts-ignore
    handleClose();
  };

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} className="change-background" backgroundColor={'white'}>
      <div className={classes.wrapper}>
        <h3>{isEnLocale ? 'Game backgrounds' : 'Фоны для игры'}</h3>

        <div className={classes.backgroundsList}>
          {backgroundsList.length
            ? backgroundsList.map((background) => {
                const { id, preview } = background; // backgroundColor, gradient, image,
                const backgroundId = id;

                const addStyles = () => {
                  // gradient
                  if (preview?.includes('gradient')) {
                    return { backgroundImage: preview };

                    // image
                  } else if (preview?.includes('http')) {
                    return { backgroundImage: `url(${preview})` };

                    // color
                  } else if (preview?.includes('#')) {
                    return { backgroundColor: preview, border: '1px solid #BBBCC3' };
                  }
                };

                return (
                  <div
                    key={id}
                    style={addStyles()}
                    className={checkedBackground === backgroundId ? 'active' : ''}
                    onClick={() => onBackgroundClick(backgroundId)}
                  />
                );
              })
            : isEnLocale
            ? 'No game backgrounds'
            : 'Нет фонов для игры'}
        </div>

        <div className={classes.btnContainer}>
          <Button
            type="primary-red-new"
            text={isEnLocale ? 'Cancel' : 'Закрыть'}
            onClick={handleClose}
            style={{ marginRight: '15px' }}
          />
          <Button
            type="primary-blue-new"
            text={isEnLocale ? 'Save' : 'Сохранить'}
            onClick={onSaveBackgroundClick}
            disabled={!checkedBackground}
          />
        </div>
      </div>
    </CommonModal>
  );
};

export default ChangeBackground;

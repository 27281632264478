import { fontFamily } from '@src/art/theme/textStyles';
import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '17px',
    textAlign: 'center',
    '& .title': {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '17px',
      color: '#1B2036',
      marginBottom: '9px',
    },
    '& .subtitle': {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '17px',
      lineHeight: '17px',
      color: '#5568AE',
      textAlign: 'center',
      '& span': {
        fontFamily: 'SF Pro Rounded Black',
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: '20px',
        lineHeight: '17px',
        color: '#FFFFFF',
        textStroke: '1px #000000',
      },
    },
  },
}));

const LoveBottleTextContentEn: React.FC = () => {
  return (
    <>
      <li>
        You get <b>1 point</b> every time when you get a heart
      </li>
      <li>
        You get <b>5 points</b> when you get a heart at final date question
      </li>
    </>
  );
};

const LoveBottleTextContentRu: React.FC = () => {
  return (
    <>
      <li>
        Тебе даётся <b>1 очко</b> каждый раз, когда ты получаешь сердце
      </li>
      <li>
        Тебе даётся <b>5 очков</b>, когда ты получаешь сердце в заключительном вопросе
      </li>
    </>
  );
};

export { LoveBottleTextContentEn, LoveBottleTextContentRu };

import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { selectBalance, selectPlayerId } from '@src/store/reducers/player.reducer';
import { selectRoomPlayers } from '@src/store/room/roomSlice';
import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import Button from '@src/components/Button';
import { useBlockchainData } from '@src/features/blockchain';
import ChangeBlockChainPopup from '@src/features/bsc/ChangeBlockChainPopup';
import { mutateGraphql } from '@src/library/fetch';
import { analytics } from '@src/services/amplitude';
import { requestRewards } from '@src/graphql/player.queries';
import { claimReward, ClaimRewardParams, ClaimRewardResponse, CLAIM_RESPONSE } from '@graphql/debugQueries';
import { setOperationSuccess, setNotificationType, SNACKBAR_SUCCESS, SNACKBAR_TYPE } from '@src/store/reducers/popups';
import { IResultNotification, NOTIFICATION_TYPE } from '@src/store/room/notifications';
import { pushNotification } from '@src/store/room/roomFeaturesData.slice';
import { sendTokens } from '@src/shared/solana/sendTokens';

interface IProps {
  setIsOpenWithdrawModal: (isOpen: boolean) => void;
  maxClaim: number;
}

const WithdrawModal: React.FC<IProps> = ({ setIsOpenWithdrawModal, maxClaim }) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const playerId = useAppSelector(selectPlayerId);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const [value, setValue] = React.useState<number>(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isButtonClicked, setButtonClicked] = useState(false);
  const balance = useAppSelector(selectBalance);
  const { walletConnected, requestBalance, isRightChain, publicKey, sendTokens } = useBlockchainData();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setValue(parseInt(event.target.value) || 0);
  const handleClose = () => setIsOpenWithdrawModal(false);

  useEffect(() => {
    if (!walletConnected) {
      handleClose();
    }
  }, [walletConnected]);

  const onClaimReward = () => {
    if (!isRightChain) {
      setIsPopupOpen(true);
      return;
    }

    setButtonClicked(true);
    // dispatch(setNotificationType(SNACKBAR_TYPE.WITHDRAW));
    analytics.logEvent(analytics.EVENTS.HITS_CLAIMED, { playerId, balance });
    mutateGraphql<ClaimRewardParams, ClaimRewardResponse>(claimReward, {
      playerId,
      wallet: publicKey!,
      amount: Number(value),
    })
      .then((data) => {
        const isSuccess = data.claimReward === CLAIM_RESPONSE.OK;
        if (isSuccess) {
          dispatch(requestRewards);
          requestBalance();
          analytics.logEvent(analytics.EVENTS.HITS_RECEIVED, { playerId, balance, success: true });
          //dispatch(setOperationSuccess(SNACKBAR_SUCCESS.SUCCESS));
          handleClose();
        }
        dispatch(pushNotification({ type: NOTIFICATION_TYPE.WITHDRAW, success: isSuccess } as IResultNotification));
      })
      .catch((data) => {
        dispatch(pushNotification({ type: NOTIFICATION_TYPE.WITHDRAW, success: false } as IResultNotification));
        // dispatch(setOperationSuccess(SNACKBAR_SUCCESS.UNSUCCESS));
      })
      .finally(() => {
        setButtonClicked(false);
      });
  };

  /*const onClaimReward2 = async () => {
    const transactionId = await sendTokens(1);
    console.log(transactionId);
    requestSendTransaction(playerId, transactionId);
  };*/

  return (
    <>
      <div className={classes.sendModalContainer}>
        <p className={'title'}>{isEnLocale ? `Withdraw to your wallet` : `Выведи на свой кошелек`}</p>
        <div className={classes.formInner}>
          <div className={classes.customInput}>
            <input
              onChange={handleChange}
              value={value.toString()}
              id="value"
              name="value"
              type="number"
              style={{ border: `1px solid ${value > maxClaim ? '#F83E67' : 'transparent'}` }}
              autoComplete="off"
              maxLength={8}
              inputMode="numeric"
              // placeholder="0"
            />
            <div className={'currency'}>HIT</div>
          </div>
          <div style={{ justifyContent: 'center' }}>
            <p style={{ color: value > maxClaim ? '#F83E67' : '#5568AE' }}>
              {isEnLocale
                ? `Maximum withdraw amount: ${maxClaim}HIT`
                : `Максимальное количество для вывода - ${maxClaim}HIT`}
            </p>
          </div>

          <div className={classes.btnContainer}>
            <Button type="primary-red-new" text={isEnLocale ? 'Cancel' : 'Закрыть'} onClick={handleClose} />
            <Button
              text={isEnLocale ? 'Send' : 'Отправить'}
              buttonType="submit"
              type="primary-blue-new"
              disabled={value <= 0 || isButtonClicked || value > maxClaim || balance < value}
              onClick={onClaimReward}
            />
          </div>
        </div>
      </div>
      <ChangeBlockChainPopup isOpen={isPopupOpen} handleClose={() => setIsPopupOpen(false)} />
    </>
  );
};

export default WithdrawModal;

import { useState } from 'react';
import { useAppSelector } from '@src/app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import Button from '@components/Button';
import QuestionButton from '@src/components/QuestionButton';
import { GameTypes } from '@src/common/constants/common';
import { ROULETTE_TICKET_TYPE } from '@src/shared/gameInterfaces/roulette.socket';
import { styles } from '@src/games/common/NextButton/styles';

interface IProps {
  playerId: string;
  readyPlayers?: string[];
  shouldDrink: boolean;
  isSpectator: boolean;
  isShowResults: boolean;
  questionType?: ROULETTE_TICKET_TYPE;
  onCheers: () => void;
}

const Buttons: React.FC<IProps> = ({
  readyPlayers,
  shouldDrink,
  isSpectator,
  isShowResults,
  playerId,
  questionType,
  onCheers,
}) => {
  const classes = styles();
  const [isButtonClicked, setButtonClicked] = useState(false);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const onCheersClick = () => {
    setButtonClicked(true);
    onCheers();
  };

  const isPlayerReady = readyPlayers?.includes(playerId);
  const buttonTextEn = shouldDrink ? 'Cheers!' : 'Next';
  const buttonTextRu = shouldDrink ? 'За здоровье!' : 'Далее';

  const waitingText = isEnLocale
    ? `All players must tap ${buttonTextEn} to proceed`
    : `Все игроки должны нажать ${buttonTextRu}, чтобы продолжить`;
  const isButtonEnabled = !isButtonClicked && !isSpectator && !isPlayerReady;

  return (
    <div className={classes.buttonWrapper}>
      {isButtonEnabled ? (
        <Button type="primary-blue" text={isEnLocale ? buttonTextEn : buttonTextRu} onClick={onCheersClick} />
      ) : (
        <span className="mobile-outline">{waitingText}</span>
      )}

      {shouldDrink && (
        <QuestionButton
          gray={true}
          gameType={GameTypes.DRINK_ROULETTE}
          isShowResults={isShowResults}
          questionType={questionType}
        />
      )}
    </div>
  );
};

export { Buttons };

import { IRoomPlayer } from '@store/room/roomSlice';
import { DrinkRouletteState, ROULETTE_TICKET_TYPE } from '@shared/gameInterfaces/roulette.socket';
import { styles } from './styles';

interface IProps {
  results: string[][] | undefined;
  players: IRoomPlayer[];
  gameState: DrinkRouletteState;
}

const Bars: React.FC<IProps> = ({ gameState, players }) => {
  const classes = styles();
  const { ticket } = gameState;

  // answers
  const answers = ticket?.answers.length ? ticket.answers : [];

  // results
  const results = gameState?.results?.length ? gameState.results : [];

  // [vote1: [playerId1, playerId2], vote2: [...] ] --> [vote1: [{name, playerId, gender}, {name, playerId, gender}], vote2: []]
  const playersNamesByVotesArray = results.map((resultsId: any) =>
    resultsId.map((resultId: any) => players.find((player: { playerId: string }) => player.playerId === resultId))
  );

  // [[1,2,3], [1,2]]  -->  [3, 2]
  const votesCountArray = results.map((result) => result.length);

  // [3, 2] --> 3
  const maxVotes = votesCountArray.reduce((prev: any, next: any) => Math.max(prev, next));

  return (
    <div
      className={`
    ${classes.resultsContainer}
    ${results.length > 2 ? 'more-2' : ''}
  `}
    >
      {/* results bars */}
      <div
        className={`
          ${classes.resultsBarsContainer}
          ${results.length > 2 ? 'more-2' : ''}
        `}
      >
        {playersNamesByVotesArray.map((namesArray, index) => {
          return (
            <div key={index} className={`resultsBarsItem ${classes.resultsBarsItem}`}>
              {namesArray.map((playerName: any, index: number) => {
                return (
                  <div
                    key={index}
                    className={classes.resultsNameItem}
                    style={{
                      backgroundColor: namesArray.length === maxVotes ? '#2C74F3' : '#D7E4FD',
                      color: namesArray.length === maxVotes ? 'white' : '#5F6372',
                    }}
                  >
                    {playerName?.name}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      {/* resultsVotesTitlesContainer */}
      <div
        className={`
          ${classes.resultsVotesTitlesContainer}
          ${answers.length > 2 ? 'more-2' : ''}
        `}
      >
        {answers.map((answer, index) => {
          const getOptionPlayerName = () => players.find((player) => player.playerId === answer)?.name || '@left';
          const option = ticket?.ticketType === ROULETTE_TICKET_TYPE.PLAYERS_LIST ? getOptionPlayerName() : answer;
          return (
            <div key={index} className={`resultsVoteTitlesItem ${classes.resultsVoteTitlesItem}`}>
              {option}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { Bars };

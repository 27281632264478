import { useState } from 'react';

// import { IGameState } from '../../roulette.types';
import { DrinkRouletteState } from '@shared/gameInterfaces/roulette.socket';

import getImages from './getImages';

import { styles } from './styles';
import { useAppSelector } from '@src/app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';

interface IProps {
  gameState: DrinkRouletteState;
  isActivePlayer: boolean;
  isSpin: boolean;
  gameType: string;
  onSpin: () => void;
}

const MultiStartRound: React.FC<IProps> = ({ gameState, isActivePlayer, isSpin, gameType, onSpin }) => {
  const classes = styles();
  const { roundNumber, totalRounds } = gameState;
  const [isButtonClicked, setButtonClicked] = useState(false);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  // images src and animations names
  const buttonImage = getImages(gameType)?.button;
  const spinnerImage = getImages(gameType)?.spinnerImage;
  const spinnerImage2 = getImages(gameType)?.spinnerImage2;

  const onButtonClick = () => {
    setButtonClicked(true);
    onSpin();
  };

  const showTitleEn = roundNumber === totalRounds ? 'Final round' : `Question ${roundNumber} of ${totalRounds}`;
  const showTitleRu = roundNumber === totalRounds ? 'Финальный раунд' : `Раунд ${roundNumber} из ${totalRounds}`;

  return (
    <div className={`${classes.startWrapper} animate__animated animate__fadeInDown`}>
      <h2 className={`${classes.startTitle} mobile-outline`}>{isEnLocale ? showTitleEn : showTitleRu}</h2>

      <div className={classes.spinnerWrapper}>
        <img
          src={spinnerImage?.src}
          className={classes.spinnerImage}
          style={{ animationName: isSpin ? spinnerImage?.animationName : '' }}
          alt="spinnerImage"
        />

        {spinnerImage2?.src && (
          <img
            src={spinnerImage2?.src}
            className={classes.spinnerImage2}
            style={{ animationName: isSpin ? spinnerImage2?.animationName : '' }}
            alt="spinnerImage2"
          />
        )}

        {isActivePlayer && !isSpin && (
          <img
            src={buttonImage?.src}
            className={classes.button}
            style={{ animationName: isButtonClicked ? buttonImage?.animationName : '' }}
            onClick={onButtonClick}
            alt="button"
          />
        )}
      </div>
    </div>
  );
};

export { MultiStartRound };

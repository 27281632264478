import React from 'react';
import BlueButton from '@components/MaterialRewrite/BlueButton';
import { IQuestion, LoveBottleState } from '@src/shared/gameInterfaces/loveBottle.socket';
import { loveBottleQuestionStyles } from '../../game.styles';

interface IProps {
  game: LoveBottleState;
  currUserId: string;
  onVote: (index: any) => void;
  activeQuestion: IQuestion;
}

const Question: React.FC<IProps> = ({ onVote, game, currUserId, activeQuestion }) => {
  const classes = loveBottleQuestionStyles();
  const { activePlayerId, pairPlayerId } = game;
  const isAnswered = activeQuestion.options.some((option) => option.votes.includes(currUserId));
  const isRoundPlayer = activePlayerId === currUserId || pairPlayerId === currUserId;
  const isDisabled = isAnswered || !isRoundPlayer;

  return (
    <div className={classes.questionCardWhiteContainer}>
      <p className={classes.questionCardText}>{activeQuestion.title}</p>

      <div className={classes.questionCardContainer}>
        <div style={{ width: '100%', justifyContent: 'space-between' }}>
          {activeQuestion.options.map((option) => {
            const isChosen = option.votes && option.votes.includes(currUserId);
            const backgroundColor = isChosen ? 'rgba(215, 228, 253, 1)' : 'white';
            const color = isChosen ? 'rgba(27, 32, 54, 1)' : 'rgba(187, 188, 195, 1)';
            return (
              <BlueButton
                className={classes.questionCardAnswers}
                key={option.title}
                style={{
                  width: '30%',
                  backgroundColor: isAnswered ? backgroundColor : 'none',
                  color: isAnswered ? color : 'none',
                  border: 'none',
                  opacity: 1,
                  wordBreak: 'break-word',
                }}
                onClick={() => onVote(option.title)}
                disabled={isDisabled}
              >
                {option.title}
              </BlueButton>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { Question };

import { useAppSelector } from '@app/hooks';
import FakeArtistWon from '@art/images/fake-artist/fake-artist-won.svg';
import ArtistsWon from '@art/images/fake-artist/artists-won.svg';
import Stars2 from '@art/images/fake-artist/stars2.svg';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';

interface Props {
  chosenWord?: string;
  hasFakeArtistWon?: Boolean;
  fakeArtistName?: string;
}

const Results: React.FC<Props> = ({ chosenWord, hasFakeArtistWon, fakeArtistName }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <div className={classes.results}>
      <div
        className={`${classes.resultsCard} animate__animated animate__fadeInDown`}
        style={{
          backgroundImage: `url(${hasFakeArtistWon ? FakeArtistWon : ArtistsWon}), url(${Stars2})`,
        }}
      >
        <div style={{ borderColor: hasFakeArtistWon ? '#305088' : '#F8CA61' }} className={classes.resultWrapper}>
          {isEnLocale ? (
            <h3>{hasFakeArtistWon ? 'Fake WIN' : 'Artists WIN'}</h3>
          ) : (
            <h3>{hasFakeArtistWon ? 'Фэйк ПОБЕДИЛ' : 'Художники ПОБЕДИЛИ'}</h3>
          )}

          {isEnLocale ? (
            <div className={classes.resultName}>
              <div>{fakeArtistName}</div>
              <span>was FAKE Artist</span>
            </div>
          ) : (
            <div className={classes.resultName}>
              <div>{fakeArtistName}</div>
              <span>был ФЕЙКОМ</span>
            </div>
          )}
        </div>

        <div className={classes.resultWord}>
          <span>{isEnLocale ? 'Secret word' : 'Секретное слово'}</span>
          <b>{chosenWord}</b>
        </div>
      </div>
    </div>
  );
};

export { Results };

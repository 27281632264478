import React from 'react';
import { styles } from './styles';
import arrowRight from '@art/icons/arrow-right.svg';

interface Props {
  text: string;
  onClick: () => void;
}

const MenuPoint: React.FC<Props> = ({ text, onClick }) => {
  const classes = styles();

  return (
    <div className={classes.menuPointWrapper} onClick={onClick}>
      <p>{text}</p>
      <img src={arrowRight} alt="" />
    </div>
  );
};

export { MenuPoint };

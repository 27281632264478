import { useAppSelector } from '@src/app/hooks';
import { GameTypes } from '@src/common/constants/common';
import {
  IPlayerQuest,
  QUEST_TYPE,
  QUEST_STATE,
  PlayQuest,
  LinkQuest,
  PLAY_QUEST_OWNER,
  PlayQuestData,
  CostQuest,
  QUEST_COST,
} from '@src/shared/quests';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';

export const useTitle = (type: string, quest: IPlayerQuest) => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const link = type === QUEST_TYPE.LINK && quest.state !== QUEST_STATE.DONE ? `${quest.data.link}` : '';
  const buttonText =
    type === QUEST_TYPE.LINK && quest.state !== QUEST_STATE.DONE
      ? isEnLocale
        ? `Link`
        : 'Cсылка'
      : isEnLocale
      ? 'Claim'
      : 'Cобрать';

  const getByOwner = (owner: PLAY_QUEST_OWNER) => {
    switch (owner) {
      case PLAY_QUEST_OWNER.OWNER:
        return isEnLocale ? `as room owner` : 'как владелец комнаты';
      case PLAY_QUEST_OWNER.GUEST:
        return isEnLocale ? `as guest` : 'как гость';
      default:
        return '';
    }
  };

  const getGameName = (gameName: GameTypes) => {
    switch (gameName) {
      case GameTypes.DRINK_ROULETTE:
        return `Drink Roulette`;
      case GameTypes.TWENTY_QUESTIONS:
        return `Ten questions`;
      case GameTypes.ROAST:
        return `Roast`;
      case GameTypes.LOVE_BOTTLE:
        return `Love Bottle`;
      case GameTypes.MATCH_ME:
        return `Match Me`;
      case GameTypes.GUESS_ME:
        return `Guess Me`;
      case GameTypes.QUIZ_BATTLE:
        return `Quiz Battle`;
      case GameTypes.ROAST_BATTLE:
        return `Roast Battle`;
      case GameTypes.QUIZ_CLASSIC:
        return `Quiz Classic`;
      case GameTypes.CHESTS:
        return `Chests`;
      case GameTypes.QUESTION_CHALLENGE:
        return `One Question Challenge`;
      case GameTypes.FAKE_ARTIST:
        return `Fake Artist`;
      case GameTypes.DRAW_AND_GUESS:
        return `Draw and guess`;
      case GameTypes.UNITY_GAME:
        return `Unity Game`;
      default:
        return 'any';
    }
  };

  const playAnyGame = isEnLocale ? 'any game' : 'любую игру';
  const firstAnyGame = isEnLocale ? 'any game' : 'любой игре';

  const gameName =
    (type === QUEST_TYPE.PLAY || type === QUEST_TYPE.FIRST) && quest.data?.gameName
      ? getGameName(quest.data.gameName as GameTypes)
      : type === QUEST_TYPE.PLAY
      ? playAnyGame
      : firstAnyGame;

  const getTimes = () => {
    return isEnLocale
      ? quest.target > 1
        ? 'times'
        : 'time'
      : [2, 3, 4].find((item) => item === quest.target % 10)
      ? 'раза'
      : 'раз';
  };

  const getCoins = () => {
    return isEnLocale
      ? quest.target > 1
        ? 'tokens'
        : 'token'
      : [2, 3, 4].find((item) => item === quest.target % 10)
      ? 'монеты'
      : 'монет';
  };

  const getParties = () => {
    return isEnLocale
      ? quest.target > 1
        ? 'parties'
        : 'party'
      : quest.target === 1
      ? 'игру'
      : [2, 3, 4].includes(quest.target)
      ? 'игры'
      : 'игр';
  };

  const getPlayers = () => {
    return quest.data.minFriends === 1 ? (isEnLocale ? 'player' : 'игроком') : isEnLocale ? 'players' : 'игроками';
  };

  const minFriends =
    type === QUEST_TYPE.PLAY && quest.data?.minFriends
      ? isEnLocale
        ? `with ${quest.data.minFriends} ${getPlayers()} in the room`
        : `с ${quest.data.minFriends} ${getPlayers()} в комнате`
      : '';

  const getPlayTitle = (quest: PlayQuest) => {
    return `${isEnLocale ? 'Play' : 'Сыграй в'} ${gameName} ${quest.target} ${getTimes()} ${minFriends} ${getByOwner(
      quest.data?.owner
    )}`;
  };

  const getLinkTitle = (quest: LinkQuest) => {
    return ` ${isEnLocale ? quest.data.text.EN : quest.data.text.RU}`;
  };

  const getSendStickerTitle = (quest: CostQuest) => {
    const isPaid =
      quest.data && quest.data.cost === QUEST_COST.PAID
        ? isEnLocale
          ? 'paid sticker'
          : 'платный стикер'
        : isEnLocale
        ? 'sticker'
        : 'стикер'; //!is it right?
    return `${isEnLocale ? 'Send a' : 'Отправь'} ${isPaid} ${quest.target} ${getTimes()}`;
  };

  const getTitle = () => {
    switch (type) {
      case QUEST_TYPE.SEND_MONEY:
        return isEnLocale
          ? `Send ${quest.target} ${getCoins()} to other players`
          : `Отправь ${quest.target} ${getCoins()} другим игрокам`;
      case QUEST_TYPE.SPEND_MONEY:
        return isEnLocale
          ? `Spend ${quest.target} ${getCoins()} for stickers and parties unlock`
          : `Потрать ${quest.target} ${getCoins()} на анлок стикеров и пати`;
      case QUEST_TYPE.RECIEVE_MONEY:
        return isEnLocale
          ? `Receive ${quest.target} ${getCoins()} from the other players`
          : `Получи ${quest.target} ${getCoins()} от других игроков`;
      case QUEST_TYPE.PLAY:
        return getPlayTitle(quest as PlayQuest);
      case QUEST_TYPE.LINK:
        return getLinkTitle(quest as LinkQuest);
      case QUEST_TYPE.SEND_STICKER:
        return getSendStickerTitle(quest as CostQuest);
      case QUEST_TYPE.CLAIM_MONEY:
        return isEnLocale
          ? `Claim ${quest.target} ${getCoins()} in any game`
          : `Собери ${quest.target} ${getCoins()} в любой игре`;
      case QUEST_TYPE.COMPLETE_ALL:
        return isEnLocale ? `Complete all daily quests` : `Заверши все дневные квесты`;
      case QUEST_TYPE.FIRST:
        return isEnLocale
          ? `Top 1 in ${gameName} ${quest.target} ${getTimes()}`
          : `Стань ТОР-1 в ${gameName} ${quest.target} ${getTimes()}`;
      case QUEST_TYPE.CONNECT_WALLET:
        return isEnLocale ? 'Connect wallet' : 'Подключи кошелек';
      case QUEST_TYPE.WITHDRAW_MONEY:
        return isEnLocale ? `Withdraw ${quest.target} ${getCoins()}` : `Выведи ${quest.target} ${getCoins()}`;
      case QUEST_TYPE.RATE:
        return isEnLocale ? `Rate ${quest.target} ${getParties()}` : `Оцени ${quest.target} ${getParties()}`;
      case QUEST_TYPE.CHANGE_NAME:
        return isEnLocale ? `Change your name` : `Смени свое имя`;
      case QUEST_TYPE.INVITE:
        return isEnLocale ? `Invite fiends` : `Пригласи друзей`;
      default:
        return 'title';
    }
  };

  return {
    getTitle,
    buttonText,
    link,
  };
};

import creatorIcon from './defaultImages/Creator_icon_mobile.png';
import creatorIntro from './defaultImages/Creator_intro_mobile.png';
import experienceIcon from './defaultImages/Experience_icon_mobile.png';
import partyBanner from './defaultImages/Party_banner.png';
import partyIcon from './defaultImages/Party_icon.png';
import partyIntro from './defaultImages/Party_intro_mobile.png';
import roomIcon from './defaultImages/RoomIcon.png';
import union from './defaultImages/Union.svg';
import banner from './defaultImages/Banner.png';

export const defaultImages = {
  creatorIcon,
  creatorIntro,
  experienceIcon,
  partyBanner,
  partyIcon,
  partyIntro,
  roomIcon,
  union,
  banner,
};

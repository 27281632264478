import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';
import { defaultImages } from '@src/art/resources/loadResources';
import { Theme } from '@material-ui/core';

// Only for >= tablet
export const roomCardSettings = {
  width: 155,
  gapRow: 15,
  gapBottom: 25,
};

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    background: 'none',
    border: 'none',
    flex: '0 0 auto',
    boxShadow: 'none',
    display: 'block',
    cursor: 'pointer',
    height: 'fit-content',
  },
  title: {
    fontFamily: `${fontFamily} !important`,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#1B2036',
    // backgroundColor: 'white',
    borderRadius: '5px',
    padding: '5px 0 3px',
    width: '97%',
    // position: 'absolute',
    bottom: '1px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineClamp: 1,
    boxOrient: 'vertical',
    display: 'block',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '24px',
    },
  },
  gameType: {
    fontFamily: `${fontFamily} !important`,
    fontSize: '10px',
    lineHeight: '12px',
    textAlign: 'center',
    color: '#5F6372',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    lineClamp: 1,
    fontWeight: 400,
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      lineHeight: '17px',
    },
  },
  roomIcon: {
    borderRadius: '50%',
    flexShrink: 0,
    minWidth: '85px',
    maxWidth: '105px',
    minHeight: '85px',
    maxHeight: '105px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: '105px',
      maxWidth: '150px',
      minHeight: '105px',
      maxHeight: '150px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '85px',
      minHeight: '85px',
    },
  },
  background: {
    backgroundImage: `url(${defaultImages.union})`,
    backgroundRepeat: 'no-repeat',
    alignItems: 'center',
    flexDirection: 'column',
    width: '95px',
    height: '117px',
    [theme.breakpoints.up('sm')]: {
      width: '155px',
      height: '191px',
      backgroundSize: 'cover',
      backgroundPosition: 'bottom',
    },
  },
}));

import { AppDispatch } from '@src/app/store';
import { updateGameState } from '@store/reducers/game.reducer';

import { CHESTS_STATE, ChestsState } from '@shared/gameInterfaces/chests.socket';

enum LABELS {
  ACTIVE = 'Active',
  ADDED = 'Added',
  SKIPED = 'Skiped',
}

const updateChestsLabelsThunk = (game: ChestsState) => (dispatch: AppDispatch) => {
  const { gameState, stakedPlayers, activePlayerId, skippedPlayers } = game;
  const startStates = [CHESTS_STATE.READY_START_ROUND].includes(gameState);
  const activeStates = [CHESTS_STATE.ROUND_IN_PROGRESS].includes(gameState);

  const labels = new Map<string, string>();

  if (startStates) {
    stakedPlayers!.forEach((id: string) => labels.set(id, LABELS.ADDED));
    skippedPlayers!.forEach((id: string) => labels.set(id, LABELS.SKIPED));
  }

  if (activeStates) {
    labels.set(activePlayerId || '', LABELS.ACTIVE);
  }

  dispatch(updateGameState({ playerLabels: [...labels.entries()] }));
};

export { updateChestsLabelsThunk };

import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

const LoveBottleRules: React.FC = () => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();

  return (
    <>
      {isEnLocale ? (
        <ul className={classes.list}>
          <li>Spin the bottle to pick up a dating partner</li>
          <li>Answer questions</li>
          <li>Do not tell your answers anyone</li>
          <li>
            If your <b>answers match</b> the answers of your partner, then your <b>date continues.</b>
          </li>
          <li>
            If your <b>answers do not match</b> the answers of your partner, then your <b>date is over</b>
          </li>
          <li>
            In the end of the successful date decide whether you want <b>to kiss</b> your partner
          </li>
        </ul>
      ) : (
        <ul className={classes.list}>
          <li>Крути бутылочку, чтобы найти себе партнера для свидания</li>
          <li>Отвечай на вопросы</li>
          <li>Никому не говори свои ответы</li>
          <li>
            Если твои <b>ответы совпадут</b> с ответами твоего партнёра, то ваше <b>свидание продолжится</b>
          </li>
          <li>
            Если твои ответы <b>не совпадут</b> с ответами твоего партнёра, то ваше <b>свидание закончится</b>
          </li>
          <li>
            В конце удачного свидания реши, хочешь ли ты <b>поцеловать</b> партнера
          </li>
        </ul>
      )}
    </>
  );
};

export { LoveBottleRules };

import { fontFamily } from '@src/art/theme/textStyles';
import { makeStyles } from '@material-ui/core/styles';
import ThreeDots from '@art/icons/three-dots.svg';

export const styles = makeStyles((theme) => ({
  profileHeader: {
    display: 'flex',
    padding: '15px 15px 10px',
    width: '100%',
    height: '85px',
    background: '#F5F3DF',
    borderRadius: '20px 20px 0px 0px',
    alignItems: 'center',
    borderBottom: '1px solid #AE5D02',
    // overflow: 'hidden',
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {},
    '& .profile': {
      '&__icon': {
        width: '42px',
        height: '42px',
        marginRight: '10px',
        [theme.breakpoints.up('sm')]: {
          width: '60px',
          height: '60px',
        },
      },
      '&__info': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden',
        marginRight: '5px',
        '&-title': {
          fontFamily: fontFamily,
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '21px',
          lineHeight: '24px',
          color: 'black',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          // max-width: 200px;
          [theme.breakpoints.up('sm')]: {
            fontSize: '24px',
            lineHeight: '29px',
          },
          '&.wallet': {
            fontSize: '12px',
            lineHeight: '17px',
            width: 'max-content',
          },
        },
        '&-arrow': {
          marginLeft: '5px',
          width: '6px',
          height: '7px',
          cursor: 'pointer',
        },
      },
      '&__button': {
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 'auto',
        flexShrink: 0,
        '&-info': {
          fontFamily: fontFamily,
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '18px',
          color: '#1B2036',
        },
      },
    },
  },

  // walletAddress: {
  //   alignItems: 'center',
  //   marginBottom: '2px',

  //   // adress
  //   '& > span': {
  //     color: '#5F6372',
  //     fontWeight: 500,
  //     fontSize: '12px',
  //     lineHeight: '14px',
  //     minWidth: '54px',
  //     [theme.breakpoints.up('sm')]: {
  //       fontSize: '14px',
  //       lineHeight: '17px',
  //       minWidth: '62px',
  //     },
  //   },
  // },

  connectYourWalletButton: {
    padding: '8px 16px',
    height: '32px',
    outline: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    borderRadius: '70px',
    border: '0.5px solid #000000',
    boxShadow: 'inset 0px -2px 0px rgba(0, 0, 0, 0.3)',
    transition: 'all .23s',
    cursor: 'pointer',
    background: 'linear-gradient(180deg, #0A97FF 0%, #1050CB 100%)',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#FFFFFF',
    textStroke: '0.5px #000000',
    textShadow: '0px 1px 0px #000000',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      padding: '12px 15px',
      width: '180px',
      height: '40px',
    },
    '&:hover, &:focus': {
      backgroundColor: '#356FD6',
    },
    '& > i': {
      display: 'none',
    },
    // [theme.breakpoints.up('sm')]: {
    //   width: '180px',
    //   height: '40px',
    // },

    '&.connected': {
      backgroundColor: 'transparent',
      padding: 0,
      width: 'auto',
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      color: '#1B2036',
      height: 'auto',
      background: 'transparent',
      textStroke: 'transparent',
      textShadow: 'none',
      border: 'none',
      boxShadow: 'none',

      // [theme.breakpoints.up('sm')]: {
      //   fontSize: '14px',
      //   lineHeight: '17px',
      // },
      '&:hover': {
        backgroundColor: 'transparent',
        backgroundImage: 'none',
      },
      '& > i': {
        display: 'none',
      },
    },
  },

  disconnectYourWalletButton: {
    display: 'inline-flex',
    justifyContent: 'center !important',
    alignItems: 'center',
    width: '109px',
    height: '36px',
    backgroundColor: '#EAF1FE',
    color: '#2C74F3',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
    borderRadius: '30px',
    letterSpacing: '-0.5px',
    border: 0,
    outline: 'none',
    cursor: 'pointer',
    transition: 'all .23s',
    '&:hover, &:focus': {
      backgroundColor: 'rgba(0,0,0, 0.15)',
    },
    [theme.breakpoints.up('sm')]: {
      width: '120px',
      height: '40px',
      fontSize: '16px',
      lineHeight: '19px',
    },
    '& > i': { display: 'none' },
  },
}));

// '&.wallet-adapter-button[disabled]': {
//   backgroundColor: '#BBBCC3',
//   width: '175px',
//   color: 'white',

//   // wallet img
//   '& img': { filter: 'saturate(0)' },
// },

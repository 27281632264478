import React from 'react';
import { useStyles } from './styles';
import { CSSProperties } from '@material-ui/styles';

interface IProps {
  value?: number | string;
  type: string;
  maxLength?: number;
  placeholder?: string;
  style?: CSSProperties;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<IProps> = ({ value, type, maxLength, placeholder, style, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.formInner}>
      <div className={classes.customInput}>
        <input
          onChange={onChange}
          value={value}
          id="value"
          name="value"
          type={type}
          style={style}
          autoComplete="off"
          maxLength={maxLength}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default Input;

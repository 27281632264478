const QuizClassicTextContentEn: React.FC = () => {
  return (
    <>
      <li>
        A player gets <b>1 point</b> for correct answer
      </li>
      <li>
        <b>0 points</b> for incorrect answer
      </li>
    </>
  );
};

const QuizClassicTextContentRu: React.FC = () => {
  return (
    <>
      <li>
        Игрок получает <b>1 очко</b> за правильный ответ
      </li>
      <li>
        <b>0 очков</b> - за неправильный ответ
      </li>
    </>
  );
};

export { QuizClassicTextContentEn, QuizClassicTextContentRu };

import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  modalWrapper: {
    [theme.breakpoints.up('sm')]: {
      width: '520px',
      height: 'auto',
      maxWidth: 'none',
    },
    [theme.breakpoints.up('md')]: {
      width: '720px',
      height: '520px',
    },
  },
  wrapper: {
    color: 'inherit',
    flexDirection: 'column',
    textAlign: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '10px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px 20px 20px',
    },
    '& > h3': {
      fontFamily: fontFamily,
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '29px',
      marginTop: 0,
      fontStyle: 'normal',
      textAlign: 'center',
      color: '#1B2036',
      [theme.breakpoints.up('sm')]: {
        fontSize: '32px',
        lineHeight: '38px',
      },
    },
    '& img': {
      width: '175px',
      height: '145px',
      [theme.breakpoints.up('sm')]: {
        width: '240px',
        height: '195px',
        marginBottom: '5px',
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: '25px',
      },
      '&.animation': {
        animationName: 'appearing',
        animationDuration: '0.5s',
        transition: 'width 1s linear',
      },
    },
    '& > .thanks': {
      width: '175px',
      height: '142px',
      [theme.breakpoints.up('sm')]: {
        width: '215px',
        height: '175px',
        marginBottom: '10px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '280px',
        height: '244px',
        marginBottom: '40px',
      },
    },
  },

  handler: {
    background: 'rgba(15, 94, 213, 0.1)',
    boxShadow: 'inset 0px 2px 8px rgb(16 90 211 / 5%)',
    borderRadius: '30px',
    border: 'none',
    width: '99%',
    fontFamily: 'Nunito Sans',
    fontWeight: 900,
    fontSize: '24px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#5568AE',
    alignItems: 'center',
    marginBottom: '10px',
    [theme.breakpoints.up('sm')]: {
      height: '55px',
      marginBottom: '20px',
    },
    '& > div': {
      width: '33%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '8px 0px',
      cursor: 'pointer',
      '&.active': {
        color: '#FFFFFF',
        textStroke: '0.5px #000000',
        textShadow: '0px 1px 0px #000000',
        background: '#2C74F3',
        borderRadius: '100px',
        [theme.breakpoints.up('sm')]: {
          height: '100%',
        },
      },
    },
  },

  customInput: {
    display: 'flex',
    position: 'relative',
    width: ' 100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '25px',
    },

    '& > .currency': {
      position: 'absolute',
      right: 19,
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'flex',
      color: '#5568AE',
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '15px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '22px',
      },
    },

    '& > input': {
      background: 'rgba(15, 94, 213, 0.1)',
      boxShadow: 'inset 0px 2px 8px rgb(16 90 211 / 5%)',
      borderRadius: '30px',
      border: 'none',
      padding: '11px 20px',
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '21px',
      color: '#1B2036',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
        lineHeight: '21px',
        height: '55px',
      },
      '&::-webkit-outer-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&::-moz-inner-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&::-moz-outer-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&:hover': {
        '&::-webkit-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
      },
      '&:focus': {
        '&::-webkit-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
      },
      '&::placeholder': {
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '21px',
        color: '#5568AE',
        fontFamily: fontFamily,
        [theme.breakpoints.up('sm')]: {
          fontSize: '24px',
        },
      },
    },
  },
}));

import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

const TwentyQuestionsRules: React.FC = () => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();

  return (
    <>
      {isEnLocale ? (
        <ul className={classes.list}>
          <li>An active player spins the roulette and reads a question out loud</li>
          <li>All players answers a question</li>
          <li>See voting results and discuss who answered how and why</li>
        </ul>
      ) : (
        <ul className={classes.list}>
          <li>Активный игрок крутит рулетку и читает вопрос вслух</li>
          <li>Все игроки отвечают на вопрос</li>
          <li>Посмотрите результаты голосования и обсудите, кто как ответил и почему</li>
        </ul>
      )}
    </>
  );
};

export { TwentyQuestionsRules };

import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  containerStyles: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '600px',
      marginBottom: '100px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '875px',
      marginBottom: 'auto',
      marginTop: '70px',
    },
  },

  containerVolume: {
    margin: 'auto',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },

  userAnswer: {
    width: '100%',
    padding: '6px 0',
    justifyContent: 'center',
    top: '97%',
    position: 'absolute',
    zIndex: 5,
    background: 'linear-gradient(77.61deg, #FDCC88 0%, #FCBC85 51.56%, #FC9E8C 100%)',
    borderRadius: '0px 0px 8px 8px',
    [theme.breakpoints.up('sm')]: {
      padding: '9px 0',
    },
  },

  userAnswerText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'center',
    color: '#1B2036',
    fontFamily: fontFamily,
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '19px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '17px',
    },
  },

  personContainer: {
    position: 'relative',
    display: 'flex',
    flexGrow: 0,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#C4C4C4',
    borderRadius: '10px !important',
    maxWidth: '215px',
    height: '55vw',
    width: '50%',
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '320px',
      maxHeight: '425px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '420px',
      maxHeight: '252px',
      width: '100%',
      height: '17vw',
    },
  },

  pairContainer: {
    transitionDelay: '2s',
    transitionDuration: '0s',
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    display: 'block',
  },

  normalContainer: {
    position: 'relative',
    borderRadius: '4px',
    display: 'flex',
  },

  muteIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '55px',
    height: '55px',
    position: 'absolute',
    right: '1px',
    bottom: '12px',
    zIndex: 5,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('sm')]: {
      width: '110px',
      height: '110px',
      bottom: '22px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '55px',
      height: '55px',
      bottom: '12px',
    },
  },
  alias: {
    width: '65px',
    height: '33px',
    [theme.breakpoints.up('sm')]: {
      width: '132px',
      height: '69px',
    },
    [theme.breakpoints.up('md')]: {
      width: '190px',
      height: '99px',
    },
  },
}));

export const wordStyles = makeStyles((theme) => ({
  wordShowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '215px',
    height: '55vw',
    width: '50%',
    background: '#FFFFFF',
    border: '5px solid #F05452',
    borderRadius: '10px',
    flexGrow: 0,
    marginRight: '10px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '320px',
      maxHeight: '425px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '430px',
      maxHeight: '252px',
      width: '100%',
      height: '17vw',
    },
  },

  word: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'SF Pro Rounded',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '28px',
    lineHeight: '33px',
    textAlign: 'center',
    color: '#F05452',
    width: '100%',
    padding: '20px',
    wordBreak: 'break-word',
    [theme.breakpoints.up('sm')]: {
      fontSize: '44px',
      lineHeight: '52px',
      padding: '40px 10px',
    },
  },

  description: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    fontFamily: 'SF Pro Rounded',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#1B2036',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      fontSize: '32px',
      lineHeight: '38px',
    },
  },
}));

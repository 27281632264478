import { useEffect, useState } from 'react';
import { useAppSelector } from '@src/app/hooks';
import { simpleMerge } from '@src/common/generics';
import { CLIENT_EVENTS, SERVER_EVENTS } from '@shared/socketEvents/eventTypes';
import { defaultGameState, initialMapState } from './matchMe.types';
import WSClient from '@src/sockets';
import { MatchMeState } from '@shared/gameInterfaces/matchMe.socket';
import { selectPlayerId } from '@src/store/reducers/player.reducer';

export const useMatchMeProvider = (roomId: string) => {
  const playerId = useAppSelector(selectPlayerId);
  const [state, setState] = useState<MatchMeState>(defaultGameState);
  const stateMatcher = { ...initialMapState, [state.gameState]: true };

  useEffect(() => {
    const updateState = ({ data }: any) => setState(simpleMerge(state, data.matchMe));

    WSClient.on(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    WSClient.on(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);

    WSClient.emit(CLIENT_EVENTS.GAME_STATE_REQUESTED, { roomId });

    return () => {
      WSClient.off(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);
      WSClient.off(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    };
  }, []);

  const setVote = (answerIndex: number) => setState(simpleMerge(state, { vote: answerIndex }));

  return [stateMatcher, { ...state, state: stateMatcher }, playerId, { setVote }] as const;
};

import { ClearAllButton } from '../';
import { DrawControls, PalleteList } from './parts';
import { styles } from './styles';

type Props = {
  penWidth: number;
  menuItem: string;
  brushColor: string;
  isActivePlayer: boolean;
  onClear: () => void;
  setMenuItem: (item: string) => void;
  onChangeColor: (color: string) => void;
  onChangePen: (penWidth: number) => void;
  children: any;
};

const DesktopMenu: React.FC<Props> = ({
  penWidth,
  menuItem,
  brushColor,
  isActivePlayer,
  onClear,
  setMenuItem,
  onChangeColor,
  onChangePen,
  children,
}) => {
  const classes = styles();

  return (
    <aside className={classes.desktopMenu}>
      {/* DesktopMenuContent */}
      {children}

      {isActivePlayer && (
        <div className={classes.desktopMenuInterface}>
          {/* clear all */}
          <ClearAllButton className="desktop" onClick={onClear} />

          {/* pen, erase and brush sizes */}
          <DrawControls menuItem={menuItem} penWidth={penWidth} setMenuItem={setMenuItem} onChangePen={onChangePen} />

          {/* colors-pallete */}
          <PalleteList brushColor={brushColor} setMenuItem={setMenuItem} onChangeColor={onChangeColor} />
        </div>
      )}
    </aside>
  );
};

export { DesktopMenu };

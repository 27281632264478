import { Active, Passive, ActiveStatic, PassiveStatic } from './parts';
import { styles } from './styles';

interface Props {
  game: any;
  debug?: boolean;
  activePlayer: any;
  isActivePlayer: boolean;
}

const ShowWord: React.FC<Props> = ({ game, debug = false, isActivePlayer, activePlayer }) => {
  const classes = styles();
  const { chosenWord } = game;

  return (
    <div className={`${classes.wordShow} animate__animated  animate__fadeInDown`}>
      {/* debug */}
      {debug ? (
        isActivePlayer ? (
          <ActiveStatic />
        ) : (
          <PassiveStatic />
        )
      ) : // game
      isActivePlayer ? (
        <Active chosenWord={chosenWord} />
      ) : (
        <Passive chosenWord={chosenWord} activePlayer={activePlayer} />
      )}
    </div>
  );
};

export { ShowWord };

import React from 'react';
import { useMatchMeHook } from './matchMe.hook';
import { Question, Results } from './parts';

import { styles } from './styles';

interface IProps {
  roomId: string;
}

const MatchMe: React.FC<IProps> = ({ roomId }) => {
  const classes = styles();
  const { state, game, isActivePlayer, actions } = useMatchMeHook(roomId);
  const { onPlayerVoted, onGameQuit } = actions;

  if (!game.activeGameId || !isActivePlayer) return <></>;

  return (
    <section className={`${classes.matchMe} ${state.RESULTS ? 'results' : ''}`}>
      <div className={classes.matchMeWrapper}>
        {state.IN_PROGRESS && <Question game={game} onPlayerVoted={onPlayerVoted} />}
        {state.RESULTS && <Results game={game} onGameQuit={onGameQuit} />}
      </div>
    </section>
  );
};

export { MatchMe };

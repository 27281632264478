import { AppDispatch } from '@src/app/store';
import { GUESS_ME_STATE } from '@shared/gameInterfaces/guessMe.socket';
import { updateGameState } from '@store/reducers/game.reducer';
import { IGameState } from './guessMe.types';

enum LABELS {
  VOTED = 'Voted',
  READY = 'Ready',
  ACTIVE = 'Active',
}

const updateGuessMeLabelsThunk = (game: IGameState) => (dispatch: AppDispatch) => {
  const gameState = game.gameState;
  const votedPlayers = game.votedPlayers;
  const readyPlayers = game.readyPlayers;

  const readyStates = [GUESS_ME_STATE.RESULTS, GUESS_ME_STATE.TOP_PLAYERS].includes(gameState);
  const voteStates = [GUESS_ME_STATE.QUESTION_ACTIVE, GUESS_ME_STATE.QUESTION_PASSIVE].includes(gameState);
  const activeStates = [GUESS_ME_STATE.READY_START_ROUND, GUESS_ME_STATE.SPIN, GUESS_ME_STATE.QUESTION_ACTIVE].includes(
    gameState
  );

  const labels = new Map<string, string>();

  if (activeStates) {
    labels.set(game.activePlayerId, LABELS.ACTIVE);
  }

  if (voteStates) {
    votedPlayers!.forEach((playerId) => labels.set(playerId, LABELS.VOTED));
  }

  if (readyStates) {
    readyPlayers!.forEach((playerId) => labels.set(playerId, LABELS.READY));
  }

  dispatch(updateGameState({ playerLabels: [...labels.entries()] }));
};

export { updateGuessMeLabelsThunk };

import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '@app/hooks';
import { getHomepageBanners } from '@store/reducers/common';
import { selectIsInRoom } from '@store/room/roomSlice';
import { getHomepageScroll, setSessionState } from '@store/reducers/session.reducer';
import { HeroBanner } from '@components/HeroBanner';
import { HomePageContent } from './content';
import { mainCardStyle } from './styles';

const roomHPContent: React.FC = () => {
  const dispatch = useDispatch();
  const classes = mainCardStyle();
  const rootRef = useRef<HTMLDivElement>(null);
  const homepageScroll = useSelector(getHomepageScroll);
  const bannerInfos = useAppSelector(getHomepageBanners);
  const [bannerHeight, setBannerHeight] = useState(0);

  const scroll = () => {
    if (!rootRef.current) {
      return;
    }

    dispatch(setSessionState({ homepageScroll: rootRef.current.scrollTop }));
  };

  useEffect(() => {
    if (rootRef.current) {
      rootRef.current.scrollTop = homepageScroll;
    }
  }, []);

  const onBannerLoaded = (height: number) => {
    setBannerHeight(height);
    setTimeout(() => {
      if (rootRef.current) {
        rootRef.current.scrollTop = homepageScroll;
      }
    }, 0);
  };

  return (
    <div className={classes.roomContent} onScroll={scroll} ref={rootRef}>
      <HeroBanner bannerInfos={bannerInfos} onLoaded={onBannerLoaded} />
      <HomePageContent />
    </div>
  );
};

export default roomHPContent;

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';
import { Theme } from '@material-ui/core';

export const gameCardSettings = {
  width: 315,
  gapRow: 15,
  gapBottom: 25,
};

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '8px',
      flex: '0 0 auto',
      // width: '165px',
      // height: '90px',
      display: 'block',
      boxShadow: 'none',
      position: 'relative',
      flexDirection: 'column',
      overflow: 'inherit',
      '& .MuiCardActionArea-root': {
        borderRadius: '8px',
      },
      [theme.breakpoints.up('md')]: {
        width: '315px',
        height: '175px',
      },
    },
    img: {
      width: '100%',
      // height: '90px',
      maxWidth: '315px',
      display: 'block',
      borderRadius: '8px',
      [theme.breakpoints.up('md')]: {
        width: '315px',
        height: '175px',
      },
    },
    gameTitle: {
      fontFamily: fontFamily,
      borderRadius: '0px 0px 8px 8px',
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 112.5%)',
      position: 'absolute',
      bottom: 0,
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '29px',
      color: '#FFFFFF',
      textShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
      padding: '22px 19px 14px',
      width: '100%',
      boxSizing: 'border-box',
    },
    description: {
      fontFamily: fontFamily,
      height: '38px',
      marginTop: 8,
      fontSize: '16px',
      lineHeight: '19px',
      color: '#5F6372',
      marginBottom: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      boxOrient: 'vertical',
      // whiteSpace: 'nowrap',
      display: '-webkit-box',
    },
    tooltip: {
      background: 'linear-gradient(180deg, #C2E8FF -13.64%, #A7CAFF 100%, rgba(196, 196, 196, 0) 100%)',
      borderRadius: '20px',
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'center',
      color: '#1B2036',
      position: 'absolute',
      padding: '13px 22px',
      zIndex: 4,
      top: '-55px',
      left: 27,
    },
    triangle: {
      display: 'block',
      width: 0,
      height: 0,
      position: 'absolute',
      bottom: -40,
      top: '-5px',
      left: '161px',
      marginLeft: '-21px',
      border: '12px solid transparent',
      borderTop: '12px solid rgba(167, 202, 255, 1)',
      zIndex: 4,
    },
    jackpot: {
      position: 'absolute',
      zIndex: 1,
      top: '-2.5px',
      width: '40px',
      height: '50px',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        width: '80px',
        height: '90px',
        top: '-5px',
      },
      '& > img': {
        width: '15px',
        height: '15px',
        marginTop: '5px',
        [theme.breakpoints.up('sm')]: {
          width: '25px',
          height: '25px',
          margin: '12px 8px 0px 0px',
        },
      },
      '& > p': {
        fontFamily: 'SF Pro Rounded Black',
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: '8px',
        // lineHeight: '17px',
        textAlign: 'center',
        color: '#FFFFFF',
        textStroke: '0.5px #591A54',
        [theme.breakpoints.up('sm')]: {
          fontSize: '14px',
          marginRight: '8px',
        },
        '&.infinity': {
          fontSize: '17px',
          lineHeight: '11px',
          [theme.breakpoints.up('sm')]: {
            fontSize: '28px',
            lineHeight: '17px',
          },
        },
      },
    },
  })
);

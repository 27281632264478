import { IdType } from '@shared/generics';
import { BaseGameState } from '../socketEvents/socketEvents';

export enum QUIZ_BATTLE_ROYALE_STATE {
  READY_START_ROUND = 'READY_START_ROUND',
  SPIN = 'SPIN',
  ROUND_IN_PROGRESS = 'ROUND_IN_PROGRESS',
  SHOWING_RIGHT_ANSWER = 'SHOWING_RIGHT_ANSWER',
  LOOP_RESULTS = 'LOOP_RESULTS',
  QUESTION_RESULTS = 'QUESTION_RESULTS',
  ROUND_RESULTS = 'ROUND_RESULTS',
  LEADERBOARD = 'LEADERBOARD',
}
export interface QuizBattleRoyaleState extends BaseGameState {
  gameState: QUIZ_BATTLE_ROYALE_STATE;
  activePlayerId: string;
  roundNumber: number;
  totalRounds: number;
  activeQuestion: number;
  countQuestions: number;
  loopCurrent: number;
  ticketData?: {
    ticketId: IdType;
    title: string;
    options: [
      {
        title: string;
      }
    ];
    rightAnswer?: number;
  };
  votedPlayers: string[];
  readyPlayers?: string[];
  timer?: {
    countDown: number;
    timeIsUp: boolean;
  };
  questionResults?: {
    name: string;
    type: 'active' | 'ghost';
    points: number;
  }[];
  roundResults?: {
    name: string;
    points: number;
  }[];
  finalLeaderboard?: {
    name: string;
    value: number;
    place: number;
  }[];
}

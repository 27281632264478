import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';
import { isSafari, isMobileSafari } from 'react-device-detect';

// # Scrollbar containers inside game

// mobile and tablet
// .game - has no scrollbar (display: flex)
// .gameWrapper - has scrollbar (display: block, overflowY: 'scroll')

// desktop
// .game - add scrollbar (display: block, overflowY: 'scroll')
// .gameWrapper - remove scrollbar (display: flex, overflowY: 'initial')

export const styles = makeStyles((theme) => ({
  game: {
    position: 'relative',
    display: 'flex',
    fontFamily,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    // minHeight: '100%',
    // minWidth: '100%',
    // flexGrow: 1,
    flexDirection: 'column',
    boxSizing: 'border-box',
    '& *': { boxSizing: 'border-box' },

    // add desktop scrollbar
    /*[theme.breakpoints.up('md')]: {
      display: 'block', // display: 'flex' broke desktop scrollbar
      overflowY: 'scroll',
      padding: '15px',

      // visual hide scrollbar
      // IE 11
      '-ms-overflow-style': 'none',
      // Firefox
      'scrollbar-width': 'none',
      // Chrome, Safari
      '&::-webkit-scrollbar': {
        display: 'none',
        background: 'transparent',
        width: 0,
      },
    },*/

    // isGrayWindow
    '&.isGrayWindow': {
      '& .desktopCentered': {
        [theme.breakpoints.up('md')]: {
          backgroundColor: '#F3F4F5',
        },
      },
    },

    // transparent
    '&.transparent': {
      '& .desktopCentered': {
        [theme.breakpoints.up('md')]: {
          backgroundColor: 'transparent',
        },
      },
    },

    // hasDrawCanvas
    '&.hasDrawCanvas': {
      '& .gameWrapper': {
        padding: 0,
      },
      '& .desktopCentered': {
        padding: 0,
        height: '100%',
        [theme.breakpoints.up('md')]: {
          height: 'auto',
        },
      },
    },
  },

  gameWrapper: {
    position: 'relative',
    padding: '15px',
    backgroundColor: 'transparent',

    // fix Safary height bug collapse
    // display: isSafari || isMobileSafari ? 'flex' : 'block',
    display: 'flex',
    // flexGrow: 1,

    // add mobile scrollbar
    overflowY: 'scroll',
    width: '100%',
    height: '100%',
    // minHeight: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // IE 11
    '-ms-overflow-style': 'none',
    // Firefox
    'scrollbar-width': 'none',
    // Chrome, Safari
    '&::-webkit-scrollbar': {
      display: 'none',
      background: 'transparent',
      width: 0,
    },

    [theme.breakpoints.up('sm')]: {
      padding: '30px',
    },

    /*[theme.breakpoints.up('md')]: {
      // remove mobile scrollbar
      // overflowY: 'initial',
      // height: 'auto',
//       display: 'flex',
      flexDirection: 'column',
      //justifyContent: 'center',
      //alignItems: 'center',
      maxWidth: '975px',
      minHeight: '500px',
      borderRadius: '20px',
      backgroundColor: 'white',
      // flexGrow: 'initial',
    },*/
  },

  desktopCentered: {
    display: 'flex',
    flexDirection: 'column',
    // minHeight: '100%',
    // minWidth: '100%',
    width: '100%',
    minHeight: '100%',
    // height: '100%', // height: '100%' broke desktop scrollbar
    //flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',

    [theme.breakpoints.up('md')]: {
      // remove mobile scrollbar
      // overflowY: 'initial',
      // height: 'auto',
      //       display: 'flex',
      // flexDirection: 'column',
      //justifyContent: 'center',
      //alignItems: 'center',
      maxWidth: '975px',
      minHeight: '500px',
      borderRadius: '20px',
      backgroundColor: 'white',
      padding: '15px',
      // flexGrow: 'initial',
    },
  },
}));

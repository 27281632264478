import { Dispatch, SetStateAction } from 'react';
import { useAppSelector } from '@app/hooks';
import { IRoomPlayer } from '@store/room/roomSlice';
import CommonModal from '@components/Modals/CommonModal';
import { styles } from './styles';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import Button from '@src/components/Button';
import { ITimer } from '@src/shared/common';
import { TextTimer } from '@src/games/common';

interface Props {
  isOpen: boolean;
  activePlayer: IRoomPlayer;
  onVote: (value: boolean) => void;
  timer?: ITimer;
}

const GuessedModal: React.FC<Props> = ({ isOpen, activePlayer, onVote, timer }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const onValueClick = (value: boolean) => {
    onVote(value);
  };

  return (
    <CommonModal isOpen={isOpen} handleClose={() => {}} className={classes.chooseModal} padding={false}>
      <div className={classes.timer}>
        <TextTimer timer={timer!} />
      </div>
      <h2>{isEnLocale ? `Did ${activePlayer.name} guess phrase?` : `${activePlayer.name} отгадал фразу?`}</h2>
      <div className={classes.btnContainer}>
        <Button
          onClick={() => onValueClick(false)}
          text={isEnLocale ? 'No' : 'Нет'}
          type={'primary-red-new'}
          style={{ marginRight: '7px' }}
        />
        <Button onClick={() => onValueClick(true)} text={isEnLocale ? 'Yes' : 'Да'} type={'primary-green-new'} />
      </div>
    </CommonModal>
  );
};

export { GuessedModal };

import { GameTypes } from '@src/common/constants/common';
import QuestionButton from '@components/QuestionButton';
import heartPoint from '@art/icons/LoveBottle/matchHeart.svg';
import { IGameState } from '../game.types';
import { NextButton } from '@src/games/common/NextButton/NextButton';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { useAppSelector } from '@app/hooks';
import { loveBottleLeaderboard } from '../game.styles';

interface IProps {
  game: IGameState;
  onNext: () => void;
  isSpectator: boolean;
}

const RoundResults: React.FC<IProps> = ({ game, onNext, isSpectator }) => {
  const classes = loveBottleLeaderboard();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const addEmptyItems = (itemsArrayLength: number) => {
    const maxItems = 5;
    const emptyItemsToAdd = maxItems - itemsArrayLength;

    if (itemsArrayLength < maxItems) {
      return [1, 2, 3, 4, 5].slice(-emptyItemsToAdd).map((item) => (
        <div className={classes.leaderboardTableRow} key={item}>
          <span className={classes.resultsTableRowName}>-</span>
          <div className={classes.heartContainer}>
            <img className={classes.heartPoint} src={heartPoint}></img>
            <b className={classes.resultsTableRowPoints}></b>
          </div>
          <span className={classes.resultsTableRowName}>-</span>
        </div>
      ));
    }
    return null;
  };

  return (
    <div className={classes.resultsWrapper}>
      <h2 className="mobile-outline">{isEnLocale ? 'Top Couples' : 'Лучшие пары'}</h2>

      <div className={classes.leaderboardTable}>
        {game.topCouples!.map((item, index) => {
          const { playerName, matchName, value } = item;

          return (
            <div className={classes.leaderboardTableRow} key={index}>
              <span className={classes.resultsTableRowName}>{playerName}</span>
              <div className={classes.heartContainer}>
                <img className={classes.heartPoint} src={heartPoint}></img>
                <b className={classes.resultsTableRowPoints}>{value}</b>
              </div>
              <span className={classes.resultsTableRowName}>{matchName}</span>
            </div>
          );
        })}
        {addEmptyItems(game.topCouples!.length)}
      </div>

      <div className={classes.buttonWrapper}>
        <NextButton isSpectator={isSpectator} onClick={onNext} isTopPlayers={true} />
        <QuestionButton gray={true} gameType={GameTypes.LOVE_BOTTLE} />
      </div>
    </div>
  );
};

export { RoundResults };

import React from 'react';
import { useAppSelector } from '@app/hooks';
import arrowDown from '@art/icons/arrow-down.svg';
import { styles } from './styles';
import { BlockchainConnectButton } from '@src/features/blockchain';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { IdType } from '@src/shared/generics';
import { selectAvatars } from '@src/store/room/roomFeaturesData.slice';

type Props = {
  tokenBalance: number;
  walletConnected: boolean;
  avatarId: IdType;
  name: string;
};

const Header: React.FC<Props> = ({ tokenBalance, walletConnected, avatarId, name }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const avatars = useAppSelector(selectAvatars);
  const avatar = avatars.find((avatar) => avatar.id === avatarId);

  return (
    <header className={classes.profileHeader}>
      <img
        className={'profile__icon'}
        src={avatar?.image}
        alt="avatar"
        style={{ backgroundColor: `#${avatar?.background}` }}
      />

      <div className={'profile__info'}>
        <p className={'profile__info-title'}>{name}</p>
      </div>

      <div className={'profile__button'}>
        {walletConnected && (
          <p className={'profile__info-title wallet'}>
            {isEnLocale ? 'Wallet Connected' : 'Кошелек'}
            <img className={'profile__info-arrow'} src={arrowDown} alt="drop" />
          </p>
        )}
        <BlockchainConnectButton
          walletConnected={walletConnected}
          className={` ${classes.connectYourWalletButton} ${walletConnected ? 'connected' : ''}`}
        >
          {!walletConnected ? 'Connect wallet' : ''}
        </BlockchainConnectButton>

        {walletConnected && <div className={'profile__button-info'}>{tokenBalance.toFixed(2)} HIT</div>}
      </div>
    </header>
  );
};

export { Header };

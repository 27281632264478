import { useAppSelector } from '@app/hooks';
import { TextTimer } from '@src/games/common';
// import Button from '@components/Button';
import { ITimer } from '@shared/common';
import { useDeviceSize } from '@src/common/screenSizes';
import { styles } from './styles';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';

type Props = {
  onNext: () => void;
  onVotingStart: () => void;
  subroundsLeft: number;
  activePlayerName: string;
  isSkipVoting: boolean;
  isActivePlayer: boolean;
  isSpectator: boolean;
  isFirstSubround: boolean;
  isVotingStatuses: boolean;
  timer: ITimer;
};

const FakeArtistHeader: React.FC<Props> = ({
  subroundsLeft,
  activePlayerName,
  isSkipVoting,
  isActivePlayer,
  isSpectator,
  isFirstSubround,
  isVotingStatuses,
  timer,
  onVotingStart,
  onNext,
}) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const { isDesktop } = useDeviceSize();

  return (
    <header className={classes.fakeArtistHeader}>
      <div className={classes.buttonWrapper}>
        {!isSkipVoting && !isFirstSubround && !isSpectator && (
          <div
            onClick={onVotingStart}
            className={`${classes.voteButton} ${!isEnLocale ? 'ru' : ''}`}
            style={{ fontFamily: isEnLocale ? 'Barbieri-Regular' : 'Impact' }}
          >
            {isEnLocale ? 'Vote' : 'Голосовать'}
          </div>
        )}

        <span className={classes.movesLeftInfo}>
          <span>{isEnLocale ? 'Moves left: ' : 'Ходов осталось: '}</span>
          <span>{subroundsLeft}</span>
        </span>
      </div>

      <p className="no-selection">{isEnLocale ? 'GUESS the word being drawn' : 'РИСУЙ как будто знаешь слово'}</p>

      <div className={classes.buttonWrapper}>
        {isActivePlayer && !isSpectator ? (
          <div
            onClick={onNext}
            className={`${classes.nextButton} ${!isEnLocale ? 'ru' : ''} ${isDesktop ? 'hover' : ''}`}
            style={{ fontFamily: isEnLocale ? 'Barbieri-Regular' : 'Impact' }}
          >
            {isEnLocale ? 'Next' : 'Далее'}
          </div>
        ) : (
          <div className={classes.whoDrawing}>
            <div className={classes.whoDrawingName}>{activePlayerName}</div>
            <div className={classes.whoDrawingPhrase}>{isEnLocale ? 'is drawing' : 'рисует'}</div>
          </div>
        )}

        {!isVotingStatuses ? (
          <span className={classes.headerTimer}>
            <span>{isEnLocale ? 'Time: ' : 'Время: '}</span>
            <div>
              <TextTimer timer={timer!} />
              <span>{isEnLocale ? 's' : 'c'}</span>
            </div>
          </span>
        ) : (
          <span className={classes.headerTimer}>
            <span>{isEnLocale ? 'Time: ' : 'Время: '}</span>
            <div>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
            </div>
          </span>
        )}
      </div>
    </header>
  );
};

export { FakeArtistHeader };

import React, { Ref, useEffect, useCallback, useRef, useState } from 'react';
import { Observable } from '@apollo/client';
import { Line } from '@src/features/canvas-draw/types';
import { EngineProps } from '../../DrawAndGuess.types';
import { CanvasDrawApi } from '@src/features/canvas-draw/components/CanvasDrawApi';
import {
  DesktopMenu,
  ClearAllButton,
  MobileMenu,
  MobilePalette,
  CanvasWrapper,
  CanvasDraw,
} from '@src/games/common/Drawing';
import { TimeAlert, DesktopMenuContent, ChooseModal, MobileHeader } from './parts';
import { CircleTimer } from '@src/games/common';
import { useDeviceSize } from '@src/common/screenSizes';
import { styles } from './styles';
import { DrawEvent } from '@src/shared/drawData';

export type CanvasEngineProps = EngineProps;

interface Props {
  game: any;
  activePlayer: any;
  isActivePlayer: boolean;
  onVote: (value: string | undefined) => void;
  onGameStateRequest: (totalLines: number) => void;
  eventObserver: Observable<DrawEvent> | undefined;
  onDrawUpdate: ((data: DrawEvent) => void) | undefined;
  onLiveUpdate: ((data: DrawEvent) => void) | undefined;
}

const Draw: React.FC<Props> = ({
  game,
  activePlayer,
  isActivePlayer,
  eventObserver,
  onVote,
  onGameStateRequest,
  onDrawUpdate,
  onLiveUpdate,
}) => {
  const classes = styles();
  const canvasApi: Ref<CanvasDrawApi> = useRef(null);
  const { isDesktop } = useDeviceSize();
  const [menuItem, setMenuItem] = useState('pen');
  const [brushRadius, setBrushRadius] = useState(4);
  const [isModalOpen, setModalOpen] = useState(false);
  const [brushColor, setBrushColor] = useState('#1E1E1E');

  const onLiveDraw = (line: Line) => {
    if (onLiveUpdate) {
      onLiveUpdate({
        liveUpdate: { line: line },
      });
    }
  };

  const onCompleteLine = (line: ArrayBufferLike) => {
    if (onDrawUpdate) {
      onDrawUpdate({
        completeLine: { line: line },
      });
    }
  };

  useEffect(() => {
    if (game.drawing) {
      canvasApi.current?.setProtobufLines(game.drawing.lines);
    }
  }, [game.drawing, canvasApi.current]);

  useEffect(() => {
    console.log('CanvasEngine load with observer', eventObserver, isActivePlayer, canvasApi);

    if (!eventObserver || isActivePlayer || !canvasApi.current) {
      return;
    }

    const subscription = eventObserver.subscribe((event: DrawEvent) => {
      if (event.liveUpdate) {
        canvasApi.current?.updateLiveDraw(event.liveUpdate.line);
      } else if (event.completeLine) {
        const lines = canvasApi.current?.addProtobufLine(event.completeLine.line);
        canvasApi.current?.finishLiveDraw();
        if (event.completeLine.totalLines != lines?.length) {
          onGameStateRequest(lines?.length || 0);
        }
      } else if (event.lines) {
        canvasApi.current?.setProtobufLines(event.lines.lines);
      } else if (event.clear) {
        canvasApi.current?.clear();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [isActivePlayer, eventObserver, canvasApi.current]);

  const { timeIsUp } = game.timer;
  const isPlayerSelectOpen = isModalOpen || (timeIsUp && isActivePlayer);

  // clear all canvas
  const onClear = useCallback(() => {
    canvasApi.current!!.clear();

    if (onDrawUpdate) {
      onDrawUpdate({ clear: {} });
    }
  }, []);

  return (
    <div className={classes.draw}>
      {/* mobile-header */}
      {!isDesktop && (
        <MobileHeader
          game={game}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          activePlayer={activePlayer}
          isActivePlayer={isActivePlayer}
        />
      )}

      {/* desktop-menu */}
      {isDesktop && (
        <DesktopMenu
          onClear={onClear}
          menuItem={menuItem}
          penWidth={brushRadius}
          brushColor={brushColor}
          setMenuItem={setMenuItem}
          isActivePlayer={isActivePlayer}
          onChangePen={(width) => setBrushRadius(width)}
          onChangeColor={(color) => setBrushColor(color)}
        >
          <DesktopMenuContent
            game={game}
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            isActivePlayer={isActivePlayer}
            activePlayerName={activePlayer.name}
          />
        </DesktopMenu>
      )}

      {/* timer */}
      <CircleTimer timer={game.timer} />

      {/* canvas-wrapper */}
      <CanvasWrapper isActivePlayer={isActivePlayer} timeIsUp={timeIsUp}>
        {/* time-alert */}
        {timeIsUp && <TimeAlert />}

        {/* clear-button mobile */}
        {!isDesktop && isActivePlayer && <ClearAllButton className={`${menuItem ? 'up' : ''}`} onClick={onClear} />}

        {/* canvas */}
        <CanvasDraw
          menuItem={menuItem}
          canvasApi={canvasApi}
          brushColor={brushColor}
          brushRadius={brushRadius}
          onLiveUpdate={onLiveDraw}
          onLineFinish={onCompleteLine}
          isActivePlayer={isActivePlayer}
        />

        {/* mobile draw controls */}
        {!isDesktop && isActivePlayer && (
          <>
            {/* mobile-menu */}
            <MobileMenu
              menuItem={menuItem}
              brushColor={brushColor}
              setMenuItem={setMenuItem}
              setBrushColor={setBrushColor}
            />

            {/* mobile-palette */}
            <MobilePalette
              menuItem={menuItem}
              penWidth={brushRadius}
              brushColor={brushColor}
              setMenuItem={setMenuItem}
              onChangePen={(width) => setBrushRadius(width)}
              onChangeColor={(color) => setBrushColor(color)}
            />
          </>
        )}
      </CanvasWrapper>

      {/* modal */}
      <ChooseModal
        onVote={onVote}
        isOpen={isPlayerSelectOpen}
        activePlayer={activePlayer}
        setModalOpen={setModalOpen}
      />
    </div>
  );
};

export { Draw };

import { useSelector } from 'react-redux';
import { useAppSelector } from '@app/hooks';
import { FakeArtistState, RESULT_STATE, VOTE_STATE } from '@shared/gameInterfaces/fakeArtist.socket';
import { selectRoomPlayers } from '@store/room/roomSlice';
import CommonModal from '@components/Modals/CommonModal';
import { CircleTimer } from '@src/games/common';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';

type Props = {
  game: FakeArtistState;
  votedId: string;
  isOpen: boolean;
  isVoteResults: boolean;
  onSkipClick: () => void;
  onVoteClick: (votePlayerId: string) => void;
};

const VoteModal: React.FC<Props> = ({ game, isOpen, isVoteResults, votedId, onSkipClick, onVoteClick }) => {
  const classes = styles();
  const players = useSelector(selectRoomPlayers);
  const subroundsLeft = game.totalSubrounds! - game.subroundNumber!;
  const isEnLocale = useAppSelector(selectIsEnLocale);

  // returnSkipClickCheck
  const returnSkipClickCheck = () => {
    if (votedId === 'skip') {
      return <div className={classes.skipCheck} />;
    }
  };

  // returnSkipsCount
  const returnSkipsCount = () => {
    const skipsObj = game.results?.find((item) => {
      if (item.targetId === '') {
        return item;
      }
    });
    const skipsArray = skipsObj?.votes;

    return skipsArray?.map((item, index) => {
      return <div key={index} className={classes.skipCheck} />;
    });
  };

  // returnVotesCount
  const returnVotesCount = (playerId: string) => {
    const playerVotesObj = game.results?.find((item) => item.targetId === playerId);

    if (playerVotesObj?.votes.length) {
      return playerVotesObj?.votes.map((vote) => {
        return <div key={vote} className={classes.redSkull} />;
      });
    }
  };

  const activePlayers = players.filter((player) => game.voteList?.some((id) => id === player.playerId));

  return (
    <CommonModal isOpen={isOpen} handleClose={() => null} resetWidth={true} backgroundColor={'white'}>
      <div className={classes.voteModalContent}>
        {/* voting skip alert */}
        {isVoteResults &&
          game.voteState === VOTE_STATE.FORCED &&
          subroundsLeft &&
          game.resultState === RESULT_STATE.NONE && (
            <div className={`${classes.votingSkiped} animate__fadeInDown  animate__animated`}>
              <b>{isEnLocale ? 'Voting was skipped' : 'Голосование пропущено'}</b>
            </div>
          )}

        <h2>
          {isEnLocale
            ? !isVoteResults
              ? 'Vote who is FAKE Artist?'
              : 'Vote results'
            : !isVoteResults
            ? 'Кто ФЭЙК?'
            : 'Результаты'}
        </h2>

        {/* timer */}
        {!isVoteResults && (
          <div className={classes.voteModalTimerContainer}>
            <CircleTimer timer={game.timer!} />
          </div>
        )}

        <div className={classes.voteModalButtons}>
          {/* skip button */}
          {subroundsLeft ? (
            <div
              className={`
                ${classes.skipButton}
                ${votedId === 'skip' || isVoteResults ? 'isButtonClicked' : ''}
              `}
              onClick={onSkipClick}
              style={{ fontFamily: isEnLocale ? 'Barbieri-Regular' : 'Impact' }}
            >
              <span>{isEnLocale ? 'Skip' : 'Пропустить'}</span>

              {/* skip count indicator */}
              <div className={classes.skipCount}>{!isVoteResults ? returnSkipClickCheck() : returnSkipsCount()}</div>
            </div>
          ) : null}

          {/* vote buttons */}
          {!isVoteResults ? (
            <div className={classes.voteForPlayerButtons}>
              {activePlayers?.map((player: { playerId: string; name: string }) => {
                return (
                  <div
                    key={player.playerId}
                    style={{ cursor: 'pointer' }}
                    className={`
                        ${classes.voteForPlayerButton}
                        ${player.playerId === votedId ? 'isButtonClicked' : ''}
                      `}
                    onClick={() => onVoteClick(player.playerId)}
                  >
                    <span>{player.name}</span>

                    {/* vote indicator */}
                    <div className={classes.skulls}>
                      {player.playerId === votedId ? <div className={classes.graySkull} /> : null}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            // vote buttons results
            <div className={classes.voteForPlayerButtons}>
              {activePlayers?.map((player: { playerId: string; name: string }) => {
                return (
                  <div
                    key={player.playerId}
                    className={classes.voteForPlayerButton}
                    style={{ cursor: 'default', pointerEvents: 'none' }}
                  >
                    <span>{player.name}</span>

                    {/* votes count indicator */}
                    <div className={classes.skulls}>{returnVotesCount(player.playerId)}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {isEnLocale ? (
          !isVoteResults ? (
            <p>{`Voted ${game.votedPlayers?.length} of ${activePlayers.length}`}</p>
          ) : (
            <p>All players have voted</p>
          )
        ) : !isVoteResults ? (
          <p>{`Проголосовало ${game.votedPlayers?.length} из ${activePlayers.length}`}</p>
        ) : (
          <p>Все игроки проголосовали</p>
        )}
      </div>
    </CommonModal>
  );
};

export { VoteModal };

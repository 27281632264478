import { MatchMeState } from '@shared/gameInterfaces/matchMe.socket';

export enum MATCH_ME_STATE {
  IN_PROGRESS = 'IN_PROGRESS',
  RESULTS = 'RESULTS',
}

export const initialMapState = {
  IN_PROGRESS: false,
  RESULTS: false,
};

export type IGameStateMatcher = typeof initialMapState;

export const defaultGameState: MatchMeState = {
  gameState: MATCH_ME_STATE.IN_PROGRESS,
  activeGameId: '',
  creatorAvatar: '',
  ticket: undefined,
  vote: undefined,
  results: {
    value: undefined,
    creatorName: '',
  },
  questionNumber: 0,
  totalQuestions: 0,
};

export interface IGameState extends MatchMeState {
  state: IGameStateMatcher;
}

export interface IGameActions {
  [key: string]: Function;
}

import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { getPlayersVideoState, setVideoState } from '@store/reducers/common';
import { getRoomId, IRoomPlayer, selectRoomPlayers } from '@store/room/roomSlice';
import {
  // popStickerAction,
  // selectStickerAction,
  selectStickers,
  StickerAction,
} from '@store/room/roomFeaturesData.slice';
import { PersonControlsModal } from '@components/Modals';
import { PersonName, PersonVideo } from '../';
import { useStyles } from './styles';
import { IdType } from '@shared/generics';
import WSClient from '@src/sockets';
import { CLIENT_EVENTS } from '@shared/socketEvents/eventTypes';
import { selectAvatarId, selectOwnAvatar, selectPlayerId } from '@store/reducers/player.reducer';
import { analytics } from '@src/services/amplitude';
import { PlayerStateLabel } from '../PersonStatuses/playerStateLabel';
import StickerIcon from '@art/icons/StatusBar/sticker.svg';
import muteVideo from '@art/icons/videoMuteDsk.svg';
import muteAudio from '@art/icons/audioMuteDsk.svg';
import mute from '@art/icons/mute.svg';
import { useDeviceSize } from '@src/common/screenSizes';
import { MUTE_TYPE, setAudioAccess } from '@src/store/reducers/playerData.reducer';
// import { Gif } from '@giphy/react-components';
// import { random } from 'lodash';

interface Props {
  user: IRoomPlayer;
  isLoading: boolean;
  stickerAction?: StickerAction;
}

const DURATION_BONUS = 500;
const STICKER_DURATION = 5000;

interface StickerProps {
  src: string;
  className: string;
}

const ActiveSticker: React.FC<StickerProps> = (props) => {
  const [data, setData] = useState('');
  const [id] = useState(Math.random());

  useEffect(() => {
    fetch(props.src, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
    })
      .then((res) => res.blob())
      .then(async (text) => {
        const reader = new FileReader();
        reader.onload = (ev) => {
          const result = ev.target?.result?.toString() || '';
          setData(result.replace('data:image/gif;base64', ''));
        };
        reader.readAsDataURL(text);
      });
  }, []);

  if (!data) return <></>;

  return <img src={`data:image/gif;gif-id=${id};base64` + data} className={props.className} />;
  //return <GifPlayer gif={props.src} autoplay={true} width="100%" height="100%" className={props.className} />;
};

const PersonItem: React.FC<Props> = ({ user, isLoading, stickerAction }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isDesktop } = useDeviceSize();
  const roomId = useAppSelector(getRoomId);
  const currentPlayerId = useAppSelector(selectPlayerId);
  const players = useAppSelector(selectRoomPlayers);
  // const currentPlayer = players.find((player) => player.playerId === currentPlayerId);
  const stickersList = useAppSelector(selectStickers);
  const playersVideoInfo = useAppSelector(getPlayersVideoState);
  const stickerContainerRef = useRef<HTMLDivElement>(null);
  const [stickerKey, setStickerKey] = useState(0);
  const [isControlsOpen, setControlsOpen] = useState(false);
  // Why we need `isShowSticker`?
  const [isShowSticker, setShowSticker] = useState(false);
  const [gifs, setGifs] = useState<string[]>([]);
  const [activeStickers, setStickers] = useState<React.FunctionComponentElement<StickerProps>[]>([]);
  const currentAvatar = useAppSelector(selectOwnAvatar);

  const onStickerSend = (stickerId: IdType, targetId: IdType) => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_SENT_STICKER, { roomId, playerId: currentPlayerId, stickerId, targetId });
    analytics.logEvent(analytics.EVENTS.STICKER_SEND_CLICKED, { stickerId });

    // maybe timer on this sticker.
  };

  const playerId = user && user.playerId;
  const playerName = user && user.name;
  const playerStatus = playersVideoInfo[user.playerId];
  const isCurrentPlayer = user.playerId === currentPlayerId;
  const playerAvatarId = isCurrentPlayer ? currentAvatar?.id : user.avatarId;

  const muteAudioCondition = playerId ? !playerStatus?.isAudioEnabled : false;
  const muteVideoCondition = playerId ? !playerStatus?.isVideoEnabled : false;

  const isRoomOwnerIndicator = players[0].playerId === playerId; // first player is always owner
  const isRoomOwner = currentPlayerId === players[0].playerId;
  const [isBigMuteOpen, setIsBigMuteOpen] = useState(isCurrentPlayer);

  useEffect(() => {
    dispatch(setVideoState({ playerId, data: { containerId: `${playerId}-video` } }));
    return () => {
      dispatch(setVideoState({ playerId, data: { containerId: undefined } }));
    };
  }, []);

  // find need sticker from the all stickerlist
  const returnStickerData = (stickerId: IdType) => {
    const stickerSrc = stickersList.find((sticker) => sticker.id === stickerId);
    const duration = stickerSrc?.duration ? stickerSrc.duration + DURATION_BONUS : STICKER_DURATION;
    if (isDesktop) {
      return [stickerSrc?.desktop || '', duration] as const;
    } else {
      return [stickerSrc?.mobile || '', duration] as const;
    }
  };

  const controlsOpenClick = () => {
    if (isBigMuteOpen) {
      dispatch(setAudioAccess(MUTE_TYPE.OWN, true));
      setIsBigMuteOpen(false);
    } else {
      setControlsOpen(true);
    }
  };

  useEffect(() => {
    if (stickerAction) {
      //setGifs(state => [...state, stickerAction.stickerId]);
      const [gifUrl, duration] = returnStickerData(stickerAction.stickerId);
      const sticker = React.createElement(ActiveSticker, {
        src: gifUrl,
        key: stickerKey,
        className: classes.sticker,
      });
      setStickers((state) => [...state, sticker]);
      setStickerKey((state) => state + 1);
      // setTimeout(() => setGifs(state => state.slice(1)), STICKER_DURATION);
      setTimeout(() => setStickers((state) => state.slice(1)), duration);
    }
  }, [stickerAction]);

  return (
    <>
      <div id={`${playerId}_container`} className={classes.personContainer}>
        <div className={`${classes.personWrapper} ${isShowSticker ? 'isShowSticker' : ''}`} onClick={controlsOpenClick}>
          {/* video root-container and mute-video overlay */}
          <PersonVideo playerId={playerId} avatarId={playerAvatarId || ''} muteVideoCondition={muteVideoCondition}>
            <div className={`${classes.stickerContainer} animate__animated animate__fadeIn`} ref={stickerContainerRef}>
              {activeStickers.map((sticker) => sticker)}
            </div>
          </PersonVideo>

          {/* person statuses */}
          {!isLoading && <PlayerStateLabel playerId={playerId} />}

          {/* room-owner icon */}
          {isRoomOwnerIndicator && <div className={classes.crownIcon} />}
          <img src={StickerIcon} alt="" className={classes.stickerIcon} />

          {isDesktop && (
            <div className={classes.mute}>
              {muteVideoCondition && <img src={muteVideo} />}
              {muteAudioCondition && <img src={muteAudio} />}
            </div>
          )}

          {/* && muteAudioCondition */}
          {isBigMuteOpen && (
            <div className={classes.bigMute}>
              <img src={mute} />
            </div>
          )}

          {/* person name and mute-audio indicator */}
          <PersonName
            playerName={playerName}
            muteAudioCondition={muteAudioCondition}
            muteVideoCondition={muteVideoCondition}
          />
        </div>
      </div>

      <PersonControlsModal
        user={user}
        roomId={roomId}
        isLoading={isLoading}
        isOpen={isControlsOpen}
        isRoomOwner={isRoomOwner}
        stickersList={stickersList}
        onStickerSend={onStickerSend}
        roomOwnerId={players[0].playerId}
        playersVideoInfo={playersVideoInfo}
        handleClose={() => setControlsOpen(false)}
        // muteVideoCondition={muteVideoCondition}
        // muteAudioCondition={muteAudioCondition}
      />
    </>
  );
};

export default PersonItem;

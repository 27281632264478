import { useAppSelector } from '@app/hooks';
import { useDeviceSize } from '@src/common/screenSizes';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';

interface Props {
  chosenWord?: string;
}

const ShowWordToArtists: React.FC<Props> = ({ chosenWord }) => {
  const classes = styles();
  const { isMobile } = useDeviceSize();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const returnFontSize = (chosenWord: string) => {
    if (chosenWord.length < 8) {
      return { fontSize: isMobile ? '32px' : '48px' };
    } else {
      return { fontSize: isMobile ? '25px' : '38px' };
    }
  };

  return (
    <div className={classes.showWord}>
      <div className={`${classes.showWordCard} animate__animated animate__fadeInDown`}>
        <div className={classes.showWordCardTop}>
          <span>{isEnLocale ? 'You need to draw' : 'Вам нужно нарисовать'}</span>
          <b style={returnFontSize(chosenWord!)}>{chosenWord}</b>
        </div>

        <div className={classes.showWordCardLine} />

        <div className={classes.showWordCardBottom}>
          {isEnLocale ? (
            <p>
              Guess who is
              <br /> Fake Artist
            </p>
          ) : (
            <p>
              Угадай кто
              <br /> ФЭЙК
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export { ShowWordToArtists };

import { GuessMeState, RESULTS_MATCH_TYPE } from '@shared/gameInterfaces/guessMe.socket';
import Button from '@components/Button';
import { Target, Star } from '@components/icons';
import { AddEmptyRows } from '@src/games/common/Leaderboard/AddEmptyRows';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

interface IProps {
  game: GuessMeState;
  isSpectator: boolean;
  onNext: () => void;
  playerId: string;
}

const VoteList: React.FC<IProps> = ({ game, isSpectator, onNext, playerId }) => {
  const classes = styles();
  const { ticket, voteResults } = game;
  const isButtonClicked = game.readyPlayers?.includes(playerId);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <div className={`${classes.voteList} animate__animated  animate__fadeIn`}>
      <h2>{ticket!.title}</h2>
      <div className={classes.voteListTable}>
        {voteResults!.length &&
          voteResults!.map((item, index) => {
            return (
              <div key={index} className={classes.voteTableRow}>
                <p>{item.name}</p>
                <b>{item.value || '-'}</b>
                {item.match === RESULTS_MATCH_TYPE.TARGET && <Target />}
                {item.match === RESULTS_MATCH_TYPE.MATCH && <Star />}
              </div>
            );
          })}
        <AddEmptyRows itemsArrayLength={voteResults!.length} roundResults={true} className={classes.voteTableRow} />
      </div>

      <div className={classes.buttonWrapper}>
        {!isButtonClicked && !isSpectator ? (
          <Button type="primary-blue" text={isEnLocale ? 'Next' : 'Далее'} onClick={onNext} />
        ) : (
          <span className="mobile-outline">
            {isEnLocale ? 'All players must click on Next' : 'Все игроки должны нажать Далее'}
          </span>
        )}
      </div>
    </div>
  );
};

export { VoteList };

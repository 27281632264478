import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  startWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // minHeight: '320px',
    minHeight: '100%',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
  },

  startTitle: {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '28px',
    color: 'white',
    textAlign: 'center',
    marginTop: 0,
    marginBottom: '18px',
    textStroke: '1px #1B2036',
    fontFamily: 'SF Pro Rounded',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '23px',
    },
    [theme.breakpoints.up('md')]: {
      lineHeight: '33px',
      marginBottom: '16px',
      color: '#1B2036',
      textStroke: 'initial',
    },
  },

  spinWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'inherit',
    width: '275px',
    height: '275px',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      height: '450px',
    },
    [theme.breakpoints.up('md')]: {
      width: '375px',
      height: '375px',
    },
  },

  raptorImg: {
    position: 'relative',
    width: '275px',
    height: '275px',
    animationDuration: '1.45s',
    animationIterationCount: '1',
    animationDelay: '100ms',
    animationFillMode: 'both',
    animationTimingFunction: 'cubic-bezier(0.81, 0.1, 0.31, 0.9)',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      height: '450px',
    },
    [theme.breakpoints.up('md')]: {
      width: '375px',
      height: '375px',
    },
  },

  spinButton: {
    zIndex: 4,
    position: 'absolute',
    top: '55%',
    left: '50%',
    width: '104px',
    height: '40px',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    opacity: 1,
    transitionProperty: 'filter, opacity',
    transitionDuration: '.23s, .46s',
    '&.clicked': {
      opacity: 0,
      pointerEvents: 'none',
      '&:hover': {
        cursor: 'default',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: '170px',
      height: '65px',
      // top: '33%',
    },
    [theme.breakpoints.up('md')]: {
      width: '142px',
      height: '55px',
      // top: '34%',
    },
  },
}));

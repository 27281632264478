import React from 'react';
import { useAppSelector } from '@app/hooks';
import { selectEnergy } from '@store/reducers/player.reducer';
import energyIcon from '@art/icons/StatusBar/energy.svg';
import { styles } from './styles';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { useResetTimer } from '@src/common/misc';

const EnergyHeader: React.FC = () => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const energy = useAppSelector(selectEnergy);
  const claimDate = useResetTimer(energy.end);
  const maxEnergy = 5;

  return (
    <header className={classes.energyHeader}>
      <img className={'energy__icon'} src={energyIcon} alt="energy" />

      <div className={'energy__info'} style={{ marginLeft: '5px' }}>
        <p className={'energy__info-title'}>{isEnLocale ? 'Energy' : 'Энергия'}</p>
        <p className={'energy__info-value'}>{energy.amount}</p>
      </div>

      <div className={'energy__info'}>
        <p className={'energy__info-title'}>{isEnLocale ? 'Next Energy' : 'Следующая'}</p>
        <p className={'energy__info-timer'}>{claimDate === '0m' ? (isEnLocale ? 'Full' : 'Все') : claimDate}</p>
      </div>

      <div className={'energy__info'}>
        <p className={'energy__info-title'}>{isEnLocale ? 'Max Energy' : 'Максимум'}</p>
        <p className={'energy__info-value'} style={{ color: 'black' }}>
          {maxEnergy}
        </p>
      </div>
    </header>
  );
};

export { EnergyHeader };

import React, { useEffect, useState } from 'react';
import { Link, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { ReactComponent as StarIcon } from '@art/icons/star.svg';
import Button from '@components/Button';
import { useHistory, useParams } from 'react-router-dom';
import { getHomepageRoute } from '@common/misc';
import { ratePartyMutation } from '@graphql/debugQueries';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { analytics } from '@services/amplitude';
import { TwitterLogo, YoutubeLogo, DiscordLogo, VkontakteLogo } from './Images';
import GameWrapper from '@src/components/GameWrapper';
import { rateParty, selectPlayerId } from '@src/store/reducers/player.reducer';
import { mutateGraphql } from '@src/library/fetch';

enum SOCIAL_NETWORK {
  VK = 'VK',
  YOUTUBE = 'YOUTUBE',
  DISCORD = 'DISCORD',
  TWITTER = 'TWITTER',
}

const linksArray = [
  [SOCIAL_NETWORK.VK, 'https://vk.com/topic-208696216_48165293', VkontakteLogo],
  [SOCIAL_NETWORK.TWITTER, 'https://twitter.com/Hello_Ent_RU', TwitterLogo],
  [SOCIAL_NETWORK.DISCORD, 'https://discord.gg/yQuRq9duvM', DiscordLogo],
  [SOCIAL_NETWORK.YOUTUBE, 'https://www.youtube.com/channel/UC5ZnIOSK9DQYzsowq-dHqlQ', YoutubeLogo],
] as const;

const RateScreen: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { partyId } = useParams<{ partyId: string }>();
  const playerId = useAppSelector(selectPlayerId);
  const [value, setValue] = useState(0); // 0 = undefined
  const [disabled, setDisabled] = useState(true); // 0 = undefined
  const [hoverValue, setHoverValue] = useState(0); // 0 = undefined
  const baseRoute = getHomepageRoute();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const starClassname = (index: number) => (Math.max(value, hoverValue) > index ? classes.starActive : classes.star);
  const handleClick = (value: number) => () => setValue(value);
  const handleHover = (hoverValue: number) => () => setHoverValue(hoverValue);

  const renderStars = (number: number = 5) => {
    return Array.from({ length: number }, (item, index) => (
      <StarIcon
        key={index}
        className={starClassname(index)}
        onClick={handleClick(index + 1)}
        onMouseOver={handleHover(index + 1)}
      />
    ));
  };

  const renderLinks = () => {
    const sendAnalytics = (network: SOCIAL_NETWORK) =>
      analytics.logEvent(analytics.EVENTS.SOCIAL_LINK_CLICKED, { link: network });

    return linksArray.map((link, index) => (
      <li key={index} onClick={() => sendAnalytics(link[0])}>
        <Link href={link[1]} target="_blank">
          <img className={classes.shareItem} src={link[2]} />
        </Link>
      </li>
    ));
  };

  const onNextClick = () => {
    history.push({ pathname: `${baseRoute}/nextParty/${partyId}` });
  };

  const onRateClick = () => {
    if (value > 0) {
      mutateGraphql(ratePartyMutation, { partyId, playerId, rate: value });
      dispatch(rateParty);
      analytics.logEvent(analytics.EVENTS.PARTY_RATED, { partyId, playerId, value });
    }
    onNextClick();
  };

  useEffect(() => {
    setDisabled(value === 0);
  }, [value]);

  return (
    <GameWrapper>
      <div className={classes.container}>
        <div className={classes.containerTop}>
          <Typography className={classes.text}>
            {isEnLocale ? 'How did you like this party?' : 'Как Вы оцените эту игру?'}
          </Typography>

          <div onMouseOut={() => setHoverValue(0)}>{renderStars()}</div>

          <Button
            className={classes.button}
            type="primary-blue-new"
            text={isEnLocale ? 'Rate' : 'Оценить'}
            onClick={onRateClick}
            disabled={disabled}
          />

          <p className={classes.next} onClick={onNextClick}>
            {isEnLocale ? 'Skip' : 'Пропустить'}
          </p>
        </div>

        <div className={classes.networks}>
          <div>{isEnLocale ? 'Share your impressions with us' : 'Поделись с нами впечатлениями'}</div>
          <ul className={classes.shareList}>{renderLinks()}</ul>
        </div>
      </div>
    </GameWrapper>
  );
};

export { RateScreen };

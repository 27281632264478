import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  wordChooseContainer: {
    justifyContent: 'center',
  },

  wordChooseTitle: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    color: 'white',
    textAlign: 'center',
    marginTop: 0,
    marginBottom: '10px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '33px',
      marginBottom: '20px',
    },
    [theme.breakpoints.up('md')]: {
      color: '#1B2036',
      marginBottom: '18px',
    },
  },

  wordChooseItem: {
    flexDirection: 'column',
    minHeight: '220px',
    flexBasis: '165px',
    flexShrink: 0,
    alignItems: 'center',
    margin: '0 5px',
    backgroundColor: 'white',
    borderRadius: '10px',
    paddingTop: '5px',
    paddingRight: '5px',
    paddingLeft: '5px',
    backgroundImage: 'linear-gradient(180deg, #FFD957 0, #CAC6FA 80%, #CAC6FA 100%)',
    transition: 'all .1s',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '325px',
      flexBasis: '243px',
      margin: '0 15px',
      borderRadius: '20px',
      paddingTop: '10px',
      paddingRight: '10px',
      paddingLeft: '10px',
    },
  },

  wordChooseTop: {
    alignItems: 'center',
    width: '100%',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '7px 7px 0 0',
    paddingLeft: '5px',
    paddingRight: '5px',
    minHeight: '173px',
    [theme.breakpoints.up('sm')]: {
      borderRadius: '10px 10px 0 0',
      minHeight: '242px',
    },

    '& > b': {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Nunito',
      flexGrow: 1,
      fontWeight: 900,
      color: '#ABA8DC',
      textTransform: 'uppercase',
      marginTop: '15px',
      fontSize: '24px',
      lineHeight: '33px',
      textAlign: 'center',
      wordBreak: 'break-word',
      maxWidth: '190px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '300px',
        marginTop: '35px',
        fontSize: '32px',
        lineHeight: '44px',
      },
    },

    '& > button': {
      width: '75px',
      height: '28px',
      marginBottom: '15px',
      [theme.breakpoints.up('sm')]: {
        width: '95px',
        height: '32px',
        '& > span': {
          fontSize: '14px',
          lineHeight: '17px',
        },
      },
      '& > span': {
        fontSize: '12px',
        lineHeight: '14px',
      },
    },
  },

  wordChooseBottom: {
    backgroundColor: '#CAC6FA',
    borderRadius: '0 0 7px 7px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '5px',
      paddingBottom: '5px',
      borderRadius: '0 0 10px 10px',
    },
    '& > b': {
      fontFamily: 'Nunito',
      maxWidth: '190px',
      wordBreak: 'break-word',
      fontWeight: 800,
      color: '#161F43',
      paddingRight: '2px',
      paddingLeft: '2px',
      fontSize: '28px',
      lineHeight: '42px',
      flexBasis: '42px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '300px',
        fontSize: '42px',
        lineHeight: '63px',
        paddingLeft: '5px',
        paddingRight: '10px',
      },
    },
    '& > div': {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      marginTop: '5px',
      borderRadius: '0 0 7px 0',
      marginBottom: '5px',
      paddingLeft: '2px',
      paddingRight: '2px',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '10px',
        paddingRight: '5px',
        borderRadius: '0 0 10px 0',
      },
      '& > p': {
        margin: 0,
        color: '#1B2036',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '14px',
        letterSpacing: '-0.8px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '16px',
          lineHeight: '19px',
        },
      },
    },
  },

  activeChoosePencilImage: {
    height: '62px',
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      height: '85px',
    },
  },
}));

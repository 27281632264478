import { useAppSelector } from '@app/hooks';
import Pencil from '@art/images/draw-and-guess/pencil.svg';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

interface Props {
  activePlayer: any;
}

const Passive: React.FC<Props> = ({ activePlayer }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <div className={classes.passiveWatch}>
      <div className={classes.passiveWatchTop}>
        <img src={Pencil} className={classes.passiveWatchPencilImage} alt="pencil.svg" />
        <p>
          {isEnLocale ? 'Wait until ' : 'Подожди, пока '}
          <span>{activePlayer?.name}</span>
          {isEnLocale ? ' is choosing the word' : ' выбирает слово'}
        </p>
      </div>
    </div>
  );
};

export { Passive };

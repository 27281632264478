import { programs } from '@metaplex/js';
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Connection, Keypair, PublicKey, SendOptions, Signer, SystemProgram, Transaction } from '@solana/web3.js';
import { Wallet } from '@project-serum/anchor';

export const sendTransaction = async (
  connection: Connection,
  transaction: Transaction,
  signers: Signer[],
  options?: SendOptions
) => {
  const sendOptions: SendOptions = { skipPreflight: true, preflightCommitment: 'singleGossip' };
  const txhash = await connection.sendTransaction(transaction, signers, options || sendOptions);
  console.log(`txhash: ${txhash}`);
  return txhash;
};

export const requestAirDrop = async (connection: Connection, publicKey: PublicKey, lamports: number) => {
  const txhash = await connection.requestAirdrop(publicKey, lamports);
  console.log(`txhash: ${txhash}`);
};

export const getToken = async (connection: Connection, tokenKey: PublicKey, key: Keypair) => {
  const token = new Token(connection, tokenKey, TOKEN_PROGRAM_ID, key);
  return token;
};

export const getWallet = (key: Keypair) => new Wallet(key);

export const transfer = async (connection: Connection, target: PublicKey, from: Keypair, lamports: number) => {
  const tx = new Transaction();
  tx.add(
    SystemProgram.transfer({
      fromPubkey: from.publicKey,
      toPubkey: target,
      lamports,
    })
  );
  tx.feePayer = from.publicKey;

  const txhash = await connection.sendTransaction(tx, [from]);
  console.log(`txhash: ${txhash}`);
};

export const getMetadata = async (connection: Connection, token: PublicKey) => {
  const tokenMetaKey = await programs.metadata.Metadata.getPDA(token);

  const metadata = await programs.metadata.Metadata.load(connection, tokenMetaKey);
};

export const getTokenAccountAddress = async (token: PublicKey, address: PublicKey) =>
  Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, token, address);

export const getTokenBalance = async (connection: Connection, token: string, address: PublicKey) => {
  const tokenAddress = new PublicKey(token);
  try {
    const tokenAccount = await getTokenAccountAddress(tokenAddress, address);
    const balance = await connection.getTokenAccountBalance(tokenAccount);
    return balance.value.uiAmount || 0;
  } catch {
    return 0;
  }
};

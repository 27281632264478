import { useAppSelector } from '@src/app/hooks';
import { ITimer } from '@src/shared/common';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { showBackgroundColor } from './showColors';
import { useStyles } from './styles';
import { useTimer } from './timer.hook';

interface Props {
  timer: ITimer;
  isVote: boolean;
}

const LineTimer: React.FC<Props> = ({ timer, isVote }) => {
  const classes = useStyles(timer);
  const { countDown, duration, timeLeftInPercents } = useTimer(timer);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <div className={classes.lineTimer}>
      {/* alert */}
      <div
        className={`
        ${classes.timerAlert}
        lineTimerAlertPosition
        ${countDown === 0 ? 'showAlert' : ''}
      `}
      >
        {isEnLocale ? 'Time is up!' : 'Время вышло!'}
      </div>

      <div
        className={`
        gameTimerInner
        ${classes.lineTimerInner}
        ${countDown ? 'showTimer' : ''}
        ${countDown === 0 ? 'hideTimer' : ''}
      `}
      >
        {/* output label */}
        <div
          className={classes.lineTimerOutput}
          style={{
            left: `${timeLeftInPercents}%`,
            backgroundColor: showBackgroundColor(duration, countDown),
          }}
        >
          <span>{countDown}</span>
        </div>

        {/* range track */}
        <div
          className={classes.lineTimerRange}
          style={{
            width: `${timeLeftInPercents}%`,
            backgroundColor: showBackgroundColor(duration, countDown),
          }}
        />
      </div>
    </div>
  );
};

export { LineTimer };

import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';
import BackgrounMobile from '@art/images/one-question/mobile-bg.jpg';
import warningImage from '@art/images/one-question/warning.svg';
import closeImage from '@art/images/one-question/close.svg';
import loader from '@art/images/one-question/loader.webp';
import PersonVideoOff from '@art/images/person-video-off.png';
import PersonAudioOff from '@art/images/one-question/audio-mute.svg';

export const styles = makeStyles((theme) => ({
  video: {
    position: 'relative',
    height: '170px',
    width: '130px',
    borderRadius: '10px',
    backgroundColor: 'white',
    padding: '5px',
    [theme.breakpoints.up('sm')]: {
      padding: '10px',
      height: '340px',
      width: '260px',
      borderRadius: '20px',
    },
  },

  videoWrapper: {
    position: 'relative',
    zIndex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    flexGrow: 1,
    textAlign: 'center',
    backgroundColor: '#F3F4F5',
    borderRadius: '10px',
    overflow: 'hidden',
    '&.isReloading': {
      '&::before': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        content: '""',
        position: 'absolute',
        zIndex: 2,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        backgroundPosition: 'center',
        backgroundImage: `url(${loader})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100px auto',
      },
    },

    '&.isMuteVideo': {
      '&::before': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        content: '""',
        position: 'absolute',
        zIndex: 3,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        backgroundPosition: 'center',
        backgroundImage: `url(${PersonVideoOff})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '80px auto',
      },
    },

    '&.isMuteAudio': {
      '&::after': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        content: '""',
        position: 'absolute',
        zIndex: 2,
        width: '80px',
        height: '80px',
        left: '50%',
        top: '50%',
        marginTop: '-40px',
        marginLeft: '-40px',
        backgroundPosition: 'center',
        backgroundSize: '30px auto',
        backgroundImage: `url(${PersonAudioOff})`,
        backgroundRepeat: 'no-repeat',
      },
    },
  },

  videoComponent: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    objectFit: 'cover',
  },
}));

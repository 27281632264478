import WSClient from '@src/sockets';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import {
  MUTE_TYPE,
  selectHasMultipleCameras,
  selectIsVideoEnabled,
  setAudioAccess,
  setVideoAccess,
} from '@src/store/reducers/playerData.reducer';
import { selectBalance, selectPlayerId, setBalance } from '@src/store/reducers/player.reducer';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { CLIENT_EVENTS } from '@shared/socketEvents/eventTypes';
import { IdType, SimpleFunction } from '@shared/generics';
import { CommonModal, RenameModal, BuyCoinModal, SettingsModal } from '@components/Modals';
import Button from '@src/components/Button';
import {
  muteAudioOffIcon,
  muteAudioOnIcon,
  muteVideoOffIcon,
  muteVideoOnIcon,
  kickOutIcon,
  settings,
} from './importIcons';
import helloCoin from '@art/icons/coin_flat.svg';
import { styles } from './styles';

interface Props {
  isOpen: boolean | undefined;
  isRoomOwner: boolean;
  isLoading: boolean;
  roomId: IdType;
  roomOwnerId: IdType;
  user: {
    playerId: string;
    name: string;
  };
  stickersList: any[];
  playersVideoInfo: any;
  handleClose: SimpleFunction;
  onStickerSend: (selectedStickerId: IdType, targetId: IdType) => void;
}

const PersonControlsModal: React.FC<Props> = ({
  user,
  roomId,
  roomOwnerId,
  isOpen,
  isLoading,
  isRoomOwner,
  stickersList,
  playersVideoInfo,
  handleClose,
  onStickerSend,
}) => {
  const classes = styles();
  const dispatch = useAppDispatch();

  const [state, setState] = useState({
    playerId: '',
    isAudioEnabled: true,
    isVideoEnabled: false,
    selectedSticker: {
      id: null,
      price: 0,
    },
  });

  const currentPlayerId = useAppSelector(selectPlayerId);
  const balance = useAppSelector(selectBalance);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const hasMultipleCameras = useAppSelector(selectHasMultipleCameras);
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  const [isBuyCoinModalOpen, setBuyCoinModalOpen] = useState(false);
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
  const price = state.selectedSticker.price;

  const onSelect = (sticker: any) => {
    setState((prevState) => {
      return { ...prevState, selectedSticker: sticker };
    });
  };

  const onSend = () => {
    if (balance >= price) {
      dispatch(setBalance(balance - price));
      onStickerSend(state.selectedSticker.id || '', state.playerId);
    } else {
      setBuyCoinModalOpen(true);
    }
    handleClose();
  };

  const playerStatus = playersVideoInfo[user.playerId];

  if (!isLoading) {
    useEffect(() => {
      setState((prevState) => {
        return {
          ...prevState,
          playerId: user?.playerId,
          isAudioEnabled: playerStatus?.isAudioEnabled,
          isVideoEnabled: playerStatus?.isVideoEnabled,
        };
      });
    }, [playersVideoInfo]);
  }

  const onMuteAudioClick = () => {
    dispatch(setAudioAccess(MUTE_TYPE.OWN, !playerStatus?.isAudioEnabled));
  };

  const onMuteVideoClick = () => {
    dispatch(setVideoAccess(MUTE_TYPE.OWN, !playerStatus?.isVideoEnabled));
    if (!state.isVideoEnabled && hasMultipleCameras) {
      onSettingsClick();
    }
  };

  const onKickOutClick = (targetId: IdType) => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_KICKED_FROM_ROOM, { roomId, playerId: roomOwnerId, targetId });
    handleClose();
  };

  const onSettingsClick = () => {
    handleClose();
    setSettingsModalOpen(true);
  };

  if (!isLoading) {
    const { isAudioEnabled, isVideoEnabled, selectedSticker } = state;

    return (
      <>
        <CommonModal isOpen={isOpen} handleClose={handleClose} backgroundColor={'white'}>
          <div className={classes.wrapper}>
            <div className={classes.top}>
              <b>{user.name}</b>

              {currentPlayerId === user.playerId && (
                <ul className={classes.actionsMenu}>
                  <li className={classes.option} onClick={onMuteAudioClick}>
                    <img src={isAudioEnabled ? muteAudioOffIcon : muteAudioOnIcon} alt="Mute" />
                    <span>{isAudioEnabled ? 'Mute' : 'Unmute'}</span>
                  </li>

                  <li className={classes.option} onClick={onMuteVideoClick}>
                    <img src={isVideoEnabled ? muteVideoOffIcon : muteVideoOnIcon} alt="Stop video" />
                    <span>{isVideoEnabled ? 'Stop video' : 'Start video'}</span>
                  </li>

                  <li className={classes.option} onClick={onSettingsClick}>
                    <img src={settings} alt="settings" />
                    <span>{'Settings'}</span>
                  </li>
                </ul>
              )}

              {isRoomOwner && roomOwnerId !== user.playerId && (
                <div className={classes.option} onClick={() => onKickOutClick(user.playerId)}>
                  <img src={kickOutIcon} alt="Kick out" />
                  <span>Kick out</span>
                </div>
              )}
            </div>

            <div className={classes.bottom}>
              <ul className={classes.stickerSelect}>
                {stickersList.length
                  ? stickersList.map((sticker, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => onSelect(sticker)}
                          style={{ backgroundImage: `url(${sticker.preview})` }}
                          className={`${classes.stickerItem} ${selectedSticker.id === sticker.id ? 'active' : ''}`}
                        >
                          {sticker.price === 0 ? (
                            <div className="free">free</div>
                          ) : (
                            <div>
                              {sticker.price}
                              <img src={helloCoin} alt="coin" />
                            </div>
                          )}
                        </li>
                      );
                    })
                  : isEnLocale
                  ? 'No stickers'
                  : 'Стикеров нет'}
              </ul>

              <div className={classes.btnContainer}>
                <Button
                  onClick={onSend}
                  disabled={!selectedSticker.id}
                  text={isEnLocale ? 'Send' : 'Отправить'}
                  type={'primary-blue-new'}
                  className="send"
                >
                  {price > 0 && (
                    <div className="coin">
                      {price}
                      <img src={helloCoin} alt="coin" />
                    </div>
                  )}
                </Button>
              </div>
            </div>
          </div>

          <RenameModal
            isOpen={isRenameModalOpen}
            handleClose={() => setRenameModalOpen(false)}
            isGraphqlRename={false}
          />
        </CommonModal>

        <BuyCoinModal isOpen={isBuyCoinModalOpen} handleClose={() => setBuyCoinModalOpen(false)} />
        <SettingsModal isOpen={isSettingsModalOpen} handleClose={() => setSettingsModalOpen(false)} />
      </>
    );
  } else return <></>;
};

export default PersonControlsModal;

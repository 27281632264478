import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { createSimpleDraftReducer, createSimpleReducer, simpleMergeThunk } from '@store/reduxHelpers';

export enum MENU_PAGE {
  MAIN,
  PROFILE,
  CHAT,
  COIN,
  ENERGY,
}

export enum SNACKBAR_TYPE {
  UNDEFINED = 'UNDEFINED',
  WITHDRAW = 'WITHDRAW',
  SEND = 'SEND',
  RECEIVE = 'RECEIVE',
}

export enum SNACKBAR_SUCCESS {
  UNDEFINED = 'UNDEFINED',
  SUCCESS = 'SUCCESS',
  UNSUCCESS = 'UNSUCCESS',
}

const initialState = {
  inviteOnce: false,
  invite: false,
  roomFull: false,
  inviteLink: '',
  page: MENU_PAGE.MAIN,
  shouldShowLanguagePopup: false,
  operationSuccess: SNACKBAR_SUCCESS.UNDEFINED,
  isOperationUnsuccessful: false,
  notificationType: SNACKBAR_TYPE.UNDEFINED,
};

type StateType = typeof initialState;
type PayloadType = Partial<StateType>;

export const popupsSlice = createSlice({
  name: 'popups',
  initialState: initialState,
  reducers: {
    setInviteShownOnce: createSimpleDraftReducer('inviteOnce'),
    setInviteShown: createSimpleReducer('invite'),
    setRoomFullShown: createSimpleReducer('roomFull'),
    setInviteLink: createSimpleReducer('inviteLink'),
    setPage: createSimpleReducer('page'),
    setShouldShowLanguagePopup: createSimpleReducer('shouldShowLanguagePopup'),
    setOperationSuccess: createSimpleReducer('operationSuccess'),
    setNotificationType: createSimpleReducer('notificationType'),
  },
});

export const {
  setInviteShownOnce,
  setInviteShown,
  setRoomFullShown,
  setInviteLink,
  setPage,
  setShouldShowLanguagePopup,
  setOperationSuccess,
  setNotificationType,
} = popupsSlice.actions;

const selectState = (state: RootState) => state.popups || initialState;

export const selectIsInviteShownOnce = createSelector(selectState, (state) => state.inviteOnce);
export const selectIsInviteShown = createSelector(selectState, (state) => state.invite);
export const selectIsRoomFullShown = createSelector(selectState, (state) => state.roomFull);
export const selectInviteLink = createSelector(selectState, (state) => state.inviteLink);
export const selectPage = createSelector(selectState, (state) => state.page);
export const selectIsProfilePage = createSelector(selectState, (state) => state.page === MENU_PAGE.PROFILE);
export const selectIsCoinPage = createSelector(selectState, (state) => state.page === MENU_PAGE.COIN);
export const selectIsEnergyPage = createSelector(selectState, (state) => state.page === MENU_PAGE.ENERGY);
export const selectShouldShowLanguagePopup = createSelector(selectState, (state) => state.shouldShowLanguagePopup);
export const selectOperationSuccess = createSelector(selectState, (state) => state.operationSuccess);
export const selectNotificationType = createSelector(selectState, (state) => state.notificationType);

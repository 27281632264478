import { Dispatch, SetStateAction } from 'react';
import { useAppSelector } from '@app/hooks';
import { IRoomPlayer, selectRoomPlayers } from '@store/room/roomSlice';
import CommonModal from '@components/Modals/CommonModal';
import { styles } from './styles';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import Button from '@src/components/Button';
import WSClient from '@src/sockets';
import { CLIENT_EVENTS } from '@src/shared/socketEvents/eventTypes';
import { selectPlayerId } from '@src/store/reducers/player.reducer';

interface Props {
  isOpen: boolean;
  activePlayer: IRoomPlayer;
  onGiveUp: () => void;
  setModalOpen: (isOpen: boolean) => void;
}

const GiveUpModal: React.FC<Props> = ({ isOpen, onGiveUp, setModalOpen }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const playerId = useAppSelector(selectPlayerId);

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={() => setModalOpen(false)}
      className={classes.chooseModal}
      padding={false}
    >
      <h2>{isEnLocale ? 'Are you sure?' : 'Ты уверен?'}</h2>
      <div className={classes.btnContainer}>
        <Button onClick={() => onGiveUp()} text={isEnLocale ? 'Give up' : 'Сдаться'} type={'primary-red-new'} />
        <Button
          onClick={() => setModalOpen(false)}
          text={isEnLocale ? 'Cancel' : 'Закрыть'}
          type={'primary-blue-new'}
        />
      </div>
    </CommonModal>
  );
};

export { GiveUpModal };

import React, { useEffect, useRef } from 'react';
import { useCanvasDrawContext } from './CanvasDrawContext';

import { styles } from './styles';

export type CanvasDrawGridProps = {
  gridColor?: string;
  gridSize?: number;
  hideGrid?: boolean;
};

const drawGrid = (ctx: CanvasRenderingContext2D, gridColor: string, gridSize: number, hideGrid: boolean) => {
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  if (hideGrid) {
    return;
  }
  ctx.strokeStyle = gridColor;
  ctx.lineWidth = 0.5;
  // ctx.setLineDash([5, 1]);

  // draw rows
  for (let x = gridSize; x < ctx.canvas.width; x += gridSize) {
    ctx.moveTo(x, 0);
    ctx.lineTo(x, ctx.canvas.height);
  }

  for (let y = gridSize; y < ctx.canvas.height; y += gridSize) {
    ctx.moveTo(0, y);
    ctx.lineTo(ctx.canvas.width, y);
  }

  ctx.stroke();
};

const CanvasDrawGrid: React.FC<CanvasDrawGridProps> = ({ gridColor = '#000', gridSize = 25, hideGrid = false }) => {
  const classes = styles();

  // canvas ref
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const context = useCanvasDrawContext();

  const drawGridHandler = () => {
    //mounted, let's draw
    const ctx = canvasRef.current?.getContext('2d');
    if (ctx) {
      drawGrid(ctx, gridColor, gridSize, hideGrid);
    }
  };

  useEffect(() => {
    drawGridHandler();
  }, [gridColor, gridSize, hideGrid]);

  useEffect(() => {
    if (canvasRef.current) {
      context.subscribeToResize(canvasRef.current, () => drawGridHandler());
      return () => context.unsubscribeToResize(canvasRef.current!);
    }
  }, [context, canvasRef.current]);

  return <canvas ref={canvasRef} className={classes.canvas} />;
};

export default CanvasDrawGrid;

import { gql } from '@apollo/client';
import { IdType } from '@shared/generics';
import { BANNER_CONDITION, BANNER_TYPE } from '@shared/homepage/configSet';

export interface BannerInfo {
  id: IdType;
  type: BANNER_TYPE;
  condition?: BANNER_CONDITION;
}

export const bannerInfoFragment = gql`
  fragment BannerInfoFragment on BannerInfo {
    id
    type
    condition
  }
`;

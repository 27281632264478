import { makeStyles } from '@material-ui/core';
import WinnerImg from '@art/images/winner-illustration-with-stars.svg';

export const styles = makeStyles((theme) => ({
  rightAnswerWrapper: {
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',
    minHeight: '100%',
    [theme.breakpoints.up('md')]: {
      paddingTop: '30px',
    },
  },

  rightAnswer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '15px',
    width: '345px',
    minHeight: '313px',
    '&.winner': {
      paddingTop: '120px',
      backgroundImage: `url(${WinnerImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center 15px',
      backgroundSize: '280px auto',
      [theme.breakpoints.up('sm')]: {
        paddingTop: '135px',
        backgroundPosition: 'center 30px',
        backgroundSize: '380px auto',
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: '150px',
      },
    },
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
    [theme.breakpoints.up('sm')]: {
      minWidth: '428px',
      minHeight: '484px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '30px',
      backgroundColor: 'transparent',
    },
    '& > h2': {
      width: '90%',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '26px',
      color: '#1B2036',
      margin: 0,
      marginBottom: '6px',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
      },
    },
    '& > p': {
      fontSize: '17px',
      lineHeight: '20px',
      margin: 0,
      marginBottom: '18px',
      color: '#5F6372',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '25px',
        fontSize: '20px',
        lineHeight: '24px',
      },
    },
  },

  smile: {
    width: '60px',
    marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      width: '80px',
      marginBottom: '10px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },

  word: {
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '215px',
    maxWidth: '90%',
    minHeight: '122px',
    borderRadius: '10px',
    backgroundImage: 'linear-gradient(180deg, #FFD957 0, #CAC6FA 80%, #CAC6FA 100%)',
    padding: '5px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '328px',
      minHeight: '210px',
      padding: '10px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '364px',
      minHeight: '210px',
    },
    '& > div': {
      backgroundColor: 'white',
      paddingLeft: '5px',
      paddingRight: '5px',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      flexGrow: 1,
      alignSelf: 'stretch',
      borderRadius: '7px',
      minHeight: '112px',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '10px',
        paddingRight: '10px',
        minHeight: '190px',
      },
      '& > b': {
        fontFamily: 'Nunito',
        fontSize: '32px',
        lineHeight: '44px',
        fontWeight: 900,
        color: '#ABA8DC',
        wordBreak: 'break-word',
        textTransform: 'uppercase',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
          fontSize: '48px',
          lineHeight: '48px',
        },
      },
    },
  },
}));

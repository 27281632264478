import { FC } from 'react';
import { IGameState } from '../../matchMe.types';
import Button from '@components/Button';
import AvatarImg from '@art/images/person-avatar.png';
import { styles } from './styles';
// import CountUp from 'react-countup';

interface IProps {
  game: IGameState;
  onGameQuit: React.MouseEventHandler;
}

const Results: FC<IProps> = ({ game, onGameQuit }) => {
  const classes = styles();
  const { results, creatorAvatar } = game;
  const value = results?.value ? (results.value * 100).toFixed() : 'undefined';
  const valueColor = results?.value && results.value > 0.5 ? '#069627' : '#064ECE';

  return (
    <div className={classes.resultsWrapper}>
      <div className={classes.resultsInner}>
        {<img src={creatorAvatar || AvatarImg} alt="creatorAvatar" />}
        <p>
          Your match with <b>{results?.creatorName}</b> is
        </p>
        <div className={classes.resultsCount} style={{ color: valueColor }}>
          {/* <CountUp end={Number(value)} suffix="%" /> */}
        </div>
      </div>

      <div className={classes.buttonWrapper}>
        <Button text="Quit game" type="primary-blue" onClick={onGameQuit} />
      </div>
    </div>
  );
};

export { Results };

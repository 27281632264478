import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@src/app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';

import { Bombs } from '..';
import { PotPart1Mobile, PotPart2Mobile, PotCoinMobile, PotGoldMobile } from '../../../../helpers/chests.images';
import { styles } from './styles';

interface CoinProps {
  src: string;
  className: string;
}

type Props = {
  hasEnoughTokens: boolean;
  areAllClicked: boolean;
  isSkipClicked: boolean;
  hasPlayerClicked: boolean;
  hasPlayerClickedVerified: boolean;
  playersStaked: string[];
  stakeAmount: number;
  onStakeClick: (hasStaked: boolean) => void;
};

const StakeTokens: React.FC<Props> = ({
  hasEnoughTokens,
  areAllClicked,
  isSkipClicked,
  hasPlayerClicked,
  hasPlayerClickedVerified,
  playersStaked,
  stakeAmount,
  onStakeClick,
}) => {
  const classes = styles();
  const [coinKey, setCoinKey] = useState(0);
  const numberOfStaked = playersStaked.length;
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const [activeCoins, setCoins] = useState<React.DetailedReactHTMLElement<CoinProps, HTMLElement>[]>([]);

  // create image with coin when someone add coin
  useEffect(() => {
    if (numberOfStaked === 0) return;

    const coinImage = React.createElement('img', {
      src: PotCoinMobile,
      key: coinKey,
      className: classes.coin,
    });

    setCoins((state) => [...state, coinImage]);
    setCoinKey((state) => state + 1);
  }, [numberOfStaked]);

  return (
    <div className={classes.imageWrapper}>
      {/* pot-1 */}
      <img className={classes.potPart1} src={PotPart1Mobile} alt="PotPart1Mobile" />

      {/* coins */}
      {activeCoins.map((coin) => coin)}

      {/* gold */}
      {areAllClicked && !isSkipClicked && (
        <img
          style={{ animationDelay: '100ms' }}
          className={`${classes.gold} animate__animated animate__fadeIn`}
          src={PotGoldMobile}
          alt="Golg"
        />
      )}

      {/* pot-2 */}
      <img className={classes.potPart2} src={PotPart2Mobile} alt="PotPart2Mobile" />

      {/* add-tokens button */}
      {(hasEnoughTokens || hasPlayerClicked) && (
        <div
          className={`
            ${classes.addTokensButton}
            ${hasPlayerClicked ? 'animate__animated animate__fadeOut' : ''}
          `}
          style={{
            pointerEvents: hasPlayerClicked ? 'none' : 'auto',
            fontFamily: isEnLocale ? 'Barbieri-Regular' : 'Impact',
          }}
          onClick={() => onStakeClick(true)}
        >
          <span>{isEnLocale ? `Add ${stakeAmount} Tokens` : `Бросить ${stakeAmount} Токенов`}</span>
        </div>
      )}

      {/* not-enough */}
      {!hasPlayerClicked && !hasEnoughTokens && (
        <div className={classes.notEnoughtButton} style={{ fontFamily: isEnLocale ? 'Barbieri-Regular' : 'Impact' }}>
          <span>{isEnLocale ? 'Not Enough Tokens' : 'Не Хватает Токенов'}</span>
        </div>
      )}

      {/* skip button */}
      <div
        className={`${classes.skipButton} ${hasPlayerClicked ? 'animate__animated animate__fadeOut' : ''}`}
        onClick={() => onStakeClick(false)}
        style={{
          pointerEvents: hasPlayerClicked ? 'none' : 'auto',
          fontFamily: isEnLocale ? 'Barbieri-Regular' : 'Impact',
        }}
      >
        <span>{isEnLocale ? 'Skip\u00A0\u00A0\u00A0round' : 'Пропустить\u00A0\u00A0\u00A0раунд'}</span>
      </div>

      {/* all-players text */}
      {hasPlayerClickedVerified && !areAllClicked && (
        <div className={classes.allPlayers} style={{ fontFamily: isEnLocale ? 'Barbieri-Regular' : 'Impact' }}>
          <span>
            {isEnLocale
              ? 'All players must add tokens to\u00A0\u00A0\u00A0start round'
              : 'Все игроки должны добавить токенов, чтобы начать раунд'}
          </span>
        </div>
      )}

      {/* bombs */}
      {areAllClicked && !isSkipClicked && <Bombs />}
    </div>
  );
};

export { StakeTokens };

import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  withdrawalContainer: {
    padding: '19px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    '& img': {
      width: '22px',
      height: '20px',
      marginRight: '7px',
    },
    '& p': {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '18px',
      textAlign: 'center',
      color: '#5568AE',
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
  },
}));

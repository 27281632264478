import { useAppSelector } from '@app/hooks';
import Pencil from '@art/images/draw-and-guess/pencil.svg';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

interface Props {
  chosenWord: {
    word: string;
    value: number;
  };
  activePlayer: any;
}

const Passive: React.FC<Props> = ({ chosenWord, activePlayer }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const wordValueText = isEnLocale
    ? 'Point to you and player who guessed correctly'
    : chosenWord.value === 1
    ? 'балл тебе и игроку, который угадает'
    : 'балла тебе и игроку, который угадает';

  return (
    <div className={classes.wordShowContainer}>
      <div className={classes.wordShowItem}>
        <div className={classes.wordShowTop}>
          <img src={Pencil} className={classes.pencilImage} alt="pencil.svg" />
          <p>
            {isEnLocale ? 'Guess the word ' : 'Отгадайте слово, которое '}
            <span>{activePlayer?.name}</span>
            {isEnLocale ? ' is drawing' : ' рисует'}
          </p>
        </div>

        <div className={classes.wordShowBottom}>
          <b>{chosenWord.value}</b>
          <div>
            <p>{wordValueText}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Passive };

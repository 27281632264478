import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';

const ChestsRules: React.FC = () => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();

  return (
    <>
      {isEnLocale ? (
        <ul className={classes.list}>
          <li>All players put tokens into the pot</li>
          <li>One by one players open chests</li>
          <li>
            Chests may contain:
            <ul>
              <li>Pot with coins (that player put in it)</li>
              <li>Bomb (3 pieces on the field)</li>
              <li>Coins</li>
            </ul>
          </li>
          <li>If player finds coins in the chest he takes them all</li>
          <li>If player finds bomb in the chest he gets 0 coins</li>
        </ul>
      ) : (
        <ul className={classes.list}>
          <li>Токены всех игроков складываются в горшок</li>
          <li>Игроки по очереди выбирают один из 9 сундуков</li>
          <li>
            В сундуках может быть:
            <ul>
              <li>Горшок с монетами всех игроков</li>
              <li>Бомба (3 штуки на поле)</li>
              <li>Монеты</li>
            </ul>
          </li>
          <li>Игрок забирает себе то количество монет которое он нашел в сундуке</li>
          <li>Еcли игроку попалась бомба то в этом раунде он получает 0 монет</li>
        </ul>
      )}
    </>
  );
};

export { ChestsRules };

import { TimeAlert } from '../TimeAlert/TimeAlert';
import { ITimer } from '@src/shared/common';
import { useTimer } from '@src/games/common/Timer/timer.hook';
import { styles } from './styles';
import { FiveSecondsState } from '@src/shared/gameInterfaces/fiveSeconds.socket';

interface Props {
  timer: ITimer;
  alertThreshold?: number;
}

const TimerBig: React.FC<Props> = ({ timer, alertThreshold }) => {
  const classes = styles();
  const { countDown } = useTimer(timer);
  const timerText = countDown > 1 ? `${countDown - 1}` : 'GO';
  const shouldTimerShow = !alertThreshold || countDown < alertThreshold;

  return <>{shouldTimerShow && <span className={classes.timerBig}>{timerText}</span>}</>;
};

export { TimerBig };

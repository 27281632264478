// import Typing from 'react-typing-animation';
import { useMediaQuery } from '@material-ui/core';
import dogMobile from '@art/images/landing/dog-mobile.png';
import dogTablet from '@art/images/landing/dog-tablet.png';
import dogDesktop from '@art/images/landing/dog-desktop.png';
import { styles } from './styles';

interface IProps {
  value: number;
  isQuestionClicked: boolean;
  questionText: string;
  answers: string[];
  questionClick: (value: number) => any;
}

const Question: React.FC<IProps> = ({ value, answers, isQuestionClicked, questionText, questionClick }) => {
  const classes = styles();
  const isTablet = useMediaQuery('(min-width: 600px)');
  const isDesktop = useMediaQuery('(min-width: 960px)');

  return (
    <div className={classes.question}>
      <div className={classes.spacer} />

      {/* questionTextContainer */}
      <div
        className={`
        ${classes.questionTextContainer}
        animate__animated animate__fadeInDown
        ${isQuestionClicked ? 'animate__fadeOutUp' : ''}
      `}
      >
        <div className={classes.name}>Doge</div>
        <p>
          {questionText}
          {/* <Typing speed={20} startDelay={1000} hideCursor={true}>
            Would you rather spend a year without sex or a year without internet?
          </Typing> */}
        </p>
        <div className={classes.triangle} />
      </div>

      {/* dogContainer */}
      <div
        className={`
        ${classes.dogContainer}
        animate__animated animate__fadeIn
        ${isQuestionClicked ? 'animate__fadeOutRight' : ''}
      `}
      >
        <img src={isDesktop ? dogDesktop : isTablet ? dogTablet : dogMobile} className={`${classes.dog}`} />
      </div>

      {/* buttonContainer */}
      <div
        className={`
        ${classes.buttonsContainer}
        buttons-${answers.length}
        animate__animated animate__fadeInUp
        ${isQuestionClicked ? 'isQuestionClicked animate__fadeOutDown' : ''}
      `}
      >
        {answers.length
          ? answers.map((answer: string, index: number) => {
              return (
                <div
                  key={index}
                  className={`${classes.button} ${value === index + 1 ? 'active' : ''}`}
                  onClick={() => questionClick(index + 1)}
                >
                  <span>{answer}</span>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default Question;

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { IRoomPlayer } from '@store/room/roomSlice';
import { setVideoState } from '@store/reducers/common';
import { styles, wordStyles } from './styles';
import { useDeviceSize } from '@src/common/screenSizes';
import { CircleTimer } from '@src/games/common/Timer/CircleTimer';
import { TimeAlert } from '../TimeAlert/TimeAlert';
import { GiveUpModal } from './GiveUpModal/GiveUpModal';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import Button from '@src/components/Button';
import monkey from '@src/art/images/guess-up/monkey.png';
import { GuessUpState, GUESS_UP_STATE } from '@src/shared/gameInterfaces/guessUp.socket';
import { GuessedModal } from './GuessedModal copy/GuessedModal';
import { selectPlayerId } from '@src/store/reducers/player.reducer';
import { SectionTimer } from '@src/games/common/Timer/SectionTimer';

interface IProps {
  game: GuessUpState;
  isActivePlayer: boolean;
  activePlayer: IRoomPlayer;
  onVote: (value: boolean) => void;
  onGiveUp: () => void;
  onVotingStart: () => void;
  isSpectator: boolean;
}

interface IPlayerCardProps {
  game: GuessUpState;
  playerId: string;
}

const PlayerContainer: React.FC<IProps> = ({
  game,
  isActivePlayer,
  activePlayer,
  onVote,
  onGiveUp,
  onVotingStart,
  isSpectator,
}) => {
  const classes = styles();
  const { gameState, activePlayerId, word, votes, votedPlayers, timer } = game;
  const [isGiveupModalOpen, setGiveupModalOpen] = useState(false);
  const [isPlayerVoted, setIsPlayerVoted] = useState(false);
  const { timeIsUp } = game.timer!;
  const isPlayerSelectOpen = isGiveupModalOpen || false;
  const isGuessedOpen = gameState === GUESS_UP_STATE.VOTE && !isPlayerVoted && !isActivePlayer && !isSpectator;
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const currentPlayerId = useAppSelector(selectPlayerId);

  useEffect(() => {
    setIsPlayerVoted(!!votedPlayers?.find((playerId) => playerId === currentPlayerId));
  }, [votedPlayers?.length]);

  return (
    <>
      {timeIsUp && <TimeAlert />}
      <div className={classes.containerStyles}>
        <Word isActivePlayer={isActivePlayer} word={word!} />
        <PlayerCard playerId={activePlayerId} game={game} />
      </div>
      {isActivePlayer ? (
        !timeIsUp ? (
          <Button
            onClick={() => setGiveupModalOpen(true)}
            text={isEnLocale ? 'Give up' : 'Сдаться'}
            type={'primary-red-new'}
            style={{ marginBottom: '30px' }}
          />
        ) : (
          <div className={classes.badge}>
            {isEnLocale
              ? 'Other players must tap Guessed to proceed'
              : 'Остальные игроки должны проголосовать, чтобы продолжить'}
          </div>
        )
      ) : !isPlayerVoted ? (
        <Button
          onClick={() => onVotingStart()}
          text={isEnLocale ? 'Guessed' : 'Отгадано'}
          type={'primary-blue-new'}
          style={{ marginBottom: '30px' }}
          disabled={isSpectator || isPlayerVoted}
        />
      ) : (
        <div className={classes.badge}>
          {isEnLocale ? 'All players must tap Guessed to proceed' : 'Все игроки должны проголосовать, чтобы продолжить'}
        </div>
      )}
      <GiveUpModal
        onGiveUp={onGiveUp}
        isOpen={isPlayerSelectOpen}
        activePlayer={activePlayer}
        setModalOpen={setGiveupModalOpen}
      />
      <GuessedModal onVote={onVote} isOpen={isGuessedOpen} activePlayer={activePlayer} timer={timer} />
    </>
  );
};

interface Props {
  word: string;
  isActivePlayer: boolean;
}

const Word: React.FC<Props> = ({ word, isActivePlayer }) => {
  const classes = wordStyles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const firstText = isEnLocale ? 'You need to guess Who are You' : 'Тебе нужно понять, кто ты';
  const secondText = isEnLocale ? 'Ask questions to the other players' : 'Задавай вопросы другим игрокам';

  return (
    <div className={classes.wordShowContainer}>
      {!isActivePlayer ? (
        <div className={classes.word}>{word}</div>
      ) : (
        <>
          <div className={classes.description}>{firstText}</div>
          <div className={classes.description}>{secondText}</div>
        </>
      )}
    </div>
  );
};

const PlayerCard: React.FC<IPlayerCardProps> = ({ playerId, game }) => {
  const dispatch = useAppDispatch();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const classes = styles();
  const { isDesktop } = useDeviceSize();
  const videoContainer = document.getElementById(playerId!);
  const videoContainerOrigin = document.getElementById(`${playerId}_container`);

  useEffect(() => {
    if (videoContainerOrigin) {
      //@ts-ignore
      videoContainerOrigin.firstChild.insertAdjacentHTML('afterBegin', `<img src=${monkey} class=${classes.icon} />`);
      //@ts-ignore
      videoContainerOrigin.firstChild.style.backgroundColor = '#537BC5';
      //@ts-ignore
      videoContainerOrigin.firstChild.style.alignItems = 'center';
    }

    if (videoContainer && containerRef.current) {
      containerRef.current.append(videoContainer);
      videoContainer.classList.add(`${classes.pairContainer}`);
      videoContainer.classList.remove(`${classes.normalContainer}`);
      //@ts-ignore
      videoContainer.style.zIndex = '4';
    }

    return () => {
      if (videoContainer) {
        videoContainer.classList.add(`${classes.normalContainer}`);
        videoContainer.classList.remove(`${classes.pairContainer}`);
        //@ts-ignore
        videoContainer.style.zIndex = '0';
      }

      dispatch(setVideoState({ playerId, data: { shouldRestart: {} } }));

      if (videoContainerOrigin) {
        videoContainerOrigin.firstChild!.firstChild!.remove();
        //@ts-ignore
        videoContainerOrigin.firstChild!.insertBefore(videoContainer, videoContainerOrigin.firstChild!.firstChild);
      }
    };
  }, [videoContainerOrigin, videoContainer, isDesktop]);

  return (
    <div className={classes.personContainer} ref={containerRef}>
      {!game.timer?.timeIsUp && (
        <div style={{ position: 'absolute', bottom: '10px', zIndex: 10, right: '10px' }}>
          <CircleTimer timer={game.timer!} />
          {/* <SectionTimer timer={game.timer!} /> */}
        </div>
      )}
    </div>
  );
};

export { PlayerContainer };

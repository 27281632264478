import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  startWrapper: {
    width: '100%',
    minHeight: '100%',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '43px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '53px',
    },
  },
}));

import { helpStyles } from './styles';
import back from '@art/icons/back.svg';
import { ViewAll } from '@src/components/common/viewAll';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';

interface Props {
  src?: string;
  text: string;
  onClick?: () => void;
}

const HelpQuestion: React.FC<Props> = ({ src = '', text, onClick }) => {
  const classes = helpStyles();

  return (
    <div className={classes.helpQuestionContainer} onClick={onClick}>
      {src && <img className={classes.image} src={src} alt="" />}
      <p className={classes.question}>{text}</p>
      <img className={classes.back} src={back} alt="" />
    </div>
  );
};

const HelpWallets: React.FC<Props> = ({ text }) => {
  const classes = helpStyles();

  return (
    <div className={classes.helpQuestionContainer} style={{ flexDirection: 'column' }}>
      <div style={{ width: '100%', alignItems: 'center' }}>
        <p className={classes.question} style={{ marginRight: 'auto' }}>
          {text}
        </p>
        <ViewAll onClick={() => {}} />
        {/* <WalletModalProvider>
              <WalletMultiButton className={classes.viewAllButton}>View All</WalletMultiButton>
            </WalletModalProvider> */}
      </div>
    </div>
  );
};

export { HelpQuestion, HelpWallets };

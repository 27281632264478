import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    flexDirection: 'column',
    color: '#1B2036',
  },

  top: {
    position: 'relative',
    marginTop: '-7px',
    paddingBottom: '15px',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E8E9EB',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      height: '1px',
      width: '200px',
      bottom: '-1px',
      backgroundColor: '#E8E9EB',
    },
    '&::before': {
      left: '-200px',
      [theme.breakpoints.up('sm')]: {
        width: '14px',
        left: '-14px',
      },
      [theme.breakpoints.up('md')]: {
        left: '-15px',
      },
    },
    '&::after': {
      right: '-200px',
      [theme.breakpoints.up('sm')]: {
        width: '14px',
        right: '-14px',
      },
      [theme.breakpoints.up('md')]: {
        right: '-15px',
      },
    },

    // player name
    '& > b': {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '29px',
    },
  },

  actionsMenu: {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },

  option: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'all .23s',
    marginLeft: '25px',
    cursor: 'pointer',
    '& > img': {
      maxWidth: '18px',
      maxHeight: '18px',
      height: 'auto',
      width: 'auto',
    },
    '& > span': {
      color: '#5F6372',
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '12px',
    },
  },

  middle: {
    position: 'relative',
    borderBottom: '1px solid #E8E9EB',
    paddingTop: '20px',
    paddingBottom: '20px',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      height: '1px',
      width: '200px',
      bottom: '-1px',
      backgroundColor: '#E8E9EB',
    },
    '&::before': {
      left: '-200px',
      [theme.breakpoints.up('sm')]: {
        width: '35px',
        left: '-35px',
      },
      [theme.breakpoints.up('md')]: {
        left: '-15px',
      },
    },
    '&::after': {
      right: '-200px',
      [theme.breakpoints.up('sm')]: {
        width: '35px',
        right: '-35px',
      },
      [theme.breakpoints.up('md')]: {
        right: '-15px',
      },
    },
  },

  contactMenu: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    '& > li': {
      display: 'flex',
      alignItems: 'center',

      marginBottom: '16px',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:last-child': {
        marginBottom: 0,
      },
      '& > img': {
        width: '18px',
        height: 'auto',
        marginRight: '15px',
      },

      '& > span': {
        color: '#1B2036',
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '18px',
      },
    },
  },

  bottom: {
    flexDirection: 'column',
  },

  coins: {
    height: '40px',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#1B2036',

    '& > b': {
      fontWeight: 500,
      fontSize: '17px',
      lineHeight: '17px',
    },

    '& > div': {
      alignItems: 'center',
      '& > img': {
        width: '16px',
        height: 'auto',
        marginRight: '4px',
      },
      '& > span': {
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '1px',
      },
    },
  },

  stickerMenu: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    height: '40px',
    backgroundColor: '#F3F4F5',
    borderTop: '1px solid #E8E9EB',
    borderBottom: '1px solid #E8E9EB',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      height: '40px',
      width: '200px',
      bottom: '-1px',
      backgroundColor: '#F3F4F5',
      borderTop: '1px solid #E8E9EB',
      borderBottom: '1px solid #E8E9EB',
      boxSizing: 'border-box',
    },
    '&::before': {
      left: '-200px',
      [theme.breakpoints.up('sm')]: {
        width: '35px',
        left: '-35px',
      },
      [theme.breakpoints.up('md')]: {
        left: '-15px',
      },
    },
    '&::after': {
      right: '-200px',
      [theme.breakpoints.up('sm')]: {
        width: '35px',
        right: '-35px',
      },
      [theme.breakpoints.up('md')]: {
        right: '-15px',
      },
    },

    '& > li': {
      display: 'inline-flex',
      alignItems: 'center',
      position: 'relative',
      zIndex: 1,
      height: '40px',
      '&.active': {
        '&::after': {
          content: '""',
          position: 'absolute',
          zIndex: 2,
          bottom: 0,
          left: 0,
          right: 0,
          height: '2px',
          backgroundColor: '#2C74F3',
          borderRadius: '2px 2px 0px 0px',
        },
        '& > span': {
          color: '#2C74F3',
        },
      },
      '& > img': {
        height: '18px',
        width: 'auto',
        marginRight: '5px',
      },
      '&:hover': {
        cursor: 'pointer',
        '& > span': {
          color: '#2C74F3',
        },
      },
    },
  },

  stickerSelect: {
    listStyle: 'none',
    flexWrap: 'wrap',
    maxWidth: '360px',
    width: '100%',
    alignSelf: 'center',
    margin: 0,
    padding: 0,
    paddingTop: '15px',
    minHeight: '200px',
    maxHeight: '300px',
    overflowY: 'scroll',
    justifyContent: 'center',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 83px)',
    gap: '5px',
    paddingBottom: '65px',
    '&::-webkit-scrollbar': {
      display: 'none',
      background: 'transparent',
      width: 0,
    },
    '& > li': {
      color: 'inherit',
    },
  },

  stickerItem: {
    margin: 0,
    flexGrow: 0,
    width: '83px',
    height: '83px',
    borderRadius: '4px',
    backgroundColor: '#F3F4F5',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative',
    '&.active': {
      border: '2px solid #2C74F3',
      '&:hover': {
        cursor: 'default',
      },
    },
    '&:hover': {
      cursor: 'pointer',
    },
    '& > div': {
      width: '43px',
      height: '16px',
      background: '#FFFFFF',
      borderRadius: '4px 0px',
      position: 'absolute',
      bottom: 0,
      right: 0,
      fontFamily: 'SF Pro Rounded',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '17px',
      textAlign: 'right',
      color: '#FFDE17',
      textStroke: '0.5px #683700',
      textShadow: '0.5px -0.5px 0px #683700',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '2px',
      '& > img': {
        width: '12px',
        height: '12px',
        marginLeft: '2px',
        marginBottom: '2px',
      },
    },
    '& > .free': {
      background: '#43AD17',
      color: 'white',
      textShadow: 'none',
      textStroke: 'initial',
      fontSize: '12px',
      textTransform: 'uppercase',
    },
  },

  btnContainer: {
    position: 'absolute',
    backgroundImage: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    width: '100%',
    '& .send': {
      minWidth: '165px',
      width: 'auto',
      padding: '0px 15px',
      [theme.breakpoints.up('sm')]: {
        minWidth: '200px',
      },
    },
  },

  edit: {
    marginRight: 'auto',
    marginLeft: '10px',
    cursor: 'pointer',
  },
}));

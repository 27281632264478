import blue from '@art/images/roast-battle/popupBlue.png';
import red from '@art/images/roast-battle/popupRed.png';
import shout from '@art/images/roast-battle/shout.svg';
import { RoastBattleState, ROAST_BATTLE_STEP } from '@shared/gameInterfaces/roastBattle.socket';
import { selectRoomPlayers } from '@store/room/roomSlice';
import { useAppSelector } from '@app/hooks';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import { selectIsEnLocale, selectWrapperOffsets } from '@store/reducers/session.reducer';
import { styles } from './styles';

interface Props {
  isActivePlayer?: boolean;
  isPairPlayer?: boolean;
  game: RoastBattleState;
  isStartPopup: boolean;
  open: boolean;
}

const TimeAlert: React.FC<Props> = ({ isActivePlayer, isPairPlayer, game, isStartPopup, open }) => {
  const wrapperOffsets = useAppSelector(selectWrapperOffsets);
  const classes = styles(wrapperOffsets);
  const players = useAppSelector(selectRoomPlayers);
  const activePlayerTime =
    game.battleStep === ROAST_BATTLE_STEP.COUNTDOWN_FIRST || game.battleStep === ROAST_BATTLE_STEP.SPEECH_FIRST;
  const pairPlayerTime =
    game.battleStep === ROAST_BATTLE_STEP.COUNTDOWN_SECOND || game.battleStep === ROAST_BATTLE_STEP.SPEECH_SECOND;

  const isEnLocale = useAppSelector(selectIsEnLocale);

  const getPlayerName = (playerId: string | undefined) =>
    players.find((player) => player.playerId === playerId)?.name || '@left';
  const playerName = getPlayerName(activePlayerTime ? game.activePlayerId : game.pairPlayerId);

  const textStart =
    (activePlayerTime && isActivePlayer) || (pairPlayerTime && isPairPlayer)
      ? isEnLocale
        ? 'Convince the other players to vote for your option'
        : 'Убеди игроков отдать голос за твой вариант'
      : isEnLocale
      ? `${playerName} starts in`
      : `${playerName} начинает через`;
  const textFinish = isEnLocale ? `${playerName} finishes in` : `${playerName} заканчивает через`;

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <section className={`${classes.container} animate__animated  animate__fadeInDown`}>
        <div className={classes.popup} style={{ backgroundImage: `url(${activePlayerTime ? blue : red})` }}>
          <img src={shout} className={classes.shout} />
          <p className={`${classes.text} mobile-outline`}>{isStartPopup ? textStart : textFinish}</p>
        </div>
      </section>
    </Backdrop>
  );
};

export { TimeAlert };

import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { ViewAll } from '@components/common/viewAll';
import { MixProps } from '@src/common/generics';

interface Props {
  canShowAll?: boolean;
  headline: string;
  onClick?: any;
  margin?: number;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  headline2: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '32px',
    lineHeight: '32px',
    marginBottom: '20px',
    // display: 'flex',
    display: 'block',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    // width: '100%',
    [theme.breakpoints.up('sm')]: {
      fontSize: '52px',
      lineHeight: '52px',
      marginBottom: '29px',
      width: 'auto',
    },
  },
}));

const Headline: React.FC<MixProps<Props>> = (props) => {
  const classes = useStyles();

  return (
    <Typography className={`${classes.headline2}`} style={props.style}>
      {props.headline}
      {props.canShowAll && props.onClick && (
        <ViewAll style={{ marginRight: `${props.margin}px` }} onClick={props.onClick} />
      )}
    </Typography>
  );
};

const Headline2: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <div style={{ flexDirection: 'row', alignItems: 'baseline' }}>
      <Typography className={classes.headline2} onClick={props.onClick}>
        {' '}
        {props.headline}{' '}
      </Typography>
      <div style={{ minWidth: '30px', flex: 1 }} />
      {props.canShowAll && <ViewAll style={{ marginRight: `${props.margin}px` }} onClick={props.onClick} />}
    </div>
  );
};

export { Headline, Headline2 };

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import {
  cleanNotification,
  popNotification,
  selectNotification,
  selectPlayingNotification,
} from '@src/store/room/roomFeaturesData.slice';
import {
  INotification,
  IRecievedNotification,
  IResultNotification,
  IQuestNotification,
  NOTIFICATION_TYPE,
  IDepositNotification,
} from '@src/store/room/notifications';
import { Slide, Snackbar, SnackbarCloseReason } from '@material-ui/core';
import { styles } from './styles';
import { CopyNotification } from './inner/copy';
import { ResultNotification } from './inner/result';
import { QuestProgressNotification } from './inner/questProgress';
import { QuestDoneNotification } from './inner/questDone';
import { selectIsEnLocale, selectWrapperOffsets } from '@src/store/reducers/session.reducer';
import { RecievedNotification } from './inner/recieved';
import { PublicityNotification } from './inner/public';
import { QuestNotification } from './inner/quest';
import { ErrorNotification } from './inner/error';
import { DepositNotification } from './inner/deposit';

interface Props {}

const HIDE_DURATION = 2500;

enum SNACKBAR_STATE {
  CLOSED,
  OPENED,
  TRANSITION,
}

const Notifications: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const wrapperOffsets = useAppSelector(selectWrapperOffsets);
  const notification = useAppSelector(selectNotification);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const nextNotification = notification; //useAppSelector(selectPlayingNotification);
  // const [nextNotification, setNextNotification] = useState<INotification | undefined>(undefined);
  const [state, setState] = useState(SNACKBAR_STATE.CLOSED);
  const classes = styles(wrapperOffsets);

  const textInvite = isEnLocale
    ? 'Room link copied to the clipboard. Send it to your friends!'
    : 'Ссылка на комнату скопирована в буфер обмена. Отправь её своим друзьям!';

  const textCopyId = isEnLocale ? 'Player ID copied to the clipboard' : 'ID игрока скопирован в буфер обмена';

  const hide = () => setState(SNACKBAR_STATE.TRANSITION);
  const onClose = (event: unknown, reason: SnackbarCloseReason) => (reason === 'timeout' ? hide() : undefined);
  const onExited = () => {
    dispatch(popNotification());
    setState(SNACKBAR_STATE.CLOSED);
    //dispatch(cleanNotification());
  };

  /*useEffect(() => {
    if (notification && state === SNACKBAR_STATE.CLOSED) {
      // setNextNotification(notification);
      setState(SNACKBAR_STATE.OPENED);
      // dispatch(popNotification());
    }
  }, [notification, state]);*/

  useEffect(() => {
    if (notification) {
      const timeout = setTimeout(hide, HIDE_DURATION);
      setState(SNACKBAR_STATE.OPENED);
      return () => clearTimeout(timeout);
    }
  }, [notification]);

  /*useEffect(() => {
    if (nextNotification) {
      const timeout = setTimeout(hide, HIDE_DURATION);
      return () => clearTimeout(timeout);
    }
  }, [nextNotification]);*/

  const getNotification = () => {
    switch (nextNotification?.type) {
      case NOTIFICATION_TYPE.COPY_LINK:
        return <CopyNotification notification={nextNotification} text={textInvite} />;
      case NOTIFICATION_TYPE.COPY_ID:
        return <CopyNotification notification={nextNotification} text={textCopyId} />;
      case NOTIFICATION_TYPE.SEND:
      case NOTIFICATION_TYPE.WITHDRAW:
        return <ResultNotification notification={nextNotification as IResultNotification} />;
      case NOTIFICATION_TYPE.RECEIVE:
        return <RecievedNotification notification={nextNotification as IRecievedNotification} />;
      case NOTIFICATION_TYPE.PUBLICITY:
        return <PublicityNotification notification={nextNotification} />;
      case NOTIFICATION_TYPE.QUEST:
        return <QuestNotification notification={nextNotification as IQuestNotification} />;
      case NOTIFICATION_TYPE.ERROR:
        return <ErrorNotification notification={nextNotification} />;
      case NOTIFICATION_TYPE.DEPOSIT:
        return <DepositNotification notification={nextNotification as IDepositNotification} />;
      // case NOTIFICATION_TYPE.QUEST_PROGRESS:
      //   return <QuestProgressNotification notification={nextNotification as IQuestNotification} />;
      // case NOTIFICATION_TYPE.QUEST_DONE:
      //   return <QuestDoneNotification notification={nextNotification as IQuestNotification} />;
      default:
        return <div></div>;
    }
  };

  return (
    <Snackbar
      open={state === SNACKBAR_STATE.OPENED}
      onClose={onClose}
      // autoHideDuration={HIDE_DURATION}
      className={classes.snackBar}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={Slide}
      onExited={onExited}
    >
      <div style={{ alignItems: 'center', justifyItems: 'center', width: '100%' }} onClick={hide}>
        {getNotification()}
      </div>
    </Snackbar>
  );
};

export { Notifications };

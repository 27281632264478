import { ILeaderboard, IReward, ITimer } from '../common';
import { IdType } from '../generics';
import { BaseGameState } from '../socketEvents/socketEvents';

export enum ROAST_BATTLE_STEP {
  PREPARE,
  COUNTDOWN_FIRST,
  SPEECH_FIRST,
  COUNTDOWN_SECOND,
  SPEECH_SECOND,
  BATTLE_END,
}

export enum ROAST_BATTLE_STATE {
  READY_START_ROUND = 'READY_START_ROUND',
  SPIN = 'SPIN',
  BATTLE = 'BATTLE',
  VOTE = 'VOTE',
  RESULTS = 'RESULTS',
  TOP_PLAYERS = 'TOP_PLAYERS',
  LEADERBOARD = 'LEADERBOARD',
}

export interface RoastBattleVote {
  target: IdType;
  players: IdType[];
}

export interface RoastBattleState extends BaseGameState {
  gameState: ROAST_BATTLE_STATE;
  roundNumber: number;
  totalRounds: number;
  activePlayerId: IdType;
  pairPlayerId?: IdType;
  ticket?: {
    question: string;
    answers: string[];
  };
  votes?: RoastBattleVote[];
  readyPlayers?: IdType[];
  leaderboard?: ILeaderboard;
  reward?: IReward;
  battleStep?: ROAST_BATTLE_STEP;
  timer?: ITimer;
}

import { GuessUpState } from './../../shared/gameInterfaces/guessUp.socket';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PLAYER_STATE, selectRoomPlayers } from '@store/room/roomSlice';
import { selectPlayerId } from '@store/reducers/player.reducer';
import WSClient from '@src/sockets';
import { simpleMerge } from '@src/common/generics';
import { CLIENT_EVENTS, SERVER_EVENTS } from '@shared/socketEvents/eventTypes';
import { defaultGameState, initialMapState } from './GuessUp.types';

interface GuessUpData {
  data: {
    guessUp: GuessUpState;
  };
}

export const useGameProvider = (roomId: string) => {
  const [game, setGame] = useState<GuessUpState>(defaultGameState);
  const players = useSelector(selectRoomPlayers);
  const playerId = useSelector(selectPlayerId);
  const isActivePlayer = playerId === game.activePlayerId;
  const activePlayer = players.find((player) => player.playerId === game.activePlayerId)!;
  const currentPlayer = players.find((player: any) => player.playerId === playerId);
  const isRoundPlayers = playerId === game.activePlayerId;
  const isSpectator = currentPlayer ? currentPlayer.state !== PLAYER_STATE.GAME : true;

  const stateMatcher = { ...initialMapState, [game.gameState]: true };

  useEffect(() => {
    const updateState = ({ data }: GuessUpData) => setGame(simpleMerge(game, data.guessUp));

    WSClient.on(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    WSClient.on(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);

    WSClient.emit(CLIENT_EVENTS.GAME_STATE_REQUESTED, { roomId });

    return () => {
      WSClient.off(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);
      WSClient.off(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    };
  }, []);

  const setVote = (value: boolean) =>
    setGame(
      simpleMerge(game, {
        votedPlayers:
          game.votedPlayers && game.votedPlayers?.length > 0 ? [...game.votedPlayers, playerId] : [playerId],
      })
    );

  const setReady = () => setGame(simpleMerge(game, {}));

  return [
    stateMatcher,
    { ...game, state: stateMatcher },
    playerId,
    activePlayer,
    isActivePlayer,
    isSpectator,
    isRoundPlayers,
    { setVote, setReady },
  ] as const;
};

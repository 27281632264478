import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // flex: 1,
    maxWidth: '100%',
  },
  container: {
    flexDirection: 'column',
    paddingBottom: 10,
    paddingTop: 40,
    paddingLeft: 15,
    paddingRight: 15,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '528px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    //backgroundColor?
  },
  gameContainer: {
    flexDirection: 'column',
    width: '100%',
    overflowY: 'auto',
  },
  notReadyBtn: {
    backgroundColor: 'unset!important',
    color: '#F98593',
  },
  skeletonContainer: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'unset',
    },
  },
}));

export const nextPartyStyles = makeStyles((theme) => ({
  nextParty: {
    fontFamily,
    overflow: 'auto',
    color: '#1B2036',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    paddingTop: '20px',
    boxSizing: 'border-box',
    '& > *': {
      boxSizing: 'border-box',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },

    '& > h2': {
      fontFamily: 'Nunito',
      fontSize: '32px',
      textTransform: 'uppercase',
      margin: 0,
      marginBottom: '12px',
      textAlign: 'center',
      flexShrink: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: '52px',
        marginBottom: '25px',
      },
    },
    '& > h3': {
      fontFamily: 'Nunito',
      fontSize: '24px',
      lineHeight: '32px',
      textTransform: 'uppercase',
      margin: 0,
      marginBottom: '6px',
      textAlign: 'center',
      flexShrink: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: '42px',
        marginBottom: '15px',
      },
    },
    '& > p': {
      margin: 0,
      marginBottom: '20px',
      textAlign: 'center',
      flexShrink: 0,
    },
  },

  partiesContainer: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginBottom: '14px',
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      maxWidth: '830px',
      marginBottom: '40px',
    },

    '& > div': {
      marginBottom: '10px',
      [theme.breakpoints.up('sm')]: {
        width: '48%',
      },
    },
  },

  gamesContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '12px',
    paddingLeft: '15px',
    paddingRight: '15px',
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      maxWidth: '686px',
      marginBottom: '40px',
    },

    '& > div': {
      marginRight: 0,
      marginBottom: '15px',
      backgroundColor: 'transparent',
      [theme.breakpoints.up('sm')]: {
        width: '48%',
      },
    },
  },

  buttonWrapper: {
    minHeight: '40px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '25px',
    flexShrink: 0,
    '& > button': {
      width: '170px',
      [theme.breakpoints.up('sm')]: {
        width: '180px',
      },
      '& > span': {
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '18px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '16px',
          lineHeight: '19px',
        },
      },
    },
  },
}));

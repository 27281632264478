import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';

type Props = {
  showAlert: boolean;
  isFakeArtist: boolean;
};

const ActivePlayerAlert: React.FC<Props> = ({ showAlert, isFakeArtist }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <div
      className={`
        ${classes.ActivePlayerAlert}
        ${showAlert ? 'animate__fadeInDown' : 'animate__fadeOutUp'} animate__animated`}
    >
      <div className={classes.ActivePlayerAlertText}>
        <p className="mobile-outline">{isEnLocale ? 'Draw one line at a time' : 'Нарисуй одну линию за ход'}</p>
        {isEnLocale ? (
          <span>{isFakeArtist ? 'FAKE like you know the word' : 'Tap Next when you are done'}</span>
        ) : (
          <span>{isFakeArtist ? 'РИСУЙ как будто знаешь слово' : 'Нажми «Далее», когда закончишь'}</span>
        )}
      </div>
    </div>
  );
};

export { ActivePlayerAlert };

import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    textAlign: 'center',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.1)',
    background: 'rgba(44, 116, 243, 1)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(44, 116, 243, 1)',
    },
    // '&:disabled': {
    //   backgroundColor: 'red !important',
    // },
  },
}));

const BlueButton: React.FC<ButtonProps> = ({ ...props }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      // @ts-ignore
      color="white"
      // background="rgba(44, 116, 243, 1)"
      classes={classes}
      // className={classes.root}
      {...props}
    />
  );
};

export default BlueButton;

import { styles } from './styles';
import { useAppSelector } from '@src/app/hooks';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import React, { useState } from 'react';
import attention from '@art/icons/attention.svg';
import Button from '@src/components/Button';
import { BuyCoinModal } from '@src/components/Modals';
import DepositModal from './WithdrawModal/WithdrawModal';
import { selectBalance, selectPlayerId } from '@src/store/reducers/player.reducer';
import { requestSendTransaction } from '@src/graphql/player.queries';
import { useBlockchainData } from '@src/features/blockchain';

interface IProps {
  walletConnected: boolean;
}

const DepositTab: React.FC<IProps> = ({ walletConnected }) => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const [isBuyCoinModalOpen, setBuyCoinModalOpen] = useState(false);
  const [value, setValue] = React.useState<number>(0);
  const [isButtonClicked, setButtonClicked] = useState(false);
  // const balance = useAppSelector(selectBalance);
  const playerId = useAppSelector(selectPlayerId);
  const { tokenBalance, sendTokens } = useBlockchainData();
  const classes = styles();
  const minClaim = 50;

  const onClaimReward2 = async () => {
    const transactionId = await sendTokens(value);
    requestSendTransaction(playerId, transactionId);
  };

  return (
    <div className={classes.depositContainer}>
      {!walletConnected ? (
        <div>
          <img src={attention} alt="" />
          <p>{isEnLocale ? 'Connect your wallet to deposit HIT' : 'Подключи кошелек, чтобы внести HIT'}</p>
        </div>
      ) : (
        <DepositModal minClaim={minClaim} value={value} setValue={setValue} />
      )}
      {!value ? (
        <>
          <div className={'or'}>{isEnLocale ? 'OR' : 'или'}</div>
          <Button
            type="primary-green-new"
            text={isEnLocale ? 'Swap tokens' : 'В магазин'}
            onClick={() => setBuyCoinModalOpen(true)}
            style={{ padding: '8px 20px', width: 'auto' }}
          />
        </>
      ) : (
        <div className={classes.btnContainer}>
          <Button type="primary-red-new" text={isEnLocale ? 'Cancel' : 'Закрыть'} onClick={() => setValue(0)} />
          <Button
            text={isEnLocale ? 'Deposit' : 'Внести'}
            buttonType="submit"
            type="primary-blue-new"
            disabled={value <= 0 || isButtonClicked || value < minClaim || tokenBalance < value}
            onClick={onClaimReward2}
          />
        </div>
      )}
      <BuyCoinModal isOpen={isBuyCoinModalOpen} handleClose={() => setBuyCoinModalOpen(false)} />
    </div>
  );
};

export { DepositTab };

import Pencil from '@art/images/draw-and-guess/pencil.svg';
import { styles } from './styles';

const ActiveStatic: React.FC = () => {
  const classes = styles();

  return (
    <div className={classes.wordShowContainer}>
      <div className={classes.wordShowItem}>
        <div className={classes.wordShowTop}>
          <div className={classes.wordShowTitle}>
            <span>You</span>&nbsp;have to&nbsp;draw
          </div>

          <img src={Pencil} className={classes.pencilImage} alt="pencil.svg" />

          <b>LOLLIPOP</b>
        </div>

        <div className={classes.wordShowBottom}>
          <b>2</b>
          <div>
            <p>Points to you and player who guessed correctly</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ActiveStatic };

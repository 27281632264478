import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { selectRoomName, selectIsFirstPlayer, selectIsRoomPublic } from '@store/room/roomSlice';
import { LockOn, LockOff } from '@components/icons';
import { styles } from './styles';
import { useAppSelector } from '@app/hooks';
import { useChangeRoomPublicity } from '@src/common/misc';

const RoomTitle: React.FC = () => {
  const classes = styles();
  const history = useHistory();
  const isPublic = useAppSelector(selectIsRoomPublic);
  const roomName = useAppSelector(selectRoomName);
  const isRoomOwner = useAppSelector(selectIsFirstPlayer);
  const [showInviteSnack, setShowInviteSnack] = useState(false);
  const { onChangeRoomPublicity } = useChangeRoomPublicity(isPublic);

  const handleClick = () => {
    // isRoomOwner ? setShowInviteSnack(true) : undefined;
    isRoomOwner ? onChangeRoomPublicity() : undefined;
  };

  return (
    <>
      {/*<SnackbarPublicRoom open={showInviteSnack} onClose={() => setShowInviteSnack(false)} isPublic={isPublic} />*/}

      <div className={classes.statusBarTitle} onClick={handleClick}>
        {isPublic ? <LockOff /> : <LockOn />}
        <span>{roomName}</span>
      </div>
    </>
  );
};

export default RoomTitle;

import GameWrapper from '@components/GameWrapper';
import { GameTypes } from '@src/common/constants/common';
import { useGameHook } from './helpers/roulette.hook';
import { Leaderboard, Reward } from '@src/games/common';
import PartyFinishModal from '../common/PartyFinishModal';

import {
  // MULTIPLAYER
  MultiStartRound,
  MultiQuestion,
  MultiResults,

  // SINGLEPLAYER
  SingleStartRound,
  SingleQuestion,
  SingleResults,
} from './parts';
import { useDeviceSize } from '@src/common/screenSizes';

interface IProps {
  roomId: string;
  gameType: string;
  shouldDrink: boolean;
}

// @ts-ignore
const Roulette: React.FC<IProps> = ({ roomId, gameType, shouldDrink }) => {
  const { status, gameState, playerId, players, isActivePlayer, isSpectator, actions } = useGameHook(
    roomId,
    shouldDrink
  );
  const { onSpin, onVote, onCheers, onNext, onQuit } = actions;
  const { isDesktop } = useDeviceSize();

  const onClick = () => {
    status.TOP_PLAYERS ? onNext() : onQuit();
  };

  const padding = status.COMMON_RESULTS && isDesktop ? '0px' : '';
  // SINGLEPLAYER: GAME_START --> ROUND_IN_PROGRESS --> COMMON_RESULTS -> SPIN --> GAME_START --> ...
  // MULTIPLAYER: READY_START_ROUND --> SPIN --> ROUND_IN_PROGRESS -> SHOW_RESULTS --> TOP_PLAYERS | LEADERBOARD --> READY_START_ROUND --> ...

  return (
    <>
      {status.GAME_START && <SingleStartRound gameType={gameType} onSpin={onSpin} isActivePlayer={isActivePlayer} />}
      {/* {(status.READY_START_ROUND || status.SPIN) && <Reward />} */}
      <GameWrapper state={status} style={{ padding: `${padding}` }}>
        {/* MULTIPLAYER - READY_START_ROUND and SPIN */}
        {(status.READY_START_ROUND || status.SPIN) && (
          <MultiStartRound
            onSpin={onSpin}
            gameType={gameType}
            isSpin={status.SPIN}
            gameState={gameState}
            isActivePlayer={isActivePlayer}
          />
        )}

        {status.ROUND_IN_PROGRESS &&
          (gameState?.isSinglePlayer ? (
            <SingleQuestion onVote={onVote} playerId={playerId} gameState={gameState} isSpectator={isSpectator} />
          ) : (
            <MultiQuestion
              onVote={onVote}
              playerId={playerId}
              gameState={gameState}
              isSpectator={isSpectator}
              players={players}
            />
          ))}

        {status.SHOW_RESULTS && (
          <MultiResults
            players={players}
            playerId={playerId}
            onCheers={onCheers}
            gameState={gameState}
            shouldDrink={shouldDrink}
            isSpectator={isSpectator}
            isShowResults={status.SHOW_RESULTS}
          />
        )}

        {status.COMMON_RESULTS && (
          <SingleResults
            onQuit={onQuit}
            onCheers={onCheers}
            gameState={gameState}
            isSpectator={isSpectator}
            isActivePlayer={isActivePlayer}
          />
        )}

        {(status.TOP_PLAYERS || status.LEADERBOARD) && (
          <Leaderboard
            game={gameState}
            onClick={onClick}
            isSpectator={isSpectator}
            isTopPlayers={status.TOP_PLAYERS}
            gameType={GameTypes.DRINK_ROULETTE}
            shouldDrink={shouldDrink}
            roomId={roomId}
          />
        )}

        {gameState.reward && <PartyFinishModal isOpen={true} game={gameState} />}
      </GameWrapper>
    </>
  );
};

export { Roulette };

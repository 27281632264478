import { useState } from 'react';
import { IRoomPlayer } from '@src/store/room/roomSlice';
import { ChestsState } from '@shared/gameInterfaces/chests.socket';
import { StakeTokens } from './parts';
import { styles } from './styles';

type Props = {
  game: ChestsState;
  balance: number;
  playerId: string;
  players: IRoomPlayer[];
  onStart: (value: boolean) => void;
};

const StartRound: React.FC<Props> = ({ game, balance, playerId, players, onStart }) => {
  const classes = styles();
  const { skippedPlayers, stakedPlayers } = game;
  const hasTokens = balance >= game.stakeAmount;
  const isSkipClicked = skippedPlayers.includes(playerId);
  const isStakeClicked = stakedPlayers.includes(playerId);
  const hasPlayerClickedVerified = isSkipClicked || isStakeClicked;
  const [hasPlayerClicked, setHasPlayerClicked] = useState(hasPlayerClickedVerified);
  const areAllClicked = players.length === skippedPlayers.length + stakedPlayers.length;

  const onClick = (hasStaked: boolean) => {
    setHasPlayerClicked(true);
    onStart(hasStaked);
  };

  return (
    <div className={`${classes.startWrapper} animate__animated animate__fadeIn`}>
      <StakeTokens
        hasEnoughTokens={hasTokens}
        hasPlayerClicked={hasPlayerClicked}
        hasPlayerClickedVerified={hasPlayerClickedVerified}
        areAllClicked={areAllClicked}
        playersStaked={game.stakedPlayers}
        stakeAmount={game.stakeAmount}
        isSkipClicked={isSkipClicked}
        onStakeClick={onClick}
      />
    </div>
  );
};

export { StartRound };

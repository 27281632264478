import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    background: '#fff',
    touchAction: 'none',
    flexGrow: 1,
    flexShrink: 0,
  },

  canvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    flexGrow: 1,
    flexShrink: 0,
  },
}));

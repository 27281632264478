import { useAppSelector } from '@src/app/hooks';
import React, { useState } from 'react';
import { styles } from './styles';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import ChangeBlockChainPopup from '@src/features/bsc/ChangeBlockChainPopup';

interface IProps {
  minClaim: number;
  value: number;
  setValue: (number: number) => void;
}

const DepositModal: React.FC<IProps> = ({ minClaim, value, setValue }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setValue(parseInt(event.target.value) || 0);

  return (
    <>
      <div className={classes.sendModalContainer}>
        <p className={'title'}>{isEnLocale ? `Deposit from your wallet` : `Пополни со своего кошелька`}</p>
        <div className={classes.formInner}>
          <div className={classes.customInput}>
            <input
              onChange={handleChange}
              value={value.toString()}
              id="value"
              name="value"
              type="number"
              style={{ border: `1px solid ${value < minClaim ? '#F83E67' : 'transparent'}` }}
              autoComplete="off"
              maxLength={8}
              inputMode="numeric"
              // placeholder="0"
            />
            <div className={'currency'}>HIT</div>
          </div>
          <div style={{ alignItems: 'center', flexDirection: 'column' }}>
            <p style={{ color: value < minClaim ? '#F83E67' : '#5568AE', marginBottom: '6px' }}>
              {isEnLocale
                ? `Minimal withdraw amount: ${minClaim}HIT`
                : `Минимальное количество для вывода - ${minClaim}HIT`}
            </p>
            <p>{isEnLocale ? `It will take about 15 seconds` : `Это займет около 15 секунд`}</p>
          </div>
        </div>
      </div>
      <ChangeBlockChainPopup isOpen={isPopupOpen} handleClose={() => setIsPopupOpen(false)} />
    </>
  );
};

export default DepositModal;

import React from 'react';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { useStyles } from './styles';
import { INotification } from '@src/store/room/notifications';

interface Props {
  notification: INotification;
}

const ErrorNotification: React.FC<Props> = ({ notification }) => {
  const styles = useStyles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const textError = isEnLocale ? 'Something went wrong' : 'Что-то пошло не так';

  return <div className={`${styles.result} ${'unsuccessful'}`}>{textError}</div>;
};

export { ErrorNotification };

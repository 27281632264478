import { makeStyles, createStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      minHeight: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    containerTop: {
      minWidth: '345px',
      backgroundColor: 'white',
      borderRadius: '20px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '25px 30px 15px 30px',
      [theme.breakpoints.up('md')]: {
        padding: 0,
        borderRadius: 0,
        backgroundColor: 'transparent',
      },
    },

    text: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '17px',
      lineHeight: '17px',
      textAlign: 'center',
      color: '#1B2036',
      margin: 0,
      marginBottom: '7px',
      [theme.breakpoints.up('md')]: {
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: '25px',
      },
    },

    star: {
      width: '55px',
      height: '55px',
      paddingRight: '3px',
      WebkitTapHighlightColor: 'transparent',
      cursor: 'pointer',
      '&:hover': {
        '& > path': {
          fill: 'rgba(255, 204, 1, 1)',
          stroke: 'rgba(255, 204, 1, 1)',
          transition: 'cubic-bezier(0.4, 0, 1, 1) 0.1s',
        },
      },
    },

    starActive: {
      width: '55px',
      height: '55px',
      paddingRight: '3px',
      WebkitTapHighlightColor: 'transparent',
      cursor: 'pointer',
      '& > path': {
        fill: 'rgba(255, 204, 1, 1)',
        stroke: 'rgba(255, 204, 1, 1)',
        transition: 'cubic-bezier(0.4, 0, 1, 1) 0.1s',
      },
    },

    button: {
      marginTop: '25px',
      [theme.breakpoints.up('md')]: {
        marginTop: '15px',
      },
    },

    next: {
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '20px',
      lineHeight: '28px',
      textAlign: 'center',
      color: '#5F6372',
      marginTop: '10px',
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        fontSize: '28px',
        marginTop: '22px',
        marginBottom: '12px',
      },
    },

    networks: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '80px',
      backgroundColor: 'white',
      borderRadius: '20px',
      minWidth: '260px',
      padding: '15px 25px',
      [theme.breakpoints.up('md')]: {
        marginTop: '50px',
        backgroundColor: 'transparent',
        borderRadius: 0,
        padding: 0,
      },
      '& > div': {
        fontFamily: fontFamily,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '18px',
        textAlign: 'center',
        color: '#5F6372',
        marginBottom: '5px',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
          fontSize: '17px',
          lineHeight: '17px',
          marginBottom: '15px',
        },
      },
    },

    shareList: {
      display: 'flex',
      justifyContent: 'center',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      [theme.breakpoints.up('md')]: {
        margin: '0px 0px 25px',
      },
      '& > li': {
        marginRight: '10px',
        '&:last-child': {
          marginRight: 0,
        },
      },
    },

    shareItem: {
      width: '45px',
      height: '45px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundColor: 'white',
      [theme.breakpoints.up('md')]: {
        width: '65px',
        height: '65px',
      },
      // '&.twitter': {
      //   backgroundImage: `url(${TwitterLogo})`,
      // },
      // '&.vkontakte': {
      //   backgroundImage: `url(${VkontakteLogo})`,
      // },
      // '&.discord': {
      //   backgroundImage: `url(${DiscordLogo})`,
      // },
      // '&.youtube': {
      //   backgroundImage: `url(${YoutubeLogo})`,
      // },
    },
  })
);

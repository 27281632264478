import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

const GuessMeRules: React.FC = () => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();

  return (
    <>
      {isEnLocale ? (
        <ul className={classes.list}>
          <li>
            An active player answers a question by choosing a number <b>from 1 to 10</b>
          </li>
          <li>
            All other players should guess the answer of an active player by choosing a number <b>from 1 to 10</b>
          </li>
          <li>
            <b>The closer</b> your guess to an active player&apos;s answer, <b>the more</b> points you get
          </li>
          <li>
            An active player gets points <b>if no one guessed</b> his/her answer
          </li>
        </ul>
      ) : (
        <ul className={classes.list}>
          <li>
            Активный игрок отвечает на вопрос, выбирая число <b>от 1 до 10</b>
          </li>
          <li>
            Все остальные игроки должны угадать ответ активного игрока, выбрав число <b>от 1 до 10</b>
          </li>
          <li>
            <b>Чем ближе</b> твоё предположение к ответу активного игрока, <b>тем больше</b> очков ты получишь
          </li>
          <li>
            Активный игрок получает очки, если <b>никто</b> не отгадал его/её ответ{' '}
          </li>
        </ul>
      )}
    </>
  );
};

export { GuessMeRules };

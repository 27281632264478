import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  startWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
  },

  title: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    color: 'white',
    textAlign: 'center',
    marginTop: 0,
    marginBottom: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '33px',
      marginBottom: '20px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '16px',
      color: '#1B2036',
    },
  },

  cupWrapper: {
    position: 'relative',
    zIndex: 1,
    color: 'inherit',
    width: '275px',
    height: '275px',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      height: '450px',
    },
    [theme.breakpoints.up('md')]: {
      width: '340px',
      height: '340px',
    },
  },

  coffeeCup: {
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    left: '50%',
    width: '275px',
    height: '275px',
    marginLeft: '-137.5px',
    marginTop: '-137.5px',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      height: '450px',
      marginLeft: '-225px',
      marginTop: '-225px',
    },
    [theme.breakpoints.up('md')]: {
      width: '340px',
      height: '340px',
      marginLeft: '-170px',
      marginTop: '-170px',
    },
  },

  cupPhrase: {
    position: 'absolute',
    zIndex: 3,
    top: '50%',
    left: '50%',
    width: '185px',
    height: '205px',
    marginLeft: '-92.5px',
    marginTop: '-102.5px',
    animationDuration: '1.45s',
    animationIterationCount: '1',
    animationDelay: '100ms',
    animationFillMode: 'both',
    animationTimingFunction: 'cubic-bezier(0.81, 0.1, 0.31, 0.9)', // ease-in-out
    [theme.breakpoints.up('sm')]: {
      width: '303px',
      height: '335px',
      marginLeft: '-151.5px',
      marginTop: '-167.5px',
    },
    [theme.breakpoints.up('md')]: {
      width: '230px',
      height: '254px',
      marginLeft: '-115px',
      marginTop: '-127px',
    },
  },

  cupButton: {
    position: 'absolute',
    zIndex: 4,
    top: '50%',
    left: '50%',
    width: '65px',
    height: '65px',
    marginLeft: '-32.5px',
    marginTop: '-32.5px',
    borderRadius: '50%',
    cursor: 'pointer',
    opacity: 1,
    transitionProperty: 'filter, opacity',
    transitionDuration: '.23s, .46s',

    '&.clicked': {
      opacity: 0,
      pointerEvents: 'none',
      '&:hover': {
        cursor: 'default',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: '90px',
      height: '90px',
      marginLeft: '-45px',
      marginTop: '-45px',
    },
    [theme.breakpoints.up('md')]: {
      width: '80px',
      height: '80px',
      marginLeft: '-40px',
      marginTop: '-40px',
    },
  },
}));

import { AuthProviderProps } from 'oidc-react';
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';

export const AUTH_CALLBACK_LOCATION = 'login'; //'auth/callback';
export const LOCATION_PATH_PARAM = 'LOCATION_PATH_PARAM';
export const USER_COOKIE = 'user';
export const WAS_LOGGED = 'was_logged';

/**
 * Configuration parameters are set during build stage, but we can keep 1 SSO server for all instances
 */
const realm = process.env.REACT_APP_AUTH_REALM || 'funnchat'; //'prod';
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID || 'prod'; //'hello-poc-client';
const authHost = process.env.REACT_APP_AUTH_AUTH_HOST || 'https://sso.funn.chat';
const redirectUri = buildRedirectUri();
const autoSignIn = false;

function buildRedirectUri(): string {
  return `${window.location.origin}/${AUTH_CALLBACK_LOCATION}`;
}

const userManagerSettings: UserManagerSettings = {
  authority: `${authHost}/auth/realms/${realm}/`,
  client_id: clientId,
  redirect_uri: redirectUri,
  post_logout_redirect_uri: window.location.origin,
  response_type: 'code',
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const userManager: UserManager = new UserManager(userManagerSettings);

export const createUserManager = async (user: User | undefined) => {
  if (user) {
    await userManager.storeUser(user);
  }
  return userManager;
};

const authProps: AuthProviderProps = {
  userManager: userManager,
  autoSignIn: autoSignIn,
};

export const logoutUrl = `${authHost}/auth/realms/${realm}/protocol/openid-connect/logout?redirect_uri=http://localhost:3000`;

export { authProps, userManager };

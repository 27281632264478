import { UnityGameState, UNITY_GAME_STATE } from '../../shared/gameInterfaces/unityGame.socket';

export const initialMapState = {
  PLAYING: false,
};

export const defaultGameState: UnityGameState = {
  gameState: UNITY_GAME_STATE.PLAYING,
  activeGameId: '',
};

// export type MapState = typeof initialMapState;

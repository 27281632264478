import { AppDispatch } from '@src/app/store';
import { updateGameState } from '@store/reducers/game.reducer';
import { DRINK_ROULETTE_STATE, DrinkRouletteState } from '@shared/gameInterfaces/roulette.socket';

enum LABELS {
  ACTIVE = 'Active',
  VOTED = 'Voted',
  DRINK = 'Drink',
  WINNER = 'Winner',
  CHEERS = 'Cheers!',
  READY = 'Ready',
}

const updateRouletteLabelsThunk = (game: DrinkRouletteState, shouldDrink: boolean) => (dispatch: AppDispatch) => {
  const { gameState, readyPlayers, activePlayerId, votedPlayers, drinkPlayers } = game;

  // ACTIVE
  const activeStates = [DRINK_ROULETTE_STATE.READY_START_ROUND, DRINK_ROULETTE_STATE.SPIN].includes(gameState);

  // VOTED
  const voteStates = [DRINK_ROULETTE_STATE.ROUND_IN_PROGRESS].includes(gameState);

  // DRINK
  const drinkStates = [DRINK_ROULETTE_STATE.SHOW_RESULTS, DRINK_ROULETTE_STATE.SKIP_RESULTS].includes(gameState);

  // READY
  const readyStates = [DRINK_ROULETTE_STATE.TOP_PLAYERS, DRINK_ROULETTE_STATE.LEADERBOARD].includes(gameState);

  const labels = new Map<string, string>();

  // ACTIVE
  if (activeStates) {
    labels.set(activePlayerId, LABELS.ACTIVE);
  }

  // VOTED
  if (voteStates) {
    votedPlayers!.forEach((vote) => labels.set(vote.playerId, LABELS.VOTED));
  }

  // DRINK
  if (drinkStates) {
    const drinkLabel = shouldDrink ? LABELS.DRINK : LABELS.WINNER;
    const cheersLabel = shouldDrink ? LABELS.CHEERS : LABELS.READY;
    drinkPlayers!.forEach((playerId) => labels.set(playerId, drinkLabel));
    readyPlayers!.forEach((playerId) => labels.set(playerId, cheersLabel));
  }

  // READY
  if (readyStates) {
    readyPlayers!.forEach((playerId) => labels.set(playerId, LABELS.READY));
  }

  dispatch(updateGameState({ playerLabels: [...labels.entries()] }));
};

export { updateRouletteLabelsThunk };

import { FakeArtistState } from '@src/shared/gameInterfaces/fakeArtist.socket';
import { useEffect, useState } from 'react';
import { ActivePlayerAlert, PassivePlayerAlert } from './parts';

type Props = {
  game: FakeArtistState;
  activePlayerName: string;
  isActivePlayer: boolean;
  isFakeArtist: boolean;
};

const ROUND_ALERT_TIMEOUT = 3000;

const RoundAlert: React.FC<Props> = ({ game, activePlayerName, isActivePlayer, isFakeArtist }) => {
  const [showAlert, setShowAlert] = useState<boolean>(true);

  // useEffect(() => {
  //   // show alert after skiped voting
  //   if (game.voteState === VOTE_STATE.FORCED && game.gameState === 'DRAW') {
  //     setShowAlert(true);

  //     setTimeout(() => {
  //       setShowAlert(false);
  //     }, ROUND_ALERT_TIMEOUT);
  //   }
  // }, [game]);

  useEffect(() => {
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), ROUND_ALERT_TIMEOUT);
  }, [game.subroundNumber]);

  if (isActivePlayer) {
    return <ActivePlayerAlert showAlert={showAlert} isFakeArtist={isFakeArtist} />;
  } else {
    return null;
    // return (
    //   <PassivePlayerAlert
    //     showAlert={showAlert}
    //     activePlayerName={activePlayerName}
    //     isFakeArtist={isFakeArtist}
    //   />
    // );
  }
};

export { RoundAlert };

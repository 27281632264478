import React from 'react';

const Martini: React.FC = () => (
  <svg width="22" height="28" viewBox="0 0 22 28" fill="none">
    <path
      d="M2.85369 4.80309C4.18003 4.80309 5.25524 3.72788 5.25524 2.40155C5.25524 1.07521 4.18003 0 2.85369 0C1.52736 0 0.452148 1.07521 0.452148 2.40155C0.452148 3.72788 1.52736 4.80309 2.85369 4.80309Z"
      fill="#E94444"
    />
    <path
      d="M5.25482 2.40164C5.25482 3.72786 4.1801 4.80313 2.85333 4.80313C2.06955 4.80313 1.37376 4.42759 0.935059 3.84741C1.1578 3.91561 1.39405 3.95225 1.63878 3.95225C2.965 3.95225 4.04027 2.87698 4.04027 1.55075C4.04027 1.00776 3.85985 0.507043 3.5565 0.10498C4.53984 0.404996 5.25482 1.32014 5.25482 2.40164Z"
      fill="#D83131"
    />
    <path
      d="M21.4419 3.23629C21.7005 2.8928 21.4554 2.40149 21.0255 2.40149H12.2528H3.48021C3.0502 2.40149 2.80515 2.89275 3.06382 3.23629L10.646 13.3063C11.0461 13.8377 11.2625 14.4849 11.2625 15.1501V23.1996C11.2625 24.8854 10.0981 26.3643 8.44944 26.7159C8.4029 26.7259 8.35554 26.7354 8.30747 26.7446C8.30747 26.7446 7.05207 26.9239 6.81297 28H12.2529H17.6928C17.4537 26.924 16.1983 26.7446 16.1983 26.7446C16.1556 26.7364 16.1135 26.728 16.072 26.7193C14.4167 26.3704 13.2433 24.8914 13.2433 23.1997V15.1501C13.2433 14.4849 13.4597 13.8377 13.8598 13.3063L21.4419 3.23629Z"
      fill="#DFF6FD"
    />
    <path
      d="M21.4419 3.23629C21.7005 2.8928 21.4554 2.40149 21.0255 2.40149H12.2528H3.48021C3.0502 2.40149 2.80515 2.89275 3.06382 3.23629L3.95867 4.42482L4.32743 4.69492L10.7327 13.1807C10.9298 13.4426 11.0962 13.6937 11.2002 14.0001C11.2218 14.0637 11.1408 14.2924 11.1582 14.3573C11.227 14.6144 11.2625 14.8808 11.2625 15.1502V23.1997C11.2625 24.8855 10.0981 26.3645 8.44944 26.716C8.4029 26.726 8.35554 26.7355 8.30747 26.7447C8.30747 26.7447 7.05207 26.924 6.81297 28.0001H12.2529H17.6928C17.4537 26.9241 16.1983 26.7447 16.1983 26.7447C16.1556 26.7365 16.1135 26.7281 16.072 26.7194C14.4167 26.3705 13.2433 24.8915 13.2433 23.1998V15.1501C13.2433 14.8548 13.286 14.563 13.3684 14.2833C13.3819 14.2376 13.3591 14.127 13.3747 14.082C13.4785 13.782 13.6316 13.4728 13.825 13.2159L20.0262 5.01391L20.2152 4.86549L21.4419 3.23629Z"
      fill="#C8EFFE"
    />
    <path
      d="M13.2433 15.15V23.1995C13.2433 24.8913 14.4165 26.3704 16.0722 26.719C16.1134 26.7278 16.1556 26.7365 16.1983 26.7448C16.1983 26.7448 17.4539 26.924 17.6929 27.9999H16.41C16.29 27.4596 15.8551 27.3443 15.3314 27.2382C14.3014 27.0295 13.4958 26.5336 12.8684 25.6392C12.3726 24.9325 12.1123 24.061 12.1123 23.1996C12.1123 23.1954 12.1123 15.1501 12.1123 15.1501C12.1123 14.4847 12.3286 13.8379 12.7287 13.3064L20.1592 3.23654C20.4178 2.89305 20.1731 2.40173 19.7431 2.40173H21.0255C21.4555 2.40173 21.7007 2.89305 21.4421 3.23654L13.8598 13.3064C13.4596 13.8378 13.2433 14.4847 13.2433 15.15Z"
      fill="#99E6FC"
    />
    <path
      d="M20.265 4.7991L13.8596 13.3062C13.6216 13.6223 13.4482 13.9798 13.3475 14.3573L11.158 14.3575L11.1349 14.276C11.032 13.9287 10.8667 13.5997 10.6459 13.3062L3.95898 4.42488C6.13232 4.29456 8.34984 4.82229 10.4677 5.31125C13.1489 5.92999 16.0772 6.47872 18.6964 5.57616C19.2607 5.38174 19.7873 5.11733 20.265 4.7991Z"
      fill="#E94444"
    />
    <path
      d="M20.2653 4.79907C20.2653 4.79907 13.872 13.2901 13.8597 13.3064C13.7238 13.4868 13.6057 13.6842 13.5136 13.8914C13.4572 14.0184 13.4085 14.1504 13.3684 14.2834C13.3611 14.3076 13.3539 14.3328 13.3477 14.3575H12.2168C12.3177 13.98 12.4908 13.6226 12.7287 13.3064L18.3516 5.68638C18.4675 5.65236 18.5823 5.61583 18.6967 5.57618C19.2611 5.38149 19.7874 5.1173 20.2653 4.79907Z"
      fill="#D83131"
    />
  </svg>
);

export default Martini;

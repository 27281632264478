import Pencil from '@art/images/draw-and-guess/pencil.svg';
import { styles } from './styles';

const PassiveStatic: React.FC = () => {
  const classes = styles();

  return (
    <div className={classes.wordShowContainer}>
      <div className={classes.wordShowItem}>
        <div className={classes.wordShowTop}>
          <img src={Pencil} className={classes.pencilImage} alt="pencil.svg" />
          <p>
            Guess the word <span>Active player</span> is&nbsp;drawing
          </p>
        </div>

        <div className={classes.wordShowBottom}>
          <b>2</b>
          <div>
            <p>Points to you and player who guessed correctly</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PassiveStatic };

import { useTimer } from './timer.hook';
import { ITimer } from '@src/shared/common';
import { useAppSelector } from '@src/app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { useStyles } from './styles';

interface Props {
  timer: ITimer;
  shouldShowAlert?: boolean;
}

const CircleTimer: React.FC<Props> = ({ timer, shouldShowAlert = true }) => {
  const classes = useStyles(timer);
  const { countDown, timeLeftInPercents, timeIsUp } = useTimer(timer);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  return (
    <div
      className={`
        timerContainer
        ${classes.circleTimerContainer}
        ${timeLeftInPercents < 40 ? 'warning' : ''}
        ${timeLeftInPercents < 15 ? 'danger' : ''}
        ${timeIsUp ? 'timeIsUp' : ''}
      `}
    >
      <span className={classes.circleTimer}>{countDown}</span>
    </div>
  );
};

export { CircleTimer };

import { makeStyles } from '@material-ui/core';
import RecordStop from '@art/images/one-question/record-stop-button.svg';

export const styles = makeStyles((theme) => ({
  modalTitle: {
    fontFamily: 'Nunito Sans',
    textTransform: 'uppercase',
    color: '#1B2036',
    fontWeight: 900,
    fontSize: '32px',
    lineHeight: '32px',
    marginTop: '3px',
    marginBottom: '10px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '10px',
      marginBottom: '12px',
    },
  },

  questionText: {
    textAlign: 'center',
    margin: 0,
    marginBottom: '25px',
    maxWidth: '90%',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#1B2036',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '95%',
      fontSize: '24px',
      lineHeight: '28px',
    },
  },

  buttonsContainer: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },

  startTimer: {
    color: '#F83E67',
    fontSize: '40px',
    lineHeight: 1.3,
    fontFamily: 'Barbieri-Regular',
    animationDelay: '0.5s',
    minHeight: '79px',
  },

  recordButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '140px',
    height: '32px',
    borderRadius: '70px',
    color: '#F83E67',
    fontSize: '20px',
    lineHeight: '28px',
    cursor: 'pointer',
    transition: 'color .23s',
    marginBottom: '15px',
    '&:hover': {
      color: '#D9264D',
    },
    '&.ru': {
      width: '150px',
      fontSize: '16px',
      lineHeight: '20px',
      [theme.breakpoints.up('sm')]: {
        width: '210px',
        height: '45px',
        fontSize: '24px',
        lineHeight: '26px',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: '160px',
      height: '45px',
      fontSize: '24px',
      lineHeight: '26px',
    },
  },

  recordContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },

  recordStopButton: {
    display: 'inline-flex',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    backgroundImage: `url(${RecordStop})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    cursor: 'pointer',
  },

  recordTimer: {
    color: '#F83E67',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 600,
  },

  downloadButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '160px',
    height: '32px',
    border: '1px solid #000',
    backgroundColor: '#008AFF',
    boxShadow: 'inset 0px -5px 0px #0243A5, inset 0px 17px 0px #00ABFF',
    borderRadius: '70px',
    color: 'white',
    fontSize: '20px',
    lineHeight: '28px',
    textShadow: '0px 2px 0px #000',
    cursor: 'pointer',
    transition: 'all .23s',
    [theme.breakpoints.up('sm')]: {
      width: '160px',
      height: '45px',
      fontSize: '24px',
      lineHeight: '26px',
    },
    '&.ru': {
      width: '160px',
      fontSize: '16px',
      lineHeight: '20px',
      [theme.breakpoints.up('sm')]: {
        width: '200px',
        height: '45px',
        fontSize: '24px',
        lineHeight: '26px',
      },
    },
    '&:hover': {
      backgroundColor: '#0243A5',
      boxShadow: 'none',
    },
  },
}));

const RoastTextContentEn: React.FC = () => {
  return (
    <>
      You get <b>1 point</b> every time when:
      <br />
      <br />
      <li>you are an active player</li>
      <li>you answer the same way as an active player</li>
      <li>you are the top voted player</li>
    </>
  );
};

const RoastTextContentRu: React.FC = () => {
  return (
    <>
      Ты получаешь 1 очко каждый раз, когда:
      <br />
      <br />
      <li>ты активный игрок</li>
      <li>ты отвечаешь так же, как активный игрок</li>
      <li>за тебя проголосовало большее количество игроков</li>
    </>
  );
};

export { RoastTextContentEn, RoastTextContentRu };

import { GameTypes } from '@src/common/constants/common';
import { BANNER_TYPE } from '@shared/homepage/configSet';
import { BannerInfo } from '@shared/misc/bannerInfo';
import { IImage } from '@shared/misc/image.types';
import { getAllGames, getBanners } from '@store/reducers/common';
import { selectIsInRoom, selectIsFirstPlayer } from '@store/room/roomSlice';
import { useAppSelector } from '@src/app/hooks';
import { selectHasPlayerRoom } from '@src/store/reducers/player.reducer';

interface HeroBannerInfo {
  type: BANNER_TYPE;
  image?: IImage;
  title?: string;
  id?: string;
  gameType?: GameTypes;
}

const useBannerInfo = (bannerInfos: BannerInfo[]): HeroBannerInfo | undefined => {
  const hasPlayerRoom = useAppSelector(selectHasPlayerRoom);
  const isInRoom = useAppSelector(selectIsInRoom);
  const isFirstPlayer = useAppSelector(selectIsFirstPlayer);
  const games = useAppSelector(getAllGames);
  const banners = useAppSelector(getBanners);

  for (const i in bannerInfos) {
    const bannerInfo = bannerInfos[i];

    if (bannerInfo.type === BANNER_TYPE.WELCOME && !hasPlayerRoom && !isInRoom) {
      const banner = banners.find((banner) => banner.id === bannerInfo.id);
      return {
        type: bannerInfo.type,
        image: banner?.image,
      };
    }

    if (bannerInfo.type === BANNER_TYPE.DONATION && hasPlayerRoom && isInRoom) {
      const banner = banners.find((banner) => banner.id === bannerInfo.id);

      return {
        type: bannerInfo.type,
        image: banner?.image,
      };
    }

    if (bannerInfo.type === BANNER_TYPE.GAME) {
      const game = games.find((game) => game.id === bannerInfo.id);
      return {
        type: bannerInfo.type,
        image: game?.gameIntro,
        title: game?.title,
        id: game?.id,
        gameType: game?.type,
      };
    }
  }

  return undefined;
};

export { useBannerInfo };
export type { HeroBannerInfo };

import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';

const ShowFakeArtistInfo = () => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <div className={classes.showWord}>
      <div className={`${classes.showFakeCard} animate__animated  animate__fadeInDown`}>
        <div className={classes.showFakeCardTop}>
          <p>{isEnLocale ? 'FAKE like you know the word' : 'РИСУЙ как будто знаешь слово'}</p>
        </div>

        <div className={classes.showFakeCardLine} />

        <div className={classes.showFakeCardBottom}>
          <p>{isEnLocale ? 'GUESS the word being drawn' : 'УГАДАЙ рисуемое слово'}</p>
        </div>
      </div>
    </div>
  );
};

export { ShowFakeArtistInfo };

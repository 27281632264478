import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  resultsContainer: {
    flexDirection: 'column',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      width: '100%',
      maxWidth: '540px',
      '&.more-2': {
        maxWidth: '100%',
      },
    },
  },

  resultsBarsContainer: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #F3F4F5',
    marginBottom: '6px',
    alignItems: 'flex-end',
    '&.more-2': {
      justifyContent: 'space-evenly',
      '& > .resultsBarsItem': {
        width: '61px',
        maxWidth: 'none',
        marginLeft: '5px',
        marginRight: '5px',
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.up('sm')]: {
          width: '110px',
        },
      },
    },
  },

  resultsBarsItem: {
    position: 'relative',
    flexDirection: 'column',
    width: '45%',
    maxWidth: '280px',
    paddingLeft: '10%',
    paddingRight: '10%',
    flexShrink: 0,
    flexGrow: 0,
    transition: 'all .23s',
  },

  resultsNameItem: {
    display: 'block',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    lineHeight: '12px',
    height: '22px',
    padding: '4px 5px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:first-child': {
      borderRadius: '4px 4px 0px 0px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
      lineHeight: '21px',
      height: '30px',
    },
  },

  resultsVotesTitlesContainer: {
    justifyContent: 'space-between',
    '&.more-2': {
      justifyContent: 'space-evenly',
      '& > .resultsVoteTitlesItem': {
        width: '61px',
        maxWidth: 'none',
        marginLeft: '1%',
        marginRight: '1%',
        lineClamp: 2,
        display: '-webkit-box',
        boxOrient: 'vertical',
        overflow: 'hidden',
        '&:first-child': {
          marginLeft: '1%',
          marginRight: '1%',
        },
        [theme.breakpoints.up('sm')]: {
          width: '110px',
        },
      },
    },
  },

  resultsVoteTitlesItem: {
    position: 'relative',
    width: '45%',
    maxWidth: '280px',
    minHeight: '12px',
    flexShrink: 0,
    flexGrow: 0,
    display: 'block',
    maxHeight: '25px',
    textAlign: 'center',
    marginLeft: '15px',
    wordBreak: 'break-word',
    color: '#1B2036',
    fontSize: '10px',
    fontWeight: 'normal',
    lineHeight: '12px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
      lineHeight: '21px',
      minHeight: '21px',
      maxHeight: '40px',
    },
    '&:first-child': {
      marginLeft: 0,
      marginRight: '15px',
    },
  },
}));

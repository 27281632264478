import { useAppSelector } from '@app/hooks';
import { selectRoomPlayers } from '@store/room/roomSlice';
import GrinningFace from '@art/images/grinning-face-with-sweat.png';
import { styles } from './styles';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
// import WinnerImg from '@art/images/winner-illustration-with-stars.svg';

interface Props {
  game: any;
  debug?: boolean;
}

const RightAnswer: React.FC<Props> = ({ game, debug = false }) => {
  const classes = styles();
  const { winner, chosenWord } = game;
  const players = useAppSelector(selectRoomPlayers);
  const winnerPlayer = players.find((player) => player.playerId === winner);
  const debugCondition = false;
  const isEnLocale = useAppSelector(selectIsEnLocale);

  // debug
  if (debug) {
    return (
      <div className={classes.rightAnswerWrapper}>
        <div
          className={`
          ${classes.rightAnswer} animate__animated  animate__fadeIn
          ${debugCondition ? 'winner' : 'no-one'}
        `}
        >
          {!debugCondition && <img className={classes.smile} src={GrinningFace} alt="WinnerImg" />}

          <h2>{debugCondition ? 'Winner Player Name' : 'No one'}</h2>

          <p>Guessed Correctly</p>

          <div className={classes.word}>
            <div>
              <b>Someword</b>
            </div>
          </div>
        </div>
      </div>
    );

    // game
  } else {
    return (
      <div className={classes.rightAnswerWrapper}>
        <div
          className={`
          ${classes.rightAnswer} animate__animated  animate__fadeIn
          ${winner ? 'winner' : 'no-one'}
        `}
        >
          {!winner && <img className={classes.smile} src={GrinningFace} alt="WinnerImg" />}

          <h2 className="text-overflow">{winner ? winnerPlayer?.name : isEnLocale ? 'No one' : 'Никто'}</h2>

          <p>{isEnLocale ? 'Guessed Correctly' : winnerPlayer?.name ? 'Отгадал/а' : 'не отгадал'}</p>

          <div className={classes.word}>
            <div>
              <b>{chosenWord?.word}</b>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export { RightAnswer };

import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';

const HeaderSkeleton: React.FC = () => {
  return (
    <>
      <Skeleton variant="rect" width={100} height={20} style={{ marginRight: 10 }} />

      <div>
        <Skeleton variant="circle" width={20} height={20} style={{ marginRight: 10 }} />
        <Skeleton variant="circle" width={20} height={20} style={{ marginRight: 10 }} />
        <Skeleton variant="rect" width={100} height={20} style={{ marginRight: 10 }} />
        <Skeleton variant="rect" width={20} height={20} />
      </div>
    </>
  );
};

export default HeaderSkeleton;

import React from 'react';

const Target: React.FC = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M12.5005 0C12.1704 0 11.8433 0.0130392 11.5195 0.0381373C14.3889 0.260882 16.9861 1.45309 18.9852 3.28676L18.5463 3.72559C18.156 4.11593 17.9982 4.68191 18.1301 5.21794L18.527 6.8298L12.2467 13.1102L11.6427 12.5061V14.0809H11.6432C11.6461 14.2966 11.7293 14.5115 11.8939 14.6762C12.0614 14.8437 12.281 14.9274 12.5006 14.9274C12.7201 14.9274 12.9397 14.8437 13.1072 14.6762L19.2647 8.51858L20.8766 8.91549C21.4126 9.04745 21.9786 8.88961 22.3689 8.49926L22.3801 8.48814C22.8066 9.74745 23.0386 11.0966 23.0386 12.5C23.0386 19.0734 17.9646 24.4615 11.5196 24.9619C11.8433 24.987 12.1704 25 12.5005 25C19.4041 25 25.0005 19.4036 25.0005 12.5C25.0005 5.59642 19.4041 0 12.5005 0Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M12.4999 13.9683C13.3121 13.9683 13.9705 13.3099 13.9705 12.4977C13.9705 11.6855 13.3121 11.0271 12.4999 11.0271C11.6877 11.0271 11.0293 11.6855 11.0293 12.4977C11.0293 13.3099 11.6877 13.9683 12.4999 13.9683Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M12.5003 3.67529C7.6272 3.67529 3.67676 7.62573 3.67676 12.4988C3.67676 17.3719 7.6272 21.3224 12.5003 21.3224C17.3734 21.3224 21.3238 17.3719 21.3238 12.4988C21.3238 7.62573 17.3734 3.67529 12.5003 3.67529ZM12.5003 17.6459C9.65764 17.6459 7.35323 15.3415 7.35323 12.4988C7.35323 9.65618 9.65764 7.35176 12.5003 7.35176C15.3429 7.35176 17.6473 9.65618 17.6473 12.4988C17.6473 15.3415 15.3429 17.6459 12.5003 17.6459Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M12.2465 13.1094L11.6426 12.505V13.6933C11.8838 13.8673 12.1803 13.9697 12.5004 13.9697C13.3127 13.9697 13.971 13.3114 13.971 12.4992C13.971 12.1844 13.872 11.8923 13.7029 11.6531L12.2465 13.1094Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M18.2816 5.83325L18.5272 6.82933L16.3145 9.04208C16.7076 9.4759 17.0282 9.97737 17.2561 10.5269L19.2649 8.51806L20.5267 8.82884C19.9983 7.67492 19.2282 6.65482 18.2816 5.83325Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M24.4797 3.35194L22.2076 2.79248L21.6482 0.520467C21.5209 0.00365346 20.8778 -0.175758 20.5014 0.200614L18.0704 2.63159C17.68 3.02194 17.5222 3.58792 17.6542 4.12395L18.2908 6.70929L20.8761 7.34591C21.4122 7.47787 21.9781 7.32003 22.3685 6.92968L24.7995 4.4987C25.1758 4.12233 24.9965 3.47919 24.4797 3.35194Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M24.4797 3.35194L22.2076 2.79248L21.6482 0.520467C21.5209 0.00365346 20.8778 -0.175758 20.5014 0.200614L18.0704 2.63159C17.68 3.02194 17.5222 3.58792 17.6542 4.12395L18.2908 6.70929L20.8761 7.34591C21.4122 7.47787 21.9781 7.32003 22.3685 6.92968L24.7995 4.4987C25.1758 4.12233 24.9965 3.47919 24.4797 3.35194Z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M24.48 3.35193L22.208 2.79247L21.6485 0.520458C21.5213 0.00364439 20.8782 -0.175718 20.5018 0.200556L19.1329 1.56948C18.7425 1.95982 18.5847 2.52585 18.7167 3.06183L19.3533 5.64717L21.9386 6.28379C22.4746 6.41575 23.0406 6.25791 23.431 5.86757L24.7998 4.49869C25.1762 4.12232 24.9968 3.47918 24.48 3.35193Z"
        fill="url(#paint8_linear)"
      />
      <path
        d="M24.4795 3.35193L22.2075 2.79247L21.648 0.520458C21.5207 0.00369357 20.8777 -0.175718 20.5013 0.200507L20.1944 0.507419C19.8041 0.897762 19.6462 1.46374 19.7782 1.99977L20.4148 4.58512L23.0002 5.22173C23.5362 5.35369 24.1022 5.19585 24.4925 4.80551L24.7993 4.49874C25.1756 4.12232 24.9963 3.47918 24.4795 3.35193Z"
        fill="url(#paint9_linear)"
      />
      <path
        d="M12.4995 13.3586C12.2799 13.3586 12.0604 13.2748 11.8929 13.1073C11.5579 12.7723 11.5579 12.2291 11.8929 11.8941L21.547 2.24002C21.882 1.90502 22.4251 1.90502 22.7602 2.24002C23.0952 2.57502 23.0952 3.11821 22.7602 3.45321L13.1061 13.1073C12.9386 13.2748 12.719 13.3586 12.4995 13.3586Z"
        fill="url(#paint10_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="-0.588235"
          y1="12.5"
          x2="24.7127"
          y2="12.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE99A0" />
          <stop offset="0.593" stopColor="#FE646F" />
          <stop offset="1" stopColor="#E41F2D" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="12.6288" y1="12.5" x2="26.3048" y2="12.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FE646F" />
          <stop offset="0.7043" stopColor="#E41F2D" />
          <stop offset="1" stopColor="#C41926" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="3.62732"
          y1="12.4977"
          x2="21.0852"
          y2="12.4977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2FBFF" />
          <stop offset="0.593" stopColor="#FAF7F7" />
          <stop offset="1" stopColor="#F3EAE6" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="3.62774"
          y1="12.4988"
          x2="21.0856"
          y2="12.4988"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2FBFF" />
          <stop offset="0.593" stopColor="#FAF7F7" />
          <stop offset="1" stopColor="#F3EAE6" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="10.1475"
          y1="12.8114"
          x2="21.1354"
          y2="12.8114"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3EAE6" />
          <stop offset="1" stopColor="#CDBFBA" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="10.1478"
          y1="8.18007"
          x2="21.1357"
          y2="8.18007"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3EAE6" />
          <stop offset="1" stopColor="#CDBFBA" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="17.5001"
          y1="3.69581"
          x2="25.0505"
          y2="3.69581"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF9DF" />
          <stop offset="0.593" stopColor="#FFE177" />
          <stop offset="1" stopColor="#FEB137" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="16.9282"
          y1="3.69581"
          x2="25.246"
          y2="3.69581"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF9DF" />
          <stop offset="0.593" stopColor="#FFE177" />
          <stop offset="1" stopColor="#FEB137" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="16.9285"
          y1="3.16477"
          x2="23.5298"
          y2="3.16477"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF9DF" />
          <stop offset="0.593" stopColor="#FFE177" />
          <stop offset="1" stopColor="#FEB137" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="16.928"
          y1="2.63374"
          x2="22.1568"
          y2="2.63374"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF9DF" />
          <stop offset="0.593" stopColor="#FFE177" />
          <stop offset="1" stopColor="#FEB137" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="11.6416"
          y1="7.67365"
          x2="23.0114"
          y2="7.67365"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01D0FB" />
          <stop offset="0.6075" stopColor="#26A6FE" />
          <stop offset="1" stopColor="#0182FC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Target;

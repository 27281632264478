import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

const RoastBattleRules: React.FC = () => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();

  return (
    <>
      {isEnLocale ? (
        <ul className={classes.list}>
          <li>Spin the wheel and read the questions</li>
          <li>Convince the other players to vote for your option</li>
          <li>Get more votes to win</li>
        </ul>
      ) : (
        <ul className={classes.list}>
          <li>Крути колесо и читай вопросы</li>
          <li>Убеди других игроков проголосовать за твой вариант ответа</li>
          <li>Получи большее количество голосов, чтобы победить</li>
        </ul>
      )}
    </>
  );
};

export { RoastBattleRules };

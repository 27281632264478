import React, { MouseEventHandler } from 'react';
import CommonModal from '@components/Modals/CommonModal';
import Button from '@components/Button';
import RouletteLogo from '@art/images/roulette/drink-roulette-big-logo.jpg';
import { GameTypes } from '@src/common/constants/common';
import { GameRules } from './gameRules';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { getRules } from './chooseRules';
import { styles } from './styles';

interface Props {
  isOpen: boolean | undefined;
  playerInLobby?: Boolean;
  isPrivateLobby?: Boolean;
  next?: Boolean;
  gameType?: GameTypes;
  rulesUrl?: string | null;
  handleClose: MouseEventHandler;
  onNext: MouseEventHandler;
  isJoiningPopup?: boolean;
}

const Rules: React.FC<Props> = ({
  isOpen,
  playerInLobby = false,
  isPrivateLobby = false,
  next = false,
  gameType,
  rulesUrl,
  handleClose,
  onNext,
  isJoiningPopup = false,
}) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} isFullscreen={isJoiningPopup} backgroundColor={'white'}>
      <h3 id="transition-modal-title">{isEnLocale ? 'Rules' : 'Правила'}</h3>

      <div className={classes.rules}>{getRules(gameType)}</div>

      {isJoiningPopup ? (
        <Button text={isEnLocale ? 'Join' : 'Присоединиться'} onClick={onNext} type="primary-blue-new" />
      ) : (
        <Button
          text={
            isPrivateLobby && playerInLobby
              ? isEnLocale
                ? 'Back to Lobby'
                : 'В лобби'
              : isEnLocale
              ? 'Back to Game'
              : 'К игре'
          }
          onClick={onNext}
          type="primary-blue-new"
          fontSize="19px"
        />
      )}
    </CommonModal>
  );
};

export default Rules;

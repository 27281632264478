import { QuizClassicState } from '@shared/gameInterfaces/quizClassic.socket';
import Button from '@components/Button';
import { styles } from './styles';
import { AddEmptyRows } from '@src/games/common/Leaderboard/AddEmptyRows';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { selectRoomPlayers } from '@src/store/room/roomSlice';

interface IProps {
  game: QuizClassicState;
  isSpectator: boolean;
  onNext: () => void;
  playerId: string;
}

const VoteList: React.FC<IProps> = ({ game, isSpectator, onNext, playerId }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const addEmptyItems = (itemsArrayLength: number) => {
    const classes = styles();
    const maxItems = 5;
    const emptyItemsToAdd = maxItems - itemsArrayLength;

    if (itemsArrayLength < maxItems) {
      return [1, 2, 3, 4, 5].slice(-emptyItemsToAdd).map((item, index) => (
        <div key={index} className={classes.voteTableRow}>
          <p style={{ marginRight: 'auto' }}>-</p>
          <p style={{ marginRight: '40px' }}>-</p>
        </div>
      ));
    }
    return null;
  };

  const { ticket, playerAnswers, rightAnswer } = game;
  const isButtonClicked = game.readyPlayers?.includes(playerId);
  const players = useAppSelector(selectRoomPlayers);

  return (
    <div className={`${classes.voteList} animate__animated  animate__fadeIn`}>
      <h2>{ticket!.question}</h2>
      <div className={classes.voteListTable}>
        {playerAnswers!.map((player, index: number) => {
          const isRightAnswer = player.answer === rightAnswer;
          const answer = ticket?.answers[player.answer];
          const playerName = players.find((gamePlayer) => gamePlayer.playerId === player.playerId)?.name || '@left';
          return (
            <div key={index} className={classes.voteTableRow}>
              <p style={{ marginRight: 'auto' }}>{playerName}</p>
              <div style={{ backgroundColor: isRightAnswer ? '#3AB957' : '#F83E67' }}>
                <span>{answer}</span>
              </div>
            </div>
          );
        })}
        {playerAnswers && addEmptyItems(playerAnswers?.length)}
      </div>

      <div className={classes.buttonWrapper}>
        {!isButtonClicked && !isSpectator ? (
          <Button type="primary-blue" text={isEnLocale ? 'Next' : 'Далее'} onClick={onNext} />
        ) : (
          <span className="mobile-outline">
            {isEnLocale ? 'All players must tap Next to proceed' : 'Все игроки должны нажать Далее'}
          </span>
        )}
      </div>
    </div>
  );
};

export { VoteList };

import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  questionWrapper: {
    position: 'relative',
    width: '100%',
    minHeight: '100%',
    justifyContent: 'space-between',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
  },

  timerWrapper: {
    position: 'relative',
    height: '40px',
    width: '100%',
    marginTop: '-15px',
    marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '-30px',
      marginBottom: '10px',
      height: '50px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '15px',
      marginBottom: 0,
    },

    // fix timer on mobile
    '& > div': {
      position: 'fixed',
      zIndex: 10,
      width: 'calc(100% - 30px)', // minus 15 + 15 paddings
      [theme.breakpoints.up('sm')]: {
        width: 'calc(100% - 60px)', // minus 30 + 30 paddings
      },
      [theme.breakpoints.up('md')]: {
        position: 'static',
        width: '100%',
      },
    },
  },

  questionInner: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '620px',
      margin: 'auto',
    },
  },

  questionText: {
    minHeight: '28px',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#1B2036',
    marginTop: 0,
    marginBottom: '20px',
    whiteSpace: 'pre-wrap',
    borderRadius: '20px',
    backgroundColor: 'white',
    padding: '8px 15px',
    wordBreak: 'break-word',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '33px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '50px',
      padding: 0,
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
  },

  questionRangeWhiteContainer: {
    flexDirection: 'column',
    width: '100%',
    minWidth: '300px',
    alignItems: 'center',
    borderRadius: '20px',
    backgroundColor: 'white',
    padding: '8px 15px',
    marginBottom: '25px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '20px',
      padding: 0,
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
  },

  questionRangeWrapper: {
    width: '85%',
    minHeight: '67px',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: '92%',
    },
  },

  questionRange: {
    flexDirection: 'column',
    marginBottom: '20px',
  },

  questionGrades: {
    justifyContent: 'space-between',
    '& span': {
      color: '#5F6372',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '21px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '21px',
      },
      '&:first-child': {
        textAlign: 'left',
        paddingRight: '15px',
        [theme.breakpoints.up('sm')]: {
          paddingRight: '30px',
        },
      },
      '&:last-child': {
        textAlign: 'right',
        paddingLeft: '15px',
        [theme.breakpoints.up('sm')]: {
          paddingLeft: '30px',
        },
      },
    },
  },

  buttonWrapper: {
    position: 'relative',
    width: '100%',
    minHeight: '56px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '250px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '300px',
      position: 'static',
      minHeight: '60px',
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      height: '50px',
    },

    '& > button': {
      border: '10px solid white',
      height: '56px',
      [theme.breakpoints.up('sm')]: {
        height: '60px',
      },
      [theme.breakpoints.up('md')]: {
        border: 'none',
        height: '40px',
      },
    },

    // after click text
    '& > span': {
      fontFamily,
      color: 'white',
      fontWeight: 800,
      fontSize: '15px',
      lineHeight: '18px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
      [theme.breakpoints.up('md')]: {
        color: '#5F6372',
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
  },
}));

import { styles } from './styles';

interface IProps {
  questionText: string;
  buttons: { title: string }[];
  onAnswerClick: (index: number) => void;
}

const Question: React.FC<IProps> = ({ questionText, buttons, onAnswerClick }) => {
  const classes = styles();
  const LARGE_TRIGGER = 100;
  const questionLength = questionText.length;

  return (
    <div className={`${classes.question} animate__animated  animate__fadeIn`}>
      <div className={classes.questionWrapper}>
        <div
          className={`
          ${classes.questionText}
          ${questionLength >= LARGE_TRIGGER ? 'large' : ''}
        `}
        >
          <p>
            <span>Question #1</span>
            <br />
            <br />
            {questionText}
          </p>
        </div>

        {/* buttons */}
        <div className={classes.btnContainer}>
          {buttons.length
            ? buttons.map((button: any, index: number) => {
                // votedItem?.id - need to save vote when reload browser
                return (
                  <div
                    key={index}
                    onClick={() => onAnswerClick(index)} // onCLick im map with anonim arrow func
                    className={classes.voteBtn}
                    style={{ width: `calc(100% / ${buttons.length}` }}
                  >
                    <span>{button.title}</span>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default Question;

import React from 'react';
import { DAppProvider } from '@usedapp/core';
import { useAppSelector } from '@src/app/hooks';
import { selectBsc } from '@src/store/reducers/common';

/*const config = {
    readOnlyChainId: 56,
    readOnlyUrls: {
      [56]: 'https://bsc-dataseed.binance.org/',
    },
  }*/

const BscWrapper = <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  function BscComponent({ ...props }) {
    const { chainId } = useAppSelector(selectBsc);
    const config = { readOnlyChainId: chainId };

    return (
      <DAppProvider config={config}>
        <Component {...props} />
      </DAppProvider>
    );
  };

export { BscWrapper };

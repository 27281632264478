import React from 'react';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { IResultNotification, NOTIFICATION_TYPE } from '@src/store/room/notifications';
import { useStyles } from './styles';

interface Props {
  notification: IResultNotification;
}

const getSuccessfulText = (type: NOTIFICATION_TYPE, isEnLocale: boolean) => {
  switch (type) {
    case NOTIFICATION_TYPE.WITHDRAW:
      return isEnLocale ? 'Withdraw successfully' : 'Вывод завершен успешно';
    case NOTIFICATION_TYPE.SEND:
      return isEnLocale ? 'Tokens sent successfully' : 'Токены успешно отправлены';
    default:
      return '';
  }
};

const getUnsuccessfulText = (type: NOTIFICATION_TYPE, isEnLocale: boolean) => {
  switch (type) {
    case NOTIFICATION_TYPE.WITHDRAW:
      return isEnLocale ? 'Not succeed to withdraw tokens' : 'Не удалось вывести токены';
    case NOTIFICATION_TYPE.SEND:
      return isEnLocale ? 'Tokens sending failed' : 'Отправка токенов не удалась';
    default:
      return '';
  }
};

const getText = (notification: IResultNotification, isEnLocale: boolean) =>
  notification.success
    ? getSuccessfulText(notification.type, isEnLocale)
    : getUnsuccessfulText(notification.type, isEnLocale);

const ResultNotification: React.FC<Props> = ({ notification }) => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const styles = useStyles();
  const text = getText(notification, isEnLocale);

  return <div className={`${styles.result} ${notification.success ? '' : 'unsuccessful'}`}>{text}</div>;
};

export { ResultNotification };

import React, { useCallback, MouseEventHandler } from 'react';
import { useParams } from 'react-router-dom';
import { selectPartyId, selectIsEndGamePopup, selectIsFirstPlayer } from '@store/room/roomSlice';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import WSClient from '@src/sockets';
import Button from '@components/Button';
import { CommonModal } from '@components/Modals';
import { styles } from './styles';
import { CLIENT_EVENTS } from '@shared/socketEvents/eventTypes';
import { useAppSelector } from '@app/hooks';
import { analytics } from '@services/amplitude';
import { useSelector } from 'react-redux';
import { selectPlayerId } from '@src/store/reducers/player.reducer';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const LeaveModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const classes = styles();
  const { roomId } = useParams<{ roomId: string }>();
  const partyId = useAppSelector(selectPartyId);
  const playerId = useAppSelector(selectPlayerId);
  const isLeaveGame = useAppSelector(selectIsEndGamePopup);
  const isRoomOwner = useSelector(selectIsFirstPlayer);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const handleLeaveGame = useCallback(() => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_LEFT_GAME, {
      playerId,
      roomId,
    });
    analytics.logEvent(analytics.EVENTS.PLAYER_LEFT_GAME, { roomId, partyId });
    handleClose();
  }, [handleClose, roomId]);

  const handleLeaveRoom = useCallback(() => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_LEFT_ROOM, {
      playerId,
      roomId,
    });
    analytics.logEvent(analytics.EVENTS.PLAYER_LEFT_ROOM, { roomId });
    handleClose();
    //dispatch(exitToHomepage('Player clicked leave the room'));
  }, [handleClose, roomId]);

  const modalEnglishText = isLeaveGame
    ? 'Current game will end and all players stay in the room. You will be able to start another game.'
    : // : isRoomOwner
      // ? 'Without room owner the room will be closed, all players will leave the room.'
      'You leave the room and move to the homepage.';

  const modalRussianText = isLeaveGame
    ? 'Текущая игра закончится и все игроки останутся в комнате. Вы сможете начать другую игру.'
    : // : isRoomOwner
      // ? 'Все игроки покинут комнату, потому что она закроется без создателя комнаты.'
      'Вы выйдете из комнаты и перейдете на главную страницу.';

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} backgroundColor={'white'}>
      <div className={classes.wrapper}>
        <h3 id="transition-modal-title">{isEnLocale ? 'Are you sure?' : 'Ты уверен?'}</h3>
        <p className={classes.text}>{isEnLocale ? modalEnglishText : modalRussianText}</p>
        <div className={classes.btnContainer}>
          <Button
            type="primary-red-new"
            text={isEnLocale ? (isLeaveGame ? 'End' : 'Exit') : isLeaveGame ? 'Закончить' : 'Выйти'}
            onClick={isLeaveGame ? handleLeaveGame : handleLeaveRoom}
            style={{ marginRight: '15px' }}
          />
          <Button text={isEnLocale ? 'Stay' : 'Остаться'} onClick={handleClose} type="primary-blue-new" />
        </div>
      </div>
    </CommonModal>
  );
};

export default LeaveModal;

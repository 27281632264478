import { QuestionChallengeState, QUESTION_CHALLENGE_STATE } from '@shared/gameInterfaces/questionChallenge.socket';

export const initialMapState = {
  QUESTION: false,
};

export enum Screens {
  START_SCREEN = 'start-screen',
  CHOOSE_HOTNESS = 'choose-hotness',
  RECORD_ANSWER = 'record-answer',
  SHARE_VIDEO = 'share-video',
  TAKE_REWARD = 'take-reward',
}

export type Hotness = number | null;

export const defaultGameState: QuestionChallengeState = {
  gameState: QUESTION_CHALLENGE_STATE.QUESTION,
  question: '',
  activeGameId: '',
};

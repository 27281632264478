import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  modalTitle: {
    textAlign: 'center',
    // maxWidth: '220px',
    fontFamily: 'Nunito Sans',
    // textTransform: 'uppercase',
    color: '#1B2036',
    fontWeight: 900,
    fontSize: '24px',
    lineHeight: '29px',
    marginTop: '3px',
    marginBottom: '10px',
    // marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      // maxWidth: '450px',
      marginTop: '10px',
      fontSize: '28px',
      lineHeight: '33px',
      marginBottom: '12px',
    },
  },

  hotness: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },

  pepper: {
    width: '80px',
    height: '55px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '70px auto',
    cursor: 'pointer',
  },

  startButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '215px',
    height: '32px',
    border: '1px solid #000',
    backgroundColor: '#008AFF',
    boxShadow: 'inset 0px -5px 0px #0243A5, inset 0px 17px 0px #00ABFF',
    borderRadius: '70px',
    color: 'white',
    fontSize: '20px',
    lineHeight: '28px',
    textShadow: '0px 2px 0px #000',
    cursor: 'pointer',
    transition: 'all .23s',
    '&[disabled]': {
      pointerEvents: 'none',
      opacity: 0.5,
    },
    [theme.breakpoints.up('sm')]: {
      width: '160px',
      height: '45px',
      fontSize: '24px',
      lineHeight: '26px',
    },
    '&.ru': {
      width: '160px',
      fontSize: '16px',
      lineHeight: '20px',
      [theme.breakpoints.up('sm')]: {
        width: '250px',
        height: '45px',
        fontSize: '24px',
        lineHeight: '26px',
      },
    },
    '&:hover': {
      backgroundColor: '#0243A5',
      boxShadow: 'none',
    },
  },
}));

import React, { useEffect, useState } from 'react';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import Button from '@components/Button';
import { CommonModal } from '@components/Modals';
import { styles } from './styles';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectEnergyAmount, selectPlayerId } from '@src/store/reducers/player.reducer';
import helloCoin from '@art/icons/coin_flat.svg';
import { selectFriendlyBonus } from '@src/store/reducers/common';
import CloseCross from '@src/components/CloseCross/CloseCross';
import energyIcon from '@src/art/icons/StatusBar/energy.svg';
import { ENERGY_COST } from '@src/common/constants/system';
import { ILeaderboard, ILeaderboardItem, IReward, ITimer, RewardState } from '@src/shared/common';
import WSClient from '@src/sockets';
import { CLIENT_EVENTS } from '@src/shared/socketEvents/eventTypes';
import { GameTreasuryEmpty } from '@src/components/Modals/UnityModals/EmptyNotification/GameTreasuryEmpty';
import { TextTimer } from '../Timer/TextTimer';
import { IdType } from '@src/shared/generics';
import { selectCurrentGame } from '@src/store/room/roomSlice';
import { WaitModal } from './waitPopup';
import { analytics } from '@src/services/amplitude';

interface Props {
  isOpen?: boolean;
  game: {
    activeGameId: IdType;
    leaderboard?: ILeaderboard;
    reward?: IReward;
    timer?: ITimer;
  };
}

const PartyFinishModal: React.FC<Props> = ({ isOpen, game }) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const playerId = useAppSelector(selectPlayerId);
  const friendBonusBase = useAppSelector(selectFriendlyBonus);
  const energyAmount = useAppSelector(selectEnergyAmount);
  const selectedGame = useAppSelector(selectCurrentGame);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(isOpen);
  const [isOpenWaitModal, setIsOpenWaitModal] = useState(false);
  const leaderboardInfo = game.leaderboard!.find((player: ILeaderboardItem) => player.playerId === playerId);
  const result = leaderboardInfo!.value * selectedGame!.balance.multiplier;
  const friendsBonusPercent = game.reward!.extraPlayers * friendBonusBase * 100;
  const friendsBonusCoins = result * game.reward!.extraPlayers * friendBonusBase;
  const showFullPopup = game.reward?.rewardState === RewardState.PRESENT || isRequesting;
  const hasEnergy = energyAmount >= ENERGY_COST;

  const onRewardClaim = () => {
    setIsRequesting(true);
    WSClient.emit(CLIENT_EVENTS.PLAYER_REWARD_REQUEST, { activeGameId: game.activeGameId, shouldSkip: false });
    analytics.logEvent(analytics.EVENTS.CLAIM_CLAIMED, {
      result: leaderboardInfo!.value,
      toClaim: result + friendsBonusCoins,
    });
    setIsOpenModal(false);
  };

  const onSkip = () => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_REWARD_REQUEST, { activeGameId: game.activeGameId, shouldSkip: true });
    analytics.logEvent(analytics.EVENTS.CLAIM_CLOSED, {
      result: leaderboardInfo!.value,
      toClaim: result + friendsBonusCoins,
    });
    setIsOpenModal(false);
    setIsOpenWaitModal(true);
  };

  useEffect(() => {
    analytics.logEvent(analytics.EVENTS.CLAIM_SHOWN, { hasReward: showFullPopup });
  }, []);

  return (
    <>
      <CommonModal isOpen={isOpenModal} handleClose={onSkip}>
        <div className={classes.wrapper}>
          <div className={classes.timer}>
            <TextTimer timer={game.timer!} />
          </div>
          <CloseCross onClick={onSkip} />
          <h3 id="transition-modal-title">{isEnLocale ? 'Game complete' : 'Игра завершена'}</h3>
          {showFullPopup && (
            <div style={{ flexDirection: 'column', width: '100%' }}>
              <div className={classes.row}>
                <p className={'title'}>{isEnLocale ? 'Your Results' : 'Твой результат'}</p>
                <div className={'dots'} />
                <p className={'value'} style={{ color: '#1B2036' }}>
                  {result}
                </p>
              </div>

              <div className={classes.row}>
                <p className={'title'}>{isEnLocale ? 'Total Friends Bonus' : 'Бонус за друзей'}</p>
                <div className={'dots'} />
                <p className={'value'}>{friendsBonusPercent ? `+${friendsBonusPercent.toFixed()}` : 0}%</p>
              </div>

              <div className={classes.row}>
                <p className={'title'}>{isEnLocale ? 'Total Rewards' : 'Общая награда'}</p>
                <div className={'dots'} />
                <p className={'value'}>
                  {result > 0 ? `${result.toFixed(2)} + ${friendsBonusCoins.toFixed(2)}` : `${result.toFixed(2)}`}
                </p>
                <img className={'coin'} src={helloCoin} alt="coin" />
              </div>
            </div>
          )}

          {game.reward?.rewardState === RewardState.NO_TREASURY && <GameTreasuryEmpty />}

          <div className={classes.btnContainer}>
            {showFullPopup ? (
              <Button
                onClick={onRewardClaim}
                disabled={isRequesting || result <= 0 || !hasEnergy}
                text={isEnLocale ? `Claim for ${ENERGY_COST}` : `Забрать за ${ENERGY_COST}`}
                type={'primary-blue-new'}
                className="send"
              >
                <img src={energyIcon} alt="" />
              </Button>
            ) : (
              <Button
                onClick={onSkip}
                text={isEnLocale ? 'Next' : 'Далее'}
                type={'primary-blue-new'}
                className="send"
              />
            )}
          </div>
        </div>
      </CommonModal>
      {isOpenWaitModal && <WaitModal game={game} />}
    </>
  );
};

export default PartyFinishModal;

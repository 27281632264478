import GameWrapper from '@components/GameWrapper';
import { useGameHook } from './FiveSeconds.hook';
import { GameTypes } from '@src/common/constants/common';
import { StartRound, PlayerContainer, Rule, VotesTable } from './parts';
import { Leaderboard } from '@src/games/common/Leaderboard/Leaderboard';
import { styles } from './styles';
import PartyFinishModal from '../common/PartyFinishModal';

interface Props {
  roomId: string;
}

const FiveSeconds: React.FC<Props> = ({ roomId }) => {
  const classes = styles();
  const { state, game, actions, activePlayer, isActivePlayer, isRoundPlayers, isSpectator } = useGameHook(roomId);
  const { onSpin, onVote, onReady, onQuit, onVotingStart } = actions;

  const onClick = () => {
    state.TOP_PLAYERS ? onReady() : onQuit();
  };

  return (
    <GameWrapper state={state}>
      <div className={classes.gameContainer}>
        {(state.READY_START_ROUND || state.SPIN) && (
          <StartRound game={game} isSpin={state.SPIN} onSpin={onSpin} isActivePlayer={isActivePlayer} />
        )}

        {state.SHOW_WORD && <Rule activePlayer={activePlayer} />}

        {(state.GAME || state.VOTE || state.PREPARE) && (
          <PlayerContainer
            game={game}
            isActivePlayer={isActivePlayer}
            activePlayer={activePlayer}
            onVote={onVote}
            onVotingStart={onVotingStart}
            isSpectator={isSpectator}
          />
        )}

        {state.VOTE_RESULTS && (
          <VotesTable
            game={game}
            isSpectator={isSpectator}
            isActivePlayer={isActivePlayer}
            activePlayer={activePlayer}
            onReady={onReady}
          />
        )}

        {(state.TOP_PLAYERS || state.LEADERBOARD) && (
          <Leaderboard
            game={game}
            onClick={onClick}
            isSpectator={isSpectator}
            isTopPlayers={state.TOP_PLAYERS}
            gameType={GameTypes.FIVE_SECONDS}
            roomId={roomId}
          />
        )}

        {game.reward && <PartyFinishModal isOpen={true} game={game} />}
      </div>
    </GameWrapper>
  );
};

export { FiveSeconds };

import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    overflowY: 'scroll',
    flexDirection: 'column',
    padding: '15px',
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
      display: 'none',
    },
  },

  inputTitle: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#5568AE',
    display: 'flex',
    marginBottom: '6px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '24px',
    },
  },

  formInner: {
    color: 'inherit',
    flexDirection: 'column',
    width: '100%',
  },
  customInput: {
    display: 'flex',
    position: 'relative',
    width: ' 100%',
    alignItems: 'center',
    justifyContent: 'center',

    '& > input': {
      background: 'rgba(15, 94, 213, 0.1)',
      boxShadow: 'inset 0px 2px 8px rgb(16 90 211 / 5%)',
      borderRadius: '30px',
      border: 'none',
      padding: '11px 20px',
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '21px',
      color: '#1B2036',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
        lineHeight: '21px',
      },
      '&::-webkit-outer-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&::-moz-inner-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&::-moz-outer-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&:hover': {
        '&::-webkit-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
      },
      '&:focus': {
        '&::-webkit-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
      },
    },
  },

  border: {
    height: '1px',
    display: 'block',
    background: 'rgba(15, 94, 213, 0.1)',
    margin: '10px -15px 10px',
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      margin: '23px -15px 20px',
    },
  },
}));

import { IDrawing, ILeaderboard, IReward, ITimer } from '../common';
import { Line } from '../drawData';
import { IdType, OneOfType } from '../generics';
import { BaseGameState } from '../socketEvents/socketEvents';

export enum VOTE_STATE {
  NONE,
  FORCED,
  FINAL,
}

export enum RESULT_STATE {
  NONE,
  ARTISTS_WON,
  FAKE_WON,
}

export enum FAKE_ARTIST_STATE {
  READY_START_ROUND = 'READY_START_ROUND',
  SPIN = 'SPIN',
  SHOW_ROLE = 'SHOW_ROLE',
  SHOW_WORD = 'SHOW_WORD',
  DRAW = 'DRAW',
  VOTE = 'VOTE',
  VOTE_RESULTS = 'VOTE_RESULTS',
  RESULTS = 'RESULTS',
  TOP_PLAYERS = 'TOP_PLAYERS',
  LEADERBOARD = 'LEADERBOARD',
}

export interface FakeArtistState extends BaseGameState {
  gameState: FAKE_ARTIST_STATE;
  roundNumber: number;
  totalRounds: number;
  subroundNumber?: number;
  totalSubrounds?: number;
  fakeArtistId?: IdType;
  chosenWord?: string;
  activePlayerId?: IdType;
  readyPlayers?: IdType[];
  votedPlayers?: IdType[];
  voteList?: IdType[];
  results?: {
    targetId: IdType;
    votes: IdType[];
  }[];
  voteState?: VOTE_STATE;
  resultState?: RESULT_STATE;
  leaderboard?: ILeaderboard;
  reward?: IReward;
  drawing?: IDrawing;
  timer?: ITimer;
}

import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';
import BackgrounMobile from '@art/images/one-question/mobile-bg.jpg';

export const styles = makeStyles((theme) => ({
  oneQuestion: {
    display: 'flex',
    fontFamily,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    boxSizing: 'border-box',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${BackgrounMobile})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '& *': { boxSizing: 'border-box' },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      overflowY: 'scroll',
      padding: '30px',
      // IE 11
      '-ms-overflow-style': 'none',
      // Firefox
      'scrollbar-width': 'none',
      // Chrome, Safari
      '&::-webkit-scrollbar': {
        display: 'none',
        background: 'transparent',
        width: 0,
      },
    },
  },

  oneQuestionWrapper: {
    display: 'block',
    width: '100%',
    minHeight: '100%',
    padding: '15px',
    overflowY: 'scroll',
    // IE 11
    '-ms-overflow-style': 'none',
    // Firefox
    'scrollbar-width': 'none',
    // Chrome, Safari
    '&::-webkit-scrollbar': {
      display: 'none',
      background: 'transparent',
      width: 0,
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      padding: 0,
      overflowY: 'initial',
      // border: '1px solid yellow',
    },
  },

  oneQuestionDesktopCentered: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    // border: '1px solid white',
    [theme.breakpoints.up('md')]: {
      maxWidth: '640px',
    },
  },
}));

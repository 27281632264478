import { AppDispatch } from '@src/app/store';
import { updateGameState } from '@store/reducers/game.reducer';
import { GUESS_UP_STATE, GuessUpState } from '@src/shared/gameInterfaces/guessUp.socket';

enum LABELS {
  READY = 'Ready',
  ACTIVE = 'Active',
  VOTED = 'Voted',
}

const updateGuessUpLabelsThunk = (game: GuessUpState) => (dispatch: AppDispatch) => {
  const { gameState, votedPlayers, readyPlayers, activePlayerId } = game;

  // ACTIVE
  const activeStates = [
    GUESS_UP_STATE.READY_START_ROUND,
    GUESS_UP_STATE.SPIN,
    GUESS_UP_STATE.SHOW_WORD,
    GUESS_UP_STATE.RESULTS,
    GUESS_UP_STATE.GAME,
    GUESS_UP_STATE.VOTE_RESULTS,
  ].includes(gameState);

  // READY
  const readyStates = [GUESS_UP_STATE.TOP_PLAYERS, GUESS_UP_STATE.LEADERBOARD].includes(gameState);
  const votedStates = [GUESS_UP_STATE.VOTE].includes(gameState);

  const labels = new Map<string, string>();

  // ACTIVE
  if (activeStates) {
    labels.set(activePlayerId, LABELS.ACTIVE);
  }

  // READY
  if (readyStates && readyPlayers) {
    readyPlayers.forEach((playerId) => labels.set(playerId, LABELS.READY));
  }

  // VOTED
  if (votedStates && votedPlayers) {
    votedPlayers.forEach((playerId) => labels.set(playerId, LABELS.VOTED));
  }
  dispatch(updateGameState({ playerLabels: [...labels.entries()] }));
};

export { updateGuessUpLabelsThunk };

import { useAppSelector } from '@app/hooks';
import { styles } from './styles';
import { roundNumbers } from '@src/common/misc';
import React from 'react';
import coin from '@art/icons/coin_flat.svg';
import { selectBalance } from '@src/store/reducers/player.reducer';

interface IProps {
  onClick: () => void;
}

const Balance: React.FC<IProps> = ({ onClick }) => {
  const classes = styles();
  const balance = useAppSelector(selectBalance);
  const roundedBalance = roundNumbers(balance);

  return (
    <div className={classes.coinContainer} onClick={onClick}>
      <img className={classes.coin} src={coin} alt="coin" />
      {roundedBalance}
    </div>
  );
};

export { Balance };

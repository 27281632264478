const GuessMeTextContentEn: React.FC = () => {
  return (
    <>
      <li>
        Active player gets <b>10 points</b> if no one guessed his answer. Otherwise he/she gets <b>0 points</b>.
      </li>
      <li>
        Player (or players) who guessed Active player’s answer correctly get <b>10 points</b> each.
      </li>
      <li>
        The other players get <b>the more</b> points <b>the closer</b> their answer to Active player’s answer.
      </li>
    </>
  );
};

const GuessMeTextContentRu: React.FC = () => {
  return (
    <>
      <li>
        Активный игрок получает <b>10 очков</b>, если никто не угадал его/её ответ. Иначе он/она получает <b>0 очков</b>
        .
      </li>
      <li>
        Игроки, которые угадали ответ активного игрока, получают по <b>10 очков</b>.
      </li>
      <li>
        <b>Чем ближе</b> ответы других игроков к ответу активного игрока, <b>тем больше</b> очков они получают.
      </li>
    </>
  );
};

export { GuessMeTextContentEn, GuessMeTextContentRu };

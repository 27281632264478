import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';

const FakeArtist = () => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <div className={classes.showRole}>
      <div className={`${classes.showFakeArtist} animate__animated  animate__fadeInDown`}>
        <h2>{isEnLocale ? 'Fake' : 'Фэйк'}</h2>
      </div>
    </div>
  );
};

export { FakeArtist };

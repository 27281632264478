import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  questionSubText: {
    display: 'block',
    minHeight: '70px',
    color: '#1B2036',
    fontSize: '17px',
    lineHeight: '28px',
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 0,
    '& > p': {
      marginTop: '5px',
      marginBottom: '5px',
    },
    '& span': {
      color: '#2C74F3',
      overflow: 'hidden',
      display: 'block',
      textOverflow: 'ellipsis',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '29px',
      marginBottom: 0,
      minHeight: '110px',
    },
  },
}));

import { fontFamily } from '@src/art/theme/textStyles';
import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    '& > h3': {
      fontFamily: fontFamily,
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '24px',
      marginTop: 0,
      textAlign: 'center',
      color: '#1B2036',
      marginBottom: '21px',
      maxWidth: '83%',
    },
  },

  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    '& > .send': {
      width: 'auto',
      padding: '7px 22px',
    },
  },

  row: {
    display: 'flex',
    width: '100%',
    alignItems: 'baseline',
    marginBottom: '17px',
    '& .title': {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '17px',
      lineHeight: '17px',
      color: '#1B2036',
    },
    '& .dots': {
      color: 'inherit',
      flexGrow: 1,
      marginLeft: '10px',
      marginRight: '10px',
      borderBottom: '1px dashed #5568AE',
    },
    '& .coin': {
      flexShrink: 0,
      width: '22px',
      height: '22px',
      marginLeft: 3,
      paddingTop: 3,
    },
    '& .value': {
      fontFamily: 'SF Pro Rounded Black',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '23px',
      lineHeight: '17px',
      color: '#FFE250',
      textAlign: 'right',
      textStroke: '1px #985E02',
    },
  },
}));

import { useAppSelector } from '@app/hooks';
import Button from '@components/Button';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

interface Props {
  game: any;
  onChoose: (index: number) => void;
}

const Active: React.FC<Props> = ({ game, onChoose }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <>
      <h2 className={`${classes.wordChooseTitle} mobile-outline`}>
        {isEnLocale ? 'Choose the word to draw' : 'Выбери слово, которое будешь рисовать'}
      </h2>

      <div className={classes.wordChooseContainer}>
        {game.chooseWords?.map((word: { value: number; word: string }, index: number) => {
          const wordValueText = isEnLocale
            ? word.value === 1
              ? 'Point to you and player who guessed correctly'
              : 'Points to you and player who guessed correctly'
            : word.value === 1
            ? 'очко тебе и игроку, который угадает'
            : 'очка тебе и игроку, который угадает';

          return (
            <div key={index} className={classes.wordChooseItem} onClick={() => onChoose(index)}>
              <div className={classes.wordChooseTop}>
                <b>{word.word}</b>

                <Button text={isEnLocale ? 'Choose' : 'Выбрать'} type="primary-blue" onClick={() => onChoose(index)} />
              </div>

              <div className={classes.wordChooseBottom}>
                <b>{word.value}</b>

                <div>
                  <p>{wordValueText}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export { Active };

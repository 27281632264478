import React from 'react';
import lightHeart from '@art/icons/LoveBottle/lightHeart.svg';
import matchedHeart from '@art/icons/LoveBottle/matchHeart.svg';
import { heartListStyles } from '../game.styles';

interface IProps {
  heart: string;
  activeTicketIndex: number;
}

const HeartList: React.FC<IProps> = ({ heart, activeTicketIndex }) => {
  const classes = heartListStyles();

  return (
    <div className={classes.heartList}>
      {[1, 2, 3].map((item, index) => {
        let imgSrc = lightHeart;

        const heartChoice = (heart: string) => {
          switch (heart) {
            case 'RED':
              imgSrc = matchedHeart;
              break;
            case 'BROKEN':
              imgSrc = lightHeart;
              break;
            default:
              imgSrc = lightHeart;
              break;
          }

          return imgSrc;
        };

        if (index < activeTicketIndex!) {
          imgSrc = matchedHeart;
        }

        if (index === activeTicketIndex) {
          heartChoice(heart);
        }

        return <img src={imgSrc} className={classes.imgStyle} key={index} />;
      })}
    </div>
  );
};

export default HeartList;

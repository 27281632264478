import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsHomePageLoading } from '@store/reducers/loadActions';
import { useAppSelector } from '@app/hooks';
import { analytics } from '@services/amplitude';
import { FROM_LOCATIONS } from '@src/common/constants/system';
import { HeroBannerInfo } from '@src/features/banner.logics';
import { BANNER_TYPE } from '@shared/homepage/configSet';
import { Image } from '@components/entities/Image';
import { RoomArea, StartGameArea } from '@components/CreateRoomButton';
import Start from '@art/images/buttons/start/background.svg';
import { WelcomeBannerSkeleton } from '../../parts';
import { styles } from './styles';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import Button from '@src/components/Button';
import { DonateModal } from '@src/components/Modals';

interface SimpleBannerProps {
  bannerInfo: HeroBannerInfo;
  isRoomGameIntro?: boolean;
  onLoaded?: (height: number) => void;
  onClick?: () => void;
}

const SimpleBanner: React.FC<SimpleBannerProps> = (props) => {
  const isHomePageLoading = useSelector(getIsHomePageLoading);
  const classes = styles();
  const bannerRef = useRef<HTMLImageElement>(null);
  const bannerInfo = props.bannerInfo;
  const isGameBanner = bannerInfo.type === BANNER_TYPE.GAME;
  const isDonationBanner = bannerInfo.type === BANNER_TYPE.DONATION;
  const [bannerLoaded, setBannerLoaded] = useState(false);
  const [isDonateModalOpen, setIsDonateModalOpen] = useState(false);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const onLoaded = () => {
    setBannerLoaded(true);
    props.onLoaded ? props.onLoaded(bannerRef.current?.height || 0) : null;
  };

  const onAnalyticsClick = () => {
    analytics.logEvent(analytics.EVENTS.BANNER_CLICKED, { type: bannerInfo.type, bannerId: bannerInfo.id });
  };

  const onBannerClick = (onClick?: () => void) => {
    onAnalyticsClick();
    isDonationBanner ? setIsDonateModalOpen(true) : onClick?.apply(null);
  };

  const renderBanner = (onClick: () => void) => (
    <div className={classes.root} onClick={() => onBannerClick(onClick)}>
      <Image
        className={bannerLoaded ? classes.welcomeBanner : classes.welcomeBannerSkeleton}
        srcSet={bannerInfo.image?.srcSet}
        alt={'Welcome banner'}
        ref={bannerRef}
        onLoad={onLoaded}
      />

      {(isGameBanner || isDonationBanner) && (
        <>
          <Button
            text={isDonationBanner ? (isEnLocale ? 'Donate' : 'Донат') : isEnLocale ? 'Start' : 'Старт'}
            type={'primary-green-new'}
            className={classes.buttonStart}
          />
        </>
      )}
    </div>
  );

  const subpath = bannerInfo.id ? `/lobby/experience/${bannerInfo.id}` : '';

  return (
    <>
      {!isHomePageLoading ? (
        props.isRoomGameIntro ? (
          <StartGameArea gameType={bannerInfo.gameType!} onClick={onBannerClick}>
            {renderBanner}
          </StartGameArea>
        ) : (
          <RoomArea subpath={subpath} automatic onClick={onAnalyticsClick} from={FROM_LOCATIONS.BANNER} hash={'banner'}>
            {renderBanner}
          </RoomArea>
        )
      ) : (
        <WelcomeBannerSkeleton />
      )}
      {<DonateModal isOpen={isDonateModalOpen} handleClose={() => setIsDonateModalOpen(false)} />}
    </>
  );
};

export default SimpleBanner;

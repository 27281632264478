import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';
import matchMeBackground from '@art/images/match-me/match-me-bg.jpg';

export const styles = makeStyles((theme) => ({
  matchMe: {
    fontFamily,
    display: 'flex',
    padding: '15px',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    color: 'inherit',
    backgroundColor: 'rgba(0,0,0, 0.05)',
    boxSizing: 'border-box',
    '& *': {
      boxSizing: 'border-box',
    },
    [theme.breakpoints.up('md')]: {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center bottom',
      backgroundColor: 'white',
      backgroundImage: `url(${matchMeBackground})`,
    },
    '&.results': {
      backgroundColor: 'white',
    },
  },

  matchMeWrapper: {
    height: '100%',
    flexGrow: 1,
    color: 'inherit',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden',
      maxWidth: '680px',
      padding: '30px',
      height: '500px',
      borderRadius: '20px',
      backgroundColor: '#F3F4F5',
    },
  },
}));

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  canvasDrawApiWrapper: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '550px',
      maxHeight: '440px',
    },
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden',
      maxWidth: '625px',
      maxHeight: '500px',
      flexGrow: 0,
    },
    '&.isActivePlayer': {
      ['@media (min-width:1300px)']: {
        overflow: 'hidden',
        borderRadius: '0px 20px 20px 0px',
      },
    },
  },
}));

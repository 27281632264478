import React, { MouseEventHandler } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { getRoomLink, selectIsEndGamePopup, selectIsRoomPublic } from '@store/room/roomSlice';
import { selectIsFirstPlayer } from '@store/room/roomSlice';
import Popover from '@material-ui/core/Popover/Popover';
import { styles } from './styles';
import { copyLinkToClipboard, useChangeRoomPublicity } from '@common/misc';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { NOTIFICATION_TYPE } from '@src/store/room/notifications';
import { pushNotification } from '@src/store/room/roomFeaturesData.slice';

interface Props {
  id: string | any;
  isMenuOpen: boolean;
  anchorEl: HTMLElement | null;
  playerInLobby: Boolean;
  setAnchorEl: MouseEventHandler | any;
  setModalType: (modalType: string) => MouseEventHandler | any;
  handleCloseMenu: MouseEventHandler;
}

const Menu: React.FC<Props> = ({
  id,
  isMenuOpen,
  anchorEl,
  playerInLobby,
  setModalType,
  setAnchorEl,
  handleCloseMenu,
}) => {
  const dispatch = useAppDispatch();
  const classes = styles();
  const showEndGamePopup = useAppSelector(selectIsEndGamePopup);
  const roomLink = useAppSelector(getRoomLink);
  const isRoomOwner = useAppSelector(selectIsFirstPlayer);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const isPublic = useAppSelector(selectIsRoomPublic);
  const { onChangeRoomPublicity } = useChangeRoomPublicity(isPublic);

  const showMenuBtnClass = (item: { disabled: Boolean; borderTop: Boolean }) => {
    return `${classes.popOverItem} ${item.borderTop ? 'borderTop' : ''}  ${item.disabled ? 'disabled' : ''}`;
  };

  const onClickLink = (modalType: string | '') => {
    setAnchorEl(null);
    setModalType(modalType);
  };

  const onClickRoomPublicity = () => {
    onChangeRoomPublicity();
    setAnchorEl(null);
  };

  const lobbyMenu = [
    {
      title: isEnLocale ? 'Copy room link' : 'Скопировать ссылку на комнату',
      onClick: () => {
        onClickLink('copy');
        dispatch(copyLinkToClipboard(roomLink, 'MENU'));
        dispatch(pushNotification({ type: NOTIFICATION_TYPE.COPY_LINK }));
      },
    },
    {
      title: isEnLocale ? 'Room preferences' : 'Параметры комнаты',
      owner: true,
      onClick: () => onClickLink('roomPreferences'),
    },
    // {
    //   // owner: true,
    //   title: isEnLocale ? 'Change my name' : 'Изменить имя',
    //   onClick: () => onClickLink('rename'),
    // },
    {
      title: isEnLocale ? 'Exit the room' : 'Выйти из комнаты',
      onClick: () => onClickLink('leave'),
    },
  ];

  const gameMenu = [
    {
      title: isEnLocale ? 'Copy room link' : 'Скопировать ссылку на комнату',
      onClick: () => {
        onClickLink('copy');
        dispatch(copyLinkToClipboard(roomLink, 'MENU'));
        dispatch(pushNotification({ type: NOTIFICATION_TYPE.COPY_LINK }));
      },
    },
    // {
    //   title: isEnLocale ? 'Change my name' : 'Изменить имя',
    //   onClick: () => onClickLink('rename'),
    // },
    // {
    //   title: isEnLocale ? 'Change background' : 'Изменить фон',
    //   owner: true,
    //   onClick: () => onClickLink('background'),
    // },
    {
      title: isEnLocale ? 'Room preferences' : 'Параметры комнаты',
      owner: true,
      onClick: () => onClickLink('roomPreferences'),
    },
    {
      title: isEnLocale ? 'Rules' : 'Правила',
      borderTop: true,
      onClick: () => onClickLink('rules'),
    },
    {
      title: showEndGamePopup
        ? isEnLocale
          ? 'End game'
          : 'Закончить игру'
        : isEnLocale
        ? 'Exit the room'
        : 'Выйти из комнаты',
      onClick: () => onClickLink('leave'),
    },
  ];

  return (
    <Popover
      id={id}
      open={isMenuOpen}
      anchorEl={anchorEl}
      onClose={handleCloseMenu}
      className={classes.popOver}
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <div className={classes.popOverContainer}>
        {playerInLobby
          ? // player in lobby
            lobbyMenu.map((link: any) => {
              // strange logic but works
              if (link.owner !== !isRoomOwner) {
                return (
                  <div className={showMenuBtnClass(link)} key={link.title} onClick={link.onClick}>
                    <div className={classes.popOverText}>{link.title}</div>
                  </div>
                );
              }
            })
          : // player in game
            gameMenu.map((link: any) => {
              // strange logic but works
              if (link.owner !== !isRoomOwner) {
                return (
                  <div className={showMenuBtnClass(link)} key={link.title} onClick={link.onClick}>
                    <div className={classes.popOverText}>{link.title}</div>
                  </div>
                );
              }
            })}
      </div>
    </Popover>
  );
};

export default Menu;

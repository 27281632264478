import { Card, CardActionArea, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { defaultImages } from '@src/art/resources/loadResources';
import { getAllGames } from '@store/reducers/common';
import { Image } from './Image';
import { useStyles, roomCardSettings } from './styles/roomCard.style';
import { IActiveRoom } from '@shared/misc/room.fragment';
import { RoomArea } from '../CreateRoomButton';
import { analytics } from '@src/services/amplitude';
import { FROM_LOCATIONS } from '@src/common/constants/system';
import { useAppSelector } from '@src/app/hooks';
import { ROOM_STATE } from '@src/store/room/roomSlice';

interface IProps {
  roomData: IActiveRoom;
  style?: any;
}

const RoomCard: React.FC<IProps> = (props) => {
  const games = useAppSelector(getAllGames);
  const { state, gameId, icon, id, title } = props.roomData;
  const currentGame = games.find((game) => game.id === gameId);

  const history = useHistory();
  const classes = useStyles();

  // const onClick = () => history.push(`/room/${id}`);
  const subpath = ``;
  const onAnalyticsClick = () => {
    analytics.logEvent(analytics.EVENTS.PLAYER_JOINED_ROOM, { roomId: id, from: FROM_LOCATIONS.ROOM });
  };

  const roomCardIcon = icon || defaultImages.roomIcon;
  const roomStatusText = state === ROOM_STATE.LOBBY ? 'In Lobby' : 'In Progress';

  const renderRoomCard = (onClick: () => void) => {
    return (
      <Card className={classes.root} style={props.style} onClick={onClick}>
        <Image src={roomCardIcon} className={classes.roomIcon} alt={`${title} room icon`} />
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.gameType}>{currentGame?.title || roomStatusText}</Typography>
      </Card>
    );
  };

  return (
    <RoomArea
      subpath={subpath}
      automatic={false}
      onClick={onAnalyticsClick}
      from={FROM_LOCATIONS.ROOM}
      roomId={id}
      hash={'roomcard'}
    >
      {renderRoomCard}
    </RoomArea>
  );
};

export { RoomCard, roomCardSettings };

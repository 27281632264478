import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  wordShow: {
    width: '100%',
    minHeight: '100%',
    flexGrow: 1,
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#1B2036',
    padding: '15px',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
    [theme.breakpoints.up('md')]: {
      padding: '30px',
    },
  },
}));

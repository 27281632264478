import React, { Ref, useState, useEffect, useRef, useCallback } from 'react';
import { useAppSelector } from '@app/hooks';
import { Observable } from '@apollo/client';
import { Line } from '@src/features/canvas-draw/types';
import { FakeArtistState, VOTE_STATE } from '@shared/gameInterfaces/fakeArtist.socket';
import { useMediaQuery } from '@material-ui/core';
import { CanvasDrawApi } from '@src/features/canvas-draw/components/CanvasDrawApi';
import { MobileHeader, DesktopMenuContent, RoundAlert, TimeAlert, VoteModal } from './parts';
import { DesktopMenu, MobileMenu, MobilePalette, CanvasDraw, CanvasWrapper } from '@src/games/common/Drawing';
import { styles } from './styles';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { DrawEvent } from '@src/shared/drawData';

interface Props {
  game: FakeArtistState;
  isFakeArtist: boolean;
  activePlayer: any;
  isActivePlayer: boolean;
  isSpectator: boolean;
  isVote: boolean;
  isVoteResults: boolean;
  actions: {
    onNext: () => void;
    onSkip: () => void;
    onVote: (votePlayerId: string) => void;
    onVotingStart: () => void;
    onGameStateRequest: (totalLines: number) => void;
    onDrawUpdate: ((data: DrawEvent) => void) | undefined;
    onLiveUpdate: ((data: DrawEvent) => void) | undefined;
  };
  eventObserver: Observable<DrawEvent> | undefined;
}

const Draw: React.FC<Props> = ({
  game,
  isFakeArtist,
  isActivePlayer,
  isSpectator,
  activePlayer,
  isVote,
  isVoteResults,
  actions,
  eventObserver,
}) => {
  const classes = styles();
  const { onSkip, onVote, onNext, onVotingStart, onGameStateRequest, onDrawUpdate, onLiveUpdate } = actions;
  const [votedId, setVotedId] = useState<string>('');
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const canvasApi: Ref<CanvasDrawApi> = useRef(null);
  const [menuItem, setMenuItem] = useState('pen');
  const [brushRadius, setBrushRadius] = useState(4);
  const [brushColor, setBrushColor] = useState('#1E1E1E');
  // const [isButtonClicked, setButtonClicked] = useState<boolean>(false);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const onLiveDraw = (line: Line) => {
    if (onLiveUpdate) {
      onLiveUpdate({
        liveUpdate: { line: line },
      });
    }
  };

  const onCompleteLine = (line: ArrayBufferLike) => {
    if (onDrawUpdate) {
      onDrawUpdate({
        completeLine: { line: line },
      });
    }
  };

  useEffect(() => {
    // reset button clicked (votedId) in new subround
    setVotedId('');
    // setButtonClicked(false);
  }, [game.subroundNumber]);

  // onSkipClick
  const onSkipClick = () => {
    setVotedId('skip');
    onSkip();
  };

  // onVoteClick
  const onVoteClick = (votePlayerId: string) => {
    onVote(votePlayerId);
    setVotedId(votePlayerId);
  };

  // onNextClick
  // const onNextClick = () => {
  //   // setButtonClicked(true);
  //   onNext();
  // };

  useEffect(() => {
    if (game.drawing) {
      canvasApi.current?.setProtobufLines(game.drawing.lines);
    }
  }, [game.drawing, canvasApi.current]);

  useEffect(() => {
    console.log('CanvasEngine load with observer', eventObserver, isActivePlayer, canvasApi);

    if (!eventObserver || isActivePlayer || !canvasApi.current) {
      return;
    }

    const subscription = eventObserver.subscribe((event: DrawEvent) => {
      if (event.liveUpdate) {
        canvasApi.current?.updateLiveDraw(event.liveUpdate.line);
      } else if (event.completeLine) {
        const lines = canvasApi.current?.addProtobufLine(event.completeLine.line);
        canvasApi.current?.finishLiveDraw();
        if (event.completeLine.totalLines != lines?.length) {
          onGameStateRequest(lines?.length || 0);
        }
      } else if (event.lines) {
        canvasApi.current?.setProtobufLines(event.lines.lines);
      } else if (event.clear) {
        canvasApi.current?.clear();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [isActivePlayer, eventObserver, canvasApi.current]);

  // clear all canvas
  const onClear = useCallback(() => {
    canvasApi.current!!.clear();

    if (onDrawUpdate) {
      onDrawUpdate({ clear: {} });
    }
  }, []);

  return (
    <div className={classes.draw}>
      {/* MobileHeader */}
      {!isDesktop && (
        <MobileHeader
          game={game}
          onNext={onNext}
          isSpectator={isSpectator}
          isFakeArtist={isFakeArtist}
          onVotingStart={onVotingStart}
          isActivePlayer={isActivePlayer}
          activePlayerName={activePlayer.name}
          isSkipVoting={Boolean(game.voteState)}
          isVotingStatuses={isVote || isVoteResults}
        />
      )}

      {/* DesktopMenu */}
      {isDesktop && (
        <DesktopMenu
          onClear={onClear}
          menuItem={menuItem}
          penWidth={brushRadius}
          brushColor={brushColor}
          setMenuItem={setMenuItem}
          isActivePlayer={isActivePlayer}
          onChangePen={(width) => setBrushRadius(width)}
          onChangeColor={(color) => setBrushColor(color)}
        >
          <DesktopMenuContent
            game={game}
            isEnLocale={isEnLocale}
            isActivePlayer={isActivePlayer}
            isSpectator={isSpectator}
            isFakeArtist={isFakeArtist}
            activePlayerName={activePlayer.name}
            isVotingStatuses={isVote || isVoteResults}
            onNext={onNext}
            onVotingStart={onVotingStart}
          />
        </DesktopMenu>
      )}

      {/* canvas-wrapper */}
      <CanvasWrapper isActivePlayer={isActivePlayer} timeIsUp={game.timer?.timeIsUp}>
        {/* time-alert */}
        {game.timer?.timeIsUp && <TimeAlert showAlert={game.timer?.timeIsUp} />}

        {/* RoundAlert */}
        <RoundAlert
          game={game}
          isFakeArtist={isFakeArtist}
          isActivePlayer={isActivePlayer}
          activePlayerName={activePlayer.name}
        />

        {/* canvas */}
        <CanvasDraw
          menuItem={menuItem}
          canvasApi={canvasApi}
          brushColor={brushColor}
          brushRadius={brushRadius}
          onLiveUpdate={onLiveDraw}
          onLineFinish={onCompleteLine}
          isActivePlayer={isActivePlayer}
        />

        {/* mobile draw controls */}
        {!isDesktop && isActivePlayer && (
          <>
            {/* mobile-menu */}
            <MobileMenu
              menuItem={menuItem}
              brushColor={brushColor}
              setMenuItem={setMenuItem}
              setBrushColor={setBrushColor}
            />

            {/* mobile-palette */}
            <MobilePalette
              menuItem={menuItem}
              penWidth={brushRadius}
              brushColor={brushColor}
              setMenuItem={setMenuItem}
              onChangePen={(width) => setBrushRadius(width)}
              onChangeColor={(color) => setBrushColor(color)}
            />
          </>
        )}
      </CanvasWrapper>

      {/* modal */}
      {!isSpectator && (
        <VoteModal
          game={game}
          votedId={votedId}
          onSkipClick={onSkipClick}
          onVoteClick={onVoteClick}
          isVoteResults={isVoteResults}
          isOpen={isVote || isVoteResults}
        />
      )}
    </div>
  );
};

export { Draw };

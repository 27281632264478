import { useState } from 'react';
import { styles } from './styles';
import SpinButton from '@art/images/guess-up/spin.svg';
import SpinnerBackground from '@art/images/guess-up/spinner_background.svg';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { useAppSelector } from '@app/hooks';
import { GuessUpState } from '@src/shared/gameInterfaces/guessUp.socket';

interface Props {
  game: GuessUpState;
  isSpin: boolean;
  isActivePlayer: boolean;
  onSpin: () => void;
}

const StartRound: React.FC<Props> = ({ game, isSpin, onSpin, isActivePlayer }) => {
  const classes = styles();

  const [isButtonClicked, setButtonClicked] = useState(false);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const onSpinClick = () => {
    setButtonClicked(true);
    onSpin();
  };

  const { roundNumber, totalRounds } = game;

  const showTitleEn = roundNumber === totalRounds ? 'Final round' : `Round ${roundNumber} of ${totalRounds}`;
  const showTitleRu = roundNumber === totalRounds ? 'Финальный раунд' : `Раунд ${roundNumber} из ${totalRounds}`;

  return (
    <div className={`${classes.startWrapper} animate__animated  animate__fadeInDown`}>
      <h2 className={`${classes.startTitle} mobile-outline`}>{isEnLocale ? showTitleEn : showTitleRu}</h2>

      <div className={classes.spinWrapper}>
        {/* circle */}
        <img
          className={classes.raptorImg}
          src={SpinnerBackground}
          style={{ animationName: isSpin ? 'rotating-360' : '' }}
          alt="background"
        />

        {/* spin button */}
        {isActivePlayer && (
          <img
            src={SpinButton}
            onClick={onSpinClick}
            className={`${classes.spinButton} ${isButtonClicked ? 'clicked' : ''}`}
            alt="spin-button"
          />
        )}
      </div>
    </div>
  );
};

export { StartRound };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  questionWrapper: {
    position: 'relative',
    width: '100%',
    minHeight: '100%',
    justifyContent: 'space-between',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
  },

  questionInner: {
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    width: '100%',
    minHeight: '100%',
    position: 'relative',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '620px',
      margin: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'none',
    },
  },

  timerWrapper: {
    position: 'relative',
    height: '40px',
    width: '100%',
    marginTop: '-15px',
    marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '-30px',
      marginBottom: '10px',
      height: '50px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '15px',
      marginBottom: 0,
    },

    // fix timer on mobile
    '& > div': {
      position: 'fixed',
      zIndex: 10,
      width: 'calc(100% - 30px)', // minus 15 + 15 paddings
      [theme.breakpoints.up('sm')]: {
        width: 'calc(100% - 60px)', // minus 30 + 30 paddings
      },
      [theme.breakpoints.up('md')]: {
        position: 'static',
        width: '100%',
      },
    },
  },

  questionText: {
    textAlign: 'center',
    marginBottom: '10px',
    flexGrow: 1,
    wordBreak: 'break-word',
    '& > p': {
      whiteSpace: 'pre-wrap',
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 500,
      color: '#1B2036',
      marginTop: 0,
      marginBottom: 0,
      alignItems: 'center',
      display: 'flex',
      alignSelf: 'center',
      borderRadius: '20px',
      backgroundColor: 'white',
      padding: '8px 15px',
      '&::-webkit-scrollbar': {
        display: 'none',
        background: 'transparent',
        width: 0,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
        padding: '10px 15px',
      },
      [theme.breakpoints.up('md')]: {
        padding: 0,
        borderRadius: 0,
        backgroundColor: 'transparent',
      },
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: '100%',
      marginBottom: '55px',
      justifyContent: 'center',
    },
  },

  btnContainer: {
    boxSizing: 'border-box',
    width: '100%',
    marginTop: 'auto',
    padding: '15px',
    backgroundColor: 'white',
    borderRadius: '20px',
    '& > div': {
      flexShrink: 1,
    },
    '&.clicked > div': {
      pointerEvents: 'none',
      cursor: 'default',
      boxSizing: 'border-box',
      '&.not-clicked-btn': {
        backgroundColor: 'transparent',
        color: '#BBBCC3',
        '&:hover': {
          backgroundColor: 'inherit',
        },
        '&:active': {
          backgroundColor: 'inherit',
        },
      },
    },

    [theme.breakpoints.up('md')]: {
      padding: 0,
      backgroundColor: 'transparent',
      borderRadius: 0,
    },
  },

  voteBtn: {
    display: 'inline-flex',
    padding: '0 15px',
    flexGrow: 0,
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '48px',
    backgroundColor: '#2C74F3',
    color: 'white',
    borderRadius: '4px',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    marginRight: '15px',
    transitionProperty: 'background-color',
    transitionDuration: '.23s',
    cursor: 'pointer',

    '& > span': {
      textAlign: 'center',
      display: 'inline-flex',
      maxHeight: '40px',
      overflow: 'hidden',
      wordBreak: 'break-word',
    },

    '&.clicked-btn': {
      backgroundColor: '#D7E4FD',
      color: '#1B2036',
      '&:hover': {
        backgroundColor: 'inherit',
      },
      '&:active': {
        backgroundColor: 'inherit',
      },
    },
    '&:hover': {
      backgroundColor: '#356FD6',
    },
    '&:active': {
      backgroundColor: '#3662AE',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

import { palleteColors } from '@src/games/common/Drawing/palleteColors';
import { styles } from './styles';

type Props = {
  brushColor: string;
  setMenuItem: (item: string) => void;
  onChangeColor: (color: string) => void;
};

const PalleteList: React.FC<Props> = ({ brushColor, setMenuItem, onChangeColor }) => {
  const classes = styles();

  return (
    <section className={classes.palleteList}>
      <ul className={classes.palleteListColors}>
        {palleteColors.map((color: string, index: number) => (
          <li
            key={index}
            className={`${brushColor === color ? 'active' : ''}`}
            onClick={() => {
              setMenuItem('pen');
              onChangeColor(color);
            }}
          >
            <div
              style={{
                backgroundColor: color,
                border: color === '#FFFFFF' ? '1px solid #ddd' : '1px solid transparent',
              }}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export { PalleteList };

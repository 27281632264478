import { AppDispatch } from '@src/app/store';
import { QUIZ_BATTLE_ROYALE_STATE } from '@shared/gameInterfaces/battleRoyale.socket';
import { updateGameState } from '@store/reducers/game.reducer';
import { IGameState } from './quizBattle.types';

enum LABELS {
  VOTED = 'Voted',
  READY = 'Ready',
  ACTIVE = 'Active',
}

const updateQuizBattleLabelsThunk = (game: IGameState) => (dispatch: AppDispatch) => {
  const { gameState, votedPlayers, readyPlayers } = game;

  // VOTED
  const voteStates = [QUIZ_BATTLE_ROYALE_STATE.ROUND_IN_PROGRESS].includes(gameState);

  // ACTIVE
  const activeStates = [QUIZ_BATTLE_ROYALE_STATE.READY_START_ROUND].includes(gameState);

  // READY
  const readyStates = [QUIZ_BATTLE_ROYALE_STATE.ROUND_RESULTS, QUIZ_BATTLE_ROYALE_STATE.LOOP_RESULTS].includes(
    gameState
  );

  const labels = new Map<string, string>();

  // ACTIVE
  if (activeStates) {
    labels.set(game.activePlayerId, LABELS.ACTIVE);
  }

  // VOTED
  if (voteStates) {
    votedPlayers!.forEach((playerId) => labels.set(playerId, LABELS.VOTED));
  }

  // READY
  if (readyStates) {
    readyPlayers!.forEach((playerId) => labels.set(playerId, LABELS.READY));
  }

  dispatch(updateGameState({ playerLabels: [...labels.entries()] }));
};

export { updateQuizBattleLabelsThunk };

import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import CommonModal from '@components/Modals/CommonModal';
import Button from '@components/Button';
import { styles } from './styles';

type MetamaskPopupProps = {
  isOpen: boolean;
  handleClose: () => void;
};

const ChangeBlockChainPopup: React.FC<MetamaskPopupProps> = ({ isOpen, handleClose }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} backgroundColor={'white'}>
      <div className={classes.metaMaskPopup}>
        <h2>
          {isEnLocale ? 'Hello Token works on Binance Smart Chain' : 'Hello Token работает на Binance Smart Chain'}
        </h2>

        <div className={classes.currentBlockchain}>
          <span>{isEnLocale ? 'Your current blockchain' : 'Ваш текущий блокчейн'}</span>
          <b>Solana</b>
        </div>

        <Button
          href="https://metamask.io/download/"
          text={
            isEnLocale ? 'How to add Binance Smart Chain to MetaMask' : 'Как добавить Binance Smart Chain в MetaMask'
          }
          onClick={handleClose}
        />
      </div>
    </CommonModal>
  );
};

export default ChangeBlockChainPopup;

import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { useAppSelector } from '@src/app/hooks';
import { selectSolanaToken } from '@src/store/reducers/common';
import { createContext, useContext, useEffect, useState } from 'react';
import { getTokenAccountAddress, getTokenBalance } from './engine/misc';

interface IBalance {
  balance: number;
  tokenBalance: number;
  requestBalance: () => void;
}

const BalanceContext = createContext<IBalance>({ balance: 0, tokenBalance: 0, requestBalance: () => {} });

export default function useWalletBalance() {
  const context = useContext(BalanceContext);
  return { ...context };
}

export const WalletBalanceProvider: React.FC = ({ children }) => {
  const wallet = useWallet();
  const connectionContext = useConnection();
  const connection = connectionContext.connection;
  const token = useAppSelector(selectSolanaToken);
  const [balance, setBalance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [shouldRequestBalance, setRequestBalance] = useState({});

  useEffect(() => {
    (async () => {
      if (wallet?.publicKey) {
        const balance = await connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, connection, shouldRequestBalance]);

  useEffect(() => {
    (async () => {
      if (wallet?.publicKey && token) {
        const balance = await getTokenBalance(connection, token, wallet.publicKey);
        setTokenBalance(balance);
      }
    })();
  }, [wallet, connection, token, shouldRequestBalance]);

  const requestBalance = () => setRequestBalance({});

  const value = { balance, tokenBalance, requestBalance };

  return <BalanceContext.Provider value={value}>{children}</BalanceContext.Provider>;
};

import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  landingPage3: {
    fontFamily,
    height: '100%',
    width: '100%',
    display: 'block',
    minHeight: '100%',
    minWidth: '100%',
    boxSizing: 'border-box',
    // overflowY: 'scroll',
    overflow: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
      background: 'transparent',
      width: 0,
    },
    '& *': { boxSizing: 'border-box' },
  },

  landingPage3Wrapper: {
    height: '100%',
    width: '100%',
    minHeight: '100%',
    minWidth: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  questionText: {
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: '10px',
    maxWidth: '315px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '55px',
      maxWidth: '600px',
    },
    '& > p': {
      whiteSpace: 'pre-wrap',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 500,
      color: '#1B2036',
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
      },
    },
  },

  singlePage: {
    fontFamily,
    display: 'flex',
    minHeight: '100%',
    minWidth: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundImage: 'linear-gradient(180deg, #EBF6FF 0%, #ACD8FD 98.44%)',
    boxSizing: 'border-box',
    '& *': { boxSizing: 'border-box' },
    [theme.breakpoints.up('md')]: {
      overflowY: 'initial',
      padding: '15px',
    },
  },

  singlePageWrapper: {
    flexGrow: 1,
    width: '100%',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    [theme.breakpoints.up('md')]: {
      flexGrow: 0,
      maxWidth: '975px',
      flexBasis: '500px',
      borderRadius: '20px',
      backgroundColor: 'white',
    },
  },
}));

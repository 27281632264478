import { GameTypes } from '@src/common/constants/common';
import { Leaderboard } from '@src/games/common/Leaderboard/Leaderboard';
import { useGameHook } from './helpers/fakeArtist.hook';
import GameWrapper from '@components/GameWrapper';
import { RESULT_STATE } from '@shared/gameInterfaces/fakeArtist.socket';

import { StartRound, ShowRole, ShowWord, Draw, Results } from './parts';
import PartyFinishModal from '../common/PartyFinishModal';

interface Props {
  roomId: string;
}

const FakeArtist: React.FC<Props> = ({ roomId }) => {
  const {
    // 1 - status
    status,

    // 2 - game
    game,

    // 3 - isActivePlayer
    isActivePlayer,

    // 4 - activePlayer
    activePlayer,

    // 5 - isRoomOwner
    isRoomOwner,

    // 6 - isFakeArtist
    isFakeArtist,

    // 7 - fakeArtistName
    fakeArtistName,

    // 8 - actions
    actions,

    // 9 - eventObserver
    eventObserver,

    // 10 - isSpectator
    isSpectator,
  } = useGameHook(roomId);

  const hasDrawCanvas = status.DRAW || status.VOTE || status.VOTE_RESULTS;
  const padding = hasDrawCanvas ? 0 : '';

  const onLiderboardClick = () => {
    status.TOP_PLAYERS ? actions.onReady() : actions.onQuit();
  };

  return (
    <GameWrapper state={game.state} style={{ padding: `${padding}` }} hasDrawCanvas={hasDrawCanvas}>
      {/* READY_START_ROUND || SPIN */}
      {(status.READY_START_ROUND || status.SPIN) && (
        <StartRound game={game} isSpin={status.SPIN} onSpin={actions.onSpin} isActivePlayer={isRoomOwner} />
      )}

      {/* SHOW_ROLE */}
      {status.SHOW_ROLE && <ShowRole isFakeArtist={isFakeArtist} />}

      {/* SHOW_WORD */}
      {status.SHOW_WORD && <ShowWord chosenWord={game.chosenWord} isFakeArtist={isFakeArtist} />}

      {/* DRAW || VOTE || VOTE_RESULTS */}
      {(status.DRAW || status.VOTE || status.VOTE_RESULTS) && (
        <Draw
          game={game}
          actions={actions}
          isVote={status.VOTE}
          isSpectator={isSpectator}
          eventObserver={eventObserver}
          activePlayer={activePlayer}
          isFakeArtist={isFakeArtist}
          isActivePlayer={isActivePlayer}
          isVoteResults={status.VOTE_RESULTS}
        />
      )}

      {/* RESULTS */}
      {status.RESULTS && (
        <Results
          chosenWord={game.chosenWord}
          fakeArtistName={fakeArtistName}
          hasFakeArtistWon={game.resultState === RESULT_STATE.FAKE_WON}
        />
      )}

      {/* TOP_PLAYERS || LEADERBOARD */}
      {(status.TOP_PLAYERS || status.LEADERBOARD) && (
        <Leaderboard
          game={game}
          onClick={onLiderboardClick}
          isSpectator={isSpectator}
          isTopPlayers={status.TOP_PLAYERS}
          gameType={GameTypes.FAKE_ARTIST}
          roomId={roomId}
        />
      )}

      {game.reward && <PartyFinishModal isOpen={true} game={game} />}
    </GameWrapper>
  );
};

export { FakeArtist };

import { useAppSelector } from '@app/hooks';
import { IRoomPlayer, selectRoomPlayers } from '@store/room/roomSlice';
import GrinningFace from '@art/images/grinning-face-with-sweat.png';
import { styles } from './styles';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { GuessUpState } from '@src/shared/gameInterfaces/guessUp.socket';

interface Props {
  game: GuessUpState;
  activePlayer: IRoomPlayer;
}

const RightAnswer: React.FC<Props> = ({ game, activePlayer }) => {
  const classes = styles();
  const { word, hasWon } = game;
  const players = useAppSelector(selectRoomPlayers);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <div className={classes.rightAnswerWrapper}>
      <div
        className={`
        ${classes.rightAnswer} animate__animated  animate__fadeIn
        ${hasWon ? 'winner' : 'no-one'}
      `}
      >
        {!hasWon && <img className={classes.smile} src={GrinningFace} alt="WinnerImg" />}

        <h2 className="text-overflow">{activePlayer.name}</h2>

        <p>
          {hasWon
            ? isEnLocale
              ? 'Guessed Correctly'
              : 'Отгадал/а'
            : isEnLocale
            ? 'Did not Guess Correctly'
            : 'не отгадал/a'}
        </p>

        <div className={classes.word}>{word} </div>
      </div>
    </div>
  );
};

export { RightAnswer };

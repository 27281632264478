import React, { MouseEventHandler } from 'react';
import TextInput from '@components/Inputs/TextInput';
import { CommonModal } from '@components/Modals';
import Button from '@components/Button';
import { styles } from './styles';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';

interface Props {
  isOpen: boolean | undefined;
  handleClose: MouseEventHandler;
}

const EditRoomModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} backgroundColor={'white'}>
      <div className={classes.wrapper}>
        <h3 id="transition-modal-title">{isEnLocale ? 'Edit room' : 'Редактировать комнату'}</h3>
        <form className={classes.root} noValidate autoComplete="off">
          <TextInput
            id="room-name"
            label={isEnLocale ? 'Room name' : 'Название комнаты'}
            variant="outlined"
            type="text"
          />
        </form>

        <div className={classes.btnContainer}>
          <Button type="secondary" text={isEnLocale ? 'Cancel' : 'Закрыть'} onClick={handleClose} />
          <Button text={isEnLocale ? 'Save' : 'Сохранить'} onClick={handleClose} type="primary-blue-new" />
        </div>
      </div>
    </CommonModal>
  );
};

export default EditRoomModal;

import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  coinContainer: {
    boxSizing: 'border-box',
    borderRadius: '22px',
    padding: '3px 11px 3px 22px',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '17px',
    lineHeight: '17px',
    position: 'relative',
    color: '#FFFFFF',
    textStroke: '1px #1E1E1E',
    fontFamily: 'SF Pro Rounded Black',
    background: '#FCF1B2',
    border: '1px solid #FABE1C',
    marginRight: '20px',
    cursor: 'pointer',
  },

  coin: {
    width: '33px',
    height: '31px',
    position: 'absolute',
    left: '-12px',
    top: '-3px',
  },
}));

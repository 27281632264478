import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as BackArrow } from '@art/icons/backArrow.svg';
import { IconButton } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';
import { useAppSelector } from '@src/app/hooks';
import { getDefaultPath } from '@src/store/room/roomSlice';
import { MixProps } from '@src/common/generics';

interface Props {
  onClick?: () => void;
}

export const useGoBack = () => {
  const history = useHistory();
  const defaultPath = useAppSelector(getDefaultPath);

  return {
    goBack: () => {
      history.push(defaultPath);
    },
  };
};

const BackButton: React.FC<MixProps<Props>> = ({ onClick, children }) => {
  const classes = styles();
  const { goBack } = useGoBack();

  const onButtonClick = () => {
    onClick ? onClick() : goBack();
  };

  return (
    <IconButton onClick={onButtonClick} className={classes.btnBack}>
      <BackArrow title="back button" style={{ marginRight: '10px' }} />
      {children}
    </IconButton>
  );
};

export default BackButton;

const styles = makeStyles((theme) => ({
  btnBack: {
    background: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    padding: '8px 15px',
    border: 0,
    outline: 'none',
    cursor: 'pointer',
    transition: '0.2s ease-out',
    fontFamily: fontFamily,
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '17px',
    color: '#FFFFFF',
    width: 'fit-content',
    height: '40px',
    '&:hover': {
      transform: 'scale(1.01)',
      background: 'rgba(0, 0, 0, 0.3)',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '13px 25px',
    },
  },
}));

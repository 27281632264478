import React, { useEffect, useRef, useState } from 'react';
import { Line } from '../types';
import { calcMidPoint } from '../helpers';
import { useCanvasDrawContext } from './CanvasDrawContext';

import { styles } from './styles';

export type CanvasDrawProps = {
  isTemp: boolean;
  lines?: Line[];
  liveDrawLine?: Line;
};

const drawPoints: (ctx: CanvasRenderingContext2D, line: Line, widthRatio: number, heightRatio: number) => void = (
  ctx,
  line,
  widthRatio,
  heightRatio
) => {
  if (line.x.length === 0) {
    console.warn('Trying to draw with points.length = 0');
    return;
  }

  ctx.lineJoin = 'round';
  ctx.lineCap = 'round';
  ctx.strokeStyle = line.brushColor;
  ctx.lineWidth = line.brushRadius * 2;

  let x = line.x[0];
  let y = line.y[0];
  ctx.beginPath();
  ctx.moveTo(x * widthRatio, y * heightRatio);
  for (let i = 1, len = line.x.length; i < len; i++) {
    const x2 = line.x[i];
    const y2 = line.y[i];
    const midPoint = calcMidPoint(x, y, x2, y2);
    ctx.quadraticCurveTo(x * widthRatio, y * heightRatio, midPoint.x * widthRatio, midPoint.y * heightRatio);
    x = x2;
    y = y2;
  }
  ctx.lineTo((x + 0.01) * widthRatio, y * heightRatio);
  ctx.stroke();
};

interface CanvasSize {
  width?: number;
  height?: number;
}

const CanvasDraw: React.FC<CanvasDrawProps> = ({ isTemp, lines = [], liveDrawLine }) => {
  const classes = styles();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const context = useCanvasDrawContext();
  const [canvasSize, setCanvasSize] = useState<CanvasSize>({ width: undefined, height: undefined });

  function drawLine(line: Line) {
    const ctx = canvasRef.current?.getContext('2d');
    if (!ctx) return;

    if (isTemp) {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    }
    const widthRatio = ctx.canvas.width / line.canvasWidth;
    const heightRatio = ctx.canvas.height / line.canvasHeight;

    drawPoints(ctx, line, widthRatio, heightRatio);
  }

  function clearRect() {
    const ctx = canvasRef.current?.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    }
  }

  useEffect(() => {
    if (canvasRef.current) {
      context.subscribeToResize(canvasRef.current, (width: number, height: number) => {
        console.debug('Draw resized lines!');
        setCanvasSize({ width, height });
      });

      return () => context.unsubscribeToResize(canvasRef.current!);
    }

    /*if (!isTemp)
      context.addLinesUpdateListener((line) => {
        window.requestAnimationFrame(() => {
          drawLine(line);
        });
      });
    }*/
  }, [context, canvasRef.current]);

  useEffect(() => {
    if (isTemp) {
      const onLiveDraw = (line: Line) =>
        window.requestAnimationFrame(() => {
          drawLine(line);
        });
      const onLineFinished = (line: Line) => clearRect();

      context.addPointsUpdateListener(onLiveDraw);
      context.addLinesUpdateListener(onLineFinished);
      return () => {
        context.unsubscribePointsUpdate(onLiveDraw);
        context.unsubscribeLinesUpdate(onLineFinished);
      };
    }
  }, [context]);

  useEffect(() => {
    if (isTemp) return;

    const onLineFinished = (line: Line) => {
      window.requestAnimationFrame(() => {
        drawLine(line);
      });
    };

    context.addLinesUpdateListener(onLineFinished);
    return () => context.unsubscribeLinesUpdate(onLineFinished);
  }, [context]);

  useEffect(() => {
    if (isTemp) return;

    window.requestAnimationFrame(() => {
      clearRect();
      for (const line of lines) {
        drawLine(line);
      }
    });
  }, [lines, canvasSize]);

  useEffect(() => {
    if (!isTemp) return;

    window.requestAnimationFrame(() => {
      liveDrawLine ? drawLine(liveDrawLine) : clearRect();
    });
  }, [liveDrawLine]);

  /*useEffect(() => {
    console.log('CANVAS DRAW USE EFFECT UPDATE', liveDrawLine);
    if (isTemp) return;

    window.requestAnimationFrame(() => {

      if (liveDrawLine) {
        drawLine(liveDrawLine);
      } else if (liveDrawLine === undefined) {
        clearRect();
      }

      for (const line of lines) {
        drawLine(line);
      }
    });
  }, [lines, liveDrawLine, canvasSize]);*/

  return <canvas ref={canvasRef} className={classes.canvas} />;
};

export default CanvasDraw;

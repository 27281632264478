import { useAppDispatch, useAppSelector } from '@app/hooks';
import { GameTypes } from '@src/common/constants/common';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { selectPlayerId } from '@store/reducers/player.reducer';
import QuestionButton from '@components/QuestionButton';
import { NextButton } from '../NextButton/NextButton';
import { AddEmptyRows } from './AddEmptyRows';
import { Chest } from './Chest';
import { styles } from './styles';
import { goldMedal, silverMedal, bronzeMedal } from './getImages';
import { useEffect } from 'react';
import { analytics } from '@src/services/amplitude';
import { ILeaderboardItem } from '@src/shared/common';
import { requestRewards } from '@src/graphql/player.queries';

interface IProps {
  game: any; //!
  onClick: () => void;
  isSpectator: boolean;
  isTopPlayers: boolean;
  gameType: GameTypes;
  shouldDrink?: boolean;
  roomId: string;
}

const Leaderboard: React.FC<IProps> = ({ isSpectator, onClick, isTopPlayers, game, gameType, shouldDrink, roomId }) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const { roundNumber, totalRounds, leaderboard } = game;
  const playerId = useAppSelector(selectPlayerId);

  useEffect(() => {
    if (!isTopPlayers) {
      dispatch(requestRewards);
      analytics.logEvent(analytics.EVENTS.PLAYER_FINISHED_GAME, { roomId, playerId, gameType });
    }
  }, [isTopPlayers]);

  const isEnLocale = useAppSelector(selectIsEnLocale);
  const showTitleEn = roundNumber === totalRounds ? 'Final Leaderboard' : shouldDrink ? 'Top drinkers' : `Top players`;
  const showTitleRu =
    roundNumber === totalRounds ? 'Финальная таблица лидеров' : shouldDrink ? 'Самые пьющие' : `Лучшие игроки`;

  return (
    <div className={`${classes.resultsWrapper} animate__animated  animate__fadeIn`}>
      <h2 className="mobile-outline">{isEnLocale ? showTitleEn : showTitleRu}</h2>

      <div className={`${classes.leaderboardTable} animate__animated  animate__fadeIn`}>
        {leaderboard!.map((player: ILeaderboardItem, index: number) => {
          // isTopPlayers
          if (isTopPlayers) {
            return (
              <div key={index} className={classes.leaderboardTableRow}>
                <b className={classes.leaderboardTableRowIndex}>{player.place}</b>
                <span className={classes.leaderboardTableRowName}>{player.name}</span>
                <b className={classes.leaderboardTableRowPoints}>{player.value}</b>
              </div>
            );
          }

          // isLeaderboard
          if (!isTopPlayers) {
            return (
              <div key={index} className={classes.leaderboardTableRow}>
                {player.place === 1 && <img className={classes.resultsMedalImage} src={goldMedal} alt="goldMedal" />}
                {player.place === 2 && (
                  <img className={classes.resultsMedalImage} src={silverMedal} alt="silverMedal" />
                )}
                {player.place === 3 && (
                  <img className={classes.resultsMedalImage} src={bronzeMedal} alt="bronzeMedal" />
                )}
                <b className={classes.leaderboardTableRowIndex}>{player.place}</b>
                <span className={classes.leaderboardTableRowName}>{player.name}</span>
                <b className={classes.leaderboardTableRowPoints}>{player.value}</b>

                {/* chest image */}
                <Chest place={player.place} index={index} />
              </div>
            );
          }
        })}

        <AddEmptyRows itemsArrayLength={leaderboard!.length} roundResults={false} />
      </div>

      <div className={classes.buttonWrapper}>
        <NextButton isSpectator={isSpectator} onClick={onClick} isTopPlayers={isTopPlayers} />
        <QuestionButton gray={true} gameType={gameType} />
      </div>
    </div>
  );
};

export { Leaderboard };

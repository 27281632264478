import React from 'react';

// import { useAppDispatch } from '@src/app/hooks';
// import { showInviteThunk } from '@common/misc';
import { useAppSelector } from '@src/app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';

import getImages from './getImages';

import StartButtonEng from '@art/images/buttons/start/start-en.png';
import StartButtonRus from '@art/images/buttons/start/start-ru.png';

// import InviteButtonEng from '@art/images/buttons/invite/invite-en.png';
// import InviteButtonRus from '@art/images/buttons/invite/invite-ru.png';

import { styles } from './styles';

interface IProps {
  gameType: string;
  isActivePlayer: boolean;
  onSpin: () => void;
}

const SingleStartRound: React.FC<IProps> = ({ gameType, onSpin, isActivePlayer }) => {
  const classes = styles();

  // images src and animations names
  const logoImage = getImages(gameType)?.logo;
  const backgroundImage = getImages(gameType)?.background;
  const infoLabel = getImages(gameType)?.infoLabel;
  const isEnLocale = useAppSelector(selectIsEnLocale);
  // const dispatch = useAppDispatch();

  // const onInviteClick = () => {
  //   dispatch(showInviteThunk('TOOLTIP'));
  // };

  return (
    <div
      className={`${classes.singleStartRound} animate__animated  animate__fadeIn`}
      style={{ backgroundImage: `url(${backgroundImage?.src})` }}
    >
      <div className={classes.content}>
        {/* spacer */}
        <div className={classes.spacer} />

        {/* logo */}
        <img className={`${classes.logo} ${logoImage?.className}`} src={logoImage?.src} alt="logoImage" />

        {/* spacer */}
        <div className={classes.spacer} />

        {/* buttonsContainer */}
        {isActivePlayer && (
          <div className={classes.buttonsContainer}>
            {/* start */}
            <div
              className={classes.buttonStart}
              style={{ backgroundImage: `url(${isEnLocale ? StartButtonEng : StartButtonRus})` }}
              onClick={() => onSpin()}
            />

            {/* invite */}
            {/* <div
              className={classes.buttonInvite}
              onClick={onInviteClick}
              style={{ backgroundImage: `url(${isEnLocale ? InviteButtonEng : InviteButtonRus})`}}
            /> */}
          </div>
        )}
      </div>

      {infoLabel && <div className={classes.infoLabel} style={{ backgroundImage: `url(${infoLabel?.src})` }} />}
    </div>
  );
};

export { SingleStartRound };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  desktopMenuHeader: {
    width: '100%',
    flexDirection: 'column',
  },

  showFakeArtistInfo: {
    width: '100%',
    minHeight: '125px',
    padding: '10px',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    backgroundColor: 'white',
    border: '10px solid #305088',
    marginBottom: '20px',
    '& > p': {
      color: '#1B2036',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '24px',
    },
  },

  choosenWord: {
    width: '100%',
    minHeight: '125px',
    padding: '10px',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    backgroundColor: 'white',
    border: '10px solid #F8CA61',
    marginBottom: '20px',
    '& > b': {
      display: 'inline-flex',
      maxWidth: '270px',
      wordBreak: 'break-word',
      color: '#1B2036',
      fontWeight: 900,
      lineHeight: 1.2,
      textTransform: 'uppercase',
    },
  },

  buttonsContainer: {
    width: '100%',
  },

  desktopMenuSpacer: {
    position: 'relative',
    flexGrow: 1,
    marginTop: '5%',
    marginBottom: '5%',
  },
}));

import {
  GoldChestClose,
  GoldChestOpen,
  MagicChestClose,
  MagicChestOpen,
  SkyChestClose,
  SkyChestOpen,
  WoodChestClose,
  WoodChestOpen,
} from './chests.images';

const chestArray = [
  [10, WoodChestOpen, WoodChestClose] as const,
  [50, GoldChestOpen, GoldChestClose] as const,
  [100, MagicChestOpen, MagicChestClose] as const,
  [Infinity, SkyChestOpen, SkyChestClose] as const,
];

export const getChestImages = (stakeAmount: number) => {
  const data = chestArray.find((data) => stakeAmount <= data[0])!;
  return { open: data[1], close: data[2] };
};

import { styles } from './styles';
import { IAnswers } from '@pages/Landings/LandingPage2';
import { useMediaQuery } from '@material-ui/core';
import YouWinImg from '@art/images/landing/you-win.png';
import NextTimeImg from '@art/images/landing/next-time.png';

interface IProps {
  value: number;
  screen: string;
  isRight: boolean;
  questionText: string;
  answers: IAnswers[];
  resultsClick: (value: string) => void;
}

const Results: React.FC<IProps> = ({ value, answers, isRight, questionText, screen, resultsClick }) => {
  const classes = styles();
  const isMobile = useMediaQuery('(max-width: 599px)');

  return (
    <div className={classes.results}>
      {/* questionText */}
      <div className={`${classes.questionText} animate__animated animate__fadeInDown`}>{questionText}</div>

      {/* results spacer */}
      {screen === 'results' && <div className={classes.spacer} />}

      {/* image */}
      <div
        style={{ height: screen === 'final' ? '0px' : isMobile ? '224px' : '392px' }}
        className={`
          ${classes.imageContainer}
          animate__animated animate__fadeIn
          ${screen === 'final' ? 'animate__fadeOut' : ''}
        `}
      >
        {isRight ? <img src={YouWinImg} alt="YouWinImg" /> : <img src={NextTimeImg} alt="NextTimeImg" />}
      </div>

      {/* results and final spacer */}
      <div className={classes.spacer} />

      {/* buttonContainer */}
      <div
        className={`
          ${classes.answersContainer}
          answers-${answers.length}
          animate__animated animate__fadeInUp
      `}
      >
        {answers.length
          ? answers.map((answer: IAnswers, index: number) => {
              return (
                <div
                  key={index}
                  className={`
                    ${classes.answer} ${value === index + 1 ? 'active' : ''}
                    ${value && answer.right ? 'right' : 'wrong'}
                  `}
                >
                  {/* <b>{`${answer.percents}%`}</b> */}
                  <span>{answer.text}</span>
                </div>
              );
            })
          : null}
      </div>

      {/* final spacer */}
      {screen === 'final' && <div className={classes.spacer} />}

      {screen === 'final' && (
        <div className={`${classes.buttonsContainer} animate__animated animate__fadeInUp`}>
          <div className={classes.button} onClick={() => resultsClick('friends')}>
            <span>Send question to Friends</span>
          </div>

          <div className={classes.button} onClick={() => resultsClick('homepage')}>
            <span>Next Question</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Results;

import { makeStyles } from '@material-ui/core';
import ScrollDownButtonImg from '@art/icons/scroll-down-button.png';

export const styles = makeStyles((theme) => ({
  scrollToBottom: {
    position: 'fixed',
    zIndex: 10,
    left: '50%',
    bottom: '58px',
    marginLeft: '-28px',
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundImage: `url(${ScrollDownButtonImg})`,
    '&::selection': {
      background: 'transparent',
    },
  },
}));

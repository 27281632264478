import WSClient from '@src/sockets';
import { useEffect } from 'react';
import { useGameProvider } from './FiveSeconds.provider';
import { CLIENT_EVENTS } from '@shared/socketEvents/eventTypes';
import { sendRoundStarted } from '@services/analyticsThunk';
import { useAppDispatch } from '@src/app/hooks';
import { GameTypes } from '@src/common/constants/common';
import { updateFiveSecondsLabelsThunk } from './FiveSeconds.labels';

const useGameHook = (roomId: string) => {
  const dispatch = useAppDispatch();

  const [
    state,
    game,
    playerId,
    activePlayer,
    isActivePlayer,
    isSpectator,
    isRoundPlayers,
    storeActions,
  ] = useGameProvider(roomId);

  const activeGameId = game.activeGameId;
  const socketData = {
    roomId,
    activeGameId,
    playerId,
    gameType: GameTypes.FIVE_SECONDS,
  };

  useEffect(() => {
    dispatch(updateFiveSecondsLabelsThunk(game));
  }, [state]);

  useEffect(() => {
    if (state.READY_START_ROUND) {
      dispatch(sendRoundStarted(game.roundNumber.toString()));
    }
  }, [state.READY_START_ROUND]);

  return {
    state,
    game,
    playerId,
    activePlayer,
    isActivePlayer,
    actions: {
      onSpin: () => {
        WSClient.emit(CLIENT_EVENTS.ROUND_STARTED, socketData);
      },

      onVote: (value: boolean) => {
        storeActions.setVote(value);
        WSClient.emit(CLIENT_EVENTS.PLAYER_VOTED, {
          roomId,
          activeGameId: game.activeGameId,
          playerId,
          value,
        });
      },

      onVotingStart: () => {
        WSClient.emit(CLIENT_EVENTS.PLAYER_STARTED_VOTE, {
          roomId,
          activeGameId: game.activeGameId,
          playerId,
        });
      },

      onReady: () => {
        storeActions.setReady();
        WSClient.emit(CLIENT_EVENTS.PLAYER_READY, {
          roomId,
          activeGameId: game.activeGameId,
          playerId,
        });
      },

      onQuit: () => {
        WSClient.emit(CLIENT_EVENTS.PLAYER_READY_TO_QUIT_GAME, {
          roomId,
          activeGameId: game.activeGameId,
          playerId,
        });
      },
    },
    isSpectator,
    isRoundPlayers,
  };
};

type HookType = ReturnType<typeof useGameHook>;
type ActionsType = HookType['actions'];

export type { ActionsType };

export { useGameHook };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  question: {
    display: 'flex',
    minHeight: '100%',
    minWidth: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundImage: 'linear-gradient(180deg, #EBF6FF 0%, #ACD8FD 98.44%)',
    boxSizing: 'border-box',
    '& *': { boxSizing: 'border-box' },
    [theme.breakpoints.up('md')]: {
      overflowY: 'initial',
      padding: '30px',
    },
  },

  questionWrapper: {
    flexGrow: 1,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    padding: '15px',
    [theme.breakpoints.up('md')]: {
      padding: '30px',
      flexGrow: 0,
      maxWidth: '975px',
      // flexBasis: '500px',
      borderRadius: '20px',
      backgroundColor: 'white',
    },
  },

  questionText: {
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: '10px',
    maxWidth: '315px',
    flexGrow: 1,
    flexBasis: '100%',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '55px',
      maxWidth: '600px',
    },
    '& > p': {
      whiteSpace: 'pre-wrap',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 500,
      color: '#1B2036',
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
      },
    },

    '&.large': {
      maxWidth: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '100%',
      },
      '& > p': {
        fontSize: '16px',
        lineHeight: '21px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '21px',
          lineHeight: '27px',
        },
      },
    },
  },

  btnContainer: {
    boxSizing: 'border-box',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '620px',
    },
    '& > div': {
      flexShrink: 1,
    },
    '&.clicked > div': {
      pointerEvents: 'none',
      cursor: 'default',
      boxSizing: 'border-box',
      '&.not-clicked-btn': {
        backgroundColor: 'transparent',
        color: '#BBBCC3',
        '&:hover': {
          backgroundColor: 'inherit',
        },
        '&:active': {
          backgroundColor: 'inherit',
        },
      },
    },
  },

  voteBtn: {
    display: 'inline-flex',
    padding: '0 15px',
    flexGrow: 0,
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '48px',
    backgroundColor: '#2C74F3',
    color: 'white',
    borderRadius: '4px',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '22px',
    marginRight: '5px',
    transitionProperty: 'background-color',
    transitionDuration: '.23s',
    cursor: 'pointer',

    '& > span': {
      textAlign: 'center',
      display: 'inline-flex',
      maxHeight: '40px',
      overflow: 'hidden',
      wordBreak: 'break-word',
    },

    '&:hover': {
      backgroundColor: '#356FD6',
    },
    '&:active': {
      backgroundColor: '#3662AE',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  header: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '29px',
    textAlign: 'center',
    color: '#5568AE',
    display: 'flex',
    margin: 'auto',
    marginTop: '15px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '20px',
    },
  },
  avatarsContainer: {
    height: '100%',
    alignItems: 'center',
    marginTop: '3px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    display: 'grid',
    gap: '15px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(65px, 75px))',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(75px, 95px))',
    },
  },

  subtitle: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#5568AE',
    display: 'flex',
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginTop: '8px',
      fontSize: '16px',
    },
  },
  avatar: {
    display: 'flex',
    cursor: 'pointer',
    transition: '0.1s ease-out',
    width: '100%',
    height: '100%',
    borderRadius: '4px',
  },

  check: {
    position: 'absolute',
    zIndex: 2,
    transform: 'translate(-50%, -50%)',
    top: '48%',
    left: '48%',
    width: '21px',
    height: '21px',
  },

  background: {
    background: 'rgba(255, 255, 255, 0.45)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    border: '2px solid #2C74F3',
    borderRadius: '4px',
  },
}));

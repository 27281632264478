import { useState } from 'react';
import { Catenary } from 'catenary-curve';
import { LazyBrush } from 'lazy-brush';
import { hasResizeObserver } from '@common/compatibility';

export const useCatenary = () => {
  const [catenary] = useState(() => {
    return new Catenary();
  });

  return catenary;
};

export const useResizeObserver = (callback: ResizeObserverCallback) => {
  const [resizeObserver] = useState(() => {
    return hasResizeObserver() ? new ResizeObserver(callback) : null;
  });
  return resizeObserver;
};

export const useLazyBrush = (lazyBrushRadius: number) => {
  const [lazyBrush] = useState<LazyBrush>(() => {
    return new LazyBrush({
      radius: lazyBrushRadius * window.devicePixelRatio,
    });
  });

  return lazyBrush;
};

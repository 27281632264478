import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  questsContainer: {
    display: 'block',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
      display: 'none',
    },
  },
  title: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '29px',
    textAlign: 'center',
    color: '#1E1E1E',
    padding: '15px',
    alignContent: 'center',
    justifyContent: 'center',
  },
  subtitle: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '17px',
    textAlign: 'center',
    color: '#5568AE',
    marginTop: '-15px',
    alignContent: 'center',
    justifyContent: 'center',
    maxWidth: '340px',
  },
  questWrapper: {
    border: '1px solid rgba(15, 94, 213, 0.1)',
    boxShadow: 'inset 0px 2px 8px rgba(16, 90, 211, 0.05)',
    padding: '8px 15px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    '& div > button': {
      width: '100% !important',
    },
  },
  wrapper: {
    flexDirection: 'column',
    alignItems: 'start',
    marginRight: '10px',
    width: '100%',
    display: 'block',
    '& .questTitle': {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '17px',
      color: '#1B2036',
      marginBottom: '15px',
      textAlign: 'left',
      maxWidth: '255px',
      width: '100%',
      display: 'block',
    },
    '& .progress': {
      background: 'rgba(15, 94, 213, 0.1)',
      boxShadow: 'inset 0px 2px 8px rgba(16, 90, 211, 0.05)',
      borderRadius: '100px',
      maxWidth: '255px',
      width: '100%',
      height: '18px',
      display: 'flex',
      justifyContent: 'start',
      fontFamily: 'SF Pro Rounded Black',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '12px',
      lineHeight: '17px',
      textAlign: 'center',
      color: '#FFFFFF',
      textStroke: '1px #1E1E1E',
      position: 'relative',
      '& .fill': {
        background: 'linear-gradient(180deg, #9FEE3A 0%, #43AD17 100%)',
        mixBlendMode: 'normal',
        boxShadow: 'inset 0px 2px 8px rgba(16, 90, 211, 0.05)',
        borderRadius: '100px',
        height: '100%',
        transition: 'all 1s ease-out 0s',
      },
      '& .value': {
        fontFamily: 'SF Pro Rounded Black',
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: '12px',
        lineHeight: '17px',
        textAlign: 'center',
        color: '#FFFFFF',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
  },
  rewards: {
    width: '100%',
    minWidth: '95px',
    marginBottom: '3px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '& > p': {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '20px',
      textAlign: 'center',
      color: '#5568AE',
    },
    '& > .energy': {
      '& > img': {
        width: '18px',
        height: '18px',
        marginRight: '2px',
      },
      '& > p': {
        fontFamily: 'SF Pro Rounded Black',
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: '15px',
        lineHeight: '17px',
        textAlign: 'center',
        color: '#FFFFFF',
        textStroke: '1px #1E1E1E',
      },
    },
    '& > .coins': {
      '& > img': {
        width: '16px',
        height: '15px',
        marginRight: '2px',
      },
      '& > p': {
        fontFamily: 'SF Pro Rounded Black',
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: '12px',
        lineHeight: '17px',
        textAlign: 'center',
        color: '#FFFFFF',
        textStroke: '1px #1E1E1E',
        marginRight: '5px',
      },
    },
    '&.notification': {
      transform: 'scale(1.3)',
      // justifyContent: 'initial',
      marginBottom: '0px',
      marginLeft: '12px',
      alignSelf: 'center',
      minWidth: '60px',
      width: '90px',
    },
  },
  timer: {
    fontFamily: 'SF Pro Rounded Black',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '24px',
    lineHeight: '29px',
    textAlign: 'center',
    color: '#FFFFFF',
    textStroke: '1px #1E1E1E',
  },
  button: {
    height: '28px !important',
    display: 'flex',
    '&[disabled]': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      backgroundColor: 'transparent !important',
      minWidth: '95px',
      padding: '5px 10px',
      height: '28px !important',
      border: '1px solid #2C74F3',
      borderRadius: '100px',
      transition: 'ease-out 0.2s',
      boxShadow: 'none',
      '& > span': {
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '16px',
        color: '#2C74F3',
        textDecoration: 'none',
        textShadow: 'none',
        textStroke: 'transparent',
        fontFamily: fontFamily,
      },
      '&:hover, &:focus': {
        backgroundColor: '#356FD6',
        color: 'white',
      },
    },
  },
  receivedQuest: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'block',
    background: 'rgba(189, 221, 254, 0.6)',
    top: 0,
    left: 0,
    zIndex: 1,
    boxShadow: 'inset 0px 2px 8px rgba(16, 90, 211, 0.05)',
  },
}));

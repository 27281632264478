import { useAppSelector } from '@src/app/hooks';
import { IRoomPlayer } from '@src/store/room/roomSlice';
import React from 'react';
import { styles } from './styles';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import Button from '@src/components/Button';
import { IPlayerCard } from '@src/store/reducers/common';
import WSClient from '@src/sockets';
import { CLIENT_EVENTS } from '@src/shared/socketEvents/eventTypes';
import { selectBalance, selectPlayerName } from '@src/store/reducers/player.reducer';
import { analytics } from '@src/services/amplitude';

interface IProps {
  setIsOpenSendModal: (isOpen: boolean) => void;
  chosenFriend: IPlayerCard;
}

const SendModal: React.FC<IProps> = ({ setIsOpenSendModal, chosenFriend }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const balance = useAppSelector(selectBalance);
  const playerName = useAppSelector(selectPlayerName);
  const [value, setValue] = React.useState<number>(0);

  const handleClose = () => setIsOpenSendModal(false);

  const handleSubmit = () => {
    const data = {
      targetId: chosenFriend.playerId,
      amount: value,
      name: playerName,
      isDonation: chosenFriend.isStreamer,
    };
    WSClient.emit(CLIENT_EVENTS.PLAYER_SENT_MONEY, data);
    analytics.logEvent(analytics.EVENTS.MONEY_SENT, data);
    handleClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setValue(parseInt(event.target.value) || 0);

  return (
    <div className={classes.sendModalContainer}>
      <p className={'title'}>
        {isEnLocale ? `Send tokens to ${chosenFriend?.name}` : `Отправить токены ${chosenFriend?.name}`}
      </p>
      <div className={classes.formInner}>
        <div className={classes.customInput}>
          <input
            onChange={handleChange}
            value={value.toString()}
            id="value"
            name="value"
            type="number"
            style={{ border: `1px solid ${value > balance ? '#F83E67' : 'transparent'}` }}
            autoComplete="off"
            maxLength={8}
            inputMode="numeric"
            // placeholder="0"
          />
          <p className={'currency'}>HIT</p>
        </div>

        <div className={classes.btnContainer}>
          <Button type="primary-red-new" text={isEnLocale ? 'Cancel' : 'Закрыть'} onClick={handleClose} />
          <Button
            text={isEnLocale ? 'Send' : 'Отправить'}
            buttonType="submit"
            type="primary-blue-new"
            disabled={value <= 0 || value > balance}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default SendModal;

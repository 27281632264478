import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';
import CrownIcon from '@art/icons/crown.svg';

export const gameStyles = makeStyles((theme) => ({
  gameContainer: {
    display: 'flex',
    width: '100%',
    minHeight: '100%',
    flexGrow: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export const loveBottleQuestionStyles = makeStyles((theme) => ({
  questionCardWhiteContainer: {
    backgroundColor: 'white',
    flexDirection: 'column',
    padding: '20px',
    borderRadius: '20px',
    width: '100%',
    marginTop: '10px',
    marginBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      padding: '20px 30px',
    },
    [theme.breakpoints.up('md')]: {
      padding: 0,
      borderRadius: 0,
      backgroundColor: 'transparent',
      marginBottom: '70px',
    },
  },
  questionCardContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('sm')]: { flexGrow: 0 },
    [theme.breakpoints.up('md')]: {
      alignSelf: 'center',
      maxWidth: '620px',
    },
  },

  questionCardText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '17px',
    textAlign: 'center',
    color: '#1B2036',
    marginTop: 0,
    marginBottom: '18px',
    fontFamily: fontFamily,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '36px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },

  questionCardAnswers: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      lineHeight: '16px',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '17px',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '&:disabled': {
      backgroundColor: 'rgba(243, 244, 245, 1)',
      color: 'rgba(95, 99, 114, 1)',
      border: '1px solid #E8E9EB',
    },
  },

  questionCardGifContainer: {
    maxWidth: '100%',
    flexDirection: 'column',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

export const loveBottleSpinStyles = makeStyles((theme) => ({
  spinnerWrapper: { position: 'relative' },

  spinContainer: {
    minHeight: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',

    // For iPhone 5/SE overflow
    ['@media (max-width: 360px)']: { transform: 'scale(0.8)' },
  },

  spinRunning: {
    position: 'relative',
    width: '275px',
    height: '275px',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      height: '450px',
    },
    [theme.breakpoints.up('md')]: {
      width: '375px',
      height: '375px',
    },
  },

  spinText: {
    fontFamily,
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    color: 'white',
    textAlign: 'center',
    marginTop: 0,
    marginBottom: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '33px',
      marginBottom: '20px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '16px',
      color: '#1B2036',
    },
  },

  spinButton: {
    width: '73px',
    height: '56px',
    position: 'absolute',
    topCouples: '50%',
    left: '50%',
    marginTop: '-28px',
    marginLeft: '-36.2px',
    zIndex: 4,
    top: '177px',
    cursor: 'pointer',
    '&.clicked': {
      opacity: 0,
      pointerEvents: 'none',
      '&:hover': { cursor: 'default' },
    },
    [theme.breakpoints.up('sm')]: {
      width: '120px',
      height: '92px',
      top: '270px',
      marginLeft: '-60px',
    },
    [theme.breakpoints.up('md')]: {
      width: '99px',
      height: '76px',
      top: '230px',
      marginLeft: '-49.5px',
    },
    '&:hover': { filter: 'sepia(3)' },
  },
}));

export const gifStyles = makeStyles((theme) => ({
  gifWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    width: '100%',
    flexGrow: 1,
  },

  gifContainer: {
    maxWidth: '345px',
    maxHeight: '300px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '620px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '685px',
    },
  },

  gifText: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#1B2036',
    marginBottom: '8px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '28px',
    lineHeight: '33px',
    marginBottom: '17px',
  },
}));

export const loveBottleLeaderboard = makeStyles((theme) => ({
  resultsWrapper: {
    minHeight: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',

    '& > h2': {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '28px',
      color: 'white',
      marginTop: 0,
      marginBottom: '10px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
        marginBottom: '20px',
      },
      [theme.breakpoints.up('md')]: {
        color: '#1B2036',
        marginBottom: '18px',
      },
    },
  },

  leaderboardTable: {
    flexShrink: 0,
    minWidth: '245px',
    maxWidth: '100%',
    flexDirection: 'column',
    border: '1px solid #E8E9EB',
    borderRadius: '15px',
    marginBottom: '15px',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      minWidth: '260px',
      marginBottom: '30px',
      width: '360px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '55px',
    },
  },

  leaderboardTableRow: {
    position: 'relative',
    minHeight: '45px',
    padding: '10px 5px',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#1B2036',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '15px center',
    backgroundSize: '25px auto',
    '&:nth-child(odd)': {
      backgroundColor: '#FFFFFF',
    },
    '&:nth-child(even)': {
      backgroundColor: 'rgb(249,249,249)',
    },
    [theme.breakpoints.up('sm')]: {
      backgroundSize: '30px auto',
      minHeight: '56px',
      fontSize: '16px',
      lineHeight: '19px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '14px 25px',
    },
  },

  resultsMedalImage: {
    position: 'absolute',
    width: '25px',
    height: '40px',
    left: '25px',
    top: 0,
  },

  resultsTableRowName: {
    width: 88,
    justifyСontent: 'center',
    textOverflow: 'ellipsis',
    display: 'block',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: '130px',
    },
  },

  resultsTableRowPoints: {
    position: 'absolute',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '14px',
    color: 'white',
  },

  heartPoint: {
    // width: 31,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 35px',
  },

  heartContainer: {
    width: '100px',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },

  textWaiting: {
    color: 'rgba(95, 99, 114, 0.7)',
    fontWeight: 500,
    fontSize: '12px',
    // lineHeight: '14px',
    textAlign: 'center',
  },

  questionButton: {
    position: 'fixed',
    right: '20px',
    bottom: '70px',
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
      bottom: '34px',
      right: '34px',
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      right: 5,
      bottom: 5,
    },
  },

  buttonWrapper: {
    justifyContent: 'center',
    position: 'relative',
    width: '100%',

    '& > .questionButton': {
      position: 'absolute',
      top: '10px',
      right: 0,
    },
  },
}));

export const heartListStyles = makeStyles((theme) => ({
  imgStyle: {
    marginBottom: 10,
    width: 35,
  },
  heartList: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 22,
      paddingRight: 22,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
}));

export const matchStatusStyles = makeStyles((theme) => ({
  imgStyle: {
    width: 58,
    height: 'auto',
    marginRight: 10,
    [theme.breakpoints.up('sm')]: { width: 100 },
    [theme.breakpoints.up('md')]: { width: 85 },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '15px',
    [theme.breakpoints.up('md')]: { marginBottom: 80 },
  },
  matchWhiteContainer: {
    backgroundColor: 'white',
    borderRadius: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 18px',
    [theme.breakpoints.up('sm')]: {
      padding: '14px 18px',
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent',
      borderRadius: 0,
      padding: 0,
    },
  },

  matchStatus: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 28,
    lineHeight: '36px',
    textAlign: 'center',
    color: '#1B2036',
  },

  buttonContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '250px',
    minHeight: '60px',
  },

  button: {
    border: '10px solid white',
    height: '56px',
    background: '#2C74F3',
    borderRadius: '30px',
    padding: '10px 0px',
    outline: 'none',
    color: 'white',
    width: 145,
    display: 'flex',
    justifyContent: 'center',
    fontFamily: fontFamily,
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      width: '220px',
      height: '60px',
    },
    [theme.breakpoints.up('md')]: {
      border: 'none',
      height: '40px',
    },
  },

  textWaiting: {
    fontFamily,
    color: 'white',
    fontWeight: 800,
    margin: 0,
    fontSize: '15px',
    lineHeight: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '19px',
    },
    [theme.breakpoints.up('md')]: {
      color: '#5F6372',
      fontSize: '16px',
      lineHeight: '19px',
    },
  },
}));

export const playerContainerStyles = makeStyles((theme) => ({
  personContainer: {
    position: 'relative',
    display: 'flex',
    flexGrow: 0,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#C4C4C4',
    borderRadius: '8px',
    maxWidth: '215px',
    height: '55vw',
    width: '100%',
    boxShadow: 'none',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '270px',
      maxHeight: '360px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '420px',
      maxHeight: '235px',
      height: '17vw',
    },
  },

  playersWhiteContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '20px',
    marginBottom: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '620px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'none',
      padding: 0,
      borderRadius: 0,
    },
  },

  crownIcon: {
    position: 'absolute',
    zIndex: 10,
    top: 0,
    left: 0,
    width: '32px',
    height: '32px',
    backgroundColor: 'rgba(0,0,0, 0.45)',
    backgroundSize: '21px auto',
    backgroundImage: `url(${CrownIcon})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '4px 0px',
    [theme.breakpoints.up('sm')]: {
      width: '44px',
      height: '44px',
      backgroundSize: '28px auto',
    },
  },

  userAnswer: {
    width: '100%',
    padding: '6px 0',
    justifyContent: 'center',
    bottom: 0,
    position: 'absolute',
    zIndex: 5,
    background: 'linear-gradient(77.61deg, #FDCC88 0%, #FCBC85 51.56%, #FC9E8C 100%)',
    [theme.breakpoints.up('sm')]: {
      padding: '9px 0',
    },
  },

  userAnswerText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'center',
    color: '#1B2036',
    fontFamily: fontFamily,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      lineHeight: '19px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
      lineHeight: '17px',
    },
  },
  bigHeart: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    width: 58,
    height: 48,
    zIndex: 5,
    [theme.breakpoints.up('sm')]: {
      width: 85,
      height: 70,
    },
  },
  containerHeart: {
    margin: 'auto',
    width: 35,
    height: 29,
    [theme.breakpoints.up('sm')]: {
      width: 57,
      height: 75,
    },
    [theme.breakpoints.up('md')]: {
      width: 81,
      height: 66,
    },
  },
  pairContainer: {
    transitionDelay: '2s',
    transitionDuration: '0s',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  normalContainer: {
    position: 'relative',
    borderRadius: '4px',
  },
}));

import { fontFamily } from './../../../../art/theme/textStyles';
import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  resultsWrapper: {
    minHeight: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',

    '& > h2': {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '28px',
      color: 'white',
      marginTop: 0,
      marginBottom: '10px',
      textStroke: '1px #1B2036',
      fontFamily: 'SF Pro Rounded',
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
        marginBottom: '20px',
      },
      [theme.breakpoints.up('md')]: {
        color: '#1B2036',
        marginBottom: '18px',
      },
    },
  },

  leaderboardTable: {
    flexShrink: 0,
    minWidth: '245px',
    maxWidth: '90vw',
    flexDirection: 'column',
    // border: '1px solid #E8E9EB',
    marginBottom: '13px',
    background: 'white',
    borderRadius: '15px',
    padding: '0px 15px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '260px',
      marginBottom: '30px',
    },
    // [theme.breakpoints.up('md')]: {
    //   marginBottom: '30px',
    // },
  },

  leaderboardTableRow: {
    position: 'relative',
    minHeight: '50px',
    width: '100%',
    padding: '15px 5px',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#1B2036',
    borderTop: '1px solid #E8E9EB',
    '&:first-child': {
      borderTop: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '25px 5px',
    },
  },

  leaderboardTableRowName: {
    marginRight: 'auto',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 1,
    wordWrap: 'break-word',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '21px',
    color: '#1B2036',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
    },
  },
  leaderboardTableRowSign: {},
  buttonWrapper: {
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    '& > button': {
      width: 'init !important',
    },
  },

  badge: {
    background: '#FFFFFF',
    borderRadius: '10px',
    padding: '10px 15px',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '24px',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      background: 'transparent',
    },
  },
}));

import React, { useEffect, useState } from 'react';
import { selectIsFullRoom } from '@store/room/roomSlice';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import Button from '@components/Button';
import { CommonModal } from '@components/Modals';
import { styles } from './styles';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import helloCoin from '@art/icons/coin_flat.svg';
import { Game } from '@src/graphql/queries';
import CloseCross from '@src/components/CloseCross/CloseCross';
import { useGameModal } from '@src/games/UnityGame/UnityGame.hooks';
import { EnergyEmpty } from '../EmptyNotification/EnergyEmpty';
import { GameTreasuryEmpty } from '../EmptyNotification/GameTreasuryEmpty';
import { UNITY_POPUP_STATE } from '../unityModals.types';
import { analytics } from '@src/services/amplitude';
import { roundNumbers } from '@src/common/misc';

interface Props {
  isOpen: boolean;
  game: Game;
  handleClose: () => void;
  startGame: () => void;
}

const UnityStartModal: React.FC<Props> = ({ isOpen, game, handleClose, startGame }) => {
  const classes = styles();
  const { gameTreasury, friendsBonusPercent, popupState } = useGameModal(game, isOpen);
  const isFullRoom = useAppSelector(selectIsFullRoom);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const [showPopup, setShowPopup] = useState(false);
  const roundedTreasury = roundNumbers(gameTreasury);

  const onStartGame = () => {
    analytics.logEvent(analytics.EVENTS.UNITY_STARTED, { gameId: game.id, state: popupState });
    startGame();
  };

  useEffect(() => {
    setShowPopup(isOpen && popupState !== UNITY_POPUP_STATE.NO_POPUP);

    if (isOpen && popupState === UNITY_POPUP_STATE.NO_POPUP) {
      onStartGame();
    }
  }, [isOpen, popupState]);

  return (
    <CommonModal isOpen={showPopup} handleClose={onStartGame}>
      <div className={classes.wrapper}>
        <h3 id="transition-modal-title">{isEnLocale ? 'Start Round' : 'Старт раунда'}</h3>

        {/*<CloseCross onClick={handleClose} />*/}
        {popupState === UNITY_POPUP_STATE.FULL && (
          <div style={{ flexDirection: 'column', width: '100%' }}>
            <div className={classes.row}>
              <p className={'title'}>{isEnLocale ? 'Game Daily Treasury' : 'Сокровищница'}</p>
              <div className={'dots'} />
              <p className={'value'}>{roundedTreasury}</p>
              <img className={'coin'} src={helloCoin} alt="coin" />
            </div>

            <div className={classes.row}>
              <p className={'title'}>{isEnLocale ? 'Total Friends Bonus' : 'Бонус за друзей'}</p>
              <div className={'dots'} />
              <p className={'value'}>{friendsBonusPercent ? `+${friendsBonusPercent.toFixed()}` : 0}%</p>
            </div>

            {!isFullRoom && (
              <p className={classes.subtitle}>
                {' '}
                {isEnLocale ? 'Invite Friends to increase bonus' : 'Приглашай друзей, чтобы увеличить бонус'}
              </p>
            )}
          </div>
        )}

        {popupState === UNITY_POPUP_STATE.NO_ENERGY && <EnergyEmpty />}

        {popupState === UNITY_POPUP_STATE.NO_TREASURY && <GameTreasuryEmpty />}

        <div className={classes.btnContainer}>
          <Button
            onClick={onStartGame}
            // disabled={ }
            text={isEnLocale ? 'Start' : 'Начать'}
            type={'primary-blue-new'}
            className="send"
          />
        </div>
      </div>
    </CommonModal>
  );
};

export default UnityStartModal;

import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

const TimeAlert: React.FC = () => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <section className={`${classes.timeIsUp} animate__animated  animate__fadeInDown`}>
      <span>{isEnLocale ? 'Time is up!' : 'Время вышло!'}</span>
    </section>
  );
};

export { TimeAlert };

import Skeleton from '@material-ui/lab/Skeleton';

import { styles } from './styles';

const WelcomeBannerSkeleton: React.FC = () => {
  const classes = styles();

  return (
    <div className={classes.skeletonWrapper}>
      <Skeleton className={classes.welcomeBannerSkeleton} variant="rect" />;
    </div>
  );
};

export default WelcomeBannerSkeleton;

// import Typing from 'react-typing-animation';
import { useMediaQuery } from '@material-ui/core';
import dogMobile from '@art/images/landing/dog-mobile.png';
import dogTablet from '@art/images/landing/dog-tablet.png';
import dogDesktop from '@art/images/landing/dog-desktop.png';
import { styles } from './styles';

interface IProps {
  greetingClick: () => void;
  isGreetingClicked: boolean;
}

const Greeting: React.FC<IProps> = ({ greetingClick, isGreetingClicked }) => {
  const classes = styles();
  // const isMobile = useMediaQuery('(max-width: 599px)');
  const isTablet = useMediaQuery('(min-width: 600px)');
  const isDesktop = useMediaQuery('(min-width: 960px)');

  return (
    <div className={classes.greeting}>
      <div className={classes.spacer} />

      {/* questionTextContainer */}
      <div
        className={`
        ${classes.questionTextContainer}
        animate__animated animate__fadeInDown
        ${isGreetingClicked ? 'animate__fadeOutUp' : ''}
      `}
      >
        <div className={classes.name}>Doge</div>

        <p>
          Hi! <br />I have a crazy question for you
          {/* <Typing speed={20} startDelay={1300} hideCursor={true}>
            I have a crazy question for you
          </Typing> */}
        </p>

        <div className={classes.triangle} />
      </div>

      {/* dogContainer */}
      <div
        className={`
        ${classes.dogContainer}
        animate__animated animate__fadeInRight
        ${isGreetingClicked ? 'animate__fadeOut' : ''}
      `}
      >
        <img src={isDesktop ? dogDesktop : isTablet ? dogTablet : dogMobile} className={`${classes.dog}`} />
      </div>

      {/* buttonContainer */}
      <div
        className={`
        ${classes.buttonsContainer}
        animate__animated animate__fadeInUp
        ${isGreetingClicked ? 'animate__fadeOutDown' : ''}
      `}
      >
        <div className={classes.button} onClick={greetingClick}>
          <span>Shoot it</span>
        </div>
      </div>
    </div>
  );
};

export default Greeting;

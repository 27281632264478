import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

const QuizClassicRules: React.FC = () => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();

  return (
    <>
      {isEnLocale ? (
        <ul className={classes.list}>
          <li>An active player spins the roulette and reads a question out loud</li>
          <li>All players answers a question</li>
          <li>Answer questions correctly to get the most points</li>
          <li>The game lasts for 10 rounds</li>
        </ul>
      ) : (
        <ul className={classes.list}>
          <li>Активный игрок крутит рулетку и читает вопрос вслух</li>
          <li>Все игроки отвечают на вопрос</li>
          <li>Отвечай на вопросы правильно, чтобы получить наибольшее количество очков</li>
          <li>Игра длится 10 раундов</li>
        </ul>
      )}
    </>
  );
};

export { QuizClassicRules };

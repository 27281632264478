import React from 'react';
import { RoastBattleState } from '@shared/gameInterfaces/roastBattle.socket';
import { MapState } from 'games/RoastBattle/RoastBattle.types';
import { LineTimer } from '@src/games/common';
import { styles } from './styles';
import { useDeviceSize } from '@src/common/screenSizes';

interface IProps {
  game: RoastBattleState;
  isRoundPlayers: boolean;
  state: MapState;
}

const Question: React.FC<IProps> = ({ game, state, isRoundPlayers }) => {
  const classes = styles();
  const { isDesktop } = useDeviceSize();

  return (
    <>
      {!isDesktop && state.VOTE && !isRoundPlayers && game.timer?.duration && (
        <div className={classes.timerWrapper}>
          <LineTimer timer={game.timer!} isVote={false} />
        </div>
      )}

      <p className={classes.questionCardText}>{game.ticket?.question}</p>
    </>
  );
};

export { Question };

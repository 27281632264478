import { useState } from 'react';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { selectRoomPlayers } from '@store/room/roomSlice';
import { isPlayerReadyToQuit } from '@store/room/roomSlice';
import { RoastBattleState } from '@shared/gameInterfaces/roastBattle.socket';

import Button from '@components/Button';
import Crown from '@art/images/roast-battle/Crown.svg';
import { styles } from './styles';

interface IProps {
  game: RoastBattleState;
  onReady: () => void;
  isSpectator: boolean;
  isRoundPlayers: boolean;
}

const Bars: React.FC<IProps> = ({ game, onReady, isSpectator, isRoundPlayers }) => {
  const { votes } = game;
  const classes = styles();
  const players = useAppSelector(selectRoomPlayers);
  const isButtonClickedAfterReload = useAppSelector(isPlayerReadyToQuit);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const [isButtonClicked, setButtonClicked] = useState(false);

  const onButtonClick = () => {
    setButtonClicked(true);
    onReady();
  };

  const clickedCondition = !isButtonClicked && !isButtonClickedAfterReload && !isSpectator;
  const maxVotes = Math.max(...votes!.map((vote) => vote.players.length));
  const blueColor = '#2C74F3';
  const greyColor = '#D7E4FD';
  const darkColor = '#5F6372';

  return (
    <div className={classes.resultsContainer}>
      <div className={classes.resultsWhiteContainer}>
        {/* results bars */}
        <div
          className={`
            ${classes.resultsBarsContainer}
            ${votes!.length > 2 ? 'more-2' : ''}
          `}
        >
          {votes!.map((vote, index) => {
            return (
              <div key={index} className={`resultsBarsItem ${classes.resultsBarsItem}`}>
                {vote.players.length === maxVotes ? <img className={classes.crown} src={Crown} alt="crown" /> : ''}
                {vote.players.map((playerId, index) => {
                  const passivePlayerName = players.find((player) => player.playerId === playerId)?.name || '@left';

                  return (
                    <div
                      key={index}
                      className={classes.resultsNameItem}
                      style={{
                        backgroundColor: vote.players.length === maxVotes ? blueColor : greyColor,
                        color: vote.players.length === maxVotes ? 'white' : darkColor,
                      }}
                    >
                      {passivePlayerName}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        {/* results titles */}
        <div
          className={`
            ${classes.resultsVotesTitlesContainer}
            ${votes!.length > 2 ? 'more-2' : ''}
          `}
        >
          {votes!.map((vote, index) => {
            const roundPlayerName = players.find((player) => player.playerId === vote.target)?.name || '@left';
            return (
              <div key={index} className={`resultsVoteTitlesItem ${classes.resultsVoteTitlesItem}`}>
                {roundPlayerName}
              </div>
            );
          })}
        </div>
      </div>

      <div className={classes.buttonWrapper}>
        {isRoundPlayers && clickedCondition ? (
          <Button type="primary-blue" text={isEnLocale ? 'Next' : 'Далее'} onClick={onButtonClick} />
        ) : (
          <span className="mobile-outline">
            {isEnLocale ? 'Battle players have to tap `Next`' : 'Выступающие игроки должны нажать `Далее`'}
          </span>
        )}
      </div>
    </div>
  );
};

export { Bars };

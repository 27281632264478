import { styles } from './styles';

type ScrollButtoProps = {
  onClick: () => void;
};

const ScrollButton: React.FC<ScrollButtoProps> = ({ onClick }: ScrollButtoProps) => {
  const classes = styles();

  return <div className={classes.scrollToBottom} onClick={onClick} />;
};

export default ScrollButton;

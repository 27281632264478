import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  threeDots: {
    justifyContent: 'space-between',
    position: 'absolute',
    right: '10px',
    top: '10px',
    zIndex: 4,
    width: '25px',
    height: '7px',
    cursor: 'pointer',
    '& > div': {
      width: '5px',
      height: '5px',
      borderRadius: '50%',
      boxShadow: '0 0 1px 1px #1B2036',
      backgroundColor: 'white',
    },
  },
}));

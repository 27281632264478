import { Dispatch, SetStateAction } from 'react';
import { useAppSelector } from '@app/hooks';
import { IRoomPlayer, selectRoomPlayers } from '@store/room/roomSlice';
import CommonModal from '@components/Modals/CommonModal';
import { styles } from './styles';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import Button from '@src/components/Button';

interface Props {
  isOpen: boolean;
  activePlayer: IRoomPlayer;
  onVote: (value: string | undefined) => void;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const ChooseModal: React.FC<Props> = ({ isOpen, activePlayer, onVote, setModalOpen }) => {
  const classes = styles();
  const players = useAppSelector(selectRoomPlayers);
  const passivePlayers = players.filter((player) => player.playerId !== activePlayer.playerId);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={() => setModalOpen(false)}
      className={classes.chooseModal}
      padding={false}
    >
      <h2>{isEnLocale ? 'Who guessed correctly?' : 'Кто отгадал?'}</h2>
      <div className={passivePlayers.length === 1 ? classes.btnContainerFlex : classes.btnContainerGrid}>
        {passivePlayers.map((player, index) => {
          return (
            <div
              key={index}
              onClick={() => onVote(player.playerId)}
              className={classes.voteBtn}
              style={{ width: passivePlayers.length === 1 ? '50%' : '' }}
            >
              {player.name}
            </div>
          );
        })}
      </div>
      <Button
        onClick={() => onVote(undefined)}
        className={`${classes.none}`}
        text={isEnLocale ? 'No one' : 'Никто'}
        type={'primary-red-new'}
      />
    </CommonModal>
  );
};

export { ChooseModal };

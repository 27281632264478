import { chestPurple, chestRed, chestGreen, chestWood } from './getImages';
import { styles } from './styles';

interface Props {
  place: number;
  index: number;
}

const Chest: React.FC<Props> = ({ place, index }) => {
  const classes = styles();

  return (
    <>
      {/* chests images */}
      {place === 1 && (
        <img
          src={chestPurple}
          alt="chestPurple"
          className={`
            ${classes.chestImage}
            animate__animated
            animate__zoomIn
            animate__delay-${index + 1}s
          `}
        />
      )}

      {place === 2 && (
        <img
          src={chestRed}
          alt="chestRed"
          className={`
            ${classes.chestImage}
            animate__animated
            animate__zoomIn
            animate__delay-${index + 1}s
          `}
        />
      )}

      {place === 3 && (
        <img
          src={chestGreen}
          alt="chestGreen"
          className={`
            ${classes.chestImage}
            animate__animated
            animate__zoomIn
            animate__delay-${index + 1}s
          `}
        />
      )}

      {(place === 4 || place === 5) && (
        <img
          src={chestWood}
          alt="chestWood"
          className={`
            ${classes.smallChestImage}
            animate__animated
            animate__zoomIn
            animate__delay-${index + 1}s
          `}
        />
      )}
    </>
  );
};

export { Chest };

import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  wrapper: {
    color: 'inherit',
    flexDirection: 'column',
    textAlign: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    position: 'relative',
    '& > h3': {
      fontFamily: fontFamily,
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '29px',
      marginTop: 0,
      fontStyle: 'normal',
      textAlign: 'center',
      color: '#5569AE',
      marginBottom: '15px',
    },
    '& > p': {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '17px',
      lineHeight: '20px',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#5569AE',
      margin: '12px 0px',
    },
    '& > img': {
      width: '18px',
      height: '18px',
      position: 'absolute',
      right: 0,
      top: 0,
      cursor: 'pointer',
    },
  },

  content: {
    background: '#F5F3DF',
    border: '2px solid #FEAA4D',
    borderRadius: '10px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    justifyContent: 'center',
    maxWidth: '342px',
    width: '100%',
    '& > p': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '17px',
      lineHeight: '20px',
      textAlign: 'center',
      color: '#AE5D02',
      margin: 0,
      marginBottom: '10px',
    },
    '& > .btnContainer': {
      display: 'flex',
      justifyContent: 'space-between',
      '& > img': {
        width: '153px',
        height: '51px',
      },
    },
  },
}));

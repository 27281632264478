import { makeStyles } from '@material-ui/core';
import Artist from '@art/images/fake-artist/artist.svg';

export const styles = makeStyles((theme) => ({
  showRole: {
    width: '100%',
    minHeight: '100%',
    flexGrow: 1,
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
    justifyContent: 'center',
    color: '#1B2036',
    [theme.breakpoints.up('md')]: {
      padding: '30px',
    },
  },

  showArtist: {
    minWidth: '207px',
    height: '275px',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F8CA61',
    backgroundImage: `url(${Artist})`,
    backgroundPosition: 'bottom center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    borderRadius: '20px',
    padding: '15px 15px 0 15px',
    animationDuration: '230ms',
    animationDelay: '230ms',
    [theme.breakpoints.up('sm')]: {
      minWidth: '320px',
      height: '425px',
    },

    '& > h2': {
      fontWeight: 900,
      fontSize: '40px',
      lineHeight: '55px',
      textTransform: 'uppercase',
      color: 'white',
      margin: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: '60px',
        lineHeight: '82px',
      },
    },
  },
}));

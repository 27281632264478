import { fontFamily } from '@src/art/theme/textStyles';
import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  personStatus: {
    fontFamily,
    position: 'absolute',
    // zIndex: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '16px',
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 400,
    color: '#FFFFFF',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: '0px 0px 4px 4px',
    [theme.breakpoints.up('sm')]: {
      height: '26px',
      fontSize: '14px',
      lineHeight: '17px',
    },
  },
}));

import { palleteColors, brushSizes } from '../palleteColors';

import { styles } from './styles';

export type DrawAndGuessPaletteProps = {
  penWidth: number;
  brushColor: string;
  menuItem: string;
  onChangePen: (penWidth: number) => void;
  onChangeColor: (color: string) => void;
  setMenuItem: (color: string) => void;
};

const MobilePalette: React.FC<DrawAndGuessPaletteProps> = ({
  penWidth,
  onChangePen,
  onChangeColor,
  setMenuItem,
  brushColor,
  menuItem,
}) => {
  const classes = styles();

  return (
    <>
      {/* brush sizes */}
      {(menuItem === 'pen' || menuItem === 'erase') && (
        <section className={classes.brushSizes}>
          <ul className={classes.brushSizesList}>
            {brushSizes.map((size: number, index: number) => {
              return (
                <li
                  key={index}
                  style={{ height: `${size}px`, width: `${size}px` }}
                  className={`${size === penWidth * 2 ? 'active' : ''}`}
                  onClick={() => onChangePen(size / 2)}
                />
              );
            })}
          </ul>

          {/* close */}
          {menuItem !== 'erase' && <div onClick={() => setMenuItem('')} className={classes.closeButton} />}
        </section>
      )}

      {/* colors */}
      {menuItem === 'colors' && (
        <section className={classes.palleteList}>
          <ul className={classes.palleteListColors}>
            {palleteColors.map((color: string, index: number) => (
              <li
                key={index}
                className={`${brushColor === color ? 'active' : ''}`}
                onClick={() => onChangeColor(color)}
              >
                <div
                  style={{
                    backgroundColor: color,
                    border: color === '#FFFFFF' ? '1px solid #ddd' : '1px solid transparent',
                  }}
                />
              </li>
            ))}
          </ul>

          {/* close */}
          <div onClick={() => setMenuItem('')} className={classes.closeButton} />
        </section>
      )}
    </>
  );
};

export { MobilePalette };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  reward: {
    position: 'relative',
    zIndex: 1,
    height: '100%',
    width: '100%',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',
    boxSizing: 'border-box',
    '--animate-duration': '500ms',
    '--animate-delay': '500ms',
    [theme.breakpoints.up('md')]: {
      padding: '30px',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      zIndex: 2,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.6)',
    },

    // hide GameWrapper
    '& + section': { display: 'none' },
  },
}));

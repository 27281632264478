import { useEffect, useState } from 'react';
import WSClient from '@src/sockets';
import { simpleMerge } from '@src/common/generics';
import { defaultGameState, initialMapState } from './UnityGame.types';
import { CLIENT_EVENTS, SERVER_EVENTS } from '@shared/socketEvents/eventTypes';
import { UnityGameState } from '../../shared/gameInterfaces/unityGame.socket';

export const useGameProvider = (roomId: string) => {
  const [gameState, setGameState] = useState<UnityGameState>(defaultGameState);

  const stateMatcher = { ...initialMapState, [gameState.gameState]: true };

  useEffect(() => {
    const updateState = ({ data }: any) => {
      if (!data.unityGame) return;
      setGameState(simpleMerge(gameState, data.unityGame));
    };

    WSClient.on(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    WSClient.on(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);

    WSClient.emit(CLIENT_EVENTS.GAME_STATE_REQUESTED, { roomId });

    return () => {
      WSClient.off(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);
      WSClient.off(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    };
  }, []);

  return [
    // stateMatcher,
    { ...gameState, state: stateMatcher },
  ] as const;
};

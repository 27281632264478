import React from 'react';
import { useResetTimer } from '@src/common/misc';
import { useAppSelector } from '@src/app/hooks';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';
import { MS_IN_MINUTE } from '@src/common/constants/system';

interface IProps {
  lastClaimDate: number;
}

const ClaimTimer: React.FC<IProps> = ({ lastClaimDate }) => {
  const classes = styles();
  const claimDate = useResetTimer(lastClaimDate);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <div className={classes.timerContainer}>
      {isEnLocale ? 'Next withdraw available in' : 'Следующий вывод возможен через'}
      <div className={classes.timer}>{claimDate}</div>
      {/* <div style={{ position: 'absolute', bottom: '50px', right: '50px' }}>
        <QuestionButton
          style={{ position: 'fixed' }}
          gray={false}
          gameType={GameTypes.HELP_SCREEN}
          isShowResults={true}
          padding={false}
        />
      </div> */}
    </div>
  );
};

export { ClaimTimer };

import React from 'react';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { INotification } from '@src/store/room/notifications';
import { useStyles } from './styles';

interface Props {
  notification: INotification;
  text: string;
}

const CopyNotification: React.FC<Props> = ({ notification, text }) => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const styles = useStyles();

  return <div className={styles.copy}>{text}</div>;
};

export { CopyNotification };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  ruleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '210px',
    height: '275px',
    background: '#FFFFFF',
    border: '5px solid #F05452',
    borderRadius: '10px',
    [theme.breakpoints.up('md')]: {
      width: '320px',
      height: '425px',
    },
  },

  name: {
    display: 'block',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'SF Pro Rounded',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '28px',
    lineHeight: '33px',
    textAlign: 'center',
    color: '#F05452',
    borderBottom: '5px solid #F05452',
    width: '100%',
    padding: '25px 20px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      padding: '40px 20px',
      fontSize: '44px',
      lineHeight: '53px',
    },
  },

  description: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',
    fontFamily: 'SF Pro Rounded',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#1B2036',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      fontSize: '32px',
      lineHeight: '38px',
      padding: '40px',
    },
  },
}));

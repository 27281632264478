import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { selectAvatars } from '@src/store/room/roomFeaturesData.slice';
import check from '@art/icons/check.svg';
import { IdType } from '@src/shared/generics';
import { useBlockchainData } from '@src/features/blockchain';
import { requestAvatarUpdate } from '@src/graphql/debugQueries';
import { selectOwnAvatar, updatePlayerAvatar } from '@src/store/reducers/player.reducer';
import { selectIsInRoom } from '@src/store/room/roomSlice';
import WSClient from '@src/sockets';
import { CLIENT_EVENTS } from '@src/shared/socketEvents/eventTypes';

interface IProps {
  setAvatar: (avatarId: IdType) => void;
}

const Avatars: React.FC<IProps> = ({ setAvatar }) => {
  const classes = styles();
  const avatars = useAppSelector(selectAvatars);
  const dispatch = useAppDispatch();
  const { walletConnected } = useBlockchainData();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const currentAvatar = useAppSelector(selectOwnAvatar);
  const currentAvatarIndex = avatars.indexOf(currentAvatar!);
  const isInRoom = useAppSelector(selectIsInRoom);
  const [oldAvatarId] = React.useState<string>(currentAvatar!.id);
  const [state] = React.useState<{ avatarId: string }>({ avatarId: currentAvatar!.id });
  const [iconIndex, setIconIndex] = useState<number | undefined>(currentAvatarIndex);

  const onChangeAvatarInRoom = (avatarId: IdType) => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_CHANGED_AVATAR, { avatarId });
    dispatch(updatePlayerAvatar(avatarId));
  };

  useEffect(() => {
    return () => {
      if (oldAvatarId === state.avatarId) return;
      isInRoom ? onChangeAvatarInRoom(state.avatarId) : dispatch(requestAvatarUpdate(state.avatarId));
    };
  }, []);

  const onAvatarClick = (index: number, avatarId: IdType) => {
    setIconIndex(index);
    setAvatar(avatarId);
    state.avatarId = avatarId;
  };

  const renderAvatars = () =>
    avatars.map((avatar, index) => {
      return (
        <div style={{ position: 'relative' }} key={index} onClick={() => onAvatarClick(index, avatar.id)}>
          <img src={avatar.image} className={classes.avatar} style={{ backgroundColor: `#${avatar?.background}` }} />
          {iconIndex === index && <img className={classes.check} src={check} alt="✓" />}
          {iconIndex === index && <div className={classes.background} />}
        </div>
      );
    });

  return (
    <>
      <div className={classes.header}>{isEnLocale ? 'Choose Avatar' : 'Выбрать аватар'}</div>
      {walletConnected ? (
        avatars.length > 0 ? (
          <div className={classes.avatarsContainer}>{renderAvatars()}</div>
        ) : (
          <p className={classes.subtitle}>{isEnLocale ? 'You have no NFT' : 'У тебя нет NFT'}</p>
        )
      ) : (
        <p className={classes.subtitle}>
          {isEnLocale ? 'Connect your wallet to change avatar' : 'Подключи кошелек, чтобы выбрать аватар'}
        </p>
      )}
    </>
  );
};

export { Avatars };

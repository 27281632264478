import React from 'react';
import { compose } from 'redux';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './app/App';
import theme from './art/theme';
import * as serviceWorker from './tools/serviceWorker';
import { logger } from './common/logger';
import { ThemeProvider } from '@material-ui/core/styles';
import './index.css';
import { SecurityProvider } from './features/auth/components/secured';
import { CookiesProvider } from 'react-cookie';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

console.debug('localStorage', localStorage);

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <SecurityProvider>
            <App />
          </SecurityProvider>
        </ThemeProvider>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { IRoomPlayer } from '@store/room/roomSlice';
import { setVideoState } from '@store/reducers/common';
import { styles } from './styles';
import { useDeviceSize } from '@src/common/screenSizes';
import { CircleTimer } from '@src/games/common/Timer/CircleTimer';
import { TimeAlert } from '../TimeAlert/TimeAlert';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import clock from '@src/art/images/five_seconds/clock.svg';
import background from '@src/art/images/five_seconds/background.png';
import { GuessedModal } from './GuessedModal copy/GuessedModal';
import { selectPlayerId } from '@src/store/reducers/player.reducer';
import { FiveSecondsState, FIVE_SECONDS_STATE } from '@src/shared/gameInterfaces/fiveSeconds.socket';
import { TimerBig } from '../Timer/Timer';

interface IProps {
  game: FiveSecondsState;
  isActivePlayer: boolean;
  activePlayer: IRoomPlayer;
  onVote: (value: boolean) => void;
  onVotingStart: () => void;
  isSpectator: boolean;
}

interface IPlayerCardProps {
  game: FiveSecondsState;
  playerId: string;
}

const PlayerContainer: React.FC<IProps> = ({
  game,
  isActivePlayer,
  activePlayer,
  onVote,
  onVotingStart,
  isSpectator,
}) => {
  const classes = styles();
  const { gameState, activePlayerId, word, votes, votedPlayers, timer } = game;
  const [isPlayerVoted, setIsPlayerVoted] = useState(false);
  const isVoteState = gameState === FIVE_SECONDS_STATE.VOTE;
  const isGuessedOpen = isVoteState && !isPlayerVoted && !isActivePlayer && !isSpectator;
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const currentPlayerId = useAppSelector(selectPlayerId);
  const text = isEnLocale ? 'Name 3' : 'Назови три';

  useEffect(() => {
    setIsPlayerVoted(!!votedPlayers?.find((playerId) => playerId === currentPlayerId));
  }, [votedPlayers?.length]);

  return (
    <>
      {isVoteState && <TimeAlert />}
      <div className={classes.containerStyles}>
        <div className={classes.questionShowContainer}>
          <div className={classes.question}>{text + ' ' + word}</div>
        </div>
        <PlayerCard playerId={activePlayerId} game={game} />
      </div>

      {isActivePlayer && isVoteState && (
        <div className={classes.badge}>
          {isEnLocale
            ? 'Wait until all players decide whether you have completed the task'
            : 'Подожди, пока остальные игроки решат, справился ли ты с заданием'}
        </div>
      )}

      {isPlayerVoted && isVoteState && (
        <div className={classes.badge}>
          {isEnLocale ? 'All players must vote to proceed' : 'Все игроки должны проголосовать, чтобы продолжить'}
        </div>
      )}
      <GuessedModal onVote={onVote} isOpen={isGuessedOpen} activePlayer={activePlayer} timer={timer} />
    </>
  );
};

const PlayerCard: React.FC<IPlayerCardProps> = ({ playerId, game }) => {
  const dispatch = useAppDispatch();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const classes = styles();
  const { isDesktop } = useDeviceSize();
  const videoContainer = document.getElementById(playerId!);
  const videoContainerOrigin = document.getElementById(`${playerId}_container`);

  useEffect(() => {
    if (videoContainerOrigin) {
      //@ts-ignore
      videoContainerOrigin.firstChild.insertAdjacentHTML('afterBegin', `<img src=${clock} class=${classes.icon} />`);
      //@ts-ignore
      videoContainerOrigin.firstChild.style.background = `url(${background}) bottom`;
      //@ts-ignore
      videoContainerOrigin.firstChild.style.alignItems = 'center';
    }

    if (videoContainer && containerRef.current) {
      containerRef.current.append(videoContainer);
      videoContainer.classList.add(`${classes.pairContainer}`);
      videoContainer.classList.remove(`${classes.normalContainer}`);
      //@ts-ignore
      videoContainer.style.zIndex = '4';
    }

    return () => {
      if (videoContainer) {
        videoContainer.classList.add(`${classes.normalContainer}`);
        videoContainer.classList.remove(`${classes.pairContainer}`);
        //@ts-ignore
        videoContainer.style.zIndex = '0';
      }

      dispatch(setVideoState({ playerId, data: { shouldRestart: {} } }));

      if (videoContainerOrigin) {
        videoContainerOrigin.firstChild!.firstChild!.remove();
        //@ts-ignore
        videoContainerOrigin.firstChild!.insertBefore(videoContainer, videoContainerOrigin.firstChild!.firstChild);
      }
    };
  }, [videoContainerOrigin, videoContainer, isDesktop]);

  return (
    <div className={classes.personContainer} ref={containerRef}>
      {game.gameState === FIVE_SECONDS_STATE.PREPARE && !game.timer?.timeIsUp && (
        <TimerBig timer={game.timer!} alertThreshold={5} />
      )}
      {game.gameState === FIVE_SECONDS_STATE.GAME && !game.timer?.timeIsUp && (
        <div style={{ position: 'absolute', bottom: '10px', zIndex: 10, right: '10px' }}>
          <CircleTimer timer={game.timer!} />
        </div>
      )}
    </div>
  );
};

export { PlayerContainer };

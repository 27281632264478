import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  modalTitle: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: '32px',
    lineHeight: '32px',
    color: '#1B2036',
    textTransform: 'uppercase',
    marginTop: '3px',
    maxWidth: '200px',
    marginBottom: '15px',
    '&.ru': {
      maxWidth: '250px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
      marginBottom: '30px',
    },
  },

  shareList: {
    padding: 0,
    margin: 0,
    marginBottom: '5px',
    listStyle: 'none',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '25px',
    },
  },

  shareItem: {
    '& > a': {
      display: 'inline-flex',
      width: '55px',
      height: '55px',
      borderRadius: '50%',
      marginRight: '10px',
    },
    '&:last-child > a': {
      marginRight: 0,
    },
  },

  skipButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '140px',
    height: '32px',
    borderRadius: '70px',
    color: '#5F6372',
    fontSize: '24px',
    lineHeight: '28px',
    cursor: 'pointer',
    transition: 'all .23s',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '28px',
      width: '160px',
      height: '45px',
    },
    '&:hover': {
      color: '#D9264D',
    },
    '&.ru': {
      width: '150px',
      fontSize: '16px',
      lineHeight: '20px',
      [theme.breakpoints.up('sm')]: {
        width: '210px',
        height: '45px',
        fontSize: '24px',
        lineHeight: '26px',
      },
    },
  },
}));

import { fontFamily } from '@src/art/theme/textStyles';
import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  infoContainer: {
    display: 'flex',
    // background: 'linear-gradient(180deg, #E8FDFF 0%, #BCE1FC 100%)',
    flexDirection: 'column',
    padding: '0px',
    '& p': {
      fontFamily: fontFamily,
      fontStyle: 'normal',
    },
    '& .row': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '23px',
      '& .title': {
        color: 'black',
      },
    },
  },
}));

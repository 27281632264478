import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { IRoomPlayer } from '@store/room/roomSlice';
import { setVideoState } from '@store/reducers/common';
import { styles, wordStyles } from './styles';
import { useDeviceSize } from '@src/common/screenSizes';
import { AliasState } from '@src/shared/gameInterfaces/alias.socket';
import { CircleTimer } from '@src/games/common';
import { TimeAlert } from '../TimeAlert/TimeAlert';
import { ChooseModal } from './ChooseModal/ChooseModal';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import Button from '@src/components/Button';
import alias from '@src/art/images/alias/alias.svg';

interface IProps {
  game: AliasState;
  isActivePlayer: boolean;
  activePlayer: IRoomPlayer;
  onVote: (value: string | undefined) => void;
}

interface IPlayerCardProps {
  game: AliasState;
  playerId: string;
}

const PlayerContainer: React.FC<IProps> = ({ game, isActivePlayer, activePlayer, onVote }) => {
  const classes = styles();
  const { activePlayerId, word } = game;
  const [isModalOpen, setModalOpen] = useState(false);
  const { timeIsUp } = game.timer!;
  const isPlayerSelectOpen = isModalOpen || (timeIsUp && isActivePlayer) || false;
  const isEnLocale = useAppSelector(selectIsEnLocale);

  return (
    <>
      {timeIsUp && <TimeAlert />}
      <div className={classes.containerStyles}>
        <Word isActivePlayer={isActivePlayer} word={word!} />
        <PlayerCard playerId={activePlayerId} game={game} />
      </div>
      {isActivePlayer && (
        <Button
          onClick={() => setModalOpen(true)}
          text={isEnLocale ? 'Guessed' : 'Отгадано'}
          type={'primary-blue-new'}
          style={{ marginBottom: '30px' }}
        />
      )}
      <ChooseModal
        onVote={onVote}
        isOpen={isPlayerSelectOpen}
        activePlayer={activePlayer}
        setModalOpen={setModalOpen}
      />
    </>
  );
};

interface Props {
  word: string;
  isActivePlayer: boolean;
}

const Word: React.FC<Props> = ({ word, isActivePlayer }) => {
  const classes = wordStyles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const description = isEnLocale ? 'Guess the phrase' : 'Отгадай фразу';

  return (
    <div className={classes.wordShowContainer}>
      {isActivePlayer ? (
        <div className={classes.word}>{word}</div>
      ) : (
        <div className={classes.description}>{description}</div>
      )}
    </div>
  );
};

const PlayerCard: React.FC<IPlayerCardProps> = ({ playerId, game }) => {
  const dispatch = useAppDispatch();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const classes = styles();
  const { isDesktop } = useDeviceSize();
  const videoContainer = document.getElementById(playerId!);
  const videoContainerOrigin = document.getElementById(`${playerId}_container`);

  useEffect(() => {
    if (videoContainerOrigin) {
      //@ts-ignore
      videoContainerOrigin.firstChild.insertAdjacentHTML('afterBegin', `<img src=${alias} class=${classes.alias} />`);
      //@ts-ignore
      videoContainerOrigin.firstChild.style.backgroundImage = 'linear-gradient(180deg, #DC2D1F 28.5%, #C61712 66.85%)';
      //@ts-ignore
      videoContainerOrigin.firstChild.style.alignItems = 'center';
    }

    if (videoContainer && containerRef.current) {
      containerRef.current.append(videoContainer);
      videoContainer.classList.add(`${classes.pairContainer}`);
      videoContainer.classList.remove(`${classes.normalContainer}`);
      //@ts-ignore
      videoContainer.style.zIndex = '4';
    }

    return () => {
      if (videoContainer) {
        videoContainer.classList.add(`${classes.normalContainer}`);
        videoContainer.classList.remove(`${classes.pairContainer}`);
        //@ts-ignore
        videoContainer.style.zIndex = '0';
      }

      dispatch(setVideoState({ playerId, data: { shouldRestart: {} } }));

      if (videoContainerOrigin) {
        videoContainerOrigin.firstChild!.firstChild!.remove();
        //@ts-ignore
        videoContainerOrigin.firstChild!.insertBefore(videoContainer, videoContainerOrigin.firstChild!.firstChild);
      }
    };
  }, [videoContainerOrigin, videoContainer, isDesktop]);

  return (
    <div className={classes.personContainer} ref={containerRef}>
      <div style={{ position: 'absolute', bottom: '10px', zIndex: 10, right: '10px' }}>
        <CircleTimer timer={game.timer!} />
      </div>
    </div>
  );
};

export { PlayerContainer };

import { useAppSelector } from '@src/app/hooks';
import { useDeviceSize } from '@src/common/screenSizes';
import { AliasState } from '@src/shared/gameInterfaces/alias.socket';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';

interface Props {
  activePlayer: { name: string };
  isActivePlayer: boolean;
}

const Rule: React.FC<Props> = ({ activePlayer, isActivePlayer }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const { isMobile } = useDeviceSize();

  const firstText = isActivePlayer
    ? isEnLocale
      ? 'You need to guess Who are You'
      : 'Тебе нужно понять кто ты'
    : isEnLocale
    ? 'Will guess Who is he/she'
    : 'Будет отгадывать свою личность';

  const secondText = isActivePlayer
    ? isEnLocale
      ? 'Ask questions to the other players'
      : 'Задавай вопросы другим игрокам'
    : isEnLocale
    ? 'Answer to the questions only Yes / No'
    : 'Отвечай на вопросы только да/нет';

  return (
    <div className={`animate__animated  ${isMobile ? `animate__fadeInDown` : `animate__zoomIn`}`}>
      <div className={classes.ruleContainer}>
        <div className={classes.name}>{activePlayer?.name}</div>
        <div
          className={classes.description}
          style={{ padding: !isMobile ? (isEnLocale ? '18px 48px' : '17px') : '15px' }}
        >
          {firstText}
        </div>
        <div className={classes.description}>{secondText}</div>
      </div>
    </div>
  );
};

export { Rule };

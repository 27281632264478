import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  passiveWatch: {
    flexDirection: 'column',
    minHeight: '275px',
    width: '207px',
    alignItems: 'center',
    borderRadius: '10px',
    paddingTop: '5px',
    paddingRight: '5px',
    paddingLeft: '5px',
    paddingBottom: '5px',
    backgroundImage: 'linear-gradient(180deg, #FFD957 0, #CAC6FA 80%, #CAC6FA 100%)',
    [theme.breakpoints.up('sm')]: {
      padding: '10px',
      minHeight: '325px',
      width: '243px',
    },
  },

  passiveWatchTop: {
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '5px',
    minHeight: '265px',
    [theme.breakpoints.up('sm')]: {
      minHeight: '305px',
    },

    '& > p': {
      textAlign: 'center',
      fontSize: '20px',
      lineHeight: '24px',
      color: '#1B2036',
      fontWeight: 500,
      maxWidth: '170px',
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 3,
      display: '-webkit-box',
      boxOrient: 'vertical',
      width: '95%',
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
        lineHeight: '31px',
        maxWidth: '200px',
      },
      '& > span': {
        color: '#2C74F3',
        overflow: 'hidden',
        display: 'block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },

  passiveWatchPencilImage: {
    height: '60px',
    width: 'auto',
    flexShrink: 0,
    marginBottom: '30px',
    [theme.breakpoints.up('sm')]: {
      height: '85px',
    },
  },
}));

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  bombs: {
    width: '376px',
    position: 'absolute',
    justifyContent: 'space-between',
    zIndex: 7,
    bottom: '-60px',
    left: '50%',
    marginLeft: '-175px',
  },

  bombImage: {
    width: '122px',
    height: '125px',
    animationDuration: '230ms',
  },
}));

import { IDrawing, ILeaderboard, ITimer } from '../common';
import { Line } from '../drawData';
import { IdType, OneOfType } from '../generics';
import { BaseGameState } from '../socketEvents/socketEvents';

export enum DRAW_AND_GUESS_STATE {
  READY_START_ROUND = 'READY_START_ROUND',
  SPIN = 'SPIN',
  CHOOSE_WORD = 'CHOOSE_WORD',
  SHOW_WORD = 'SHOW_WORD',
  DRAW = 'DRAW',
  RESULTS = 'RESULTS',
  TOP_PLAYERS = 'TOP_PLAYERS',
  LEADERBOARD = 'LEADERBOARD',
}

export interface IWord {
  word: string;
  value: number;
}

export interface DrawAndGuessState extends BaseGameState {
  gameState: DRAW_AND_GUESS_STATE;
  roundNumber: number;
  totalRounds: number;
  activePlayerId: IdType;
  chooseWords?: IWord[];
  chosenWord?: IWord;
  winner?: IdType;
  readyPlayers?: IdType[];
  leaderboard?: ILeaderboard;
  drawing?: IDrawing;
  timer?: ITimer;
}

import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  img: {
    width: '315px',
    height: '175px',
    display: 'block',
    borderRadius: '8px',
  },

  list: {
    flexDirection: 'column',
    paddingLeft: 0,
    marginTop: 0,
    overflowY: 'auto',
    maxHeight: '58vh',
    '& li': {
      position: 'relative',
      fontSize: '15px',
      lineHeight: '21px',
      marginBottom: '12px',
      listStyle: 'none',
      paddingLeft: '15px',
      '&:last-child': {
        marginBottom: 0,
      },
      [theme.breakpoints.up('md')]: {
        paddingRight: '15px',
        fontSize: '14px',
        lineHeight: '21px',
      },
      '&::before': {
        content: '""',
        display: 'block',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: '#E8E9EB',
        position: 'absolute',
        top: '8px',
        left: 0,
      },
    },
    '& b': {
      fontWeight: 700,
    },
  },

  button: {
    width: '145px',
    height: '36px',
    '& span': {
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
    },
  },

  introContentRulesItem: {
    fontFamily: fontFamily,
    fontSize: '16px',
    lineHeight: '21px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#1B2036',
    listStylePosition: 'outside',
    // marginLeft: '10px',
    '&::marker': {
      color: '#E8E9EB',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
      // marginBottom: 14,
    },
  },

  rules: {
    flexDirection: 'column',
    '& ul ul': {
      paddingLeft: '10px',
      paddingTop: '10px',
    },
  },
}));

const GuessUpTextContentEn: React.FC = () => {
  return (
    <>
      <li>
        <b>Players vote</b> whether Secret Phrase was guessed correctly by Active player.
      </li>
      <li>
        If Secret Phrase guessed correctly, Active player gets <b>1 point</b>
      </li>
      <li>
        If at least one player votes No, Active player gets <b>0 points</b>
      </li>
    </>
  );
};

const GuessUpTextContentRu: React.FC = () => {
  return (
    <>
      <li>
        <b>Игроки голосуют,</b> правильно ли отгадал секретную фразу активный игрок.
      </li>
      <li>
        Если секретная фраза отгадана верно - активный игрок получает <b>1 балл</b>
      </li>
      <li>
        Если хотя бы олин игрок проголосовал Нет, активный игрок получает <b>0 баллов</b>
      </li>
    </>
  );
};

export { GuessUpTextContentEn, GuessUpTextContentRu };

import { Dispatch, SetStateAction } from 'react';
import Button from '@components/Button';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

interface Props {
  game: any;
  activePlayer: any;
  isModalOpen: boolean;
  isActivePlayer: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const MobileHeader: React.FC<Props> = ({ game, activePlayer, isModalOpen, isActivePlayer, setModalOpen }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const buttonText = isEnLocale ? (isModalOpen ? 'Cancel' : 'Correct') : isModalOpen ? 'Закрыть' : 'Отгадано!';

  return (
    <header className={classes.drawPictureMobileHeader}>
      {isActivePlayer ? (
        <>
          <Button
            type="primary-green"
            text={buttonText}
            className={isModalOpen ? 'isModalOpen' : ''}
            disabled={game.timer.timeIsUp}
            onClick={() => setModalOpen(true)}
          />

          <b>{game.chosenWord.word}</b>
        </>
      ) : (
        <div className={classes.whoDraw}>
          <span>{isEnLocale ? 'Guess what ' : 'Отгадайте, что '}</span>
          <div className="text-overflow">{activePlayer?.name}</div>
          <span>{isEnLocale ? ' is drawing' : ' рисует'}</span>
        </div>
      )}
    </header>
  );
};

export { MobileHeader };

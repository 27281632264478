import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  questionWrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
  },

  questionInner: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '540px',
      flexGrow: 0.75,
    },
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
  },

  questionNumber: {
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 500,
    color: '#5F6372',
    justifyContent: 'center',
    marginBottom: '15px',
  },

  questionTitle: {
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '32px',
    },
    '& > img': {
      display: 'flex',
      flexShrink: 0,
      width: '45px',
      height: '45px',
      borderRadius: '50%',
      alignSelf: 'flex-end',
      marginRight: '10px',
    },
    '& > p': {
      margin: 0,
      padding: '15px',
      fontSize: '17px',
      lineHeight: '20px',
      fontWeight: 500,
      color: '#1B2036',
      backgroundColor: '#FFF',
      borderRadius: '18px',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '24px',
      },
    },
  },

  questionOptions: {
    flexDirection: 'column',
  },

  buttonWrapper: {
    minHeight: '40px',
    alignSelf: 'center',
    textAlign: 'center',
  },
}));

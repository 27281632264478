import React, { useState } from 'react';
import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { IQuestNotification } from '@src/store/room/notifications';
import { useTitle } from '@src/Pages/energy/parts/Quests/helpers';
import { ProgressPartQuest, RewardPartQuest } from '@src/Pages/energy/parts/Quests/quest';
import { selectQuests } from '@src/store/reducers/player.reducer';
import { IPlayerQuest, QUEST_STATE, QUEST_TYPE } from '@src/shared/quests';
import { useStyles } from './styles';

interface Props {
  notification: IQuestNotification;
}

const QuestNotification: React.FC<Props> = ({ notification }) => {
  const quests = useAppSelector(selectQuests);
  const quest = quests.find((quest: IPlayerQuest) => quest.id === notification.questId) || ({} as IPlayerQuest);
  const styles = useStyles();
  const [isClaimed, setIsClaimed] = useState(false);
  const { getTitle } = useTitle(quest.type, quest);
  const isDone = quest.state === QUEST_STATE.DONE;

  return (
    <div className={styles.quest}>
      <p className={'questTitle'}>{getTitle()}</p>
      {isDone && (
        <RewardPartQuest
          className={'rewardPart'}
          questData={quest}
          isClaimed={isClaimed}
          setIsClaimed={setIsClaimed}
          isNotification={true}
        />
      )}
      {!isDone && <ProgressPartQuest questData={quest} />}
    </div>
  );
};

export { QuestNotification };

import { FakeArtistState } from '@shared/gameInterfaces/fakeArtist.socket';
import { ArtistsHeader, FakeArtistHeader } from './parts';

type Props = {
  game: FakeArtistState;
  isFakeArtist: boolean;
  isSpectator: boolean;
  isSkipVoting: boolean;
  isActivePlayer: boolean;
  isVotingStatuses: boolean;
  chosenWord?: string;
  activePlayerName: string;
  onNext: () => void;
  onVotingStart: () => void;
};

const MobileHeader: React.FC<Props> = ({
  game,
  isFakeArtist,
  isSpectator,
  isSkipVoting,
  isActivePlayer,
  activePlayerName,
  isVotingStatuses,
  onNext,
  onVotingStart,
}) => {
  const subroundsLeft = game.totalSubrounds! - game.subroundNumber!;

  if (isFakeArtist) {
    return (
      <FakeArtistHeader
        onNext={onNext}
        timer={game.timer!}
        isSpectator={isSpectator}
        isSkipVoting={isSkipVoting}
        subroundsLeft={subroundsLeft}
        isActivePlayer={isActivePlayer}
        onVotingStart={onVotingStart}
        activePlayerName={activePlayerName}
        isVotingStatuses={isVotingStatuses}
        isFirstSubround={game.subroundNumber === 1}
      />
    );
  } else {
    return (
      <ArtistsHeader
        onNext={onNext}
        timer={game.timer!}
        isSpectator={isSpectator}
        isSkipVoting={isSkipVoting}
        chosenWord={game.chosenWord}
        subroundsLeft={subroundsLeft}
        onVotingStart={onVotingStart}
        isActivePlayer={isActivePlayer}
        activePlayerName={activePlayerName}
        isVotingStatuses={isVotingStatuses}
        isFirstSubround={game.subroundNumber === 1}
      />
    );
  }
};

export { MobileHeader };

import { LoveBottleState, LOVE_BOTTLE_STATE } from '@src/shared/gameInterfaces/loveBottle.socket';

export interface IMapGameState {
  READY_START_ROUND: boolean;
  ROUND_IN_PROGRESS: boolean;
  SPIN_BOTTLE: boolean;
  SHOWING_RESULTS: boolean;
  SHOW_GIF: boolean;
  TOP_COUPLES: boolean;
  LEADERBOARD: boolean;
}

export const initialMapState: IMapGameState = {
  READY_START_ROUND: false,
  SPIN_BOTTLE: false,
  ROUND_IN_PROGRESS: false,
  SHOW_GIF: false,
  SHOWING_RESULTS: false,
  TOP_COUPLES: false,
  LEADERBOARD: false,
};

export type IGameStateMatcher = typeof initialMapState;

export const defaultGameState: LoveBottleState = {
  gameState: LOVE_BOTTLE_STATE.READY_START_ROUND,
  activeGameId: '',
  activePlayerId: '',
  pairPlayerId: '',
  gifId: '',
  questions: [],
  activeTicketIndex: 0,
  roundNumber: 0,
  totalRounds: 0,
  readyPlayers: [],
};

export enum MATCH_STATE {
  GREY = 'GREY',
  RED = 'RED',
  BROKEN = 'BROKEN',
}

export interface IGameState extends LoveBottleState {
  state: IGameStateMatcher;
}

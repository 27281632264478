import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  sendModalContainer: {
    padding: '20px 15px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    '& .title': {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '29px',
      textAlign: 'center',
      color: '#5568AE',
      display: 'flex',
      marginBottom: '9px',
    },
  },
  formInner: {
    color: 'inherit',
    flexDirection: 'column',
    width: '100%',
  },
  customInput: {
    display: 'flex',
    position: 'relative',
    width: ' 100%',
    alignItems: 'center',
    justifyContent: 'center',
    '& > .currency': {
      position: 'absolute',
      right: 19,
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'flex',
      color: '#5568AE',
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '15px',
    },

    '& > input': {
      background: 'rgba(15, 94, 213, 0.1)',
      boxShadow: 'inset 0px 2px 8px rgb(16 90 211 / 5%)',
      borderRadius: '30px',
      border: 'none',
      padding: '11px 20px',
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '21px',
      color: '#1B2036',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      '&::-webkit-outer-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&::-moz-inner-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&::-moz-outer-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&:hover': {
        '&::-webkit-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
      },
      '&:focus': {
        '&::-webkit-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
      },
    },
  },
  btnContainer: {
    color: 'inherit',
    justifyContent: 'space-between',
    marginTop: '15px',
  },
}));

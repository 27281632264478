import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '20px',
    marginTop: 0,
    marginBottom: '10px',
  },

  wrapper: {
    color: 'inherit',
    flexDirection: 'column',
  },

  button: {
    color: 'inherit',
  },
}));

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  wordShowContainer: {
    justifyContent: 'center',
  },

  wordShow: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '320px',
    color: '#1B2036',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
  },

  wordShowItem: {
    flexDirection: 'column',
    minHeight: '275px',
    flexBasis: '207px',
    alignItems: 'center',
    margin: '0 5px',
    backgroundColor: 'white',
    borderRadius: '10px',
    paddingTop: '5px',
    paddingRight: '5px',
    paddingLeft: '5px',
    backgroundImage: 'linear-gradient(180deg, #FFD957 0, #CAC6FA 80%, #CAC6FA 100%)',
    transition: 'all .1s',
    [theme.breakpoints.up('sm')]: {
      minHeight: '425px',
      flexBasis: '317px',
      margin: '0 15px',
      borderRadius: '20px',
      paddingTop: '10px',
      paddingRight: '10px',
      paddingLeft: '10px',
    },
  },
  [theme.breakpoints.up('sm')]: {
    marginTop: '35px',
    fontSize: '32px',
    lineHeight: '44px',
  },

  wordShowTop: {
    alignItems: 'center',
    width: '100%',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '10px 10px 0 0',
    paddingLeft: '5px',
    paddingRight: '5px',
    minHeight: '221px',
    [theme.breakpoints.up('sm')]: {
      minHeight: '330px',
    },
    '& > b': {
      transition: 'all .1s',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Nunito',
      flexGrow: 1,
      fontWeight: 900,
      color: '#ABA8DC',
      textTransform: 'uppercase',
      marginTop: '-15px',
      fontSize: '32px',
      lineHeight: '44px',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '42px',
        lineHeight: '57px',
        marginTop: '-10px',
      },
    },
  },

  wordShowTitle: {
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '18px',
    marginTop: '20px',
    marginBottom: '20px',
    color: '#2C74F3',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '24px',
      marginTop: '30px',
      marginBottom: '40px',
    },
  },

  pencilImage: {
    height: '62px',
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      height: '85px',
    },
  },

  wordShowBottom: {
    backgroundColor: '#CAC6FA',
    borderRadius: '0 0 10px 10px',
    alignSelf: 'stretch',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    '& > b': {
      flexBasis: '50px',
      fontFamily: 'Nunito',
      color: '#161F43',
      fontSize: '36px',
      lineHeight: '49px',
      fontWeight: 800,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        flexBasis: '80px',
        fontSize: '50px',
        lineHeight: '75px',
      },
    },
    '& > div': {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      marginTop: '5px',
      borderRadius: '0 0 5px 0',
      marginBottom: '5px',
      paddingLeft: '2px',
      paddingRight: '2px',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '10px',
        paddingRight: '5px',
      },
      '& > p': {
        margin: 0,
        color: '#1B2036',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '14px',
        letterSpacing: '-0.8px',
        paddingLeft: '12px',
        paddingRight: '5px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '16px',
          lineHeight: '19px',
        },
      },
    },
  },
}));

import { makeStyles } from '@material-ui/core';
import WinnerImg from '@art/images/winner-illustration-with-stars.svg';

export const styles = makeStyles((theme) => ({
  rightAnswerWrapper: {
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',
    minHeight: '100%',
    [theme.breakpoints.up('md')]: {
      paddingTop: '30px',
      minHeight: '365px',
    },
  },

  rightAnswer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '15px',
    width: '345px',
    minHeight: '313px',
    '&.winner': {
      paddingTop: '120px',
      backgroundImage: `url(${WinnerImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center 15px',
      backgroundSize: '280px auto',
      [theme.breakpoints.up('sm')]: {
        paddingTop: '135px',
        backgroundPosition: 'center 30px',
        backgroundSize: '380px auto',
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: '150px',
      },
    },
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
    [theme.breakpoints.up('sm')]: {
      minWidth: '428px',
      minHeight: '484px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '30px',
      backgroundColor: 'transparent',
    },
    '& > h2': {
      width: '90%',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '26px',
      color: '#1B2036',
      margin: 0,
      marginBottom: '6px',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
      },
    },
    '& > p': {
      fontSize: '17px',
      lineHeight: '20px',
      margin: 0,
      marginBottom: '18px',
      color: '#5F6372',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '25px',
        fontSize: '20px',
        lineHeight: '24px',
      },
    },
  },

  smile: {
    width: '60px',
    marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      width: '80px',
      marginBottom: '10px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },

  word: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'SF Pro Rounded',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '28px',
    lineHeight: '33px',
    textAlign: 'center',
    color: '#F05452',
    width: '100%',
    padding: '25px',
    background: '#FFFFFF',
    border: '5px solid #F05452',
    borderRadius: '10px',
  },
}));

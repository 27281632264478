import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  startWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
  },

  startTitle: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    color: 'white',
    textAlign: 'center',
    marginTop: 0,
    marginBottom: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '33px',
      marginBottom: '20px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '16px',
      color: '#1B2036',
    },
  },

  spinnerWrapper: {
    position: 'relative',
    zIndex: 1,
    color: 'inherit',
    width: '275px',
    height: '275px',
    overflow: 'hidden',
    borderRadius: '50%',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      height: '450px',
    },
    [theme.breakpoints.up('md')]: {
      width: '375px',
      height: '375px',
    },
  },

  spinnerImage: {
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    left: '50%',
    width: '275px',
    height: '275px',
    marginLeft: '-137.5px',
    marginTop: '-137.5px',
    animationDuration: '2s',
    animationIterationCount: '1',
    animationDelay: '25ms',
    animationFillMode: 'both',
    animationTimingFunction: 'ease-in-out',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      height: '450px',
      marginLeft: '-225px',
      marginTop: '-225px',
    },
    [theme.breakpoints.up('md')]: {
      width: '375px',
      height: '375px',
      marginLeft: '-187.5px',
      marginTop: '-187.5px',
    },
  },

  spinnerImage2: {
    position: 'absolute',
    zIndex: 3,
    top: '50%',
    left: '50%',
    width: '245px',
    height: '245px',
    marginLeft: '-122.5px',
    marginTop: '-122.5px',
    animationDuration: '2s',
    animationIterationCount: '1',
    animationDelay: '25ms',
    animationFillMode: 'both',
    animationTimingFunction: 'ease-in-out',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
      height: '400px',
      marginLeft: '-200px',
      marginTop: '-200px',
    },
    [theme.breakpoints.up('md')]: {
      width: '330px',
      height: '330px',
      marginLeft: '-165px',
      marginTop: '-165px',
    },
  },

  button: {
    zIndex: 4,
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100px',
    height: '100px',
    marginLeft: '-50px',
    marginTop: '-50px',
    borderRadius: '50%',
    cursor: 'pointer',
    opacity: 1,
    animationDuration: '.46s',
    animationIterationCount: '1',
    animationDelay: '25ms',
    animationFillMode: 'both',
    animationTimingFunction: 'ease-in-out',

    [theme.breakpoints.up('sm')]: {
      width: '164px',
      height: '164px',
      marginLeft: '-82px',
      marginTop: '-82px',
    },
    [theme.breakpoints.up('md')]: {
      width: '136px',
      height: '136px',
      marginLeft: '-68px',
      marginTop: '-68px',
    },
  },
}));

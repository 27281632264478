import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';

const HillbillyRules: React.FC = () => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();

  return (
    <>
      {isEnLocale ? (
        <ul className={classes.list}>
          <li>
            <b>Hello Billy</b>, today your goal is <b>to drive</b> as far as you can and <b>collect</b> as many coins as
            you can.
          </li>
          <li>
            <b>Control your car</b> - Ride fast but avoid hard hits. If your car loses all HP it will explode. And do
            not forget about your rival. Those guys are crazy, they throw molotov cocktail in your car!
          </li>
          <li>
            <b>Control shooter</b> - one guy in your car has a firearm. Aim precisely and shoot TNT crates, and any
            other obstacles before they hit your car.
          </li>
          <li>
            <b>Control fighter</b> - the other guy has melee weapon. Use it if a beaver is attacking your car.
          </li>
        </ul>
      ) : (
        <ul className={classes.list}>
          <li>
            Привет, Билли! Сегодня твоя цель - <b>проехать как можно дальше</b> и собрать{' '}
            <b>столько монет, сколько сможешь</b>.
          </li>
          <li>
            <b>Управляй своим автомобилем</b> - двигайся быстро, но избегай серьезных повреждений. Если твоя машина
            потеряет всю защиту - она взорвется. И не забывай о соперниках! Те парни сумасшедшие, они бросаются
            коктейлем Молотова в твою тачку!
          </li>
          <li>
            <b>Управляй стрелком</b>— у одного парня в вашей машине есть огнестрельное оружие. Точно целься и стреляй в
            ящики с тротилом и любые другие препятствия, прежде чем они попадут в твою машину.
          </li>
          <li>
            <b>Управляй бойцом</b> - у другого парня есть что-то тяжелое. Используй его, если бобр атакует твою тачку.
          </li>
        </ul>
      )}
    </>
  );
};

export { HillbillyRules };

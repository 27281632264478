const ChestsTextContentEn: React.FC = () => {
  return (
    <>
      <li>Leaderboard shown the total amount of coins players got from chests in this game </li>
    </>
  );
};

const ChestsTextContentRu: React.FC = () => {
  return (
    <>
      <li>На лидерборде отображается суммарное количество монет, которое игрок нашел в сундуках</li>
    </>
  );
};

export { ChestsTextContentEn, ChestsTextContentRu };

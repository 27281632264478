import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { getDefaultRoomPath } from '@store/room/roomSlice';
import { getAllGames, getHosts, selectPartyGame } from '@store/reducers/common';
import { GameTypes } from '@src/common/constants/common';
import Button from '@components/Button';
import { PartyCard } from '@components/entities/PartyCard';
import { GameCard } from '@components/entities/GameCard';
import { useMediaQuery } from '@material-ui/core';
import { nextPartyStyles } from './styles';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { Game, Host } from '@src/graphql/queries';
import { selectPlayedPartyIds } from '@src/store/reducers/player.reducer';

interface RouteProps {
  partyId: string;
}

interface Props extends RouteComponentProps<RouteProps> {}

const NextParty: React.FC<Props> = ({ history, match }) => {
  const isMobile = useMediaQuery('(max-width: 599px)');
  const PARTIES_TO_SHOW = isMobile ? 3 : 4;
  const GAMES_TO_SHOW = 2;
  const classes = nextPartyStyles();
  const partyId = match.params.partyId;
  const roomPath = useAppSelector(getDefaultRoomPath);
  const games = useAppSelector(getAllGames);
  const parties = useAppSelector(getHosts);
  const game = useAppSelector(selectPartyGame(partyId));
  const gameId = game?.id;
  const playedParties = useAppSelector(selectPlayedPartyIds);
  const sameGameParties = parties.filter((party) => party.game.id === gameId && party.id != partyId);
  const otherGames = games.filter((game) => game.id !== gameId && game.type !== GameTypes.COMING_SOON);
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const [gamesRandomized, setGamesRandomized] = useState<Game[]>([]);
  const [partiesRandomized, setPartiesRandomized] = useState<Host[]>([]);

  useEffect(() => {
    setGamesRandomized(_.shuffle(otherGames).slice(0, GAMES_TO_SHOW));
    setPartiesRandomized(
      _.sortBy(_.shuffle(sameGameParties), [(party) => playedParties.includes(party.id)]).slice(0, PARTIES_TO_SHOW)
    );
  }, []);

  const onButtonClick = () => {
    history.push(roomPath);
  };

  return (
    <div className={classes.nextParty}>
      <h2>{isEnLocale ? 'Start next party' : 'Начать новую игру'}</h2>

      {partiesRandomized.length > 0 && (
        <>
          <h3>{game?.title}</h3>
          <div className={classes.partiesContainer}>
            {partiesRandomized.map((party, index) => (
              <PartyCard key={index} party={party} />
            ))}
          </div>
        </>
      )}

      <h3>{isEnLocale ? 'New Game' : 'Новая игра'}</h3>
      <div className={classes.gamesContainer}>
        {gamesRandomized.length &&
          gamesRandomized.map((game, index) => {
            return <GameCard key={index} game={game} />;
          })}
      </div>

      <div className={classes.buttonWrapper}>
        <Button type="primary-blue-new" text={isEnLocale ? 'Back to homepage' : 'На главную'} onClick={onButtonClick} />
      </div>
    </div>
  );
};

export { NextParty };

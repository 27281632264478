import { analytics } from '@services/amplitude';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { styles } from './styles';

const SinglePage: React.FC = () => {
  const history = useHistory();
  const classes = styles();
  const [screen, setScreen] = useState('question');
  const questionText =
    'You make $ 10,000 a month, but ...\n\
  Your boss periodically sends his homemade porn to you, asks you to rate his technique in 10 and to give a constructive feedback\n\
  \n\
  Will you quit this job?';
  const buttons = [
    { title: 'Quit', value: 75 },
    { title: 'Stay', value: 25 },
  ];
  // const questionText = 'Would you rather spend a year without sex or a year without internet?';
  // const buttons = [{ title: 'No sex' }, { title: 'No internet' }];

  useEffect(() => {
    analytics.logEvent(analytics.EVENTS.INTRO_SHOWN);
  }, []);

  const onAnswerClick = (index: number) => {
    analytics.logEvent(analytics.EVENTS.INTRO_ANSWER_CLICKED, { answer: index });
    setScreen('results');
  };

  const onResultClick = (buttonType: string) => {
    analytics.logEvent(analytics.EVENTS.INTRO_RESULT_CLICKED, { button: buttonType });
    history.push('/');
  };

  const LARGE_TRIGGER = 100;
  const questionLength = questionText.length;

  return (
    <div className={classes.singlePage}>
      <div className={classes.singlePageWrapper}>
        {/* Question screen */}
        {screen === 'question' && (
          // question text
          <div className={`${classes.questionWrapper} animate__animated  animate__fadeIn`}>
            <div
              className={`
              ${classes.questionText}
              ${questionLength >= LARGE_TRIGGER ? 'large' : ''}
            `}
            >
              <p>
                <span>Question #1</span>
                <br />
                <br />
                {questionText}
              </p>
            </div>

            {/* buttons */}
            <div className={classes.btnContainer}>
              {buttons.length
                ? buttons.map((button, index) => {
                    // votedItem?.id - need to save vote when reload browser
                    return (
                      <div
                        key={index}
                        onClick={() => onAnswerClick(index)} // onCLick im map with anonim arrow func
                        className={classes.voteBtn}
                        style={{ width: `calc(100% / ${buttons.length}` }}
                      >
                        <span>{button.title}</span>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        )}

        {/* Results screen */}
        {screen === 'results' && (
          <div className={`${classes.resultsWrapper} animate__animated  animate__fadeIn`}>
            {/* question text */}
            <div
              className={`
                ${classes.questionTextResults}
                ${questionLength >= LARGE_TRIGGER ? 'large' : ''}
              `}
            >
              <p>{questionText}</p>
            </div>

            {/* bars */}
            <div className={classes.barsContainer}>
              <div className={classes.barsWrapper}>
                {buttons.map((button, index: number) => {
                  return (
                    <div key={index}>
                      <span>{`${button.value}%`}</span>
                      <b>{button.title}</b>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={classes.spacer} />

            {/* buttons */}
            <div className={classes.buttonsContainer}>
              <div className={classes.purple} onClick={() => onResultClick('friends')}>
                <span>See your Friends answers</span>
              </div>

              <div className={classes.blue} onClick={() => onResultClick('homepage')}>
                <span>Next Question</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SinglePage;

import React, { useState } from 'react';
import { useStyles } from './styles';
import { Friends, Streamers, SendModal, Withdraw, WithdrawModal } from '../index';
import { useAppSelector } from '@src/app/hooks';
import { IPlayerCard, selectBlockchain } from '@src/store/reducers/common';
import { ClaimTimer } from './TimerStaking/timerStaking';
import { selectRewards } from '@src/store/reducers/player.reducer';

const SendTab: React.FC = () => {
  const [isOpenSendModal, setIsOpenSendModal] = useState(false);
  const [isOpenWithdrawModal, setIsOpenWithdrawModal] = useState(false);
  const [chosenFriend, setChosenFriend] = useState<IPlayerCard | undefined>();
  const { claimed, end } = useAppSelector(selectRewards);
  const blockchainData = useAppSelector(selectBlockchain);
  const maxClaim = blockchainData.maxClaim;
  const minClaim = blockchainData.minClaim;
  const canClaim = end < new Date().valueOf() || claimed < maxClaim;
  const classes = useStyles();

  const onPlayerClick = (player: IPlayerCard) => {
    setIsOpenSendModal(true);
    setChosenFriend(player);
  };

  return (
    <>
      {!isOpenSendModal && !isOpenWithdrawModal && (
        <div className={classes.container}>
          <Friends onPlayerClick={onPlayerClick} />
          <Streamers onPlayerClick={onPlayerClick} />
          {!canClaim ? (
            <ClaimTimer lastClaimDate={end} />
          ) : (
            <Withdraw setIsOpenWithdrawModal={setIsOpenWithdrawModal} maxClaim={maxClaim} minClaim={minClaim} />
          )}
        </div>
      )}

      {isOpenSendModal && <SendModal setIsOpenSendModal={setIsOpenSendModal} chosenFriend={chosenFriend!} />}
      {isOpenWithdrawModal && <WithdrawModal setIsOpenWithdrawModal={setIsOpenWithdrawModal} maxClaim={maxClaim} />}
    </>
  );
};

export { SendTab };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  singleResults: {
    position: 'relative',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: '15px',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
    [theme.breakpoints.up('md')]: {
      // padding: '30px',
      background: 'white',
      borderRadius: '20px',
      width: '100%',
      paddingBottom: '20px',
    },
  },

  spacer: {
    flexGrow: 1,
  },

  questionText: {
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: '10px',
    backgroundColor: '#fff',
    borderRadius: '20px',
    padding: '15px',
    wordBreak: 'break-word',
    '& > span': {
      color: '#5F6372',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      marginBottom: '7px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '24px',
        marginBottom: '14px',
      },
    },

    '& > p': {
      whiteSpace: 'pre-wrap',
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 500,
      color: '#1B2036',
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
      },
    },
  },

  barsContainer: {
    width: '345px',
    alignItems: 'flex-end',
    justifyContent: 'center',
    background: '#FFF',
    borderRadius: '20px',
    marginBottom: '15px',
    paddingTop: '30px',
    paddingBottom: '30px',
    [theme.breakpoints.up('sm')]: {
      width: '528px',
      // height: '230px',
      paddingBottom: '50px',
    },
    // [theme.breakpoints.up('md')]: {
    //   marginTop: '60px',
    //   marginBottom: '60px',
    //   height: 'auto',
    //   paddingBottom: 0,
    // },
  },

  barsWrapper: {
    borderBottom: '1px solid #F3F4F5',
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'flex-end',
    justifyContent: 'space-evenly',

    '& > div': {
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: '75px',
      borderRadius: '4px 4px 0px 0px',
      minHeight: '20px',
      [theme.breakpoints.up('sm')]: {
        width: '115px',
        minHeight: '30px',
      },
      '& > span': {
        fontWeight: 'bold',
        fontSize: '15px',
        lineHeight: '18px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '24px',
          lineHeight: '29px',
        },
      },
      '& > b': {
        position: 'absolute',
        top: '100%',
        // bottom: '-16px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '12px',
        color: '#1B2036',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
          fontSize: '15px',
          lineHeight: '21px',
          // bottom: '-30px',
        },
      },
    },
  },

  buttonsContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '450px',
    },
  },

  purple: {
    height: '36px',
    width: '216px',
    backgroundImage: 'linear-gradient(77.61deg, #8E78FF 0%, #FC7D7B 100%)',
    borderRadius: '30px',
    marginBottom: '20px',
    cursor: 'pointer',
    transition: 'all .23s',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 0 0 5px white',
    [theme.breakpoints.up('sm')]: {
      width: '248px',
      marginBottom: '20px',
    },
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
    },
    '& > a': {
      width: '100%',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& span': {
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '15px',
      color: '#fff',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '16px',
      },
    },
  },

  blue: {
    height: '36px',
    // minWidth: '165px',
    padding: '0px 15px',
    backgroundColor: '#2C74F3',
    borderRadius: '30px',
    cursor: 'pointer',
    transition: 'all .23s',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 0 0 5px white',
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
    },
    '&:hover, &:focus': {
      backgroundColor: '#356FD6',
    },
    '&:active': {
      backgroundColor: '#3662AE',
    },
    '& > a': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
    },
    '& span': {
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '15px',
      color: '#fff',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '16px',
      },
    },
  },
}));

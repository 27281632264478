import { FC, useState, ChangeEvent } from 'react';
import { IGameState } from '../../matchMe.types';
import Button from '@components/Button';
import { RadioCustom } from '@components/Inputs';
import AvatarImg from '@art/images/person-avatar.png';
import { styles } from './styles';

interface IProps {
  game: IGameState;
  onPlayerVoted: (index: number) => void;
}

const Question: FC<IProps> = ({ game, onPlayerVoted }) => {
  const DEFAULT_VALUE = -1;
  const classes = styles();
  const { creatorAvatar, ticket, questionNumber, totalQuestions } = game;
  const [value, setValue] = useState(DEFAULT_VALUE);

  const onChangeRadioButtons = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(Number(event.currentTarget.value));
  };

  const onVoteClick = () => {
    onPlayerVoted(value); // value === option._id
    setValue(DEFAULT_VALUE); // reset value after answer
  };

  return (
    <div className={classes.questionWrapper}>
      <div className={classes.questionInner}>
        <div className={classes.questionNumber}>
          Question&nbsp;<span>{questionNumber}</span>&nbsp;of&nbsp;<span>{totalQuestions}</span>
        </div>

        <div className={classes.questionTitle}>
          {<img src={creatorAvatar || AvatarImg} alt="creatorAvatar" />}
          <p>{ticket?.question}</p>
        </div>

        <div className={classes.questionOptions}>
          {ticket?.answers.length &&
            ticket.answers.map((answer, index) => {
              return (
                <RadioCustom
                  id={index.toString()}
                  key={index}
                  value={index}
                  checked={value === index}
                  labelText={answer}
                  onChange={onChangeRadioButtons}
                />
              );
            })}
        </div>
      </div>

      <div className={classes.buttonWrapper}>
        {value != DEFAULT_VALUE && <Button text="Next" type="primary-blue" onClick={onVoteClick} />}
      </div>
    </div>
  );
};

export { Question };

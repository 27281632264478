import { IRoomPlayer, PLAYER_STATE } from '@store/room/roomSlice';
import { useAppSelector } from '@app/hooks';
import { selectPlayerId } from '@store/reducers/player.reducer';
import WSClient from '@src/sockets';
import { defaultGameState, initialMapState } from './game.types';
import { CLIENT_EVENTS, SERVER_EVENTS } from '@shared/socketEvents/eventTypes';
import { selectRoomPlayers } from '@src/store/room/roomSlice';
import { useState, useEffect } from 'react';
import { LoveBottleState } from '@src/shared/gameInterfaces/loveBottle.socket';
import { PLAYER_STATUS } from '@src/common/constants/system';
import { simpleMerge } from '@src/common/generics';

interface LoveBottleData {
  data: {
    loveBottle: LoveBottleState;
  };
}

export const useGameProvider = (roomId: string) => {
  const players = useAppSelector(selectRoomPlayers);
  const playerId = useAppSelector(selectPlayerId);
  const currentPlayer = players.find((player) => player.playerId === playerId) || ({} as IRoomPlayer);
  const [state, setState] = useState<LoveBottleState>(defaultGameState);
  const isSpectator = currentPlayer ? currentPlayer.state !== PLAYER_STATE.GAME : true;
  const stateMatcher = { ...initialMapState, [state.gameState]: true };

  useEffect(() => {
    const updateState = ({ data }: LoveBottleData) => setState(simpleMerge(state, data.loveBottle));

    WSClient.on(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    WSClient.on(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);

    WSClient.emit(CLIENT_EVENTS.GAME_STATE_REQUESTED, { roomId });

    return () => {
      WSClient.off(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);
      WSClient.off(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    };
  }, []);

  const setVote = (answerIndex: number) => setState(simpleMerge(state, {}));
  const setReady = () => setState(simpleMerge(state, {}));

  return [
    // 1 - state
    stateMatcher,
    // 2 - game
    { ...state, state: stateMatcher },
    // 3 - playerId
    playerId,
    // 4 - storeActions
    { setVote, setReady },
    // 5 - isSpectator
    isSpectator,
    currentPlayer,
  ] as const;
};

import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';
import longTape from '@art/icons/GameIntro/longTape.svg';
import shortTape from '@art/icons/GameIntro/shortTape.svg';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: '#F3F4F5',
    overflowY: 'scroll',
    alignItems: 'center',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '&::-webkit-scrollbar': {
      display: 'none',
      background: 'transparent',
      width: 0,
    },
  },

  bestParties: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '40px',
    [theme.breakpoints.up('md')]: {
      marginTop: '125px',
    },
  },

  containerViewAll: {
    display: 'flex',
    padding: '15px',
    boxSizing: 'border-box',
    width: '100%',
    justifyContent: 'start',
    flexDirection: 'column',
    alignItems: 'center',
    flexShrink: 0,
    // overflowX: 'hidden',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: '20px',
      maxWidth: '840px',
    },
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
      display: 'none',
    },
  },

  gridWidgetWrapper: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: 'repeat(auto, 95px)',
    rowGap: 10,
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: '48.5% 48.5%',
      gridTemplateRows: 'repeat(auto, 95px)',
      columnGap: 20,
      rowGap: 15,
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateRows: 'repeat(auto, 95px)',
      columnGap: 30,
    },
  },

  title: {
    width: 'auto',
    flexWrap: 'nowrap',
    padding: '0 25px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '32px',
    lineHeight: '32px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    textShadow: '1px 3px 0px #000000',
    textStroke: '2px black',
    marginTop: 0,
    marginBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '52px',
      lineHeight: '52px',
    },
  },

  subtitle: {
    width: 'auto',
    flexWrap: 'nowrap',
    padding: '0 25px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '20px',
    lineHeight: '24px',
    marginTop: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    textShadow: '1px 1px 0px #000000',
    textStroke: '1px black',
    marginBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      textStroke: '2px black',
      fontSize: '40px',
      lineHeight: '48px',
      marginBottom: '20px',
    },
  },

  rules: {
    flexDirection: 'column',
    cursor: 'pointer',
    '& ul ul': {
      paddingLeft: '10px',
      paddingTop: '10px',
    },
  },

  headline: {
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '32px',
    lineHeight: '32px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'unset',
    flexShrink: 0,
    alignContent: 'center',
    textTransform: 'uppercase',
    color: '#1B2036',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '29px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '32px',
      lineHeight: '32px',
    },
  },

  description: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '21px',
    color: '#1B2036',
    marginBottom: 10,
    cursor: 'pointer',
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
      marginBottom: 5,
    },
  },

  info: {
    background: '#FFFFFF',
    border: '1px solid #E8E9EB',
    boxSizing: 'border-box',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 30px',
    width: '100%',
    marginTop: '40px',
    [theme.breakpoints.up('md')]: {
      marginTop: '125px',
    },
  },

  positionButton: {
    marginTop: '-17px',
    marginBottom: 30,
  },

  createRoomButton: {
    background: ' linear-gradient(180deg, rgba(243, 244, 245, 0) 0%, #F3F4F5 100%);',
    paddingTop: 30,
    paddingBottom: 20,
    marginBottom: -20,
  },

  width: {
    width: 'auto',
    flexShrink: 0,
  },

  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    position: 'relative',
    flexDirection: 'column',
  },

  modeContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > div, a': {
      textDecoration: 'none',
      display: 'flex',
      '& > img': {
        marginRight: '8px',
        paddingTop: '28px',
        width: '12px',
        height: '12px',
        [theme.breakpoints.up('sm')]: {
          width: '20px',
          height: '20px',
          marginRight: '11px',
        },
        [theme.breakpoints.up('md')]: {
          marginRight: '15px',
          paddingTop: '35px',
          width: '24px',
          height: '24px',
        },
      },
      '& > p': {
        fontFamily: 'Barbieri-Regular',
        fontSize: '24px',
        lineHeight: '28px',
        color: '#FFFFFF',
        textShadow: '0px 2px 0px #000000',
        margin: '22px 0px 0px',
        textStroke: '1.5px black',
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
          fontSize: '44px',
          lineHeight: '44px',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '48px',
          lineHeight: '56px',
        },
        '&:hover': {
          transform: 'scale(1.01)',
          color: '#FFFFFF',
        },
      },
    },
  },

  // logo: {
  //   width: '160px',
  //   height: '123px',
  //   flexShrink: 0,
  //   [theme.breakpoints.up('sm')]: {
  //     width: '340px',
  //     height: '260px',
  //   },
  //   [theme.breakpoints.up('md')]: {
  //     width: '380px',
  //     height: '290px',
  //   },
  // },

  logo: {
    width: '275px',
    height: '126px',
    flexShrink: 0,
    marginTop: '50px',
    [theme.breakpoints.up('sm')]: {
      width: '540px',
      height: '248px',
      marginTop: '35px',
    },
    [theme.breakpoints.up('md')]: {
      width: '675px',
      height: '310px',
    },
  },

  logoUnity: {
    width: '275px',
    height: '126px',
    flexShrink: 0,
    marginTop: '50px',
    [theme.breakpoints.up('sm')]: {
      width: '540px',
      height: '248px',
      marginTop: '35px',
    },
    [theme.breakpoints.up('md')]: {
      width: '675px',
      height: '310px',
    },
  },

  arrowUp: {
    width: '40px',
    height: '40px',
    position: 'fixed',
    bottom: '60px',
    left: '10px',
    zIndex: 1,
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      width: '60px',
      height: '60px',
      bottom: '10px',
    },
  },

  hotIcon: {
    width: '39px',
    height: '45px',
    position: 'fixed',
    bottom: '60px',
    right: '10px',
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      width: '55px',
      height: '63px',
      bottom: '10px',
    },
    [theme.breakpoints.up('md')]: {
      width: '90px',
      height: '103px',
      bottom: '10px',
      right: '340px',
    },
  },
  buttonPlay: {
    width: '201px',
    height: '102px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    position: 'absolute',
    bottom: '14px',
    [theme.breakpoints.up('sm')]: {
      bottom: '20px',
      width: '325px',
      height: '165px',
    },
    [theme.breakpoints.up('md')]: {
      bottom: '55px',
    },
    '&:hover': {
      transform: 'scale(1.02)',
    },
  },

  buttonText: {
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '34px',
    lineHeight: '28px',
    textAlign: 'center',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    textStroke: '2px  #000000',
    margin: 0,
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '54px',
    },
  },
  onlyRoomOwner: {
    background: '#FFFFFF',
    borderRadius: '20px',
    padding: '15px 30px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '24px',
    lineHeight: '28px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#1B2036',
    maxWidth: '515px',
    margin: '20px 10px',
    position: 'absolute',
    bottom: '50px',
    [theme.breakpoints.up('sm')]: {
      fontWeight: 900,
      fontSize: '32px',
      lineHeight: '32px',
      padding: '35px 50px',
      position: 'relative',
    },
  },
  treasuryContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    top: '0px',
    right: '4px',
    [theme.breakpoints.up('sm')]: {
      top: 'auto',
      right: '10px',
    },
    '& > p': {
      fontFamily: 'SF Pro Rounded Black',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '26px',
      lineHeight: '31px',
      textAlign: 'center',
      color: '#FFFFFF',
      textStroke: '1px #000000',
      margin: 0,
    },
    '& > div': {
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: `url(${shortTape})`,
      width: '72px',
      height: '73px',
      flexDirection: 'column',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.up('sm')]: {
        width: '342px',
        height: '73px',
        backgroundImage: `url(${longTape})`,
        flexDirection: 'row',
      },
      '& > img': {
        width: '26px',
        height: '25px',
        marginTop: '-15px',
        [theme.breakpoints.up('sm')]: {
          marginRight: '4px',
          marginTop: '0px',
          width: '37px',
          height: '36px',
        },
      },
      '& > p': {
        fontFamily: 'SF Pro Rounded Black',
        fontWeight: 900,
        fontSize: '15px',
        lineHeight: '17px',
        textStroke: '1px #8F0D92',
        textAlign: 'center',
        color: '#FFFFFF',
        [theme.breakpoints.up('sm')]: {
          fontSize: '32px',
          lineHeight: '38px',
        },
        '&.infinity': {
          fontSize: '30px',
          lineHeight: '25px',
          [theme.breakpoints.up('sm')]: {
            fontSize: '40px',
            lineHeight: '45px',
          },
        },
      },
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '110px',
    alignContent: 'center',
    justifyContent: 'space-between',
    marginTop: 'auto',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      height: '220px',
      marginBottom: '60px',
    },
  },
}));

import { makeStyles } from '@material-ui/core';

import questionMark from '@art/icons/question-mark.svg';

export const styles = makeStyles((theme) => ({
  questionButton: {
    width: '32px',
    height: '32px',
    backgroundColor: '#2C74F3',
    backgroundImage: `url(${questionMark})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '50%',
    cursor: 'pointer',
    boxShadow: '0 0 0 10px rgba(255,255,255, 0.5)',
  },

  modalWrapper: {
    flexDirection: 'column',
  },

  list: {
    flexDirection: 'column',
    paddingLeft: 0,
    margin: 0,
    overflowY: 'auto',
    maxHeight: '58vh',
    borderRadius: '20px',
    [theme.breakpoints.up('sm')]: {
      borderRadius: '20px',
      maxHeight: '35vh',
    },
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
      display: 'none',
    },
    '& li': {
      position: 'relative',
      fontSize: '15px',
      lineHeight: '21px',
      marginBottom: '12px',
      listStyle: 'none',
      paddingLeft: '15px',
      '&:last-child': {
        marginBottom: 0,
      },
      [theme.breakpoints.up('md')]: {
        paddingRight: '15px',
        fontSize: '14px',
        lineHeight: '21px',
      },
      '&::before': {
        content: '""',
        display: 'block',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: '#E8E9EB',
        position: 'absolute',
        top: '8px',
        left: 0,
      },
    },
    '& b': {
      fontWeight: 700,
    },
  },
}));

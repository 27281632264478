import { useAppSelector } from '@app/hooks';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { styles } from './styles';

const AliasRules: React.FC = () => {
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = styles();

  return (
    <>
      {isEnLocale ? (
        <ul className={classes.list}>
          <li>An active player gets a phrase to explain</li>
          <li>An active player should explain the phrase to the other players without using it or any part of it</li>
          <li>All other players should guess the phrase</li>
          <li>An active player and the one who guessed the phrase get points</li>
          <li>If no one guessed correctly, no one gets points</li>
        </ul>
      ) : (
        <ul className={classes.list}>
          <li>Активный игрок получает фразу для объяснения</li>
          <li>Активный игрок должен объяснить фразу другим игрокам, не используя ее или ее часть</li>
          <li>Все остальные игроки должны отгадать фразу</li>
          <li>Активный игрок и игрок, угадавший фразу, получает очки</li>
          <li>Если никто не угадал фразу, то очки не начисляются никому</li>
        </ul>
      )}
    </>
  );
};

export { AliasRules };

import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  startWrapper: {
    flexGrow: 1,
    padding: '15px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '--animate-duration': '230ms',
    '--animate-delay': '230ms',
    [theme.breakpoints.up('md')]: {
      padding: '30px',
    },
  },

  startTitle: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    color: 'white',
    textAlign: 'center',
    marginTop: 0,
    marginBottom: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '33px',
      marginBottom: '20px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '16px',
      color: '#1B2036',
    },
  },

  spinWrapper: {
    position: 'relative',
    zIndex: 1,
    width: '275px',
    height: '275px',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      height: '450px',
    },
    [theme.breakpoints.up('md')]: {
      width: '375px',
      height: '375px',
    },
  },

  image: {
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    left: '50%',
    width: '275px',
    height: '275px',
    marginLeft: '-137.5px',
    marginTop: '-137.5px',
    animationDuration: '1.45s',
    animationIterationCount: '1',
    animationDelay: '100ms',
    animationFillMode: 'both',
    animationTimingFunction: 'cubic-bezier(0.81, 0.1, 0.31, 0.9)', // ease-in-out
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      height: '450px',
      marginLeft: '-225px',
      marginTop: '-225px',
    },
    [theme.breakpoints.up('md')]: {
      width: '375px',
      height: '375px',
      marginLeft: '-187.5px',
      marginTop: '-187.5px',
    },
  },

  spinButton: {
    zIndex: 4,
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '130px',
    height: '71px',
    marginLeft: '-65px',
    marginTop: '-35.5px',
    cursor: 'pointer',
    opacity: 1,
    transitionProperty: 'filter, opacity',
    transitionDuration: '.23s, .46s',
    '&.clicked': {
      opacity: 0,
      pointerEvents: 'none',
      '&:hover': {
        cursor: 'default',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: '212px',
      height: '116px',
      marginLeft: '-106px',
      marginTop: '-58px',
    },
    [theme.breakpoints.up('md')]: {
      width: '182px',
      height: '106px',
      marginLeft: '-91px',
      marginTop: '-53px',
    },
  },
}));

import WSClient from '@src/sockets';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { simpleMerge } from '@src/common/generics';
import { PLAYER_STATE, selectRoomPlayers } from '@store/room/roomSlice';
import { selectBalance, selectPlayerId } from '@store/reducers/player.reducer';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { ChestsState } from '@shared/gameInterfaces/chests.socket';
import { CLIENT_EVENTS, SERVER_EVENTS } from '@shared/socketEvents/eventTypes';
import { defaultGameState, initialMapState } from './chests.types';
import { requestRewards } from '@src/graphql/player.queries';

interface ChestsData {
  data: {
    chests: ChestsState;
  };
}

export const useGameProvider = (roomId: string) => {
  const dispatch = useAppDispatch();
  const [game, setGameState] = useState<ChestsState>(defaultGameState);
  const players = useSelector(selectRoomPlayers);
  const playerId = useSelector(selectPlayerId);
  const balance = useAppSelector(selectBalance);
  const isActivePlayer = playerId === game.activePlayerId;
  const currentPlayer = players.find((player: any) => player.playerId === playerId);
  const isSpectator = currentPlayer ? currentPlayer.state !== PLAYER_STATE.GAME : true;
  const stateMatcher = { ...initialMapState, [game.gameState]: true };
  const hasStaked = game.stakedPlayers.find((id) => id === playerId);

  useEffect(() => {
    const updateState = ({ data }: ChestsData) => setGameState(simpleMerge(game, data.chests));

    WSClient.on(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    WSClient.on(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);

    WSClient.emit(CLIENT_EVENTS.GAME_STATE_REQUESTED, { roomId });

    return () => {
      WSClient.off(SERVER_EVENTS.GAME_STATE_UPDATED, updateState);
      WSClient.off(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    };
  }, []);

  useEffect(() => {
    if (hasStaked) {
      dispatch(requestRewards);
    }
  }, [hasStaked]);

  const setVote = (answerIndex: number) => setGameState(simpleMerge(game, {})); // vote: answerIndex }));
  const setReady = () => setGameState(simpleMerge(game, {})); // readyPlayers: playerId }));

  return [
    stateMatcher,
    { ...game, status: stateMatcher },
    playerId,
    players,
    isActivePlayer,
    { setVote, setReady },
    isSpectator,
    balance,
  ] as const;
};

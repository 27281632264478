import { useAppSelector } from '@src/app/hooks';
import { AliasState } from '@src/shared/gameInterfaces/alias.socket';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import { styles } from './styles';

interface Props {
  activePlayer: { name: string };
}

const Rule: React.FC<Props> = ({ activePlayer }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);

  const wordValueText = isEnLocale
    ? 'will explain the phrase without using it or any part of it'
    : 'объяснит фразу, не называя ее или ее частей';

  return (
    <div className={` animate__animated  animate__fadeInDown`}>
      <div className={classes.ruleContainer}>
        <div className={classes.name}>{activePlayer?.name}</div>
        <div className={classes.description}>{wordValueText}</div>
      </div>
    </div>
  );
};

export { Rule };
